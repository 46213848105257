import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import { MURALL_WALL, MURALL_WALL_ID } from '../../lib/constants'
import { useEns } from '../../hooks/useEns'
import {
  IPFS_GATEWAY_PINATA_MURALL,
  replaceIpfsIfNeeded
} from '../libs/appUtils'
import { useTheme } from '@mui/material/styles'
import SvgHistoryVideoState from '../../components/common/svg/svg-history-video-state'

const useStyles = base64Png =>
  makeStyles(theme => ({
    root: {
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        minWidth: '344px !important'
      }
    },
    bgTransparentCard: {
      backgroundColor: 'transparent'
    },
    bgTransparent: {
      backgroundBlendMode: `normal, normal`,
      backgroundImage: `
      radial-gradient(circle at top right, ${theme.palette.primary.main}00 35%,  ${theme.palette.primary.main}80),
      linear-gradient(to right, #26262B, #26262B)
    `,
      mixBlendMode: 'screen'
    },
    bgImage: {
      backgroundColor: '#fddc6c',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundBlendMode: `normal, multiply, normal, multiply`,
      backgroundImage: `
      radial-gradient(circle at top right, ${theme.palette.primary.main}00 35%,  ${theme.palette.primary.main}80),
      radial-gradient(circle at top right,
        #cccccc,
        #4a4a4a,
        #212121),
      url(${base64Png}),
      linear-gradient(to right, #26262B, #26262B)
    `
    },
    outerGlow: {
      filter: `drop-shadow(2px 2px 5px #00000066)`
    },
    typography: {
      fontFamily: 'Roboto',
      fontWeight: 300,
      textShadow: '2px 2px 5px black'
    },
    actionRoot: {
      padding: '8px 8px 8px 16px'
    },
    icons: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignContent: 'flex-start',
      justifyContent: 'flex-start'
    },
    closeButton: {
      padding: '8px 8px'
    }
  }))

const PaintedEventSnackMessage = ({ event, onClose } = {}) => {
  const isVideo = event.animation_url // Check if the event has an animation_url
  const wallInfo = MURALL_WALL[event.wallId]

  const ens = useEns(event.artist)

  const theme = useTheme()

  const image =
    event.wallId === MURALL_WALL_ID.EVOLV3
      ? replaceIpfsIfNeeded(event.fileUri, IPFS_GATEWAY_PINATA_MURALL)
      : event.croppedBase64PngString
  const classes = useStyles(image)()

  return (
    <div className={classes.root}>
      {isVideo && (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            width: '100%',
            height: '100%'
          }}
        >
          <SvgHistoryVideoState
            x={0}
            y={0}
            video={event.animation_url}
            height={'100%'}
            width={'100%'}
            shouldPlay={true}
            objectFit='cover'
          />
          <foreignObject
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              mixBlendMode: 'screen'
            }}
          >
            <div
              id={'overlay'}
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%'
              }}
              className={clsx({
                [classes.bgTransparent]: isVideo
              })}
            />
          </foreignObject>
        </svg>
      )}
      <Card
        className={clsx(
          'pixelated-image',
          {
            [classes.bgImage]: !isVideo
          },
          {
            [classes.bgTransparentCard]: isVideo
          }
        )}
      >
        <CardHeader
          sx={{
            mixBlendMode: 'normal'
          }}
          avatar={
            <Avatar
              sx={{ width: 24, height: 24 }}
              alt={wallInfo.name}
              variant='square'
              style={{
                overflow: 'visible',
                background: 'transparent',
                mixBlendMode: 'normal'
              }}
            >
              <wallInfo.svgComponent
                className={clsx(classes.outerGlow)}
                style={{
                  overflow: 'visible',
                  width: '100%',
                  height: '100%',
                  mixBlendMode: 'normal'
                }}
              />
            </Avatar>
          }
          action={
            <IconButton
              className={classes.closeButton}
              onClick={onClose}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          }
          title={
            <Typography
              noWrap
              className={clsx(classes.typography)}
              color='textPrimary'
            >
              {'New Artwork on ' + wallInfo.name}
            </Typography>
          }
        />
        <CardContent></CardContent>
        <CardActions classes={{ root: classes.actionRoot }}>
          <div className={classes.icons}>
            <Typography
              component='h5'
              variant='h5'
              noWrap
              className={clsx(classes.typography)}
              color='textPrimary'
            >
              {event.name}
            </Typography>

            <Typography
              component='span'
              variant='subtitle1'
              noWrap
              className={clsx(classes.inline, classes.typography)}
              color='textPrimary'
            >
              {event.description}
            </Typography>
            <Typography
              component='span'
              variant='subtitle2'
              noWrap
              className={clsx(classes.inline, classes.typography)}
              color={'textSecondary'}
            >
              {ens || event.artist}
            </Typography>
          </div>
        </CardActions>
      </Card>
    </div>
  )
}

export default PaintedEventSnackMessage
