import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import PaintIconImage from '../../../images/introducing_paint.png'
import AboutNftImage from '../../../images/nft.png'
import HeaderImage from '../../../images/about-header.png'
import OnChainImage from '../../../images/onchain.png'
import InstantRepresentationImage from '../../../images/representation.png'
import Evolv3Image from '../../../images/logos/evolv3_logo.png'
import AiImage from '../../../images/AI.png'
import EthMaticImage from '../../../images/eth_matic.png'
import RewindImage from '../../../images/rewind.png'
import EvRevImage from '../../../images/EvRev.png'
import '../../css/layout.css'
import clsx from 'clsx'
import { ButtonGroup, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import GlitchImage from './images/glitch-image'
import CrtOverlay from './images/crt-overlay'
import Fade from '../../reveal/in-and-out/Fade'
import { DefaultContainer } from './DefaultContainer'

const useStyles = makeStyles(theme => ({
  gradientBackground: {
    background:
      'radial-gradient(circle at top right,' +
      theme.palette.secondary.main +
      '40' +
      ' 45%, ' +
      theme.palette.primary.main +
      '80' +
      ')'
  },
  bgImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundBlendMode: `multiply, normal`,
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat'
  }
}))

const backgroundForImage = image => {
  return `radial-gradient(circle at top right,
    #cccccc 25%,
    #4a4a4a,
    #212121),
    url(${image})`
}

const GridSection = ({ iconImage, title, paragraph, isMobile }) => {
  return (
    <Grid item xs={12} p={{ xs: 5, sm: 10 }}>
      <DefaultContainer>
        <Grid
          container
          direction={isMobile ? 'column' : 'row'}
          spacing={1}
          alignItems={'center'}
        >
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Fade top>
              <Title>{title}</Title>
            </Fade>
            <Fade top>
              <Paragraph>{paragraph}</Paragraph>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Fade right>
              <GlitchImage src={iconImage} />
            </Fade>
          </Grid>
        </Grid>
      </DefaultContainer>
    </Grid>
  )
}

const GridSectionWithImage = ({
  isMobile,
  classes,
  backgroundImage,
  iconImage,
  title,
  paragraph,
  end
}) => {
  return (
    <Grid
      className={clsx(
        {
          ['slanted']: !end
        },
        {
          ['slanted-end']: end
        },
        classes.bgImage
      )}
      item
      xs={12}
      mt={10}
      p={{ xs: 5, sm: 10 }}
      style={{
        backgroundImage: backgroundForImage(backgroundImage)
      }}
    >
      <CrtOverlay
        grain
        refresh
        sx={{
          m: { xs: -5, sm: -10 }
        }}
        style={{
          zIndex: 0,
          position: 'absolute',
          width: '100%',
          height: '100%'
        }}
      />
      <DefaultContainer>
        <Grid
          container
          direction={isMobile ? 'column' : 'row'}
          spacing={1}
          alignItems={'center'}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Fade left>
              <GlitchImage src={iconImage} />
            </Fade>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Fade top>
              <Title>{title}</Title>
            </Fade>
            <Fade top>
              <Paragraph>{paragraph}</Paragraph>
            </Fade>
          </Grid>
        </Grid>
      </DefaultContainer>
    </Grid>
  )
}

const content = [
  {
    title: 'Introducing PAINT',
    paragraph: (
      <>
        MurAll requires a digital token called PAINT to enable artists to draw
        onto the canvas. Each PAINT token can draw 2 pixels and just like real
        life paint, it can only be used to draw once. If it has been used it
        can’t be taken off of the wall and reused! With only a finite amount of
        PAINT available globally (there is only enough PAINT to cover the canvas
        21,000 times), it is a commodity which encourages artists to produce
        work they are passionate about as the more PAINT that is used, the less
        is available.
      </>
    ),
    iconImage: PaintIconImage
  },
  {
    title: 'Your NFT',
    paragraph: (
      <>
        Once an artist has created their piece and “minted” it on the MurAll
        canvas, it is immortalised forever on the blockchain. The artist
        receives an NFT (Non Fungible Token) of their drawing; in contrast to
        traditional tokens, NFTs are one of a kind, ensuring that the artwork is
        a UNIQUE ORIGINAL which has value and can be kept or sold whenever they
        wish - just like real life paintings. Unlike most existing technologies,
        the artwork itself is contained within the token and isnt just a
        certificate.
      </>
    ),
    iconImage: AboutNftImage
  },
  {
    title: 'Evolv3 now available',
    paragraph: (
      <>
        Evolv3 is the latest addition to the MurAll family, complete with full
        colour image and video support (including transparent images and video!)
        <br />
        <br />
        Evolv3 is also gasless, meaning you can draw using only your Paint
        tokens, with no network fees to pay!
      </>
    ),
    iconImage: Evolv3Image
  },
  {
    title: 'Now with AI',
    paragraph: (
      <>
        Unleash your creativity with our AI-powered text to image generation,
        designed to make artistic expression accessible to everyone, regardless
        of skill level, creating a truly diverse and inclusive digital mural for
        all to enjoy.
        <br />
        <br />
        Whether you're a seasoned artist or a beginner, our advanced AI can
        transform your thoughts and ideas into stunning visuals, bridging the
        gap between imagination and the canvas.
      </>
    ),
    iconImage: AiImage
  },
  {
    title: 'Instant Representation',
    paragraph: (
      <>
        The moment you draw your artwork is visible to everyone through our live
        state. No need to jump through hoops and beg to have your work featured;
        just draw!
      </>
    ),
    iconImage: InstantRepresentationImage
  },
  {
    title: 'Get Your Artwork On Chain',
    paragraph: (
      <>
        Using our proprietary Blockmap image encoding, all artwork drawn on the
        Revo1t and Di2rupt MurAlls are put onto the blockchain itself, the
        worlds most secure and robust storage availble, ensuring your artwork
        will be available for as long as the blockchain lives.
      </>
    ),
    iconImage: OnChainImage
  },
  {
    title: 'Available on Ethereum and Polygon',
    paragraph: (
      <>
        Contribute your artwork to either network, each with it's own MurAll
        state, and your artwork will become part of the ever changing MurAll
        state on your chosen blockchain. Polygon NFTs can also be bridged to
        Ethereum once minted!
      </>
    ),
    iconImage: EthMaticImage
  },
  {
    title: 'Rewind time',
    paragraph: (
      <>
        Using our History viewer you can go back to previous states of MurAll,
        and even choose a range of states to play back through in order to see
        how it evolved between those points.
      </>
    ),
    iconImage: RewindImage
  }
]

const backgroundImages = [
  'https://images.murall.art/polygon/history/1182/state.png',
  'https://images.murall.art/evolv3/history/0/state.png',
  'https://images.murall.art/polygon/history/1552/state.png',
  'https://images.murall.art/polygon/history/19/state.png'
]

const AboutContents = ({ detailsRef }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const network = useSelector(state => state.network)

  return (
    <>
      <Grid
        container
        spacing={0}
        direction='column'
        justifyContent='center'
        sx={{
          overflowX: 'hidden'
        }}
      >
        <Grid ref={detailsRef} item xs={12} p={{ xs: 5, sm: 10 }}>
          <DefaultContainer>
            <Fade top>
              <GlitchImage src={HeaderImage} />
            </Fade>

            <Fade top>
              <Title>What is MurAll?</Title>
            </Fade>
            <Fade top>
              <Paragraph>
                MurAll is a collaborative canvas allowing artists worldwide to
                paint onto an ever evolving piece of art. This is a first of its
                kind project made possible by the use of blockchain technology,
                allowing anyone anywhere in the world to draw whatever they like
                without restrictions on what they can draw.
              </Paragraph>
            </Fade>
          </DefaultContainer>
        </Grid>

        {content.map((item, index) => ({
          ...(index % 2 === 0 ? (
            <GridSectionWithImage
              key={index}
              classes={classes}
              iconImage={item.iconImage}
              backgroundImage={backgroundImages[Math.floor(index / 2)]}
              title={item.title}
              paragraph={item.paragraph}
              isMobile={isMobile}
              end={index === content.length - 1}
            />
          ) : (
            <GridSection
              key={index}
              isMobile={isMobile}
              iconImage={item.iconImage}
              title={item.title}
              paragraph={item.paragraph}
            />
          ))
        }))}

        <Grid
          item
          xs={12}
          p={{ xs: 5, sm: 10 }}
          style={{ backgroundColor: '#000000' }}
          alignItems={'center'}
        >
          <DefaultContainer>
            <Grid
              container
              direction={'column'}
              alignItems={'center'}
              spacing={1}
            >
              <Grid item xs={12}>
                <Fade top>
                  <GlitchImage
                    src={EvRevImage}
                    classes={classes}
                    style={{ maxHeight: '406px' }}
                  />
                </Fade>
              </Grid>
              <Grid item xs={12} mt={10} mb={5} sx={{ width: '100%' }}>
                <Fade top cascade>
                  <ButtonGroup
                    variant='text'
                    aria-label='text button group'
                    fullWidth
                  >
                    <Button
                      href='https://murall.art/docs/LitePaper.pdf'
                      target='_blank'
                      rel='noopener'
                    >
                      litepaper
                    </Button>
                    <Button component={Link} to='/faq'>
                      faq
                    </Button>
                    <Button
                      href='https://knowledgebase.murall.art'
                      target='_blank'
                      rel='noopener'
                    >
                      Knowledgebase
                    </Button>
                  </ButtonGroup>
                </Fade>
              </Grid>
            </Grid>
          </DefaultContainer>
        </Grid>
      </Grid>
    </>
  )
}

const Title = ({ children }) => (
  <Typography
    className={clsx('crt-text')}
    variant='h3'
    component='h3'
    align='center'
    gutterBottom
    my={2}
    sx={{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      textShadow: '2px 2px 5px black'
    }}
  >
    {children}
  </Typography>
)
const Paragraph = ({ children }) => (
  <Typography
    className={clsx('crt-text')}
    variant='h6'
    align='justify'
    component='p'
    gutterBottom
    sx={{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      textShadow: '2px 2px 5px black'
    }}
  >
    {children}
  </Typography>
)

export default AboutContents
