import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SUPPORTED_CHAINS, L2_CHAINS } from '../lib/constants'

const getNetworkName = wallId => {
  if (!SUPPORTED_CHAINS.includes(wallId)) return ''
  return L2_CHAINS.includes(wallId) ? 'polygon' : 'ethereum'
}

const getPathForWallId = wallId => {
  switch (wallId) {
    case MURALL_WALL_ID.LAYER_1:
      return 'ethereum'
    case MURALL_WALL_ID.LAYER_2:
      return 'polygon'
    case MURALL_WALL_ID.EVOLV3:
      return 'evolve'
    default:
      return 'ethereum'
  }
}

export const historyAPI = createApi({
  reducerPath: 'historyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://images.murall.art`
  }),
  endpoints: builder => ({
    getHistory: builder.query({
      query: (wallId, id) => ({
        url: `${getPathForWallId(wallId)}/history/${id}/state.png`,
        method: 'GET'
      })
    }),
    getCurrentState: builder.query({
      query: (wallId) => ({
        url: `${getPathForWallId(wallId)}/state.png`,
        method: 'GET'
      })
    })
  })
})

export const { useGetHistoryQuery, useGetCurrentStateQuery } = historyAPI
