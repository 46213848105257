import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../web3.js'
import switchToNetwork from '../../lib/actions/switch-network.js'
import { setSelectedNetwork } from '../../state/slices/networkSwitch.js'
import { getUrlParameters } from '../../js/libs/appUtils.js'
import { MURALL_WALL } from '../../lib/constants.js'
import { setWall } from '../../state/slices/wall.js'

export const useMurAllSelectFromQueryParams = () => {
  const [loading, setLoading] = useState(false)

  const { library } = useActiveWeb3React()
  const network = useSelector(state => state.network)
  const currentWall = useSelector(state => state.wall)
  const dispatch = useDispatch()
  const search = useLocation().search

  useEffect(() => {
    const queryParams = getUrlParameters(search)
    const targetWall = Object.values(MURALL_WALL).find(
      wall => wall.queryParam === queryParams.wall
    )

    if (!targetWall) return

    const desiredNetwork = targetWall.chainId
    const processNetworkSwitch = async () => {
      setLoading(true)
      await switchToNetwork({ library, chainId: desiredNetwork })
      dispatch(setSelectedNetwork(desiredNetwork))
      setLoading(false)
    }
    if (currentWall.id !== targetWall.id) {
      dispatch(setWall(targetWall.id))
    }
    if (desiredNetwork && network.id !== desiredNetwork) {
      try {
        processNetworkSwitch()
      } catch (error) {
        console.error('Network switch failed', error)
        setLoading(false)
      }
    }
  }, [currentWall, network, search])

  return { loading }
}

export default useMurAllSelectFromQueryParams
