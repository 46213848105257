import { useMemo } from 'react'
import { ethers } from 'ethers'
import { useEthersProviderForChainId } from './use-ethers-provider-for-chain-id'

// returns null on errors
export const useEthersContractForAddress = ({
  abi,
  contractAddress,
  targetChainId,
  forceArchiveNode = false,
  websocketInstance = false
}) => {
  const ethersProvider = useEthersProviderForChainId(
    targetChainId,
    forceArchiveNode,
    websocketInstance
  )

  return useMemo(() => {
    if (!ethersProvider) {
      console.error('Failed to get provider')
      return null
    }
    if (!abi || !contractAddress) {
      console.error(
        'Failed to get contract ABI or address for chainId',
        targetChainId
      )
      return null
    }

    try {
      return new ethers.Contract(contractAddress, abi, ethersProvider)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [contractAddress, ethersProvider, targetChainId, abi])
}
