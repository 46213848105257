import { useCallback, useEffect, useState } from 'react'
import { useMurAllEvolv3NftDataSource } from '../use-murall-evolv3-nft-datasource.js'
import useListenForEvolv3Mints from './use-listen-for-evolv3-mints.js'
import { useActiveWeb3React } from '../web3.js'

const useMintToEvolv3 = ({
  ipfsHash,
  fileType,
  videoLengthMillis,
  position,
  dimensions,
  name,
  description,
  attributeKeys,
  attributeValues,
  nonce,
  verifierSignature,
  metaTransaction = true,
  immediate = false
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [mintedNftData, setMintedNftData] = useState(undefined)
  const [shouldListenForMint, setShouldListenForMint] = useState(false)

  const { mintMetaTx, mint } = useMurAllEvolv3NftDataSource()
  const { account } = useActiveWeb3React()
  const mintedNfts = useListenForEvolv3Mints({
    filterAddress: account,
    active: shouldListenForMint
  })

  useEffect(() => {
    if (mintedNfts && mintedNfts.length > 0) {
      setSuccess(true)
      setMintedNftData(mintedNfts[0])
      setLoading(false)
    }
  }, [mintedNfts])

  const apply = useCallback(async () => {
    try {
      setLoading(true)
      setSuccess(false)
      setShouldListenForMint(true)

      const response = metaTransaction
        ? await mintMetaTx(
            ipfsHash,
            fileType,
            videoLengthMillis,
            position,
            dimensions,
            name,
            description,
            attributeKeys,
            attributeValues,
            nonce,
            verifierSignature
          )
        : await mint(
            ipfsHash,
            fileType,
            videoLengthMillis,
            position,
            dimensions,
            name,
            description,
            attributeKeys,
            attributeValues,
            nonce,
            verifierSignature
          )
      console.log('useMintToEvolv3 - response', response)
    } catch (error) {
      console.error(error)

      setError(error)
      setShouldListenForMint(false)

      setLoading(false)
      return
    }
  }, [
    ipfsHash,
    fileType,
    videoLengthMillis,
    position,
    dimensions,
    name,
    description,
    attributeKeys,
    attributeValues,
    nonce,
    verifierSignature
  ])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, success, loading, error, mintedNftData }
}

export default useMintToEvolv3
