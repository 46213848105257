import React, { useCallback, useEffect, useState } from 'react'

import { useMurAllEvolv3NftDataSource } from '../use-murall-evolv3-nft-datasource.js'
import { MAX_UINT } from '../../lib/constants.js'

const useAllowPaintTransferToEvolv3ForAmount = ({
  paintTokenAmount,
  immediate = false
}) => {
  const [loading, setLoading] = useState(false)

  const [approved, setApproved] = useState(false)
  const [error, setError] = useState(false)
  const {
    isPaintTokenApprovedForAmount,
    approvePaintTokenForAmount,
  } = useMurAllEvolv3NftDataSource()

  const apply = useCallback(async () => {
    try {
      setLoading(true)
      let isApprovedForAmount = await isPaintTokenApprovedForAmount(
        paintTokenAmount
      )

      if (!isApprovedForAmount) {
        try {
          await approvePaintTokenForAmount(MAX_UINT)
          isApprovedForAmount = true
        } catch (error) {
          console.error(error)
          setError(error)
          setApproved(false)
          setLoading(false)
          return
        }
      }
      setApproved(isApprovedForAmount)
      setLoading(false)
      return isApprovedForAmount
    } catch (error) {
      console.error(error)
      setLoading(false)
      return
    }
  }, [
    paintTokenAmount,
    isPaintTokenApprovedForAmount,
    approvePaintTokenForAmount
  ])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, loading, approved, error }
}

export default useAllowPaintTransferToEvolv3ForAmount
