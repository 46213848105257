import React from 'react'
import { Box, Typography } from '@mui/material'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

export default function ImagePlaceholderView ({ sx, message }) {
  return (
    <Box
      sx={{
        border: '2px dashed',
        borderColor: 'text.secondary',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx
      }}
    >
      <ImageOutlinedIcon
        sx={{
          color: 'text.secondary'
        }}
      />
      {message && (
        <Typography variant='body2' component='p' color='textSecondary'>
          {message}
        </Typography>
      )}
    </Box>
  )
}
