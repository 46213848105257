import React, { useEffect, useState } from 'react'

import { useMurAllEvolv3NftDataSource } from '../use-murall-evolv3-nft-datasource.js'

export const useCalculatePaintUsage = ({
  width,
  height,
  videoLengthMilliseconds,
  fileType
}) => {
  const [loading, setLoading] = useState(false)

  const [paintUsage, setPaintUsage] = useState(0)
  const { calculatePaintUsage } = useMurAllEvolv3NftDataSource()

  useEffect(() => {
    if (!width || !height) {
      setLoading(false)
      setPaintUsage(0)
      return
    }
    async function calculatePaintUsageForImage () {
      setLoading(true)
      const paintToBeUsed = await calculatePaintUsage(
        fileType,
        videoLengthMilliseconds ? videoLengthMilliseconds : 0,
        [width, height]
      )
      setPaintUsage(paintToBeUsed)
      setLoading(false)
    }
    calculatePaintUsageForImage()
  }, [width, height, fileType, videoLengthMilliseconds])

  return { paintUsage, loading }
}

export default useCalculatePaintUsage
