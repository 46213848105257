import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../../../lib/constants'


const useStyles = makeStyles(theme => ({
  backgroundAnimValues: {
    transition: 'all 0.6s'
  },

  backgroundMediaHovered: {
    filter: 'brightness(35%) blur(3px)'
  }
}))

export default function SvgImageOverlayFocusableView ({
  style,
  backgroundImageSrc,
  backgroundImageProps = {},
  foregroundImageSrc,
  foregroundImageProps = {},
  viewBox = `0 0 ${CANVAS_WIDTH} ${CANVAS_HEIGHT}`
}) {
  const classes = useStyles()

  const [shouldDim, setShouldDim] = useState(false)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      style={{
        ...style
      }}
      onMouseEnter={() => setShouldDim(true)}
      onMouseLeave={() => setShouldDim(false)}
    >
      <svg viewBox={viewBox}>
        <g>
          <image
            href={backgroundImageSrc}
            className={clsx('pixelated-image', classes.backgroundAnimValues, {
              [classes.backgroundMediaHovered]: shouldDim
            })}
            {...backgroundImageProps}
          />
          <image
            className={clsx('pixelated-image')}
            href={foregroundImageSrc}
            {...foregroundImageProps}
          />
        </g>
      </svg>
    </svg>
  )
}
