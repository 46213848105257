import React, { useEffect, useState } from 'react'
import useDetectSmallScreen from '../../../../uicomponents/useSmallScreenDetection'
import { useSelector } from 'react-redux'
import {
  singleTokenValue as sanitiseSingleValue,
  tokenRangeStartValue as sanitiseRangeStartValue,
  tokenRangeEndValue as sanitiseRangeEndValue
} from '../../../../libs/sanitisers'
import useStyles from './styles'
import { MURALL_WALL, SupportedChain } from '../../../../../lib/constants'
import { Box, TextField as MuiTextField, Tooltip } from '@mui/material'
import TokenListItem from './token_list_item'
import VirtualisedAutocompleteField from './virtualised-autocomplete-field'
import clsx from 'clsx'
import { useApi } from '../../../../../hooks/use-api'
import './../../../../../css/layout.css'

const Wrapper = ({ isMobile, className, children }) => {
  // if (isMobile) return children
  return <div className={className}>{children}</div>
}

const Fields = ({
  showRange,
  totalTokens,
  rangeStart,
  rangeEnd,
  setRangeStart,
  setRangeEnd,
  rangeStartEndAdornment,
  rangeEndEndAdornment,
  maxRange
}) => {
  const isMobile = useDetectSmallScreen()
  const currentWall = useSelector(state => state.wall)
  const wallInfo = MURALL_WALL[currentWall.id]
  const api = useApi(wallInfo.chainId, currentWall.id)
  const cls = useStyles(isMobile)()
  const [startValue, setStartValue] = useState(rangeStart)
  const [endValue, setEndValue] = useState(rangeEnd)

  const stateChangeImageUrlForValue = value => {
    //TODO replace with api url once it's available
    return api.s3.token(value)
  }

  const entireRangeOptions =
    parseInt(totalTokens) > 1 ? [...Array(parseInt(totalTokens)).keys()] : [0]

  const rangeStartOptions =
    parseInt(totalTokens) > 1
      ? entireRangeOptions.slice(
          0,
          totalTokens > 0 ? parseInt(totalTokens) - 1 : 0
        )
      : [0]

  const rangeEndOptions =
    totalTokens > 1
      ? entireRangeOptions.slice(
          parseInt(startValue) + 1,
          startValue !== null
            ? parseInt(startValue) + parseInt(maxRange)
            : parseInt(totalTokens)
        )
      : [0]

  useEffect(() => {
    setStartValue(null)
    setRangeStart(null)
    setEndValue(null)
    setRangeEnd(null)
  }, [totalTokens])

  return (
    <Wrapper isMobile={isMobile} className={cls.gridRow}>
      <VirtualisedAutocompleteField
        id='token-select-start'
        className={clsx(cls.rangeStart)}
        options={rangeStartOptions}
        value={startValue}
        autoHighlight
        getOptionLabel={option => option.toString()}
        renderVirtualisedOption={(props, option, inlineStyle) => (
          <TokenListItem
            {...props}
            style={inlineStyle}
            item={option}
            primaryText={`#${option}`}
            index={option}
            key={option}
            id={option}
            image={stateChangeImageUrlForValue(option)}
          />
        )}
        renderInput={params => (
          <Box className={clsx('pixelated-image')}>
            <MuiTextField
              {...params}
              label={showRange ? 'From NFT' : 'Token Id'}
              helperText={`0 to ${
                rangeStartOptions[rangeStartOptions.length - 1]
              }`}
              min={0}
              max={rangeStartOptions[rangeStartOptions.length - 1]}
              inputProps={{
                ...params.inputProps,
                // autocomplete: 'new-password',
                autocomplete: 'off',
                form: {
                  autocomplete: 'off'
                }
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  backgroundBlendMode: `multiply, normal, normal`,
                  backgroundImage: `radial-gradient(circle at top right,
                                  #cccccc,
                                  #4a4a4a,
                                  #212121),
                                  url(${stateChangeImageUrlForValue(
                                    startValue
                                  )}),
                                  linear-gradient(to right, #26262B, #26262B)`
                }
              }}
              sx={{
                '&, & .MuiTypography-root': {
                  textShadow: '2px 2px 5px black'
                }
              }}
            />
          </Box>
        )}
        onChange={(e, value, reason) => {
          if (reason === 'selectOption') {
            const sanitisedValue = sanitiseSingleValue(
              Number(value),
              totalTokens
            )
            setStartValue(sanitisedValue)
            setRangeStart(sanitisedValue)
            setEndValue(null)
            setRangeEnd(null)
          } else if (reason === 'clear') {
            setStartValue(null)
            setRangeStart(null)
            setEndValue(null)
            setRangeEnd(null)
          }
        }}
      />

      {rangeStartEndAdornment}
      {showRange && (
        <VirtualisedAutocompleteField
          id='token-select-end'
          disabled={startValue === null || parseInt(totalTokens) <= 1}
          className={clsx(cls.rangeEnd)}
          options={rangeEndOptions}
          value={endValue}
          autoHighlight
          getOptionLabel={option => option.toString()}
          renderVirtualisedOption={(props, option, inlineStyle) => (
            <TokenListItem
              {...props}
              style={inlineStyle}
              item={option}
              primaryText={`#${option}`}
              index={option}
              key={option}
              id={option}
              image={stateChangeImageUrlForValue(option)}
            />
          )}
          renderInput={params => (
            <Box className={clsx('pixelated-image')}>
              <MuiTextField
                {...params}
                label={showRange ? 'To NFT' : 'Token Id'}
                helperText={`${startValue + 1} to ${
                  rangeEndOptions[rangeEndOptions.length - 1]
                }`}
                min={startValue + 1}
                max={rangeEndOptions[rangeEndOptions.length - 1]}
                inputProps={{
                  ...params.inputProps,
                  // autocomplete: 'new-password',
                  autocomplete: 'off',
                  form: {
                    autocomplete: 'off'
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundBlendMode: `multiply, normal, normal`,
                    ...(endValue && {
                      backgroundImage: `radial-gradient(circle at top right,
                                  #cccccc,
                                  #4a4a4a,
                                  #212121),
                                  url(${stateChangeImageUrlForValue(endValue)}),
                                  linear-gradient(to right, #26262B, #26262B)`
                    })
                  }
                }}
                sx={{
                  '&, & .MuiTypography-root': {
                    textShadow: '2px 2px 5px black'
                  }
                }}
              />
            </Box>
          )}
          onChange={(e, value, reason) => {
            if (reason === 'selectOption') {
              const sanitisedValue = sanitiseRangeEndValue(
                Number(value),
                totalTokens
              )
              setEndValue(sanitisedValue)
              setRangeEnd(sanitisedValue)
            } else if (reason === 'clear') {
              setEndValue(null)
              setRangeEnd(null)
            }
          }}
        />
      )}

      {rangeEndEndAdornment}
    </Wrapper>
  )
}

export default Fields
