import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: '#000000',
    color: theme.palette.primary.contrastText,
    // boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + 'B3'
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px'
  },
  media: {
    marginTop: '24px',
    height: '245px',
    objectFit: 'contain'
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  cardActions: {
    backgroundColor: '#000000'
  },
  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  colouredLineBorder: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: 10
  }
}))

export default function TradeInfoCard ({
  image,
  title,
  cardContent,
  availableMarketButtons
}) {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={10}>
      <CardMedia
        className={clsx(classes.media)}
        component={'img'}
        image={image}
        title={title}
        alt={title}
      />
      <CardContent>{cardContent}</CardContent>

      <CardActions className={clsx(classes.cardActions)}>
        <div
          className={clsx(classes.flexColumn)}
          style={{
            margin: '24px'
          }}
        >
          {availableMarketButtons}
        </div>
      </CardActions>
    </Card>
  )
}
