import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import StyledDialog from '../../uicomponents/styled_dialog'
import clsx from 'clsx'
import _ from 'underscore'
import Divider from '@mui/material/Divider'

import { POSClient, use, setProofApi } from '@maticnetwork/maticjs'
import { Web3ClientPlugin } from '@maticnetwork/maticjs-web3'
import { useActiveWeb3React } from '../../../hooks/web3'
import notification from '../../../lib/notification'
import { SupportedChain } from '../../../lib/constants'
import getWeb3L1 from '../../libs/getWeb3L1'
import getWeb3L2 from '../../libs/getWeb3L2'

use(Web3ClientPlugin)
setProofApi('https://apis.matic.network/')

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '24px'
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },

  emptyViewContainer: {
    display: 'inline-block'
  },

  outerGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },

  paintLogo: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  },
  paintInfoBg: {
    background:
      'radial-gradient(circle at top right,' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      ')',
    backgroundBlendMode: `normal`
  },
  paintInfoBgDisabled: {
    background:
      'radial-gradient(circle at top right,' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      '), linear-gradient(to right, #54545f, #54545f)',
    backgroundBlendMode: `overlay, normal`
  }
}))

const DEFAULT_HELPER_TEXT = 'Click CHECK to check the entered address'

export default function TokenBridgeDialog (props) {
  const classes = useStyles()
  const { account, library, chainId } = useActiveWeb3React()

  const [burnAddress, setBurnAddress] = useState(null)
  const [burnTokenId, setBurnTokenId] = useState(null)
  const [exitAddress, setExitAddress] = useState(null)
  const [exitBurnHash, setExitBurnHash] = useState(null)
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [exitButtonEnabled, setExitButtonEnabled] = useState(false)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState(DEFAULT_HELPER_TEXT)
  const [posClient, setPosClient] = useState(null)

  useEffect(() => {
    async function init () {
      console.log('init')
      const from = account

      const web3 = library
      console.log('web3', web3)
      console.log('web3 currentProvider', web3.currentProvider)
      const newPosClient = new POSClient()

      await newPosClient.init({
        log: true,
        network: 'mainnet',
        version: 'v1',
        parent: {
          provider:
            chainId === SupportedChain.Ethereum
              ? web3.currentProvider
              : getWeb3L1(),
          defaultConfig: {
            from: account
          }
        },
        child: {
          provider:
            chainId === SupportedChain.Polygon
              ? web3.currentProvider
              : getWeb3L2(),
          defaultConfig: {
            from: account
          }
        }
      })
      setPosClient(newPosClient)
    }
    if (!library || !account || !chainId) {
      return
    }
    init()
  }, [library, account, chainId]) // Empty array ensures that effect is only run on mount

  const onAddressChanged = event => {
    var newAddress = event.target.value

    setBurnAddress(newAddress)
    setButtonEnabled(
      newAddress &&
        newAddress.length > 0 &&
        burnTokenId &&
        burnTokenId.length > 0
    )
  }

  const onTokenIdChanged = event => {
    var newTokenId = event.target.value

    setBurnTokenId(newTokenId)
    setButtonEnabled(
      burnAddress &&
        burnAddress.length > 0 &&
        newTokenId &&
        newTokenId.length > 0
    )
  }
  const onExitAddressChanged = event => {
    var newAddress = event.target.value

    setExitAddress(newAddress)
    setExitButtonEnabled(
      newAddress &&
        newAddress.length > 0 &&
        exitBurnHash &&
        exitBurnHash.length > 0
    )
  }

  const onExitBurnHashChanged = event => {
    var newBurnHash = event.target.value

    setExitBurnHash(newBurnHash)
    setExitButtonEnabled(
      exitAddress &&
        exitAddress.length > 0 &&
        newBurnHash &&
        newBurnHash.length > 0
    )
  }

  return (
    <StyledDialog
      {...props}
      maxWidth={'sm'}
      withCloseButton={true}
      dialogTitle={'NFT Bridge Polygon <> Ethereum'}
      dialogContent={() => (
        <div className={clsx(classes.flexColumn)}>
          <Typography
            component='body1'
            variant='body1'
            noWrap
            className={clsx(classes.typographyLight)}
            color='textPrimary'
          >
            Start withdraw NFT:
          </Typography>
          <div className={clsx(classes.flexRow)}>
            <TextField
              style={{ alignSelf: 'center', flex: 1, marginRight: '8px' }}
              label={'Address'}
              error={error}
              value={burnAddress}
              variant='outlined'
              helperText={'Enter the address of the NFT'}
              onChange={onAddressChanged}
              margin='normal'
            />
            <TextField
              style={{ alignSelf: 'center', flex: 1 }}
              label={'TokenId'}
              error={error}
              value={burnTokenId}
              variant='outlined'
              helperText={'Enter the token id of the NFT'}
              onChange={onTokenIdChanged}
              margin='normal'
            />
          </div>

          <Button
            size='large'
            color='secondary'
            onClick={async () => {
              props.onBurnClick && props.onBurnClick(burnAddress, burnTokenId)
              if (chainId !== SupportedChain.Polygon) {
                notification.error(
                  'Please switch to Polygon network to start withdrawal of NFT'
                )
                return
              }
              try {
                console.log(posClient)
                const erc721Token = posClient.erc721(burnAddress)

                const result = await erc721Token.withdrawStartWithMetaData(
                  burnTokenId
                )

                const txHash = await result.getTransactionHash()

                console.log(txHash)
                const txReceipt = await result.getReceipt()
                console.log(txReceipt)
                notification.success(
                  `Started withdrawal - transaction hash ${txHash}, please wait for checkpoint`
                )
              } catch (e) {
                console.log(e)
                notification.error(`Error: ${e.message}`)
              }
            }}
            disabled={!buttonEnabled}
            variant={buttonEnabled ? 'contained' : 'outlined'}
            style={{ marginBottom: '12px', marginTop: '24px' }}
          >
            Start withdraw NFT to Ethereum
          </Button>
          <Divider light style={{ margin: '24px' }} />

          <Typography
            component='body1'
            variant='body1'
            noWrap
            className={clsx(classes.typographyLight)}
            color='textPrimary'
          >
            Finish Withdraw NFT to Ethereum:
          </Typography>
          <div className={clsx(classes.flexRow)}>
            <TextField
              style={{ alignSelf: 'center', flex: 1, marginRight: '8px' }}
              label={'Ethereum Address'}
              error={error}
              value={exitAddress}
              variant='outlined'
              helperText={'Enter the mapped Ethereum address of the NFT'}
              onChange={onExitAddressChanged}
              margin='normal'
            />
            <TextField
              style={{ alignSelf: 'center', flex: 1 }}
              label={'Withdraw transaction Hash'}
              error={error}
              value={exitBurnHash}
              variant='outlined'
              helperText={'Enter the tx hash of the withdraw tx on Polygon'}
              onChange={onExitBurnHashChanged}
              margin='normal'
            />
          </div>

          <Button
            size='large'
            color='secondary'
            onClick={async () => {
              props.onExitClick && props.onExitClick(exitAddress, exitBurnHash)

              if (chainId !== SupportedChain.Ethereum) {
                notification.error(
                  'Please switch to Ethereum network to finish withdrawal of NFT'
                )
                return
              }

              try {
                const erc721RootToken = posClient.erc721(exitAddress, true)

                const isCheckPointed = await posClient.isCheckPointed(
                  exitBurnHash
                )
                if (!isCheckPointed) {
                  notification.error('Please wait for checkpoint to reach')
                  return
                }

                // const erc721RootToken = posClient.erc721(exitAddress, true)

                const result = await erc721RootToken.withdrawExitFasterWithMetaData(
                  exitBurnHash
                )

                const txHash = await result.getTransactionHash()
                console.log(txHash)
                const txReceipt = await result.getReceipt()
                console.log(txReceipt)
                notification.success(
                  `Finished withdrawal - transaction hash ${txHash}`
                )
              } catch (e) {
                console.log(e)
                notification.error(`Error: ${e.message}`)
              }
            }}
            disabled={!exitButtonEnabled}
            variant={exitButtonEnabled ? 'contained' : 'outlined'}
            style={{ marginBottom: '12px', marginTop: '24px' }}
          >
            Finish Withdraw NFT
          </Button>
        </div>
      )}
    />
  )
}
