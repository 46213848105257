import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTheme } from '@mui/material/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  pulse: {
    '-moz-animation': '$pulse ease-in-out infinite',
    animation: '$pulse ease-in-out infinite',
    animationName: '$pulse',
    animationDuration: (props) => `${props.animationDuration}ms`,
    webkitAnimationDuration: (props) => `${props.animationDuration}ms`
  },
  pulseRadar: {
    '-moz-animation': '$pulseRadar ease-out infinite',
    animation: '$pulseRadar ease-out infinite',
    animationName: '$pulseRadar',
    animationDuration: (props) => `${props.animationDuration * 2}ms`,
    webkitAnimationDuration: (props) => `${props.animationDuration * 2}ms`
  },
  '@keyframes pulse': {
    '0%': {
      opacity: '0'
    },
    '50%': {
      opacity: '1'
    },
    '100%': {
      opacity: '0'
    }
  },
  '@keyframes pulseRadar': {
    '0%': {
      opacity: '0.5',
      transformOrigin: 'center center',
      transform: 'scale(1, 1)'
    },
    '50%': {
      opacity: '0',
      transformOrigin: 'center center',
      transform: 'scale(2.5, 2.5)'
    },
    '100%': {
      opacity: '0',
      transformOrigin: 'center center',
      transform: 'scale(2.5, 2.5)'
    }
  }
}))

const MurAllLogo = ({ theme, style = {}, className }) => (
  <g {...(className && { className })} style={style}>
    <path
      d="M7.44 45.36c-.28 0-.55-.12-.74-.32-.19-.21-.28-.48-.26-.76l.91-10.38c.04-.46.39-.83.85-.9l8.32-1.22L3.29 29.8a.993.993 0 0 1-.85-.99c0-.5.36-.92.85-.99l11.08-1.66a.991.991 0 0 1-.53-.88c0-.06 0-.12.01-.17L1.84 23.16c-.48-.08-.84-.5-.84-.99s.35-.91.84-.99l9.61-1.59-1.87-.27c-.52-.08-.9-.55-.85-1.08l.62-7c.03-.37.27-.69.61-.84l22.36-9.33c.11-.04.24-.07.37-.07a.983.983 0 0 1 .99.87l2.09 16.07 4.19.61c.49.07.85.49.85.99s-.36.92-.85.99l-4.48.66c-.08.04-.18.07-.27.08l-6.16.9 13.29 1.96c.49.07.85.49.85.99v.06l.06.01c.49.07.85.49.85.99s-.36.92-.85.99l-11.44 1.68 12.39 1.93c.49.08.85.5.85.99s-.36.91-.85.99l-6.47 1.01 1.45 10.46a.98.98 0 0 1-.24.79.98.98 0 0 1-.75.34H7.44z"
      style={{
        fill: theme.palette.primary.main
      }}
    />
    <path d="m32.69 2 2.19 16.82 4.94.72-4.75.7.01.04-12.88 1.89 20 2.96-3.14.46 4.06.59-17.89 2.63.34.08 18.49 2.88-7.44 1.16 1.59 11.43H7.44l.91-10.38 15.03-2.2-19.94-2.96L21 26.19l-6.15-.9 3.31-.49L2 22.17l15.93-2.63-8.21-1.2.61-7L32.69 2m0-2c-.26 0-.52.05-.77.15L9.57 9.49c-.69.29-1.16.93-1.22 1.67l-.61 7c-.03.36.03.7.17 1.01L1.67 20.2c-.96.16-1.67 1-1.67 1.98s.71 1.81 1.68 1.97L11 25.66l-7.85 1.18a2 2 0 0 0 .01 3.96l6.53.97-1.63.23c-.92.13-1.62.88-1.7 1.8l-.92 10.39a2.007 2.007 0 0 0 1.99 2.18H38.2c.58 0 1.13-.25 1.51-.69.38-.44.55-1.02.47-1.59l-1.32-9.48 5.5-.86c.97-.15 1.69-.99 1.69-1.98s-.72-1.82-1.69-1.98l-5.86-.91 4.9-.72c.98-.14 1.71-.99 1.71-1.98 0-.75-.42-1.41-1.04-1.76a2.01 2.01 0 0 0-1.58-1.28l-6.72-.99 4.34-.64c.98-.14 1.71-.99 1.71-1.98s-.73-1.83-1.71-1.98l-3.44-.5-1.99-15.31A1.98 1.98 0 0 0 33.7.27 2.04 2.04 0 0 0 32.69 0z" />
  </g>
)
const LiveContainer = ({ theme, style = {}, className }) => (
  <g {...(className && { className })} style={style}>
    <path
      d="M3.59 56.89c-.55 0-1-.45-1-1v-9.95c0-.55.45-1 1-1h38.87c.55 0 1 .45 1 1v9.95c0 .55-.45 1-1 1H3.59z"
      style={{
        fill: '#e91bfa'
      }}
    />
    <path d="M42.46 45.94v9.95H3.59v-9.95h38.87m0-2H3.59c-1.1 0-2 .9-2 2v9.95c0 1.1.9 2 2 2h38.87c1.1 0 2-.9 2-2v-9.95a2 2 0 0 0-2-2z" />
  </g>
)
const LiveText = ({ theme, style = {}, className }) => (
  <g style={style}>
    <path
      {...(className && { className })}
      d="M10.3 53.02v-5.03c0-.45.21-.67.62-.67h.21c.43 0 .65.22.65.67v4.55h2.42c.45 0 .68.21.68.62v.04c0 .42-.23.63-.68.63h-3.13c-.24 0-.43-.07-.56-.21-.14-.14-.21-.34-.21-.6zm7.46.18v-5.21c0-.45.21-.67.63-.67h.2c.43 0 .65.22.65.67v5.21c0 .45-.22.67-.65.67h-.2c-.42 0-.63-.23-.63-.67zm4.25-5.39c0-.01-.01-.02-.01-.03 0-.04.03-.09.1-.16.08-.08.2-.15.36-.21.15-.06.3-.09.45-.09.41 0 .68.23.83.68l1.42 4.18L26.57 48c.14-.45.41-.68.8-.68.15 0 .29.03.44.1s.26.13.34.21c.06.06.09.11.09.16 0 .01 0 .02-.01.03l-2.22 5.52c-.09.2-.2.34-.33.43-.13.09-.32.13-.55.13-.23 0-.41-.04-.54-.12s-.23-.23-.32-.43l-2.26-5.54zm8.92 5.21v-4.84c0-.26.07-.46.21-.61s.32-.21.56-.21h3.61c.45 0 .68.21.68.62v.05c0 .41-.23.62-.68.62h-2.9v1.25h2.12c.45 0 .68.2.68.58v.04c0 .41-.23.62-.68.62h-2.12v1.4h2.95c.45 0 .68.2.68.59v.06c0 .43-.23.64-.68.64H31.7c-.24 0-.43-.07-.56-.21-.15-.14-.21-.34-.21-.6z"
      style={{
        fill: '#fff'
      }}
    />
  </g>
)

export default function LiveViewIndicatorIcon({ style = {} }) {
  const classes = useStyles({
    animationDuration: 1750
  })
  const theme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        overflow: 'visible',
        opacity: 0.5,
        ...style
      }}
      viewBox="0 0 46.06 57.89"
    >
      <g>
        <MurAllLogo theme={theme} />

        <LiveContainer className={clsx(classes.pulse)} />

        <LiveText />
      </g>
    </svg>
  )
}
