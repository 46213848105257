import React, { useEffect, useRef, useState } from 'react'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../../../lib/constants'
import SvgHistoryState from './svg-history-state'
import { gsap } from 'gsap'
import { startLocateAnimation, startPixellateAnimation } from './animations'

const LOCATE_ANIMATION_DURATION_MILLIS = 800
export default function SvgLayersImageViewer ({
  style,
  layers,
  layerPreview,
  selectedIndex,
  baseState,
  selectedOnTop = false,
  useGlitchEffect = true,
  animationDuration = '0.5s'
}) {
  const timeline = gsap.timeline()

  const blurRef = useRef(null)
  const fadeRRef = useRef(null)
  const fadeGRef = useRef(null)
  const fadeBRef = useRef(null)

  const pixellateGlitchFilter = useRef(null)
  const currentPixellateGlitchAnim = useRef(null)
  const currentLocateAnim = useRef(null)

  const [isLocating, setLocating] = useState(false)
  const [isGlitchAnimating, setGlitchAnimating] = useState(false)
  const showOnTop = selectedOnTop && selectedIndex !== null

  useEffect(() => {
    if (selectedIndex !== null) {
      setLocating(true)
      startLocateAnimation(
        timeline,
        LOCATE_ANIMATION_DURATION_MILLIS,
        blurRef,
        fadeRRef,
        fadeGRef,
        fadeBRef,
        currentLocateAnim,
        () => {
          setLocating(false)
        },
        selectedOnTop ? false : true
      )
    }
  }, [selectedIndex])

  useEffect(() => {
    if (useGlitchEffect) {
      setGlitchAnimating(true)
      startPixellateAnimation(
        timeline,
        animationDuration,
        pixellateGlitchFilter,
        currentPixellateGlitchAnim,
        () => {
          setGlitchAnimating(false)
        }
      )
    }
  }, [selectedIndex])

  if (!layers || layers.length === 0) {
    return null
  }

  const selectedLayerPreviewInfo =
    selectedIndex !== null &&
    selectedIndex < layers.length &&
    layerPreview(layers[selectedIndex])
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      style={{
        overflow: 'visible',
        ...(style && {
          ...style
        })
      }}
    >
      <defs>
        <filter id='blurFilter' width='100%' height='100%' x='0' y='0'>
          <feGaussianBlur
            ref={blurRef}
            id='blur'
            in='SourceGraphic'
            stdDeviation='0'
            result='blurred'
          />
          <feComponentTransfer id={'darkened'}>
            <feFuncR ref={fadeRRef} id='darkenR' type='linear' slope='1' />
            <feFuncG ref={fadeGRef} id='darkenG' type='linear' slope='1' />
            <feFuncB ref={fadeBRef} id='darkenB' type='linear' slope='1' />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode in='neutral' />
            <feMergeNode in='darkened' />
          </feMerge>
        </filter>
        <filter id='pixelate' x='0' y='0' width='200%' height='200%'>
          <feTurbulence
            id='animation'
            type='fractalNoise'
            baseFrequency='0.00001 9.9999999'
            numOctaves='1'
            result='warp'
          >
            {useGlitchEffect && (
              <animate
                attributeName='baseFrequency'
                from='0.00001 9.9999'
                to='0.00001 0.001'
                dur='2s'
                repeatCount='indefinite'
                restart='always'
              />
            )}
          </feTurbulence>
          <feDisplacementMap
            id={'glitchelate'}
            ref={pixellateGlitchFilter}
            xChannelSelector='R'
            yChannelSelector='G'
            scale='0'
            in='SourceGraphic'
            in2='warpOffset'
          ></feDisplacementMap>
        </filter>
      </defs>
      <svg
        viewBox={`0 0 ${CANVAS_WIDTH} ${CANVAS_HEIGHT}`}
        style={{
          overflow: 'visible'
        }}
      >
        <g
          style={{
            overflow: 'visible',
            ...((isLocating || showOnTop) && { filter: 'url(#blurFilter)' })
          }}
        >
          {baseState && (
            <SvgHistoryState
              showFullImage={true}
              image={baseState.fullBase64PngString}
              x={baseState.positionInformation.start.x}
              y={baseState.positionInformation.start.y}
              height={baseState.positionInformation.height}
              width={baseState.positionInformation.width}
            />
          )}

          {layers.map((layer, index) => {
            const layerPreviewInfo = layerPreview(layer)
            return (
              <SvgHistoryState
                animationDuration={
                  isLocating
                    ? `${LOCATE_ANIMATION_DURATION_MILLIS}ms`
                    : animationDuration
                }
                shouldLocate={isLocating && index === selectedIndex}
                image={layerPreviewInfo.croppedBase64PngString}
                x={layerPreviewInfo.positionInformation.start.x}
                y={layerPreviewInfo.positionInformation.start.y}
                height={layerPreviewInfo.positionInformation.croppedHeight}
                width={layerPreviewInfo.positionInformation.croppedWidth}
              />
            )
          })}
        </g>
        {(isLocating || showOnTop) && selectedLayerPreviewInfo && (
          <SvgHistoryState
            animationDuration={`${LOCATE_ANIMATION_DURATION_MILLIS}ms`}
            shouldLocate={isLocating}
            image={selectedLayerPreviewInfo.croppedBase64PngString}
            x={selectedLayerPreviewInfo.positionInformation.start.x}
            y={selectedLayerPreviewInfo.positionInformation.start.y}
            height={selectedLayerPreviewInfo.positionInformation.croppedHeight}
            width={selectedLayerPreviewInfo.positionInformation.croppedWidth}
            useGlitchEffect={isGlitchAnimating}
          />
        )}
      </svg>
    </svg>
  )
}
