import React, { useEffect, useRef, useState } from 'react'
import { useActiveWeb3React } from '../../../hooks/web3'
import useGetEmoteForNftByAccount from '../../../hooks/emotable/use-get-emotes-for-nft-by-account'
import EmojiFabWithPicker from './EmojiFabWithPicker'
import useEffectDebounced from '../../../hooks/useEffectDebounced'
import { useTheme } from '@mui/material/styles'
import useGaslessEmoteForNft from '../../../hooks/emotable/use-gasless-emote-for-nft'
import ConfettiExplosion from '../ConfettiExplosion'
import { usePostThxEventMutation } from '../../../state/evolv3Api'
import { SUPPORTED_THX_EVENTS } from '../../../lib/constants'

export default function NftEmoteButton({
  sx,
  tokenId,
  tokenContractAddress,
  chainId,
  size = 'small',
  disabled = false,
  onEmojiSelect,
  debounce = 500
}) {
  const theme = useTheme()
  const { account } = useActiveWeb3React()

  const [selectedEmoji, setSelectedEmoji] = useState(null)
  const [shouldShowConfetti, setShouldShowConfetti] = useState(false)
  const buttonRef = useRef() // Reference to the button to get its position
  const [confettiPosition, setConfettiPosition] = useState({ x: 0, y: 0 })
  const { apply, emotes, error, loading } = useGetEmoteForNftByAccount({
    account,
    chainId,
    tokenId,
    tokenContractAddress,
    immediate: false
  })

  useEffectDebounced(
    () => {
      if (chainId && tokenId >= 0 && tokenContractAddress && account) {
        apply()
      }
    },
    [chainId, tokenId, tokenContractAddress, account],
    debounce
  )

  useEffect(() => {
    setSelectedEmoji(null)
  }, [chainId, tokenId, tokenContractAddress, account])

  const {
    apply: gaslessEmote,
    error: errorEmoting,
    loading: loadingEmote,
    success
  } = useGaslessEmoteForNft({
    tokenId,
    tokenContractAddress,
    chainId,
    account,
    state: true,
    immediate: !!selectedEmoji,
    emoji: selectedEmoji
  })

  const [postThxEvent, response] = usePostThxEventMutation()

  useEffect(() => {
    if (!window.THXWidget || !account || !success) return

    postThxEvent({
      userAddress: account,
      event: SUPPORTED_THX_EVENTS.EMOTE_EVOLV3
    }).unwrap()
  }, [success, account])

  const triggerConfetti = () => {
    if (success) {
      setShouldShowConfetti(false)
      setTimeout(() => {
        setShouldShowConfetti(true)
      }, 0)
    }
  }

  useEffect(() => {
    if (errorEmoting) {
      setSelectedEmoji(null)
    }
  }, [errorEmoting])

  useEffect(() => {
    if (!loadingEmote && success) {
      triggerConfetti()
    }
  }, [success, loadingEmote])

  useEffect(() => {
    if (buttonRef.current && shouldShowConfetti) {
      const rect = buttonRef.current.getBoundingClientRect()
      setConfettiPosition({
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2
      })
    }
  }, [shouldShowConfetti])

  const handleEmojiSelect = emoji => {
    setSelectedEmoji(emoji?.native)
    onEmojiSelect && onEmojiSelect(emoji)
  }

  return (
    <>
      <EmojiFabWithPicker
        ref={buttonRef}
        size={size}
        sx={{
          ...sx,
          ...((selectedEmoji || emotes?.length) && {
            backgroundImage: `linear-gradient( 135deg,${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`
          })
        }}
        disabled={disabled}
        emoji={
          selectedEmoji ? selectedEmoji : emotes?.length ? emotes[0] : null
        }
        onEmojiSelect={handleEmojiSelect}
        loading={loading || loadingEmote}
      >
        {shouldShowConfetti && (
          <ConfettiExplosion
            run={shouldShowConfetti && success}
            emoji={selectedEmoji}
            positionX={confettiPosition.x}
            positionY={confettiPosition.y}
            onConfettiExplosionFinish={() => {
              setShouldShowConfetti(false)
            }}
          />
        )}
      </EmojiFabWithPicker>
    </>
  )
}
