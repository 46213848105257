import React from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../../../hooks/web3'
import PaintButton from '../../buttons/paint-button'
import { addPaintToken } from '../../../../lib/actions/add-token'

const PaintActions = () => {
  const { library, account, chainId } = useActiveWeb3React()
  return (
    <Container>
      <PaintButton
        text='Add PAINT token to your wallet'
        onClick={() => {
          addPaintToken(library, chainId)
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  color: ${({ theme }) => theme.palette.text.secondary};
`

export default PaintActions
