import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Popover, IconButton, Badge } from '@mui/material'
import RedeemIcon from '@mui/icons-material/Redeem'
import { THXWidget, THXWidgetOptions } from '@thxnetwork/sdk'
import { useActiveWeb3React } from '../../../hooks/web3'
import StyledTooltip from '../../../js/uicomponents/styled_tooltip'
import {
  useGetThxUserQuery,
  usePostThxEventMutation
} from '../../../state/evolv3Api'
import { SUPPORTED_THX_EVENTS } from '../../../lib/constants'

const POPOVER_WIDTH = 350

const THXWidgetPopover = () => {
  const { account } = useActiveWeb3React()
  const [arrowLeft, setArrowLeft] = useState('50%') // State to hold the calculated left position of the arrow
  const [arrowTop, setArrowTop] = useState('100%') // State to hold the calculated bottom position of the arrow
  const buttonRef = useRef(null) // Ref for the button to calculate its position
  const {
    data: currentThxUserData,
    error: errorFetchingThxUserData,
    isFetching: isFetchingThxUserData
  } = useGetThxUserQuery(
    { address: account },
    {
      skip: !account
    }
  )
  const [postThxEvent, response] = usePostThxEventMutation()
  const [canCheckIn, setCanCheckIn] = useState(false)
  const [checkedIn, setCheckedIn] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(true)

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const initializeTHXWidget = useCallback(node => {
    if (node !== null) {
      const options = {
        campaignId: '65169bcfbbd3487e720ae94e',
        containerSelector: '#thx-widget-container',
        identity: '36d33a59-5398-463a-ac98-0f7d9b201648' // Optional
      }
      THXWidget.create(options)
      setCanCheckIn(true)
    }
  }, [])

  const handleClick = event => {
    const buttonRect = buttonRef.current.getBoundingClientRect()
    // Calculate the center of the button horizontally
    const arrowLeftPosition = buttonRect.left + buttonRect.width / 2
    // Calculate the bottom of the button vertically
    const arrowTopPosition = buttonRect.bottom + 10
    setArrowLeft(`${arrowLeftPosition}px`)
    setArrowTop(`${arrowTopPosition}px`) // New state to hold the top position of the arrow

    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (!window.THXWidget || isFetchingThxUserData || !currentThxUserData)
      return
    try {
      window.THXWidget.setIdentity(currentThxUserData.id)
      console.log('THXWidget user set', currentThxUserData.id)
    } catch (error) {
      console.error('Failed to set THXWidget user', error)
    }
  }, [currentThxUserData, errorFetchingThxUserData, isFetchingThxUserData])

  useEffect(() => {
    if (!account || checkedIn || !canCheckIn) return

    postThxEvent({
      userAddress: account,
      event: SUPPORTED_THX_EVENTS.CHECK_IN
    }).unwrap()
    setCheckedIn(true)
  }, [checkedIn, account, canCheckIn])

  return (
    <>
      <StyledTooltip
        title={'Hi there!👋 Click me to complete quests and earn rewards...'}
        open={tooltipOpen}
        onClose={handleTooltipClose}
      >
        <IconButton
          aria-label="thx-rewards"
          onClick={handleClick}
          ref={buttonRef}
        >
          <Badge color="secondary" variant="dot">
            <RedeemIcon />
          </Badge>
        </IconButton>
      </StyledTooltip>
      <Popover
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          elevation: 0,
          style: { height: '80%', padding: 0 },
          sx: {
            overflow: 'visible',
            bgcolor: '#1b1b1f',
            borderRadius: '10px',
            mt: 1.5,
            '&:before': {
              bgcolor: '#1b1b1f',
              content: '""',
              display: 'block',
              position: 'fixed', // Position the arrow using fixed positioning
              width: 10,
              height: 10,
              transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
              left: arrowLeft, // Set the fixed left position derived from the button
              top: arrowTop, // Set the fixed top position derived from the bottom of the button
              zIndex: 0
            }
          }
        }}
      >
        <div
          id="thx-widget-container"
          ref={initializeTHXWidget}
          style={{
            width: `${POPOVER_WIDTH}px`,
            height: '100%',
            padding: 0,
            margin: 0
          }}
        />
      </Popover>
    </>
  )
}

export default THXWidgetPopover
