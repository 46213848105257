import React from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import configJson from '../../config/notable_artists.json'
import { Typography, Box } from '@mui/material'
import LoadingPlaceholderNftCard from '../js/pages/nfts/loadingPlaceholderNftCard'
import ArtistCard from '../js/pages/nfts/artistCard'
import AddressInput from '../components/common/AddressInput'
import DefaultVirtualizedGrid from '../components/common/DefaultVirtualizedGrid'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
    flexGrow: 1
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}))

const ArtistLandingPage = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={clsx(classes.absoluteFill, classes.root)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <AddressInput
          placeholder={'Search for an artist by address or ENS'}
          onSearchClicked={address => {
            history.push(`/artist/${address}`)
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 300,
            textShadow: '2px 2px 5px black',
            width: '100%',
            p: 2
          }}
          color={'primary.main'}
          align={'center'}
          variant={'h6'}
        >
          FEATURED ARTISTS:
        </Typography>
        <DefaultVirtualizedGrid
          style={{ width: '100%' }}
          items={configJson.artists}
          loadingPlaceholder={<LoadingPlaceholderNftCard />}
          renderItem={(index, artist) => {
            return (
              <ArtistCard
                sx={{ width: '100%' }}
                image={artist.image}
                address={artist.address}
              />
            )
          }}
        />
      </Box>
    </div>
  )
}

export default ArtistLandingPage
