import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Slider from '@mui/material/Slider'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import Popover from '@mui/material/Popover'
import { Box, IconButton, Typography } from '@mui/material'
import clsx from 'clsx'
import { VerticalVolumeSlider } from './VerticalVolumeSlider'

const formatTime = (time) => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.light
  },
  slider: {
    margin: 0,
    padding: 0,
    color: theme.palette.primary.main,
    transform: 'translate(0%, -50%)'
  },
  largeIcon: {
    height: 28,
    width: 28
  },
  videoPlayControls: {
    backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    backgroundClip: 'padding-box',
    borderRadius: '4px'
  }
}))

function StyledPlayControls({
  onPlayPauseClicked,
  disabled,
  onMuteClicked,
  isMuted,
  isPlaying,
  duration,
  currentTime,
  onSeek,
  showDuration,
  volume = 1.0, // you can pass this as a prop if required
  onVolumeChange,
  sx
}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleVolumeIconClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      className={clsx(classes.videoPlayControls)}
      sx={{
        ...sx
      }}
    >
      <IconButton
        aria-label="play/pause"
        onClick={onPlayPauseClicked}
        disabled={disabled}
        sx={{
          px: 0,
          py: 0,
          mr: 1
        }}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <IconButton
        aria-label="mute/unmute"
        onClick={handleVolumeIconClick}
        disabled={disabled}
        sx={{
          px: 0,
          py: 0,
          mr: 1,
          zIndex: 1
        }}
      >
        {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        PaperProps={{
          style: {
            borderRadius: 10,
            padding: 0,
            width: 'fit-content',
            height: 'fit-content'
            // backgroundColor: 'transparent',
            // boxShadow: 'none',
            // backgroundImage: 'none',
          }
        }}
        sx={{
          p: 0,
          m: 0
        }}
      >
        <VerticalVolumeSlider value={volume} onVolumeChange={onVolumeChange} />
      </Popover>
      <Slider
        disabled={disabled}
        className={classes.slider}
        defaultValue={0}
        value={currentTime || 0}
        onChange={onSeek}
        min={0}
        step={0.01}
        max={duration || 0}
        sx={{
          flex: 1,
          px: 0,
          py: 0,
          mx: 0,
          my: 0,
          minWidth: 100,
          '& .MuiSlider-thumb': {
            width: 0,
            height: 0,
            padding: 0,
            margin: 0
          },
          '& .MuiSlider-track': {
            border: 'none',
            height: 8,
            borderRadius: 4
          },
          '& .MuiSlider-rail': {
            border: 'none',
            height: 8,
            borderRadius: 4
          }
        }}
      />
      {showDuration && (
        <Typography
          variant="body2"
          sx={{
            px: 0,
            py: 0,
            ml: 1
          }}
        >
          {formatTime(currentTime)} / {formatTime(duration)}
        </Typography>
      )}
    </Box>
  )
}

export default StyledPlayControls
