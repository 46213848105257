import { createSlice } from '@reduxjs/toolkit'
import { SupportedChain } from '../../lib/constants'

const networkSwitchSlice = createSlice({
  name: 'networkSwitch',
  initialState: { network: SupportedChain.Ethereum },
  reducers: {
    setSelectedNetwork: (state, action) => {
      state.network = action.payload
      return state
    }
  }
})

export const { setSelectedNetwork } = networkSwitchSlice.actions

export default networkSwitchSlice.reducer
