import React, { useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import _ from 'underscore'

import Slider from '@mui/material/Slider'
import Grid from '@mui/material/Grid'
import clsx from 'clsx'
import Collapse from '@mui/material/Collapse'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import FastForwardIcon from '@mui/icons-material/FastForward'
import PauseIcon from '@mui/icons-material/Pause'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import './../../../css/layout.css'
import CrtOverlay from '../../../components/common/images/crt-overlay'
import { Link as RouterLink } from 'react-router-dom'
import EnsAddress from '../../../components/common/wallet/ensAddress'
import StyledPlayControls from '../../../components/common/video/StyledPlayControls'
import { DESCRIPTION_PLACEHOLDER, MURALL_WALL_ID } from '../../../lib/constants'
import { StateActions } from './actions/StateActions'
import EmojiFabWithPicker from '../../../components/common/emoji/EmojiFabWithPicker'
import NftEmojiAvatarGroup from '../../../components/common/emoji/NftEmojiAvatarGroup'
import NftEmoteButton from '../../../components/common/emoji/NftEmoteButton'
import { useActiveWeb3React } from '../../../hooks/web3'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    left: '0px',
    bottom: '0px',
    right: '0px',
    width: '100%',
    zIndex: '99'
  },
  inline: {
    display: 'inline'
  },
  playControlsWrapper: {
    marginTop: '-20px',
    paddingTop: '20px',
    overflow: 'hidden',
    maxWidth: '100%'
  },
  playControlsContainer: {
    padding: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    borderTop: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px',
    width: '100%',
    zIndex: '99'
  },
  slider: {
    margin: 0,
    padding: 0,
    color: theme.palette.secondary.main,
    transform: 'translate(0%, -50%)'
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(270deg)'
  },
  controlsExpandMore: {
    marginBottom: '25px'
  },
  controlsExpandLess: {
    marginBottom: '8px'
  },
  gridRootContainer: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0
  },
  defaultSidePadding: {
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  gridColumnContainer: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column'
  },
  gridRowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  largeIcon: {
    height: 28,
    width: 28
  },
  controlsExpandIcon: {
    padding: '26px',
    alignSelf: 'center',
    position: 'absolute',
    top: 0,
    right: '50%',
    transform: 'translate(50%, calc(-100% - 8px))'
  },

  backgroundImage: {
    backgroundSize: 'cover',
    backgroundClip: 'padding-box',
    backgroundPosition: 'center center',
    backgroundBlendMode: `normal, normal`
  }
}))

export default function HistoryNavigationControlsMobile(props) {
  const { account } = useActiveWeb3React()
  const classes = useStyles()
  const [settingsExpanded, setSettingsExpanded] = useState(false)

  const handleSettingsExpandClick = (event) => {
    setSettingsExpanded(!settingsExpanded)
  }

  const constructPlayControls = () => {
    return props.singleTokenMode ? null : (
      <div
        className={clsx(classes.gridRowContainer, classes.defaultSidePadding)}
        style={{
          justifyContent: 'space-between',
          paddingBottom: '6px',
          paddingTop: '12px'
        }}
      >
        <IconButton aria-label="previous" onClick={props.onSkipPreviousClicked} size="large">
          <SkipPreviousIcon />
        </IconButton>
        <IconButton aria-label="previous" onClick={props.onFastRewindClicked} size="large">
          <FastRewindIcon />
        </IconButton>
        <IconButton aria-label="play/pause" onClick={props.onPlayPauseClicked} size="large">
          {props.isPlaying ? (
            <PauseIcon className={clsx(classes.largeIcon)} />
          ) : (
            <PlayArrowIcon className={clsx(classes.largeIcon)} />
          )}
        </IconButton>
        <IconButton aria-label="next" onClick={props.onFastForwardClicked} size="large">
          <FastForwardIcon />
        </IconButton>
        <IconButton aria-label="next" onClick={props.onSkipNextClicked} size="large">
          <SkipNextIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: settingsExpanded
          })}
          onClick={handleSettingsExpandClick}
          aria-expanded={settingsExpanded}
          aria-label="settings"
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      </div>
    )
  }

  const constructInfoView = () => {
    return (
      <Grid item xs={12}>
        {!_.isUndefined(props.currentSelection) && (
          <div
            className={clsx(classes.gridRowContainer, classes.defaultSidePadding)}
            style={{
              zIndex: 1,
              flex: 1,
              paddingBottom: '24px',
              paddingTop: '24px'
            }}
          >
            <div
              className={clsx(classes.gridColumnContainer)}
              style={{
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                alignSelf: 'flex-start',
                width: '100%',
                textOverflow: 'ellipsis'
              }}
            >
              <Typography
                component="h4"
                variant="h4"
                noWrap
                className={clsx(classes.typography, 'crt-text')}
                color={
                  !props.currentSelection.name || props.currentSelection.name === '' ? 'textSecondary' : 'textPrimary'
                }
                sx={{
                  fontStyle: props.currentSelection.name ? 'normal' : 'italic'
                }}
              >
                {!props.currentSelection.name || props.currentSelection.name === ''
                  ? 'Untitled'
                  : props.currentSelection.name}
              </Typography>

              <Typography
                component="span"
                variant="h6"
                noWrap
                className={clsx(classes.inline, classes.typography, 'crt-text')}
                color="textPrimary"
                sx={{
                  textOverflow: 'ellipsis',
                  fontStyle: props.currentSelection?.description !== DESCRIPTION_PLACEHOLDER ? 'normal' : 'italic'
                }}
              >
                {props.wallId === MURALL_WALL_ID.EVOLV3
                  ? props.currentSelection.description
                  : props.currentSelection.description ||
                    `${props.currentSelection.number}  of Series ${props.currentSelection.seriesId}`}
              </Typography>

              <EnsAddress
                titleProps={{
                  component: RouterLink,
                  to: '/artist/' + props.currentSelection.artist,
                  underline: 'hover',
                  variant: 'subtitle2',
                  noWrap: true,
                  className: clsx(classes.typography, classes.inline, 'crt-text'),
                  color: 'textSecondary'
                }}
                short
              />
              <Typography
                component="span"
                variant="subtitle2"
                className={clsx(classes.typography, 'crt-text')}
                color="textPrimary"
              >
                Token #{props.currentSelection.tokenId}
              </Typography>

              {props.wallId === MURALL_WALL_ID.EVOLV3 && (
                <>
                  <NftEmojiAvatarGroup
                    chainId={props.currentSelection.chainId}
                    tokenContractAddress={props.currentSelection.contractAddress}
                    tokenId={props.currentSelection.tokenId}
                    logoStyle={{ position: 'relative' }}
                    sx={{ alignSelf: 'flex-start' }}
                    debounce={props.singleTokenMode ? 0 : 250}
                  />
                  <StyledPlayControls
                    sx={{
                      width: '100%',
                      marginTop: '16px',
                      zIndex: 1
                    }}
                    disabled={!props.currentSelection.animation_url}
                    currentTime={props.videoCurrentTime}
                    duration={props.videoDuration}
                    isPlaying={!props.videoPaused}
                    onPlayPauseClicked={props.onVideoPlayPauseClicked}
                    onSeek={props.onVideoSeek}
                    onMuteClicked={props.onVideoMuteClicked}
                    isMuted={props.videoMuted}
                    volume={props.videoVolume}
                    onVolumeChange={props.onVideoVolumeChange}
                    showDuration
                  />
                </>
              )}
            </div>
            <StateActions
              style={{
                flex: 1,
                marginRight: '16px',
                marginBottom: '16px',
                justifyContent: 'flex-end',
                position: 'absolute',
                right: 0,
                bottom: props.wallId === MURALL_WALL_ID.EVOLV3 ? '48px' : 0
              }}
              onInfoButtonClicked={props.onInfoButtonClicked}
              onLocateButtonClicked={props.onLocateButtonClicked}
              onOpenDetailsClicked={props.onOpenDetailsClicked}
            />
          </div>
        )}
      </Grid>
    )
  }
  const constructSettings = () => {
    return (
      <div
        className={clsx(classes.gridRowContainer, classes.defaultSidePadding)}
        style={{ justifyContent: 'space-evenly', marginBottom: '12px' }}
      >
        <TextField
          style={{ flex: 1, marginRight: '24px' }}
          onChange={props.onFadeDurationChanged}
          id="standard-helperText"
          label="Transition duration"
          value={props.fadeDurationPlaying}
          helperText={'in milliseconds'}
          inputProps={{
            step: 50,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
        <TextField
          style={{ flex: 1 }}
          onChange={props.onDurationBetweenStatesChanged}
          id="standard-helperText"
          label="Delay between states"
          value={props.durationBetweenStates}
          helperText={'in milliseconds'}
          inputProps={{
            step: 50,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
      </div>
    )
  }

  const bgImageForCurrentSelection = () => {
    return _.isUndefined(props.currentSelection)
      ? null
      : `radial-gradient(circle at top right,
                                #26262B80,
                                #26262B 80%),
                                url(${props.image ? props.image : props.currentSelection.image})`
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="show/hide play controls"
        onClick={props.onExpandNavigationControlsIconClicked}
        className={clsx(
          classes.controlsExpandIcon,
          classes.largeIcon,
          {
            [classes.controlsExpandMore]: props.open
          },
          {
            [classes.controlsExpandLess]: !props.open
          }
        )}
        size="medium"
      >
        {props.open ? (
          <ExpandMore className={clsx(classes.largeIcon)} />
        ) : (
          <ExpandLess className={clsx(classes.largeIcon)} />
        )}
      </IconButton>
      <Collapse in={props.open} mountOnEnter unmountOnExit>
        <div className={clsx(classes.playControlsWrapper)}>
          <Paper
            elevation={0}
            className={clsx(classes.playControlsContainer, 'pixelated-image', {
              [classes.backgroundImage]: !_.isUndefined(props.currentSelection)
            })}
            style={{ backgroundImage: bgImageForCurrentSelection() }}
          >
            <CrtOverlay
              grain
              refresh
              style={{
                zIndex: '0',
                position: 'absolute',
                width: '100%',
                height: '100%'
              }}
            />
            <Grid
              className={classes.gridRootContainer}
              container
              spacing={0}
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <div
                  className={classes.gridColumnContainer}
                  style={{
                    zIndex: '1'
                  }}
                >
                  {!props.singleTokenMode && (
                    <Slider
                      className={classes.slider}
                      defaultValue={0}
                      value={props.currentSelectedState}
                      onChange={props.onSliderChange}
                      step={1}
                      min={props.rangeStart}
                      max={props.rangeEnd}
                    />
                  )}
                  {constructPlayControls()}
                  <Collapse in={settingsExpanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                    {constructSettings()}
                  </Collapse>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginLeft: '24px', marginRight: '24px' }} />
              </Grid>
              {constructInfoView()}
              {props.wallId === MURALL_WALL_ID.EVOLV3 && props.currentSelection && account && (
                <NftEmoteButton
                  chainId={props.currentSelection.chainId}
                  tokenContractAddress={props.currentSelection.contractAddress}
                  tokenId={props.currentSelection.tokenId}
                  size="medium"
                  sx={{
                    position: 'absolute',
                    right: '24px',
                    m: 0,
                    top: '-24px',
                    zIndex: 999
                  }}
                  debounce={props.singleTokenMode ? 0 : 500}
                />
              )}
            </Grid>
          </Paper>
        </div>
      </Collapse>
    </div>
  )
}
