import React from 'react'
import NftCard from './nftCard'
import PaginatedNftGridView from './paginated_nft_gridview'
import { useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../../hooks/web3'
import { getMurAllNFTContractAddress } from '../../modules/blockchain/datasource/ContractAddressDataSource'
import { useMurAllBridgedNftDataSource } from '../../../hooks/use-murall-bridged-nft-datasource'

export default function ViewBridgedMurAllNfts () {
  const network = useSelector(state => state.network)
  const { account } = useActiveWeb3React()
  const {
    getBalanceForAddress,
    getNftDataForAccountForOwnedTokenInIndexRange
  } = useMurAllBridgedNftDataSource()

  return (
    <>
      {network.id && (
        <PaginatedNftGridView
          itemsPerPage={9}
          onFetchNftBalance={() => {
            return getBalanceForAddress(account)
          }}
          onFetchNftsInIndexRange={(startIndex, endIndex) => {
            return getNftDataForAccountForOwnedTokenInIndexRange(
              account,
              startIndex,
              endIndex
            )
          }}
          constructNftView={currentNft => {
            return (
              <NftCard
                key={currentNft.tokenId}
                address={getMurAllNFTContractAddress(network.id)}
                nftInformation={currentNft}
              />
            )
          }}
        />
      )}
    </>
  )
}
