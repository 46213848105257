import { useEffect, useState } from 'react'
import { useEthersContractForAddress } from '../use-ethers-contract.js'
import MurAllContractAbiL1 from '../../contracts/layer1/MurAll.json'
import MurAllContractAbiL2 from '../../contracts/layer2/MurAll.json'
import { MURALL_WALL, MURALL_WALL_ID } from '../../lib/constants.js'

const useListenForPaintedEvents = ({ wallId, active = true }) => {
  const wallInfo = MURALL_WALL[wallId]
  const [paintedEvents, setPaintedEvents] = useState(undefined)

  const contractInstance = useEthersContractForAddress({
    abi: wallInfo.id === MURALL_WALL_ID.LAYER_1 ? MurAllContractAbiL1.abi : MurAllContractAbiL2.abi,
    websocketInstance: true,
    contractAddress: wallInfo.murAllContractAddress,
    targetChainId: wallInfo.chainId
  })

  useEffect(() => {
    console.log(`useListenForPaintedEvents for ${wallInfo.name} - paintedEvents updated`, paintedEvents)
  }, [paintedEvents])

  useEffect(() => {
    if (!active) return
    if (!contractInstance) return

    const filter = contractInstance.filters.Painted()

    const listener = (...args) => {
      const event = args[args.length - 1]
      console.log(`useListenForPaintedEvents for ${wallInfo.name}  - listener received event`, event)

      setPaintedEvents((rs) => [event, ...(rs || [])])
    }

    const subscribe = async () => {
      contractInstance.on(filter, listener)
    }

    subscribe()
    return () => contractInstance.off(filter, listener)
  }, [contractInstance, active])

  return paintedEvents
}

export default useListenForPaintedEvents
