export const fetchImage = async url => {
  const image = new Image()
  if (!url) return image
  image.crossOrigin = 'Anonymous'
  image.src = url

  try {
    await image.decode()
    return image
  } catch (error) {
    console.error('Failed to fetch image: ', error)
    return new Image()
  }
}

export default fetchImage
