import React, { useState } from 'react'
import config from '../../config'
import NftBundleCard from './nftBundleCard'
import PaginatedNftGridView from './paginated_nft_gridview'
import Get_nft_bundle_data_for_owned_tokens_at_index_range_usecase from '../../modules/blockchain/usecase/get_nft_bundle_data_for_owned_tokens_at_index_range_usecase'
import Get_erc721_balance_for_user_account_usecase from '../../modules/blockchain/usecase/get_erc721_balance_for_user_account_usecase'
import Unbundle_nfts_for_user_account_usecase from '../../modules/blockchain/usecase/unbundle_nfts_for_user_account_usecase'
import notification from '../../../lib/notification'
import { useSelector } from 'react-redux'

export default function ViewNftBundles (props) {
  const network = useSelector(state => state.network)
  const getErc721BalanceForUserAccountUsecase = new Get_erc721_balance_for_user_account_usecase()
  const getNftBundleDataInIndexRangeUsecase = new Get_nft_bundle_data_for_owned_tokens_at_index_range_usecase()

  const [isFetching, setIsFetching] = useState(false)

  const handleSellClick = async (address, tokenId) => {
    const isL2 = network.isLayer2
    if (isL2) {
      window.open(
        `https://opensea.io/assets/matic/${address}/${tokenId}`,
        '_blank'
      )
    } else {
      window.open(`https://opensea.io/assets/${address}/${tokenId}`, '_blank')
    }
  }

  return (
    <PaginatedNftGridView
      isLoading={isFetching}
      itemsPerPage={9}
      onFetchNftBalance={() => {
        return getErc721BalanceForUserAccountUsecase.execute(
          config.nftBundlerContractAddress
        )
      }}
      onFetchNftsInIndexRange={(startIndex, endIndex) => {
        return getNftBundleDataInIndexRangeUsecase.execute(
          config.nftBundlerContractAddress,
          startIndex,
          endIndex
        )
      }}
      constructNftView={nftBundle => {
        return (
          <NftBundleCard
            key={nftBundle.tokenId}
            address={config.nftBundlerContractAddress}
            nftInformation={nftBundle}
            onSellButtonClick={handleSellClick}
            onUnbundleButtonClick={async bundleId => {
              setIsFetching(true)
              try {
                await new Unbundle_nfts_for_user_account_usecase().execute(
                  config.nftBundlerContractAddress,
                  bundleId
                )
                notification.success(
                  `Successfully unbundled bundle #${bundleId}`
                )
              } catch (error) {
                if (error.message && error.message.indexOf('User denied') != -1) {
                  notification.error(
                    'You rejected the transaction on Metamask!'
                  )
                } else {
                  notification.error(
                    `Unbundle failed for bundle id ${bundleId}`
                  )
                }
                console.error(error)
              }
              setIsFetching(false)
            }}
          />
        )
      }}
    />
  )
}
