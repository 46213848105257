import React from 'react'
import ErrorMessageView from './error_message_view'
import { Grid, Typography, Link,  Icon } from '@mui/material'
import { PaintPlusIcon } from '../../components/common/wallet/paint-icon'
import { Link as RouterLink } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import RoundedButton from '../../components/common/buttons/rounded-button'

export default function EmptyNftsMessageView () {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <ErrorMessageView
      title={'You do not own any MurAll NFTs!'}
      description={'Try drawing something or go to the marketplace'}
    >
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        spacing={1}
        alignItems={'center'}
        sx={{
          mt: 2,
          width: 'auto'
        }}
      >
        <Grid item>
          <RoundedButton
            variant='text'
            aria-label='close'
            sx={{
              color: 'inherit',
              fontSize: 'inherit',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
            endIcon={
              <Icon style={{ textAlign: 'center' }}>
                <img src={'images/logos/opensea_blue.svg'} alt={'opensea'} />
              </Icon>
            }
            component={Link}
            href='https://opensea.io/collection/murall'
            target='_blank'
          >
            <Typography variant='body1'>Buy on OpenSea</Typography>
          </RoundedButton>
        </Grid>
        <Grid item>
          <RoundedButton
            variant='text'
            aria-label='close'
            sx={{
              color: 'inherit',
              fontSize: 'inherit',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
            endIcon={<PaintPlusIcon />}
            component={RouterLink}
            to='/draw'
          >
            <Typography variant='body1'>Draw on MurAll</Typography>
          </RoundedButton>
        </Grid>
      </Grid>
    </ErrorMessageView>
  )
}


