import React from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'

import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { CHAIN_INFO } from '../../lib/constants'
import NetworkSwitchButton from '../../components/common/buttons/network-switch-button'
import WarningIcon from '@mui/icons-material/Warning'

const useStyles = () =>
  makeStyles(theme => ({
    root: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '344px !important'
      }
    },

    card: {
      borderRadius: 10,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
      border: 'solid',
      borderColor: 'black',
      borderWidth: '0px',
      transition: 'all 0.3s'
    },

    typography: {},
    actionRoot: {
      padding: '8px 8px 8px 16px'
    }
  }))

const NetworkMismatchSnackMessage = ({ targetChainId }) => {
  const classes = useStyles()()

  const targetChainName = CHAIN_INFO[targetChainId]?.label
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          avatar={<WarningIcon />}
          action={<NetworkSwitchButton targetChainId={targetChainId} />}
          title={
            <Typography
              className={clsx(classes.typography)}
              sx={{
                mr: 2
              }}
            >
              {'Wrong network - select ' +
                targetChainName +
                ' Mainnet network in your wallet'}
            </Typography>
          }
        />
      </Card>
    </div>
  )
}

export default NetworkMismatchSnackMessage
