import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import AlchemyLogo from './alchemy-logo'
import ExternalLink from '../links/external'
import InternalLink from '../links/internal'
import PulseBorder from '../pulse-border'

import TwitterIcon from '@mui/icons-material/Twitter'
import DiscordLogo from '../../../../images/logos/discord.svg'
import MediumLogo from '../../../../images/logos/medium.svg'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon from '@mui/icons-material/Instagram'
import TelegramIcon from '@mui/icons-material/Telegram'

const Footer = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <footer>
      <Border />
      <Box px={5} pt={5}>
        <Container maxWidth='lg'>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4}>
              {!isMobile && <SectionTitle title='Info' />}
              <LinkWrapper isMobile={isMobile}>
                <InternalLink path='/about' text='About' hideIcon />
              </LinkWrapper>
              <LinkWrapper isMobile={isMobile}>
                <InternalLink path='/faq' text='FAQ' hideIcon />
              </LinkWrapper>
              <LinkWrapper isMobile={isMobile}>
                <ExternalLink
                  text='Knowledgebase'
                  href='https://knowledgebase.murall.art'
                />
              </LinkWrapper>
              <LinkWrapper isMobile={isMobile}>
                <ExternalLink
                  text='Litepaper'
                  href='https://murall.art/docs/LitePaper.pdf'
                />
              </LinkWrapper>
            </Grid>
            <Grid item xs={12} sm={4}>
              {!isMobile && <SectionTitle title='Follow Us' />}
              <SocialsWrapper>
                <ExternalLink
                  href='https://twitter.com/MurAll_art'
                  icon={<TwitterIcon mx={0.5} />}
                />
                <ExternalLink
                  href='https://discord.murall.art'
                  icon={<CustomSocialIcon src={DiscordLogo} alt='Discord' />}
                />
                <ExternalLink
                  href='https://murall.medium.com'
                  icon={<CustomSocialIcon src={MediumLogo} alt='Medium' />}
                />
                <ExternalLink
                  href='https://www.instagram.com/murall.art/'
                  icon={<InstagramIcon mx={0.5} />}
                />
                <ExternalLink
                  href='https://www.youtube.com/channel/UCrEFkjpHY5YojV4px7kGcZQ'
                  icon={<YouTubeIcon mx={0.5} />}
                />
                <ExternalLink
                  href='https://t.me/MurALLart'
                  icon={<TelegramIcon mx={0.5} />}
                />
              </SocialsWrapper>
            </Grid>
            <Grid item xs={12} sm={4} textAlign='center'>
              <AlchemyLogo />
            </Grid>
          </Grid>
          <Box textAlign='center' pt={2} pb={1} color='primary.gray'>
            MurAll &reg; {new Date().getFullYear()}
          </Box>
        </Container>
      </Box>
    </footer>
  )
}

const SectionTitle = ({ title }) => {
  return (
    <Box
      borderBottom={1}
      pb={1}
      mb={1}
      color='primary.gray'
      borderColor='primary.main'
    >
      {title}
    </Box>
  )
}

const CustomSocialIcon = ({ src, alt }) => {
  return (
    <Icon mr={1} ml={0.5}>
      <img src={src} alt={alt} />
    </Icon>
  )
}

const LinkWrapper = styled(Box)`
  width: min-content;
  margin: ${({ isMobile }) => (isMobile ? '0 auto' : 0)};
`

const SocialsWrapper = styled(Box)`
  display: flex;
`

const Border = styled(PulseBorder)`
  bottom: auto;
`

export default Footer
