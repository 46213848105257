import { useContext, useMemo } from 'react'
import Web3 from 'web3'
import { PAINT_TOKEN_ADDRESS, SupportedChain } from '../lib/constants'
import { useErc20DataSource } from './use-erc20'

const INITIAL_SUPPLY = '22020096000000000000000000000'
// returns null on errors

export const usePaintDataSource = chainId => {
  const contract = useErc20DataSource(PAINT_TOKEN_ADDRESS[chainId], chainId)

  return useMemo(() => {
    return {
      getBalanceForAddress: address => {
        return contract?.getBalanceForAddress(address)
      },
      getTotalSupply: () => {
        return contract?.getTotalSupply()
      },
      name: () => {
        return contract?.name()
      },
      symbol: () => {
        return contract?.symbol()
      },
      decimals: () => {
        return contract?.decimals()
      },
      getTokenInformation: () => {
        return contract?.getTokenInformation()
      },
      isTokenTransferApprovedForAmount: async (
        senderAddress,
        spenderAddress,
        amount
      ) => {
        return contract?.isTokenTransferApprovedForAmount(
          senderAddress,
          spenderAddress,
          amount
        )
      },
      transferAmountToAddress: (senderAddress, recipientAddress, amount) => {
        return contract?.transferAmountToAddress(
          senderAddress,
          recipientAddress,
          amount
        )
      },
      approveTokenTransfer: (senderAddress, spenderAddress, amount) => {
        return contract?.approveTokenTransfer(
          senderAddress,
          spenderAddress,
          amount
        )
      },
      maxAllowance: () => {
        return contract?.maxAllowance()
      },
      initialSupply: () => {
        return Web3.utils.toBN(INITIAL_SUPPLY)
      }
    }
  }, [contract])
}
