import React, { useEffect } from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import SectionMurAll from './sections/SectionMurAll'
import SectionMurAllRevo1tDi2rupt from './sections/SectionMurAllRevo1tDi2rupt'
import SectionDrawingOnMurAll from './sections/SectionDrawingOnMurAll'
import SectionPaintToken from './sections/SectionPaintToken'
import SectionMurAllNfts from './sections/SectionMurAllNfts'
import SectionMontage from './sections/SectionMontage'
import SectionMurAllEvolv3Nfts from './sections/SectionMurAllEvolv3Nfts'
import SectionMurAllEvolv3 from './sections/SectionMurAllEvolv3'
import Container from '@mui/material/Container'
import SectionGovernance from './sections/SectionGovernance'

const FaqPage = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const theme = useTheme()

  return (
    <RootContainer
      style={{
        paddingLeft: isMobile ? 0 : theme.spacing(6),
        paddingRight: isMobile ? 0 : theme.spacing(6),
        paddingBottom: theme.spacing(6)
      }}
    >
      <Container maxWidth='md'>
        <SectionMurAll />
        <SectionMurAllEvolv3 />
        <SectionMurAllRevo1tDi2rupt />
        <SectionDrawingOnMurAll />
        <SectionPaintToken />
        <SectionMurAllEvolv3Nfts />
        <SectionMurAllNfts />
        <SectionMontage />
        <SectionGovernance />
      </Container>
    </RootContainer>
  )
}

const RootContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
`

export default FaqPage
