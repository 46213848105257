import { useMemo } from 'react'
import { ethers } from 'ethers'

const BASE_PATH =
  'https://api.thegraph.com/subgraphs/name/murall-art/murall-evolv3-statistics'

export const useEvolv3StatisticsDataSource = () => {
  return useMemo(() => {
    const basePath = BASE_PATH
    return {
      currentStatistics: async () => {
        const result = await fetch(basePath, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: `query {
                            artistStatistics(first: 1) {
                              totalArtists
                              totalArtworks
                            }
                            paintTokens(first: 1) {
                              totalPaintBurned
                            }
                          }`
          })
        })

        const resultJson = await result.json()

        const totalArtists =
          resultJson?.data?.artistStatistics?.[0]?.totalArtists ?? 0
        const totalArtworks =
          resultJson?.data?.artistStatistics?.[0]?.totalArtworks ?? 0
        resultJson?.data?.artistStatistics?.[0]?.totalArtists ?? 0
        const totalPaintBurned =
          resultJson?.data?.paintTokens?.[0]?.totalPaintBurned ?? 0
        return {
          totalArtists,
          totalArtworks,
          totalPaintBurned: ethers.BigNumber.from(totalPaintBurned)
        }
      },
      topArtists: async (count = 5) => {
        const result = await fetch(basePath, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: `query {
                            artists(first: ${count}, orderBy: artworksCreated, orderDirection: desc) {
                              id
                              artworksCreated
                              paintBurned
                            }
                          }`
          })
        })

        const resultJson = await result.json()

        const artists = resultJson?.data?.artists ?? []
        return artists.map(e => {
          return {
            address: e.id,
            artworksCreated: e.artworksCreated,
            paintBurned: ethers.BigNumber.from(e.paintBurned)
          }
        })
      },
      topPaintBurners: async (count = 5) => {
        const result = await fetch(basePath, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: `query {
                            artists(first: ${count}, orderBy: paintBurned, orderDirection: desc) {
                              id
                              artworksCreated
                              paintBurned
                            }
                          }`
          })
        })

        const resultJson = await result.json()

        const artists = resultJson?.data?.artists ?? []
        return artists.map(e => {
          return {
            address: e.id,
            artworksCreated: e.artworksCreated,
            paintBurned: ethers.BigNumber.from(e.paintBurned)
          }
        })
      }
    }
  }, [])
}
