import React from 'react'
import PaintedEventSnackMessage from '../js/uicomponents/painted_event_notification'
import toast from 'react-hot-toast'
import NetworkMismatchSnackMessage from '../js/uicomponents/network_mismatch_notification'
import evolv3Theme from '../theme/evolv3'
import layer1Theme from '../theme/layer1'
import layer2Theme from '../theme/layer2'
import { MURALL_WALL, MURALL_WALL_ID } from './constants'
import { ThemeProvider } from '@mui/material/styles'

const defaultStyle = {
  maxWidth: '500px',
  wordBreak: 'break-word',
  background: '#333',
  color: '#fff'
}

export const success = (message, { duration = 2000 } = {}) => {
  return toast.success(message, { style: defaultStyle, duration })
}

export const error = (
  message,
  { duration = 2000, showRasterize = false } = {}
) => {
  return toast.error(message, { style: defaultStyle, showRasterize, duration })
}

export const warning = (message, { duration = 4000 } = {}) => {
  return toast(message, { icon: '⚠️', style: defaultStyle, duration })
}

export const info = (message, { duration = 4000 } = {}) => {
  return toast(message, { icon: 'ℹ️', style: defaultStyle, duration })
}

export const promise = (
  promiseFunction,
  messages,
  { duration = 0, id } = {}
) => {
  return toast.promise(
    promiseFunction,
    {
      loading: messages.loading,
      success: messages.success,
      error: messages.error
    },
    { style: defaultStyle, duration, ...(id && { id }) }
  )
}

export const paintedEvent = (event, duration = 6000) => {
  const wallInfo = MURALL_WALL[event.wallId]
  const themeForWall =
    wallInfo.id === MURALL_WALL_ID.EVOLV3
      ? evolv3Theme
      : wallInfo.id === MURALL_WALL_ID.LAYER_1
      ? layer1Theme
      : layer2Theme
  return toast.custom(
    t => (
      <ThemeProvider theme={themeForWall}>
        <PaintedEventSnackMessage
          event={event}
          onClose={() => toast.remove(t.id)}
        />
      </ThemeProvider>
    ),
    {
      position: 'bottom-right',
      duration: duration,
      id: event.tokenId.toString()
    }
  )
}

export const wrongNetwork = targetChainId => {
  return toast.custom(
    t => <NetworkMismatchSnackMessage targetChainId={targetChainId} />,
    { style: defaultStyle, duration: 4000 }
  )
}

export default {
  success,
  error,
  warning,
  info,
  promise,
  paintedEvent,
  wrongNetwork
}
