import React, { useEffect, useState } from 'react'
import { EVOLV3_FILETYPES } from '../../../../lib/constants'
import Evolv3MintDetailsInputDialog from './evolv3MintDetailsInputDialog'
import StyledDialog from '../../../uicomponents/styled_dialog'
import { Typography, Box, Button } from '@mui/material'
import { useGetFileUploadEndpointDetailsQuery } from '../../../../state/evolv3Api'
import LayerPreviewDialog from '../../../../components/common/layers/LayerPreviewDialog'
import IndeterminateLoadingMessage from '../../../../components/common/IndeterminateLoadingMessage'
import LoadingDialog from '../../../uicomponents/loading_dialog'
import useGetFileFromLayer from '../../../../hooks/layers/use-get-file-from-layer'
import CongratulationsDialog from '../../../../components/common/CongratulationsDialog'
import SendTweetButton from '../../../../components/common/socials/send-tweet-button'
import Evolv3MintStepper from './evolv3MintStepper'
import ArrowButton from '../../../../components/common/buttons/arrow-button'
const Evolv3MintManager = ({
  startMintFlow = false,
  onCancelClicked,
  onMintFinished
}) => {
  const [
    showEvolv3MetadataInputDialog,
    setShowEvolv3MetadataInputDialog
  ] = useState(false)
  const [showEvolv3MintDialog, setShowEvolv3MintDialog] = useState(false)
  const [showCongratulationsDialog, setShowCongratulationsDialog] = useState(
    false
  )
  const [evolv3MintData, setEvolv3MintData] = useState(null)
  const [mintedTokenId, setMintedTokenId] = useState(null)
  const [proceedToMint, setProceedToMint] = useState(false)
  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false)

  const {
    data: currentFileUploadEndpointData,
    error: errorFetchingUploadEndpoint,
    isFetching: isFetchingUploadEndpoint,
    refetch
  } = useGetFileUploadEndpointDetailsQuery(
    {},
    {
      skip: !proceedToMint
    }
  )

  const {
    file: fileToUpload,
    loading: loadingFileFromLayer
  } = useGetFileFromLayer(evolv3MintData?.selectedLayer)

  useEffect(() => {
    if (proceedToMint && !isFetchingUploadEndpoint) {
      console.log(
        'proceedToMint clicked - refetching endpoint info',
        proceedToMint
      )
      refetch()
    }
  }, [proceedToMint])

  useEffect(() => {
    if (startMintFlow) {
      setShowEvolv3MetadataInputDialog(true)
    } else {
      setShowEvolv3MetadataInputDialog(false)
      setShowEvolv3MintDialog(false)
      setEvolv3MintData(null)
    }
    setProceedToMint(false)
  }, [startMintFlow])

  return (
    <>
      {showEvolv3MetadataInputDialog && (
        <Evolv3MintDetailsInputDialog
          open={showEvolv3MetadataInputDialog}
          mintData={evolv3MintData}
          onDataChanged={data => {
            setEvolv3MintData(data)
          }}
          onCancelClicked={() => {
            setProceedToMint(false)
            setShowEvolv3MintDialog(false)
            setShowEvolv3MetadataInputDialog(false)
            setEvolv3MintData(null)
            onCancelClicked && onCancelClicked()
          }}
          onMintClicked={data => {
            setShowEvolv3MetadataInputDialog(false)
            setShowEvolv3MintDialog(true)
            if (data === evolv3MintData && errorFetchingUploadEndpoint) {
              refetch()
            }
            setProceedToMint(true)
          }}
        >
          {errorFetchingUploadEndpoint && (
            <Typography color='error'>
              Error fetching upload endpoint. Please try again.
            </Typography>
          )}
        </Evolv3MintDetailsInputDialog>
      )}

      {isFetchingUploadEndpoint && (
        <LoadingDialog open={isFetchingUploadEndpoint}>
          <IndeterminateLoadingMessage message='Getting things ready...' />
        </LoadingDialog>
      )}
      {showEvolv3MintDialog &&
        evolv3MintData &&
        proceedToMint &&
        currentFileUploadEndpointData &&
        !isFetchingUploadEndpoint && (
          <LayerPreviewDialog
            open={showEvolv3MintDialog}
            withCloseButton={false}
            layer={evolv3MintData.selectedLayer}
            title={
              <ArrowButton
                onClick={() => {
                  // confirm cancel
                  setShowConfirmCancelDialog(true)
                }}
                variant='text'
                size='large'
                arrowPosition='start'
                sx={{ mr: '16px', mb: '16px' }}
              >
                Back
              </ArrowButton>
            }
          >
            <Evolv3MintStepper
              nftFileToUpload={fileToUpload}
              paintUsage={evolv3MintData.paintUsage}
              nftUploadEndpoint={currentFileUploadEndpointData.url}
              nftUploadEndpointFilePath={currentFileUploadEndpointData.filePath}
              nftName={evolv3MintData.name}
              nftDescription={evolv3MintData.description || ''}
              nftPosition={[
                evolv3MintData.position?.x?.toFixed(0) || 0,
                evolv3MintData.position?.y?.toFixed(0) || 0
              ]}
              nftDimensions={[
                evolv3MintData.dimensions?.width?.toFixed(0) || 0,
                evolv3MintData.dimensions?.height?.toFixed(0) || 0
              ]}
              nftFileType={
                evolv3MintData.selectedLayer?.type === 'video'
                  ? EVOLV3_FILETYPES.VIDEO
                  : EVOLV3_FILETYPES.IMAGE
              }
              nftAttributeKeys={
                evolv3MintData.attributes?.map(attribute => attribute.name) ||
                []
              }
              nftAttributeValues={
                evolv3MintData.attributes?.map(attribute => attribute.value) ||
                []
              }
              metaTransaction={true}
              onMintSuccess={data => {
                console.log('onMintSuccess', data)
                setShowCongratulationsDialog(true)
                setShowEvolv3MintDialog(false)
                setMintedTokenId(data?.tokenId)
              }}
            />
          </LayerPreviewDialog>
        )}
      {showCongratulationsDialog &&
        evolv3MintData &&
        evolv3MintData.selectedLayer &&
        mintedTokenId && (
          <CongratulationsDialog
            open={showCongratulationsDialog}
            layer={evolv3MintData.selectedLayer}
            title={'Minted!'}
            message={'Your artwork is now on Evolv3!'}
            onClose={() => {
              setEvolv3MintData(null)
              setShowCongratulationsDialog(false)
              onMintFinished && onMintFinished()
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', pt: '1em' }}>
              <SendTweetButton
                title={'Share on '}
                tweetContents={`I just minted my artwork onto the MurAll Evolv3! Check it out here: https://murall.art/murall/evolv3/${mintedTokenId}`}
                tweetUrl={`https://murall.art/murall/evolv3/${mintedTokenId}`}
                size='large'
                variant='contained'
              />
            </Box>
          </CongratulationsDialog>
        )}
      {showConfirmCancelDialog && (
        <StyledDialog
          open={showConfirmCancelDialog}
          dialogTitle={'Cancel Minting?'}
          dialogContent={() => (
            <Typography>Are you sure you want to cancel?</Typography>
          )}
          positiveButtonTitle={'Yes'}
          negativeButtonTitle={'No'}
          onPositiveButtonClick={() => {
            setProceedToMint(false)
            setShowConfirmCancelDialog(false)
            setShowEvolv3MintDialog(false)
            setShowEvolv3MetadataInputDialog(true)
          }}
          onNegativeButtonClick={() => {
            setShowConfirmCancelDialog(false)
          }}
        />
      )}
    </>
  )
}

export default Evolv3MintManager
