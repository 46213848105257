import { useMemo } from 'react'
import { useActiveWeb3React } from './web3'
import { SupportedChain } from '../lib/constants'
import { isTransactionSuccess } from '../js/libs/appUtils'
import { useEthersContractForAddress } from './use-ethers-contract'
import { ethers } from 'ethers'
import { sendMetaTx } from '../lib/metatx/utils'

const EMOTABLE_REPOSITORY_ADDRESS = '0x3110735F0b8e71455bAe1356a33e428843bCb9A1'
const BASE_PATH = {
  [SupportedChain.Polygon]: 'https://api.thegraph.com/subgraphs/name/murall-art/murall-emotes-polygon',
  [SupportedChain.Ethereum]: 'https://api.thegraph.com/subgraphs/name/murall-art/murall-emotes-ethereum'
}

const getSubgraphApiForNetwork = (targetChainId) => {
  const basePath = BASE_PATH[targetChainId] || BASE_PATH[SupportedChain.Polygon]
  return useMemo(() => {
    return {
      emotesForToken: async (address, tokenId) => {
        const result = await fetch(basePath, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: `query {
                        tokenContracts(where:{address:"${address}"}) {
                          tokens(where:{tokenId:${parseInt(tokenId)}}){
                            emojiCounts(where:{count_gt:0}, orderBy: count, orderDirection: desc) {
                              emoji
                              count
                            }
                          }
                        }    
                      }`
          })
        })

        const resultJson = await result.json()

        const emojiCounts = resultJson?.data?.tokenContracts?.[0]?.tokens?.[0]?.emojiCounts ?? []
        return emojiCounts
      },
      emotesForTokenByAccount: async (address, tokenId, account) => {
        const result = await fetch(basePath, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: `query {
                      tokenContracts(where: {address: "${address}"}) {
                        tokens(where: {tokenId: ${parseInt(tokenId)}}) {
                          emotes(where: {emoter: "${account}", on: true}) {
                            emoji
                          }
                        }
                      }
            }`
          })
        })

        const resultJson = await result.json()

        const emojiCounts = resultJson?.data?.tokenContracts?.[0]?.tokens?.[0]?.emotes ?? []
        return emojiCounts.map((e) => e.emoji)
      }
    }
  }, [basePath])
}

// returns null on errors
export const useEmotableContract = (targetChainId, forceArchiveNode = false) => {
  return useEthersContractForAddress({
    abi: abi,
    contractAddress: EMOTABLE_REPOSITORY_ADDRESS,
    targetChainId: targetChainId,
    forceArchiveNode
  })
}
export const useEmotableDataSource = (targetChainId) => {
  const contract = useEmotableContract(targetChainId)
  const subgraphApi = getSubgraphApiForNetwork(targetChainId)
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!contract || !subgraphApi) return null
    const prepareMessageToPresignEmote = async (
      collection,
      tokenId,
      emoji,
      state = true,
      deadline = ethers.BigNumber.from(9999999999)
    ) => {
      const message = await contract?.prepareMessageToPresignEmote(collection, tokenId, emoji, state, deadline)
      const signer = library?.ethersProvider?.getSigner()
      const signature = await signer.signMessage(ethers.utils.arrayify(message))

      const r = signature.substring(0, 66)
      const s = '0x' + signature.substring(66, 130)
      const v = parseInt(signature.substring(130, 132), 16)

      return {
        collection,
        tokenId,
        emoji,
        state,
        deadline,
        v,
        r,
        s
      }
    }
    return {
      prepareMessageToPresignEmote,
      presignedEmoteMetaTx: async (
        collection,
        tokenId,
        emoji,
        v,
        r,
        s,
        state = true,
        deadline = ethers.BigNumber.from(9999999999)
      ) => {
        const provider = library?.ethersProvider
        const signer = provider.getSigner()

        const from = await signer.getAddress()
        return sendMetaTx({
          provider: provider,
          signer: signer,
          targetContract: contract,
          functionName: 'presignedEmote',
          functionArgs: [from, collection, tokenId, emoji, state, deadline, v, r, s]
        })
      },
      emotesForToken: (address, tokenId) => {
        return subgraphApi.emotesForToken(address, tokenId)
      },
      emotesForTokenByAccount: (address, tokenId, account) => {
        return subgraphApi.emotesForTokenByAccount(address, tokenId, account)
      },
      emote: (collectionAddress, tokenId, emoji, state = true) => {
        const signer = library?.ethersProvider?.getSigner()

        return new Promise(async function (resolve, reject) {
          const response = await contract?.connect(signer).emote(collectionAddress, tokenId, emoji, state)
          response
            .wait()
            .then(function (receipt) {
              if (isTransactionSuccess(receipt)) {
                resolve(true)
              } else {
                reject('Transaction Failed')
              }
            })
            .catch(function (error) {
              console.error(error)

              reject(error)
            })
        })
      }
    }
  }, [contract, library, subgraphApi])
}

const abi = [
  { inputs: [], name: 'BulkParametersOfUnequalLength', type: 'error' },
  { inputs: [], name: 'ExpiredPresignedEmote', type: 'error' },
  { inputs: [], name: 'InvalidSignature', type: 'error' },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'emoter',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'collection',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256'
      },
      { indexed: false, internalType: 'string', name: 'emoji', type: 'string' },
      { indexed: false, internalType: 'bool', name: 'on', type: 'bool' }
    ],
    name: 'Emoted',
    type: 'event'
  },
  {
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'collections', type: 'address[]' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
      { internalType: 'string[]', name: 'emojis', type: 'string[]' },
      { internalType: 'bool[]', name: 'states', type: 'bool[]' }
    ],
    name: 'bulkEmote',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'collections', type: 'address[]' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
      { internalType: 'string[]', name: 'emojis', type: 'string[]' }
    ],
    name: 'bulkEmoteCountOf',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'collections', type: 'address[]' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
      { internalType: 'string[]', name: 'emojis', type: 'string[]' },
      { internalType: 'bool[]', name: 'states', type: 'bool[]' },
      { internalType: 'uint256[]', name: 'deadlines', type: 'uint256[]' }
    ],
    name: 'bulkPrepareMessagesToPresignEmote',
    outputs: [{ internalType: 'bytes32[]', name: '', type: 'bytes32[]' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'emoters', type: 'address[]' },
      { internalType: 'address[]', name: 'collections', type: 'address[]' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
      { internalType: 'string[]', name: 'emojis', type: 'string[]' },
      { internalType: 'bool[]', name: 'states', type: 'bool[]' },
      { internalType: 'uint256[]', name: 'deadlines', type: 'uint256[]' },
      { internalType: 'uint8[]', name: 'v', type: 'uint8[]' },
      { internalType: 'bytes32[]', name: 'r', type: 'bytes32[]' },
      { internalType: 'bytes32[]', name: 's', type: 'bytes32[]' }
    ],
    name: 'bulkPresignedEmote',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'collection', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'string', name: 'emoji', type: 'string' },
      { internalType: 'bool', name: 'state', type: 'bool' }
    ],
    name: 'emote',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'collection', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'string', name: 'emoji', type: 'string' }
    ],
    name: 'emoteCountOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'emoter', type: 'address' },
      { internalType: 'address', name: 'collection', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'string', name: 'emoji', type: 'string' }
    ],
    name: 'hasEmoterUsedEmote',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'emoters', type: 'address[]' },
      { internalType: 'address[]', name: 'collections', type: 'address[]' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
      { internalType: 'string[]', name: 'emojis', type: 'string[]' }
    ],
    name: 'haveEmotersUsedEmotes',
    outputs: [{ internalType: 'bool[]', name: '', type: 'bool[]' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'collection', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'string', name: 'emoji', type: 'string' },
      { internalType: 'bool', name: 'state', type: 'bool' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' }
    ],
    name: 'prepareMessageToPresignEmote',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'emoter', type: 'address' },
      { internalType: 'address', name: 'collection', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'string', name: 'emoji', type: 'string' },
      { internalType: 'bool', name: 'state', type: 'bool' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
      { internalType: 'uint8', name: 'v', type: 'uint8' },
      { internalType: 'bytes32', name: 'r', type: 'bytes32' },
      { internalType: 'bytes32', name: 's', type: 'bytes32' }
    ],
    name: 'presignedEmote',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  }
]
