import React, { useRef, useState, useEffect } from 'react'
import { FixedSizeList as List } from 'react-window'

const VirtualizedList = ({ className, style, itemCount, itemSize, itemData, RowComponent }) => {
  const listContainerRef = useRef()
  const [listHeight, setListHeight] = useState(0)

  useEffect(() => {
    const updateSize = () => {
      if (listContainerRef.current) {
        setListHeight(listContainerRef.current.getBoundingClientRect().height)
      }
    }

    const resizeObserver = new ResizeObserver(updateSize)
    if (listContainerRef.current) {
      resizeObserver.observe(listContainerRef.current)
      updateSize() // Set initial size
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const Row = ({ index, style }) => {
    const item = itemData[index]
    return (
      <div style={style}>
        <RowComponent item={item} index={index} />
      </div>
    )
  }

  return (
    <div ref={listContainerRef} {...(className && { className })} style={{ width: '100%', height: '100%', ...style }}>
      {listHeight > 0 && (
        <List height={listHeight} itemCount={itemCount} itemSize={itemSize} width={'100%'} itemData={itemData}>
          {Row}
        </List>
      )}
    </div>
  )
}

export default VirtualizedList
