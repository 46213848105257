import React from 'react'
import Link from '@mui/material/Link'
import CustomAccordion from '../../../components/common/Accordion'
import SectionTitle from '../SectionTitle'

const SectionDrawingOnMurAll = () => {
  return (
    <>
      <SectionTitle variant='h4' component='h4'>
        Drawing on MurAll with the MurAll App
      </SectionTitle>
      <CustomAccordion
        items={[
          {
            title: 'How can I draw/mint on MurAll?',
            content: (
              <>
                You can find full details of how to draw and mint on MurAll{' '}
                <Link
                  href='https://knowledgebase.murall.art/tutorials/minting'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  here on our knowledgebase
                </Link>
              </>
            )
          },
          {
            title: 'What colour space does MurAll use?',
            content: (
              <>
                MurAll uses RGB565, a 16 bit colour space which gives the best
                look while allowing for more pixels per transaction, as well as
                taking up less room on the blockchain.
              </>
            )
          },
          {
            title: 'How many colours can each drawing have?',
            content: (
              <>
                In order to fit as much information as possible into each
                transaction without sacrificing colour too much, each drawn
                image can have up to 256 distinct colours from the RGB565
                spectrum. There is no set 256 colour spectrum, each artist can
                choose any 256 colours to use for each drawing
              </>
            )
          },
          {
            title: 'How can I reduce my drawing to 256 colours/RGB565?',
            content: (
              <>
                The drawing editor includes a smart colour reduction tool to
                reduce your drawing to 256 colours/RGB565.
                <br />
                <br /> Either go to "Image
                {` > `}
                Convert to 256 color depth" from the top menu or click the{' '}
                <img
                  src={'images/palette_256_icon.png'}
                  style={{
                    display: 'inline-block',
                    width: '25px',
                    verticalAlign: 'bottom'
                  }}
                />{' '}
                button on the tools menu on the left hand side of the drawing
                editor to bring up the colour reduction options, then hit "OK"
                and it will create a new layer consisting of your image in
                reduced 256 colour/RGB565.
              </>
            )
          },
          {
            title: 'Is there a limit on how big a drawing can be on MurAll?',
            content: (
              <>
                You are only limited by the transaction gas limits, how much
                Paint you have, and the dimensions of MurAll (2048 x 1024
                pixels). We did not want to enforce arbitrary size limits past
                the dimensions of MurAll as we want users to be able to push it
                to the limits, plus we want MurAll to be fluid enough to handle
                the changes to the Ethereum blockchain over time. Even if the
                current Ethereum gas limits prevent a drawing in one
                transaction, you will still be able to draw in multiple
                transactions, and the MURALL NFTs can be displayed together even
                though they are pieces.
              </>
            )
          },
          {
            title:
              'My image is too large to fit in 1 transaction - how can I mint it?',
            content: (
              <>
                Since the image data in MurAll NFTs are stored on-chain, this
                introduces some limitation. Namely, blockchains cannot handle
                large amounts of data (From testing it seems around 100K-120K
                pixels per image is the maximum that fits into 1 transaction),
                and thus large images have to be either reduced or split up into
                separate sections and minted individually.
                <br />
                <br />
                By going to "Layers {` > `} Divide selected layer" from the top
                menu in our drawing editor you can divide your image into
                multiple sections defined by columns and rows. For example a
                full 2048 x 1024 pixel coverage divided into 5 rows by 4 columns
                produces 20 individual sections, each small enough to go into a
                single transaction.
                <br />
                <br />
                For more details see{' '}
                <Link
                  href='https://knowledgebase.murall.art/tutorials/minting/3-minting#imagetoolarge'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  our knowledgebase.
                </Link>
              </>
            )
          }
        ]}
      />
    </>
  )
}

export default SectionDrawingOnMurAll
