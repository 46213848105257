import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import DrawerDetailsView from '../../uicomponents/drawer_detail_view'
import {
  tokenRangeStartValue as sanitiseRangeStartValue,
  tokenRangeEndValue as sanitiseRangeEndValue,
  singleTokenValue as sanitiseSingleTokenValue
} from '../../libs/sanitisers'
import { LoadingSpinner } from '../../uicomponents/loading_spinner'
import NetworkErrorMessageView from '../../uicomponents/network_error_message_view'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'
import { getUrlParameters, overrideUrlQueries } from '../../libs/appUtils'
import {
  EVOLV3_FILETYPES,
  MURALL_WALL,
  MURALL_WALL_ID,
  MURALL_WALL_QUERY_PARAM,
  SUPPORTED_THX_EVENTS
} from '../../../lib/constants'
import configJson from '../../../../config/notable_states.json'
import { useHistory, useLocation } from 'react-router-dom'
import NftImagePreviewDialog from '../../uicomponents/nft_image_preview_dialog'
import useGetTotalStateChanges from '../../../hooks/history/use-get-total-state-changes'
import MainMurAllHistoryView from './main_murall_history_view'
import { useMurAllSelectFromQueryParams } from '../../../hooks/murall/use-murall-select-from-query-params'
import HistoryDrawerContent from './history_drawer_content'
import { useHistoryForTokenRange } from '../../../hooks/history/use-history-for-token-range'
import ErrorMessageView from '../../uicomponents/error_message_view'
import RoundedButton from '../../../components/common/buttons/rounded-button'
import { Typography } from '@mui/material'
import { usePostThxEventMutation } from '../../../state/evolv3Api'
import { useActiveWeb3React } from '../../../hooks/web3'

const DEFAULT_FADE_DURATION_PLAYING_MILLIS = 800
const DEFAULT_DURATION_BETWEEN_STATES_MILLIS = 800

const useStyles = makeStyles(theme => ({
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}))

export default function ViewHistorySvg(props) {
  const [cookies] = useCookies(['agreedToTerms'])
  const { account } = useActiveWeb3React()

  const terms = useSelector(state => state.terms)
  const currentWall = useSelector(state => state.wall)
  const wallInfo = MURALL_WALL[currentWall.id]
  const wallId = currentWall.id
  const history = useHistory()
  const agreedToTerms = !!cookies.agreedToTerms || terms.agreed

  const {
    apply: getTotalStateChanges,
    loading: loadingTotalStateChanges,
    totalStateChanges: totalStates,
    error: totalStateChangesError
  } = useGetTotalStateChanges(wallId, agreedToTerms)

  const classes = useStyles()

  const [isFetching, setIsFetching] = useState(false)
  const [isFetchingStates, setIsFetchingStates] = useState(false)
  const [singleTokenModeExtraData, setSingleTokenModeExtraData] = useState(null)
  const [networkError, setNetworkError] = useState(false)
  const [isLiveMode, setLiveMode] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [rangeStart, setRangeStart] = useState(0)
  const [rangeEnd, setRangeEnd] = useState(0)
  const [rangeToFetch, setRangeToFetch] = useState([null, null])
  const [drawerOpen, setDrawerOpen] = useState(true)
  const [fadeDurationPlaying, setFadeDurationPlaying] = useState(
    DEFAULT_FADE_DURATION_PLAYING_MILLIS
  )
  const [durationBetweenStates, setDurationBetweenStates] = useState(
    DEFAULT_DURATION_BETWEEN_STATES_MILLIS
  )
  const [currentSelectedState, setCurrentSelectedState] = useState(0)

  const [navigationControlsExpanded, setNavigationControlsExpanded] = useState(
    false
  )
  const [nftInfoOpen, setNftInfoOpen] = useState(false)
  const [shouldLocate, setShouldLocate] = useState(0)
  const [singleTokenMode, setSingleTokenMode] = useState(false)
  const [timer, setTimer] = useState(null)
  const [states, setStates] = useState([])
  const [baseState, setBaseState] = useState(null)
  const smallScreen = useSmallScreenDetection()

  const {
    baseState: baseStateForTokenId,
    stateChanges: statesDataForTokenIds,
    loading: loadingHistoryForTokenRange,
    error: historyForTokenRangeError
  } = useHistoryForTokenRange(
    wallId,
    rangeToFetch[0],
    rangeToFetch[1],
    rangeToFetch[0] !== null && rangeToFetch[1] !== null
  )

  const search = useLocation().search

  const [postThxEvent, response] = usePostThxEventMutation()

  useEffect(() => {
    if (!window.THXWidget || !account || !isLiveMode) return

    const event =
      wallId === MURALL_WALL_ID.EVOLV3
        ? SUPPORTED_THX_EVENTS.VIEW_EVOLV3
        : MURALL_WALL_ID.LAYER_1
        ? SUPPORTED_THX_EVENTS.VIEW_REVO1T
        : SUPPORTED_THX_EVENTS.VIEW_DI2RUPT
    postThxEvent({
      userAddress: account,
      event
    }).unwrap()
  }, [wallId, account, isLiveMode])

  useMurAllSelectFromQueryParams()

  const initialise = () => {
    if (currentWall && agreedToTerms) {
      setRangeStart(null)
      setRangeEnd(null)
      setRangeToFetch([null, null])
      getTotalStateChanges()
    }
  }
  useEffect(() => {
    initialise()
  }, [agreedToTerms, currentWall])

  useEffect(() => {
    if (isLiveMode) {
      return
    }
    if (loadingTotalStateChanges) {
      setRangeStart(null)
      setRangeEnd(null)
      setRangeToFetch([null, null])
      setIsFetching(true)
      setStates([])
      setNetworkError(false)
      setShouldLocate(0)
    }

    if (!loadingTotalStateChanges) {
      setIsFetchingStates(true)
      setIsFetching(false)
      if (_.isUndefined(getUrlParameters(props.location.search).atTokenId)) {
        setStates([])
        setNavigationControlsExpanded(false)
        setLiveMode(true)
        setSingleTokenMode(true)
      } else {
        setStates([])
        setCurrentSelectedState(Math.floor(totalStates / 2))
      }
      setIsFetchingStates(false)
    }
  }, [loadingTotalStateChanges])

  useEffect(() => {
    console.log('loadingHistoryForTokenRange?', loadingHistoryForTokenRange)
    setIsFetchingStates(loadingHistoryForTokenRange)
    setNavigationControlsExpanded(!loadingHistoryForTokenRange)
    if (!statesDataForTokenIds) return
    if (
      !loadingHistoryForTokenRange &&
      statesDataForTokenIds.length > 0 &&
      baseStateForTokenId
    ) {
      if (singleTokenMode && singleTokenModeExtraData) {
        statesDataForTokenIds[0].externalUrl =
          singleTokenModeExtraData.externalUrl
        statesDataForTokenIds[0].montageImage =
          singleTokenModeExtraData.montageImage
        statesDataForTokenIds[0].montageTokenId =
          singleTokenModeExtraData.montageTokenId
      }
      console.log('statesDataForTokenIds', statesDataForTokenIds)
      setStates(statesDataForTokenIds)
      setBaseState(baseStateForTokenId)
      setRangeToFetch([null, null])
    }
  }, [loadingHistoryForTokenRange, statesDataForTokenIds, baseStateForTokenId])

  useEffect(() => {
    if (
      agreedToTerms &&
      !loadingTotalStateChanges &&
      totalStates &&
      !isFetching
    ) {
      console.log('updateFromParams', totalStates)
      updateFromParams()
    }
  }, [search, isFetching, loadingTotalStateChanges])

  useEffect(() => {
    if (isPlaying) {
      startPlayback()
    } else {
      pausePlayback()
      clearInterval(timer)
      setTimer(null)
    }
  }, [isPlaying])

  const updateFromParams = async () => {
    const queryParams = getUrlParameters(search)

    if (!_.isUndefined(queryParams.live) && !isLiveMode) {
      setStates([])
      setNavigationControlsExpanded(false)
      setLiveMode(true)
      setSingleTokenMode(true)
    } else if (!_.isUndefined(queryParams.atTokenId) && totalStates) {
      const statesLength = totalStates
      let tokenId
      if (_.isUndefined(queryParams.toTokenId)) {
        tokenId = sanitiseSingleTokenValue(
          parseInt(queryParams.atTokenId),
          statesLength - 1
        )
        showStateAtId(tokenId)
      } else {
        tokenId = sanitiseRangeStartValue(
          parseInt(queryParams.atTokenId),
          statesLength < 1 ? 0 : statesLength - 1
        )

        const rangeStart = tokenId
        const rangeEnd = sanitiseRangeEndValue(
          parseInt(queryParams.toTokenId),
          statesLength < 1 ? 0 : statesLength - 1
        )
        setNewTokenRange(rangeStart, rangeEnd)

        fetchStateChangesForRange(rangeStart, rangeEnd)
      }
    }
  }

  const handleSliderChange = (event, newValue, activeThumb) => {
    // for some reason the slider fires onChange when you click and hold the slider but move slightly without changing the slider value
    // (i.e. onChange is called with the same unchanged value) so we should see if the value is different before updating the state
    if (Array.isArray(newValue)) {
      newValue = newValue[activeThumb]
    }
    if (currentSelectedState != newValue) {
      setCurrentSelectedState(newValue)
    }
  }

  const setNewTokenRange = (newStartValue, newEndValue) => {
    if (newEndValue <= newStartValue) {
      newEndValue = newStartValue + 1
    }

    if (newStartValue >= newEndValue) {
      newStartValue = newEndValue - 1
    }

    setRangeStart(newStartValue)
    setRangeEnd(newEndValue)
  }

  const handleLoadClicked = liveMode => {
    if (smallScreen) {
      setDrawerOpen(false)
    }
    if (liveMode) {
      const data = {
        wall: wallInfo.queryParam,
        live: true
      }

      overrideUrlQueries(props, data)
    } else {
      setLiveMode(false)
      setSingleTokenMode(!rangeEnd)
      let data = {
        wall: wallInfo.queryParam,
        atTokenId: rangeStart,
        ...(rangeEnd && { toTokenId: rangeEnd })
      }

      overrideUrlQueries(props, data)
    }
  }

  const handleFastRewindClicked = event => {
    goToPreviousState()
  }

  const handleFastForwardClicked = event => {
    goToNextState()
  }

  const handleSkipPreviousClicked = event => {
    setCurrentSelectedState(0)
  }

  const handleSkipNextClicked = event => {
    setCurrentSelectedState(states.length - 1)
  }

  const togglePlayPause = event => {
    setIsPlaying(!isPlaying)
  }

  const handleFadeDurationChange = event => {
    const newValue = event.target.value === '' ? 0 : Number(event.target.value)
    setFadeDurationPlaying(newValue)
  }

  const handleDurationBetweenStatesChange = event => {
    const newValue = event.target.value === '' ? 0 : Number(event.target.value)
    setDurationBetweenStates(newValue)
  }

  const handleExpandNavigationControlsClicked = () => {
    setNavigationControlsExpanded(!navigationControlsExpanded)
  }

  const handleInfoIconClicked = () => {
    setNftInfoOpen(true)
  }
  const handleLocateIconClicked = () => {
    setShouldLocate(shouldLocate + 1)
  }

  const goToNextState = () => {
    var newState = currentSelectedState + 1

    if (newState > states.length - 1) {
      newState = states.length - 1
    }

    setCurrentSelectedState(newState)
  }

  const goToPreviousState = () => {
    var newState = currentSelectedState - 1
    if (newState < 0) {
      newState = 0
    }
    setCurrentSelectedState(newState)
  }
  const startPlayback = () => {
    if (states[currentSelectedState].fileType !== EVOLV3_FILETYPES.VIDEO) {
      goToNextState()
    }
    if (timer) {
      clearInterval(timer)
      setTimer(null)
    }
    const newTimer = setInterval(advancePlayback, durationBetweenStates)
    setTimer(newTimer)
  }

  const advancePlayback = async () => {
    if (!isPlaying) {
      const currentTimer = await getCurrentHookValue(setTimer)
      clearInterval(currentTimer)
      return
    }
    const selectedState = await getCurrentHookValue(setCurrentSelectedState)
    const isVideoPlaying = await getCurrentHookValue(setVideoPlaying)
    // if selected state is a video wait for it to finish playing

    if (
      states[selectedState].fileType === EVOLV3_FILETYPES.VIDEO &&
      isVideoPlaying
    ) {
      return
    }
    var newState = selectedState + 1

    if (newState > states.length - 1) {
      newState = states.length - 1
    }

    setCurrentSelectedState(newState)
    if (states[newState].fileType === EVOLV3_FILETYPES.VIDEO) {
      setVideoPlaying(true)
    }
    if (
      newState == states.length - 1 &&
      states[newState].fileType !== EVOLV3_FILETYPES.VIDEO
    ) {
      const currentTimer = await getCurrentHookValue(setTimer)
      clearInterval(currentTimer)
      setIsPlaying(false)
    }
  }

  /* Workaround for functional component not being able to get current hook value in interval */
  async function getCurrentHookValue(setHookFunction) {
    return new Promise(resolve => {
      setHookFunction(prev => {
        resolve(prev)
        return prev
      })
    })
  }

  const pausePlayback = () => {
    clearInterval(timer)
    setTimer(null)
  }

  const fetchStateChangesForRange = async (rangeStart, rangeEnd) => {
    setLiveMode(false)
    setShouldLocate(0)
    setStates([])
    setNavigationControlsExpanded(false)

    setRangeStart(rangeStart)
    setRangeEnd(rangeEnd)
    setCurrentSelectedState(0)
    setSingleTokenMode(false)
    console.log('fetchStateChangesForRange', rangeStart, rangeEnd)
    setRangeToFetch([rangeStart, rangeEnd])
  }

  const showStateAtId = async (
    tokenId,
    externalUrl = null,
    montageImage = null,
    montageTokenId = null
  ) => {
    setLiveMode(false)
    setShouldLocate(0)
    setStates([])
    setCurrentSelectedState(0)
    setNavigationControlsExpanded(false)
    setSingleTokenModeExtraData({
      externalUrl,
      montageImage,
      montageTokenId
    })
    setRangeStart(tokenId > 0 ? tokenId - 1 : 0)
    setRangeEnd(null)
    setSingleTokenMode(true)
    setRangeToFetch([tokenId, tokenId])
  }

  const goToExternalUrl = () => {
    const currentNft = states[currentSelectedState]
    console.log('goToExternalUrl', currentNft)
    history.push(
      `/${currentNft.montageTokenId ? 'montage' : 'murall'}/${
        MURALL_WALL_QUERY_PARAM[wallId]
      }/${currentNft.montageTokenId || currentNft.tokenId}`
    )
  }

  const constructMainContent = (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {!isFetchingStates && (
        <MainMurAllHistoryView
          wallId={wallId}
          isPlaying={isPlaying}
          baseState={baseState}
          states={states}
          currentSelectedState={currentSelectedState}
          shouldLocate={shouldLocate}
          fadeDurationPlaying={fadeDurationPlaying}
          onLoadingStateChanged={loading => {
            setNavigationControlsExpanded(!loading)
          }}
          onLiveDataUpdated={tokenInfos => {
            getTotalStateChanges()
            if (tokenInfos) {
              setStates(tokenInfos)
              setCurrentSelectedState(tokenInfos.length - 1)
              setNavigationControlsExpanded(true)
            } else {
              setStates([])
              setCurrentSelectedState(0)
              setNavigationControlsExpanded(false)
            }
          }}
          navigationControlsExpanded={navigationControlsExpanded}
          onSliderChange={handleSliderChange.bind(this)}
          onSkipPreviousClicked={handleSkipPreviousClicked.bind(this)}
          onSkipNextClicked={handleSkipNextClicked.bind(this)}
          onFastRewindClicked={handleFastRewindClicked.bind(this)}
          onFastForwardClicked={handleFastForwardClicked.bind(this)}
          onPlayPauseClicked={togglePlayPause.bind(this)}
          onFadeDurationChanged={handleFadeDurationChange.bind(this)}
          durationBetweenStates={durationBetweenStates}
          onDurationBetweenStatesChanged={handleDurationBetweenStatesChange.bind(
            this
          )}
          onInfoButtonClicked={handleInfoIconClicked.bind(this)}
          onLocateButtonClicked={handleLocateIconClicked.bind(this)}
          onExpandNavigationControlsIconClicked={handleExpandNavigationControlsClicked.bind(
            this
          )}
          onVideoStarted={() => {
            setVideoPlaying(true)
          }}
          onVideoEnded={() => {
            setVideoPlaying(false)
            if (isPlaying && currentSelectedState < states.length - 1) {
              startPlayback()
            } else {
              setIsPlaying(false)
            }
          }}
          singleTokenMode={singleTokenMode}
          liveMode={isLiveMode}
          onOpenDetailsClicked={goToExternalUrl}
        />
      )}

      {isFetchingStates && <LoadingSpinner />}
      {!isFetchingStates &&
        historyForTokenRangeError &&
        !isLiveMode &&
        !states &&
        !baseState && <NetworkErrorMessageView />}
    </div>
  )

  if (!agreedToTerms) {
    return <></>
  } else if (isFetching) {
    return <LoadingSpinner />
  } else if (networkError || totalStateChangesError) {
    return (
      <ErrorMessageView
        title={'Network Error'}
        description={'MurAll state view unavailable - please try again later'}
      >
        <RoundedButton
          variant="text"
          aria-label="close"
          sx={{
            mt: 2,
            color: 'inherit',
            fontSize: 'inherit',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
          onClick={() => {
            initialise()
          }}
        >
          <Typography variant="body1">Retry</Typography>
        </RoundedButton>
      </ErrorMessageView>
    )
  } else {
    return (
      <div
        className={clsx(classes.absoluteFill)}
        style={{ overflow: 'hidden' }}
      >
        <DrawerDetailsView
          {...props}
          drawerOpen={drawerOpen}
          onDrawerOpenClick={() => {
            setDrawerOpen(true)
          }}
          onDrawerCloseClick={() => {
            setDrawerOpen(false)
          }}
          temporaryDrawer={smallScreen}
          style={{ width: '100%', height: '100%' }}
          drawerOpenByDefault={true}
          drawerContent={
            <HistoryDrawerContent
              highlightStates={configJson[wallId]}
              onHightlightListItemClick={state => {
                if (smallScreen) {
                  setDrawerOpen(false)
                }
                const data = {
                  wall: wallInfo.queryParam
                }

                console.log('notable state selected', state)

                overrideUrlQueries(props, data)
                showStateAtId(
                  state.tokenId,
                  state.externalUrl,
                  state.montageImage,
                  state.montageTokenId
                )
              }}
              onLoadHistoryClick={handleLoadClicked.bind(this)}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              setRangeStart={setRangeStart}
              setRangeEnd={setRangeEnd}
              totalStates={totalStates}
              maxRange={totalStates}
            />
          }
          mainContent={constructMainContent}
        />

        {states[currentSelectedState] && nftInfoOpen && (
          <NftImagePreviewDialog
            open={nftInfoOpen}
            onClose={() => {
              setNftInfoOpen(false)
            }}
            image={
              states[currentSelectedState].montageImage
                ? states[currentSelectedState].montageImage
                : states[currentSelectedState].image
            }
            nftInformation={states[currentSelectedState]}
            positiveButtonTitle="View Full Details"
            onPositiveButtonClick={goToExternalUrl}
          />
        )}
      </div>
    )
  }
}
