import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ErrorMessageView from '../components/common/error-view'
import { LoadingSpinner } from '../js/uicomponents/loading_spinner'
import { MURALL_WALL, getWallIdFromQueryParam } from '../lib/constants'
import MontageNftView from '../components/common/nft/montage-nft-view'
import { useGetMontageQuery } from '../state/api'

const MontageNftPage = () => {
  let { wall, id } = useParams()
  const wallId = getWallIdFromQueryParam(wall)
  const wallInfo = MURALL_WALL[wallId]
  const chainId = wallInfo.chainId
  const [initialLoading, setInitialLoading] = useState(true)

  const { data: nftData, isFetching: isLoadingNft, error } = useGetMontageQuery(
    {
      wallId: wallId,
      id: id
    }
  )

  useEffect(() => {
    if (isLoadingNft) return

    setInitialLoading(false)
    return () => {}
  }, [isLoadingNft, nftData])

  return isLoadingNft || initialLoading ? (
    <LoadingSpinner />
  ) : error ? (
    <ErrorMessageView title='Network Error' description='Try again later' />
  ) : (
    <MontageNftView nftData={nftData} chainId={chainId} id={id} />
  )
}

export default MontageNftPage
