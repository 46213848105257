import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import useMediaQuery from '@mui/material/useMediaQuery'
import '../../../css/layout.css'
import { useTheme } from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    overflow: 'visible',
    transform: props =>
      props.hover && !props.disabled ? 'scale(1.1)' : 'scale(1)',
    margin: '0',
    transition: 'all 300ms ease-in-out',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  disabled: {
    opacity: 0.5,
    filter: 'grayscale(100%)'
  },
  avatar: {
    backgroundColor: 'transparent',
    width: props => (props.isMobile ? theme.spacing(8) : theme.spacing(12)),
    height: props => (props.isMobile ? theme.spacing(8) : theme.spacing(12))
  },

  typography: {
    overflow: 'visible',
    fontFamily: 'Roboto',
    fontWeight: 500,
    textShadow: '0px 0px 6px black'
  }
}))

const CoverButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  '&:hover, &.Mui-focusVisible': {
    zIndex: 999999
  }
}))

export default function NetworkButton ({
  sx,
  hover,
  wallInfo,
  onClick,
  disabled
}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const theme = useTheme()
  const classes = useStyles({ isMobile, hover, disabled })

  const SvgComponentForWall = wallInfo.svgComponent
  return (
    <CoverButton
      disabled={disabled}
      focusRipple
      className={clsx(classes.root)}
      elevation={10}
      sx={{
        ...sx
      }}
      onClick={onClick}
    >
      <SvgComponentForWall
        className={clsx(classes.avatar, {
          [classes.disabled]: disabled
        })}
        style={{
          overflow: 'visible',
          ...(hover &&
            !disabled && {
              filter: `drop-shadow(0 0 10px ${theme.palette.primary.main})`
            })
        }}
      />
    </CoverButton>
  )
}
