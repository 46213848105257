import config from '../config.js'
import Base_tools_class from '../core/base-tools.js'
import Divide_layer_class from '../modules/layer/new.js'

class DivideLayer_class extends Base_tools_class {
  constructor (ctx) {
    super()
    this.divideLayer = new Divide_layer_class()
    this.name = 'divide_layer'
  }

  load () {
    //nothing
  }

  render (ctx, layer) {
    //nothing
  }

  on_activate () {
    this.divideLayer.divide_layers()
  }
}
export default DivideLayer_class
