import React, { useMemo } from 'react'
import clsx from 'clsx'
import useStyles from './styles'

export default function SvgHistoryState ({
  opacity = 1,
  image,
  x,
  y,
  width,
  height,
  shouldLocate,
  shouldHide,
  animationDuration,
  filter,
  useGlitchEffect
}) {
  const classes = useStyles({
    animationDuration
  })

  const groupStyle = useMemo(() => {
    const basicStyles =
      opacity > 0 ? { opacity } : { visibility: 'hidden', display: 'none' }

    return basicStyles
  }, [opacity])

  const imageStyles = useMemo(() => {
    const basicStyles = {}
    if (filter) basicStyles.filter = filter
    return basicStyles
  }, [filter])

  const imageClassNames = useMemo(() => {
    return clsx({
      // 'pixelated-image': true, // Uncomment if you need this class
      [classes.hide]: shouldHide,
      [classes.show]: !shouldHide,
      [classes.locate]: shouldLocate
    })
  }, [shouldHide, shouldLocate, classes])

  return (
    <g transform={`translate(${x} ${y})`} style={groupStyle}>
      <image
        className={imageClassNames}
        height={height}
        width={width}
        href={image}
        style={imageStyles}
      />

      {!shouldHide && useGlitchEffect && (
        <image
          className={clsx('pixelated-image', classes.glitch1)}
          height={height}
          width={width}
          href={image}
        />
      )}
    </g>
  )
}
