import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import Skeleton from '@mui/material/Skeleton'
import _ from 'underscore'
import { CardActionArea, Avatar, CardMedia } from '@mui/material'
import { useMurAllFramesDataSource } from '../../../hooks/use-murall-frame-datasource'
import clsx from 'clsx'
import {
  IPFS_GATEWAY_PINATA_MURALL,
  replaceIpfsIfNeeded
} from '../../../js/libs/appUtils'
import useMediaQuery from '@mui/material/useMediaQuery'
import SelectableGridImageCard from '../SelectableGridImageCard'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    border: 'solid',
    borderColor: 'black',
    borderWidth: '1px'
  },
  selected: {
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px',
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },
  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%' // square
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212180,
            #21212199,
            #212121B3)`
  }
}))

export default function FrameGridItem ({
  title,
  tokenId,
  onClick,
  selected,
  loading,
  sx
}) {
  const classes = useStyles()

  const { getFullTokenUriMetadata } = useMurAllFramesDataSource()
  const [isFetching, setIsFetching] = useState(false)
  const [tokenUriMetadata, setTokenUriMetadata] = useState(null)
  const [image, setImage] = useState(null)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useEffect(() => {
    if (loading) return
    if (tokenId) {
      setIsFetching(true)
      getFullTokenUriMetadata(tokenId).then(metadata => {
        setTokenUriMetadata(metadata)
        metadata.image &&
          setImage(
            replaceIpfsIfNeeded(metadata.image, IPFS_GATEWAY_PINATA_MURALL)
          )
        setIsFetching(false)
      })
    }
  }, [tokenId, loading])

  return (
    <SelectableGridImageCard
      selected={selected}
      onClick={onClick}
      image={image}
      title={title}
      loading={loading || isFetching}
      darkenHeader
      header={
        <CardHeader
          avatar={
            <Avatar
              src={'images/logos/FRAME_small.png'}
              variant='square'
              {...(isMobile && { sx: { width: 16, height: 16 } })}
              {...(!isMobile && { sx: { width: 32, height: 32 } })}
            />
          }
          title={title ? title : <Skeleton variant='text' />}
          titleTypographyProps={{
            align: 'left',
            variant: isMobile ? 'body1' : 'h5',
            component: isMobile ? 'body1' : 'h5',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300
          }}
          sx={{ padding: isMobile ? '0.5em' : '1em' }}
        />
      }
      square
      sx={{
        ...sx
      }}
    />
  )
}
