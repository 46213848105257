import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { LoadingSpinner } from '../../uicomponents/loading_spinner'
import clsx from 'clsx'
import AuctionItemInfoCard from './auctionItemNftInfoCard'
import AuctionDetailsCard from './auctionDetailsCard'
import Grid from '@mui/material/Grid'
import NetworkErrorMessageView from '../../uicomponents/network_error_message_view'
import ErrorMessageView from '../../uicomponents/error_message_view'
import AuctionItemNftLoadingPlaceholderCard from './auctionItemNftloadingPlaceholderCard'
import AuctionDetailsLoadingPlaceholderCard from './auctionDetailsLoadingPlaceholderCard'
import PoapPromoDialog from './poap_promo_dialog'
import notification from '../../../lib/notification'
import { useAuctionMarketplaceDataSource } from '../../../hooks/use-auction-marketplace-datasource'
import { useActiveWeb3React } from '../../../hooks/web3'
import { useSelector } from 'react-redux'
import { useMontageDataSource } from '../../../hooks/use-montage-datasource'
import { usePurchaseWonAuctionForId } from '../../../hooks/auction/use-purchase-won-auction-for-id'
import { convertTokenToProperDp } from '../../libs/appUtils'
import { SupportedChain } from '../../../lib/constants'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
    flexGrow: 1
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  buttonLarge: {
    fontSize: '63px'
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  emptyViewContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const TOKEN_ID = 21
const AUCTION_ID =
  '0x0cda7895d8e4e9d598e1984954f9d32a4b664bd999a0c8404e5dde3634d08371'

export default function AuctionDetailsPage (props) {
  const {
    itemIsOnAuction,
    getAuctionItemFullDetails,
    subscribeToAuctionBidEvents,
    bidForNft
  } = useAuctionMarketplaceDataSource()

  const { getMontageFullInformation } = useMontageDataSource()
  const network = useSelector(state => state.network)
  const { account } = useActiveWeb3React()

  const classes = useStyles()
  const [isFetching, setIsFetching] = useState(true)
  const [showPoapPromo, setShowPoapPromo] = useState(false)
  const [isFetchingNftDetails, setIsFetchingNftDetails] = useState(true)
  const [isFetchingAuctionDetails, setIsFetchingAuctionDetails] = useState(true)
  const [auctionData, setAuctionData] = useState(null)
  const [montageData, setMontageData] = useState(null)
  const [tokenData, setTokenData] = useState(null)
  const [auctionActive, setAuctionActive] = useState(false)
  const [networkError, setNetworkError] = useState(false)

  const priceProperDp = convertTokenToProperDp(
    auctionData ? auctionData.auctionPrice : 0,
    tokenData ? tokenData.decimals : 1
  )
  const { apply, loading } = usePurchaseWonAuctionForId(
    auctionData ? auctionData.auctionId : null,
    tokenData ? tokenData.contractAddress : null,
    tokenData ? tokenData.symbol : '',
    priceProperDp.toFixed(),
    auctionData ? auctionData.auctionPrice : 0,
    montageData ? montageData.name : ''
  )
  useEffect(() => {
    // reload info when navigating here
    if (location.pathname === '/auction') {
      if (network && network.isLayer2) {
        setNetworkError(false)
        updateAuctionInfo()
        subscribeToAuctionBidEvents(AUCTION_ID, updateNewBidderInfo)
      } else {
        setNetworkError(true)
        setIsFetching(false)
      }
    }
  }, [location])

  useEffect(() => {
    if (network && network.isLayer2) {
      setNetworkError(false)
      updateAuctionInfo()
      subscribeToAuctionBidEvents(AUCTION_ID, updateNewBidderInfo)
    } else {
      setNetworkError(true)
      setIsFetching(false)
    }
    // Remove event listener on cleanup
    return () => {}
  }, [network]) // Empty array ensures that effect is only run on mount

  const updateNewBidderInfo = async event => {
    const currentAuctionData = await getCurrentHookValue(setAuctionData)
    const currentTokenData = await getCurrentHookValue(setTokenData)
    if (currentAuctionData && currentTokenData) {
      const newBidder = event.returnValues.bidder
      const newPrice = event.returnValues.newPrice
      currentAuctionData.currentBidderAddress = newBidder
      currentAuctionData.auctionPrice = newPrice
      setAuctionData(currentAuctionData)
      const priceProperDp = convertTokenToProperDp(
        newPrice,
        currentTokenData.decimals
      )
      notification.success(
        `New bid! ${priceProperDp.toString()} ${currentTokenData.symbol}`
      )
    }
  }

  /* Workaround for functional component not being able to get current hook value in interval */
  async function getCurrentHookValue (setHookFunction) {
    return new Promise(resolve => {
      setHookFunction(prev => {
        resolve(prev)
        return prev
      })
    })
  }

  const updateAuctionInfo = async () => {
    setIsFetching(true)

    const isAuctionActive = await itemIsOnAuction(AUCTION_ID)
    setAuctionActive(isAuctionActive)

    if (isAuctionActive) {
      setIsFetchingAuctionDetails(true)
      setIsFetchingNftDetails(true)
      setIsFetching(false)
      try {
        fetchAuctionData()
        fetchTokenData()
      } catch (e) {
        console.error('error setting up', e)
        setNetworkError(true)
      }
    } else {
      setIsFetching(false)
    }
  }

  const fetchTokenData = async () => {
    setIsFetchingNftDetails(true)

    try {
      const montageData = await getMontageFullInformation(TOKEN_ID)
      montageData.chainId = SupportedChain.Polygon

      setMontageData(montageData)
    } catch (e) {
      console.error('error setting up', e)
      setNetworkError(true)
    }
    setIsFetchingNftDetails(false)
  }

  const fetchAuctionData = async () => {
    setIsFetchingAuctionDetails(true)
    const auctionId = AUCTION_ID

    try {
      const auctionData = await getAuctionItemFullDetails(auctionId)

      setAuctionData(auctionData)
      setTokenData(auctionData.paymentTokenInfo)
      setIsFetchingAuctionDetails(false)
    } catch (e) {
      console.error('error setting up', e)
      setNetworkError(true)
    }
    setIsFetchingAuctionDetails(false)
  }

  return isFetching ? (
    <LoadingSpinner />
  ) : network && !network.isLayer2 ? (
    <ErrorMessageView
      title={'Wrong Network'}
      description={'Please select Polygon'}
    />
  ) : networkError ? (
    <NetworkErrorMessageView />
  ) : !auctionActive ? (
    <ErrorMessageView
      title={'The auction has ended!'}
      description={
        'Keep you eye out on our social pages for more auctions coming soon!'
      }
    />
  ) : (
    <div className={clsx(classes.absoluteFill, classes.root)}>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='space-around'
        alignItems='flex-start'
        style={{ maxWidth: '1500px', margin: '0 auto' }}
      >
        <Grid key={0} item xs={12} sm={12} md={6} lg={6} xl={6} align='center'>
          {isFetchingNftDetails ? (
            <AuctionItemNftLoadingPlaceholderCard />
          ) : (
            <AuctionItemInfoCard nftInformation={montageData} />
          )}
        </Grid>
        <Grid key={1} item xs={12} sm={12} md={6} lg={6} xl={6} align='center'>
          {isFetchingAuctionDetails ? (
            <AuctionDetailsLoadingPlaceholderCard />
          ) : (
            <AuctionDetailsCard
              auctionInformation={auctionData}
              nftInformation={montageData}
              tokenInformation={tokenData}
              bidButtonEnabled={account}
              onBidClicked={async (
                name,
                auctionId,
                bidAmount,
                bidAmountFormatted
              ) => {
                console.log('bidAmount', bidAmount)

                if (!network.isLayer2) {
                  notification.error(
                    'Wrong network - Change to Polygon in order to bid'
                  )
                } else {
                  try {
                    const funcPromise = bidForNft(auctionId, bidAmount)

                    await notification.promise(funcPromise, {
                      loading: `Bidding ${bidAmountFormatted} for ${name}...`,
                      success: `Successfully bid ${bidAmountFormatted} for ${name} - good luck!`,
                      error: error => {
                        if (
                          error.message &&
                          error.message.indexOf('User denied') != -1
                        )
                          return 'You rejected the transaction!'
                        return `Sorry, the transaction failed: ${error.name}`
                      }
                    })

                    setShowPoapPromo(true)
                  } catch (e) {
                    console.error('error bidding', e)
                  }
                }
              }}
              onPurchaseClicked={async (
                name,
                auctionId,
                bidAmount,
                bidAmountFormatted
              ) => {
                console.log('bidAmount', bidAmount)

                if (!network.isLayer2) {
                  notification.error(
                    'Wrong network - Change to Polygon in order to complete purchase'
                  )
                } else {
                  try {
                    await apply()
                  } catch (e) {
                    console.error('error purchasing', e)
                  }
                }
              }}
            />
          )}
        </Grid>
      </Grid>
      <PoapPromoDialog
        open={showPoapPromo}
        onClose={() => setShowPoapPromo(false)}
      />
    </div>
  )
}
