import { getOwnedTokenIdsInIndexRange } from '../datasource/Erc721DataSource'
import {
  getBundleName,
  getBundleTokenIds
} from '../datasource/NftBundleDataSource'
import Helper_class from '../../../libs/helpers.js'
import _ from 'underscore'
const async = require('async')

class Get_nft_bundle_data_for_owned_tokens_at_index_range_usecase {
  constructor () {
    this.Helper = new Helper_class()
  }

  async execute (
    web3,
    contractAddress,
    ownerAddress,
    startIndex,
    endIndex,
    isL2 = false
  ) {
    const nftIndexesInrange = await getOwnedTokenIdsInIndexRange(
      web3,
      contractAddress,
      ownerAddress,
      startIndex,
      endIndex
    )

    var _this = this
    const returnData = await async.map(nftIndexesInrange, async function (
      tokenId,
      callback
    ) {
      const name = await getBundleName(web3, contractAddress, tokenId)
      const tokenIds = await getBundleTokenIds(web3, contractAddress, tokenId)
      const mappedNftInfo = _this.constructNftBundleInformation(
        tokenId,
        null,
        null,
        name,
        'null',
        null,
        tokenIds,
        isL2
      )

      callback(null, mappedNftInfo)
    })

    return returnData
  }

  constructNftBundleInformation (
    tokenId,
    croppedBase64PngString,
    fullBase64PngString,
    name,
    artist,
    positionInformation,
    tokenIds,
    isL2
  ) {
    return {
      tokenId: tokenId,
      croppedBase64PngString: croppedBase64PngString,
      fullBase64PngString: fullBase64PngString,
      name: name,
      artist: artist,
      positionInformation: positionInformation,
      tokenIds: tokenIds,
      isL2: isL2
    }
  }
}

export default Get_nft_bundle_data_for_owned_tokens_at_index_range_usecase
