import React, { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useInterval } from '../../lib/useInterval'

export default function CountdownLoadingButton ({
  text,
  onTick,
  loading,
  onClick,
  startIcon,
  endIcon,
  loadingPosition = 'end',
  fullWidth
}) {
  useInterval(() => {
    onTick && onTick()
  }, 1000)

  return (
    <LoadingButton
      fullWidth={fullWidth}
      onClick={onClick}
      loading={loading}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={'contained'}
      loadingPosition={loadingPosition}
    >
      {text}
    </LoadingButton>
  )
}
