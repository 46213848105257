import config from '../../../config.js'
import INFTBundle from '../../../../contracts/layer1/INFTBundle.json'

/**
 * Source for Nft Bundler
 *
 * @author TheKeiron
 */

export const getBundle = (web3, bundleContractAddress, bundleId) => {
  return getNftBundleContractInstance(web3, bundleContractAddress)
    .methods.getBundle(bundleId)
    .call()
}

export const getBundleTokenIds = (web3, bundleContractAddress, bundleId) => {
  return getNftBundleContractInstance(web3, bundleContractAddress)
    .methods.getBundleTokenIds(bundleId)
    .call()
}

export const getBundleName = (web3, bundleContractAddress, bundleId) => {
  return getNftBundleContractInstance(web3, bundleContractAddress)
    .methods.getBundleName(bundleId)
    .call()
}

export const getUnlockableDescription = (
  web3,
  bundleContractAddress,
  bundleId
) => {
  return getNftBundleContractInstance(web3, bundleContractAddress)
    .methods.getUnlockableDescription(bundleId)
    .call()
}

export const hasUnlockableContentUri = (
  web3,
  bundleContractAddress,
  bundleId
) => {
  return getNftBundleContractInstance(web3, bundleContractAddress)
    .methods.hasUnlockableContentUri(bundleId)
    .call()
}

export const getUnlockableContentUri = (
  web3,
  bundleContractAddress,
  bundleId,
  senderAddress
) => {
  return getNftBundleContractInstance(web3, bundleContractAddress)
    .methods.getUnlockableContentUri(bundleId)
    .call({ from: senderAddress })
}

export const setUnlockableContentUri = (
  web3,
  bundleContractAddress,
  bundleId,
  unlockableContentUri,
  unlockableContentDescription,
  senderAddress
) => {
  return getNftBundleContractInstance(web3, bundleContractAddress)
    .methods.setUnlockableContentUri(
      bundleId,
      unlockableContentUri,
      unlockableContentDescription
    )
    .send({ from: senderAddress })
}

export const bundleNfts = (
  web3,
  bundleContractAddress,
  name,
  tokenIds,
  senderAddress
) => {
  return getNftBundleContractInstance(web3, bundleContractAddress)
    .methods.bundleNfts(name, tokenIds)
    .send({ from: senderAddress })
}

export const unbundleNfts = (
  web3,
  bundleContractAddress,
  bundleId,
  senderAddress
) => {
  return getNftBundleContractInstance(web3, bundleContractAddress)
    .methods.unbundleNfts(bundleId)
    .send({ from: senderAddress })
}

const getNftBundleContractInstance = (web3, bundleContractAddress) => {
  return new web3.eth.Contract(INFTBundle.abi, bundleContractAddress)
}
