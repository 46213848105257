import { useState, useEffect, useCallback } from 'react'
import { useMurAllStateS3DataSourceForWall } from '../use-murall-s3-state-datasource-for-wall'

export const useGetCurrentStateForWall = (
  wallId,
  includeNftData = false,
  preferImageUrl = true,
  immediate = true
) => {
  const datasource = useMurAllStateS3DataSourceForWall(wallId)

  const [currentState, setCurrentState] = useState(null)
  const [currentStateTokenData, setCurrentStateTokenData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const apply = useCallback(async () => {
    if (!datasource) {
      setError('useGetCurrentStateForWall - Not initialized')
      return
    }

    setLoading(true)
    setError(false)
    try {
      const fetchedStateData = await datasource.getCurrentMurAllStateData(
        true,
        preferImageUrl
      )
      if (!fetchedStateData) {
        setError('useGetCurrentStateForWall - No current state data')
        setLoading(false)
        return
      }

      setCurrentState(fetchedStateData)

      if (includeNftData) {
        const previousStateData = await datasource.getMurAllStateDataAtId(
          fetchedStateData.tokenId - 1
        )
        const nftData = await datasource.getMurAllStateChangesForTokenIds([
          fetchedStateData.tokenId
        ])
        setCurrentState(previousStateData)
        setCurrentStateTokenData(nftData[0])
      }
      setError(false)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setError(error)
      setLoading(false)
    }
  }, [datasource])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])

  return {
    currentState: currentState,
    currentStateTokenData: currentStateTokenData,
    apply,
    loading,
    error
  }
}

export default useGetCurrentStateForWall
