import { useState, useEffect, useCallback } from 'react'
import { useMurAllStateS3DataSourceForWall } from '../use-murall-s3-state-datasource-for-wall'

export const useGetStateChangesForIdRange = (
  wallId,
  fromTokenId,
  toTokenId,
  immediate = true
) => {
  const datasource = useMurAllStateS3DataSourceForWall(wallId)
  const getMurAllStateChangesForTokenIdRange =
    datasource?.getMurAllStateChangesForTokenIdRange || null

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [statesData, setStatesData] = useState([])

  const apply = useCallback(async () => {
    if (!getMurAllStateChangesForTokenIdRange) {
      return
    }
    try {
      setLoading(true)
      const stateData = await getMurAllStateChangesForTokenIdRange(
        fromTokenId,
        toTokenId
      )

      setStatesData(stateData)
      setLoading(false)
      return stateData
    } catch (error) {
      console.error(error)
      setStatesData(null)
      setError(error)
      setLoading(false)
      return
    }
  }, [
    wallId,
    fromTokenId,
    toTokenId,
    getMurAllStateChangesForTokenIdRange,
    datasource
  ])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, loading, statesData, error }
}

export default useGetStateChangesForIdRange
