import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'underscore'
import clsx from 'clsx'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles((theme) => ({
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  inline: {
    display: 'inline'
  },
  listItem: {
    padding: 0,
    margin: 0
  },
  listItemTypography: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0
  },

  listBgImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
  },
  unselected: {
    backgroundBlendMode: `multiply, normal, normal`
  },
  selected: {
    backgroundBlendMode: `hard-light, normal, normal`
  },

  onTop: {
    zIndex: 999
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  secondaryTypographyContainer: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  }
}))

export default function LoadingPlaceholderListItem(props) {
  const classes = useStyles()

  return (
    <ListItem className={clsx(classes.listItem)} alignItems="flex-start" disableGutters dense divider key={999}>
      <div
        style={{
          backgroundImage: `radial-gradient(circle at top right,
                                           #cccccc,
                                           #4a4a4a,
                                           #212121),
                                           linear-gradient(to right, #26262B, #26262B)`
        }}
        className={clsx(classes.listBgImage, classes.absoluteFill, classes.unselected)}
      />

      <ListItemText
        disableTypography
        className={clsx(classes.typography, classes.onTop, classes.listItemTypography)}
        style={{
          alignSelf: 'center',
          paddingLeft: '16px'
        }}
        primary={
          <Typography component="h5" variant="h5" noWrap className={clsx(classes.typography)} color="textPrimary">
            Loading
          </Typography>
        }
        secondary={
          <div className={clsx(classes.secondaryTypographyContainer)}>
            <Typography
              component="span"
              variant="subtitle1"
              noWrap
              className={clsx(classes.inline, classes.typography)}
              color="textPrimary"
            >
              Please wait...
            </Typography>
          </div>
        }
      ></ListItemText>

      <ListItemSecondaryAction className={clsx(classes.onTop)}>
        <CircularProgress variant="indeterminate" />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
