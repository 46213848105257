import Web3 from 'web3'

const WEBSOCKET_TESTNET =
  'wss://eth-goerli.ws.alchemyapi.io/v2/8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn'
const WEBSOCKET_MAINNET =
  'wss://eth-mainnet.ws.alchemyapi.io/v2/gcQg7HfeVdRNa--z3c9LqMIkAeWF1gjG'

const getWeb3L1 = (testnet = false) => {
  const url = testnet ? WEBSOCKET_TESTNET : WEBSOCKET_MAINNET
  const provider = new Web3.providers.WebsocketProvider(url)

  return new Web3(provider)
}

export default getWeb3L1
