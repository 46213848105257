import React, { useEffect, useState } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import useSmallScreenDetection from '../../../uicomponents/useSmallScreenDetection'
import './../../../../css/layout.css'
import LayerPreview from '../../../../components/common/layers/LayerPreview'
import KeyValueInput from '../../../../components/common/KeyValueInput'
import LayerSelectMenu from '../../../../components/common/layers/LayerSelectMenu'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import ImagePlaceholderView from '../../../../components/common/images/image-placeholder-view'
import SvgLayerPreviewViewer from '../../../../components/common/svg/svg-layer-preview-viewer'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  EVOLV3_FILETYPES,
  MURALL_WALL,
  MURALL_WALL_ID
} from '../../../../lib/constants'
import InfoGrid from '../../../../components/common/InfoGrid'
import useCalculatePaintUsage from '../../../../hooks/evolv3/use-calculate-paint-usage'
import { useValidateVideoLength } from '../../../../hooks/evolv3/use-validate-video-length'
import ProblemIcon from '@mui/icons-material/ReportProblemOutlined'
import Tooltip from '@mui/material/Tooltip'
import useValidateFilesizeForLayer from '../../../../hooks/evolv3/use-validate-filesize-for-layer'
import {
  convertBytesToMegabytes,
  convertTokenToProperDp
} from '../../../libs/appUtils'
import Stack from '@mui/material/Stack'
import ZoomableViewWrapper from '../../../../components/common/ZoomableViewWrapper'
import useValidateBoundsForLayer from '../../../../hooks/evolv3/use-validate-bounds-for-layer'
import useGetPaintBalance from '../../../../hooks/evolv3/use-get-paint-balance'
const BigNumber = require('bignumber.js')
import _ from 'underscore'
import { cloneDeep } from 'lodash'
import config from '../../../config'
import useLayers from '../../../../hooks/layers/use-layers'
import ErrorTextIconTooltip from '../../../../components/common/ErrorTextIconTooltip'

const useStyles = makeStyles(theme => ({
  dialogInformationRoot: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  dialogMedia: {
    backgroundColor: theme.palette.primary.dark,
    objectFit: 'contain'
  }
}))

const handlePaste = event => {
  // Get pasted data
  const paste = (event.clipboardData || window.clipboardData).getData('text')

  // Remove line breaks
  const sanitizedPaste = paste.replace(/(\r\n|\n|\r)/gm, '')

  // Prevent default paste behavior
  event.preventDefault()

  // Manually insert sanitized pasted data
  const position = event.target.selectionStart
  const before = event.target.value.substring(0, position)
  const after = event.target.value.substring(event.target.selectionEnd)
  event.target.value = before + sanitizedPaste + after

  // Optionally: Update some state or dispatch action if you're using redux or similar
}

export default function Evolv3MetadataInputForm({
  name,
  description,
  attributes,
  selectedLayer,
  onDataChange,
  children
}) {
  const classes = useStyles()

  const smallScreen = useSmallScreenDetection()

  const { layers, layerPreviews, loading } = useLayers({
    includeBlockchainDataLayer: false,
    includePreviews: true
  })

  const [nameInternal, setNameInternal] = useState(name)
  const [descriptionInternal, setDescriptionInternal] = useState(description)
  const [attributesInternal, setAttributesInternal] = useState(attributes)
  const [selectedLayerInternal, setSelectedLayerInternal] = useState(
    selectedLayer
  )
  const [previewInPosition, setPreviewInPosition] = useState(false)
  const [enoughPaintToMint, setEnoughPaintToMint] = useState(false)

  const {
    loading: validatingVideoLength,
    maximumVideoLengthMillis,
    valid: isValidVideoLength
  } = useValidateVideoLength({
    fileType:
      selectedLayerInternal?.type === 'video'
        ? EVOLV3_FILETYPES.VIDEO
        : EVOLV3_FILETYPES.IMAGE,
    videoLengthMilliseconds: selectedLayerInternal?.link?.duration * 1000
  })

  const {
    loading: validatingFilesize,
    valid: isValidFilesize,
    layerFilesizeBytes,
    maximumFilesizeBytes
  } = useValidateFilesizeForLayer({ layer: selectedLayerInternal })
  const { loading: calculatingPaintUsage, paintUsage } = useCalculatePaintUsage(
    {
      width: selectedLayerInternal?.width?.toFixed(0),
      height: selectedLayerInternal?.height?.toFixed(0),
      fileType:
        selectedLayerInternal?.type === 'video'
          ? EVOLV3_FILETYPES.VIDEO
          : EVOLV3_FILETYPES.IMAGE,
      videoLengthMilliseconds:
        selectedLayerInternal && selectedLayerInternal?.type === 'video'
          ? (selectedLayerInternal?.link?.duration * 1000).toFixed(0)
          : 0
    }
  )

  const {
    apply: getPaintBalance,
    error,
    loading: fetchingPaintBalance,
    paintBalance
  } = useGetPaintBalance({ immediate: true })

  const {
    dimensions,
    position,
    loading: validatingBounds,
    inBounds: isInBounds
  } = useValidateBoundsForLayer({ layer: selectedLayerInternal })

  useEffect(() => {
    getPaintBalance()
  }, [paintUsage])

  useEffect(() => {
    if (!fetchingPaintBalance && paintBalance && paintUsage) {
      const canMint = new BigNumber(paintBalance.toString()).gte(
        new BigNumber(paintUsage.toString())
      )

      setEnoughPaintToMint(canMint)
    }
  }, [paintBalance, fetchingPaintBalance, paintUsage])

  useEffect(() => {
    let valid = false
    // validate data
    if (nameInternal && selectedLayerInternal) {
      valid = true
      attributesInternal.forEach(attr => {
        if (!attr.name || !attr.value) {
          valid = false
        }
      })
      if (selectedLayerInternal?.type === 'video') {
        valid = !validatingVideoLength && isValidVideoLength
      }
      // check if out of bounds
      if (!isInBounds) {
        valid = false
      }
    }

    onDataChange &&
      onDataChange({
        name: nameInternal,
        description: descriptionInternal,
        attributes: attributesInternal,
        selectedLayer: selectedLayerInternal,
        dimensions,
        position,
        valid: valid && enoughPaintToMint,
        paintUsage
      })
  }, [
    nameInternal,
    descriptionInternal,
    attributesInternal,
    selectedLayerInternal,
    previewInPosition,
    paintUsage,
    isInBounds,
    dimensions,
    position,
    enoughPaintToMint
  ])
  const videoDuration = new Date(selectedLayerInternal?.link?.duration * 1000)

  const formattedVideoDuration = validatingVideoLength
    ? 'loading...'
    : `${
        videoDuration.getUTCHours() > 0
          ? videoDuration.getUTCHours() + ' h '
          : ''
      }
  ${
    videoDuration.getUTCMinutes() > 0
      ? videoDuration.getUTCMinutes() + ' m '
      : ''
  } ${videoDuration.getUTCSeconds()} sec ${videoDuration.getUTCMilliseconds()} ms`

  const formattedFilesize = validatingFilesize
    ? 'loading...'
    : `${convertBytesToMegabytes(layerFilesizeBytes).toFixed(1)} MB`
  const formattedPaintUsage = calculatingPaintUsage
    ? 'calculating...'
    : convertTokenToProperDp(paintUsage) + ' PAINT'

  const formattedPosition = validatingBounds
    ? 'validating position...'
    : `(${position.x}, ${position.y})`
  const formattedWidth = validatingBounds
    ? 'validating width...'
    : `${dimensions.width}`
  const formattedHeight = validatingBounds
    ? 'validating height...'
    : `${dimensions.height}`
  return (
    <Box
      component="form"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        rowGap: '1em',
        pt: '5px'
      }}
    >
      <Typography variant="body2" component="p" color="textSecondary">
        <Typography
          variant="body2"
          component="p"
          color="error"
          display="inline"
        >
          *
        </Typography>{' '}
        Required fields
      </Typography>

      <Header title={'Layer to mint *'} />

      <LayerSelectMenu
        label={'Select Layer'}
        layers={layers}
        loading={loading}
        previewForLayer={layerId => {
          return layerPreviews?.get(layerId)?.croppedBase64PngString
        }}
        onLayerSelected={layer => {
          if (layer && layer.type != 'image' && layer.type != 'video') {
            console.log('layer is not image or video - needs rasterising')
            const clonedLayer = cloneDeep(layer)
            const layerPreviewForLayer = layerPreviews.get(layer.id)

            clonedLayer.type = 'image'
            clonedLayer.name = layer.name + ' + raster'
            clonedLayer.data = layerPreviewForLayer.croppedBase64PngString
            clonedLayer.x = layerPreviewForLayer.positionInformation.start.x
            clonedLayer.y = layerPreviewForLayer.positionInformation.start.y
            clonedLayer.width =
              layerPreviewForLayer.positionInformation.croppedWidth
            clonedLayer.height =
              layerPreviewForLayer.positionInformation.croppedHeight
            clonedLayer.width_original =
              layerPreviewForLayer.positionInformation.croppedWidth
            clonedLayer.height_original =
              layerPreviewForLayer.positionInformation.croppedHeight
            clonedLayer.render_function = 'image'
            clonedLayer.link = new Image()
            clonedLayer.link.src = layerPreviewForLayer.croppedBase64PngString

            setSelectedLayerInternal(clonedLayer)
          } else {
            setSelectedLayerInternal(layer)
          }
        }}
        value={selectedLayerInternal}
        sx={{
          width: '100%'
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={previewInPosition}
            onChange={event => {
              setPreviewInPosition(event.target.checked)
            }}
          />
        }
        label="Preview layer in position on Evolv3"
      />
      <Box
        sx={{
          backgroundColor: 'primary.dark',
          position: 'relative',
          width: '100%',
          aspectRatio: '2/1'
        }}
      >
        {selectedLayerInternal ? (
          <>
            {previewInPosition ? (
              <ZoomableViewWrapper>
                <SvgLayerPreviewViewer
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    aspectRatio: '2/1'
                  }}
                  layer={selectedLayerInternal}
                  baseLayer={
                    config.blockchainDataLayer ||
                    MURALL_WALL[MURALL_WALL_ID.EVOLV3].fallbackLayer
                  }
                />
              </ZoomableViewWrapper>
            ) : (
              <LayerPreview
                layer={selectedLayerInternal}
                style={{
                  position: 'relative',
                  width: '100%',
                  aspectRatio: '2/1'
                }}
                classname={clsx(classes.dialogMedia)}
              />
            )}
          </>
        ) : (
          <ImagePlaceholderView
            sx={{
              width: '100%',
              aspectRatio: '2/1'
            }}
            message={'Select a layer to preview'}
          />
        )}
      </Box>

      <Header title={'Details (scroll down for more fields)'} />

      <TextField
        label="Name"
        value={nameInternal}
        required
        // variant='standard'
        fullWidth
        // color='warning'
        // focused
        onChange={event => {
          setNameInternal(event.target.value)
        }}
      />
      <TextField
        onPaste={handlePaste}
        label="Description"
        value={descriptionInternal}
        // variant='standard'
        fullWidth
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault()
          }
        }}
        multiline
        rows={4}
        onChange={event => {
          setDescriptionInternal(event.target.value)
        }}
      />
      <Header title={'Attributes'} />

      <KeyValueInput
        onAttributesChanged={attributes => {
          setAttributesInternal(attributes)
        }}
        sx={{
          width: '100%'
        }}
      />
      <Header title={'NFT details'} />
      <InfoGrid
        sx={{
          width: '100%',
          height: '100%'
        }}
        dividers
        contents={{
          Width: `${
            !selectedLayerInternal ? 'No layer selected' : formattedWidth
          }`,
          Height: `${
            !selectedLayerInternal ? 'No layer selected' : formattedHeight
          }`,
          Position: !selectedLayerInternal ? (
            'No layer selected'
          ) : isInBounds ? (
            formattedPosition
          ) : (
            <ErrorTextIconTooltip
              text={formattedPosition}
              tooltipText={`Layer is out of bounds. Layer must be within the bounds of the canvas (${CANVAS_WIDTH} x ${CANVAS_HEIGHT})`}
            />
          ),

          'File type': `${
            !selectedLayerInternal
              ? 'No layer selected'
              : selectedLayerInternal?.type === 'video'
              ? 'video'
              : 'image'
          }`,
          'File size': !selectedLayerInternal ? (
            'No layer selected'
          ) : isValidFilesize ? (
            formattedFilesize
          ) : (
            <ErrorTextIconTooltip
              text={formattedFilesize}
              tooltipText={`File size must be less than ${convertBytesToMegabytes(
                maximumFilesizeBytes
              )}MB`}
            />
          ),

          ...(selectedLayerInternal?.type === 'video' &&
            videoDuration && {
              'Video length': isValidVideoLength ? (
                formattedVideoDuration
              ) : (
                <ErrorTextIconTooltip
                  text={formattedVideoDuration}
                  tooltipText={`Video length must be less than ${
                    maximumVideoLengthMillis / 1000
                  } seconds`}
                />
              )
            }),
          'Paint usage to mint 🔥': !selectedLayerInternal ? (
            'No layer selected'
          ) : calculatingPaintUsage ? (
            'calculating...'
          ) : enoughPaintToMint ? (
            formattedPaintUsage
          ) : (
            <ErrorTextIconTooltip
              text={formattedPaintUsage}
              tooltipText={`Your paint balance is too low to mint this layer. You need ${convertTokenToProperDp(
                paintUsage
              )} PAINT`}
            />
          )
        }}
      />
      {children}
    </Box>
  )
}
const Header = ({ title, subtitle }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start'
      }}
    >
      <Typography
        variant="h6"
        component="p"
        color="textPrimary"
        gutterBottom={subtitle ? true : false}
      >
        {title}
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        {subtitle}
      </Typography>
    </Box>
  )
}
