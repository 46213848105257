import _ from 'underscore'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import clsx from 'clsx'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import Collapse from '@mui/material/Collapse'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React, { useRef, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import DeselectIcon from '@mui/icons-material/Deselect'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import IconButton from '@mui/material/IconButton'
import StyledTooltip from './styled_tooltip'

const useStyles = makeStyles(theme => ({
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  defaultBackground: {
    backgroundColor: theme.palette.secondary.dark
  },
  list: {
    minHeight: 0,
    overflowX: 'hidden'
  },
  noPadding: {
    padding: 0,
    margin: 0
  },
  listHeading: {
    margin: 0
  },
  panelDetails: {
    flexDirection: 'column',
    overflow: 'auto'
  },
  onTop: {
    zIndex: 999
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  body: {
    fontSize: 16
  },
  circleIconButton: {
    backgroundColor: theme.palette.primary.main
  },
  artistTypography: {
    fontSize: 10
  }
}))

export default function CollapsableTransferListView (props) {
  const drawerContainer = useRef(null)
  const topListHeader = useRef(null)
  const bottomListHeader = useRef(null)
  const moveListItemsHeader = useRef(null)
  const listInnerRef = useRef()

  const [expandedHeight, setExpandedHeight] = useState(0)
  const [bottomListExpanded, setBottomListExpanded] = useState(true)
  const [topListExpanded, setTopListExpanded] = useState(true)

  const classes = useStyles()
  const theme = useTheme()

  useEffect(() => {
    function handleResize () {
      updateExpandedHeight()
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  useEffect(() => {
    updateExpandedHeight()
    onScroll()
  }, [bottomListExpanded, topListExpanded])

  useEffect(() => {
    onScroll()
  }, [props.topListItems, props.bottomListItems])

  const handleTopListExpandClick = () => {
    setTopListExpanded(!topListExpanded)
  }

  const handleBottomListExpandClick = () => {
    setBottomListExpanded(!bottomListExpanded)
  }

  const updateExpandedHeight = () => {
    if (
      !_.isNull(drawerContainer.current) &&
      !_.isNull(topListHeader.current) &&
      !_.isNull(moveListItemsHeader.current) &&
      !_.isNull(bottomListHeader.current)
    ) {
      const height =
        drawerContainer.current.clientHeight -
        topListHeader.current.offsetHeight -
        moveListItemsHeader.current.offsetHeight -
        bottomListHeader.current.offsetHeight

      setExpandedHeight(
        bottomListExpanded && topListExpanded ? height / 2 : height
      )
    }
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight === scrollHeight) {
        props.onloadMoreTopListItems()
      }
    }
  }

  const constructListHeading = (
    listHeaderRef,
    title,
    expanded,
    expandClickAction,
    selectClickAction,
    deselectClickAction
  ) => {
    return (
      <ListItem
        className={clsx(classes.transparent, classes.listHeading)}
        ref={listHeaderRef}
        divider
      >
        <ListItemText
          disableTypography
          primary={
            <Typography
              component='body1'
              variant='body1'
              noWrap
              className={clsx(classes.typographyLight)}
              color='textPrimary'
            >
              {title}
            </Typography>
          }
        />

        <StyledTooltip title={'Select All'} enterDelay={500}>
          <IconButton
            variant='contained'
            size='small'
            onClick={selectClickAction}
          >
            <CheckBoxIcon />
          </IconButton>
        </StyledTooltip>

        <StyledTooltip title={'Deselect All'} enterDelay={500}>
          <IconButton
            variant='contained'
            size='small'
            onClick={deselectClickAction}
          >
            <DeselectIcon />
          </IconButton>
        </StyledTooltip>

        {expanded ? (
          <StyledTooltip title={'Hide list'} enterDelay={500}>
            <IconButton
              variant='contained'
              size='small'
              onClick={expandClickAction}
            >
              <ExpandLess />
            </IconButton>
          </StyledTooltip>
        ) : (
          <StyledTooltip title={'Show list'} enterDelay={500}>
            <IconButton
              variant='contained'
              size='small'
              onClick={expandClickAction}
            >
              <ExpandMore />
            </IconButton>
          </StyledTooltip>
        )}
      </ListItem>
    )
  }

  return (
    <List
      ref={drawerContainer}
      style={{ width: '100%', height: '100%' }}
      className={clsx(
        classes.defaultBackground,
        classes.list,
        classes.noPadding
      )}
    >
      {constructListHeading(
        topListHeader,
        props.topListTitle,
        topListExpanded,
        handleTopListExpandClick,
        props.onSelectAllTopListClick,
        props.onDeselectAllTopListClick
      )}

      <Collapse
        className={clsx(classes.panelDetails)}
        in={topListExpanded}
        timeout='auto'
        unmountOnExit
        style={{}}
      >
        <List
          className={clsx(classes.transparent, classes.list, classes.noPadding)}
          onScroll={() => onScroll()}
          ref={listInnerRef}
          style={{
            backgroundImage: 'url(images/repeat_bricks.jpg)',
            backgroundBlendMode: 'normal',
            height: expandedHeight + 'px'
          }}
        >
          {props.topListItems &&
            props.topListItems.map((listItem, index) =>
              props.constructTopListItem(listItem, index)
            )}
          {props.showLoadingPlaceholder &&
            props.constructLoadingPlaceholderListItem()}
        </List>
      </Collapse>
      <ListItem
        className={clsx(classes.transparent, classes.listHeading)}
        ref={moveListItemsHeader}
        divider
      >
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-around'
          spacing={2}
          style={{ width: '100%' }}
        >
          <Grid item>
            <IconButton
              variant='contained'
              size='small'
              className={classes.circleIconButton}
              onClick={props.onSelectedToBottomClicked}
              disabled={props.selectedToBottomDisabled || !topListExpanded}
              aria-label='move selected down'
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              variant='contained'
              size='small'
              className={classes.circleIconButton}
              onClick={props.onSelectedToTopClicked}
              disabled={props.selectedToTopDisabled || !bottomListExpanded}
              aria-label='move selected up'
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>

      {constructListHeading(
        bottomListHeader,
        props.bottomListTitle,
        bottomListExpanded,
        handleBottomListExpandClick,
        props.onSelectAllBottomListClick,
        props.onDeselectAllBottomListClick
      )}

      <Collapse
        className={clsx(classes.panelDetails)}
        in={bottomListExpanded}
        timeout='auto'
        unmountOnExit
        style={{}}
      >
        <DragDropContext onDragEnd={props.onBottomListDragEnd}>
          <Droppable droppableId={props.bottomListId}>
            {(provided, snapshot) => (
              <List
                style={{
                  backgroundBlendMode: snapshot.isDraggingOver
                    ? `screen, normal, normal`
                    : `normal, normal, normal`,
                  backgroundImage: snapshot.isDraggingOver
                    ? 'radial-gradient(circle at top right, #E91BFAcc 30%, #7E0AE5),url(images/repeat_bricks.jpg)'
                    : 'url(images/repeat_bricks.jpg)',
                  height: expandedHeight + 'px'
                }}
                className={clsx(
                  classes.transparent,
                  classes.list,
                  classes.noPadding
                )}
                // innerRef={provided.innerRef}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {props.bottomListItems &&
                  props.bottomListItems.map((listItem, index) =>
                    props.constructBottomListItem(listItem, index)
                  )}

                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Collapse>
    </List>
  )
}
