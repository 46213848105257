import React, { useEffect, useState } from 'react'
import Get_image_for_layer_usecase from '../../../js/modules/blockchain/usecase/get_image_for_layer_usecase'
import { LoadingSpinner } from '../../../js/uicomponents/loading_spinner'
import { Box } from '@mui/material'

export default function LayerPreview ({ layer, classname, style }) {
  const getImageForLayerUsecase = new Get_image_for_layer_usecase()

  const [layerPreview, setLayerPreview] = useState(null)
  const [loadingPreview, setLoadingPreview] = useState(false)

  useEffect(() => {
    async function fetchLayerPreview () {
      setLoadingPreview(true)
      const newLayerPreview = await getImageForLayerUsecase.execute(layer)
      setLayerPreview(newLayerPreview)
      setLoadingPreview(false)
    }
    if (!layer) {
      return
    }
    if (layer.type === 'image') {
      fetchLayerPreview()
    }
  }, [layer])

  return (
    <>
      {loadingPreview ? (
        <Box className={classname} style={style}>
          <LoadingSpinner />
        </Box>
      ) : (
        <>
          {layer?.type === 'image' && layerPreview && (
            <img
              style={style}
              className={classname}
              src={layerPreview.croppedBase64PngString}
              alt={'image to draw'}
            />
          )}
          {layer?.type === 'video' && (
            <video
              style={style}
              className={classname}
              src={layer.data}
              alt={'video to draw'}
              autoPlay
              loop
              muted
              controls
            />
          )}
        </>
      )}
    </>
  )
}
