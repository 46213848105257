import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { L2_CHAINS, MURALL_WALL_ID, SUPPORTED_CHAINS } from '../lib/constants'

const getNetworkName = networkId => {
  if (!SUPPORTED_CHAINS.includes(networkId)) return ''
  return L2_CHAINS.includes(networkId) ? 'polygon' : 'ethereum'
}

const getPathForWallId = wallId => {
  switch (wallId) {
    case MURALL_WALL_ID.LAYER_1:
      return 'ethereum'
    case MURALL_WALL_ID.LAYER_2:
      return 'polygon'
    case MURALL_WALL_ID.EVOLV3:
      return 'evolv3'
    default:
      return 'ethereum'
  }
}

export const API = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.AWS_API_URL}/v1`
  }),
  endpoints: builder => ({
    getTokens: builder.query({
      query: ({ wallId, queryParams }) => {
        const url = queryParams
          ? `${getPathForWallId(wallId)}/tokens?${new URLSearchParams(
              queryParams
            )}`
          : `${getPathForWallId(wallId)}/tokens`

        return {
          url,
          method: 'GET'
        }
      }
    }),
    getToken: builder.query({
      query: ({ wallId, id }) => ({
        url: `${getPathForWallId(wallId)}/tokens/${id}`,
        method: 'GET'
      })
    }),
    getTokensForRange: builder.query({
      query: ({ wallId, fromId, toId }) => ({
        url: `${getPathForWallId(wallId)}/tokens?from=${fromId}&to=${toId}`,
        method: 'GET'
      })
    }),
    getCachedToken: builder.query({
      query: ({ wallId, id }) => ({
        url: `${getPathForWallId(wallId)}/tokens/${id}?cached=true`,
        method: 'GET'
      })
    }),
    getMontage: builder.query({
      query: ({ wallId, id }) => ({
        url: `${getPathForWallId(wallId)}/montages/${id}`,
        method: 'GET'
      })
    }),
    getCurrentState: builder.query({
      query: wallId => ({
        url: `${getPathForWallId(wallId)}/states/current`,
        method: 'GET'
      })
    }),
    getArtistTokens: builder.query({
      query: ({ wallId, artist }) => ({
        url: `${getPathForWallId(wallId)}/artists/${artist}/nfts`,
        method: 'GET'
      })
    }),
    getArtistStatistics: builder.query({
      query: ({ wallId, artist }) => ({
        url: `${getPathForWallId(wallId)}/artists/${artist}/stats`,
        method: 'GET'
      })
    }),
    getArtistsWithMostTokens: builder.query({
      query: ({ wallId, limit }) => {
        const url = `${getPathForWallId(wallId)}/statistics/artists/tokens`
        return {
          url: limit ? `${url}?limit=${limit}` : url,
          method: 'GET'
        }
      }
    }),
    getArtistsWithMostPixels: builder.query({
      query: ({ wallId, limit }) => {
        const url = `${getPathForWallId(wallId)}/statistics/artists/pixels`
        return {
          url: limit ? `${url}?limit=${limit}` : url,
          method: 'GET'
        }
      }
    }),
    getPaintMaxSupply: builder.query({
      query: networkId => ({
        url: `${getNetworkName(networkId)}/statistics/tokens/paint/supply/max`,
        method: 'GET'
      })
    }),
    getPaintTotalSupply: builder.query({
      query: networkId => ({
        url: `${getNetworkName(
          networkId
        )}/statistics/tokens/paint/supply/total`,
        method: 'GET'
      })
    }),
    getPaintCirculatingSupply: builder.query({
      query: networkId => ({
        url: `${getNetworkName(
          networkId
        )}/statistics/tokens/paint/supply/circulating`,
        method: 'GET'
      })
    })
  })
})

export const {
  useGetTokensQuery,
  useGetTokensForRangeQuery,
  useGetTokenQuery,
  useGetCachedTokenQuery,
  useGetMontageQuery,
  useGetCurrentStateQuery,
  useGetArtistTokensQuery,
  useGetArtistStatisticsQuery,
  useGetArtistsWithMostTokensQuery,
  useGetArtistsWithMostPixelsQuery,
  useGetPaintMaxSupplyQuery,
  useGetPaintTotalSupplyQuery,
  useGetPaintCirculatingSupplyQuery
} = API
