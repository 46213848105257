import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import { SwapWidget, darkTheme } from '@uniswap/widgets'
import { useActiveWeb3React } from '../../../hooks/web3'
import { useTheme } from '@mui/material/styles'
import { PAINT_TOKEN_ADDRESS } from '../../../lib/constants'
import { DefaultContainer } from '../../../components/common/DefaultContainer'
import WalletDisplay from '../../../components/common/wallet/display'
import { NetworkProviderHttpUrls } from '../../../lib/connectors/urls'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    color: theme.palette.primary.contrastText,
    boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + 'B3',
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px'
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  emptyViewContainer: {
    width: '100%',
    padding: theme.spacing(2),
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  }
}))

const TOKEN_LIST =
  'https://murall.mypinata.cloud/ipfs/QmZsVgDHQNuDwvB2ssQFzcnLuNKGaCRgZ6i89mBMg9QsuK'

const NATIVE = 'NATIVE' // Special address for native token

export default function TokenSwapWidget (props) {
  const classes = useStyles()
  const { account, library, chainId } = useActiveWeb3React()

  const theme = useTheme()

  const widgetTheme = {
    ...darkTheme, // Extend the darkTheme
    accent: theme.palette.primary.main,
    primary: theme.palette.primary.contrastText,
    container: theme.colors.bg.main,
    module: theme.colors.bg.lighter,
    secondary: '#888D9B',
    fontFamily: 'Roboto',
    borderRadius: 0.8
  }

  return (
    <DefaultContainer className={classes.root}>
      {account && library && library.eth && (
        <SwapWidget
          hideConnectionUI
          className={classes.root}
          theme={widgetTheme}
          width='100%'
          permit2
          jsonRpcUrlMap={NetworkProviderHttpUrls}
          defaultInputTokenAddress={NATIVE}
          tokenList={TOKEN_LIST}
          defaultOutputTokenAddress={PAINT_TOKEN_ADDRESS}
          forceRenderOnLocaleChange={false}
          onError={error => {
            console.error('Error in trade widget', error)
          }}
        />
      )}
      {!account && (
        <div className={classes.emptyViewContainer}>
          <Typography
            variant='h6'
            className={classes.typographyLight}
            gutterBottom
          >
            Connect your wallet to buy Paint
          </Typography>
          <WalletDisplay />
        </div>
      )}
    </DefaultContainer>
  )
}
