import React from 'react'

import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'underscore'
import { Draggable } from 'react-beautiful-dnd'
import clsx from 'clsx'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import './../../../css/layout.css'
import { ListItemButton } from '@mui/material'
import { useTheme } from 'styled-components'

const useStyles = makeStyles(theme => ({
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  inline: {
    display: 'inline'
  },
  listItem: {
    minHeight: theme.spacing(13),
    backgroundBlendMode: `multiply, normal, normal`,
    padding: 0,
    margin: 0
  },
  listItemTypography: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0
  },

  listBgImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundBlendMode: props =>
      props.selected
        ? `hard-light, normal, normal`
        : `multiply, normal, normal`,
    backgroundImage: props =>
      props.selected
        ? `radial-gradient(circle at top right,
          ${theme.palette.secondary.main}cc 30%,
          ${theme.palette.primary.main}),
          url(${props.nftInformation.croppedBase64PngString}),
          linear-gradient(to right, #26262B, #26262B)`
        : `radial-gradient(circle at top right,
      #cccccc,
      #4a4a4a,
      #212121),
      url(${props.nftInformation.croppedBase64PngString}),
      linear-gradient(to right, #26262B, #26262B)`
  },
  selectedGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },
  onTop: {
    zIndex: 999
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  secondaryTypographyContainer: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  }
}))

export default function NftDrawerListItem (props) {
  const classes = useStyles(props)
  const theme = useTheme()

  const listItemContents = provided => (
    <ListItemButton
      focusRipple
      className={clsx(classes.listItem, 'pixelated-image', classes.listBgImage)}
      onClick={event => {
        if (props.disableSelect) {
          props.onCheckboxClicked(props.nftInformation, props.id)
          return
        }
        props.onListItemClick(event, props.nftInformation, props.id)
      }}
    >
      {!props.disableDrag && (
        <IconButton
          {...provided.dragHandleProps}
          className={clsx(classes.icon, classes.onTop)}
          edge='start'
          aria-label='draghandle'
          style={{
            alignSelf: 'center',
            paddingLeft: '16px'
          }}
          size='large'
        >
          <DragHandleIcon />
        </IconButton>
      )}
      <Checkbox
        className={clsx(classes.onTop)}
        edge='end'
        checked={props.checked}
        tabIndex={-1}
        inputProps={{ 'aria-labelledby': props.id }}
        style={{
          alignSelf: 'center'
        }}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          props.onCheckboxClicked(props.nftInformation, props.id)
        }}
      />
      <ListItemText
        disableTypography
        style={{
          alignSelf: 'center',
          paddingLeft: '16px'
        }}
        className={clsx(
          classes.typography,
          classes.onTop,
          classes.listItemTypography
        )}
        primary={
          <Typography
            component='h5'
            variant='h5'
            noWrap
            className={clsx(classes.typography)}
            color='textPrimary'
          >
            {props.primaryText}
          </Typography>
        }
        secondary={
          <div className={clsx(classes.secondaryTypographyContainer)}>
            {props.secondaryText1 && (
              <Typography
                component='span'
                variant='subtitle1'
                noWrap
                className={clsx(classes.inline, classes.typography)}
                color='textPrimary'
              >
                {props.secondaryText1}
              </Typography>
            )}
            {props.secondaryText2 && (
              <Typography
                component='span'
                variant='subtitle2'
                noWrap
                className={clsx(classes.inline, classes.typography)}
                color={props.selected ? 'textPrimary' : 'textSecondary'}
              >
                {props.secondaryText2}
              </Typography>
            )}
          </div>
        }
      />

      <ListItemSecondaryAction className={clsx(classes.onTop)}>
        <IconButton
          className={clsx(classes.icon, classes.onTop)}
          edge='end'
          aria-label='info'
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            props.onNftInfoClicked(props.nftInformation)
          }}
          style={{
            alignSelf: 'center'
          }}
          size='large'
        >
          <InfoIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  )

  return props.disableDrag ? (
    <ListItem
      className={clsx(
        classes.listItem,
        {
          [classes.onTop]: props.selected
        },
        {
          [classes.selectedGlow]: props.selected
        }
      )}
      sx={{
        // hover states
        '& .MuiListItemButton-root:hover': {
          backgroundImage: props.selected
            ? `radial-gradient(circle at top right,
          ${theme.palette.secondary.main}80 30%,
          ${theme.palette.primary.main})80,
          url(${props.nftInformation.croppedBase64PngString}),
          linear-gradient(to right, #26262B, #26262B)`
            : `radial-gradient(circle at top right,
      #cccccc80,
      #4a4a4a80,
      #21212180),
      url(${props.nftInformation.croppedBase64PngString}),
      linear-gradient(to right, #26262B, #26262B)`,
          '&, & .MuiTypography-root': {
            textShadow: '2px 2px 5px black'
          }
        }
      }}
      alignItems='flex-start'
      disableGutters
      dense
      divider
      key={props.id}
      selected={props.selected}
    >
      {listItemContents()}
    </ListItem>
  ) : (
    <Draggable
      key={props.id}
      draggableId={props.id.toString()}
      index={props.index}
    >
      {(provided, snapshot) => (
        <ListItem
          className={clsx(
            classes.listItem,
            {
              [classes.onTop]: props.selected
            },
            {
              [classes.selectedGlow]: props.selected
            }
          )}
          sx={{
            // hover states
            '& .MuiListItemButton-root:hover': {
              backgroundImage: props.selected
                ? `radial-gradient(circle at top right,
          ${theme.palette.secondary.main}80 30%,
          ${theme.palette.primary.main})80,
          url(${props.nftInformation.croppedBase64PngString}),
          linear-gradient(to right, #26262B, #26262B)`
                : `radial-gradient(circle at top right,
      #cccccc80,
      #4a4a4a80,
      #21212180),
      url(${props.nftInformation.croppedBase64PngString}),
      linear-gradient(to right, #26262B, #26262B)`,
              '&, & .MuiTypography-root': {
                textShadow: '2px 2px 5px black'
              }
            }
          }}
          {...provided.draggableProps}
          ref={provided.innerRef}
          alignItems='flex-start'
          disableGutters
          dense
          divider
          key={props.id}
          selected={props.selected}
        >
          {listItemContents(provided)}
        </ListItem>
      )}
    </Draggable>
  )
}
