import React, { useEffect, useState } from 'react'

import { useMurAllEvolv3NftDataSource } from '../use-murall-evolv3-nft-datasource.js'
import { EVOLV3_FILETYPES } from '../../lib/constants.js'

export const useValidateVideoLength = ({
  videoLengthMilliseconds,
  fileType
}) => {
  const [loading, setLoading] = useState(false)

  const [valid, setValid] = useState(false)
  const [maximumVideoLengthMillis, setMaximumVideoLengthMillis] = useState(0)
  const { getMaximumVideoLengthMillis } = useMurAllEvolv3NftDataSource()

  useEffect(() => {
    if (fileType !== EVOLV3_FILETYPES.VIDEO) {
      setValid(true)
      return
    }
    async function checkIsValid () {
      setLoading(true)
      const maximumVideoLengthMillis = await getMaximumVideoLengthMillis()
      setMaximumVideoLengthMillis(maximumVideoLengthMillis)
      setValid(videoLengthMilliseconds <= maximumVideoLengthMillis)
      setLoading(false)
    }
    checkIsValid()
  }, [fileType, videoLengthMilliseconds])

  return { valid, maximumVideoLengthMillis, loading }
}

export default useValidateVideoLength
