import React from 'react'

import clsx from 'clsx'
import Skeleton from '@mui/material/Skeleton'
import _ from 'underscore'

import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

import Typography from '@mui/material/Typography'

import Divider from '@mui/material/Divider'

import CardHeader from '@mui/material/CardHeader'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.contrastText
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  cardActions: {
    // backgroundColor: '#000000'
  },
  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  colouredLineBorder: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: 10
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}))

export default function AuctionDetailsLoadingPlaceholderCard (props) {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        disableTypography
        title={
          <Typography
            className={clsx(classes.typographyLight)}
            variant='h3'
            component='h3'
            color='textPrimary'
          >
            <Skeleton />
          </Typography>
        }
        subheader={
          <Typography
            className={clsx(classes.typographyLight)}
            variant='h5'
            component='h5'
            color='textPrimary'
          >
            <Skeleton />
          </Typography>
        }
      />
      <CardContent>
        <Divider variant='middle' />
        <Typography
          className={clsx(classes.typographyLight)}
          style={{ marginTop: '24px' }}
          variant='h6'
          component='h6'
          color='textPrimary'
        >
          <Skeleton />
        </Typography>
        <Typography
          className={clsx(classes.typographyLight)}
          variant='h4'
          component='h4'
          color='textPrimary'
          gutterBottom
        >
          <Skeleton />
        </Typography>{' '}
        <Typography
          className={clsx(classes.typographyLight)}
          style={{ marginTop: '24px' }}
          variant='h6'
          component='h6'
          color='textPrimary'
        >
          <Skeleton />
        </Typography>
        <Typography
          className={clsx(classes.typographyLight)}
          variant='h4'
          component='h4'
          color='textPrimary'
        >
          <Skeleton />
        </Typography>
        <Typography
          className={clsx(classes.typographyLight)}
          variant='h6'
          component='h6'
          color='textPrimary'
        >
          <Skeleton />
        </Typography>
      </CardContent>

      <CardActions className={clsx(classes.cardActions)}>
        <div
          className={clsx(classes.flexColumn)}
          style={{
            margin: '24px'
          }}
        >
          <Skeleton variant='text' />
        </div>
      </CardActions>
    </Card>
  )
}
