import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import clsx from 'clsx'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import config from '../../config.js'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import CardHeader from '@mui/material/CardHeader'
import Skeleton from '@mui/material/Skeleton'
import Web3 from 'web3'
import { convertTokenToProperDp, truncate } from '../../libs/appUtils'
import { SupportedChain } from '../../../lib/constants.js'

const BigNumber = require('bignumber.js')

const useStyles = makeStyles(theme => ({
  root: {
    // borderRadius: 10,
    backgroundColor: 'transparent',
    color: theme.palette.primary.contrastText
    // boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + 'B3'
    // border: 'solid',
    // borderColor: theme.palette.secondary.main,
    // borderWidth: '1px'
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  cardActions: {
    // backgroundColor: '#000000'
  },
  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  colouredLineBorder: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: 10
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}))

const DEFAULT_HELPER_TEXT = 'Enter Bid Amount'
const validNumber = value => /(\d+(?:\.\d+)?)/.test(value) && value > 0

export default function AuctionDetailsCard (props) {
  const classes = useStyles()

  const [timeLeft, setTimeLeft] = useState('')
  const [timeToAuctionStart, setTimeToAuctionStart] = useState('')
  const [auctionStarted, setAuctionStarted] = useState(false)
  const [auctionEnded, setAuctionEnded] = useState(false)
  const [bidAmount, setBidAmount] = useState('')
  const [isValidBid, setIsValidBid] = useState(false)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState(DEFAULT_HELPER_TEXT)

  const currentBidderAddress = props.auctionInformation.currentBidderAddress
  const auctionPrice = props.auctionInformation.auctionPrice
  const auctionStartTimeSeconds =
    props.auctionInformation.auctionStartTimeSeconds
  const auctionEndTimeSeconds = props.auctionInformation.auctionEndTimeSeconds
  const tokenSymbol = props.tokenInformation.symbol
  const decimals = props.tokenInformation.decimals
  const name = props.nftInformation ? props.nftInformation.name : null

  const priceProperDp = convertTokenToProperDp(auctionPrice, decimals)

  useEffect(() => {
    updateTimeLeft()
    updateTimeToAuctionStart()
    const interval = setInterval(() => {
      updateTimeLeft()
      updateTimeToAuctionStart()
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://platform.twitter.com/widgets.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const updateTimeToAuctionStart = () => {
    const countDownDate = new Date(
      Web3.utils.toBN(auctionStartTimeSeconds).toNumber() * 1000
    ) //convert to milliseconds
    // Get today's date and time
    const now = new Date().getTime()

    // Find the distance between now and the count down date
    const distance = countDownDate - now

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      .toString()
      .padStart(2, '0')
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
      .toString()
      .padStart(2, '0')
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, '0')

    setTimeToAuctionStart(
      distance < 0
        ? 'AUCTION STARTED!'
        : days + 'd : ' + hours + 'h : ' + minutes + 'm : ' + seconds + 's'
    )
    setAuctionStarted(distance < 0)
  }

  const updateTimeLeft = () => {
    const countDownDate = new Date(
      Web3.utils.toBN(auctionEndTimeSeconds).toNumber() * 1000
    ) //convert to milliseconds
    // Get today's date and time
    const now = new Date().getTime()

    // Find the distance between now and the count down date
    const distance = countDownDate - now

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      .toString()
      .padStart(2, '0')
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
      .toString()
      .padStart(2, '0')
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, '0')

    setTimeLeft(
      distance < 0
        ? 'AUCTION ENDED!'
        : days + 'd : ' + hours + 'h : ' + minutes + 'm : ' + seconds + 's'
    )
    setAuctionEnded(distance < 0)
  }

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        // style={{ marginLeft: '24px' }}
        // avatar={
        //   <Avatar
        //     aria-label='recipe'
        //     className={clsx(classes.avatar)}
        //     src={'/images/100_percent_onchain.png'}
        //   />
        // }
        disableTypography
        title={
          <Typography
            className={clsx(classes.typographyLight)}
            variant='h3'
            component='h3'
            color='textPrimary'
          >
            {name ? name : <Skeleton />}
          </Typography>
        }
        subheader={
          <Typography
            className={clsx(classes.typographyLight)}
            variant='h5'
            component='h5'
            color='textPrimary'
          >
            {name ? 'By Graffiti Kings' : <Skeleton />}
          </Typography>
        }
      />
      <CardContent>
        <Divider variant='middle' />
        {!auctionStarted && (
          <React.Fragment>
            <Typography
              className={clsx(classes.typographyLight)}
              style={{ marginTop: '24px' }}
              variant='h6'
              component='h6'
              color='textPrimary'
            >
              Time til auction begins:
            </Typography>
            <Typography
              className={clsx(classes.typographyLight)}
              variant='h4'
              component='h4'
              color='textPrimary'
              gutterBottom
            >
              {timeToAuctionStart}
            </Typography>
          </React.Fragment>
        )}
        {auctionStarted && (
          <React.Fragment>
            <Typography
              className={clsx(classes.typographyLight)}
              style={{ marginTop: '24px' }}
              variant='h6'
              component='h6'
              color='textPrimary'
            >
              Time left:
            </Typography>
            <Typography
              className={clsx(classes.typographyLight)}
              variant='h4'
              component='h4'
              color='textPrimary'
              gutterBottom
            >
              {timeLeft}
            </Typography>{' '}
          </React.Fragment>
        )}
        <Typography
          className={clsx(classes.typographyLight)}
          style={{ marginTop: '24px' }}
          variant='h6'
          component='h6'
          color='textPrimary'
        >
          {auctionEnded
            ? 'Final bid:'
            : auctionStarted
            ? 'Current bid:'
            : 'Starting bid:'}
        </Typography>
        <Typography
          className={clsx(classes.typographyLight)}
          variant='h4'
          component='h4'
          color='textPrimary'
        >
          {tokenSymbol ? (
            `${priceProperDp.toFixed()} ${tokenSymbol}`
          ) : (
            <Skeleton />
          )}
        </Typography>
        {auctionStarted && props.nftInformation && (
          <Typography
            className={clsx(classes.typographyLight)}
            variant='h6'
            component='h6'
            color='textPrimary'
          >
            <Link
              href={
                props.nftInformation.chainId === SupportedChain.Polygon
                  ? `https://polygonscan.com/address/${currentBidderAddress}`
                  : `https://etherscan.io/address/${currentBidderAddress}`
              }
              color='secondary'
            >
              {config.account != null &&
              currentBidderAddress.toUpperCase() ===
                config.account.toUpperCase()
                ? 'You'
                : truncate(currentBidderAddress, 6, 4)}
            </Link>
          </Typography>
        )}
        {auctionEnded &&
          config.account != null &&
          currentBidderAddress.toUpperCase() ===
            config.account.toUpperCase() && (
            <Typography
              className={clsx(classes.typographyLight)}
              variant='h4'
              component='h4'
              color='primary'
            >
              CONGRATULATIONS!
            </Typography>
          )}
      </CardContent>
      {auctionStarted && (
        <CardActions className={clsx(classes.cardActions)}>
          <div
            className={clsx(classes.flexColumn)}
            style={{
              margin: '24px'
            }}
          >
            {!auctionEnded && (
              <div className={clsx(classes.flexRow)}>
                <TextField
                  style={{ flex: 1, alignSelf: 'center' }}
                  label={'Bid'}
                  error={error}
                  value={bidAmount}
                  variant='outlined'
                  helperText={errorText}
                  onChange={e => {
                    if (e.target.value == '' || validNumber(e.target.value)) {
                      setBidAmount(e.target.value)
                      setErrorText(DEFAULT_HELPER_TEXT)
                      setError(false)
                      const valid = new BigNumber(e.target.value).isGreaterThan(
                        priceProperDp
                      )
                      setIsValidBid(props.bidButtonEnabled ? valid : false)
                    }
                  }}
                  margin='normal'
                  InputProps={{
                    endAdornment: (
                      <Button
                        size='medium'
                        color='secondary'
                        onClick={() => {
                          const priceProperDp = new BigNumber(
                            bidAmount
                          ).multipliedBy(
                            new BigNumber(10).exponentiatedBy(
                              new BigNumber(decimals.toString())
                            )
                          )
                          props.onBidClicked(
                            name,
                            props.auctionInformation.auctionId,
                            priceProperDp.toFixed(),
                            bidAmount
                          )
                        }}
                        variant='contained'
                        disabled={props.bidButtonEnabled ? !isValidBid : true}
                      >
                        {props.bidButtonEnabled
                          ? 'Bid'
                          : 'Connect wallet to bid'}
                      </Button>
                    )
                  }}
                />
              </div>
            )}
            {auctionEnded &&
              config.account != null &&
              currentBidderAddress.toUpperCase() ===
                config.account.toUpperCase() && (
                <Button
                  size='large'
                  color='secondary'
                  onClick={() => {
                    props.onPurchaseClicked(
                      name,
                      props.auctionInformation.auctionId,
                      priceProperDp.toFixed(),
                      auctionPrice
                    )
                  }}
                  variant='contained'
                  disabled={!props.bidButtonEnabled}
                >
                  {props.bidButtonEnabled
                    ? 'Complete purchase'
                    : 'Connect wallet to complete purchase'}
                </Button>
              )}
            <a
              href='https://twitter.com/share?ref_src=twsrc%5Etfw'
              className='twitter-share-button'
              data-size='large'
              data-text='🎨🖌️MurAll x Graffiti Kings❤️‍🔥 Charity Auction is LIVE!!!'
              data-url='https://murall.art/auction'
              data-via='MurAll_art'
              data-hashtags='NFTsForGood'
              data-related='GraffitiKINGS'
              data-show-count='false'
            >
              Tweet
            </a>
          </div>
        </CardActions>
      )}
    </Card>
  )
}
