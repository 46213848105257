import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ErrorMessageView from '../../components/common/error-view'
import { LoadingSpinner } from '../../js/uicomponents/loading_spinner'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  MURALL_WALL,
  MURALL_WALL_ID,
  SupportedChain
} from '../../lib/constants'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import {
  useGetArtistStatisticsQuery,
  useGetArtistTokensQuery
} from '../../state/api'
import NftCard from '../../js/pages/nfts/nftCard'
import MontageNftCard from '../../js/pages/nfts/montageNftCard'
import {
  getMontageContractAddress,
  getMurAllNFTContractAddress
} from '../../js/modules/blockchain/datasource/ContractAddressDataSource'
import { Grid, Box } from '@mui/material'
import { BigNumber } from 'bignumber.js'
import LoadingPlaceholderNftCard from '../../js/pages/nfts/loadingPlaceholderNftCard'
import useMediaQuery from '@mui/material/useMediaQuery'
import FilterSelect from '../../components/common/filterSelect'
import InfoPlaque from './infoPlaque'
import DefaultVirtualizedGrid from '../../components/common/DefaultVirtualizedGrid'
import { mapInformationToNftObject } from '../../js/modules/blockchain/NftDataMapper'
import { useEns } from '../../hooks/useEns'
import StyledInfoBox from '../../components/common/StyledInfoBox'
import { useEnsReverse } from '../../hooks/useEnsReverse'
import { ethers } from 'ethers'
const useStyles = makeStyles(theme => ({
  root: {
    // padding: '24px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  gridList: {
    width: '100%',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  }
}))

const artistDataForWallId = ({ artist, wallId, chainId }) => {
  const skip = !artist || artist === '' || artist === undefined

  const [tokens, setTokens] = useState([])

  const {
    data: stats,
    error: errorFetchingStats
  } = useGetArtistStatisticsQuery(
    { wallId, artist },
    {
      skip
    }
  )

  const {
    data: tokensData,
    error: errorFetchingTokens
  } = useGetArtistTokensQuery(
    { wallId, artist },
    {
      skip
    }
  )

  useEffect(() => {
    if (!tokensData) return

    // loop through the list setting the chain id
    const tokens = tokensData.map(token => {
      return {
        ...token,
        chainId,
        wallId
      }
    })

    setTokens(tokens)
  }, [tokensData])

  return {
    stats,
    errorFetchingStats,
    tokens,
    errorFetchingTokens
  }
}

const ArtistPage = () => {
  let { address } = useParams()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const classes = useStyles()

  const [burnedPaint, setBurnedPaint] = useState(0)
  const [pixelsDrawn, setPixelsDrawn] = useState(0)
  const [nftsCreated, setNftsCreated] = useState(0)
  const [nftsCreatedOnEth, setNftsCreatedOnEth] = useState(0)
  const [nftsCreatedOnPolygon, setNftsCreatedOnPolygon] = useState(0)
  const [nftsCreatedOnEvolv3, setNftsCreatedOnEvolv3] = useState(0)
  const [allItems, setAllItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const noDpFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const isEnsName = address && address.endsWith('.eth')
  const isAddress =
    address && ethers.utils.isAddress(address) && address.length === 42

  if (!isAddress && !isEnsName) {
    return (
      <ErrorMessageView
        title="Invalid Address"
        description={'Invalid address or ENS name'}
      />
    )
  }

  const artistAddress = isAddress
    ? ethers.utils.getAddress(address)
    : useEnsReverse(address)
  const artistEns = isEnsName ? address : useEns(artistAddress)

  const {
    stats: artistStatsEthereum,
    errorFetchingStats: errorFetchingArtistStatsEth,
    tokens: artistTokensEthereum,
    errorFetchingTokens: errorFetchingEth
  } = artistDataForWallId({
    artist: artistAddress,
    wallId: MURALL_WALL_ID.LAYER_1,
    chainId: SupportedChain.Ethereum
  })
  const {
    stats: artistStatsPolygon,
    errorFetchingStats: errorFetchingArtistStatsPolygon,
    tokens: artistTokensPolygon,
    errorFetchingTokens: errorFetchingPolygon
  } = artistDataForWallId({
    artist: artistAddress,
    wallId: MURALL_WALL_ID.LAYER_2,
    chainId: SupportedChain.Polygon
  })

  const {
    stats: artistStatsEvolv3,
    errorFetchingStats: errorFetchingArtistStatsEvolv3,
    tokens: artistTokensEvolv3,
    errorFetchingTokens: errorFetchingEvolv3
  } = artistDataForWallId({
    artist: artistAddress,
    wallId: MURALL_WALL_ID.EVOLV3,
    chainId: SupportedChain.Polygon
  })

  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    if (!artistTokensEthereum || !artistTokensPolygon || !artistTokensEvolv3)
      return

    // combine the arrays
    const allTokens = artistTokensEthereum
      .concat(artistTokensPolygon)
      .concat(artistTokensEvolv3)
    setAllItems(allTokens)
    setFilteredItems(allTokens)
    setInitialLoading(false)
  }, [artistTokensEthereum, artistTokensPolygon, artistTokensEvolv3])

  useEffect(() => {
    if (
      errorFetchingArtistStatsEth ||
      errorFetchingArtistStatsPolygon ||
      errorFetchingArtistStatsEvolv3
    ) {
      setInitialLoading(false)
      console.error(
        'errorFetchingArtistStats',
        errorFetchingArtistStatsEth,
        errorFetchingArtistStatsPolygon,
        errorFetchingArtistStatsEvolv3
      )
    }
    if (!artistStatsEthereum && !artistStatsPolygon && !artistStatsEvolv3) {
      return
    }
    BigNumber.set({ DECIMAL_PLACES: 4, ROUNDING_MODE: 3 })
    let totalBurnedPaint = new BigNumber(0)
    let totalPixelsDrawn = new BigNumber(0)
    let artworksOnEtherem = new BigNumber(0)
    let artworksOnPolygon = new BigNumber(0)
    let artworksOnEvolv3 = new BigNumber(0)
    let totalArtworks = new BigNumber(0)
    if (artistStatsEthereum) {
      totalBurnedPaint = totalBurnedPaint.plus(
        new BigNumber(artistStatsEthereum.burnedPaint)
      )
      totalPixelsDrawn = totalPixelsDrawn.plus(
        new BigNumber(artistStatsEthereum.pixelsDrawn)
      )
      artworksOnEtherem = new BigNumber(artistStatsEthereum.mintedTokens)
    }
    if (artistStatsPolygon) {
      totalBurnedPaint = totalBurnedPaint.plus(
        new BigNumber(artistStatsPolygon.burnedPaint)
      )
      totalPixelsDrawn = totalPixelsDrawn.plus(
        new BigNumber(artistStatsPolygon.pixelsDrawn)
      )
      artworksOnPolygon = new BigNumber(artistStatsPolygon.mintedTokens)
    }
    if (artistStatsEvolv3) {
      totalBurnedPaint = totalBurnedPaint.plus(
        new BigNumber(artistStatsEvolv3.burnedPaint)
      )
      totalPixelsDrawn = totalPixelsDrawn.plus(
        new BigNumber(artistStatsEvolv3.pixelsDrawn)
      )
      artworksOnEvolv3 = new BigNumber(artistStatsEvolv3.mintedTokens)
    }

    totalArtworks = artworksOnEtherem.plus(artworksOnPolygon)
    setBurnedPaint(formatter.format(totalBurnedPaint))
    setPixelsDrawn(noDpFormatter.format(totalPixelsDrawn))
    setNftsCreated(noDpFormatter.format(totalArtworks))
    setNftsCreatedOnEth(noDpFormatter.format(artworksOnEtherem))
    setNftsCreatedOnPolygon(noDpFormatter.format(artworksOnPolygon))
    setNftsCreatedOnEvolv3(noDpFormatter.format(artworksOnEvolv3))
  }, [artistStatsPolygon, artistStatsEthereum, artistStatsEvolv3])

  const convertToNftInformation = (nft, wallId) => {
    const wallInfo = MURALL_WALL[wallId]
    return mapInformationToNftObject({
      tokenId: nft.id,
      image: nft.image,
      name: nft.name,
      creator: nft.artist,
      chainId: wallInfo.chainId,
      positionInformation: {
        start: {
          x: nft.dimensions.coordinates.x,
          y: nft.dimensions.coordinates.y
        },
        end: {
          x: nft.dimensions.coordinates.x + nft.dimensions.width,
          y: nft.dimensions.coordinates.y + nft.dimensions.height
        },
        width: nft.dimensions.width,
        height: nft.dimensions.height
      },
      additionalInformation: {
        number: nft.number,
        seriesId: nft.seriesId,
        artist: nft.artist,
        croppedBase64PngString: nft.image,
        fullBase64PngString: nft.image,
        wallId: wallId
      }
    })
  }
  const convertToMontageInformation = (nft, wallId) => {
    const wallInfo = MURALL_WALL[wallId]
    return mapInformationToNftObject({
      tokenId: nft.id,
      image: nft.image,
      name: nft.name,
      creator: nft.artist,
      chainId: wallInfo.chainId,
      positionInformation: {
        start: { x: 0, y: 0 },
        end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT
      },
      additionalInformation: {
        wallId: wallId,
        number: nft.number,
        seriesId: nft.seriesId,
        artist: nft.artist,
        croppedBase64PngString: nft.image,
        fullBase64PngString: nft.image
      }
    })
  }

  const createInfoPlaque = () => (
    <StyledInfoBox
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        display: 'block',
        borderWidth: '0 0 3px 0' /* top right bottom left */,
        borderRadius: '0px',
        borderStyle: 'solid',
        borderColor: 'transparent'
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent={'center'}
      >
        <Grid item xs={12} sm={'auto'}>
          <InfoPlaque
            artistAddress={artistAddress}
            artistEns={artistEns}
            burnedPaint={burnedPaint}
            pixelsDrawn={pixelsDrawn}
            nftsCreated={nftsCreated}
            nftsCreatedOnEth={nftsCreatedOnEth}
            nftsCreatedOnPolygon={nftsCreatedOnPolygon}
            nftsCreatedOnEvolv3={nftsCreatedOnEvolv3}
          />
        </Grid>
        <Grid item xs={12} sm={'auto'}>
          {createFilterView()}
        </Grid>
      </Grid>
    </StyledInfoBox>
  )

  const createFilterView = () => (
    <FilterSelect
      sx={{
        width: isMobile ? '100%' : 300,
        ml: { xs: '0px', sm: '24px' },
        mr: { xs: '0px', sm: '24px' }
      }}
      filterName={'Filter'}
      filterOptions={[
        'Montages',
        'Artworks',
        MURALL_WALL[MURALL_WALL_ID.LAYER_1].name,
        MURALL_WALL[MURALL_WALL_ID.LAYER_2].name,
        MURALL_WALL[MURALL_WALL_ID.EVOLV3].name
      ]}
      onChange={values => {
        if (values.length === 0) {
          setFilteredItems(allItems)
          return
        }
        const shouldIncludeMontages = values.includes('Montages')
        const shouldIncludeArtworks = values.includes('Artworks')
        const shouldIncludeLayer1 = values.includes(
          MURALL_WALL[MURALL_WALL_ID.LAYER_1].name
        )
        const shouldIncludeLayer2 = values.includes(
          MURALL_WALL[MURALL_WALL_ID.LAYER_2].name
        )
        const shouldIncludeEvolv3 = values.includes(
          MURALL_WALL[MURALL_WALL_ID.EVOLV3].name
        )

        const filteredItems = allItems.filter(item => {
          const isMontage = item.type === 'montage'
          const isArtwork = item.type === 'murall'
          const isLayer1 = item.wallId === MURALL_WALL_ID.LAYER_1
          const isLayer2 = item.wallId === MURALL_WALL_ID.LAYER_2
          const isEvolv3 = item.wallId === MURALL_WALL_ID.EVOLV3

          const layer1Only =
            shouldIncludeLayer1 && !shouldIncludeLayer2 && !shouldIncludeEvolv3
          const layer2Only =
            shouldIncludeLayer2 && !shouldIncludeLayer1 && !shouldIncludeEvolv3
          const evolv3Only =
            shouldIncludeEvolv3 && !shouldIncludeLayer1 && !shouldIncludeLayer2

          const includesArtworkTypeFilter =
            shouldIncludeMontages || shouldIncludeArtworks

          // Checking for network selection and type
          if (layer1Only) {
            if (includesArtworkTypeFilter) {
              return (
                isLayer1 &&
                ((shouldIncludeMontages && isMontage) ||
                  (shouldIncludeArtworks && isArtwork))
              )
            } else {
              return isLayer1
            }
          }
          if (layer2Only) {
            if (includesArtworkTypeFilter) {
              return (
                isLayer2 &&
                ((shouldIncludeMontages && isMontage) ||
                  (shouldIncludeArtworks && isArtwork))
              )
            } else {
              return isLayer2
            }
          }
          if (evolv3Only) {
            if (includesArtworkTypeFilter) {
              return (
                isEvolv3 &&
                ((shouldIncludeMontages && isMontage) ||
                  (shouldIncludeArtworks && isArtwork))
              )
            } else {
              return isEvolv3
            }
          }

          return (
            (shouldIncludeMontages && isMontage) ||
            (shouldIncludeArtworks && isArtwork) ||
            (shouldIncludeEvolv3 && isEvolv3) || // New logic for Evolv3 artworks
            (shouldIncludeLayer1 && isLayer1) ||
            (shouldIncludeLayer2 && isLayer2)
          )
        })

        setFilteredItems(filteredItems)
      }}
    />
  )

  return !artistAddress || initialLoading ? (
    <LoadingSpinner />
  ) : errorFetchingEth || errorFetchingPolygon || errorFetchingEvolv3 ? (
    <ErrorMessageView title="Network Error" description="Try again later" />
  ) : (
    <div className={clsx(classes.absoluteFill, classes.root)}>
      {createInfoPlaque()}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
          flex: 1
        }}
      >
        <DefaultVirtualizedGrid
          style={{ flex: 1, width: '100%' }}
          items={filteredItems}
          loadingPlaceholder={<LoadingPlaceholderNftCard />}
          renderItem={(index, nft) => {
            if (!nft || nft === undefined || nft === null) {
              return <LoadingPlaceholderNftCard />
            }
            console.log('nft', nft)
            if (nft.type === 'murall') {
              return (
                <NftCard
                  sx={{
                    maxWidth: '100%'
                  }}
                  key={nft.id}
                  address={getMurAllNFTContractAddress(nft.chainId)}
                  nftInformation={convertToNftInformation(nft, nft.wallId)}
                  ens={artistEns}
                  hideArtistAddress
                />
              )
            } else {
              return (
                <MontageNftCard
                  sx={{
                    maxWidth: '100%'
                  }}
                  key={nft.id}
                  address={getMontageContractAddress(nft.chainId)}
                  nftInformation={convertToMontageInformation(nft, nft.wallId)}
                  ens={artistEns}
                  shouldFetchInfo
                  hideArtistAddress
                />
              )
            }
          }}
        />
      </Box>
    </div>
  )
}

export default ArtistPage
