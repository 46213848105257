import React from 'react'
import { useSelector } from 'react-redux'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import layer1Theme from './layer1'
import layer2Theme from './layer2'
import evolv3Theme from './evolv3'
import { MURALL_WALL_ID } from '../lib/constants'

const getTheme = wallId => {
  switch (wallId) {
    case MURALL_WALL_ID.LAYER_1:
      return layer1Theme
    case MURALL_WALL_ID.LAYER_2:
      return layer2Theme
    case MURALL_WALL_ID.EVOLV3:
      return evolv3Theme
    default:
      return layer1Theme
  }
}

const MurAllThemeProvider = props => {
  const currentWall = useSelector(state => state.wall)
  const theme = getTheme(currentWall.id)
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          {props.children}
        </StyledThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default MurAllThemeProvider
