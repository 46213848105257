import { createSlice } from '@reduxjs/toolkit'

const promoSlice = createSlice({
  name: 'promo',
  initialState: { hide: false },
  reducers: {
    setHidePromo: (state, action) => {
      state.hide = action.payload
      return state
    }
  }
})

export const { setHidePromo } = promoSlice.actions

export default promoSlice.reducer
