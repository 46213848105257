import L1Logo from '../../images/logos/murall/l1.svg'
import L2Logo from '../../images/logos/murall/l2.svg'
import Evolv3Logo from '../../images/logos/murall/evolv3.svg'
import RMRKLogo from '../../images/logos/powered_by_rmrk_vert.svg'
import RMRKLogoHoriz from '../../images/logos/powered_by_rmrk.svg'
import Revo1tSvgComponent from '../components/common/images/revo1t-svg'
import Di2ruptSvgComponent from '../components/common/images/di2rupt-svg'
import Evolv3SvgComponent from '../components/common/images/evolv3-svg'

export const FALLBACK_PROVIDER_NAME = 'FALLBACK'
export const FALLBACKL2_PROVIDER_NAME = 'FALLBACKL2'
export const MAX_UINT =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935'
export const IS_IN_IFRAME = window.parent !== window

export const SupportedChain = {
  Ethereum: 1,
  Ropsten: 3,
  Rinkeby: 4,
  Goerli: 5,
  Polygon: 137,
  Mumbai: 80001,
  LocalhostL1: 123456789,
  LocalhostL2: 987654321
}

export const SUPPORTED_CHAINS = [
  SupportedChain.Ethereum,
  SupportedChain.Ropsten,
  SupportedChain.Rinkeby,
  SupportedChain.Goerli,
  SupportedChain.Polygon,
  SupportedChain.Mumbai,
  SupportedChain.LocalhostL1,
  SupportedChain.LocalhostL2
]

export const L2_CHAINS = [
  SupportedChain.Polygon,
  SupportedChain.Mumbai,
  SupportedChain.LocalhostL2
]

export const CHAIN_INFO = {
  [SupportedChain.Ethereum]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 }
  },
  [SupportedChain.Rinkeby]: {
    explorer: 'https://rinkeby.etherscan.io/',
    label: 'Rinkeby',
    nativeCurrency: { name: 'Rinkeby ETH', symbol: 'rinkETH', decimals: 18 }
  },
  [SupportedChain.Ropsten]: {
    explorer: 'https://ropsten.etherscan.io/',
    label: 'Ropsten',
    nativeCurrency: { name: 'Ropsten ETH', symbol: 'ropETH', decimals: 18 }
  },
  [SupportedChain.Goerli]: {
    explorer: 'https://goerli.etherscan.io/',
    label: 'Görli',
    nativeCurrency: { name: 'Görli ETH', symbol: 'görETH', decimals: 18 }
  },
  [SupportedChain.Polygon]: {
    explorer: 'https://polygonscan.com/',
    label: 'Polygon',
    nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
    rpcUrls: [
      'https://polygon-rpc.com',
      'wss://rpc-mainnet.matic.network',
      'https://rpc-mainnet.maticvigil.com'
    ]
  },
  [SupportedChain.Mumbai]: {
    explorer: 'https://mumbai.polygonscan.com/',
    label: 'Mumbai',
    rpcUrls: [
      'https://rpc-mumbai.maticvigil.com/',
      'https://rpc-mumbai.matic.today',
      'wss://rpc-mumbai.matic.today'
    ],
    nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 }
  }
}

export const PAINT_TOKEN_ADDRESS = {
  [SupportedChain.Ethereum]: '0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042',
  [SupportedChain.Polygon]: '0x7c28F627eA3aEc8B882b51eb1935f66e5b875714',
  [SupportedChain.Mumbai]: '0x8C67Ef6a8503D05268c55C1ea5e6bF1c47Bfe249',
  [SupportedChain.Goerli]: '0x7AD58D4e96f0F116CB4aF0f852F6F652ec43C397'
}

export const PAINT_TOKEN_INFO = {
  [SupportedChain.Ethereum]: {
    name: 'Paint',
    address: PAINT_TOKEN_ADDRESS[SupportedChain.Ethereum],
    symbol: 'PAINT',
    decimals: 18,
    chainId: SupportedChain.Ethereum,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042/logo.png'
  },

  [SupportedChain.Polygon]: {
    name: 'Paint',
    address: PAINT_TOKEN_ADDRESS[SupportedChain.Polygon],
    symbol: 'PAINT',
    decimals: 18,
    chainId: SupportedChain.Polygon,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/assets/0x7c28F627eA3aEc8B882b51eb1935f66e5b875714/logo.png'
  }
}

export const SCHEDULER_TYPES = {
  DDPM: 'DDPMScheduler',
  PNDM: 'PNDMScheduler',
  EulerAncestralDiscrete: 'EulerAncestralDiscreteScheduler',
  DDIM: 'DDIMScheduler',
  LMSDiscrete: 'LMSDiscreteScheduler',
  EulerDiscrete: 'EulerDiscreteScheduler',
  DPMSolverMultistep: 'DPMSolverMultistepScheduler',
  HeunDiscrete: 'HeunDiscreteScheduler',
  DPMSolverSinglestep: 'DPMSolverSinglestepScheduler',
  UniPCMultistep: 'UniPCMultistepScheduler'
}

export const EVOLV3_FILETYPES = {
  IMAGE: 0,
  VIDEO: 1
}

export const MURALL_WALL_ID = {
  LAYER_1: 1,
  LAYER_2: 2,
  EVOLV3: 3,
  TESTNET: 4
}
export const MURALL_WALL_QUERY_PARAM = {
  [MURALL_WALL_ID.LAYER_1]: 'revo1t',
  [MURALL_WALL_ID.LAYER_2]: 'di2rupt',
  [MURALL_WALL_ID.EVOLV3]: 'evolv3',
  [MURALL_WALL_ID.TESTNET]: 'evolv3'
}

export const getWallIdFromQueryParam = queryParam => {
  switch (queryParam) {
    case MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.LAYER_1]:
      return MURALL_WALL_ID.LAYER_1
    case MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.LAYER_2]:
      return MURALL_WALL_ID.LAYER_2
    case MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.EVOLV3]:
      return MURALL_WALL_ID.EVOLV3
    default:
      return MURALL_WALL_ID.LAYER_1
  }
}

export const getDefaultWallIdForChain = chainId => {
  switch (chainId) {
    case SupportedChain.Ethereum:
      return MURALL_WALL_ID.LAYER_1
    case SupportedChain.Polygon:
      return MURALL_WALL_ID.EVOLV3
    case SupportedChain.Goerli:
      return MURALL_WALL_ID.TESTNET
    default:
      return MURALL_WALL_ID.LAYER_1
  }
}

export const getWallIdForContractAddress = contractAddress => {
  switch (contractAddress.toString().toLowerCase()) {
    case MURALL_WALL[MURALL_WALL_ID.LAYER_1].nftContractAddress
      .toString()
      .toLowerCase():
      return MURALL_WALL_ID.LAYER_1
    case MURALL_WALL[MURALL_WALL_ID.LAYER_2].nftContractAddress
      .toString()
      .toLowerCase():
      return MURALL_WALL_ID.LAYER_2
    case MURALL_WALL[MURALL_WALL_ID.LAYER_2].bridgedNftContractAddress
      .toString()
      .toLowerCase():
      return MURALL_WALL_ID.LAYER_2
    case MURALL_WALL[MURALL_WALL_ID.LAYER_2].montageNftContractAddress
      .toString()
      .toLowerCase():
      return MURALL_WALL_ID.LAYER_2
    case MURALL_WALL[MURALL_WALL_ID.LAYER_2].bridgedMontageNftContractAddress
      .toString()
      .toLowerCase():
      return MURALL_WALL_ID.LAYER_2
    case MURALL_WALL[MURALL_WALL_ID.EVOLV3].nftContractAddress
      .toString()
      .toLowerCase():
      return MURALL_WALL_ID.EVOLV3
    default:
      return null
  }
}

export const DUMMY_LAYER = {
  id: 123476378,
  parent_id: 0,
  name: 'dummy layer',
  type: null,
  link: null,
  x: 0,
  y: 0,
  width: CANVAS_WIDTH,
  width_original: CANVAS_WIDTH,
  height: CANVAS_HEIGHT,
  height_original: CANVAS_HEIGHT,
  visible: true,
  is_vector: false,
  opacity: 100,
  order: 0,
  composition: 'source-over',
  rotate: 0,
  data: 'https://images.murall.art/polygon/montages/30/montage.png',
  params: {},
  status: null,
  color: '#ff00ff',
  alphaEnabled: true,
  filters: [],
  render_function: null
}

export const MURALL_WALL = {
  [MURALL_WALL_ID.LAYER_1]: {
    id: MURALL_WALL_ID.LAYER_1,
    name: 'Revo1t',
    description: 'The original MurAll',
    murallLogo: L1Logo,
    svgComponent: Revo1tSvgComponent,
    chainId: SupportedChain.Ethereum,
    murAllContractAddress: '0x6442bDfd16352726AA25Ad6b3CBAb3865c05ED15',
    nftContractAddress: '0x5BB3d01F32687f92F8636799687E55cba0627704',
    queryParam: MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.LAYER_1],
    fallbackLayer: {
      ...DUMMY_LAYER,
      data: `https://images.murall.art/ethereum/state.png`
    }
  },
  [MURALL_WALL_ID.LAYER_2]: {
    id: MURALL_WALL_ID.LAYER_2,
    name: 'Di2rupt',
    description: 'The second MurAll',
    murallLogo: L2Logo,
    svgComponent: Di2ruptSvgComponent,
    chainId: SupportedChain.Polygon,
    murAllContractAddress: '0x5647b9D293E31076c859361E1499f5c29724FA97',
    nftContractAddress: '0xFfbce889D62F6C73f005B836255aD69B0a01b222',
    bridgedNftContractAddress: '0x125d9e25d4ae2dcfa43d9303d3e549b8a6f2ba42',
    montageNftContractAddress: '0xb200c550749c8f996376e7dbf56b368bc0967b8e',
    bridgedMontageNftContractAddress:
      '0x42ea05fb3d5cf4ef3927e370dc2beffd453195d3',
    queryParam: MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.LAYER_2],
    fallbackLayer: {
      ...DUMMY_LAYER,
      data: `https://images.murall.art/polygon/state.png`
    }
  },
  [MURALL_WALL_ID.EVOLV3]: {
    id: MURALL_WALL_ID.EVOLV3,
    name: 'Evolv3',
    description: 'The third murAll',
    murallLogo: Evolv3Logo,
    svgComponent: Evolv3SvgComponent,
    chainId: SupportedChain.Polygon,
    murAllContractAddress: '0x962f182c8E49fC2D9E4385202bDBe5F7A8578415',
    nftContractAddress: '0x962f182c8E49fC2D9E4385202bDBe5F7A8578415',
    queryParam: MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.EVOLV3],
    fallbackLayer: {
      ...DUMMY_LAYER,
      data: `https://images.murall.art/evolv3/state.png`
    }
  }
  // [MURALL_WALL_ID.TESTNET]: {
  //   id: 4,
  //   name: 'Testnet',
  //   description: 'The testnet',
  //   murallLogo: Evolv3Logo,
  //   svgComponent: Evolv3SvgComponent,
  //   chainId: SupportedChain.Goerli,
  //   murAllContractAddress: '0xE078517ABc2E59b6c0439b98916667A0aD2E2680',
  //   nftContractAddress: '0x36c3338819A27fCF0a080394e0813E41650C5887',
  //   queryParam: MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.TESTNET],
  //   fallbackLayer: {
  //     ...DUMMY_LAYER,
  //     data: `https://images.murall.art/evolv3/state.png`
  //   }
  // }
}

export const CANVAS_WIDTH = 2048
export const CANVAS_HEIGHT = 1024

export const SUPPORTED_THX_EVENTS = {
  CHECK_IN: 'daily_check_in',
  VIEW_REVO1T: 'view_revo1t',
  VIEW_DI2RUPT: 'view_di2rupt',
  VIEW_EVOLV3: 'view_evolv3',
  EMOTE_EVOLV3: 'emote_evolv3'
}

export const TRANSPARENT_IMAGE_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
export const DUMMY_PREVIEW_DATA = {
  fullBase64PngString: TRANSPARENT_IMAGE_DATA_URL,
  croppedBase64PngString: TRANSPARENT_IMAGE_DATA_URL,
  positionInformation: {
    start: { x: 0, y: 0 },
    end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
    width: CANVAS_WIDTH,
    height: CANVAS_HEIGHT
  }
}

export const DESCRIPTION_PLACEHOLDER_EASTER_EGG = `Words confine. Art liberates.🎨`
export const DESCRIPTION_PLACEHOLDER = '\u00A0' // blank space

export const MAX_HISTORY_REQUEST_RANGE = 50

export const EVOLV3_CONTRACT_INFO = {
  [SupportedChain.Mumbai]: {
    MinimalForwarder: '0x903a955265139ed83474D35784B281A617828D4f',
    MurAllEvolv3NFT: '0x5d49e1ff818f52fc853D6787e6C3A8B519bb476B',
    PaintToken: '0x8C67Ef6a8503D05268c55C1ea5e6bF1c47Bfe249'
  },
  [SupportedChain.Polygon]: {
    MinimalForwarder: '0xe53D57a0b28dAEFd7F6BaE5BBb10d83FF70d7118',
    MurAllEvolv3NFT: '0x962f182c8E49fC2D9E4385202bDBe5F7A8578415',
    PaintToken: '0x7c28F627eA3aEc8B882b51eb1935f66e5b875714'
  }
}

export const RMRK_LOGO = {
  vertical: RMRKLogo,
  horizontal: RMRKLogoHoriz
}
const emojis = ['🔥', '😄', '🌟', '👍', '❤️', '🍕', '🚀', '🌈', '🐱', '🎉']

export const DUMMY_EMOJI_COUNTS_LIST = Array.from({ length: 100 }, () => {
  return {
    emoji: emojis[Math.floor(Math.random() * emojis.length)],
    count: Math.floor(Math.random() * 100)
  }
})
export const DUMMY_EMOJI_LIST = Array.from({ length: 50 }, () => {
  return emojis[Math.floor(Math.random() * emojis.length)]
})
