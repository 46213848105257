import { useCallback, useEffect, useState } from 'react'
import {
  getMurAllNFTContractAddress,
  getMontageContractAddress
} from '../js/modules/blockchain/datasource/ContractAddressDataSource'
import {
  useMontageContract,
  useMurAllNFTContract
} from './use-contract-for-chain'
import { useMontageDataSource } from './use-montage-datasource'
import { useMurAllNftDataSource } from './use-murall-nft-datasource'
import { useActiveWeb3React } from './web3'
import Web3 from 'web3'
import { useSelector } from 'react-redux'
import { MURALL_WALL, MURALL_WALL_ID, SupportedChain } from '../lib/constants'
// returns null on errors

export const useMurAllCompositeNftDataSource = wallId => {
  let wallIdToUse = wallId
  const currentWallId = useSelector(state => state.wall.id)
  if (!wallIdToUse) {
    wallIdToUse = currentWallId
  }
  if (wallIdToUse === MURALL_WALL_ID.EVOLV3) {
    console.error(
      'useMurAllDataSource not supported for EVOLV3 - use useMurAllEvolv3NftDataSource instead. Falling back to MURALL_WALL_ID.LAYER_2 which is also on Polygon'
    )
    wallIdToUse = MURALL_WALL_ID.LAYER_2
  }
  const wallInfo = MURALL_WALL[wallIdToUse]
  const { account } = useActiveWeb3React()
  const montageContract = useMontageContract(wallInfo.chainId)
  const { getMontageFullInformation } = useMontageDataSource(wallIdToUse)
  const murAllNftContract = useMurAllNFTContract(wallInfo.chainId)
  const murAllNftDatasource = useMurAllNftDataSource(wallIdToUse)
  const getOffchainRemoteTokenTransactionDataForId =
    murAllNftDatasource?.getOffchainRemoteTokenTransactionDataForId || null

  const [nftList, setNftList] = useState([])
  const [isLoading, setLoading] = useState(true)

  const init = useCallback(async () => {
    if (
      !murAllNftContract ||
      !murAllNftDatasource ||
      (!montageContract && wallInfo.chainId === SupportedChain.Polygon)
    ) {
      console.error('Contracts not loaded')
      setLoading(false)
      return
    }
    setLoading(true)
    const nftInitialiserList = []

    const supportedContracts = [
      {
        address: getMurAllNFTContractAddress(wallInfo.chainId),
        balanceOf: address => {
          return murAllNftContract.methods.balanceOf(address).call()
        },
        tokenOfOwnerByIndex: (account, index) => {
          return murAllNftContract.methods
            .tokenOfOwnerByIndex(account, index)
            .call()
        },
        getter: tokenId => {
          return getOffchainRemoteTokenTransactionDataForId(tokenId, true)
        }
      },
      {
        address: getMontageContractAddress(wallInfo.chainId),
        balanceOf: address => {
          return montageContract
            ? montageContract.methods.balanceOf(address).call()
            : null
        },
        tokenOfOwnerByIndex: (account, index) => {
          return montageContract
            ? montageContract.methods.tokenOfOwnerByIndex(account, index).call()
            : null
        },
        getter: tokenId => {
          return montageContract ? getMontageFullInformation(tokenId) : null
        }
      }
    ]

    // get balances for each contract address
    for (var i = 0; i < supportedContracts.length; i++) {
      if (supportedContracts[i].address) {
        const nftBalance = await supportedContracts[i].balanceOf(account)
        const nftBalanceNumber = Web3.utils.toBN(nftBalance).toNumber()

        const indexes = [...Array(nftBalanceNumber).keys()]
        const mappedNftInfo = await _.map(indexes, function (index) {
          return {
            address: supportedContracts[i].address,
            index,
            tokenOfOwnerByIndex: supportedContracts[i].tokenOfOwnerByIndex,
            getter: supportedContracts[i].getter
          }
        })
        nftInitialiserList.push(mappedNftInfo)
      } else {
        console.error('Contract not loaded', supportedContracts[i])
      }
    }

    setNftList(_.flatten(nftInitialiserList))
    setLoading(false)
  }, [
    murAllNftContract,
    montageContract,
    wallIdToUse,
    currentWallId,
    murAllNftDatasource
  ])

  useEffect(() => {
    init()
  }, [init]) // Empty array ensures that effect is only run on mount

  return {
    isLoading,
    getBalance: () => {
      return nftList.length
    },

    getNftInfoForIndexRange: async (startIndex, endIndex) => {
      if (startIndex >= nftList.length || startIndex < 0) {
        throw new Error(
          'startIndex ' +
            startIndex +
            ' out of index bounds - balance ' +
            nftList.length
        )
      }
      if (endIndex >= nftList.length || endIndex < 0) {
        throw new Error(
          'endIndex ' +
            endIndex +
            ' out of index bounds - balance ' +
            nftList.length
        )
      }

      const returnData = []
      for (var i = startIndex; i <= endIndex; i++) {
        const { index, getter, tokenOfOwnerByIndex } = nftList[i]

        const tokenId = await tokenOfOwnerByIndex(account, index)

        const currentNftInfo = await getter(tokenId)

        returnData.push(currentNftInfo)
      }

      return returnData
    }
  }
}
