import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { shortenAddress } from '../../../lib/web3/utils'
import Typography from '@mui/material/Typography'
import { useEns } from '../../../hooks/useEns'

const EnsAddress = ({
  address,
  short,
  showAddressSubtitle,
  titleProps,
  titleSx,
  subtitleProps,
  subtitleSx,
  ensOverride
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const charsToShowAtEnd = isMobile ? 3 : 4

  const ens = useEns(address)
  const ensToDisplay = ensOverride || ens
  return (
    <>
      <Typography
        {...titleProps}
        sx={{
          ...titleSx
        }}
      >
        {ensToDisplay
          ? ensToDisplay
          : short
          ? shortenAddress(address, charsToShowAtEnd)
          : address}
      </Typography>
      {ensToDisplay && showAddressSubtitle && (
        <Typography
          {...subtitleProps}
          sx={{
            ...subtitleSx
          }}
        >
          {short ? shortenAddress(address, charsToShowAtEnd) : address}
        </Typography>
      )}
    </>
  )
}

export default EnsAddress
