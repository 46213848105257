import React, { useContext, useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardMedia from '@mui/material/CardMedia'
import clsx from 'clsx'
import './../../../css/layout.css'
import MontageInformationDialog from '../../uicomponents/montage_information_dialog'
import StyledProgress from '../../uicomponents/styled_progress'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  MURALL_WALL_QUERY_PARAM,
  SupportedChain,
  getWallIdForContractAddress
} from '../../../lib/constants'
import { CardActions, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useGetMontageQuery } from '../../../state/api'
import EnsAddress from '../../../components/common/wallet/ensAddress'
import { mapInformationToNftObject } from '../../modules/blockchain/NftDataMapper'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px'
  },
  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%' // square
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  avatar: {
    // backgroundColor: theme.palette.primary.dark,
    backgroundColor: '#000000',
    // backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  menuIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },

  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212100,
            #21212199,
            #212121B3)`
  }
}))

export default function MontageNftCard ({
  shouldFetchInfo,
  nftInformation,
  ens,
  sx,
  hideArtistAddress,
  address
}) {
  const classes = useStyles()
  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [fetchedInfo, setFetchedInfo] = useState(false)

  const contractAddress = nftInformation?.contractAddress || address
  const wallId = nftInformation.wallId || getWallIdForContractAddress(contractAddress)

  const { data: fetchedMontageNftData, error } = useGetMontageQuery(
    {
      wallId: nftInformation.wallId,
      id: nftInformation.tokenId
    },
    { skip: !shouldFetchInfo }
  )

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const constructNftInfoDialog = () => {
    return (
      <MontageInformationDialog
        open={open}
        onClose={handleClose}
        montageInformation={fetchedInfo || nftInformation}
        hideInformation
      />
    )
  }

  useEffect(() => {
    if (fetchedMontageNftData && !fetchedInfo) {
      const nftInfo = mapInformationToNftObject({
        ...fetchedMontageNftData,
        chainId: nftInformation.chainId,
        tokenId: nftInformation.tokenId,
        positionInformation: {
          start: { x: 0, y: 0 },
          end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
          width: CANVAS_WIDTH,
          height: CANVAS_HEIGHT
        },
        additionalInformation: {
          croppedBase64PngString: fetchedMontageNftData.image,
          fullBase64PngString: fetchedMontageNftData.image,
          artist: fetchedMontageNftData.creator
        }
      })

      setFetchedInfo(nftInfo)
    }
  }, [fetchedMontageNftData])

  const tokenId = fetchedInfo ? fetchedInfo.tokenId : nftInformation.tokenId
  const image = fetchedInfo ? fetchedInfo.image : nftInformation.image
  const name = fetchedInfo ? fetchedInfo.name : nftInformation.name

  const chainId = fetchedInfo
    ? fetchedInfo.chainId
    : nftInformation.bridged
    ? SupportedChain.Polygon
    : nftInformation.chainId

  return (
    <Card
      className={classes.root}
      elevation={0}
      sx={{
        ...sx
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label='tokenId'
            src={
              chainId === SupportedChain.Polygon
                ? '/images/montage_l2.png'
                : '/images/montage_l1.png'
            }
            className={clsx(classes.avatar)}
          />
        }
        title={name}
        subheader={
          !hideArtistAddress && (
            <EnsAddress
              address={nftInformation.artist}
              ensOverride={ens}
              titleSx={{
                fontFamily: 'Roboto',
                fontWeight: 300,
                fontStyle: 'normal'
              }}
              titleProps={{
                color: 'textSecondary',
                align: 'left',
                variant: 'subtitle2'
              }}
              short
            />
          )
        }
        titleTypographyProps={{
          align: 'left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        subheaderTypographyProps={{
          fontFamily: 'Roboto',
          align: 'left',
          whiteSpace: 'nowrap',
          fontWeight: 100,
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      />
      <CardActionArea onClick={handleOpen}>
        <CardMedia
          className={clsx('pixelated-image', classes.media)}
          image={image}
          title={'#' + tokenId + ': ' + name}
          alt={'#' + tokenId + 'has not been filled!'}
        >
          <div className={clsx(classes.overlay, classes.overlayBackground)}>
            <StyledProgress variant='determinate' value={100} />
          </div>
        </CardMedia>
      </CardActionArea>
      <CardActions>
        <Button
          size='small'
          onClick={() => {
            history.push(
              `/montage/${MURALL_WALL_QUERY_PARAM[wallId]}/${tokenId}`
            )
          }}
        >
          More Details
        </Button>
      </CardActions>
      {open && constructNftInfoDialog()}
    </Card>
  )
}
