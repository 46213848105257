import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import Button from '@mui/material/Button'
import './../../../css/layout.css'
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography,
  ListItemButton,
  Skeleton
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import VideoIcon from '@mui/icons-material/VideoCameraBack'
import PhotoIcon from '@mui/icons-material/PhotoOutlined'

const useStyles = makeStyles(theme => ({
  inline: {
    display: 'inline'
  },
  listItem: {
    backgroundBlendMode: `multiply, normal, normal`,
    padding: 0,
    margin: 0,
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: 16
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  listItemTypography: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0
  },

  listBgImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundBlendMode: props =>
      props.selected
        ? `hard-light, normal, normal`
        : `multiply, normal, normal`,
    backgroundImage: props =>
      props.selected
        ? `radial-gradient(circle at top right,
          ${theme.palette.secondary.main}cc 30%,
          ${theme.palette.primary.main}),
          url(${props.previewImage}),
          linear-gradient(to right, #26262B, #26262B)`
        : `radial-gradient(circle at top right,
      #cccccc,
      #4a4a4a,
      #212121),
      url(${props.previewImage}),
      linear-gradient(to right, #26262B, #26262B)`
  },
  selectedGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },
  onTop: {
    zIndex: 999
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },

  secondaryTypographyContainer: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  buttonMint: {
    color: '#fff'
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}))

export default function LayerSelectListItem ({
  selected,
  onListItemClick,
  id,
  layer,
  loading,
  primaryText,
  secondaryText1,
  secondaryText2,
  clickable = true,
  onMintButtonClicked,
  layerPreviewImage,
  ...props
}) {
  const classes = useStyles({ selected, previewImage: layerPreviewImage })

  const contents = (
    <>
      <ListItemIcon
        sx={{
          ml: '1em'
        }}
      >
        {layer.type === 'video' ? <VideoIcon /> : <PhotoIcon />}
      </ListItemIcon>
      <ListItemText
        disableTypography
        style={{
          alignSelf: 'center'
        }}
        className={clsx(
          classes.typography,
          classes.onTop,
          classes.listItemTypography
        )}
        primary={
          primaryText && (
            <Typography
              component='h5'
              variant='h5'
              noWrap
              className={clsx(classes.typography)}
              color='textPrimary'
            >
              {primaryText}
            </Typography>
          )
        }
        secondary={
          (secondaryText1 || secondaryText2) && (
            <div className={clsx(classes.secondaryTypographyContainer)}>
              {secondaryText1 && (
                <Typography
                  component='span'
                  variant='subtitle1'
                  noWrap
                  className={clsx(classes.inline, classes.typography)}
                  color='textPrimary'
                >
                  {secondaryText1}
                </Typography>
              )}
              {secondaryText2 && (
                <Typography
                  component='span'
                  variant='subtitle2'
                  noWrap
                  className={clsx(classes.inline, classes.typography)}
                  color={selected ? 'textPrimary' : 'textSecondary'}
                >
                  {secondaryText2}
                </Typography>
              )}
            </div>
          )
        }
      />
      {selected && onMintButtonClicked && (
        <ListItemSecondaryAction
          className={clsx(classes.onTop)}
          style={{ display: 'flex' }}
        >
          <div className={classes.wrapper}>
            <Button
              variant='contained'
              color='primary'
              className={clsx(classes.icon, classes.onTop, classes.buttonMint)}
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                onMintButtonClicked && onMintButtonClicked(layer)
              }}
              endIcon={<ArrowForwardIosIcon />}
            >
              {'Mint'}
            </Button>
          </div>
        </ListItemSecondaryAction>
      )}
    </>
  )
  return (
    <ListItem
      {...props}
      className={clsx(
        classes.listItem,
        {
          [classes.onTop]: selected
        },
        {
          [classes.selectedGlow]: selected
        },
        'pixelated-image',
        classes.listBgImage
      )}
      alignItems='flex-start'
      disableGutters
      disablePadding
      dense
      divider
      key={id}
      selected={selected}
    >
      {loading && (
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{
            position: 'absolute'
          }}
        />
      )}
      {clickable ? (
        <ListItemButton
          focusRipple
          className={clsx(classes.listItem, 'pixelated-image', {
            [classes.listBgImage]: !loading
          })}
          onClick={event => {
            onListItemClick && onListItemClick(event, id, layer)
          }}
        >
          {contents}

          <ListItemSecondaryAction
            className={clsx(classes.onTop)}
            style={{ display: 'flex' }}
          >
            {selected && onMintButtonClicked && (
              <div className={classes.wrapper}>
                <Button
                  variant='contained'
                  color='primary'
                  className={clsx(
                    classes.icon,
                    classes.onTop,
                    classes.buttonMint
                  )}
                  onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    onMintButtonClicked && onMintButtonClicked(layer)
                  }}
                  endIcon={<ArrowForwardIosIcon />}
                >
                  {'Mint'}
                </Button>
              </div>
            )}
          </ListItemSecondaryAction>
        </ListItemButton>
      ) : (
        <>{contents}</>
      )}
    </ListItem>
  )
}
