import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PaintStatsInfoCard from './paintStatsInfoCard'
import { useState, useEffect } from 'react'
import {
  useGetArtistsWithMostPixelsQuery,
  useGetArtistsWithMostTokensQuery,
  useGetPaintTotalSupplyQuery
} from '../../../state/api'
import Web3 from 'web3'
import { usePaintDataSource } from '../../../hooks/use-paint-datasource'
import { BigNumber } from 'bignumber.js'
import Footer from '../../../components/common/footer/index'
import {
  MURALL_WALL,
  MURALL_WALL_ID,
  SupportedChain
} from '../../../lib/constants'
import LeaderboardCard from './LeaderboardCard'
import { ensReverseLookup } from '../../../lib/web3/utils'
import { useHistory } from 'react-router-dom'
import { Web3L1Context } from '../../../App'
import { useActiveWeb3React } from '../../../hooks/web3'
import { Box } from '@mui/material'
import { useMurAllStateS3DataSourceForWall } from '../../../hooks/use-murall-s3-state-datasource-for-wall'
import { convertTokenToProperDp, ensureNumber } from '../../libs/appUtils'
import { useEvolv3StatisticsDataSource } from '../../../hooks/use-evolv3-statistics'

const COVERAGE_AMOUNT_ORIGINAL = 21000

const constructLeaderboardData = (dataList, getKey, getValue) => {
  const leaderboard = {}
  for (let i = 0; i < dataList.length; i++) {
    const data = dataList[i]
    leaderboard[`${i == 0 ? '👑' : '🏅'}#${i + 1}`] = {
      title: `${getKey(data) || 'Unknown'} - ${getValue(data)}`,
      data: data
    }
  }
  return leaderboard
}

const resolveEnsIfPossible = async (
  library,
  libraryL1,
  dataList,
  addressGetterForData
) => {
  let useConnectedLibrary = false
  if (library) {
    if (library?.eth?.net?.getId) {
      const currentChainId = await library.eth.net.getId()
      if (currentChainId === SupportedChain.Ethereum) {
        useConnectedLibrary = true
      }
    }
  }
  const libraryToUse = useConnectedLibrary ? library : libraryL1
  //loop through data and resolve ens if possible
  const resolvedData = []
  for (let i = 0; i < dataList.length; i++) {
    const data = Object.create(dataList[i])
    const address = await addressGetterForData(data)
    if (address) {
      const ens = await ensReverseLookup(address, libraryToUse)
      data.ens = ens
    }
    resolvedData.push(data)
  }

  return resolvedData
}

const useStatsForWallId = wallId => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  const history = useHistory()
  const { library } = useActiveWeb3React()
  const libraryL1 = useContext(Web3L1Context)
  const wallInfo = MURALL_WALL[wallId]
  const datasource = useMurAllStateS3DataSourceForWall(wallId)
  const getTotalStateChanges = datasource?.getTotalStateChanges
  const getTotalNumberOfArtists = datasource?.getTotalNumberOfArtists

  const [numberOfDrawings, setNumberOfDrawings] = useState(0)
  const [numberOfArtists, setNumberOfArtists] = useState(0)
  const [artistWithMostTokens, setArtistWithMostTokens] = useState({})
  const [artistMostPixels, setArtistMostPixels] = useState({})
  const [artistMostPaintBurned, setArtistMostPaintBurned] = useState({})

  const { data: artistWithMostTokensRaw } = useGetArtistsWithMostTokensQuery({
    wallId,
    limit: 5
  })
  const { data: artistMostPixelsRaw } = useGetArtistsWithMostPixelsQuery({
    wallId,
    limit: 5,
    skip: wallId === MURALL_WALL_ID.EVOLV3
  })

  const evolv3StatisticsDataSource = useEvolv3StatisticsDataSource()

  useEffect(() => {
    if (wallId === MURALL_WALL_ID.EVOLV3) {
      evolv3StatisticsDataSource?.topPaintBurners().then(data => {
        const artists = data.map(e => {
          return {
            artist: e.address,
            paintBurned: convertTokenToProperDp(e.paintBurned)
          }
        })
        console.log('artists', artists)
        setArtistMostPaintBurned(artists)
        resolveEnsIfPossible(
          library,
          libraryL1,
          artists,
          data => data.artist
        ).then(data => {
          setArtistMostPaintBurned(data)
        })
      })
    }
  }, [evolv3StatisticsDataSource, wallId])
  useEffect(() => {
    if (!artistWithMostTokensRaw) return

    setArtistWithMostTokens(artistWithMostTokensRaw)
    console.log('artistWithMostTokensRaw', artistWithMostTokensRaw)
    resolveEnsIfPossible(
      library,
      libraryL1,
      artistWithMostTokensRaw,
      data => data.artist
    ).then(data => {
      setArtistWithMostTokens(data)
    })
  }, [artistWithMostTokensRaw])
  useEffect(() => {
    if (!artistMostPixelsRaw || wallId === MURALL_WALL_ID.EVOLV3) return

    setArtistMostPixels(artistMostPixelsRaw)
    const artistsMostPaintBurned = artistMostPixelsRaw.map(e => {
      return {
        artist: e.artist,
        paintBurned: Web3.utils.toBN(e.pixels).div(Web3.utils.toBN(2))
      }
    })
    setArtistMostPaintBurned(artistsMostPaintBurned)
    resolveEnsIfPossible(
      library,
      libraryL1,
      artistsMostPaintBurned,
      data => data.artist
    ).then(data => {
      setArtistMostPaintBurned(data)
    })

    resolveEnsIfPossible(
      library,
      libraryL1,
      artistMostPixelsRaw,
      data => data.artist
    ).then(data => {
      setArtistMostPixels(data)
    })
  }, [artistMostPixelsRaw, wallId])
  useEffect(() => {
    if (!getTotalStateChanges || !getTotalNumberOfArtists) return

    const updateStats = async () => {
      const totalArtists = await getTotalNumberOfArtists()
      const totalDrawings = await getTotalStateChanges()

      setNumberOfArtists(ensureNumber(totalArtists))
      setNumberOfDrawings(ensureNumber(totalDrawings))
    }
    updateStats()
  }, [getTotalStateChanges, getTotalNumberOfArtists])

  return {
    numberOfDrawings,
    numberOfArtists,
    artistMostPixels,
    artistWithMostTokens,
    artistMostPaintBurned,
    topArtistsLeaderboard: (
      <LeaderboardCard
        title={'Top Artists on ' + wallInfo.name}
        items={constructLeaderboardData(
          artistWithMostTokens,
          burner => burner.ens || burner.artist,
          burner => `${burner.tokens} Artworks`
        )}
        onItemClicked={data => {
          history.push(`/artist/${data.artist}`)
        }}
      />
    ),
    topPaintBurnersLeaderboard: (
      <LeaderboardCard
        title={'Top Paint Burners on ' + wallInfo.name}
        items={constructLeaderboardData(
          artistMostPaintBurned,
          burner => burner.ens || burner.artist,
          burner => `${formatter.format(burner.paintBurned)} PAINT`
        )}
        onItemClicked={data => {
          history.push(`/artist/${data.artist}`)
        }}
      />
    )
  }
}

export default function StatsLandingPage(props) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const noDpFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const network = useSelector(state => state.network)
  const { initialSupply } = usePaintDataSource()
  const {
    numberOfArtists: numberOfArtistsL1,
    numberOfDrawings: numberOfDrawingsL1,
    topArtistsLeaderboard: topArtistsLeaderboardL1,
    topPaintBurnersLeaderboard: topPaintBurnersLeaderboardL1
  } = useStatsForWallId(MURALL_WALL_ID.LAYER_1)
  const {
    numberOfArtists: numberOfArtistsL2,
    numberOfDrawings: numberOfDrawingsL2,
    topArtistsLeaderboard: topArtistsLeaderboardL2,
    topPaintBurnersLeaderboard: topPaintBurnersLeaderboardL2
  } = useStatsForWallId(MURALL_WALL_ID.LAYER_2)
  const {
    numberOfArtists: numberOfArtistsEvolv3,
    numberOfDrawings: numberOfDrawingsEvolv3,
    topArtistsLeaderboard: topArtistsLeaderboardEvolv3,
    topPaintBurnersLeaderboard: topPaintBurnersLeaderboardEvolv3
  } = useStatsForWallId(MURALL_WALL_ID.EVOLV3)

  const { data: totalSupplyRaw } = useGetPaintTotalSupplyQuery(
    SupportedChain.Ethereum
  )
  const paintTotalSupply = formatter.format(totalSupplyRaw || 0)

  const [paintOriginalSupply, setPaintOriginalSupply] = useState(0)
  const [paintBurned, setPaintBurned] = useState(0)
  const [totalDrawings, setTotalDrawings] = useState(0)
  const [totalArtists, setTotalArtists] = useState(0)
  const [coveragesLeft, setCoveragesLeft] = useState(0)
  const [paintTotalSupplyPercentage, setPaintTotalSupplyPercentage] = useState(
    0
  )

  useEffect(() => {
    if (totalSupplyRaw) {
      const initialSupplyBigNum = new BigNumber(
        Web3.utils.fromWei(initialSupply(), 'ether')
      )
      BigNumber.set({ DECIMAL_PLACES: 4, ROUNDING_MODE: 3 })
      const totalSupplyBigNum = new BigNumber(totalSupplyRaw)
      const remainingSupplyMultiplier = totalSupplyBigNum.dividedBy(
        initialSupplyBigNum
      )

      const totalSupplyPercentage = remainingSupplyMultiplier.times(100.0)
      setPaintTotalSupplyPercentage(totalSupplyPercentage)

      const coveragesRemaining = new BigNumber(COVERAGE_AMOUNT_ORIGINAL).times(
        remainingSupplyMultiplier
      )
      setCoveragesLeft(noDpFormatter.format(coveragesRemaining))
      setPaintBurned(
        formatter.format(initialSupplyBigNum.minus(totalSupplyBigNum))
      )
      setPaintOriginalSupply(
        formatter.format(Web3.utils.fromWei(initialSupply(), 'ether'))
      )
    }
  }, [totalSupplyRaw])

  useEffect(() => {
    setTotalArtists(
      new BigNumber(numberOfArtistsL1 || 0)
        .plus(numberOfArtistsL2 || 0)
        .plus(numberOfArtistsEvolv3 || 0)
        .toNumber()
    )
  }, [numberOfArtistsL1, numberOfArtistsL2, numberOfArtistsEvolv3])

  useEffect(() => {
    setTotalDrawings(
      new BigNumber(numberOfDrawingsL1 || 0)
        .plus(numberOfDrawingsL2 || 0)
        .plus(numberOfDrawingsEvolv3 || 0)
        .toNumber()
    )
  }, [numberOfDrawingsL1, numberOfDrawingsL2, numberOfDrawingsEvolv3])

  return (
    <>
      <PaintStatsInfoCard
        contents={{
          'Remaining Paint Supply 🎨': `${paintTotalSupply} PAINT (${paintTotalSupplyPercentage}%)`,
          'Paint Burned 🔥': `${paintBurned} PAINT`,
          'Number of coverages possible': coveragesLeft,
          'Number of Artists Revo1t (Ethereum) 🧑‍🎨': numberOfArtistsL1,
          'Number of Artists Di2rupt (Polygon) 🧑‍🎨': numberOfArtistsL2,
          'Number of Artists Evolv3 (Polygon)🧑‍🎨': numberOfArtistsEvolv3,
          'Total Artists 🧑‍🎨': totalArtists,
          'Total State Changes Revo1t': numberOfDrawingsL1,
          'Total State Changes Di2rupt': numberOfDrawingsL2,
          'Total State Changes Evolv3': numberOfDrawingsEvolv3,
          'Total State Changes': totalDrawings
        }}
      />

      {topArtistsLeaderboardL1}
      {topArtistsLeaderboardL2}
      {topArtistsLeaderboardEvolv3}

      {topPaintBurnersLeaderboardL1}
      {topPaintBurnersLeaderboardL2}
      {topPaintBurnersLeaderboardEvolv3}

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          style={{
            objectFit: 'contain',
            alignSelf: 'flex-end'
          }}
          src={'images/Painters4Life.png'}
        />
      </Box>
      <Footer />
    </>
  )
}
