import { L2_CHAINS, MURALL_WALL_ID, SUPPORTED_CHAINS } from '../lib/constants'
import { useMemo } from 'react'
const getApi = (network, wall) => {
  return useMemo(() => {
    const basePath = `${process.env.AWS_API_URL}/v1/${wall}`
    const networkBasePath = `${process.env.AWS_API_URL}/v1/${network}`
    const imagesBasePath = `https://images.murall.art/${wall}`
    return {
      s3: {
        currentState: `${imagesBasePath}/state.png`,
        history: id => `${imagesBasePath}/history/${id}/state.png`,
        token: id => `${imagesBasePath}/tokens/${id}/token.png`,
        montage: id => `${imagesBasePath}/montages/${id}/montage.png`
      },
      images: {
        currentState: `${imagesBasePath}/state.png`,
        history: id => `${imagesBasePath}/history/${id}/state.png`,
        token: id => `${imagesBasePath}/tokens/${id}/token.png`,
        montage: id => `${imagesBasePath}/montages/${id}/montage.png`
      },
      states: {
        current: `${basePath}/states/current`
      },
      tokens: {
        inRange: (from, to) => `${basePath}/tokens?from=${from}&to=${to}`,
        cachedInRange: (from, to) => `${basePath}/tokens?from=${from}&to=${to}&cached=true`,
        token: id => `${basePath}/tokens/${id}`,
        cachedToken: id => `${basePath}/tokens/${id}?cached=true`
      },
      montages: {
        montage: id => `${basePath}/montages/${id}`
      },
      statistics: {
        tokens: {
          paint: {
            supply: {
              max: `${networkBasePath}/statistics/tokens/paint/supply/max`,
              total: `${networkBasePath}/statistics/tokens/paint/supply/total`,
              circulating: `${networkBasePath}/statistics/tokens/paint/supply/circulating`
            }
          }
        }
      },
      artists: {
        statistics: address => `${basePath}/artists/${address}/stats`,
        nfts: address => `${basePath}/artists/${address}/nfts`
      }
    }
  }, [network, wall])
}

const getNetworkName = networkId => {
  return useMemo(() => {
    if (!SUPPORTED_CHAINS.includes(networkId)) return ''
    return L2_CHAINS.includes(networkId) ? 'polygon' : 'ethereum'
  }, [networkId])
}

const getPathForWallId = wallId => {
  switch (wallId) {
    case MURALL_WALL_ID.LAYER_1:
      return 'ethereum'
    case MURALL_WALL_ID.LAYER_2:
      return 'polygon'
    case MURALL_WALL_ID.EVOLV3:
      return 'evolv3'
    default:
      return 'ethereum'
  }
}

export const useApi = (networkId, wallId = MURALL_WALL_ID.LAYER_1) => {
  return getApi(getNetworkName(networkId), getPathForWallId(wallId))
}
