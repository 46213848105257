import React from 'react'
import styled from 'styled-components'

import { injected } from '../../../../lib/connectors'
import SUPPORTED_WALLETS from '../../../../lib/connectors/supported-wallets'
import LinearProgress from '@mui/material/LinearProgress'
import WalletOption from './wallet-option'

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bg.main};
  border: 1px solid ${({ theme, error }) => error ? theme.colors.named.red : theme.colors.bg.lightest};
  border-radius: 15px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(2)};

  color: ${({ theme, error }) => (error ? theme.colors.named.red : 'inherit')};
`

const ErrorGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
`

const ErrorButton = styled.div`
  border-radius: 8px;
  font-size: 12px;
  background-color: ${({ theme }) => theme.colors.bg.lighter};
  color: #fff;
  margin-left: 1rem;
  padding: 0.5rem;
  font-weight: 600;
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.bg.lightest};
  }
`

const PendingView = ({ connector, error = false, setPendingError, tryActivation }) => {
  return (
    <>
      <Container error={error}>
        {error ? (
          <ErrorGroup>
            <span>Error connecting</span>
            <ErrorButton
              onClick={() => {
                setPendingError(false)
                connector && tryActivation(connector)
              }}
            >
              Try Again
            </ErrorButton>
          </ErrorGroup>
        ) : <LinearProgress sx={{ width: '100%' }} color='primary' />}
      </Container>
      <CurrentWallet connector={connector} />
    </>
  )
}

const CurrentWallet = ({ connector }) => {
  const isMetamask = window?.ethereum?.isMetaMask

  return Object.keys(SUPPORTED_WALLETS).map((key) => {
    const wallet = SUPPORTED_WALLETS[key]
    if (wallet.connector === connector) {
      if (wallet.connector === injected) {
        if (isMetamask && wallet.name !== 'MetaMask') return null
        if (!isMetamask && wallet.name === 'MetaMask') return null
      }
      return (
        <WalletOption
          id={`connect-${key}`}
          key={key}
          clickable={false}
          color={wallet.color}
          title={wallet.name}
          description={wallet.description}
          icon={wallet.icon}
        />
      )
    }
    return null
  })
}

export default PendingView
