import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Tooltip from '@mui/material/Tooltip'

const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.primary.light
  },
  tooltip: {
    backgroundColor: theme.palette.primary.light
  }
}))

export default function StyledTooltip (props) {
  const classes = useStyles()

  return <Tooltip arrow classes={classes} {...props} />
}
