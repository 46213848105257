import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import TradeInfoCard from './tradeInfoCard'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Divider from '@mui/material/Divider'
import Footer from '../../../components/common/footer/index'
import TokenSwapWidget from './tokenSwapWidget'
import { DefaultContainer } from '../../../components/common/DefaultContainer'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  buttonLarge: {
    fontSize: '63px'
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  emptyViewContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const MarketButton = withStyles(theme => ({
  root: {
    fontSize: theme.spacing(2.5),
    fontWeight: 300,
    textTransform: 'none'
  }
}))(Button)

export default function TradeLandingPage (props) {
  const classes = useStyles()

  const constructMarketButton = (
    title,
    clickUrl,
    imagePath,
    divider = true
  ) => {
    return (
      <>
        <Grid item xs={12}>
          <MarketButton
            size='small'
            color='inherit'
            onClick={() => {
              window.open(clickUrl, '_blank')
            }}
            startIcon={
              imagePath != null && (
                <Icon style={{ fontSize: 35, textAlign: 'center' }}>
                  <img src={imagePath} alt={title} />
                </Icon>
              )
            }
            style={{ width: '100%' }}
          >
            {title}
          </MarketButton>
        </Grid>
        {divider && constructDivider()}
      </>
    )
  }
  const constructDivider = () => {
    return (
      <Grid item xs={12}>
        <Divider variant='middle' style={{ backgroundColor: '#ffffff' }} />
      </Grid>
    )
  }

  return (
    <div className={clsx(classes.absoluteFill, classes.root)}>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='space-around'
        alignItems='flex-start'
        sx={{
          padding: '24px',
          flexGrow: 1
        }}
      >
        <Grid key={0} item xs={12} sm={12} md={6} lg={6} xl={6} align='center'>
          <TradeInfoCard
            title={'Uniswap'}
            image={'/images/trade_paint.png'}
            availableMarketButtons={
              <Grid
                container
                spacing={1}
                direction='row'
                justifyContent='space-around'
                alignItems='flex-start'
              >
                {constructMarketButton(
                  'Uniswap (Polygon)',
                  'https://app.uniswap.org/#/swap?chain=polygon&outputCurrency=0x7c28F627eA3aEc8B882b51eb1935f66e5b875714',
                  'images/uniswap_logo_white.svg'
                )}

                {constructMarketButton(
                  'Uniswap (Ethereum)',
                  'https://app.uniswap.org/#/swap?outputCurrency=0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042',
                  'images/uniswap_logo_white.svg'
                )}
                {/* {constructMarketButton(
                  'QuickSwap (Polygon)',
                  'https://quickswap.exchange/#/swap?outputCurrency=0x7c28F627eA3aEc8B882b51eb1935f66e5b875714',
                  'images/logo_quickswap.svg'
                )} */}

                {constructMarketButton(
                  'MEXC',
                  'https://www.mexc.com/exchange/PAINT_USDT',
                  'images/MXC.svg'
                )}

                {/* {constructMarketButton(
                  'Hoo',
                  'https://hoo.com/spot/paint-usdt',
                  'images/Hoo.svg'
                )} */}

                {constructMarketButton(
                  'Bilaxy',
                  'https://bilaxy.com/trade/PAINT_ETH',
                  'images/Bilaxy.svg'
                )}

                {/* {constructMarketButton(
                  'LATOKEN',
                  'https://latoken.com/exchange/PAINT_USDT',
                  'images/latoken_logo_light.svg',
                  false
                )} */}
              </Grid>
            }
            cardContent={
              <React.Fragment>
                <Typography
                  className={clsx(classes.typographyLight)}
                  style={{ marginTop: '24px' }}
                  variant='h4'
                  component='h4'
                  color='textPrimary'
                >
                  Buy Paint token
                </Typography>
                <Typography
                  className={clsx(classes.typographyLight)}
                  variant='h6'
                  component='h6'
                  color='textPrimary'
                  sx={{
                    mb: '24px'
                  }}
                >
                  Top up your PAINT balance directly here:
                </Typography>

                <DefaultContainer>
                  <TokenSwapWidget />
                </DefaultContainer>

                <Typography
                  className={clsx(classes.typographyLight)}
                  variant='h6'
                  component='h6'
                  color='textPrimary'
                  gutterBottom
                  sx={{
                    marginTop: '24px'
                  }}
                >
                  Or from any of the following exchanges:
                </Typography>
              </React.Fragment>
            }
          />
        </Grid>
        <Grid key={1} item xs={12} sm={12} md={6} lg={6} xl={6} align='center'>
          <TradeInfoCard
            title={'Opensea'}
            image={'/images/trade_nfts.png'}
            availableMarketButtons={
              <React.Fragment>
                {constructMarketButton(
                  'OpenSea MurAll L1',
                  'https://opensea.io/collection/murall',
                  'images/opensea_logo_white.svg'
                )}

                {constructMarketButton(
                  'OpenSea MurAll L2',
                  'https://opensea.io/collection/murall-l2',
                  'images/opensea_logo_white.svg'
                )}
                {constructMarketButton(
                  'OpenSea MurAll L2 (bridged to L1)',
                  'https://opensea.io/collection/murall-l2-migrated',
                  'images/opensea_logo_white.svg'
                )}

                {constructMarketButton(
                  'OpenSea Montage L2',
                  'https://opensea.io/collection/murall-montage-l2',
                  'images/opensea_logo_white.svg'
                )}
                {constructMarketButton(
                  'OpenSea Montage L2 (bridged to L1)',
                  'https://opensea.io/collection/murall-montage-l2-migrated',
                  'images/opensea_logo_white.svg',
                  false
                )}
              </React.Fragment>
            }
            cardContent={
              <React.Fragment>
                <Typography
                  className={clsx(classes.typographyLight)}
                  style={{ marginTop: '24px' }}
                  variant='h4'
                  component='h4'
                  color='textPrimary'
                >
                  Trade MurAll NFTs
                </Typography>
                <Typography
                  className={clsx(classes.typographyLight)}
                  variant='h6'
                  component='h6'
                  color='textPrimary'
                >
                  Sell your artwork or buy other artists work
                </Typography>
              </React.Fragment>
            }
          />
        </Grid>
      </Grid>

      <Footer />
    </div>
  )
}
