import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import Web3 from 'web3'
import ErrorMessageView from '../components/common/error-view'
import DrawingClient from '../js/pages/draw/drawingClient'
import { MURALL_WALL, MURALL_WALL_ID, SupportedChain } from '../lib/constants'
import { useActiveWeb3React } from '../hooks/web3'
import makeStyles from '@mui/styles/makeStyles'
import DrawingTransactionDialog from '../js/pages/draw/drawingTransactionDialog'
import useSetPixelsOnMurAll from '../hooks/murall/use-set-pixels-on-murall'
import Get_transaction_data_for_layers_usecase from '../js/modules/blockchain/usecase/get_transaction_data_for_layers_usecase'
import StyledDialog from '../js/uicomponents/styled_dialog'
import Typography from '@mui/material/Typography'
import LoadingDialog from '../js/uicomponents/loading_dialog'
import _ from 'underscore'
import useUpdateLiveStateOnDrawingClient from '../hooks/murall/use-update-live-state-on-drawing-client'
import WelcomeDrawDialog from '../js/pages/draw/welcomeDrawDialog'
import notification from '../lib/notification'
import useUpdateDrawingClientTheme from '../hooks/draw/use-update-drawing-client-theme'
import useSmallScreenDetection from '../js/uicomponents/useSmallScreenDetection'
import config from '../js/config'
import Evolv3MintManager from '../js/pages/draw/evolv3/evolv3MintManager'
import WelcomeDrawBanner from '../js/pages/draw/welcomeDrawBanner'

const useStyles = makeStyles(theme => ({
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  }
}))

const DrawPage = () => {
  const classes = useStyles()
  const [cookies] = useCookies(['agreedToTerms', 'hideDrawWelcome'])

  const { loading: loadingLiveState } = useUpdateLiveStateOnDrawingClient()
  useUpdateDrawingClientTheme()

  const smallScreen = useSmallScreenDetection()
  const network = useSelector(state => state.network)
  const terms = useSelector(state => state.terms)
  const [currentDetail, setCurrentDetail] = useState(null)
  const drawWelcome = useSelector(state => state.drawWelcome)
  const currentWall = useSelector(state => state.wall)
  const wallInfo = MURALL_WALL[currentWall.id]
  const [loading, setLoading] = useState(false)

  const [startEvolv3Mint, setStartEvolv3Mint] = useState(false)
  const [welcomeBannerOpen, setWelcomeBannerOpen] = useState(
    !cookies.hideDrawWelcome && !drawWelcome.hide
  )
  const [welcomeDialogOpen, setWelcomeDialogOpen] = useState(false)
  const [
    showTooManyColorsErrorDialog,
    setShowTooManyColorsErrorDialog
  ] = useState(false)
  const { setPixelsOnMurAllAndNotify } = useSetPixelsOnMurAll()
  const { account, chainId } = useActiveWeb3React()
  const getTransactionDataForLayersUsecase = new Get_transaction_data_for_layers_usecase()

  const agreedToTerms = !!cookies.agreedToTerms || terms.agreed

  useEffect(() => {
    if (startEvolv3Mint) {
      config.disableKeyboardShortcuts = true
    } else {
      config.disableKeyboardShortcuts = false
    }
  }, [startEvolv3Mint])

  if (network.id && !network.supported) {
    return (
      <ErrorMessageView
        title='Network unsupported'
        description='Please select the Ethereum Mainnet or Polygon network'
      />
    )
  }

  const constructDrawTransactionInfo = (
    flatten = true,
    convertTo256Color = false
  ) => {
    setLoading(true)

    getTransactionDataForLayersUsecase
      .execute({
        flatten,
        convertTo256Color
      })
      .then(data => {
        setCurrentDetail(data)
      })
      .catch(error => {
        setCurrentDetail(null)
        setShowTooManyColorsErrorDialog(true)
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <LoadingDialog open={loading} />
      {!cookies.hideDrawWelcome && !drawWelcome.hide && (
        <WelcomeDrawBanner
          onPositiveButtonClick={() => {
            setWelcomeBannerOpen(false)
            setWelcomeDialogOpen(true)
          }}
          open={welcomeBannerOpen}
          onClose={() => {
            setWelcomeBannerOpen(false)
            setWelcomeDialogOpen(false)
          }}
        />
      )}
      {welcomeDialogOpen && (
        <WelcomeDrawDialog
          open={welcomeDialogOpen}
          onPositiveButtonClick={() => {
            setWelcomeDialogOpen(false)
          }}
          onClose={() => {
            setWelcomeDialogOpen(false)
          }}
        />
      )}
      {showTooManyColorsErrorDialog && (
        <StyledDialog
          open={showTooManyColorsErrorDialog}
          onClose={() => {
            setShowTooManyColorsErrorDialog(false)
          }}
          dialogTitle={'Error: too many colors in image'}
          dialogContent={() => (
            <Typography
              className={classes.typography}
              variant='body2'
              component='p'
            >
              Your drawing must have no more than 256 colors
              <br />
              <br />
              Would you like to reduce the colors to 256 and continue?
              <br />
              <br />
              You can manually do this by going to 'Image {'>'} Convert to 256
              color depth' or select the 256 colour palette tool on the left
              menu to convert your image to 256 colors
            </Typography>
          )}
          positiveButtonTitle={'Reduce & Continue'}
          onPositiveButtonClick={() => {
            setShowTooManyColorsErrorDialog(false)
            constructDrawTransactionInfo(true, true)
          }}
          negativeButtonTitle={'Cancel'}
          onNegativeButtonClick={() => {
            setShowTooManyColorsErrorDialog(false)
          }}
        />
      )}
      <DrawingClient
        connected={!!account}
        agreedToTerms={agreedToTerms}
        wallId={currentWall.id}
        constructDrawTransactionInfo={constructDrawTransactionInfo}
        onMintClicked={() => {
          if (config.layers.length == 1 && config.blockchainDataLayer != null) {
            notification.error(
              'There is nothing to mint!\nDraw something or import an image from the file menu!'
            )
            return
          }
          if (!account) {
            notification.error('Please connect your wallet to mint!')
            return
          }
          if (wallInfo.chainId !== chainId) {
            notification.wrongNetwork(wallInfo.chainId)
            return
          }
          if (currentWall.id === MURALL_WALL_ID.EVOLV3) {
            setStartEvolv3Mint(true)
          }
        }}
      />

      {startEvolv3Mint && (
        <Evolv3MintManager
          startMintFlow={startEvolv3Mint}
          onCancelClicked={() => {
            setStartEvolv3Mint(false)
          }}
          onMintFinished={() => {
            setStartEvolv3Mint(false)
          }}
        />
      )}

      {currentDetail && (
        <DrawingTransactionDialog
          open={currentDetail}
          fullDetail={currentDetail}
          numberOfPixels={currentDetail.numberOfPixels}
          blockchainPixelDataLength={currentDetail.blockchainPixelData.length}
          blockchainPixelGroupDataLength={
            currentDetail.blockchainPixelGroupData.length
          }
          blockchainPixelGroupIndexDataLength={
            currentDetail.blockchainPixelGroupIndexData.length
          }
          blockchainTransparentPixelGroupDataLength={
            currentDetail.blockchainTransparentPixelGroupData.length
          }
          blockchainTransparentPixelGroupIndexDataLength={
            currentDetail.blockchainTransparentPixelGroupIndexData.length
          }
          blockchainColourIndexDataLength={currentDetail.colourIndexData.length}
          alphaEnabled={currentDetail.alphaEnabled}
          alphaChannel={currentDetail.alphaChannel}
          costInPaint={Web3.utils.fromWei(currentDetail.costInPaint, 'ether')}
          croppedBase64PngString={currentDetail.croppedBase64PngString}
          numberOfColors={currentDetail.numberOfColors}
          onCancelClicked={() => {
            setCurrentDetail(null)
          }}
          onDrawClicked={async (name, number, seriesId) => {
            setLoading(true)
            try {
              await setPixelsOnMurAllAndNotify(
                currentDetail,
                name,
                number,
                seriesId
              )
              setCurrentDetail(null)
            } catch (error) {
              console.error(error)
            }
            setLoading(false)
          }}
        />
      )}
    </>
  )
}

export default DrawPage
