import React from 'react'
import Paper from '@mui/material/Paper'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import useDetectSmallScreen from '../../../uicomponents/useSmallScreenDetection'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import RangeFields from './range-fields'
import useStyles from './styles'
import { Typography, Divider } from '@mui/material'
import { useTheme } from '@mui/styles'
import { LiveButton } from '../../../../components/common/buttons/live-button'


const HistoryRangePicker = ({
  open,
  rangeStart,
  setRangeStart,
  rangeEnd,
  setRangeEnd,
  totalStates,
  maxRange,
  onLoadClick
}) => {
  const isSmallScreen = useDetectSmallScreen()
  const classes = useStyles(isSmallScreen)()
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <Collapse in={open} mountOnEnter unmountOnExit>
        <Paper className={classes.rangePickerContainer}>
          <div className={classes.gridColumnContainer}>
            <Typography
              variant='body1'
              component='p'
              align='center'
              sx={{ width: '100%', pb: 2 }}
            >
              See MurAlls history:
            </Typography>

            <RangeFields
              showRange={true}
              totalTokens={totalStates > 0 ? totalStates : 0}
              rangeStart={rangeStart}
              setRangeStart={setRangeStart}
              rangeEnd={rangeEnd}
              setRangeEnd={setRangeEnd}
              maxRange={maxRange}
            />

            <Button
              size='medium'
              color='primary'
              variant={'outlined'}
              endIcon={<ArrowForwardIosSharpIcon />}
              onClick={() => onLoadClick(false)}
              className={classes.submitBtn}
            >
              <Typography
                {...theme.typography.button}
                variant='body1'
                component='p'
                align='center'
                sx={{ width: '100%' }}
              >
                Load history
              </Typography>
            </Button>
            <Divider sx={{ pt: '1em', mb: '1em' }} />
            <LiveButton onClick={() => onLoadClick(true)} />
          </div>
        </Paper>
      </Collapse>
    </div>
  )
}

export default HistoryRangePicker
