import withStyles from '@mui/styles/withStyles'
import LinearProgress from '@mui/material/LinearProgress'

const StyledProgress = withStyles(theme => ({
  root: {
    transform: 'translate(0%, 50%)',
    margin: 0,
    padding: 0
  },
  colorPrimary: {
    backgroundColor: theme.palette.secondary.main + 'B3'
  },
  bar: {
    margin: 0,
    padding: 0,
    boxShadow: '0px 0px 10px 4px ' + theme.palette.secondary.main + 'B3',
    background:
      'radial-gradient(circle at top right, ' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      ')',
    backgroundBlendMode: `normal`
  }
}))(LinearProgress)

export default StyledProgress
