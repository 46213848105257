import ERC721 from './../../../../contracts/common/ERC721.json'
import { replaceIpfsIfNeeded } from '../../../libs/appUtils'

export const getBalanceForAddress = (
  web3,
  erc721TokenContractAddress,
  address
) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.balanceOf(address)
    .call()
}

export const ownerOf = (web3, erc721TokenContractAddress, tokenId) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.ownerOf(tokenId)
    .call()
}

export const getName = (web3, erc721TokenContractAddress) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.name()
    .call()
}

export const getSymbol = (web3, erc721TokenContractAddress) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.name()
    .call()
}

export const getTotalSupply = (web3, erc721TokenContractAddress) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.totalSupply()
    .call()
}

export const getOwnedTokenIdsInIndexRange = (
  web3,
  erc721TokenContractAddress,
  ownerAddress,
  startIndex,
  endIndex
) => {
  const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
    startIndex,
    endIndex + 1
  )
  const erc721Instance = getErc721ContractInstance(
    web3,
    erc721TokenContractAddress
  )

  const getNftInfoForIndex = (index, erc721Instance) => {
    return new Promise(async function (resolve, reject) {
      const tokenId = await erc721Instance.methods
        .tokenOfOwnerByIndex(ownerAddress, index)
        .call()

      resolve(tokenId)
    })
  }

  return Promise.all(
    indexes.map(index => getNftInfoForIndex(index, erc721Instance))
  )
}

export const getOwnedTokenIdAtIndex = (
  web3,
  erc721TokenContractAddress,
  ownerAddress,
  index
) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.tokenOfOwnerByIndex(ownerAddress, index)
    .call()
}

export const transferTokenId = (
  web3,
  erc721TokenContractAddress,
  senderAddress,
  recipientAddress,
  tokenId,
  txSenderAddress = null
) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.transferFrom(
      senderAddress,
      recipientAddress,
      web3.utils.toBN(tokenId)
    )
    .send({ from: txSenderAddress != null ? txSenderAddress : senderAddress })
}

export const setApproveTokenTransfer = (
  web3,
  erc721TokenContractAddress,
  ownerAddress,
  spenderAddress,
  approved,
  txSenderAddress = null
) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.setApprovalForAll(spenderAddress, approved)
    .send({ from: txSenderAddress != null ? txSenderAddress : ownerAddress })
}

export const isTokenTransferApproved = (
  web3,
  erc721TokenContractAddress,
  ownerAddress,
  spenderAddress
) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.isApprovedForAll(ownerAddress, spenderAddress)
    .call()
}

export const getTokenUri = (web3, erc721TokenContractAddress, tokenId) => {
  return getErc721ContractInstance(web3, erc721TokenContractAddress)
    .methods.tokenURI(tokenId)
    .call()
}

export const getOffchainRemoteData = (
  web3,
  erc721TokenContractAddress,
  tokenId
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const url = await getTokenUri(web3, erc721TokenContractAddress, tokenId)
      const res = await fetch(url)
      const tokenInfo = await res.json()

      resolve(tokenInfo)
    } catch (error) {
      reject(error)
    }
  })
}

export const getFullTokenUriMetadata = async (
  web3,
  tokenContractAddress,
  tokenId
) => {
  let tokenUri = await getTokenUri(web3, tokenContractAddress, tokenId)
  console.log('tokenUri', tokenUri)
  tokenUri = replaceIpfsIfNeeded(tokenUri)

  if (tokenUri.indexOf('http') === -1) {
    tokenUri = 'https://' + tokenUri
  }

  let returnData
  if (tokenUri && tokenUri.startsWith('https://data:application/json;base64')) {
    // 29 = length of "data:application/json;base64,"
    const json = Buffer.from(
      tokenUri.replace('https://data:application/json;base64', ''),
      'base64'
    )
    returnData = JSON.parse(json)
  } else {
    const response = await fetch(tokenUri)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    returnData = await response.json()
  }

  return returnData
}

const getErc721ContractInstance = (web3, erc721TokenContractAddress) => {
  return new web3.eth.Contract(ERC721.abi, erc721TokenContractAddress)
}
