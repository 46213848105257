import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useActiveWeb3React } from '../../../hooks/web3'
import Typography from '@mui/material/Typography'
import { usePaintDataSource } from '../../../hooks/use-paint-datasource'
import Web3 from 'web3'
import { PaintIcon } from './paint-icon'
import { convertTokenToProperDp } from '../../../js/libs/appUtils'
import { useSelector } from 'react-redux'
import { MURALL_WALL } from '../../../lib/constants'

const ONE_MILLION_WEI = Web3.utils.toBN(1000000)

const AccountPaintBalance = ({
  textSize = 'h5',
  compact,
  style,
  showPaintText,
  reversed
}) => {
  const { account, chainId } = useActiveWeb3React()
  const currentWall = useSelector(state => state.wall)
  const wallInfo = MURALL_WALL[currentWall.id]
  const { getBalanceForAddress } = usePaintDataSource(wallInfo.chainId)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [balance, setBalance] = useState(null)
  useEffect(() => {
    const updateBalance = async () => {
      if (account) {
        const balance = await getBalanceForAddress(account)
        const balanceCorrectDp = convertTokenToProperDp(balance, 18)
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          ...(compact &&
            Web3.utils.toBN(balance).gt(ONE_MILLION_WEI) && {
              notation: 'compact'
            })
        })
        setBalance(formatter.format(balanceCorrectDp))
      }
    }
    updateBalance()
  }, [account, chainId, getBalanceForAddress, currentWall])
  return balance ? (
    <Container style={{ ...style }}>
      {!reversed && <PaintIcon />}
      {account && (
        <Typography
          noWrap
          variant={textSize}
          {...(!reversed && { ml: '0.25em' })}
          {...(reversed && { mr: '0.25em' })}
        >
          {balance}
          {showPaintText && ' PAINT'}
        </Typography>
      )}
      {reversed && <PaintIcon />}
    </Container>
  ) : null
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

export default AccountPaintBalance
