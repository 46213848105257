import React from 'react'
import styled from 'styled-components'
import Terms from '../components/common/dialogs/legal/terms'

const TermAndConditionsPage = () => {
  return (
    <Container>
      <Terms />
    </Container>
  )
}

const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(3)};
`

export default TermAndConditionsPage
