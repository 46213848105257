import Base_tools_class from '../core/base-tools.js'
import Helper_class from '../libs/helpers.js'

class Palette256_class extends Base_tools_class {
  constructor (ctx) {
    super()
    this.Helper = new Helper_class()
    this.name = 'ai_image_generate'
  }

  load () {
    //nothing
  }

  render (ctx, layer) {
    //nothing
  }

  on_activate () {
    this.Helper.dispatchCustomEvent('drawingContainer', 'generateWithAI', {})
  }
}
export default Palette256_class
