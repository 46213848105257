import React from 'react'

import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'

const useStyles = makeStyles(theme => ({
  buttonTextWhite: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.primary.contrastText,
        theme.palette.action.hoverOpacity
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
}))

export default function StyledCardButton (props) {
  const classes = useStyles()

  return (
    <Button
      size='small'
      className={classes.buttonTextWhite}
      onClick={props.onClick}
    >
      {props.buttonTitle}
    </Button>
  )
}
