import { useEffect, useRef, useState } from 'react'
import useUploadFileToEndpoint from '../use-upload-file-to-endpoint'
import { useGetFileInfoQuery } from '../../state/evolv3Api'

const useUploadFileToEvolv3ForMint = (
  fileToUpload,
  uploadUrl,
  fileId,
  immediate = true
) => {
  const [canFetchFileData, setCanFetchFileData] = useState(false)
  const currentFileDataRef = useRef()
  const {
    apply: uploadFileToEndpoint,
    error: fileUploadError,
    loading: isUploadingFile,
    progress: fileUploadProgress,
    success
  } = useUploadFileToEndpoint(uploadUrl, fileToUpload)

  const {
    data: currentFileData,
    error: errorFetchingFileData,
    isFetching: isFetchingFileData
  } = useGetFileInfoQuery(
    { filePath: fileId },
    {
      skip: !canFetchFileData || isUploadingFile,
      pollingInterval: canFetchFileData && !isUploadingFile ? 2500 : 0
    }
  )

  useEffect(() => {
    if (!isFetchingFileData) {
      currentFileDataRef.current = currentFileData
      setCanFetchFileData(currentFileData ? false : immediate)
    }
  }, [currentFileData, isFetchingFileData, immediate])

  useEffect(() => {
    if (isUploadingFile) {
      setCanFetchFileData(false)
    } else if (fileId && success && immediate) {
      setCanFetchFileData(true)
    }
  }, [fileId, success, isUploadingFile, immediate])

  useEffect(() => {
    if (immediate && uploadUrl && fileToUpload && fileId) {
      currentFileDataRef.current = null
      uploadFileToEndpoint()
    }
  }, [uploadUrl, fileToUpload, fileId, immediate])

  return {
    loading:
      isUploadingFile || isFetchingFileData || !currentFileDataRef?.current,
    error: fileUploadError,
    fileUploadProgress,
    fileData: currentFileDataRef?.current
  }
}

export default useUploadFileToEvolv3ForMint
