import React from 'react'
import { Icon } from '../../../node_modules/@mui/material/index'

export const CustomImageIcon = ({ sx, src, alt }) => {
  return (
    <Icon
      mr={1}
      ml={0.5}
      sx={{
        ...sx
      }}
    >
      <img
        src={src}
        alt={alt}
        style={{
          maxWidth: '100%',
          maxHeight: '100%'
        }}
      />
    </Icon>
  )
}
