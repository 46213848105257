import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { MURALL_WALL } from '../../../lib/constants'

const Logo = () => {
  const currentWall = useSelector(state => state.wall)
  return (
    <Link to='/'>
      <LogoWrapper>
        <LogoImg>
          <img src={MURALL_WALL[currentWall.id].murallLogo} alt='MurAll logo' />
        </LogoImg>
      </LogoWrapper>
    </Link>
  )
}

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`

const LogoImg = styled.div`
  height: 40px;
  width: 40px;
  img {
    width: 100%;
    height: 100%;
  }
`

export default Logo
