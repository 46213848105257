import React from 'react'
import clsx from 'clsx'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const drawerWidth = 320

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    backgroundImage: 'none'
  },
  appBar: {
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    zIndex: 99999
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflowX: 'hidden',
    pointerEvents: 'all'
  },
  drawerPaper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: drawerWidth,
    top: 'auto',
    position: 'absolute'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flex: '1 1 auto',
    overflow: 'auto',
    width: '100%'
  },
  contentAppBarShift: {
    width: `calc(100% + ${drawerWidth}px)`,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflow: 'auto'
  }
}))

export default function DrawerDetailsView (props) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <React.Fragment>
      <div
        className={clsx(classes.root, {
          [classes.appBarShift]: props.drawerOpen && !props.temporaryDrawer,
          [classes.appBar]: !props.drawerOpen && !props.temporaryDrawer
        })}
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <AppBar
          className={clsx(classes.transparent, {
            [classes.appBarShift]: props.drawerOpen && props.temporaryDrawer,
            [classes.appBar]: !props.drawerOpen && props.temporaryDrawer
          })}
          position='relative'
          style={{ width: '100%' }}
        >
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={props.onDrawerOpenClick}
              edge='start'
              className={clsx(
                classes.menuButton,
                props.drawerOpen && classes.hide
              )}
              size='large'
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              onClick={props.onDrawerCloseClick}
              aria-label='close drawer'
              edge='start'
              className={clsx(
                classes.menuButton,
                !props.drawerOpen && classes.hide
              )}
              size='large'
            >
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
        <main
          className={clsx(
            classes.content,
            props.drawerOpen && classes.contentAppBarShift
          )}
        >
          {props.mainContent}
        </main>
      </div>
      <Box
        width='auto'
        height='100%'
        id='drawer-container'
        position='relative'
        component='nav'
        style={{
          pointerEvents: 'none'
        }}
      >
        <Drawer
          className={clsx(classes.transparent, classes.drawer)}
          variant={props.temporaryDrawer ? 'temporary' : 'persistent'}
          anchor='left'
          open={props.drawerOpen}
          onClose={props.onDrawerCloseClick}
          classes={{
            paper: classes.drawerPaper
          }}
          container={document.getElementById('drawer-container')}
          ModalProps={{
            container: document.getElementById('drawer-container'),
            keepMounted: props.temporaryDrawer, // Better open performance on mobile.
            style: { position: 'absolute' }
          }}
        >
          <div
            style={{
              position: 'relative',
              height: '100%'
            }}
          >
            {props.drawerContent}
          </div>
        </Drawer>
      </Box>
    </React.Fragment>
  )
}
