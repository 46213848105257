import React, { forwardRef } from 'react'
import Box from '@mui/material/Box'
import '../../../css/layout.css'

function CrtOverlay ({ sx, className, style, refresh, grain }, ref) {
  return (
    <Box
      ref={ref}
      className={className}
      sx={{
        ...sx
      }}
      style={{
        ...style,
        pointerEvents: 'none',
        overflow: 'hidden'
      }}
    >
      <div
        className={'crt'}
        style={{ position: 'absolute', width: '100%', height: '100%' }}
      />
      {grain && (
        <div
          className={'grain-img'}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
        />
      )}
      {refresh && (
        <div
          className={'refresh'}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
        />
      )}
    </Box>
  )
}

export default forwardRef(CrtOverlay)
