import React, { useEffect, useRef, useState } from 'react'
import EmojiAvatarGroupWithCounts from './EmojiAvatarGroupWithCounts'
import useGetEmoteForNft from '../../../hooks/emotable/use-get-emotes-for-nft'
import useEffectDebounced from '../../../hooks/useEffectDebounced'
import useListenForEmotesOnNft from '../../../hooks/emotable/use-listen-for-emotes-on-nft'
import ConfettiExplosion from '../ConfettiExplosion'

export default function NftEmojiAvatarGroup({
  tokenId,
  tokenContractAddress,
  chainId,
  sx = {},
  avatarParams = { width: 30, height: 30, fontSize: 15 },
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center'
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  },
  logoStyle,
  maxShown = 3,
  debounce = 500
}) {
  const { apply, emotes: emojiCounts, error, loading, success } = useGetEmoteForNft({
    tokenId,
    tokenContractAddress,
    chainId,
    immediate: false
  })

  const groupRef = useRef() // Reference to the button to get its position
  const [confettiPosition, setConfettiPosition] = useState({ x: 0, y: 0 })
  const [latestEmote, setLatestEmote] = useState(null)
  const [shouldShowConfetti, setShouldShowConfetti] = useState(false)
  const emotesFromEvents = useListenForEmotesOnNft({ tokenId, collection: tokenContractAddress, chainId, active: true })

  useEffectDebounced(
    () => {
      if (chainId && tokenId >= 0 && tokenContractAddress) {
        apply()
      }
    },
    [chainId, tokenId, tokenContractAddress],
    debounce
  )
  const triggerConfetti = () => {
    setShouldShowConfetti(false)
    setTimeout(() => {
      setShouldShowConfetti(true)
    }, 0)
  }

  useEffect(() => {
    console.log('NftEmojiAvatarGroup - shouldShowConfetti updated', shouldShowConfetti)
  }, [shouldShowConfetti])
  useEffect(() => {
    if (emotesFromEvents) {
      if (emotesFromEvents.length > 0) {
        console.log('NftEmojiAvatarGroup - emotesFromEvents', emotesFromEvents)
        setLatestEmote(emotesFromEvents[0])
        triggerConfetti()
      }
      apply()
    }
  }, [emotesFromEvents])

  useEffect(() => {
    if (groupRef.current) {
      const rect = groupRef.current.getBoundingClientRect()
      setConfettiPosition({
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2
      })
    }
  }, [groupRef?.current])

  return (
    <EmojiAvatarGroupWithCounts
      ref={groupRef}
      emojiCounts={emojiCounts}
      sx={sx}
      avatarParams={avatarParams}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      loading={loading}
      logoStyle={logoStyle}
      maxShown={maxShown}
    >
      {shouldShowConfetti && (
        <ConfettiExplosion
          run={shouldShowConfetti}
          emoji={latestEmote}
          positionX={confettiPosition?.x}
          positionY={confettiPosition?.y}
          onConfettiExplosionFinish={() => {
            setShouldShowConfetti(false)
          }}
        />
      )}
    </EmojiAvatarGroupWithCounts>
  )
}
