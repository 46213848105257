import React from 'react'
import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

const ArrowButton = ({
  arrowPosition = 'start',
  variant = 'text',
  size = 'large',
  onClick,
  disabled,
  sx,
  children
}) => {
  const theme = useTheme()

  return (
    <Button
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      size={size}
      {...(arrowPosition === 'start'
        ? {
            startIcon:
              theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )
          }
        : {
            endIcon:
              theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )
          })}
      sx={{
        ...sx
      }}
    >
      {children}
    </Button>
  )
}

export default ArrowButton
