import React from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import Typography from '@mui/material/Typography'

const ErrorContent = () => {
  const { error } = useWeb3React()
  if (!error) return null

  const message = error instanceof UnsupportedChainIdError
    ? 'Please connect to Ethereum or Polygon mainnet.'
    : 'Error while connecting to your wallet. Try refreshing.'
  return <Typography variant='body1'>{message}</Typography>
}

export default ErrorContent
