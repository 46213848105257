import React, { useState, useEffect } from 'react'
import Confetti from 'react-confetti'

const ConfettiExplosion = ({
  run,
  onConfettiExplosionFinish,
  emoji,
  positionX,
  positionY,
  numberOfPieces = 50,
  tweenDuration = 1000,
  duration = 5000
}) => {
  const [shouldShowConfetti, setShouldShowConfetti] = useState(run)

  useEffect(() => {
    if (run) {
      setShouldShowConfetti(true)
      const timer = setTimeout(() => {
        setShouldShowConfetti(false)
        onConfettiExplosionFinish && onConfettiExplosionFinish()
      }, duration)
      return () => clearTimeout(timer)
    }
  }, [run, duration])

  if (!shouldShowConfetti) {
    return null
  }

  return (
    <Confetti
      run={shouldShowConfetti}
      recycle={false}
      numberOfPieces={numberOfPieces}
      tweenDuration={tweenDuration}
      width={window.innerWidth}
      height={window.innerHeight}
      style={{
        position: 'fixed',
        pointerEvents: 'none'
      }}
      confettiSource={{
        x: positionX,
        y: positionY,
        w: 0,
        h: 0
      }}
      drawShape={(ctx) => {
        ctx.font = '24px serif'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillText(emoji, 0, 0)
      }}
    />
  )
}

export default ConfettiExplosion
