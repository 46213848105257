import React from 'react'
import { useGetArtistStatisticsQuery } from '../../../state/api'
import Footer from '../../../components/common/footer/index'
import { MURALL_WALL_ID } from '../../../lib/constants'
import { useActiveWeb3React } from '../../../hooks/web3'
import AccountStatsInfoCard from './accountStatsInfoCard'
import { Box } from '@mui/material'

export default function MyStatsLandingPage (props) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const noDpFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const { account } = useActiveWeb3React()

  const { data: artistStatsEth } = useGetArtistStatisticsQuery(
    { wallId: MURALL_WALL_ID.LAYER_1, artist: account },
    {
      skip: !account || account === '' || account === undefined
    }
  )
  const { data: artistStatsPolygon } = useGetArtistStatisticsQuery(
    { wallId: MURALL_WALL_ID.LAYER_2, artist: account },
    {
      skip: !account || account === '' || account === undefined
    }
  )

  const { data: artistStatsEvolv3 } = useGetArtistStatisticsQuery(
    { wallId: MURALL_WALL_ID.EVOLV3, artist: account },
    {
      skip: !account || account === '' || account === undefined
    }
  )

  return (
    <>
      <AccountStatsInfoCard
        title={'General Stats'}
        contents={{
          '🖼 Artworks on Revo1t (Ethereum)': artistStatsEth
            ? noDpFormatter.format(artistStatsEth.mintedTokens || 0)
            : 0,
          '🖼 Artworks on Di2rupt (Polygon)': artistStatsPolygon
            ? noDpFormatter.format(artistStatsPolygon.mintedTokens || 0)
            : 0,
          '🖼 Artworks on Evolv3 (Polygon)': artistStatsEvolv3
            ? noDpFormatter.format(artistStatsEvolv3.mintedTokens || 0)
            : 0,
          '🖌 Pixels Drawn Revo1t': `${
            artistStatsEth
              ? noDpFormatter.format(artistStatsEth.pixelsDrawn || 0)
              : 0
          } pixels`,
          '🖌 Pixels Drawn Di2rupt': `${
            artistStatsPolygon
              ? noDpFormatter.format(artistStatsPolygon.pixelsDrawn || 0)
              : 0
          } pixels`,
          '🖌 Pixels Drawn Evolv3': `${
            artistStatsEvolv3
              ? noDpFormatter.format(artistStatsEvolv3.pixelsDrawn || 0)
              : 0
          } pixels`,
          '🔥 Paint Burned Revo1t': `${
            artistStatsEth
              ? formatter.format(artistStatsEth.burnedPaint || 0)
              : 0
          } PAINT`,
          '🔥 Paint Burned Di2rupt': `${
            artistStatsPolygon
              ? formatter.format(artistStatsPolygon.burnedPaint || 0)
              : 0
          } PAINT`,
          '🔥 Paint Burned Evolv3': `${
            artistStatsEvolv3
              ? formatter.format(artistStatsEvolv3.burnedPaint || 0)
              : 0
          } PAINT`
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          style={{
            objectFit: 'contain',
            alignSelf: 'flex-end'
          }}
          src={'images/Painters4Life.png'}
        />
      </Box>
      <Footer />
    </>
  )
}
