import { useState, useEffect, useCallback } from 'react'
import { upload } from '../js/libs/appUtils'

const useUploadFileToEndpoint = (url, file, immediate = false) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [progress, setProgress] = useState(0)
  const [success, setSuccess] = useState(false)

  const apply = useCallback(async () => {
    try {
      setLoading(true)
      setSuccess(false)
      setProgress(0)

      await upload(url, file, progress => {
        // set progress as a percentage (comes back as 0.0 - 1.0)
        setProgress(progress * 100)
      })

      setSuccess(true)
    } catch (error) {
      console.error(error)
      setProgress(0)
      setError(error)
      setLoading(false)
      return
    } finally {
      setLoading(false)
    }
  }, [url, file])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, success, progress, loading, error }
}

export default useUploadFileToEndpoint
