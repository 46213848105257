import { bundleNfts } from '../datasource/NftBundleDataSource'
import Web3 from 'web3'
class Bundle_nfts_for_user_account_usecase {
  async execute (web3, bundleContractAddress, name, tokenIds, senderAddress) {
    const hexName = Web3.utils.padRight(Web3.utils.asciiToHex(name), 64)
    return bundleNfts(
      web3,
      bundleContractAddress,
      hexName,
      tokenIds,
      senderAddress
    )
  }
}

export default Bundle_nfts_for_user_account_usecase
