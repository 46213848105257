import React from 'react'
import { Typography } from '@mui/material'
import ProblemIcon from '@mui/icons-material/ReportProblemOutlined'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'

export default function ErrorTextIconTooltip({ text, tooltipText }) {
  return (
    <Tooltip title={tooltipText}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="body"
          component="p"
          align="center"
          color="error"
          display="inline"
          sx={{
            p: 0,
            m: 0,
            fontWeight: 500
          }}
        >
          {text}
        </Typography>
        <ProblemIcon fontSize="inherit" color={'error'} />
      </Stack>
    </Tooltip>
  )
}
