import React from 'react'

export const useInterval = (callback, delay) => {
  const timeoutId = React.useRef(null)
  const savedCallback = React.useRef(callback)
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  React.useEffect(() => {
    const handleTick = () => {
      timeoutId.current = window.setTimeout(() => {
        savedCallback.current()
        handleTick()
      }, delay)
    }
    handleTick()

    return () => window.clearTimeout(timeoutId.current)
  }, [delay])
  const cancel = React.useCallback(function () {
    window.clearTimeout(timeoutId.current)
  }, [])
  return cancel
}
