import Web3 from 'web3'

const WEBSOCKET_TESTNET = 'wss://ws-mumbai.matic.today'
const WEBSOCKET_MAINNET = 'wss://polygon-mainnet.g.alchemy.com/v2/PLh-zCcMAGuB2pHN3c1Lz1x_5EIW_cps'

const getWeb3L2 = (testnet = false) => {
  const url = testnet ? WEBSOCKET_TESTNET : WEBSOCKET_MAINNET
  const provider = new Web3.providers.WebsocketProvider(url)

  return new Web3(provider)
}

export default getWeb3L2
