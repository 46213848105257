import React, { forwardRef } from 'react'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import { RMRK_LOGO } from '../../../lib/constants'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import StyledEmojiAvatar from './StyledEmojiAvatar'

function EmojiAvatarGroup(
  {
    avatarParams = {},
    maxShown = 3,
    emojiList = [],
    sx = {},
    onClick,
    onMouseEnter,
    onTouchStart,
    onTouchEnd,
    logoStyle = {
      position: 'absolute',
      top: 'calc(100% + 10px)'
    },
    loading = false
  },
  ref
) {
  const theme = useTheme()

  return (
    <Box
      id={'emoji-avatar-group-wrapper'}
      sx={{
        position: 'relative',
        ...sx
      }}
    >
      <div
        style={{
          position: 'relative'
        }}
      >
        <AvatarGroup
          ref={ref}
          max={maxShown}
          sx={{
            ...sx,
            '& .MuiAvatar-root': {
              width: 30,
              height: 30,
              fontSize: 15,
              ...(loading
                ? { backgroundColor: 'transparent' }
                : {
                    backgroundImage: `linear-gradient( 135deg,${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`
                  }),
              color: 'white',
              ...avatarParams
            }
          }}
          total={emojiList && emojiList.length}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
        >
          {loading ? (
            <Avatar style={{ overflow: 'visible' }}>
              <CircularProgress size={30} color="primary" variant="indeterminate" />
            </Avatar>
          ) : emojiList.length === 0 ? (
            <Avatar style={{ backgroundColor: 'grey', filter: 'grayscale(100%)' }}>👍</Avatar>
          ) : (
            emojiList.map((emoji) => {
              return (
                <StyledEmojiAvatar key={emoji} alt={emoji}>
                  {emoji}
                </StyledEmojiAvatar>
              )
            })
          )}
        </AvatarGroup>
        <img
          src={emojiList.length > 2 ? RMRK_LOGO.horizontal : RMRK_LOGO.vertical}
          alt={'powered by rmrk'}
          style={logoStyle}
        />
      </div>
    </Box>
  )
}

export default forwardRef(EmojiAvatarGroup)
