import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AssessmentIcon from '@mui/icons-material/Assessment'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExternalLink from '../../common/links/external'
import IconLink from '../../common/links/icon'
import TokenBridgeDialog from '../../../js/pages/montage/token_bridge_dialog'
import CreateMontageDialog from '../../../js/pages/montage/montage_nfts_dialog'
import useCreateMontageNft from '../../../hooks/montage/use-create-montage-nft'
import FramePickerDialog from '../../common/frames/frame-picker-dialog'
import PulseBorder from '../../common/pulse-border'
import MurAllSocials from '../../common/socials/MurAllSocials'

const ExtraMenu = () => {
  const { account } = useWeb3React()
  const [anchorEl, setAnchorEl] = useState(null)
  const [bridgeDialogOpen, setBridgeDialogOpen] = useState(false)
  const [montageDialogOpen, setMontageDialogOpen] = useState(false)
  const [framePickerOpen, setFramePickerOpen] = useState(false)
  const [montageInputData, setMontageInputData] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const { apply, error, loading, success } = useCreateMontageNft(
    montageInputData && montageInputData.name,
    montageInputData && montageInputData.description,
    montageInputData && montageInputData.canBeUnpacked,
    montageInputData && montageInputData.tokenIds
  )

  useEffect(() => {
    if (montageInputData) {
      apply()
    }
  }, [montageInputData])
  useEffect(() => {
    if (success) {
      setBridgeDialogOpen(false)
      setMontageDialogOpen(false)
    }
  }, [success])

  return (
    <>
      <ExtraButton aria-label='extra-menu' onClick={handleClick}>
        <MoreHorizIcon />
      </ExtraButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1.5,
            bgcolor: '#333',
            borderRadius: '10px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#333',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
      >
        {account && (
          <MenuItem onClick={handleClose}>
            <Link
              to='/allnfts'
              text='My NFTs'
              icon={
                <AccountBoxIcon
                  fontSize='inherit'
                  sx={{ marginLeft: 'auto' }}
                />
              }
            />
          </MenuItem>
        )}
        {account && (
          <MenuItem onClick={handleClose}>
            <Link
              to='/mystats'
              text='My Stats'
              icon={
                <AssessmentIcon
                  fontSize='inherit'
                  sx={{ marginLeft: 'auto' }}
                />
              }
            />
          </MenuItem>
        )}

        {account && (
          <MenuItem onClick={handleClose}>
            <Link
              to='/gallery'
              text='Gallery'
              icon={
                <BurstModeIcon fontSize='inherit' sx={{ marginLeft: 'auto' }} />
              }
            />
          </MenuItem>
        )}
        {/* {account && (
          <MenuItem
            onClick={() => {
              handleClose()
              setBridgeDialogOpen(true)
            }}
          >
            <Link
              text='Token Bridge'
              icon={
                <CompareArrowsIcon
                  fontSize='inherit'
                  sx={{ marginLeft: 'auto' }}
                />
              }
            />
          </MenuItem>
        )} */}
        {account && (
          <MenuItem
            onClick={() => {
              handleClose()
              setMontageDialogOpen(true)
            }}
          >
            <Link
              text='Create Montage'
              icon={
                <AutoAwesomeMosaicIcon
                  fontSize='inherit'
                  sx={{ marginLeft: 'auto' }}
                />
              }
            />
          </MenuItem>
        )}
        {account && (
          <MenuItem
            onClick={() => {
              handleClose()
              setFramePickerOpen(true)
            }}
          >
            <Link
              text='Use Frames with MurAll'
              icon={
                <img
                  src={'images/logos/frames.png'}
                  alt={'use frames with murall'}
                  style={{
                    marginLeft: 'auto',
                    height: 25,
                    width: 25,
                    objectPosition: 'center',
                    objectFit: 'contain'
                  }}
                />
              }
            />
          </MenuItem>
        )}
        <MenuItem onClick={handleClose}>
          <ExternalLinkFullWidth
            href='https://knowledgebase.murall.art'
            text={'Knowledgebase'}
            icon={
              <MenuBookIcon fontSize={'inherit'} sx={{ marginLeft: 'auto' }} />
            }
          />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ExternalLinkFullWidth
            href='https://snapshot.org/#/dao.murall.eth'
            text={'Governance'}
            icon={
              <OpenInNewIcon fontSize={'inherit'} sx={{ marginLeft: 'auto' }} />
            }
          />
        </MenuItem>
        <Border
          style={{
            marginTop: '1em',
            marginBottom: '1em'
          }}
        />
        <MurAllSocials sx={{ pt: 1, pb: 0.5 }} />
      </Menu>
      {account && (
        <FramePickerDialog
          open={framePickerOpen}
          onClose={() => {
            setFramePickerOpen(false)
          }}
        />
      )}
      {account && bridgeDialogOpen && (
        <TokenBridgeDialog
          open={bridgeDialogOpen}
          onClose={() => {
            setBridgeDialogOpen(false)
          }}
        />
      )}
      {account && montageDialogOpen && (
        <CreateMontageDialog
          open={montageDialogOpen}
          onClose={() => {
            setMontageDialogOpen(false)
          }}
          onCreateMontageButtonClick={(
            newName,
            newDescription,
            newCanBeUnpacked,
            newTokenIds
          ) => {
            if (
              montageInputData &&
              newName === montageInputData.name &&
              newDescription === montageInputData.description &&
              newCanBeUnpacked === montageInputData.canBeUnpacked &&
              newTokenIds === montageInputData.tokenIds
            ) {
              apply()
            } else {
              setMontageInputData({
                name: newName,
                description: newDescription,
                canBeUnpacked: newCanBeUnpacked,
                tokenIds: newTokenIds
              })
            }
          }}
        />
      )}
    </>
  )
}

const ExtraButton = styled(IconButton)`
  background-color: #333;
  border-radius: 30%;
  padding: 0 5px;
  border: 1px solid #444;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`

const Link = styled(IconLink)`
  width: 100%;
`

const ExternalLinkFullWidth = styled(ExternalLink)`
  width: 100%;
`

const Border = styled(PulseBorder)`
  height: 1px;
  position: relative;
`

export default ExtraMenu
