import { EVOLV3_CONTRACT_INFO, SupportedChain } from '../lib/constants'
import Evolv3NftAbi from '../contracts/evolv3/MurAllEvolv3NFT.json'
import { ethers } from 'ethers'
import { useEthersContractForAddress } from './use-ethers-contract'

export const useMurAllEvolv3Contract = (
  chainId = SupportedChain.Polygon,
  websocketInstance = false
) => {
  return useEthersContractForAddress({
    abi: Evolv3NftAbi.abi,
    contractAddress: EVOLV3_CONTRACT_INFO[chainId]?.MurAllEvolv3NFT,
    targetChainId: chainId,
    websocketInstance: websocketInstance
  })
}

export const createEvolv3NftContractInstance = ({
  provider,
  chainId = SupportedChain.Polygon
}) => {
  return new ethers.Contract(
    EVOLV3_CONTRACT_INFO[chainId]?.MurAllEvolv3NFT,
    Evolv3NftAbi.abi,
    provider
  )
}
