import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const StyledStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: `linear-gradient( 135deg,${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundImage: `linear-gradient( 135deg,${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`
  })
}))

export function StyledStepIcon (props) {
  const { active, completed, className } = props

  return (
    <StyledStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {props.icon}
    </StyledStepIconRoot>
  )
}

StyledStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
  stepInfo: PropTypes.object
}
