import Web3 from 'web3'
import { useApi } from './use-api'
import {
  processNftData,
  processNftTokenUriMetadata
} from '../js/modules/blockchain/NftDataMapper'
import {
  useMurAllNFTContract,
  useNftMetadataContract
} from './use-contract-for-chain'
import { useMurAllDataSource } from './use-murall-datasource'
import { useActiveWeb3React } from './web3'
import { useMemo } from 'react'
import { getMurAllNFTContractAddress } from '../js/modules/blockchain/datasource/ContractAddressDataSource'
const async = require('async')
import { arrayBetweenRange, isTransactionSuccess } from '../js/libs/appUtils'
import { useSelector } from 'react-redux'
import { MURALL_WALL, MURALL_WALL_ID, SupportedChain } from '../lib/constants'
import { batchFetchTokensInRangeCached } from './api-utils'
// returns null on errors

export const useMurAllNftDataSource = wallId => {
  let wallIdToUse = wallId
  const currentWallId = useSelector(state => state.wall.id)
  if (!wallIdToUse) {
    wallIdToUse = currentWallId
  }
  if (wallIdToUse === MURALL_WALL_ID.EVOLV3) {
    console.error(
      'useMurAllNftDataSource not supported for EVOLV3 - use useMurAllEvolv3NftDataSource instead. Falling back to MURALL_WALL_ID.LAYER_2 which is also on Polygon'
    )
    wallIdToUse = MURALL_WALL_ID.LAYER_2
  }
  const wallInfo = MURALL_WALL[wallIdToUse]

  const api = useApi(wallInfo.chainId, wallIdToUse)

  const contract = useMurAllNFTContract(wallInfo.chainId)
  const nftMetadataContract = useNftMetadataContract(wallInfo.chainId)
  const murAllDataSource = useMurAllDataSource(wallIdToUse)

  return useMemo(() => {
    if (!contract || !nftMetadataContract || !murAllDataSource) {
      return null
    }
    const getRemoteTokenTransactionDataForIdList = (
      tokenIds,
      asNftInfo = false
    ) => {
      return new Promise(function (resolve, reject) {
        murAllDataSource
          .getPaintedEventsForTokenIds(tokenIds)
          .then(async function (events) {
            if (events.length == 0) {
              reject('No events found')
            } else {
              const returnValues = await async.map(
                events,
                async function (event, callback) {
                  const tokenInfo = event.returnValues
                  if (asNftInfo) {
                    const status = await contract.methods
                      .getArtworkFillCompletionStatus(tokenInfo.tokenId)
                      .call()
                    const fillAmount = Web3.utils
                      .toBN(status.colorIndexLength)
                      .add(Web3.utils.toBN(status.individualPixelsLength))
                      .add(Web3.utils.toBN(status.pixelGroupsLength))
                      .add(Web3.utils.toBN(status.pixelGroupIndexesLength))
                      .add(Web3.utils.toBN(status.transparentPixelGroupsLength))
                      .add(
                        Web3.utils.toBN(
                          status.transparentPixelGroupIndexesLength
                        )
                      )
                      .toNumber()

                    const totalFillAmount =
                      tokenInfo.colorIndex.length +
                      tokenInfo.pixelData.length +
                      tokenInfo.pixelGroups.length +
                      tokenInfo.pixelGroupIndexes.length +
                      tokenInfo.transparentPixelGroups.length +
                      tokenInfo.transparentPixelGroupIndexes.length
                    const nftInfo = await processNftData(
                      contractAddress,
                      tokenInfo.tokenId,
                      tokenInfo.artist,
                      tokenInfo.colorIndex,
                      tokenInfo.pixelData,
                      tokenInfo.pixelGroups,
                      tokenInfo.pixelGroupIndexes,
                      tokenInfo.transparentPixelGroups,
                      tokenInfo.transparentPixelGroupIndexes,
                      tokenInfo.metadata[0],
                      tokenInfo.metadata[1],
                      fillAmount == totalFillAmount,
                      Math.round((fillAmount / totalFillAmount) * 100),
                      wallInfo.chainId
                    )
                    callback(null, nftInfo)
                  } else {
                    callback(null, tokenInfo)
                  }
                }
              )

              resolve(returnValues)
            }
          })
      })
    }

    const getRemoteTokenTransactionDataForId = (tokenId, asNftInfo = false) => {
      return new Promise(function (resolve, reject) {
        murAllDataSource
          .getPaintedEventsForTokenIds([tokenId])
          .then(async function (events) {
            if (events.length == 0) {
              reject('No events found')
            } else {
              if (asNftInfo) {
                const tokenInfo = events[0].returnValues
                const status = await contract.methods
                  .getArtworkFillCompletionStatus(tokenInfo.tokenId)
                  .call()
                const fillAmount = Web3.utils
                  .toBN(status.colorIndexLength)
                  .add(Web3.utils.toBN(status.individualPixelsLength))
                  .add(Web3.utils.toBN(status.pixelGroupsLength))
                  .add(Web3.utils.toBN(status.pixelGroupIndexesLength))
                  .add(Web3.utils.toBN(status.transparentPixelGroupsLength))
                  .add(
                    Web3.utils.toBN(status.transparentPixelGroupIndexesLength)
                  )
                  .toNumber()

                const totalFillAmount =
                  tokenInfo.colorIndex.length +
                  tokenInfo.pixelData.length +
                  tokenInfo.pixelGroups.length +
                  tokenInfo.pixelGroupIndexes.length +
                  tokenInfo.transparentPixelGroups.length +
                  tokenInfo.transparentPixelGroupIndexes.length
                const nftInfo = await processNftData(
                  getMurAllNFTContractAddress(wallInfo.chainId),
                  tokenId,
                  tokenInfo.artist,
                  tokenInfo.colorIndex,
                  tokenInfo.pixelData,
                  tokenInfo.pixelGroups,
                  tokenInfo.pixelGroupIndexes,
                  tokenInfo.transparentPixelGroups,
                  tokenInfo.transparentPixelGroupIndexes,
                  tokenInfo.metadata[0],
                  tokenInfo.metadata[1],
                  fillAmount == totalFillAmount,
                  Math.round((fillAmount / totalFillAmount) * 100),
                  wallInfo.chainId
                )
                resolve(nftInfo)
              } else {
                resolve(events[0].returnValues)
              }
            }
          })
      })
    }

    const getOffchainRemoteTokenTransactionDataForId = (
      tokenId,
      asNftInfo = false
    ) => {
      return new Promise(async function (resolve, reject) {
        try {
          const res = await fetch(api.tokens.cachedToken(tokenId))
          const tokenInfo = await res.json()

          if (asNftInfo) {
            const nftInfo = await processNftTokenUriMetadata(
              getMurAllNFTContractAddress(wallInfo.chainId),
              tokenInfo,
              wallInfo.chainId
            )

            resolve(nftInfo)
          } else {
            resolve(tokenInfo)
          }
        } catch (error) {
          console.error(error)
          // fallback to onchain data
          const info = await getRemoteTokenTransactionDataForId(
            tokenId,
            asNftInfo
          )
          resolve(info)
        }
      })
    }

    const getOffchainRemoteTokenTransactionDataForIdRange = (
      fromTokenId,
      toTokenId,
      asNftInfo = false
    ) => {
      return new Promise(async function (resolve, reject) {
        if (asNftInfo) {
          try {
            const tokenInfos = await batchFetchTokensInRangeCached(
              api,
              fromTokenId,
              toTokenId
            )
            const returnValues = await async.map(
              tokenInfos,
              async function (tokenInfo, callback) {
                try {
                  if (asNftInfo) {
                    const nftInfo = await processNftTokenUriMetadata(
                      getMurAllNFTContractAddress(wallInfo.chainId),
                      tokenInfo,
                      wallInfo.chainId
                    )
                    callback(null, nftInfo)
                  } else {
                    callback(null, tokenInfo)
                  }
                } catch (error) {
                  console.error(error)
                  callback(null, tokenInfo)
                }
              }
            )
            resolve(returnValues)
          } catch (error) {
            reject(error)
          }
        } else {
          // fallback to onchain data
          try {
            const tokenInfos = await getRemoteTokenTransactionDataForIdList(
              arrayBetweenRange(fromTokenId, toTokenId),
              asNftInfo
            )
            resolve(tokenInfos)
          } catch (error) {
            reject(error)
          }
        }
      })
    }
    const getOffchainRemoteTokenTransactionDataForIdList = (
      tokenIds,
      asNftInfo = false
    ) => {
      return new Promise(async function (resolve, reject) {
        try {
          const returnValues = await async.map(
            tokenIds,
            async function (tokenId, callback) {
              try {
                const res = await fetch(api.tokens.cachedToken(tokenId))
                const tokenInfo = await res.json()

                if (asNftInfo) {
                  const nftInfo = await processNftTokenUriMetadata(
                    getMurAllNFTContractAddress(wallInfo.chainId),
                    tokenInfo,
                    wallInfo.chainId
                  )
                  callback(null, nftInfo)
                } else {
                  callback(null, tokenInfo)
                }
              } catch (error) {
                console.error(error)
                // fallback to onchain data
                const info = await getRemoteTokenTransactionDataForId(
                  tokenId,
                  asNftInfo
                )
                callback(null, info)
              }
            }
          )
          resolve(returnValues)
        } catch (error) {
          reject(error)
        }
      })
    }

    const getOwnedTokenIdsInIndexRange = (
      ownerAddress,
      startIndex,
      endIndex
    ) => {
      const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
        startIndex,
        endIndex + 1
      )

      return Promise.all(
        indexes.map(index =>
          contract.methods.tokenOfOwnerByIndex(ownerAddress, index).call()
        )
      )
    }

    return {
      fillExistingTokenWithData: (
        sender,
        id,
        colorIndex,
        blockchainPixelData,
        blockchainPixelGroupData,
        blockchainPixelGroupIndexData,
        blockchainTransparentPixelGroupData,
        blockchainTransparentPixelGroupIndexData
      ) => {
        return new Promise(async function (resolve, reject) {
          contract.methods
            .fillData(
              id,
              colorIndex,
              blockchainPixelData,
              blockchainPixelGroupData,
              blockchainPixelGroupIndexData,
              blockchainTransparentPixelGroupData,
              blockchainTransparentPixelGroupIndexData
            )
            .send({ from: sender })
            .on('receipt', function (receipt) {
              if (isTransactionSuccess(receipt)) {
                const event = receipt.events.ArtworkFilled
                const isFilled = event.returnValues.finished

                resolve(isFilled)
              } else {
                reject('Transaction Failed')
              }
            })
            .on('error', function (error) {
              reject(error)
            })
        })
      },

      fetchNftDataForAccountForOwnedTokenAtIndex: async (
        address,
        index,
        preferOffchain = true
      ) => {
        const tokenId = await contract.methods
          .tokenOfOwnerByIndex(address, index)
          .call()
        return preferOffchain
          ? getOffchainRemoteTokenTransactionDataForId(tokenId, true)
          : getRemoteTokenTransactionDataForId(
              web3,
              contractAddress,
              murAllContractAddress,
              tokenId,
              true
            )
      },

      fetchAllTokenInfoForTokenIndexRange: (
        fromTokenId,
        toTokenId,
        preferOffchain = true
      ) => {
        return preferOffchain
          ? getOffchainRemoteTokenTransactionDataForIdRange(
              fromTokenId,
              toTokenId,
              true
            )
          : getRemoteTokenTransactionDataForIdList(
              arrayBetweenRange(fromTokenId, toTokenId),
              true
            )
      },
      fetchAllTokenInfoForTokenIndexes: (
        tokenIndexes,
        preferOffchain = true
      ) => {
        return preferOffchain
          ? getOffchainRemoteTokenTransactionDataForIdList(tokenIndexes, true)
          : getRemoteTokenTransactionDataForIdList(tokenIndexes, true)
      },

      getNftDataForAccountForOwnedTokenInIndexRange: async (
        address,
        startIndex,
        endIndex,
        preferOffchain = true
      ) => {
        const tokenIds = await getOwnedTokenIdsInIndexRange(
          address,
          startIndex,
          endIndex
        )

        return preferOffchain
          ? getOffchainRemoteTokenTransactionDataForIdList(tokenIds, true)
          : getRemoteTokenTransactionDataForIdList(tokenIds, true)
      },
      getTokenMetadataForTokenId: tokenId => {
        return nftMetadataContract.methods.getNftMetadata(tokenId).call()
      },
      getOffchainRemoteTokenTransactionDataForId,
      getRemoteTokenTransactionDataForId,
      getOffchainRemoteTokenTransactionDataForIdList,
      getRemoteTokenTransactionDataForIdList,
      getBalanceForAddress: address => {
        return contract.methods.balanceOf(address).call()
      },
      ownerOf: tokenId => {
        return contract.methods.ownerOf(tokenId).call()
      },
      name: () => {
        return contract.methods.name().call()
      },
      symbol: () => {
        return contract.methods.symbol().call()
      },
      totalSupply: () => {
        return contract.methods.totalSupply().call()
      },
      exists: tokenId => {
        return contract.methods.exists(tokenId).call()
      },
      getOwnedTokenIdsInIndexRange,
      getOwnedTokenIdAtIndex: (ownerAddress, index) => {
        return contract.methods.tokenOfOwnerByIndex(ownerAddress, index).call()
      },
      transferTokenId: (senderAddress, recipientAddress, tokenId) => {
        return contract.methods
          .transferFrom(
            senderAddress,
            recipientAddress,
            Web3.utils.toBN(tokenId)
          )
          .send({
            from: senderAddress
          })
      },
      setApproveTokenTransfer: (ownerAddress, spenderAddress, approved) => {
        return contract.methods
          .setApprovalForAll(spenderAddress, approved)
          .send({
            from: ownerAddress
          })
      },
      isTokenTransferApproved: (ownerAddress, spenderAddress) => {
        return contract.methods
          .isApprovedForAll(ownerAddress, spenderAddress)
          .call()
      },
      getTokenUri: tokenId => {
        return contract.methods.tokenURI(tokenId).call()
      },
      getOffchainRemoteData: tokenId => {
        return new Promise(async function (resolve, reject) {
          try {
            const url = await contract.methods.tokenURI(tokenId).call()
            const res = await fetch(url)
            const tokenInfo = await res.json()

            resolve(tokenInfo)
          } catch (error) {
            reject(error)
          }
        })
      },
      getFullTokenUriMetadata: async tokenId => {
        let tokenUri = await contract.methods.tokenURI(tokenId).call()

        tokenUri = replaceIpfsIfNeeded(tokenUri)

        if (tokenUri.indexOf('http') === -1) {
          tokenUri = 'https://' + tokenUri
        }

        let returnData
        if (
          tokenUri &&
          tokenUri.startsWith('https://data:application/json;base64')
        ) {
          const json = Buffer.from(
            tokenUri.replace('https://data:application/json;base64', ''),
            'base64'
          )
          returnData = JSON.parse(json)
        } else {
          const response = await fetch(tokenUri)
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }
          returnData = await response.json()
        }

        return returnData
      }
    }
  }, [
    api,
    contract,
    nftMetadataContract,
    murAllDataSource,
    wallIdToUse,
    currentWallId
  ])
}
