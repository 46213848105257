import React, { useState, useEffect, useContext } from 'react'
import { VirtuosoGrid } from 'react-virtuoso'
import styled from 'styled-components'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
const useStyles = makeStyles(theme => ({
  gridList: {
    width: '100%',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  }
}))

const DefaultVirtualizedGrid = ({
  style,
  items,
  loadingPlaceholder,
  customItemContainer,
  renderItem
}) => {
  const classes = useStyles()
  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 10
  })
  const ItemContainer = customItemContainer || DefaultItemContainer
  return (
    <VirtuosoGrid
      className={clsx(classes.gridList)}
      style={style}
      totalCount={items.length}
      // overscan={4}
      components={{
        Item: ItemContainer,
        List: ListContainer,
        ScrollSeekPlaceholder: ({ height, width, index }) => (
          <ItemContainer>
            <ItemWrapper>{loadingPlaceholder}</ItemWrapper>
          </ItemContainer>
        )
      }}
      itemContent={index => {
        const currentItem = items[index]
        return <ItemWrapper>{renderItem(index, currentItem)}</ItemWrapper>
      }}
      scrollSeekConfiguration={{
        enter: velocity => {
          return Math.abs(velocity) > 700
        },
        exit: velocity => {
          const shouldExit = Math.abs(velocity) < 10
          if (shouldExit) {
            setVisibleRange(['-', '-'])
          }
          return shouldExit
        },
        change: (_, range) => setVisibleRange(range)
      }}
    />
  )
}

const ItemWrapper = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
`

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
`
const DefaultItemContainer = styled.div`
  padding: 0.5rem;
  aspect-ratio: 1/1.15;
  width: 15%;
  max-width: 345,
  display: flex;
  flex: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 1536px) {
    width: 25%;
  }
  @media (max-width: 1200px) {
    width: 33%;
  }

  @media (max-width: 1024px) {
    width: 33%;
  }

  @media (max-width: 900px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`
export default DefaultVirtualizedGrid
