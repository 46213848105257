import EthLogo from '../../../images/logos/ethereum-white.svg'
import PolygonLogo from '../../../images/logos/polygon-white.svg'
import { SupportedChain } from '../../lib/constants'

export const logoForChain = chainId => {
    switch (chainId) {
      case SupportedChain.Ethereum:
        return EthLogo
      case SupportedChain.Polygon:
        return PolygonLogo
      default:
        return EthLogo
    }
  }
  
 export const colorForChain = chainId => {
    switch (chainId) {
      case SupportedChain.Ethereum:
        return '#5f81e7'
      case SupportedChain.Polygon:
        return '#8247E5'
      default:
        return '#5f81e7'
    }
  }