import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import { Grid, Box, Typography, Icon } from '@mui/material'
import clsx from 'clsx'

const RootContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  height: ${({ theme }) => `calc(100% - ${theme.spacing(4)})`};
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  pointer-events: none;
  overflow: hidden;
`

const SectionWrapper = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: ${({ theme }) => `calc(100% - ${theme.spacing(4)})`};
  z-index: 0;
  transition: all 0.5s;
  align-items: center;
  justify-content: center;
`
const SelectorWrapper = styled.div`
  z-index: 1;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: visible;
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 300ms ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  pointer-events: none;
  background-color: ${({ theme }) => theme.palette.primary.dark};
`
const SelectItem = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: visible;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-in-out;
`

export default function HeroTabSwitcher ({
  style,
  tabContainerStyle,
  title,
  subtitle,
  tabDirection = 'column',
  selectedSection = 1,
  onSectionHoverChange,
  tab1,
  tab2,
  tab3,
  children
}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const theme = useTheme()
  return (
    <RootContainer
      id={'RootContainer'}
      tabDirection={tabDirection}
      isMobile={isMobile}
      style={{
        ...style
      }}
    >
      <SectionWrapper>{children}</SectionWrapper>
      <SectionWrapper id={'buttonsContainer'}>
        <Container
          id={'Container'}
          maxWidth='xs'
          sx={{
            borderRadius: '10px',
            border: '5px solid #000',
            mt: 2,
            p: 2
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
            backgroundColor: theme.palette.primary.dark,
            ...tabContainerStyle
          }}
        >
          <Typography
            noWrap
            align={'center'}
            sx={{
              mb: 2,
              flexGrow: 1
            }}
          >
            {title}
          </Typography>
          <Grid
            container
            spacing={0}
            direction='row'
            justifyContent='center'
            alignItems='center'
            id={'outer grid'}
            sx={{
              width: '100%',
              mb: 2,
              flexGrow: 1
            }}
          >
            <Grid item xs={4} id={'grid item'}>
              <HeroSelectItem
                id={'HeroSelectItem1'}
                tabDirection={tabDirection}
                sectionId={1}
                sectionHovered={selectedSection}
                setSectionHovered={onSectionHoverChange}
                hoverEnabled={!isMobile}
              >
                {tab1}
              </HeroSelectItem>
            </Grid>
            <Grid item xs={4} id={'grid item'}>
              <HeroSelectItem
                id={'HeroSelectItem2'}
                tabDirection={tabDirection}
                sectionId={2}
                sectionHovered={selectedSection}
                setSectionHovered={onSectionHoverChange}
                hoverEnabled={!isMobile}
              >
                {tab2}
              </HeroSelectItem>
            </Grid>
            <Grid item xs={4} id={'grid item'}>
              <HeroSelectItem
                id={'HeroSelectItem3'}
                tabDirection={tabDirection}
                sectionId={3}
                sectionHovered={selectedSection}
                setSectionHovered={onSectionHoverChange}
                hoverEnabled={!isMobile}
              >
                {tab3}
              </HeroSelectItem>
            </Grid>
          </Grid>
          <Typography
            noWrap
            align={'center'}
            sx={{
              flexGrow: 1
            }}
          >
            {subtitle}
          </Typography>
        </Container>
      </SectionWrapper>
    </RootContainer>
  )
}

const HeroSelectItem = ({
  id,
  tabDirection,
  sectionId,
  hoverEnabled,
  sectionHovered,
  setSectionHovered,
  children
}) => {
  return (
    <SelectItem
      id={id}
      tabDirection={tabDirection}
      {...(hoverEnabled &&
        setSectionHovered && {
          onMouseEnter: e => setSectionHovered(sectionId),
          onTouchStart: e => setSectionHovered(sectionId),
          onPointerEnter: e => setSectionHovered(sectionId),
          onPointerDown: e => setSectionHovered(sectionId),
          onPointerOver: e => setSectionHovered(sectionId)
        })}
      selected={sectionHovered === sectionId}
    >
      {children}
    </SelectItem>
  )
}
