import React, { createContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ThemeProvider from './theme/Provider'
import Routes from './routes'
import Notifications from 'components/common/notifications'
import Web3ReactManager from 'components/common/web3-react-manager'
import getWeb3L1 from './js/libs/getWeb3L1'
import getWeb3L2 from './js/libs/getWeb3L2'
import CssBaseline from '@mui/material/CssBaseline'
import Updater from './state/updater'
import getEthersProvider from './js/libs/getEthersProvider'
import { SupportedChain } from './lib/constants'
import getEthersWebsocketProvider from './js/libs/getEthersWebsocketProvider'

export const Web3L1Context = createContext()
export const Web3L2Context = createContext()
export const EthersL1Context = createContext()
export const EthersL1WebsocketContext = createContext()
export const EthersL1TestnetContext = createContext()
export const EthersL2Context = createContext()
export const EthersL2WebsocketContext = createContext()
export const EthersL2TestnetContext = createContext()

const App = () => {
  return (
    <Web3ReactManager>
      <EthersL1Context.Provider
        value={getEthersProvider(SupportedChain.Ethereum)}
      >
        <EthersL1WebsocketContext.Provider
          value={getEthersWebsocketProvider(SupportedChain.Ethereum)}
        >
          <EthersL1TestnetContext.Provider
            value={getEthersProvider(SupportedChain.Goerli)}
          >
            <EthersL2Context.Provider
              value={getEthersProvider(SupportedChain.Polygon)}
            >
              <EthersL2WebsocketContext.Provider
                value={getEthersWebsocketProvider(SupportedChain.Polygon)}
              >
                <EthersL2TestnetContext.Provider
                  value={getEthersProvider(SupportedChain.Mumbai)}
                >
                  <Web3L1Context.Provider value={getWeb3L1()}>
                    <Web3L2Context.Provider value={getWeb3L2()}>
                      <ThemeProvider>
                        <Notifications />
                        <Updater />
                        <CssBaseline />
                        <Router>
                          <Routes />
                        </Router>
                      </ThemeProvider>
                    </Web3L2Context.Provider>
                  </Web3L1Context.Provider>
                </EthersL2TestnetContext.Provider>
              </EthersL2WebsocketContext.Provider>
            </EthersL2Context.Provider>
          </EthersL1TestnetContext.Provider>
        </EthersL1WebsocketContext.Provider>
      </EthersL1Context.Provider>
    </Web3ReactManager>
  )
}

export default App
