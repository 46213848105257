import React from 'react'
import styled from 'styled-components'
import Fab from '@mui/material/Fab'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from 'styled-components'

const ScrollDownFab = ({ onClick }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const theme = useTheme()
  return (
    <Button
      onClick={onClick}
      color='primary'
      aria-label='scroll to next section'
      style={{
        left: isMobile ? theme.spacing(3) : '50%',
        transform: isMobile ? 'translate(0, -50%)' : 'translate(-50%, -50%)'
      }}
    >
      <KeyboardArrowDownIcon />
    </Button>
  )
}

const Button = styled(Fab)`
  position: absolute;
`

export default ScrollDownFab
