import React from 'react'
import IconButton from '@mui/material/IconButton'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import InfoIcon from '@mui/icons-material/Info'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'

export const StateActions = ({
  style = {},
  onInfoButtonClicked,
  onLocateButtonClicked,
  onOpenDetailsClicked,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        zIndex: '1',
        ...style,
      }}
    >
      <IconButton aria-label="info" onClick={onInfoButtonClicked} size="large">
        <ImageSearchIcon />
      </IconButton>

      <IconButton
        aria-label="locate"
        onClick={onLocateButtonClicked}
        size="large"
      >
        <MyLocationIcon />
      </IconButton>
      <IconButton
        aria-label="details"
        onClick={onOpenDetailsClicked}
        size="large"
      >
        <InfoIcon />
      </IconButton>
    </div>
  )
}
