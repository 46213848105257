import { useCallback, useEffect, useState } from 'react'
import { useEmotableDataSource } from '../use-emotable.js'

const useGetEmoteForNftByAccount = ({ tokenId, tokenContractAddress, chainId, account, immediate = false }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [emotes, setEmotes] = useState(false)

  const emotableDataSource = useEmotableDataSource(chainId)

  const emotesForTokenByAccount = emotableDataSource?.emotesForTokenByAccount

  const apply = useCallback(async () => {
    if (!emotesForTokenByAccount) {
      setError('No account or emotesForToken function')
      return
    }
    setLoading(true)
    try {
      const emotes = await emotesForTokenByAccount(tokenContractAddress, tokenId, account)
      setEmotes(emotes)
      setLoading(false)
      return emotes
    } catch (error) {
      console.error(error)

      setError(error)
      setLoading(false)
      return
    }
  }, [emotesForTokenByAccount, account, tokenId, tokenContractAddress])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, loading, error, emotes }
}

export default useGetEmoteForNftByAccount
