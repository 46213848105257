import React, { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import '../../../css/layout.css'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { Card, Grid, CardContent } from '@mui/material'
import LeftRightInfoView from '../left-right-info-view'
import { MURALL_WALL, MURALL_WALL_QUERY_PARAM, SupportedChain } from '../../../lib/constants'
import NftInformationDialog from '../../../js/uicomponents/nft_information_dialog'
import { processNftTokenUriMetadata } from '../../../js/modules/blockchain/NftDataMapper'
import {
  getBridgedMurAllNFTL2ContractAddress,
  getMurAllNFTContractAddress
} from '../../../js/modules/blockchain/datasource/ContractAddressDataSource'
import { Button, Icon } from '@mui/material'
import { useActiveWeb3React } from '../../../hooks/web3'
import { useMurAllNftDataSource } from '../../../hooks/use-murall-nft-datasource'
import Web3 from 'web3'
import useGetOnchainPaintedData from '../../../hooks/nft/use-get-onchain-painted-data'
import { rgb565HexToRgbHex } from '../../../js/libs/appUtils'
import notification from '../../../lib/notification'
import { Link as RouterLink } from 'react-router-dom'
import Fade from '../../../reveal/in-and-out/Fade'
import StyledInfoBox from '../StyledInfoBox'
import StyledNftInfoPlaque from './styled-nft-info-plaque'
import { useMurAllBridgedNftDataSource } from '../../../hooks/use-murall-bridged-nft-datasource'

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  noPadding: {
    padding: 0,
    margin: 0
  },
  dialogMediaCropped: {
    width: '100%' /* Full width */,
    aspectRatio: '1/1',
    objectFit: 'contain',
    margin: 'auto',
    overflow: 'auto',
    transform: 'scale(1, 1)',
    transition: '0.6s',
    '&:hover': {
      transform: 'scale(1.1, 1.1)',
      cursor: 'pointer'
    }
  }
}))

const MurAllNftView = ({ chainId, wallId, id, nftData }) => {
  const { library, account, chainId: activeChainId } = useActiveWeb3React()

  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const [nftInfo, setNftInfo] = useState(false)
  const [migrated, setMigrated] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [blockNumber, setBlockNumber] = useState(null)

  const {
    ownerOf,
    getRemoteTokenTransactionDataForId: getMurAllNftRemoteTokenTransactionDataForId,
    fillExistingTokenWithData: fillMurAllNftTokenWithData
  } = useMurAllNftDataSource()
  const { apply, error, loading, nftData: onchainNftData } = useGetOnchainPaintedData({
    chainId,
    tokenId: id,
    fromBlock: blockNumber ? blockNumber - 5 : 'earliest',
    toBlock: blockNumber ? blockNumber + 5 : 'latest',
    immediate: blockNumber !== null
  })

  const { exists: existsBridged, ownerOf: ownerOfBridged } = useMurAllBridgedNftDataSource()

  useEffect(() => {
    if (onchainNftData) {
      console.log('onchainNftData', onchainNftData)
    }
  }, [onchainNftData])

  useEffect(() => {
    const fetchNftInfo = async () => {
      const nftInfo = await processNftTokenUriMetadata(getMurAllNFTContractAddress(chainId), nftData, chainId)

      const blockNumber = nftData.blockNumber
      setBlockNumber(blockNumber)

      console.log('nftInfo', nftInfo, nftData)
      setNftInfo(nftInfo)
      let migrated = false
      if (chainId === SupportedChain.Polygon) {
        console.log('checking if migrated...')
        migrated = await existsBridged(id)
      }
      setMigrated(migrated)

      let isOwner = false
      if (account && chainId === activeChainId) {
        let ownerAddress
        try {
          ownerAddress = await ownerOf(id)
          console.log('ownerAddress', ownerAddress)
          isOwner = Web3.utils.toChecksumAddress(ownerAddress) === Web3.utils.toChecksumAddress(account)
        } catch (e) {
          console.log('ownerAddress error - may be burned or migrated', e)
          if (chainId === SupportedChain.Polygon) {
            try {
              let ownerAddress = await ownerOfBridged(id)
              isOwner = Web3.utils.toChecksumAddress(ownerAddress) === Web3.utils.toChecksumAddress(account)
              console.log('migrated ownerAddress', ownerAddress, isOwner)
            } catch (e) {
              console.error('Error checking migration', e)
            }
          }
        }
      }

      setIsOwner(isOwner)
    }
    fetchNftInfo()
  }, [nftData])

  const [nftInfoOpen, setNftInfoOpen] = useState(false)

  const handleClose = () => {
    setNftInfoOpen(false)
  }

  const fillMintedToken = async () => {
    try {
      const isFilled = await notification.promise(
        fillMurAllNftTokenWithData(
          account,
          id,
          onchainNftData.colorIndex,
          onchainNftData.pixelData,
          onchainNftData.pixelGroups,
          onchainNftData.pixelGroupIndexes,
          onchainNftData.transparentPixelGroups,
          onchainNftData.transparentPixelGroupIndexes
        ),
        {
          loading: `Copying ${id} data to contract storage...`,
          success: `Success!`,
          error: (error) => {
            if (error.message && error.message.indexOf('User denied') != -1) return 'You rejected the transaction!'
            return `Sorry, the transaction failed: ${error.name}`
          }
        }
      )

      if (isFilled) {
        notification.success(`Filled data for token id ${id}`)
      } else {
        fillMintedToken()
      }
    } catch (error) {
      if (error.message && error.message.indexOf('User denied') != -1) {
        notification.error('You rejected the transaction on Metamask!')
      } else {
        notification.error(`Data fill failed for token id ${id}`)
      }
      console.error(error)
    }
  }

  const constructColourIndexItem = (rgb565Colour) => {
    const rgbColour = '#' + rgb565HexToRgbHex('0x' + rgb565Colour)
    return (
      <Grid key={rgb565Colour} item align="center" className={clsx(classes.noPadding)}>
        <Typography
          className={clsx(classes.typography, classes.noPadding)}
          variant="body2"
          component="p"
          color="textSecondary"
          style={{
            color: rgbColour
          }}
        >
          &#11044;
        </Typography>
      </Grid>
    )
  }

  const goToExternalUrl = () => {
    if (nftInfo.externalUrl) {
      window.open(nftInfo.externalUrl, '_blank')
      return
    }
    const baseUrl =
      chainId === SupportedChain.Mumbai
        ? 'https://testnets.opensea.io/assets/mumbai'
        : chainId === SupportedChain.Polygon
        ? 'https://opensea.io/assets/matic'
        : 'https://opensea.io/assets/ethereum'
    if (migrated) {
      window.open(`${baseUrl}/${getBridgedMurAllNFTL2ContractAddress()}/${nftInfo.tokenId}`, '_blank')
    } else {
      window.open(`${baseUrl}/${nftInfo.contractAddress}/${nftInfo.tokenId}`, '_blank')
    }
  }

  const constructNftInfoDialog = () => (
    <NftInformationDialog
      open={nftInfoOpen}
      onClose={handleClose}
      showCompletionStatus
      chainId={chainId}
      name={nftData.name}
      tokenId={id}
      contractAddress={nftData?.contractAddress || nftInfo?.contractAddress || MURALL_WALL[wallId].nftContractAddress}
      croppedImage={nftInfo ? nftInfo.croppedBase64PngString : nftData.image}
      fullImage={nftInfo ? nftInfo.fullBase64PngString : nftData.image}
      nftInformation={nftInfo}
      withViewCropToggle
      hideInformation
    />
  )

  const createTextRow = (index, key, value, breakpoints) => (
    <Grid item {...breakpoints} key={index}>
      <Fade right cascade>
        <StyledInfoBox
          sx={{
            flex: 1
          }}
          title={key}
          subtitle={value}
        />
      </Fade>
    </Grid>
  )
  const constructColorIndex = (breakpoints) => (
    <Grid item {...breakpoints} key={'colourIndex'}>
      <Fade right cascade>
        <StyledInfoBox
          sx={{
            flex: 1
          }}
          title={`Color index (${onchainNftData.colorIndex.length} colors)`}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            sx={{
              mt: { xs: '6px', sm: '12px' }
            }}
          >
            {onchainNftData.colorIndex.map(constructColourIndexItem)}
          </Grid>
        </StyledInfoBox>
      </Fade>
    </Grid>
  )

  const createInfoPlaque = () => (
    <Grid item xs={12}>
      <Fade right cascade>
        <StyledNftInfoPlaque creatorAddress={nftData.artist} description={nftInfo.description} title={nftData.name} />
      </Fade>
    </Grid>
  )

  return (
    <LeftRightInfoView
      leftContents={
        <>
          <img
            className={clsx('pixelated-image', classes.dialogMediaCropped)}
            src={nftData.image}
            alt={'Token ' + id}
            onClick={() => {
              setNftInfoOpen(true)
            }}
          />
          {constructNftInfoDialog()}
        </>
      }
      rightContents={
        <Card
          elevation={0}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            backgroundColor: 'transparent'
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px',
              width: '100%'
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{
                pt: { xs: '12px', sm: '24px', md: '36px' },
                pb: { xs: '12px', sm: '24px', md: '36px' }
              }}
            >
              {createInfoPlaque()}

              {createTextRow(0, 'Number of Pixels', nftData.pixels, {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 4
              })}
              {createTextRow(1, 'Created', new Date(Number(nftData.timestamp)).toLocaleString(), {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 4
              })}
              {migrated &&
                createTextRow(2, 'Bridged To', 'Ethereum', {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4
                })}
              {onchainNftData && constructColorIndex({ xs: 12 })}
            </Grid>
            <Grid container direction="column" alignItems="stretch" justifyContent={'center'} spacing={2}>
              <Grid item xs>
                <Button
                  variant="contained"
                  disabled={!account || migrated || !onchainNftData}
                  fullWidth
                  align="center"
                  onClick={fillMintedToken}
                >
                  {migrated
                    ? `Bridge back to ${
                        chainId === SupportedChain.Ethereum ? 'Ethereum' : 'Polygon'
                      } to copy data to contract storage`
                    : 'Copy image data to contract storage'}
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  fullWidth
                  align="center"
                  component={RouterLink}
                  to={`/state?wall=${MURALL_WALL_QUERY_PARAM[wallId]}&atTokenId=${id}`}
                >
                  View on the MurAll
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  fullWidth
                  align="center"
                  component={RouterLink}
                  to={`/artist/${nftData.artist}`}
                >
                  View other works by this artist
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  fullWidth
                  align="center"
                  onClick={goToExternalUrl}
                  endIcon={
                    <Icon style={{ fontSize: 35, textAlign: 'center' }}>
                      <img src={'images/opensea_logo_white.svg'} alt={'opensea'} />
                    </Icon>
                  }
                  sx={{
                    backgroundColor: '#2081e2'
                  }}
                >
                  View on OpenSea
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
    />
  )
}

export default MurAllNftView
