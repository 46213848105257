import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  EVOLV3_FILETYPES,
  MURALL_WALL_ID,
} from '../../../lib/constants'
import SvgHistoryState from './svg-history-state'
import { gsap } from 'gsap'
import { startPixellateAnimation, startLocateAnimation } from './animations'
import SvgHistoryVideoState from './svg-history-video-state'
import SvgHistoryVideoLocateState from './svg-history-video-locate-state'
import SvgCollatedHistoryState from './svg-collated-history-state'

const DEFAULT_LOCATE_ANIMATION_DURATION_MILLIS = 800
const COLLATED_STATE_SIZE = 10

const opacityForState = (
  index,
  selectedStateIndex,
  closestLoadedCollatedState
) => {
  // States adjacent to or at the selectedStateIndex
  if (
    index === selectedStateIndex ||
    index === selectedStateIndex - 1 ||
    index === selectedStateIndex + 1
  ) {
    return 1
  }

  // States greater than selectedStateIndex + 1
  if (index > selectedStateIndex + 1) {
    return 0
  }

  // States below the next loaded collated state
  if (index < closestLoadedCollatedState) {
    return 0
  }

  // All other cases
  return 1
}

function SvgHistoryViewer(
  {
    isPlaying,
    wallId,
    style,
    historyStates,
    selectedStateIndex,
    shouldLocate,
    baseState,
    animationDuration,
    locateAnimationDuration,
    useGlitchEffect,
    onVideoInfoUpdate,
    onVideoStarted,
    onVideoEnded,
    onVolumeChange,
    videoState = {
      paused: false,
      currentTime: 0,
      duration: 0,
      muted: true,
      volume: 1,
    },
  },
  ref
) {
  const blurRef = useRef(null)
  const fadeRRef = useRef(null)
  const fadeGRef = useRef(null)
  const fadeBRef = useRef(null)
  const videoRef = useRef(null)

  const pixellateGlitchFilter = useRef(null)
  const currentPixellateGlitchAnim = useRef(null)
  const currentLocateAnim = useRef(null)

  const [isLocating, setLocating] = useState(false)
  const [videoStateInternal, setVideoStateInternal] = useState(videoState)

  const [loadedStates, setLoadedStates] = useState([])
  const timeline = gsap.timeline()

  const locateAnimDuration =
    locateAnimationDuration || DEFAULT_LOCATE_ANIMATION_DURATION_MILLIS
  // Determine the collated base index based on the selectedStateIndex
  const collatedBaseIndex =
    Math.floor(selectedStateIndex / COLLATED_STATE_SIZE) * COLLATED_STATE_SIZE

  const closestLoadedCollatedState =
    loadedStates.length > 0
      ? loadedStates.reduce((prev, curr) => {
          if (curr <= selectedStateIndex) {
            return Math.abs(curr - selectedStateIndex) <
              Math.abs(prev - selectedStateIndex)
              ? curr
              : prev
          }
          return prev
        }, -Infinity)
      : 0

  useEffect(() => {
    setLoadedStates([])
  }, [wallId])

  useImperativeHandle(
    ref,
    () => ({
      getVideoState: () => {
        return videoStateInternal
      },
      paused: videoRef.current?.paused,
      currentTime: videoRef.current?.currentTime,
      setCurrentTime: (time) => {
        videoRef.current.currentTime = time
        setVideoStateInternal({
          ...videoStateInternal,
          currentTime: time,
        })
      },
      duration: videoRef.current?.duration,
      muted: videoRef.current?.muted,
      setMuted: (muted) => {
        videoRef.current.muted = muted
        setVideoStateInternal({
          ...videoStateInternal,
          muted,
        })
      },
      volume: videoRef.current?.volume,
      setVolume: (volume) => {
        videoRef.current.volume = volume
        setVideoStateInternal({
          ...videoStateInternal,
          volume,
        })
      },
      play: () => {
        videoRef.current?.play()
        setVideoStateInternal({
          ...videoStateInternal,
          isPlaying: true,
        })
      },
      pause: () => {
        videoRef.current?.pause()
        setVideoStateInternal({
          ...videoStateInternal,
          isPlaying: false,
        })
      },
    }),
    [videoRef, videoRef?.current, videoStateInternal]
  )

  useEffect(() => {
    if (shouldLocate) {
      setLocating(true)

      startLocateAnimation(
        timeline,
        locateAnimDuration,
        blurRef,
        fadeRRef,
        fadeGRef,
        fadeBRef,
        currentLocateAnim,
        () => {
          setLocating(false)
        }
      )
    }
  }, [shouldLocate])

  useEffect(() => {
    if (useGlitchEffect) {
      startPixellateAnimation(
        timeline,
        animationDuration,
        pixellateGlitchFilter,
        currentPixellateGlitchAnim
      )
    }
  }, [selectedStateIndex])

  useEffect(() => {
    if (videoRef && videoRef.current) {
      const currentVideoState = {
        paused: videoRef.current.paused,
        currentTime: videoRef.current.currentTime,
        duration: videoRef.current.duration,
        muted: videoRef.current.muted,
        volume: videoRef.current.volume,
      }
      setVideoStateInternal(currentVideoState)
    }
  }, [
    selectedStateIndex,
    videoRef,
    videoRef?.current,
    videoRef?.current?.currentTime,
    videoRef?.current?.duration,
    videoRef?.current?.muted,
    videoRef?.current?.paused,
    videoRef?.current?.volume,
  ])

  useLayoutEffect(() => {
    onVideoInfoUpdate && onVideoInfoUpdate(videoStateInternal)
  }, [videoStateInternal])

  if (!historyStates || historyStates.length === 0) {
    return null
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      style={{
        ...(style && {
          ...style,
        }),
      }}
    >
      <defs>
        <filter id="blurFilter" width="100%" height="100%" x="0" y="0">
          {/* <feFlood floodColor='#00000080' result='neutral' /> */}
          <feGaussianBlur
            ref={blurRef}
            id="blur"
            in="SourceGraphic"
            stdDeviation="0"
            result="blurred"
          />
          <feComponentTransfer id={'darkened'}>
            <feFuncR ref={fadeRRef} id="darkenR" type="linear" slope="1" />
            <feFuncG ref={fadeGRef} id="darkenG" type="linear" slope="1" />
            <feFuncB ref={fadeBRef} id="darkenB" type="linear" slope="1" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode in="neutral" />
            <feMergeNode in="darkened" />
          </feMerge>
        </filter>

        <filter colorInterpolationFilters="sRGB" id="gapfix">
          <feComponentTransfer id="feComponentTransfer870">
            <feFuncR id="feFuncR872" type="identity" />
            <feFuncG id="feFuncG874" type="identity" />
            <feFuncB id="feFuncB876" type="identity" />
            <feFuncA
              id="feFuncA878"
              type="discrete"
              amplitude="2.3331705729166665"
              tableValues="0 1"
            />
          </feComponentTransfer>
        </filter>
        <filter id="pixelate" x="0" y="0" width="200%" height="200%">
          <feTurbulence
            id="animation"
            type="fractalNoise"
            baseFrequency="0.00001 9.9999999"
            numOctaves="1"
            result="warp"
          >
            {useGlitchEffect && (
              <animate
                attributeName="baseFrequency"
                from="0.00001 9.9999"
                to="0.00001 0.001"
                dur="2s"
                repeatCount="indefinite"
                restart="always"
              />
            )}
          </feTurbulence>
          <feDisplacementMap
            id={'glitchelate'}
            ref={pixellateGlitchFilter}
            xChannelSelector="R"
            yChannelSelector="G"
            scale="0"
            in="SourceGraphic"
            in2="warpOffset"
          ></feDisplacementMap>
        </filter>
      </defs>
      <svg viewBox={`0 0 ${CANVAS_WIDTH} ${CANVAS_HEIGHT}`}>
        <g
          style={{
            ...(wallId !== MURALL_WALL_ID.EVOLV3 && {
              shapeRendering: 'crispEdges',
            }),
            ...(isLocating && { filter: 'url(#blurFilter)' }),
          }}
        >
          {closestLoadedCollatedState !== 0 &&
          historyStates[closestLoadedCollatedState] ? (
            <SvgCollatedHistoryState
              wallId={wallId}
              tokenId={historyStates[closestLoadedCollatedState].tokenId}
              x={0}
              y={0}
              width={CANVAS_WIDTH}
              height={CANVAS_HEIGHT}
            />
          ) : (
            baseState && (
              <SvgHistoryState
                opacity={1}
                image={baseState.fullBase64PngString}
                x={baseState.positionInformation.start.x}
                y={baseState.positionInformation.start.y}
                height={baseState.positionInformation.height}
                width={baseState.positionInformation.width}
                useGlitchEffect={false}
              />
            )
          )}
          {collatedBaseIndex && (
            <SvgCollatedHistoryState
              wallId={wallId}
              tokenId={historyStates[collatedBaseIndex].tokenId}
              onLoadCallback={() => {
                console.log('loaded state', collatedBaseIndex)
                // only put into loaded states if it's not already there
                if (!loadedStates.includes(collatedBaseIndex)) {
                  setLoadedStates([...loadedStates, collatedBaseIndex])
                }
              }}
              x={0}
              y={0}
              width={CANVAS_WIDTH}
              height={CANVAS_HEIGHT}
            />
          )}

          {historyStates.map((state, index) => {
            const trueIndex = index
            const opacity = opacityForState(
              trueIndex,
              selectedStateIndex,
              closestLoadedCollatedState
            )

            return EVOLV3_FILETYPES.VIDEO === state.fileType ? (
              <SvgHistoryVideoState
                opacity={opacity}
                key={index}
                animationDuration={`${opacity === 0 ? 0 : animationDuration}ms`}
                shouldHide={trueIndex > selectedStateIndex}
                shouldPlay={trueIndex === selectedStateIndex && isPlaying}
                video={state.animation_url}
                muted={videoStateInternal.muted}
                volume={videoStateInternal.volume}
                onVolumeChange={onVolumeChange}
                thumbnail={state.image}
                x={state.positionInformation.start.x}
                y={state.positionInformation.start.y}
                height={state.positionInformation.croppedHeight}
                width={state.positionInformation.croppedWidth}
                filter={
                  trueIndex === selectedStateIndex && useGlitchEffect
                    ? 'url(#pixelate)'
                    : wallId !== MURALL_WALL_ID.EVOLV3 && 'url(#gapfix)'
                }
                useGlitchEffect={
                  trueIndex >= selectedStateIndex && useGlitchEffect
                }
                {...(trueIndex < selectedStateIndex && {
                  seekTo: 'end',
                })}
                {...(trueIndex === selectedStateIndex && {
                  seekTo: 0,
                  ref: videoRef,
                  onPlaybackStarted: () => {
                    onVideoStarted && onVideoStarted()
                    setVideoStateInternal({
                      ...videoStateInternal,
                      isPlaying: true,
                    })
                  },
                  onPlaybackEnded: () => {
                    onVideoEnded && onVideoEnded()
                    setVideoStateInternal({
                      ...videoStateInternal,
                      isPlaying: false,
                    })
                  },
                  onTimeUpdate: (time) => {
                    setVideoStateInternal({
                      ...videoStateInternal,
                      currentTime: time,
                    })
                  },
                })}
              />
            ) : (
              <SvgHistoryState
                opacity={opacity}
                key={index}
                animationDuration={`${opacity === 0 ? 0 : animationDuration}ms`}
                shouldHide={trueIndex > selectedStateIndex}
                image={state.image}
                x={state.positionInformation.start.x}
                y={state.positionInformation.start.y}
                height={state.positionInformation.croppedHeight}
                width={state.positionInformation.croppedWidth}
                filter={
                  trueIndex === selectedStateIndex && useGlitchEffect
                    ? 'url(#pixelate)'
                    : wallId !== MURALL_WALL_ID.EVOLV3 && 'url(#gapfix)'
                }
                useGlitchEffect={
                  trueIndex >= selectedStateIndex && useGlitchEffect
                }
              />
            )
          })}
        </g>

        {isLocating &&
          (EVOLV3_FILETYPES.VIDEO ===
          historyStates[selectedStateIndex].fileType ? (
            <SvgHistoryVideoLocateState
              videoRef={videoRef}
              animationDuration={`${locateAnimDuration}ms`}
              x={historyStates[selectedStateIndex].positionInformation.start.x}
              y={historyStates[selectedStateIndex].positionInformation.start.y}
              height={
                historyStates[selectedStateIndex].positionInformation
                  .croppedHeight
              }
              width={
                historyStates[selectedStateIndex].positionInformation
                  .croppedWidth
              }
              useGlitchEffect
            />
          ) : (
            <SvgHistoryState
              animationDuration={`${locateAnimDuration}ms`}
              shouldLocate={true}
              image={historyStates[selectedStateIndex].image}
              x={historyStates[selectedStateIndex].positionInformation.start.x}
              y={historyStates[selectedStateIndex].positionInformation.start.y}
              height={
                historyStates[selectedStateIndex].positionInformation
                  .croppedHeight
              }
              width={
                historyStates[selectedStateIndex].positionInformation
                  .croppedWidth
              }
              useGlitchEffect
            />
          ))}
      </svg>
    </svg>
  )
}
export default forwardRef(SvgHistoryViewer)
