import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'

const CustomAccordion = ({
  items = [],
  titleVariant = 'h6',
  titleComponent = 'h6',
  titleStyle = {},
  accordionStyle = {},
  accordionSummaryStyle = {},
  icon = <ArrowForwardIosSharpIcon fontSize='inherit' />,
  rotateIcon = true,
  expandedIndex = false
}) => {
  const [expanded, setExpanded] = useState(
    !expandedIndex ? false : `item-${expandedIndex}`
  )

  useEffect(() => {
    if (expandedIndex) {
      setExpanded(`item-${expandedIndex}`)
    } else {
      setExpanded(false)
    }
  }, [expandedIndex])


  const handleChange = panel => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false)

  const createAccordionSection = ({ id, title, content }) => {
    return (
      <Accordion
        disableGutters
        elevation={0}
        key={id}
        expanded={expanded === id}
        onChange={handleChange(id)}
        style={{
          width: '100%',
          ...accordionStyle
        }}
      >
        <AccordionSummary
          style={{
            ...accordionSummaryStyle
          }}
          rotateIcon={rotateIcon}
          expandIcon={icon}
        >
          <Title
            variant={titleVariant}
            component={titleComponent}
            style={{
              ...titleStyle
            }}
          >
            {title}
          </Title>
        </AccordionSummary>
        <MuiAccordionDetails>
          <Content>{content}</Content>
        </MuiAccordionDetails>
      </Accordion>
    )
  }

  return items.map((item, idx) =>
    createAccordionSection({
      title: item.title,
      content: item.content,
      id: `item-${idx + 1}`
    })
  )
}

const Accordion = styled(MuiAccordion)`
  flex: 1;

  transition: all 300ms ease-in-out;
  background-color: ${({ theme, expanded }) =>
    expanded ? theme.colors.bg.lighter : 'transparent'};
  box-shadow: none;
  border: 1px solid
    ${({ theme, expanded }) =>
      expanded ? theme.palette.primary.main : 'transparent'};
  &:before {
    display: none;
  }
`

const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: ${({ rotateIcon }) =>
      rotateIcon ? 'rotate(90deg)' : 'rotate(0deg)'};
  }
`

const Title = styled(Typography)`
  font-family: Roboto;
  width: 100%;
  font-weight: 300;
  padding-left: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

const Content = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-family: Roboto;
  font-weight: 300;
  align: justify;
  padding: 0 ${({ theme }) => theme.spacing(4)};
`

export default CustomAccordion
