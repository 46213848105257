import React, { useRef, useCallback, useState, useEffect } from 'react'
import _ from 'underscore'
import TransparentDialog from './transparent_dialog'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import useSmallScreenDetection from './useSmallScreenDetection'
import './../../css/layout.css'
import { SupportedChain } from '../../lib/constants'
import ZoomableViewWrapper from '../../components/common/ZoomableViewWrapper'

const useStyles = makeStyles(theme => ({
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  dialogMedia: {
    backgroundColor: theme.palette.primary.dark
  },
  nftInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    flex: 1,
    alignContent: 'flex-end',
    justifyContent: 'flex-end'
  },
  noPadding: {
    padding: 0,
    margin: 0
  },
  dialogMediaInPlace: {
    objectFit: 'contain',
    width: '100%' /* Full width */
  }
}))

export default function MontageInformationDialog (props) {
  const classes = useStyles()
  const smallScreen = useSmallScreenDetection()
  const dialogContentRef = useRef()
  const [imageHeight, setImageHeight] = useState(0)
  const chainId = props.chainId
    ? props.chainId
    : props.montageInformation.chainId
  useEffect(() => {
    function handleResize () {
      updateExpandedHeight()
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  const updateExpandedHeight = () => {
    if (dialogContentRef.current) {
      setImageHeight(dialogContentRef.current.getBoundingClientRect().height)
    }
  }

  const measuredRef = useCallback(node => {
    if (node !== null) {
      dialogContentRef.current = node
      setImageHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const fullImage = props.fullImage
    ? props.fullImage
    : props.montageInformation && props.montageInformation.fullBase64PngString
  const artist = props.artist
    ? props.artist
    : props.montageInformation && props.montageInformation.artist
  const name = props.name
    ? props.name
    : props.montageInformation && props.montageInformation.name
  const tokenId = props.tokenId
    ? props.tokenId
    : props.montageInformation && props.montageInformation.tokenId
  const description = props.description
    ? props.description
    : props.montageInformation && props.montageInformation.description

  return (
    <TransparentDialog
      {...props}
      withCloseButton
      iconUrl={
        chainId === SupportedChain.Polygon
          ? '/images/montage_l2.png'
          : '/images/montage_l1.png'
      }
      dialogTitle={name}
      dialogContentRef={measuredRef}
      dialogContent={() => (
        <ZoomableViewWrapper style={{ aspectRatio: '1/1' }}>
          <img
            className={clsx('pixelated-image', classes.dialogMediaInPlace)}
            style={{
              height: imageHeight != 0 ? imageHeight + 'px' : 100 + 'vh'
            }}
            src={fullImage}
            alt={`Montage ${tokenId} image`}
          />
        </ZoomableViewWrapper>
      )}
      dialogFooterColumn={smallScreen}
      dialogFooter={() => (
        <React.Fragment>
          {!props.hideInformation && (
            <div
              className={clsx(classes.nftInfo)}
              style={{
                marginBottom: '16px'
              }}
            >
              <Typography
                className={clsx(classes.typography)}
                variant='h6'
                component='h6'
                color='textPrimary'
              >
                {description}
              </Typography>
              <Typography
                className={clsx(classes.typography)}
                variant='subtitle1'
                component='subtitle1'
                color='textSecondary'
              >
                {artist}
              </Typography>
            </div>
          )}
        </React.Fragment>
      )}
    />
  )
}
