import React from 'react'
import Typography from '@mui/material/Typography'
import StyledInfoBox from '../StyledInfoBox'
import EnsAddress from '../wallet/ensAddress'

const StyledNftInfoPlaque = ({ title, description, creatorAddress, children }) => {
  return (
    <StyledInfoBox
      maxWidth={'md'}
      sx={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start'
      }}
      subtitleWrapperSx={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start'
      }}
      title={
        <>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 300,
              fontStyle: title ? 'normal' : 'italic'
            }}
            color={title ? 'textPrimary' : 'textSecondary'}
            align={'left'}
            variant={'h4'}
            gutterBottom
          >
            {title ? title : 'Untitled'}
          </Typography>
          <EnsAddress
            address={creatorAddress}
            titleProps={{
              align: 'left',
              color: 'text.secondary',
              variant: 'body',
              gutterBottom: true
            }}
            titleSx={{
              fontFamily: 'Roboto',
              fontWeight: 300
            }}
          />
        </>
      }
      titleProps={{
        align: 'left'
      }}
      subtitle={description}
      subtitleMaxLength={300}
      subtitleVariant={'body1'}
      subtitleProps={{
        align: 'left',
        noWrap: false
      }}
    >
      {children}
    </StyledInfoBox>
  )
}

export default StyledNftInfoPlaque
