import React, { useMemo, useState } from 'react'
import { useApi } from '../../../hooks/use-api'
import { MURALL_WALL } from '../../../lib/constants'

export default function SvgCollatedHistoryState({
  wallId,
  tokenId,
  x,
  y,
  width,
  height,
  filter,
  onLoadCallback,
}) {
  const wallInfo = MURALL_WALL[wallId]
  const [loaded, setLoaded] = useState(false)
  const api = useApi(wallInfo.chainId, wallId)

  return useMemo(() => {
    return (
      <g transform={`translate(${x} ${y})`}>
        <image
          height={height}
          width={width}
          href={api.images.history(tokenId)}
          onLoad={() => {
            setLoaded(true)
            onLoadCallback && onLoadCallback()
          }}
          style={{
            ...(filter && {
              filter: filter,
            }),
            opacity: loaded ? 1 : 0,
          }}
        />
      </g>
    )
  }, [x, y, width, height, filter, tokenId, api, wallId, loaded])
}
