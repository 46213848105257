import React, { useRef, useState } from 'react'
import _ from 'underscore'
import { useSelector } from 'react-redux'
import HistoryNavigationControlsDesktop from './history_navigation_controls_desktop'
import HistoryNavigationControlsMobile from './history_navigation_controls_mobile'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'
import useAppBarHeight from '../../../hooks/mui/use-app-bar-height'
import SvgHistoryViewer from '../../../components/common/svg/svg-history-viewer'
import LivePage from '../../../pages/live'
import FramesWrapperView from '../../../components/common/frames/frame-wrapper-view'
import ZoomableViewWrapper from '../../../components/common/ZoomableViewWrapper'
import LiveViewIndicatorIcon from './LiveViewIndicatorIcon'

const DEFAULT_FADE_DURATION_MILLIS = 350
const DEFAULT_LOCATE_ANIMATION_DURATION_MILLIS = 1200

export default function MainMurAllHistoryView({
  wallId,
  currentSelectedState,
  isPlaying,
  baseState,
  states,
  shouldLocate,
  fadeDurationPlaying,
  onLiveDataUpdated,
  navigationControlsExpanded,
  onSliderChange,
  onSkipPreviousClicked,
  onSkipNextClicked,
  onFastRewindClicked,
  onFastForwardClicked,
  onPlayPauseClicked,
  onFadeDurationChanged,
  durationBetweenStates,
  onDurationBetweenStatesChanged,
  onInfoButtonClicked,
  onLocateButtonClicked,
  onExpandNavigationControlsIconClicked,
  onLoadingStateChanged,
  singleTokenMode,
  liveMode,
  onOpenDetailsClicked,
  onVideoStarted,
  onVideoEnded
}) {
  const appBarHeight = useAppBarHeight()
  const frames = useSelector((state) => {
    return state.frames
  })

  const videoRef = useRef()
  const smallScreen = useSmallScreenDetection()
  const [videoState, setVideoState] = useState({
    paused: false,
    currentTime: 0,
    duration: 0,
    muted: true,
    volume: 1
  })

  const [loading, setLoading] = useState(false)

  const currentSelection = states[currentSelectedState]
  const ControlsType = smallScreen ? HistoryNavigationControlsMobile : HistoryNavigationControlsDesktop

  if (!liveMode && (!states || states.length === 0)) {
    return null
  }

  return (
    <>
      <ZoomableViewWrapper>
        <FramesWrapperView
          {...(frames &&
            frames &&
            frames.assignedFrameId !== null && {
              tokenId: frames.assignedFrameId
            })}
          noWrapper
          style={{
            position: 'relative',
            flex: 1,
            width: '100%',
            height: '100%',
            paddingTop: appBarHeight,
            paddingBottom: appBarHeight
          }}
        >
          {!liveMode && (
            <SvgHistoryViewer
              ref={videoRef}
              wallId={wallId}
              isPlaying={isPlaying}
              baseState={baseState}
              historyStates={states}
              selectedStateIndex={currentSelectedState}
              shouldLocate={shouldLocate}
              animationDuration={isPlaying ? fadeDurationPlaying : DEFAULT_FADE_DURATION_MILLIS}
              locateAnimationDuration={DEFAULT_LOCATE_ANIMATION_DURATION_MILLIS}
              style={{
                width: '100%',
                height: '100%'
              }}
              videoState={videoState}
              useGlitchEffect={isPlaying}
              onVideoInfoUpdate={setVideoState}
              onVolumeChange={(volume) => {
                setVideoState((prevState) => ({
                  ...prevState,
                  volume
                }))
              }}
              onVideoStarted={onVideoStarted}
              onVideoEnded={onVideoEnded}
            />
          )}

          {liveMode && (
            <LivePage
              ref={videoRef}
              videoState={videoState}
              wallId={wallId}
              onVideoInfoUpdate={setVideoState}
              onVolumeChange={(volume) => {
                setVideoState((prevState) => ({
                  ...prevState,
                  volume
                }))
              }}
              showMurAllLogoSpinner
              shouldLocate={shouldLocate}
              onLiveDataUpdated={onLiveDataUpdated}
              onLoadingStateChanged={(loading) => {
                setLoading(loading)
                onLoadingStateChanged && onLoadingStateChanged(loading)
              }}
              style={{
                width: '100%',
                height: '100%'
              }}
            />
          )}
        </FramesWrapperView>
      </ZoomableViewWrapper>

      <ControlsType
        wallId={wallId}
        videoCurrentTime={videoState.currentTime}
        videoDuration={currentSelection?.animation_url ? videoState.duration : 0}
        videoPaused={videoState.paused}
        videoMuted={videoState.muted}
        onVideoPlayPauseClicked={() => {
          if (videoRef.current) {
            if (videoRef.current.paused) {
              videoRef.current.play()
            } else {
              videoRef.current.pause()
            }
          }
        }}
        onVideoSeek={(e, time) => {
          e.preventDefault()
          if (videoRef.current) {
            videoRef.current.setCurrentTime(time)
          }
        }}
        onVideoMuteClicked={() => {
          if (videoRef.current) {
            videoRef.current.setMuted(!videoRef.current.muted)
          }
        }}
        videoVolume={videoState.volume}
        onVideoVolumeChange={(volume) => {
          if (videoRef.current) {
            videoRef.current.setVolume(volume)
            videoRef.current.setMuted(volume === 0)
          }
        }}
        open={navigationControlsExpanded}
        currentSelectedState={currentSelectedState}
        onSliderChange={onSliderChange}
        onSkipPreviousClicked={onSkipPreviousClicked}
        onSkipNextClicked={onSkipNextClicked}
        onFastRewindClicked={onFastRewindClicked}
        onFastForwardClicked={onFastForwardClicked}
        onPlayPauseClicked={onPlayPauseClicked}
        isPlaying={isPlaying}
        rangeStart={0}
        rangeEnd={states?.length - 1 || 0}
        startTokenId={states?.[0]?.tokenId ?? 0}
        onFadeDurationChanged={onFadeDurationChanged}
        fadeDurationPlaying={fadeDurationPlaying}
        durationBetweenStates={durationBetweenStates}
        onDurationBetweenStatesChanged={onDurationBetweenStatesChanged}
        image={
          currentSelection
            ? currentSelection.montageImage
              ? currentSelection.montageImage
              : currentSelection.image
            : ''
        }
        currentSelection={currentSelection}
        onInfoButtonClicked={onInfoButtonClicked}
        onLocateButtonClicked={onLocateButtonClicked}
        onExpandNavigationControlsIconClicked={onExpandNavigationControlsIconClicked}
        singleTokenMode={singleTokenMode}
        liveMode={liveMode}
        onOpenDetailsClicked={onOpenDetailsClicked}
      />
      {liveMode && !loading && (
        <LiveViewIndicatorIcon
          style={{
            position: 'absolute',
            width: '2.5em',
            height: '2.5em',
            top: '1em',
            right: '1em'
          }}
        />
      )}
    </>
  )
}
