import { useCallback, useEffect, useState } from 'react'
import { useMurAllDataSource } from '../use-murall-datasource.js'
import { useActiveWeb3React } from '../web3.js'
import { useSelector } from 'react-redux'

export const useDataSizeCheckForSetPixels = ({
  colourIndexData,
  blockchainPixelData,
  blockchainPixelGroupData,
  blockchainPixelGroupIndexData,
  blockchainTransparentPixelGroupData,
  blockchainTransparentPixelGroupIndexData,
  name,
  number,
  seriesId,
  alphaEnabled,
  immediate = false
}) => {
  const { account } = useActiveWeb3React()
  const currentWall = useSelector(state => state.wall)

  const { checkSetPixels } = useMurAllDataSource(currentWall.id)

  const [loading, setLoading] = useState(false)

  const [txSize, setTxSize] = useState(0)
  const [maxSize, setMaxSize] = useState(0)
  const [withinLimit, setWithinLimit] = useState(true)

  const apply = useCallback(async () => {
    setLoading(true)

    const { txSize, maxSize, withinLimit } = await checkSetPixels(
      colourIndexData,
      blockchainPixelData,
      blockchainPixelGroupData,
      blockchainPixelGroupIndexData,
      blockchainTransparentPixelGroupData,
      blockchainTransparentPixelGroupIndexData,
      name,
      number,
      seriesId,
      alphaEnabled,
      account
    )

    setTxSize(txSize)
    setMaxSize(maxSize)
    setWithinLimit(withinLimit)
    setLoading(false)
  }, [
    account,
    colourIndexData,
    blockchainPixelData,
    blockchainPixelGroupData,
    blockchainPixelGroupIndexData,
    blockchainTransparentPixelGroupData,
    blockchainTransparentPixelGroupIndexData,
    name,
    number,
    seriesId,
    alphaEnabled
  ])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])

  return {
    apply,
    loading,
    txSize,
    maxSize,
    withinLimit
  }
}
