import { useCallback, useEffect, useState } from 'react'
import { useActiveWeb3React } from '../web3.js'
import { useEmotableDataSource } from '../use-emotable.js'
import notification from '../../lib/notification.js'

const useGaslessEmoteForNft = ({
  account,
  tokenId,
  tokenContractAddress,
  chainId,
  emoji,
  state = true,
  immediate = false
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const emotableDataSource = useEmotableDataSource(chainId)
  const prepareMessageToPresignEmote = emotableDataSource?.prepareMessageToPresignEmote
  const presignedEmoteMetaTx = emotableDataSource?.presignedEmoteMetaTx
  const { account: connectedAccount, chainId: connectedChainId } = useActiveWeb3React()
  const accountToUse = account || connectedAccount
  const apply = useCallback(async () => {
    if (!accountToUse || !presignedEmoteMetaTx || !prepareMessageToPresignEmote) {
      setError('No account or emote function')
      return
    }
    if (connectedChainId !== chainId) {
      notification.wrongNetwork(chainId)
      return
    }
    try {
      setLoading(true)
      setSuccess(false)
      const funcPresignPromise = prepareMessageToPresignEmote(tokenContractAddress, tokenId, emoji, state)

      const { v, r, s } = await notification.promise(funcPresignPromise, {
        loading: `Sign to confirm ${emoji} emote on token ${tokenId}...`,
        success: `Signature confirmed!`,
        error: (error) => {
          if (error && error.message && error.message.indexOf('user rejected') != -1)
            return 'You rejected the signature!'
          return `Sorry, the signature action failed: ${error ? error.name : error}`
        }
      })

      const funcPromise = presignedEmoteMetaTx(tokenContractAddress, tokenId, emoji, v, r, s, state)
      await notification.promise(funcPromise, {
        loading: `Emoting on token ${tokenId} with ${emoji}...`,
        success: `Emoted on token ${tokenId} with ${emoji}!`,
        error: (error) => {
          if (error && error.message && error.message.indexOf('User denied') != -1)
            return 'You rejected the transaction!'
          return `Sorry, the transaction failed: ${error ? error.name : error}`
        }
      })
      setSuccess(true)
    } catch (error) {
      console.error(error)

      setError(error)
    } finally {
      setLoading(false)
    }
  }, [accountToUse, presignedEmoteMetaTx, prepareMessageToPresignEmote, tokenId, tokenContractAddress, emoji, chainId, state])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, success, loading, error }
}

export default useGaslessEmoteForNft
