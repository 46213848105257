import React from 'react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Popover from '@mui/material/Popover'

export default function EmojiPickerPopover({
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right'
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
  },
  onEmojiSelect,
  open,
  onClose,
  anchorEl
}) {
  const id = anchorEl ? 'emoji-popover' : undefined
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{ sx: { backgroundColor: 'transparent', borderRadius: '10px' } }}
      sx={{
        '& em-emoji-picker': {
          maxWidth: '250px',
          '--font-family': 'Roboto',
          '--border-radius': '10px'
        }
      }}
    >
      <Picker
        data={data}
        perLine={5}
        onEmojiSelect={onEmojiSelect}
        emojiButtonSize={40}
        emojiSize={25}
        emojiButtonColors={['rgba(102, 51, 153, .2)']}
        icons={'solid'}
      />
    </Popover>
  )
}
