import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles (name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

export default function FilterSelect ({
  sx,
  filterOptions,
  filterName,
  onChange
}) {
  const theme = useTheme()
  const [display, setDisplay] = React.useState([])

  const handleChange = event => {
    const {
      target: { value }
    } = event
    setDisplay(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
    onChange && onChange(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <FormControl sx={{ m: 1, width: 300, ...sx }}>
      <InputLabel id='demo-multiple-chip-label'>{filterName}</InputLabel>
      <Select
        labelId='demo-multiple-chip-label'
        id='demo-multiple-chip'
        multiple
        value={display}
        onChange={handleChange}
        input={<OutlinedInput id='select-multiple-chip' label='chip' />}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {filterOptions.map(name => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, display, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
