import * as React from 'react'
import { Fab } from '@mui/material'
import EmojiIcon from './EmojiIcon'
import StyledTooltip from '../../../js/uicomponents/styled_tooltip'
import CircularProgress from '@mui/material/CircularProgress'
function EmojiFab(
  {
    sx,
    tooltipTitle = 'Emote to this artwork onchain (gasless!)',
    emoji,
    size = 'small',
    disabled = false,
    onClick,
    loading = false,
    children
  },
  ref
) {
  return (
    <StyledTooltip title={tooltipTitle} enterDelay={500}>
      <Fab
        ref={ref}
        size={size}
        sx={{
          ...(!emoji && { textShadow: 'none' }),
          ...sx
        }}
        disabled={disabled}
        aria-label={'reaction'}
        onClick={onClick}
      >
        {emoji ? (
          <EmojiIcon emoji={emoji} />
        ) : (
          <EmojiIcon
            emoji={'🤍'}
            // emoji={'❤️‍🔥'}
            style={{
              filter:
                'brightness(0) saturate(100%) invert(20%) sepia(86%) saturate(2927%) hue-rotate(284deg) brightness(111%) contrast(113%)'
            }}
          />
        )}
        {loading && (
          <CircularProgress
            size={48}
            color="primary"
            variant="indeterminate"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1
            }}
          />
        )}
        {children}
      </Fab>
    </StyledTooltip>
  )
}

export default React.forwardRef(EmojiFab)
