import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SUPPORTED_THX_EVENTS } from '../lib/constants'

const BASE_URL = 'https://evolve-api.murall.art'

export const Evolv3Api = createApi({
  reducerPath: 'evolv3Api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`
  }),
  endpoints: builder => ({
    getThxUser: builder.query({
      query: ({ address }) => {
        let url = `thx/users/${address}`

        return {
          url,
          method: 'GET'
        }
      },
      transformResponse: response => {
        return response
      }
    }),
    postThxEvent: builder.mutation({
      query: ({ userAddress, event }) => {
        let url = `thx/events`

        if (!Object.values(SUPPORTED_THX_EVENTS).includes(event)) {
          throw new Error(`postThxEvent: Event ${event} is not supported`)
        } else {
          console.log(`postThxEvent: Event ${event} is supported`)
        }

        return {
          url,
          method: 'POST',
          body: {
            address: userAddress,
            event: event
          }
        }
      },
      transformResponse: response => {
        console.log('postThxEvent response', response)
        return response
      }
    }),
    getFileUploadEndpointDetails: builder.query({
      query: () => {
        let url = `upload-url`

        return {
          url,
          method: 'GET'
        }
      },
      transformResponse: response => {
        console.log('getFileUploadEnpointDetails response', response)
        return response
      }
    }),
    getFileInfo: builder.query({
      query: ({ filePath }) => {
        let url = `files/${filePath}/mint-info`

        return {
          url,
          method: 'GET'
        }
      },
      transformResponse: response => {
        console.log('getFileInfo response', response)
        return response
      }
    })
  })
})

export const {
  usePostThxEventMutation,
  useGetFileUploadEndpointDetailsQuery,
  useGetFileInfoQuery,
  useGetThxUserQuery
} = Evolv3Api
