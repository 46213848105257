import React, { useState } from 'react'
import { FormHelperText, Box, Collapse, Grid, Divider } from '@mui/material'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import CircularProgress from '@mui/material/CircularProgress'
import SettingsIcon from '@mui/icons-material/Settings'
import IconButton from '@mui/material/IconButton'
import CustomInput from '../CustomInput'
import useSmallScreenDetection from '../../../js/uicomponents/useSmallScreenDetection'
import { styled } from '@mui/material/styles'
import LabelledSlider from '../LabelledSlider'
import { getRandomNumber } from '../../../lib/utils'
import CasinoIcon from '@mui/icons-material/Casino'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { SCHEDULER_TYPES } from '../../../lib/constants'

const SettingsMore = styled(props => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

export default function ImageGenerationInput({
  sx,
  inputChildren,
  loading,
  prompt,
  guidanceScale,
  scheduler,
  seed,
  negativePrompt,
  onPromptChanged,
  onNegativePromptChanged,
  onSchedulerChanged,
  onGuidanceScaleChanged,
  onSeedChanged,
  onGenerateClicked,
  error
}) {
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const smallScreen = useSmallScreenDetection()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        ...sx
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          margin: '0px',
          padding: '0px',
          width: '100%'
        }}
      >
        <Grid item xs={12}>
          <CustomInput
            value={prompt}
            multiline
            placeholder={'Image prompt - what do you want to see?'}
            onIconClicked={prompt => {
              onGenerateClicked &&
                onGenerateClicked({
                  prompt: prompt,
                  seed: seed,
                  negativePrompt: negativePrompt,
                  scheduler: scheduler,
                  guidanceScale: guidanceScale
                })
            }}
            onKeyDown={ev => {
              if (ev.key === 'Enter') {
                ev.preventDefault()
                onGenerateClicked &&
                  onGenerateClicked({
                    prompt: prompt,
                    seed: seed,
                    negativePrompt: negativePrompt,
                    scheduler: scheduler,
                    guidanceScale: guidanceScale
                  })
              }
            }}
            onChange={newValue => {
              onPromptChanged && onPromptChanged(newValue)
            }}
            buttonDisabled={loading || !prompt || prompt.length === 0}
            buttonIcon={
              loading ? (
                <CircularProgress
                  size={smallScreen ? 20 : 20}
                  variant="indeterminate"
                />
              ) : (
                <ImageSearchIcon />
              )
            }
            sx={{
              width: '100%',
              maxWidth: '100%'
            }}
          >
            {inputChildren && (
              <>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                {inputChildren}
              </>
            )}
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <SettingsMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <SettingsIcon />
            </SettingsMore>
          </CustomInput>
        </Grid>
        <Grid item xs={12}>
          <Collapse
            in={error}
            timeout="auto"
            unmountOnExit
            sx={{
              width: '100%',
              padding: '8px 0px'
            }}
          >
            <FormHelperText id="outlined-weight-helper-text">
              {error}
            </FormHelperText>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            sx={{
              width: '100%',
              padding: '8px 0px',
              margin: '0px'
            }}
          >
            <Grid
              container
              rowSpacing={2}
              sx={{
                margin: '0px',
                padding: '0px',
                width: '100%'
              }}
            >
              <Grid item xs={12}>
                <FormHelperText gutterBottom>Negative Prompt</FormHelperText>
                <CustomInput
                  value={negativePrompt}
                  placeholder={'Negative prompt (optional)'}
                  onChange={newValue => {
                    onNegativePromptChanged && onNegativePromptChanged(newValue)
                  }}
                  multiline
                  sx={{
                    width: '100%',
                    maxWidth: '100%'
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{
                  width: '100%'
                }}
              >
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Scheduler
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={scheduler}
                      label="Scheduler"
                      onChange={event => {
                        onSchedulerChanged &&
                          onSchedulerChanged(event.target.value)
                      }}
                    >
                      {Object.keys(SCHEDULER_TYPES).map(key => {
                        return (
                          <MenuItem value={SCHEDULER_TYPES[key]}>
                            {key}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LabelledSlider
                    value={guidanceScale}
                    onChange={value =>
                      onGuidanceScaleChanged && onGuidanceScaleChanged(value)
                    }
                    aria-label="Guidance Scale"
                    label={'Guidance Scale'}
                    valueLabelDisplay="auto"
                    step={0.05}
                    min={1.0}
                    max={20.0}
                    sx={{
                      width: '100%'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormHelperText>Seed</FormHelperText>
                  <CustomInput
                    value={seed && seed.toString()}
                    placeholder={'Seed (optional)'}
                    onChange={newValue => {
                      onSeedChanged && onSeedChanged(newValue)
                    }}
                    multiline
                    sx={{
                      width: '100%',
                      maxWidth: '100%'
                    }}
                    buttonIcon={<CasinoIcon />}
                    onIconClicked={() =>
                      onSeedChanged &&
                      onSeedChanged(getRandomNumber(1, 999999999999999999))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  )
}
