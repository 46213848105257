import MurAllContract from './../../../../contracts/layer1/MurAll.json'
import MurAllNFTContract from './../../../../contracts/layer1/MurAllNFT.json'
import MurAllNFTL2Contract from './../../../../contracts/layer1/MurAllNFTL2.json'
import MontageNFTContract from './../../../../contracts/layer1/MontageNFT.json'
import MontageNFTL2Contract from './../../../../contracts/layer1/MontageNFTL2.json'
import MurAllBlockList from './../../../../contracts/layer1/MurAllBlockList.json'
import PaintTokenContract from './../../../../contracts/layer1/PaintToken.json'
import MurAllMarketplace from './../../../../contracts/layer1/MurAllMarketplace.json'
import NftMetadata from './../../../../contracts/layer1/NftMetadata.json'
import MerkleDistributor from './../../../../contracts/layer1/MerkleDistributor.json'
import MurAllFrame from './../../../../contracts/layer1/MurAllFrame.json'

import MurAllContractL2 from './../../../../contracts/layer2/MurAll.json'
import MurAllNFTContractL2 from './../../../../contracts/layer2/MurAllNFT.json'
import PaintTokenContractL2 from './../../../../contracts/layer2/PaintToken.json'
import NftMetadataL2 from './../../../../contracts/layer2/NftMetadata.json'
import NftDataStorageL2 from './../../../../contracts/layer2/NftDataStorage.json'
import IMontageL2 from './../../../../contracts/layer2/MontageNFT.json'
import AuctionMarketplace from './../../../../contracts/layer2/AuctionMarketplace.json'

const L1_NETWORK_ID_TESTNET = 5
const L1_NETWORK_ID_MAINNET = 1
const L1_NETWORK_ID_DEFAULT = L1_NETWORK_ID_MAINNET
const L1_L2_BRIDGE_ADDRESS_TESTNET =
  '0xdD6596F2029e6233DEFfaCa316e6A95217d4Dc34'
const L1_L2_BRIDGE_ADDRESS_MAINNET =
  '0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf'
const L1_L2_BRIDGE_ADDRESS_DEFAULT = L1_L2_BRIDGE_ADDRESS_MAINNET

const L2_NETWORK_ID_TESTNET = 80001
const L2_NETWORK_ID_MAINNET = 137
const L2_NETWORK_ID_DEFAULT = L2_NETWORK_ID_MAINNET

/**
 * Source for Layer 1 contract address data
 *
 * @author TheKeiron
 */

export const getMurAllContractAddress = (networkId = L1_NETWORK_ID_DEFAULT) => {
  switch (networkId) {
    case L1_NETWORK_ID_MAINNET:
      return MurAllContract.networks[networkId].address
    case L2_NETWORK_ID_MAINNET:
      return MurAllContractL2.networks[networkId].address
    case L1_NETWORK_ID_TESTNET:
      return MurAllContract.networks[networkId].address
    default:
      return MurAllContract.networks[networkId].address
  }
}

export const getMurAllNFTContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  const contract =
    networkId === L1_NETWORK_ID_MAINNET
      ? MurAllNFTContract
      : MurAllNFTContractL2
  return contract.networks[networkId]
    ? contract.networks[networkId].address
    : ''
}

export const getAuctionMarketplaceContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  const contract = AuctionMarketplace.networks[networkId]
  return contract ? contract.address : ''
}

export const getMurAllBlockListContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  const contract = MurAllBlockList.networks[networkId]
  return contract ? contract.address : ''
}

export const getPaintTokenContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  const contract =
    networkId === L1_NETWORK_ID_MAINNET
      ? PaintTokenContract
      : PaintTokenContractL2
  return contract.networks[networkId]
    ? contract.networks[networkId].address
    : ''
}

export const getMurAllMarketplaceContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  return MurAllMarketplace.networks[networkId].address
}

export const getNftMetadataContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  const contract =
    networkId === L1_NETWORK_ID_MAINNET ? NftMetadata : NftMetadataL2
  return contract.networks[networkId]
    ? contract.networks[networkId].address
    : ''
}

export const getPaintClaimContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  return MerkleDistributor.networks[networkId].address
}

export const getBridgedMurAllNFTL2ContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  return MurAllNFTL2Contract.networks[networkId].address
}

export const getL1L2BridgeContractAddress = () => {
  return L1_L2_BRIDGE_ADDRESS_DEFAULT
}

export const getNftDataStorageContractAddress = (
  networkId = L2_NETWORK_ID_DEFAULT
) => {
  return NftDataStorageL2.networks[networkId].address
}

export const getMontageContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  const contract =
    networkId === L1_NETWORK_ID_MAINNET ? MontageNFTContract : IMontageL2
  return contract.networks[networkId].address
}

export const getBridgedMontageContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  return MontageNFTL2Contract.networks[networkId].address
}

export const getMontageL2ContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  const contract =
    networkId === L1_NETWORK_ID_MAINNET ? MontageNFTL2Contract : IMontageL2
  return contract.networks[networkId].address
}

export const getMurAllFramesContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  return MurAllFrame.networks[networkId].address
}
