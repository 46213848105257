import React from 'react'

import CustomAccordion from '../../../components/common/Accordion'
import SectionTitle from '../SectionTitle'

const SectionMurAllNfts = () => {
  return (
    <>
      <SectionTitle variant='h4' component='h4'>
        MurAll NFTs minted on Revo1t & Di2rupt
      </SectionTitle>
      <CustomAccordion
        items={[
          {
            title: 'What is a MURALL NFT?',
            content: (
              <>
                MURALL is a “write-once” ERC721 Non-Fungible Token (NFT) that is
                produced as a part of drawing onto MurAll, which you own. It
                contains all metadata (name, series id and number), plus a hash
                of the pixel data hosted on-chain in event log storage. Artists
                can "fill" the NFT with this pixel data if they choose (meaning
                copy the data to contract storage where the NFT is hosted).
              </>
            )
          },
          {
            title:
              'Why does MURALL only initially have the hash of the pixel data?',
            content: (
              <>
                The hash is referencing the on-chain pixel data in the event log
                storage; an immutable on-chain storage which every node on the
                Ethereum network holds (and thus is as distributed, stable and
                secure as contract storage), and is retrievable by any client
                communicating with the nodes (such as your wallet on the
                browser). Most importantly is vastly cheaper to store data on
                than contract storage, with the caveat that event log storage
                cannot be read by contract storage, and cannot be modified once
                written. By putting the pixel data onto event log storage rather
                than contract storage, this allows us to maximise the amount of
                pixels you can draw to MurAll. The artist has a choice to "fill"
                the NFT with the pixel data from event log storage, which would
                copy the data to contract storage (which we check against the
                hash to ensure the data is the same) meaning the data would then
                be hosted in both the event log storage and contract storage, or
                leave it "unfilled" if they are happy with the pixel data being
                on chain in event log storage.
              </>
            )
          },
          {
            title: 'Do you have to use Paint to fill the minted MURALL?',
            content: (
              <>
                No, PAINT is only used to draw on MurAll, which produces the NFT
                of your contribution. You only have to pay the network gas fees
                for the transaction to fill the NFT data.
              </>
            )
          },
          {
            title:
              'What if it takes more than one transaction to fill the data?',
            content: (
              <>
                The NFTs are coded in a “write once” setup, meaning it will fill
                the NFT as much as possible with the gas you provide, and if it
                runs out of gas it will stop where it is and make note of where
                you got to, meaning subsequent transactions will fill the
                remaining data continuing from where it got to last.
              </>
            )
          },
          {
            title: 'Do you have to fill the minted MURALL?',

            content: (
              <>
                No, that’s up to the token owner, but the incentive is there
                considering given the finite supply of PAINT means a finite
                amount of tokens will ever be minted by MurAll, so the NFT is
                instantly rare no matter its content.
              </>
            )
          },
          {
            title: 'What can I do with my MURALL?',
            content: (
              <>
                The MurAll client will have a place where you can view the
                MURALLs you own, where you can use them to display as they are
                or combine them together as you see fit to create whole new
                pieces of art for your personal viewing.
              </>
            )
          }
        ]}
      />
    </>
  )
}

export default SectionMurAllNfts
