import React, { useState } from 'react'
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  TextField,
  Link
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { red, yellow } from '@mui/material/colors'
import clsx from 'clsx'
import StyledImageViewerDialog from '../../uicomponents/styled_image_viewer_dialog'
import NftDisplay from '../gallery/nft_display'
import _ from 'underscore'
import './../../../css/layout.css'
const useStyles = makeStyles(theme => ({
  dialogInformationRoot: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },

  dialogMedia: {
    backgroundColor: theme.palette.primary.dark
  },
  dialogMediaCropped: {
    margin: 'auto',
    overflow: 'auto',
    width: '100%',
    height: 'auto'
  },
  footer: {
    position: 'absolute',
    width: '100%',
    minHeight: '1px',
    bottom: '0',
    left: '0',
    overflow: 'hidden'
  },

  dialogContent: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogTitle: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogTitleFlex: {
    backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '8px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  typography: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto'
    // fontWeight: 100,
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  infoRowWrapper: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  warningRed: {
    color: red[700]
  },
  verticalDivider: {
    borderColor: theme.palette.divider,
    borderTop: 0,
    borderLeft: 'thin',
    borderStyle: 'solid',
    borderBottom: 0,
    borderRight: 0,
    alignSelf: 'stretch',
    height: 'auto',
    marginTop: '16px'
  },

  warningBannerRoot: {
    width: '100%',
    borderRadius: 10,
    background: yellow[800],
    padding: '16px'
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  warningIcon: {
    height: '48px',
    width: '48px',
    marginRight: '16px'
  },
  fullFlex: {
    flex: 1
  }
}))

export default function CreateMontageDialog (props) {
  const classes = useStyles()

  const [nameValue, setNameValue] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')
  const [canBeUnpacked, setCanBeUnpacked] = useState(true)
  const [tokenIds, setTokenIds] = useState([])

  const constructNftInputFields = () => {
    return (
      <React.Fragment>
        <FormGroup row>
          <Typography
            className={classes.typography}
            variant='body1'
            component='p'
            align='justify'
            style={{
              flex: 1,
              alignSelf: 'flex-start',
              marginBottom: '16px'
            }}
          >
            {'Montage Information:'}
          </Typography>
        </FormGroup>
        <FormGroup row>
          <TextField
            id='name'
            variant='outlined'
            label='Name'
            style={{ flex: 1, marginBottom: '16px' }}
            value={nameValue}
            size='small'
            onChange={e => {
              setNameValue(e.target.value)
            }}
          />
        </FormGroup>
        <FormGroup row>
          <TextField
            id='description'
            variant='outlined'
            label='Description'
            style={{ flex: 1 }}
            value={descriptionValue}
            size='small'
            onChange={e => {
              setDescriptionValue(e.target.value)
            }}
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            style={{ paddingLeft: '12px', flex: 1 }}
            control={
              <Checkbox
                checked={canBeUnpacked}
                onChange={event => {
                  setCanBeUnpacked(event.target.checked)
                }}
                name='checkedA'
              />
            }
            label='Montage can be unpacked'
          />

          <Typography
            className={classes.typography}
            variant='body2'
            component='p'
            align='justify'
            style={{
              flex: 1,
              alignSelf: 'center'
            }}
          >
            {'Token ids: ' + tokenIds.toString()}
          </Typography>
        </FormGroup>
      </React.Fragment>
    )
  }

  return (
    <StyledImageViewerDialog
      open={props.open}
      onClose={props.onClose}
      maxWidth
      fullScreen
      withCloseButton
      dialogTitle={'Create Montage'}
      dialogImageContent={
        <NftDisplay
          disableOffset
          className={clsx(
            'pixelated-image',
            classes.absoluteFill,
            classes.dialogMedia
          )}
          style={{ minWidth: 0, minHeight: 0 }}
          displayNftsTitle={'NFTS FOR MONTAGE'}
          emptyViewTitle={'Choose your NFTs to create a montage'}
          emptyViewDescription={
            <>
              Select from the "ALL NFTS" list on the left, then use the arrows
              to move them into the "NFTS FOR MONTAGE" list below to see them
              here.` + ` Once you have the NFTs selected give it a name and
              description by filling the fields below, then click "CREATE
              MONTAGE" to create the montage.
              <br />
              <br />
              For a more in depth tutorial checkout{' '}
              <Link
                href='https://knowledgebase.murall.art/tutorials/creating-montage'
                target='_blank'
                underline='hover'
              >
                {'our knowledgebase'}
              </Link>
            </>
          }
          noDrawer
          onDisplayTokenOrderUpdate={(orderIds, nfts) => {
            const tokenIds = []
            for (const [key, nft] of Object.entries(nfts)) {
              tokenIds.push(nft.tokenId)
            }
            setTokenIds(tokenIds)
          }}
        />
      }
      dialogInformation={
        <div className={clsx(classes.dialogInformationRoot)}>
          <div className={clsx(classes.infoRowWrapper)}>
            <div
              style={{
                display: 'inline-flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'stretch',
                paddingLeft: '16px',
                paddingTop: '0px'
              }}
            >
              {constructNftInputFields()}
            </div>
          </div>
        </div>
      }
      dialogActions={
        <React.Fragment>
          <Button
            size='medium'
            color='secondary'
            onClick={() => {
              props.onCreateMontageButtonClick(
                nameValue,
                descriptionValue,
                canBeUnpacked,
                tokenIds
              )
            }}
            disabled={tokenIds.length === 0}
            style={{ marginRight: '16px' }}
          >
            Create Montage
          </Button>
          <Button
            size='medium'
            color='secondary'
            onClick={props.onCancelClicked}
          >
            Cancel
          </Button>
        </React.Fragment>
      }
    />
  )
}
