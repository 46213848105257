import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        .MuiSvgIcon-root {
          filter: drop-shadow(0px 0px 4px black);
        }
      `
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'Roboto',
      textShadow: '0px 0px 7px black'
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#E4FF1A',
      dark: '#1A1A1A',
      contrastText: '#ffffff',
      gray: '#CCC'
    },
    secondary: {
      main: '#E91BFA',
      dark: '#26262B'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1324,
      xl: 2024
    }
  },
  colors: {
    bg: {
      lightest: '#33333a',
      lighter: '#27272d',
      main: '#1b1b1f',
      darker: '#0f0f11',
      darkest: '#030304'
    },
    named: {
      red: '#ff1919',
      green: '#27AE60'
    }
  }
})

export default theme
