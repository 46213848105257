import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SCHEDULER_TYPES } from '../lib/constants'

const BASE_URL = 'https://ai.murall.art'

export const ImageApi = createApi({
  reducerPath: 'imageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`
  }),
  endpoints: builder => ({
    textToImage: builder.mutation({
      query: ({
        prompt,
        negativePrompt,
        seed,
        murAllify = false,
        guidanceScale = 10.5,
        scheduler = SCHEDULER_TYPES.EulerAncestralDiscrete
      }) => {
        let url = `text2img`
        console.log(prompt, negativePrompt, seed, guidanceScale, scheduler)

        return {
          url,
          method: 'POST',
          body: {
            prompt: prompt,
            negativePrompt: negativePrompt,
            scheduler: scheduler,
            guidanceScale: guidanceScale,
            seed: seed,
            MurAllify: murAllify
          },
          headers: {
            Accept: '*/*',
            'Content-type': 'application/json'
          }
        }
      },
      transformResponse: response => {
        console.log('response', response)
        return response
      }
    }),
    fetchResults: builder.mutation({
      query: ({ resultId, asBase64 }) => {
        let url = `fetchResults`

        return {
          url,
          method: 'POST',
          body: {
            id: resultId,
            asBase64: asBase64
          },
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
          }
        }
      },
      transformResponse: response => {
        console.log('response', response)
        return response
      }
    })
  })
})

export const { useTextToImageMutation, useFetchResultsMutation } = ImageApi
