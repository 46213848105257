import React from 'react'
import NftCard from './nftCard'
import PaginatedNftGridView from './paginated_nft_gridview'
import { useMurAllNftDataSource } from '../../../hooks/use-murall-nft-datasource'
import { useActiveWeb3React } from '../../../hooks/web3'

export default function ViewMurAllNfts () {
  const { account } = useActiveWeb3React()

  const murAllNftDatasource = useMurAllNftDataSource()
  const {
    getBalanceForAddress,
    getNftDataForAccountForOwnedTokenInIndexRange
  } = murAllNftDatasource || {}

  return (
    <>
      {account && murAllNftDatasource && (
        <PaginatedNftGridView
          itemsPerPage={9}
          onFetchNftBalance={() => {
            return getBalanceForAddress(account)
          }}
          onFetchNftsInIndexRange={(startIndex, endIndex) => {
            return getNftDataForAccountForOwnedTokenInIndexRange(
              account,
              startIndex,
              endIndex
            )
          }}
          constructNftView={currentNft => {
            return (
              <NftCard key={currentNft.tokenId} nftInformation={currentNft} />
            )
          }}
        />
      )}
    </>
  )
}
