import { useState, useEffect, useCallback } from 'react'
import Web3 from 'web3'
import { processNftData } from '../../js/modules/blockchain/NftDataMapper.js'
import { getMurAllNFTContractAddress } from '../../js/modules/blockchain/datasource/ContractAddressDataSource.js'
import { useMurAllContract as useMurAllContractForChain } from '../use-contract-for-chain.js'

export const useGetOnchainPaintedData = ({
  chainId,
  tokenId,
  fromBlock = 'earliest',
  toBlock = 'latest',
  immediate = true
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [nftData, setNftData] = useState(false)

  const contract = useMurAllContractForChain(chainId, true)

  const apply = useCallback(async () => {
    try {
      setLoading(true)
      const events = await contract.getPastEvents('Painted', {
        filter: { tokenId: Web3.utils.toBN(tokenId) },
        fromBlock: fromBlock,
        toBlock: toBlock
      })
      if (events.length == 0) {
        setError('No events found')
        setLoading(false)
        return
      }
      const tokenInfo = events[0].returnValues

      const nftInfo = await processNftData(
        getMurAllNFTContractAddress(chainId),
        tokenId,
        tokenInfo.artist,
        tokenInfo.colorIndex,
        tokenInfo.pixelData,
        tokenInfo.pixelGroups,
        tokenInfo.pixelGroupIndexes,
        tokenInfo.transparentPixelGroups,
        tokenInfo.transparentPixelGroupIndexes,
        tokenInfo.metadata[0],
        tokenInfo.metadata[1],
        true,
        0,
        chainId
      )
      setNftData(nftInfo)
      setLoading(false)
      return nftInfo
    } catch (error) {
      console.error(error)
      setError(error)
      setLoading(false)
      return
    }
  }, [contract])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, nftData, loading, error }
}

export default useGetOnchainPaintedData
