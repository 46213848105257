import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ErrorMessageView from '../components/common/error-view'
import { LoadingSpinner } from '../js/uicomponents/loading_spinner'
import {
  MURALL_WALL,
  MURALL_WALL_ID,
  getWallIdFromQueryParam
} from '../lib/constants'
import MurAllNftView from '../components/common/nft/murall-nft-view'
import useGetMurAllNftForId from '../hooks/nft/use-get-murall-nft-for-id'
import MurAllEvolv3NftView from '../components/common/nft/murall-evolv3-nft-view'

const MurAllNftPage = () => {
  let { wall, id } = useParams()
  const wallId = getWallIdFromQueryParam(wall)
  const wallInfo = MURALL_WALL[wallId]
  const chainId = wallInfo.chainId
  const [initialLoading, setInitialLoading] = useState(true)

  const { apply, error, loading: isLoadingNft, nftData } = useGetMurAllNftForId(
    wallId,
    id,
    true,
    true
  )

  useEffect(() => {
    if (isLoadingNft) return
    setInitialLoading(false)

    return () => {}
  }, [isLoadingNft, nftData])

  return isLoadingNft || initialLoading ? (
    <LoadingSpinner />
  ) : error ? (
    <ErrorMessageView title='Network Error' description='Try again later' />
  ) : wallId === MURALL_WALL_ID.EVOLV3 ? (
    <MurAllEvolv3NftView
      nftData={nftData}
      chainId={chainId}
      id={id}
      wallId={wallId}
    />
  ) : (
    <MurAllNftView
      nftData={nftData}
      chainId={chainId}
      id={id}
      wallId={wallId}
    />
  )
}

export default MurAllNftPage
