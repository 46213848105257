/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min
}

export default {
  getRandomNumber
}
