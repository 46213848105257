import * as React from 'react'
import Stack from '@mui/material/Stack'
import TrapFocus from '@mui/material/Unstable_TrapFocus'
import Paper from '@mui/material/Paper'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Modal from '@mui/material/Modal'
import { useTheme } from '@mui/material/styles'

export default function StyledBanner ({
  sx,
  open,
  children,
  actions,
  withCloseButton,
  onClose
}) {
  const theme = useTheme()
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      tabIndex={-1}
      hideBackdrop
      sx={{
        tabIndex: -1,
        position: 'absolute',
        display: 'inline-block',
        bgcolor: theme.colors.bg.lighter,
        top: 0,
        left: 0,
        right: 0,
        m: 0,
        p: 1,
        borderBottom: '2px solid ' + theme.palette.primary.main,
        ...sx
      }}
    >
      <Stack
        id={'banner-outer-container'}
        direction={'row'}
        tabIndex={-1}
        gap={1}
        sx={{
          tabIndex: -1,
          border: '0px',
          maxWidth: '100%',
          flex: 1
        }}
      >
        {withCloseButton && (
          <Box
            tabIndex={-1}
            sx={{
              tabIndex: -1,
              flexShrink: 1,
              alignSelf: 'flex-start'
            }}
          >
            <IconButton onClick={onClose} aria-label='close' size='small'>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <Stack
          id={'banner-inner-container'}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent='space-between'
          gap={1}
          tabIndex={-1}
          sx={{
            tabIndex: -1,
            border: '0px',
            minWidth: '0px',
            flex: 1
          }}
        >
          <Box
            id={'banner-content'}
            sx={{
              tabIndex: -1,
              minWidth: '0px',
              display: 'block',
              flex: { sm: 1, xs: 'none' },
              width: { sm: 'auto', xs: '100%' },
              alignSelf: { xs: 'flex-start', sm: 'center' }
            }}
          >
            {children}
          </Box>
          <Stack
            id={'banner-actions'}
            gap={1}
            direction={'row'}
            sx={{
              tabIndex: -1,     
              minWidth: '0px',
              flexShrink: 0,
              alignSelf: { xs: 'flex-end', sm: 'center' }
            }}
          >
            {actions}
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  )
}
