import React, { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import '../../../css/layout.css'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { Card, Grid, CardContent, Box } from '@mui/material'
import { useTheme } from 'styled-components'
import LeftRightInfoView from '../left-right-info-view'
import { EVOLV3_FILETYPES, MURALL_WALL_ID, MURALL_WALL_QUERY_PARAM, SupportedChain } from '../../../lib/constants'
import NftInformationDialog from '../../../js/uicomponents/nft_information_dialog'
import { getBridgedMurAllNFTL2ContractAddress } from '../../../js/modules/blockchain/datasource/ContractAddressDataSource'
import { Button, Icon } from '@mui/material'
import { useBridgedMurAllNFTContract } from '../../../hooks/use-contract-for-chain'
import { useActiveWeb3React } from '../../../hooks/web3'
import Web3 from 'web3'
import { Link as RouterLink } from 'react-router-dom'
import Fade from '../../../reveal/in-and-out/Fade'
import { useMurAllEvolv3NftDataSource } from '../../../hooks/use-murall-evolv3-nft-datasource'
import useGetMurAllNftForId from '../../../hooks/nft/use-get-murall-nft-for-id'
import StyledInfoBox from '../StyledInfoBox'
import StyledNftInfoPlaque from './styled-nft-info-plaque'
import NftEmoteButton from '../emoji/NftEmoteButton'
import NftEmojiAvatarGroup from '../emoji/NftEmojiAvatarGroup'

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  mediaContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    margin: '0px',
    padding: '0px',
    overflow: 'visible',
    flex: 1
  },
  emoteButton: {
    position: 'absolute',
    top: theme.spacing(2), // Adjust top as necessary
    right: theme.spacing(2), // Adjust right as necessary
    zIndex: 2 // Ensure it's above the media
  },
  noPadding: {
    padding: 0,
    margin: 0
  },
  dialogMediaCropped: {
    width: '100%' /* Full width */,
    height: 'fit-content',
    objectFit: 'contain',
    overflow: 'hidden',
    alignSelf: 'center'
  },
  zoomOnHover: {
    transform: 'scale(1, 1)',
    transition: '0.6s',
    '&:hover': {
      transform: 'scale(1.1, 1.1)',
      cursor: 'pointer'
    }
  }
}))

const MurAllEvolv3NftView = ({ chainId, wallId, id, nftData }) => {
  const { library, account, chainId: activeChainId } = useActiveWeb3React()
  const theme = useTheme()
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [migrated, setMigrated] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [blockNumber, setBlockNumber] = useState(null)

  const { apply, error, loading, nftData: nftInfo } = useGetMurAllNftForId(MURALL_WALL_ID.EVOLV3, id, false, true)

  const { ownerOf } = useMurAllEvolv3NftDataSource()

  const bridgedMurAllEthNftContract = useBridgedMurAllNFTContract(SupportedChain.Ethereum)

  useEffect(() => {
    const performAdditionalChecks = async () => {
      const blockNumber = nftData.blockNumber
      setBlockNumber(blockNumber)

      let migrated = false
      if (chainId === SupportedChain.Polygon) {
        console.log('checking if migrated...')
        migrated = await bridgedMurAllEthNftContract.methods.exists(id).call()
      }
      setMigrated(migrated)

      let isOwner = false
      if (account) {
        let ownerAddress
        try {
          ownerAddress = await ownerOf(id)
          console.log('ownerAddress', ownerAddress)
          isOwner = Web3.utils.toChecksumAddress(ownerAddress) === Web3.utils.toChecksumAddress(account)
        } catch (e) {
          console.log('ownerAddress error - may be burned or migrated', e)
          // todo implement bridge check
        }
      }

      setIsOwner(isOwner)
    }
    console.log('nftInfo', nftInfo)
    if (!loading && nftInfo) {
      performAdditionalChecks()
    }
  }, [loading, nftInfo])

  const [nftInfoOpen, setNftInfoOpen] = useState(false)

  const handleClose = () => {
    setNftInfoOpen(false)
  }

  const goToExternalUrl = () => {
    const baseUrl =
      chainId === SupportedChain.Mumbai
        ? 'https://testnets.opensea.io/assets/mumbai'
        : chainId === SupportedChain.Polygon
        ? 'https://opensea.io/assets/matic'
        : 'https://opensea.io/assets/ethereum'
    if (nftInfo.externalUrl) {
      window.open(nftInfo.externalUrl, '_blank')
      return
    }
    if (migrated) {
      window.open(`${baseUrl}/${getBridgedMurAllNFTL2ContractAddress()}/${nftInfo.tokenId}`, '_blank')
    } else {
      window.open(`${baseUrl}/${nftInfo.contractAddress}/${nftInfo.tokenId}`, '_blank')
    }
  }

  const constructNftInfoDialog = () => {
    return (
      <NftInformationDialog
        open={nftInfoOpen}
        onClose={handleClose}
        showCompletionStatus
        chainId={chainId}
        name={nftData.name}
        tokenId={id}
        wallId={wallId}
        croppedImage={nftInfo ? nftInfo.croppedBase64PngString : nftData.image}
        fullImage={nftInfo ? nftInfo.fullBase64PngString : nftData.image}
        nftInformation={nftInfo}
        withViewCropToggle
        hideInformation
      />
    )
  }
  const constructEmoteButton = ({ sx }) => (
    <NftEmoteButton
      chainId={nftInfo.chainId}
      tokenContractAddress={nftInfo.contractAddress}
      tokenId={id}
      size="medium"
      sx={{
        position: 'absolute',
        bottom: theme.spacing(2), // Adjust top as necessary
        right: theme.spacing(2), // Adjust right as necessary
        zIndex: 999999,
        ...sx
      }}
      debounce={0}
    />
  )

  const createTextRow = (index, key, value, breakpoints) => (
    <Grid item {...breakpoints} key={index}>
      <Fade right cascade>
        <StyledInfoBox
          sx={{
            flex: 1
          }}
          title={key}
          titleVariant={isMobile ? 'subtitle2' : 'body2'}
          subtitle={value}
          subtitleVariant={isMobile ? 'h6' : 'h5'}
        />
      </Fade>
    </Grid>
  )

  const createInfoPlaque = () => (
    <Grid item xs={12}>
      <Fade right cascade>
        <StyledNftInfoPlaque
          chainId={nftInfo.chainId}
          creatorAddress={nftData.artist}
          description={nftInfo.description}
          title={nftData.name}
          tokenContractAddress={nftInfo.contractAddress}
          tokenId={id}
        >
          <NftEmojiAvatarGroup
            chainId={nftInfo.chainId}
            tokenContractAddress={nftInfo.contractAddress}
            tokenId={id}
            logoStyle={{ position: 'relative', maxWidth: '100px' }}
            sx={{ marginTop: '1em', alignSelf: 'flex-start', justifyContent: 'flex-start', alignItems: 'flex-start' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          />
        </StyledNftInfoPlaque>
      </Fade>
    </Grid>
  )

  return (
    <>
      <LeftRightInfoView
        leftContents={
          <Box className={clsx(classes.mediaContainer, classes.noPadding)}>
            {nftInfo?.fileType === EVOLV3_FILETYPES.IMAGE && (
              <img
                className={clsx(classes.dialogMediaCropped, classes.zoomOnHover, classes.noPadding)}
                src={nftInfo.image}
                alt={'Token ' + id}
                onClick={() => {
                  setNftInfoOpen(true)
                }}
              />
            )}
            {nftInfo?.fileType === EVOLV3_FILETYPES.VIDEO && (
              <video
                className={clsx(classes.dialogMediaCropped, classes.noPadding)}
                src={nftInfo.animation_url}
                alt={'Token ' + id}
                autoPlay
                loop
                muted
                controls
              />
            )}
            {constructNftInfoDialog()}
            {!isMobile && account && constructEmoteButton({ sx: { bottom: theme.spacing(2), right: '-24px' } })}
          </Box>
        }
        rightContents={
          <Card
            elevation={0}
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'center',
              backgroundColor: 'transparent'
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px',
                width: '100%'
              }}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{
                  pt: { xs: '12px', sm: '24px', md: '36px' },
                  pb: { xs: '12px', sm: '24px', md: '36px' }
                }}
              >
                {createInfoPlaque()}

                {createTextRow(0, 'Number of Pixels', nftData.pixels, {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4
                })}
                {createTextRow(1, 'Created', new Date(Number(nftData.timestamp)).toLocaleString(), {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4
                })}
                {nftInfo?.attributes &&
                  nftInfo?.attributes.map((attribute, index) => {
                    return createTextRow(index, attribute.trait_type, attribute.value, {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 4
                    })
                  })}
                {migrated &&
                  createTextRow(2, 'Bridged To', 'Ethereum', {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4
                  })}
              </Grid>
              <Grid container direction="column" alignItems="stretch" justifyContent={'center'} spacing={2}>
                <Grid item xs>
                  <Button
                    variant="contained"
                    fullWidth
                    align="center"
                    component={RouterLink}
                    to={`/state?wall=${MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.EVOLV3]}&atTokenId=${id}`}
                  >
                    View on the MurAll
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    variant="contained"
                    fullWidth
                    align="center"
                    component={RouterLink}
                    to={`/artist/${nftData.artist}`}
                  >
                    View other works by this artist
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    variant="contained"
                    fullWidth
                    align="center"
                    onClick={goToExternalUrl}
                    endIcon={
                      <Icon style={{ fontSize: 35, textAlign: 'center' }}>
                        <img src={'images/opensea_logo_white.svg'} alt={'opensea'} />
                      </Icon>
                    }
                    sx={{
                      backgroundColor: '#2081e2'
                    }}
                  >
                    View on OpenSea
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        }
      />
      {isMobile && account && constructEmoteButton({ sx: { bottom: theme.spacing(2), right: theme.spacing(2) } })}
    </>
  )
}

export default MurAllEvolv3NftView
