import { useMemo } from 'react'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  EVOLV3_FILETYPES,
  MURALL_WALL,
  MURALL_WALL_ID
} from '../lib/constants'
import { mapInformationToNftObject } from '../js/modules/blockchain/NftDataMapper'
import { useMurAllEvolv3Contract } from './use-evolv3-contract'
import { useActiveWeb3React } from './web3'
import { sendMetaTx } from '../lib/metatx/utils'
import { usePaintDataSource } from './use-paint-datasource'
import { useApi } from './use-api'
import { ethers } from 'ethers'
import {
  IPFS_GATEWAY_PINATA_MURALL,
  arrayBetweenRange,
  ensureNumber,
  getJsonFromEndpoint,
  replaceIpfsIfNeeded
} from '../js/libs/appUtils'
import { batchFetchTokensInRangeCached } from './api-utils'
const async = require('async')

export const useMurAllEvolv3NftDataSource = () => {
  const { account, library } = useActiveWeb3React()
  const wallInfo = MURALL_WALL[MURALL_WALL_ID.EVOLV3]
  const api = useApi(wallInfo.chainId, MURALL_WALL_ID.EVOLV3)
  const contract = useMurAllEvolv3Contract()
  const paintTokenContract = usePaintDataSource(wallInfo.chainId)

  return useMemo(() => {
    const getOwnedTokenIdsInIndexRange = (
      ownerAddress,
      startIndex,
      endIndex
    ) => {
      const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
        startIndex,
        endIndex + 1
      )

      return Promise.all(
        indexes.map(index => contract?.tokenOfOwnerByIndex(ownerAddress, index))
      )
    }
    const getFullTokenUriMetadata = async tokenId => {
      let tokenUri = await contract?.tokenURI(ethers.BigNumber.from(tokenId))
      console.log('tokenUri for token ' + tokenId, tokenUri)
      let returnData
      try {
        returnData = await getJsonFromEndpoint(tokenUri)
      } catch (error) {
        throw new Error(
          'Error fetching token metadata for token ' + tokenId,
          error
        )
      }

      return returnData
    }
    const getTokenForId = (tokenId, asNftInfo = false) => {
      return new Promise(async function (resolve, reject) {
        try {
          if (asNftInfo) {
            const {
              artist,
              name,
              description,
              width,
              height,
              x,
              y,
              fileUri,
              fileType,
              duration,
              paintUsed
            } = await contract?.getDisplayData(tokenId)

            const fullTokenMetadata = await getFullTokenUriMetadata(tokenId)

            const nftInfo = await mapInformationToNftObject({
              artist,
              chainId: wallInfo.chainId,
              contractAddress: wallInfo.nftContractAddress,
              animation_url: replaceIpfsIfNeeded(
                fullTokenMetadata.animation_url,
                IPFS_GATEWAY_PINATA_MURALL
              ),
              description: decodeURIComponent(description),
              creator: artist,
              attributes: fullTokenMetadata.attributes,
              name: name,
              image: api.images.token(tokenId),
              tokenId: ensureNumber(tokenId),
              positionInformation: {
                start: { x: ensureNumber(x), y: ensureNumber(y) },
                end: {
                  x: ensureNumber(x) + ensureNumber(width),
                  y: ensureNumber(y) + ensureNumber(height)
                },
                width: CANVAS_WIDTH,
                height: CANVAS_HEIGHT,
                croppedWidth: ensureNumber(width),
                croppedHeight: ensureNumber(height)
              },
              additionalInformation: {
                fullBase64PngString:
                  fileType === EVOLV3_FILETYPES.IMAGE
                    ? replaceIpfsIfNeeded(fileUri, IPFS_GATEWAY_PINATA_MURALL)
                    : api.images.token(ensureNumber(tokenId)),
                croppedBase64PngString:
                  fileType === EVOLV3_FILETYPES.IMAGE
                    ? replaceIpfsIfNeeded(fileUri, IPFS_GATEWAY_PINATA_MURALL)
                    : api.images.token(ensureNumber(tokenId)),
                name: decodeURIComponent(name),
                number: ensureNumber(tokenId),
                seriesId: 0,
                hasAlpha: false,
                colorIndex: [],
                artist: artist,
                fileUri: fileUri,
                fileType: fileType,
                duration: ensureNumber(duration),
                paintUsed: paintUsed,
                wallId: MURALL_WALL_ID.EVOLV3
              }
            })

            resolve(nftInfo)
          } else {
            const res = await fetch(api.tokens.cachedToken(tokenId))
            const tokenInfo = await res.json()
            resolve(tokenInfo)
          }
        } catch (error) {
          console.error(error)
          reject(error)
        }
      })
    }

    const getTokenDataForIdRange = (
      fromTokenId,
      toTokenId,
      asNftInfo = false
    ) => {
      return new Promise(async function (resolve, reject) {
        if (asNftInfo) {
          try {
            const returnValues = await async.map(
              arrayBetweenRange(fromTokenId, toTokenId),
              async function (tokenId, callback) {
                try {
                  const {
                    artist,
                    name,
                    description,
                    width,
                    height,
                    x,
                    y,
                    fileUri,
                    fileType,
                    duration,
                    paintUsed
                  } = await contract?.getDisplayData(tokenId)

                  const fullTokenMetadata = await getFullTokenUriMetadata(
                    tokenId
                  )

                  const nftInfo = await mapInformationToNftObject({
                    artist,
                    chainId: wallInfo.chainId,
                    contractAddress: wallInfo.nftContractAddress,
                    animation_url: replaceIpfsIfNeeded(
                      fullTokenMetadata.animation_url,
                      IPFS_GATEWAY_PINATA_MURALL
                    ),
                    description: decodeURIComponent(description),
                    creator: artist,
                    attributes: fullTokenMetadata.attributes,
                    name: name,
                    image: api.images.token(ensureNumber(tokenId)),
                    tokenId: ensureNumber(tokenId),
                    positionInformation: {
                      start: { x: ensureNumber(x), y: ensureNumber(y) },
                      end: {
                        x: ensureNumber(x) + ensureNumber(width),
                        y: ensureNumber(y) + ensureNumber(height)
                      },
                      width: CANVAS_WIDTH,
                      height: CANVAS_HEIGHT,
                      croppedWidth: ensureNumber(width),
                      croppedHeight: ensureNumber(height)
                    },
                    additionalInformation: {
                      fullBase64PngString:
                        fileType === EVOLV3_FILETYPES.IMAGE
                          ? replaceIpfsIfNeeded(
                              fileUri,
                              IPFS_GATEWAY_PINATA_MURALL
                            )
                          : api.images.token(ensureNumber(tokenId)),
                      croppedBase64PngString:
                        fileType === EVOLV3_FILETYPES.IMAGE
                          ? replaceIpfsIfNeeded(
                              fileUri,
                              IPFS_GATEWAY_PINATA_MURALL
                            )
                          : api.images.token(ensureNumber(tokenId)),
                      name: decodeURIComponent(name),
                      number: ensureNumber(tokenId),
                      seriesId: 0,
                      hasAlpha: false,
                      colorIndex: [],
                      artist: artist,
                      fileUri: fileUri,
                      fileType: fileType,
                      duration: ensureNumber(duration),
                      paintUsed: paintUsed,
                      wallId: MURALL_WALL_ID.EVOLV3
                    }
                  })

                  callback(null, nftInfo)
                } catch (error) {
                  console.error(error)

                  callback(error)
                }
              }
            )
            resolve(returnValues)
          } catch (error) {
            reject(error)
          }
        } else {
          try {
            const tokenInfos = await batchFetchTokensInRangeCached(
              api,
              fromTokenId,
              toTokenId
            )
            resolve(tokenInfos)
          } catch (error) {
            reject(error)
          }
        }
      })
    }
    const getTokenDataForIdList = (tokenIds, asNftInfo = false) => {
      return new Promise(async function (resolve, reject) {
        try {
          const returnValues = await async.map(
            tokenIds,
            async function (tokenId, callback) {
              try {
                if (asNftInfo) {
                  const {
                    artist,
                    name,
                    description,
                    width,
                    height,
                    x,
                    y,
                    fileUri,
                    fileType,
                    duration,
                    paintUsed
                  } = await contract?.getDisplayData(tokenId)

                  const fullTokenMetadata = await getFullTokenUriMetadata(
                    tokenId
                  )

                  const nftInfo = await mapInformationToNftObject({
                    artist,
                    chainId: wallInfo.chainId,
                    contractAddress: wallInfo.nftContractAddress,
                    animation_url: replaceIpfsIfNeeded(
                      fullTokenMetadata.animation_url,
                      IPFS_GATEWAY_PINATA_MURALL
                    ),
                    description: decodeURIComponent(description),
                    creator: artist,
                    attributes: fullTokenMetadata.attributes,
                    name: name,
                    image: api.images.token(ensureNumber(tokenId)),
                    tokenId: ensureNumber(tokenId),
                    positionInformation: {
                      start: { x: ensureNumber(x), y: ensureNumber(y) },
                      end: {
                        x: ensureNumber(x) + ensureNumber(width),
                        y: ensureNumber(y) + ensureNumber(height)
                      },
                      width: CANVAS_WIDTH,
                      height: CANVAS_HEIGHT,
                      croppedWidth: ensureNumber(width),
                      croppedHeight: ensureNumber(height)
                    },
                    additionalInformation: {
                      fullBase64PngString:
                        fileType === EVOLV3_FILETYPES.IMAGE
                          ? replaceIpfsIfNeeded(
                              fileUri,
                              IPFS_GATEWAY_PINATA_MURALL
                            )
                          : api.images.token(ensureNumber(tokenId)),
                      croppedBase64PngString:
                        fileType === EVOLV3_FILETYPES.IMAGE
                          ? replaceIpfsIfNeeded(
                              fileUri,
                              IPFS_GATEWAY_PINATA_MURALL
                            )
                          : api.images.token(ensureNumber(tokenId)),
                      name: decodeURIComponent(name),
                      number: ensureNumber(tokenId),
                      seriesId: 0,
                      hasAlpha: false,
                      colorIndex: [],
                      artist: artist,
                      fileUri: fileUri,
                      fileType: fileType,
                      duration: ensureNumber(duration),
                      paintUsed: paintUsed,
                      wallId: MURALL_WALL_ID.EVOLV3
                    }
                  })

                  callback(null, nftInfo)
                } else {
                  const res = await fetch(api.tokens.cachedToken(tokenId))
                  const tokenInfo = await res.json()
                  callback(null, tokenInfo)
                }
              } catch (error) {
                console.error(error)

                callback(error)
              }
            }
          )
          resolve(returnValues)
        } catch (error) {
          reject(error)
        }
      })
    }
    return {
      fetchNftDataForAccountForOwnedTokenAtIndex: async (
        address,
        index,
        preferOffchain = true
      ) => {
        const tokenId = await contract.tokenOfOwnerByIndex(address, index)

        return getTokenForId(tokenId, true)
      },

      fetchAllTokenInfoForTokenIndexRange: (
        fromTokenId,
        toTokenId,
        preferOffchain = true
      ) => {
        return getTokenDataForIdRange(fromTokenId, toTokenId, true)
      },
      fetchAllTokenInfoForTokenIndexes: (
        tokenIndexes,
        preferOffchain = true
      ) => {
        return getTokenDataForIdList(tokenIndexes, true)
      },

      getNftDataForAccountForOwnedTokenInIndexRange: async (
        address,
        startIndex,
        endIndex,
        preferOffchain = true
      ) => {
        const tokenIds = await getOwnedTokenIdsInIndexRange(
          address,
          startIndex,
          endIndex
        )

        return getTokenDataForIdList(tokenIds, true)
      },
      getTokenMetadataForTokenId: tokenId => {
        return getTokenForId(tokenId, true)
      },

      getBalanceForAddress: address => {
        return contract?.balanceOf(address)
      },
      ownerOf: tokenId => {
        return contract?.ownerOf(tokenId)
      },
      name: () => {
        return contract?.name()
      },
      symbol: () => {
        return contract?.symbol()
      },
      totalSupply: () => {
        return contract?.totalSupply()
      },

      getOwnedTokenIdsInIndexRange,
      getOwnedTokenIdAtIndex: (ownerAddress, index) => {
        return contract?.tokenOfOwnerByIndex(ownerAddress, index)
      },
      transferTokenId: (senderAddress, recipientAddress, tokenId) => {
        const signer = library?.ethersProvider?.getSigner()
        return contract
          ?.connect(signer)
          .transferFrom(
            senderAddress,
            recipientAddress,
            ethers.BigNumber.from(tokenId)
          )
          .wait()
      },
      setApproveTokenTransfer: (ownerAddress, spenderAddress, approved) => {
        const signer = library?.ethersProvider?.getSigner()
        return contract
          ?.connect(signer)
          .setApprovalForAll(spenderAddress, approved)
          .wait()
      },
      isTokenTransferApproved: (ownerAddress, spenderAddress) => {
        return contract?.isApprovedForAll(ownerAddress, spenderAddress)
      },
      isPaintTokenApprovedForAmount: amount => {
        return paintTokenContract.isTokenTransferApprovedForAmount(
          account,
          wallInfo.nftContractAddress,
          amount
        )
      },
      approvePaintTokenForAmount: amount => {
        return paintTokenContract.approveTokenTransfer(
          account,
          wallInfo.nftContractAddress,
          amount
        )
      },
      getTokenUri: tokenId => {
        return contract?.tokenURI(tokenId)
      },
      calculatePaintUsage: (fileType, milliseconds, dimensions) => {
        return contract.calculatePaintUsage(fileType, milliseconds, dimensions)
      },
      getMaximumVideoLengthMillis: () => {
        return contract?.maximumVideoLengthMillis()
      },
      getDisplayDataForTokenId: tokenId => {
        return contract?.getDisplayData(tokenId)
      },
      mint: (
        ipfsHash,
        fileType,
        videoLengthMillis,
        position,
        dimensions,
        name,
        description,
        attributeKeys,
        attributeValues,
        nonce,
        verifierSignature
      ) => {
        const mintData = {
          ipfsHash,
          fileType,
          videoLengthMillis,
          position,
          dimensions,
          name,
          description,
          attributeKeys,
          attributeValues
        }
        const mintSignerData = {
          nonce,
          verifierSignature
        }
        const provider = library?.ethersProvider
        return contract
          ?.connect(provider.getSigner())
          .mint(mintData, mintSignerData)
      },
      mintMetaTx: (
        ipfsHash,
        fileType,
        videoLengthMillis,
        position,
        dimensions,
        name,
        description,
        attributeKeys,
        attributeValues,
        nonce,
        verifierSignature
      ) => {
        const mintData = {
          ipfsHash,
          fileType,
          videoLengthMillis,
          position,
          dimensions,
          name,
          description,
          attributeKeys,
          attributeValues
        }
        const mintSignerData = {
          nonce,
          verifierSignature
        }
        const provider = library?.ethersProvider
        const signer = provider.getSigner()
        return sendMetaTx({
          provider: provider,
          signer: signer,
          targetContract: contract,
          functionName: 'mint',
          functionArgs: [mintData, mintSignerData]
        })
      }
    }
  }, [contract, paintTokenContract, library])
}
