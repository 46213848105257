import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import StyledDialog from '../../uicomponents/styled_dialog'
import clsx from 'clsx'
import _ from 'underscore'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '24px'
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },

  emptyViewContainer: {
    display: 'inline-block'
  },

  outerGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },

  paintLogo: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  },
  paintInfoBg: {
    background:
      'radial-gradient(circle at top right,' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      ')',
    backgroundBlendMode: `normal`
  },
  paintInfoBgDisabled: {
    background:
      'radial-gradient(circle at top right,' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      '), linear-gradient(to right, #54545f, #54545f)',
    backgroundBlendMode: `overlay, normal`
  }
}))

const DEFAULT_HELPER_TEXT = 'Click CHECK to check the entered address'

export default function SetMontageUnlockableDialog (props) {
  const classes = useStyles()

  const [uri, setUri] = useState(null)
  const [description, setDescription] = useState(null)
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState(DEFAULT_HELPER_TEXT)

  const onUriChanged = event => {
    var newUri = event.target.value

    setUri(newUri)
    setButtonEnabled(
      uri && uri.length > 0 && description && description.length > 0
    )
  }

  const onDescriptionChanged = event => {
    var newDescription = event.target.value

    setDescription(newDescription)
    setButtonEnabled(
      uri && uri.length > 0 && description && description.length > 0
    )
  }

  return (
    <StyledDialog
      {...props}
      maxWidth={'sm'}
      withCloseButton={true}
      dialogTitle={'Set unlockable for Montage'}
      dialogContent={() => (
        <div className={clsx(classes.flexColumn)}>
          <Typography
            component='body1'
            variant='body1'
            className={clsx(classes.typographyLight)}
            color='textPrimary'
          >
            Enter the information for the unlockable - this can be a url to some
            additional content, a hidden message, any text you like:
          </Typography>
          <TextField
            style={{ flex: 1, alignSelf: 'center', width: '100%' }}
            label={'Uri'}
            error={error}
            value={uri}
            variant='outlined'
            helperText={'Enter the unlockable information'}
            onChange={onUriChanged}
            margin='normal'
          />
          <TextField
            style={{ flex: 1, alignSelf: 'center', width: '100%' }}
            label={'Description'}
            error={error}
            value={description}
            variant='outlined'
            helperText={
              'Enter the full description for the contents of the unlockable'
            }
            onChange={onDescriptionChanged}
            margin='normal'
          />

          <Button
            size='large'
            color='secondary'
            onClick={() => {
              props.onSetUnlockableClicked(uri, description)
            }}
            disabled={!buttonEnabled}
            variant={buttonEnabled ? 'contained' : 'outlined'}
            style={{ marginBottom: '12px', marginTop: '24px' }}
          >
            Set Unlockable
          </Button>
        </div>
      )}
    />
  )
}
