import React from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import HistoryRangePicker from './range-picker'
import { MURALL_WALL } from '../../../lib/constants'
import { useApi } from '../../../hooks/use-api.js'
import Grid from '@mui/material/Grid'
import ListSubheader from '@mui/material/ListSubheader'
import ImageListItem from '../../../components/common/list/image_list_item'
import makeStyles from '@mui/styles/makeStyles'
import VirtualizedList from '../../../components/common/VirtualizedList'

const useStyles = makeStyles((theme) => ({
  list: {
    minHeight: 0,
    overflowX: 'hidden',
    overflow: 'auto'
  },
  noPadding: {
    padding: 0,
    margin: 0
  }
}))

export default function HistoryDrawerContent({
  maxRange,
  totalStates,
  rangeStart,
  setRangeStart,
  rangeEnd,
  setRangeEnd,
  onLoadHistoryClick,
  highlightStates,
  onHightlightListItemClick
}) {
  const currentWall = useSelector((state) => state.wall)
  const wallInfo = MURALL_WALL[currentWall.id]
  const wallId = currentWall.id
  const api = useApi(wallInfo.chainId, wallId)
  const classes = useStyles()

  const itemSize = 8 * 13 // Assuming the default spacing value is 8px

  return (
    <Grid container spacing={0} direction="column" sx={{ width: '100%', height: '100%' }}>
      {totalStates && (
        <>
          <Grid item>
            <HistoryRangePicker
              maxRange={maxRange}
              open={true}
              totalStates={totalStates}
              rangeStart={rangeStart}
              setRangeStart={setRangeStart}
              rangeEnd={rangeEnd}
              setRangeEnd={setRangeEnd}
              onLoadClick={onLoadHistoryClick}
            />
          </Grid>
          <Grid item>
            <ListSubheader>Highlights:</ListSubheader>
          </Grid>
          <Grid item xs style={{ overflowY: 'auto' }}>
            <VirtualizedList
              className={clsx(classes.list, classes.noPadding)}
              style={{ height: '100%', width: '100%' }}
              itemCount={highlightStates.length}
              itemData={highlightStates}
              itemSize={itemSize} // Adjust the size based on your ImageListItem height
              RowComponent={({ item: state, index }) => (
                <ImageListItem
                  item={state}
                  primaryText={state.name}
                  index={index}
                  key={index} // Prefer using unique IDs for keys instead of index
                  id={index}
                  onListItemClick={() => onHightlightListItemClick && onHightlightListItemClick(state)}
                  image={api ? (state.coverage ? api.s3.history(state.tokenId) : api.s3.token(state.tokenId)) : ''}
                />
              )}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
