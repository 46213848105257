import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import Logo from './logo'
import BottomBorder from '../pulse-border'
import WalletDisplay from '../wallet/display'
import DesktopNavLinks from '../../desktop/navbar/links'
import ExtraMenu from '../../desktop/navbar/extra-menu'
import MobileNavLinks from '../../mobile/navbar/links'
import AccountPaintBalance from '../wallet/paint-balance'
import { useWeb3React } from '@web3-react/core'
import WallSelect from './wall-select'
import THXWidget from '../thx/thx-widget'

const NavBar = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { account } = useWeb3React()
  return (
    <Container>
      <LeftSection isMobile={isMobile}>
        <Logo />
      </LeftSection>
      {!isMobile && (
        <MiddleSection>
          <DesktopNavLinks />
        </MiddleSection>
      )}
      <RightSection isMobile={isMobile}>
        <THXWidget />
        <WallSelect />
        {!isMobile && account && (
          <AccountPaintBalance textSize="body1" compact />
        )}
        <WalletDisplay />
        {isMobile ? <MobileNavLinks /> : <ExtraMenu />}
      </RightSection>
      <BottomBorder />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  z-index: 999;
  background: ${({ theme }) => theme.colors.bg.main};
  position: relative;
`

const LeftSection = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)}
    ${({ theme, isMobile }) => (isMobile ? theme.spacing(1) : theme.spacing(3))};
  flex: 1;
  justify-content: flex-start;
`

const MiddleSection = styled.div`
  display: flex;
  flex: 2;
  height: 100%;
  justify-content: center;
`

const RightSection = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)}
    ${({ theme, isMobile }) => (isMobile ? theme.spacing(1) : theme.spacing(2))};
  flex: 1;
  justify-content: flex-end;
`

export default NavBar
