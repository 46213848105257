export default async ({ library, chainId, info }) => {
  const currentProvider = library?.currentProvider || library
  if (!currentProvider.request) return

  const formattedChainId = `0x${chainId.toString(16)}`
  try {
    await currentProvider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: formattedChainId,
          chainName: info.label,
          rpcUrls: info.rpcUrls,
          nativeCurrency: info.nativeCurrency,
          blockExplorerUrls: [info.explorer]
        },
      ],
    })
  } catch (error) {
    console.error('error adding eth network: ', chainId, info, error)
  }
}
