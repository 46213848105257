import React, { useEffect, useState } from 'react'
import { IconButton, Grid, TextField, Button, Box } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
function KeyValueInput ({ sx, onAttributesChanged }) {
  const [attributes, setAttributes] = useState([])

  const addAttribute = () => {
    setAttributes([
      ...attributes,
      {
        name: '',
        value: ''
      }
    ])
  }

  function updateAttr (index, key, value) {
    const newAttr = { ...attributes[index] }
    newAttr[key] = value
    const attrs = [...attributes]
    attrs[index] = newAttr
    setAttributes(attrs)
  }

  const removeAttribute = index => {
    const attrs = [...attributes]
    attrs.splice(index, 1)
    setAttributes(attrs)
  }

  useEffect(() => {
    onAttributesChanged && onAttributesChanged(attributes)
  }, [attributes])

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      direction={'column'}
      justifyContent='center'
      alignItems='center'
      sx={sx}
    >
      {attributes.map((attr, index) => (
        <Grid
          key={index}
          container
          item
          xs={12}
          spacing={1}
          justifyContent='center'
          alignItems='center'
        >
          <Grid container item xs spacing={1}>
            <Grid item xs={6}>
              <TextField
                label='Attribute Name'
                value={attr.name}
                onChange={e => updateAttr(index, 'name', e.target.value)}
                size='small'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Attribute Value'
                value={attr.value}
                onChange={e => updateAttr(index, 'value', e.target.value)}
                size='small'
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => removeAttribute(index)}
              aria-label='delete'
              color='error'
              size='large'
            >
              <RemoveCircleIcon fontSize='inherit' />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item alignSelf='flex-start'>
        <Button
          onClick={addAttribute}
          variant='outlined'
          size='small'
          endIcon={<AddCircleIcon />}
        >
          Add Attribute
        </Button>
      </Grid>
    </Grid>
  )
}

export default KeyValueInput
