import React, { useCallback, useEffect, useRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles(theme => ({
  root: {},
  contentRoot: {
    position: 'relative',
    minHeight: props => (props.forceFullHeight ? '100%' : 'auto'),
    minWidth: '100%',
    margin: '0 auto'
  },
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  dialogContent: {
    width: '100%',
    height: props => (props.forceFullHeight ? '100%' : 'auto'),
    backgroundColor: theme.palette.secondary.dark,
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  dialogTitle: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  }
}))

export default function StyledImageViewerDialog ({
  dialogTitle,
  disableBackdropClick,
  disableEscapeKeyDown,
  fullScreen,
  onClose,
  open,
  scroll,
  contentScroll = 'paper',
  dialogImageContent,
  withCloseButton,
  dialogInformationColumn,
  dialogInformationStyle,
  dialogInformation,
  dialogActions,
  maxWidth,
  forceFullHeight = true,
  onDialogContentResize,
  PaperProps,
  sx
}) {
  const dialogContentRef = useRef()

  const measuredRef = useCallback(node => {
    if (node !== null) {
      dialogContentRef.current = node
      onDialogContentResize &&
        onDialogContentResize(dialogContentRef.current.getBoundingClientRect())
    }
  }, [])

  useEffect(() => {
    function handleResize () {
      if (dialogContentRef.current) {
        onDialogContentResize &&
          onDialogContentResize(
            dialogContentRef.current.getBoundingClientRect()
          )
      }
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  const classes = useStyles({ forceFullHeight })

  return (
    <Dialog
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      open={open}
      className={classes.root}
      fullWidth
      maxWidth={maxWidth || 'lg'}
      scroll={scroll}
      PaperProps={{
        style: { borderRadius: 10, ...(forceFullHeight && { height: '100%' }) },
        ...PaperProps
      }}
      sx={sx}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          component='h5'
          variant='h5'
          noWrap
          className={clsx(classes.typography)}
          color='textPrimary'
        >
          {dialogTitle}
        </Typography>
        {withCloseButton && (
          <IconButton
            onClick={onClose}
            aria-label='close'
            size='large'
            sx={{
              zIndex: 9999999999,
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent
        ref={measuredRef}
        className={classes.dialogContent}
        scroll={contentScroll}
      >
        <div className={clsx(classes.contentRoot)}>{dialogImageContent}</div>
      </DialogContent>

      <DialogActions className={clsx(classes.dialogActions)}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'stretch',
            alignContent: 'stretch',
            justifyContent: 'stretch'
          }}
        >
          {dialogInformation && (
            <div
              style={{
                display: 'flex',
                alignSelf: 'stretch',
                justifyContent: 'stretch',
                alignContent: 'flex-start',
                flex: 1,
                flexDirection: dialogInformationColumn ? 'column' : 'row',
                paddingTop: '12px',
                paddingLeft: '16px',
                paddingRight: '16px',
                ...dialogInformationStyle
              }}
            >
              {dialogInformation}
            </div>
          )}
          {dialogActions && (
            <div
              style={{
                // position: 'absolute',
                // bottom: '0px',
                // right: '0px',
                display: 'flex',
                alignContent: 'flex-end',
                justifyContent: 'flex-end',
                width: '100%',
                flexDirection: 'row',
                paddingTop: '16px',
                marginBottom: 0
              }}
            >
              {dialogActions}
            </div>
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}
