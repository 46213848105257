import { useState, useEffect, useCallback } from 'react'
import { getMontageContractAddress } from '../../js/modules/blockchain/datasource/ContractAddressDataSource'
import notification from '../../lib/notification'
import { useMontageDataSource } from '../use-montage-datasource.js'
import { useMurAllNftDataSource } from '../use-murall-nft-datasource.js'
import { useActiveWeb3React } from '../web3.js'

export const useCreateMontageNft = (
  name,
  description,
  canBeUnpacked,
  tokenIds,
  immediate = false
) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const { account, chainId } = useActiveWeb3React()

  const murAllNftDatasource = useMurAllNftDataSource()
  const {
    isTokenTransferApproved,
    setApproveTokenTransfer
  } = murAllNftDatasource || {}
  const { createMontage } = useMontageDataSource()

  const apply = useCallback(async () => {
    if(!murAllNftDatasource || !createMontage) {
      return
    }
    try {
      setLoading(true)

      const allowed = await isTokenTransferApproved(
        account,
        getMontageContractAddress(chainId)
      )

      if (!allowed) {
        try {
          const approvePromise = setApproveTokenTransfer(
            account,
            getMontageContractAddress(chainId),
            true
          )
          await notification.promise(approvePromise, {
            loading: `Approving Montage contract access to your MurAll NFTs`,
            success: `Access approved to Montage contract`,
            error: error => {
              if (error.message && error.message.indexOf('User denied') != -1)
                return 'You rejected the transaction!'
              return `Sorry, the transaction failed: ${
                error
                  ? error.message
                    ? error.message
                    : error.name
                  : 'unknown error'
              }`
            }
          })
        } catch (error) {
          console.error(error)
          setError(error)
          setLoading(false)
          return
        }
      }
      try {
        const createMontagePromise = createMontage(
          name,
          description,
          canBeUnpacked,
          tokenIds,
          account
        )

        await notification.promise(createMontagePromise, {
          loading: `Creating Montage "${name}"`,
          success: `Successfully created Montage "${name}"`,
          error: error => {
            if (error.message && error.message.indexOf('User denied') != -1)
              return 'You rejected the transaction!'
            return `Error while creating Montage: ${
              error
                ? error.message
                  ? error.message
                  : error.name
                : 'unknown error'
            }`
          }
        })

        setSuccess(true)
        setLoading(false)
        return true
      } catch (error) {
        console.error(error)
        setError(error)
        setLoading(false)
        return
      }
    } catch (error) {
      console.error(error)
      setError(error)
      setLoading(false)
      return
    }
  }, [
    isTokenTransferApproved,
    setApproveTokenTransfer,
    createMontage,
    account,
    chainId,
    name,
    description,
    canBeUnpacked,
    tokenIds
  ])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, success, loading, error }
}

export default useCreateMontageNft
