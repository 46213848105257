import { useMemo } from 'react'
import Web3 from 'web3'
import {
  isTransactionSuccess,
  overwriteStringAtIndex
} from '../js/libs/appUtils'
import { useMurAllContract } from './use-contract-for-chain'
import MurAllContractAbiL1 from '../contracts/layer1/MurAll.json'
import MurAllContractAbiL2 from '../contracts/layer2/MurAll.json'
import { MURALL_WALL, MURALL_WALL_ID, SupportedChain } from '../lib/constants'
import getWeb3L1 from '../js/libs/getWeb3L1'
import getWeb3L2 from '../js/libs/getWeb3L2'
import { useSelector } from 'react-redux'

// returns null on errors
const emptyHex =
  '0x0000000000000000000000000000000000000000000000000000000000000000'
const hexStartIndex = 2

export const useMurAllDataSource = wallId => {
  let wallIdToUse = wallId
  const currentWallId = useSelector(state => state.wall.id)
  if (!wallIdToUse) {
    wallIdToUse = currentWallId
  }
  if (wallIdToUse === MURALL_WALL_ID.EVOLV3) {
    console.error(
      'useMurAllDataSource not supported for EVOLV3 - use useMurAllEvolv3NftDataSource instead. Falling back to MURALL_WALL_ID.LAYER_2 which is also on Polygon'
    )
    wallIdToUse = MURALL_WALL_ID.LAYER_2
  }
  const wallInfo = MURALL_WALL[wallIdToUse]
  const contract = useMurAllContract(wallInfo.chainId)
  const archiveNodeContract = useMurAllContract(wallInfo.chainId, true)

  return useMemo(() => {
    const setPixelsRaw = (
      colourIndexData,
      blockchainPixelData,
      blockchainPixelGroupData,
      blockchainPixelGroupIndexData,
      blockchainTransparentPixelGroupData,
      blockchainTransparentPixelGroupIndexData,
      metadata,
      senderAddress
    ) => {
      return new Promise(function (resolve, reject) {
        const txObject = getSetPixelsTxSize(
          colourIndexData,
          blockchainPixelData,
          blockchainPixelGroupData,
          blockchainPixelGroupIndexData,
          blockchainTransparentPixelGroupData,
          blockchainTransparentPixelGroupIndexData,
          metadata,
          senderAddress
        )

        // Estimate the size of the transaction
        const txSize = txObject.txSize
        const maxSize = txObject.maxSize

        if (!txObject.withinLimit) {
          console.error(
            'Transaction size too large:',
            txSize,
            'bytes',
            'Max size:',
            maxSize,
            'bytes'
          )
          reject('Transaction size exceeds the limit')
          return
        } else {
          console.log(
            'Transaction size within range:',
            txSize,
            'bytes',
            'Max size:',
            maxSize,
            'bytes'
          )
        }
        contract.methods
          .setPixels(
            colourIndexData,
            blockchainPixelData,
            blockchainPixelGroupData,
            blockchainPixelGroupIndexData,
            blockchainTransparentPixelGroupData,
            blockchainTransparentPixelGroupIndexData,
            metadata
          )
          .send({ from: senderAddress })
          .on('receipt', function (receipt) {
            if (isTransactionSuccess(receipt)) {
              resolve(receipt.events.Painted)
            } else {
              reject('Transaction Failed')
            }
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            if (isTransactionSuccess(receipt)) {
              resolve(receipt.events.Painted)
            } else {
              reject('Transaction Failed')
            }
          })
          .on('transactionHash', function (hash) {
            console.log('transactionHash', hash)
          })
          .on('error', function (error, receipt) {
            console.error(error)
            reject(error)
          })
      })
    }
    const getSetPixelsTxSize = (
      colourIndexData,
      blockchainPixelData,
      blockchainPixelGroupData,
      blockchainPixelGroupIndexData,
      blockchainTransparentPixelGroupData,
      blockchainTransparentPixelGroupIndexData,
      metadata,
      senderAddress
    ) => {
      const txObject = {
        from: senderAddress,
        to: contract.options.address, // The contract address
        data: contract.methods
          .setPixels(
            colourIndexData,
            blockchainPixelData,
            blockchainPixelGroupData,
            blockchainPixelGroupIndexData,
            blockchainTransparentPixelGroupData,
            blockchainTransparentPixelGroupIndexData,
            metadata
          )
          .encodeABI()
      }

      // Estimate the size of the transaction
      const txSize =
        txObject.data.length / 2 + 50 + (44 + txObject.from.length / 2) // 44 bytes for other transaction data
      const maxSize = 131072 // MetaMask limit
      return {
        txSize: txSize,
        maxSize: maxSize,
        withinLimit: txSize <= maxSize
      }
    }

    const setPixels = (
      colourIndexData,
      blockchainPixelData,
      blockchainPixelGroupData,
      blockchainPixelGroupIndexData,
      blockchainTransparentPixelGroupData,
      blockchainTransparentPixelGroupIndexData,
      name,
      number,
      seriesId,
      hasAlpha,
      senderAddress
    ) => {
      // strings need to be padded with zeroes on the right
      const hexName = Web3.utils.padRight(Web3.utils.asciiToHex(name), 64)
      const hexOtherMetadata = constructOtherMetadata(
        number,
        seriesId,
        hasAlpha
      )

      return setPixelsRaw(
        colourIndexData,
        blockchainPixelData,
        blockchainPixelGroupData,
        blockchainPixelGroupIndexData,
        blockchainTransparentPixelGroupData,
        blockchainTransparentPixelGroupIndexData,
        [hexName, hexOtherMetadata],
        senderAddress
      )
    }

    const constructOtherMetadata = (number, seriesId, hasAlpha) => {
      // base empty hex
      var hexOtherMetadata = emptyHex

      // numbers need to be padded with zeroes on the left
      const hexNumber = Web3.utils.padLeft(Web3.utils.numberToHex(number), 6)
      const hexSeriesId = Web3.utils.padLeft(
        Web3.utils.numberToHex(seriesId),
        6
      )

      // Insert number slicing off '0x' from start
      hexOtherMetadata = overwriteStringAtIndex(
        hexOtherMetadata,
        hexNumber.slice(2),
        hexStartIndex
      )

      // Insert seriesId slicing off '0x' from start
      hexOtherMetadata = overwriteStringAtIndex(
        hexOtherMetadata,
        hexSeriesId.slice(2),
        hexStartIndex + 6
      )

      if (hasAlpha) {
        // change last bit to 1 signifying presence of alpha channel
        hexOtherMetadata = overwriteStringAtIndex(
          hexOtherMetadata,
          '1',
          hexOtherMetadata.length - 1
        )
      }
      return hexOtherMetadata
    }

    return {
      setPixelsRaw: setPixelsRaw,
      setPixels: setPixels,
      checkSetPixels: (
        colourIndexData,
        blockchainPixelData,
        blockchainPixelGroupData,
        blockchainPixelGroupIndexData,
        blockchainTransparentPixelGroupData,
        blockchainTransparentPixelGroupIndexData,
        name,
        number,
        seriesId,
        hasAlpha,
        senderAddress
      ) => {
        // strings need to be padded with zeroes on the right
        const hexName = Web3.utils.padRight(Web3.utils.asciiToHex(name), 64)
        const hexOtherMetadata = constructOtherMetadata(
          number,
          seriesId,
          hasAlpha
        )
        return getSetPixelsTxSize(
          colourIndexData,
          blockchainPixelData,
          blockchainPixelGroupData,
          blockchainPixelGroupIndexData,
          blockchainTransparentPixelGroupData,
          blockchainTransparentPixelGroupIndexData,
          [hexName, hexOtherMetadata],
          senderAddress
        )
      },
      getPaintTokenCost: () => {
        return contract.methods.getCostPerPixel().call()
      },
      getTotalNumberOfArtists: () => {
        return contract.methods.totalArtists().call()
      },
      calculatePaintTokenCostForPixels: async pixels => {
        const costPerPixel = await contract.methods.getCostPerPixel().call()
        return Web3.utils.toBN(pixels).mul(Web3.utils.toBN(costPerPixel))
      },

      getPaintedEventsForTokenIds: (
        tokenIds,
        fromBlock = 'earliest',
        toBlock = 'latest'
      ) => {
        const bnTokenIds = tokenIds.map(id => Web3.utils.toBN(id))
        return archiveNodeContract.getPastEvents('Painted', {
          filter: { tokenId: bnTokenIds },
          fromBlock: fromBlock,
          toBlock: toBlock
        })
      },

      subscribeToPaintedEvents: callback => {
        const wallInfo = MURALL_WALL[wallIdToUse]
        const chainId = wallInfo.chainId
        const abiToUse =
          chainId === SupportedChain.Ethereum
            ? MurAllContractAbiL1
            : MurAllContractAbiL2
        // get guaranteed web socket connection for chain
        const web3 =
          chainId === SupportedChain.Ethereum ? getWeb3L1() : getWeb3L2()

        const deployedNetwork =
          abiToUse.networks[
            chainId === SupportedChain.Ethereum
              ? SupportedChain.Ethereum
              : SupportedChain.Polygon
          ]
        const instance = new web3.eth.Contract(
          abiToUse.abi,
          deployedNetwork && deployedNetwork.address
        )
        instance.events
          .Painted({ fromBlock: 'latest' })
          .on('data', async function (event) {
            callback(event)
          })
          .on('error', async function (error) {
            console.error(error)
          })
      },

      getPaintedEventsForTokenId: (
        tokenId,
        fromBlock = 'earliest',
        toBlock = 'latest'
      ) => {
        return archiveNodeContract.getPastEvents('Painted', {
          filter: { tokenId: Web3.utils.toBN(tokenId) },
          fromBlock: fromBlock,
          toBlock: toBlock
        })
      },

      setDataValidator: (validatorAddress, senderAddress) => {
        return new Promise(function (resolve, reject) {
          contract.methods
            .setDataValidator(validatorAddress)
            .send({ from: senderAddress })
            .on('receipt', function (receipt) {
              if (isTransactionSuccess(receipt)) {
                resolve(receipt.events.DataValidatorSet)
              } else {
                reject('Transaction Failed')
              }
            })
            .on('confirmation', function (confirmationNumber, receipt) {
              if (isTransactionSuccess(receipt)) {
                resolve(receipt.events.DataValidatorSet)
              } else {
                reject('Transaction Failed')
              }
            })
            .on('transactionHash', function (hash) {
              console.log('transactionHash', hash)
            })
            .on('error', function (error, receipt) {
              console.error(error)
              reject(error)
            })
        })
      }
    }
  }, [contract, wallIdToUse, currentWallId, archiveNodeContract])
}
