export const batchFetchTokensInRangeCached = (
  api,
  fromTokenId,
  toTokenId,
  batchSize = 100
) => {
  return new Promise(async (resolve, reject) => {
    const batches = []

    // Split the range into batches
    for (let i = fromTokenId; i <= toTokenId; i += batchSize) {
      const end = Math.min(i + batchSize - 1, toTokenId)
      batches.push([i, end])
    }

    // Fetch each batch
    const allTokenInfos = []
    for (const [start, end] of batches) {
      try {
        const res = await fetch(api.tokens.cachedInRange(start, end))
        const tokenInfos = await res.json()
        allTokenInfos.push(...tokenInfos.sort((a, b) => a.id - b.id))
      } catch (error) {
        reject(error)
        return
      }
    }
    console.log('batch fetched all TokenInfos', allTokenInfos)
    resolve(allTokenInfos)
  })
}

export const downloadImages = (tokenIds, urlFunction, nameFunction) => {
  return tokenIds.forEach(tokenId => {
    const url = urlFunction(tokenId)
    const anchorElement = document.createElement('a')
    anchorElement.href = url
    anchorElement.download = nameFunction(tokenId)
    anchorElement.click()
    URL.revokeObjectURL(url)
  })
}
