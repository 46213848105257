import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import CustomAccordion from '../../../components/common/Accordion'
import SectionTitle from '../SectionTitle'

const SectionGovernance = () => {
  return (
    <>
      <SectionTitle variant='h4' component='h4'>
        MurAll DAO
      </SectionTitle>
      <CustomAccordion
        items={[
          {
            title: 'What is the MurAll DAO?',

            content: (
              <>
                The MurAll DAO empowers our community to actively participate in
                decision-making, ensuring that the future of our platform
                reflects the collective vision and values of our members.
              </>
            )
          },
          {
            title: 'What voting power does Paint have?',

            content: <>Each Paint token holds 1 vote.</>
          },
          {
            title: 'What voting power do MurAll Revo1t/Di2rupt NFTs have?',

            content: (
              <>
                Each MurAll Revo1t/Di2rupt NFT holds voting power based on their
                pixel count (ie. double the amount of Paint burned in the
                production of the artwork).
              </>
            )
          },
          {
            title:
              'What are the proposal Guidelines for Community Participation?',
            content: (
              <ol>
                <li>
                  All proposals must be submitted through the DAO proposal
                  system on Snapshot.
                </li>
                <li>
                  Proposals must have a clear and concise title that reflects
                  the purpose of the proposal.
                </li>
                <li>
                  Proposals should provide detailed information and rationale
                  for the proposal, including any relevant background
                  information, data, and supporting documentation.
                </li>
                <li>
                  Proposals must include a clear description of the desired
                  outcome, including any milestones or deliverables that will be
                  achieved.
                </li>
                <li>
                  Proposals should include a budget breakdown and funding
                  request, where applicable.
                </li>
                <li>
                  Proposals should consider the long-term sustainability and
                  impact of the proposal on the MurAll ecosystem.
                </li>
                <li>
                  Proposals should align with the values and mission of the
                  MurAll community, including promoting artistic creativity and
                  freedom of expression.
                </li>
                <li>
                  Proposals must adhere to any relevant laws and regulations.
                </li>
                <li>
                  Proposals must meet the minimum voting threshold of 1% of the
                  total PAINT supply to be considered for voting.
                </li>
                <li>
                  Submitted proposals must allow at least 3 days before
                  beginning a voting period to allow for community discussion
                  and feedback.
                </li>
              </ol>
            )
          },
          {
            title:
              'How much voting power is required in order to submit proposals?',
            content: (
              <>
                Only members who hold enough PAINT for 2 full coverages of
                MurAll Revo1t/Di2rupt(2097152 PAINT) or equivalent voting power
                in MurAll NFTs will be able to create proposals within the DAO.
              </>
            )
          },
          {
            title: 'Where can I find the MurAll DAO website?',
            content: (
              <>
                The DAO is hosted{' '}
                <Link
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  href='https://snapshot.org/#/dao.murall.eth'
                  color='secondary'
                >
                  here on Snapshot.org
                </Link>
              </>
            )
          }
        ]}
      />
    </>
  )
}

export default SectionGovernance
