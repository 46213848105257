import React, { useState } from 'react'
import {
  useTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  TextField,
  Link
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import StyledImageViewerDialog from '../../uicomponents/styled_image_viewer_dialog'
import CollapsableTransferListView from '../../uicomponents/collapsable_transfer_list_view'
import { yellow } from '@mui/material/colors'
import { red } from '@mui/material/colors'
import Get_image_for_layer_usecase from '../../modules/blockchain/usecase/get_image_for_layer_usecase'
import Get_transaction_data_for_layers_usecase from '../../modules/blockchain/usecase/get_transaction_data_for_layers_usecase'
import LayerListItem from './layer_list_item'
import Grid from '@mui/material/Grid'
import config from '../../config'
import _ from 'underscore'
import { useEffect } from 'react'
import LoadingPlaceholderListItem from '../gallery/loading_placeholder_list_item'
import './../../../css/layout.css'
import notification from '../../../lib/notification'
import useSetPixelsOnMurAll from '../../../hooks/murall/use-set-pixels-on-murall'
import { Link as RouterLink } from 'react-router-dom'
import SvgLayersImageViewer from '../../../components/common/svg/svg-layers-image-viewer'
import CustomAccordion from '../../../components/common/Accordion'
import { LoadingSpinner } from '../../uicomponents/loading_spinner'
import { TRANSPARENT_IMAGE_DATA_URL } from '../../../lib/constants'
import { useLayerDataSizeCheckForSetPixels } from '../../../hooks/murall/use-layer-data-size-check-for-set-pixels'

const useStyles = makeStyles(theme => ({
  dialogInformationRoot: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },

  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },

  body: {
    fontSize: 16
  },

  dialogMedia: {
    backgroundColor: theme.palette.primary.dark
  },

  typography: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto'
    // fontWeight: 100,
  },

  warningRed: {
    color: red[700]
  },

  warningBannerRoot: {
    width: '100%',
    borderRadius: 10,
    background: yellow[800],
    marginTop: '16px',
    padding: '16px'
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  warningIcon: {
    height: '48px',
    width: '48px',
    marginRight: '16px'
  },
  fullFlex: {
    flex: 1
  }
}))

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

const DEFAULT_FADE_DURATION_MILLIS = 350
const ANIMATION_EASE_DEFAULT = 'power2.inOut'
const LIST_ID_ALL_LAYERS_ITEMS = 'topList'
const LIST_ID_LAYERS_TO_MINT_ITEMS = 'bottomList'
const LAYER_STATE_NOT_MINTED = 0
const LAYER_STATE_MINTING = 1
const LAYER_STATE_MINTED = 2

const validNumber = value => /^[\d]+$/.test(value) && value <= 16777215

const DATA_SIZE_WARNING_THRESHOLD = 4075

export default function DrawingLayersTransactionDialog(props) {
  const classes = useStyles()

  const { setPixelsOnMurAllAndNotify } = useSetPixelsOnMurAll()

  const getImageForLayerUsecase = new Get_image_for_layer_usecase()
  const getTransactionDataForLayersUsecase = new Get_transaction_data_for_layers_usecase()

  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(null)
  const [numberValue, setNumberValue] = useState(0)
  const [seriesIdValue, setSeriesIdValue] = useState(0)
  const [nameValue, setNameValue] = useState('')
  const [numberError, setNumberError] = useState(null)
  const [nameError, setNameError] = useState(null)
  const [seriesIdError, setSeriesIdError] = useState(null)
  const [isValid, setIsValid] = useState(true)
  const [layersToMint, setLayersToMint] = useState(() => [])
  const [loadingPreviews, setLoadingPreviews] = useState(false)
  const [layersToMintOrder, setLayersToMintOrder] = useState(() => [])
  const [layerPreviews, setLayerPreviews] = useState(new Map())
  const [layerMintStates, setLayerMintStates] = useState(new Map())
  const [layerInfos, setLayerInfos] = useState(new Map())
  const [masterLayerInfo, setMasterLayerInfo] = useState({})
  const [currentLayerMinting, setCurrentLayerMinting] = useState(null)
  const [allLayers, setAllLayers] = useState([])
  const [checked, setChecked] = useState([])
  const [sameNameForAllLayers, setSameNameForAllLayers] = useState(false)
  const [sameSeriesIdForAllLayers, setSameSeriesIdForAllLayers] = useState(
    false
  )
  const [
    autoGenerateNumberForAllLayers,
    setAutoGenerateNumberForAllLayers
  ] = useState(false)
  const allLayersChecked = intersection(checked, allLayers)
  const layersToMintChecked = intersection(checked, layersToMintOrder)

  const theme = useTheme()
  const { open } = props

  useEffect(() => {
    function setAllLayersInitial() {
      setAllLayers(getLayers())
    }
    async function fetchLayerPreviews() {
      setLoadingPreviews(true)
      const newLayerPreviews = new Map(layerPreviews)
      for (const layer of config.layers) {
        if (!layerPreviews.has(layer.id)) {
          const layerPreview = await getImageForLayerUsecase.execute(layer)
          newLayerPreviews.set(layer.id, layerPreview)
        }
      }
      setLayerPreviews(newLayerPreviews)
      setLoadingPreviews(false)
    }
    setAllLayersInitial()
    fetchLayerPreviews()
  }, [])

  const getLayers = () => {
    return [...config.layers].filter(function (layer, index, arr) {
      return config.blockchainDataLayer != null
        ? layer.id != config.blockchainDataLayer.id
        : true
    })
  }

  const onDragEnd = result => {
    const { destination, source, draggableId, type } = result
    // dropped outside the list
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.droppableId === LIST_ID_ALL_LAYERS_ITEMS)
    ) {
      return
    }

    if (destination.droppableId === source.droppableId) {
      //if (destination.index === source.index) return;
      const items = reorder(
        source.droppableId === LIST_ID_LAYERS_TO_MINT_ITEMS
          ? layersToMintOrder
          : allLayers,
        source.index,
        destination.index
      )

      if (source.droppableId === LIST_ID_LAYERS_TO_MINT_ITEMS) {
        updateDisplayTokenOrder(items)
      } else {
        setAllLayers(items)
      }
    } else {
      const result = move(
        source.droppableId === LIST_ID_LAYERS_TO_MINT_ITEMS
          ? layersToMintOrder
          : allLayers,
        destination.droppableId === LIST_ID_LAYERS_TO_MINT_ITEMS
          ? layersToMintOrder
          : allLayers,
        source,
        destination
      )

      updateDisplayTokenOrder(result.displayList)
      setAllLayers(result.allItemsList)
    }
  }

  const handleToggle = layerId => {
    const currentIndex = checked.indexOf(layerId)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(layerId)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const selectAll = list => {
    const newChecked = [...checked]
    list.forEach(layer => {
      if (newChecked.indexOf(layer) === -1) {
        newChecked.push(layer)
      }
    })
    setChecked(newChecked)
  }

  const deselectAll = list => {
    const newChecked = [...checked]
    list.forEach(layer => {
      newChecked.splice(newChecked.indexOf(layer), 1)
    })
    setChecked(newChecked)
  }

  const handleSelectAllTop = () => {
    selectAll(allLayers)
  }
  const handleSelectAllBottom = () => {
    selectAll(layersToMintOrder)
  }
  const handleDeselectAllTop = () => {
    deselectAll(allLayers)
  }
  const handleDeselectAllBottom = () => {
    deselectAll(layersToMintOrder)
  }

  const updateDisplayTokenOrder = newDisplayIds => {
    setLayersToMintOrder(newDisplayIds)
    if (props.onDisplayTokenOrderUpdate) {
      props.onDisplayTokenOrderUpdate(newDisplayIds)
    }
  }

  const handleMoveCheckedToDisplay = () => {
    const newDisplayIds = layersToMintOrder.concat(allLayersChecked)
    setLayersToMint(newDisplayIds)
    updateDisplayTokenOrder(newDisplayIds)
    setAllLayers(not(allLayers, allLayersChecked))
    setChecked(not(checked, allLayersChecked))
  }

  const handleMoveCheckedToAll = () => {
    setAllLayers(allLayers.concat(layersToMintChecked))
    const newDisplayIds = not(layersToMintOrder, layersToMintChecked)
    setLayersToMint(newDisplayIds)
    updateDisplayTokenOrder(newDisplayIds)
    setChecked(not(checked, layersToMintChecked))
  }

  const handleListItemClick = (event, selectedLayerIndex, selectedLayer) => {
    const newSelectedLayer =
      currentSelectedIndex === selectedLayerIndex ? null : selectedLayerIndex
    setCurrentSelectedIndex(newSelectedLayer)

    const currentIndex = checked.indexOf(selectedLayer)
    const newChecked = [...checked]

    if (currentIndex !== -1 && newSelectedLayer === null) {
      newChecked.splice(currentIndex, 1)
      setChecked(newChecked)
    } else if (currentIndex === -1 && newSelectedLayer !== null) {
      newChecked.push(selectedLayer)
      setChecked(newChecked)
    }
  }

  const constructNftInputFields = () => {
    const layerInfo =
      currentSelectedIndex !== null &&
      layersToMintOrder[currentSelectedIndex] &&
      layerInfos.get(layersToMintOrder[currentSelectedIndex].id.toString())
    return (
      <>
        <FormGroup row>
          <TextField
            {...(nameError && { error: true, helperText: nameError })}
            id="name"
            variant="outlined"
            label="Optional name"
            style={{ flex: 1 }}
            value={
              sameNameForAllLayers
                ? masterLayerInfo.name
                : layerInfo
                ? layerInfo.name
                : nameValue
            }
            size="small"
            onChange={e => {
              if (e.target.value == '' || e.target.value.length <= 32) {
                setNameValue(e.target.value)
                if (sameNameForAllLayers) {
                  setMasterLayerInfo({
                    ...masterLayerInfo,
                    name: e.target.value
                  })
                } else if (currentSelectedIndex !== null) {
                  updateLayerInfo(currentSelectedIndex, {
                    ...layerInfos.get(
                      layersToMintOrder[currentSelectedIndex].id.toString()
                    ),
                    name: e.target.value
                  })
                }
                setNameError(null)
                setIsValid(numberError == null && seriesIdError == null)
              } else {
                setNameError('Must be a string of up to 32 characters')
                setIsValid(false)
                return false
              }
            }}
          />
          <FormControlLabel
            style={{ paddingLeft: '12px', flex: 1 }}
            control={
              <Checkbox
                checked={sameNameForAllLayers}
                onChange={event => {
                  setSameNameForAllLayers(event.target.checked)
                  if (event.target.checked) {
                    setMasterLayerInfo({ ...masterLayerInfo, name: nameValue })
                  } else if (currentSelectedIndex !== null) {
                    updateLayerInfo(currentSelectedIndex, {
                      ...layerInfos.get(
                        layersToMintOrder[currentSelectedIndex].id.toString()
                      ),
                      name: nameValue
                    })
                  }
                }}
                name="checkedA"
              />
            }
            label="Use same name for all layers"
          />
        </FormGroup>
        <FormGroup row>
          <TextField
            {...(numberError && { error: true, helperText: numberError })}
            id="number"
            variant="outlined"
            label="Optional number"
            style={{ flex: 1, marginTop: '16px' }}
            value={
              autoGenerateNumberForAllLayers
                ? 0
                : layerInfo
                ? layerInfo.number
                : numberValue
            }
            size="small"
            disabled={autoGenerateNumberForAllLayers}
            onChange={e => {
              if (e.target.value == '' || validNumber(e.target.value)) {
                setNumberValue(e.target.value)
                if (currentSelectedIndex !== null) {
                  updateLayerInfo(currentSelectedIndex, {
                    ...layerInfos.get(
                      layersToMintOrder[currentSelectedIndex].id.toString()
                    ),
                    number: e.target.value
                  })
                }
                setNumberError(null)

                setIsValid(nameError == null && seriesIdError == null)
              } else {
                setNumberError('Must be a value from 0 - 16777215')
                setIsValid(false)
                return false
              }
            }}
          />
          <FormControlLabel
            style={{ paddingLeft: '12px', flex: 1 }}
            control={
              <Checkbox
                checked={autoGenerateNumberForAllLayers}
                onChange={event => {
                  setAutoGenerateNumberForAllLayers(event.target.checked)
                  if (!event.target.checked && currentSelectedIndex !== null) {
                    updateLayerInfo(currentSelectedIndex, {
                      ...layerInfos.get(
                        layersToMintOrder[currentSelectedIndex].id.toString()
                      ),
                      number: numberValue
                    })
                  }
                }}
                name="checkedA"
              />
            }
            label="Auto generate number based off layer position"
          />
        </FormGroup>
        <FormGroup row>
          <TextField
            {...(seriesIdError && { error: true, helperText: seriesIdError })}
            id="seriesId"
            variant="outlined"
            label="Optional series id"
            style={{ flex: 1, marginTop: '16px' }}
            value={
              sameSeriesIdForAllLayers
                ? masterLayerInfo.seriesId
                : layerInfo
                ? layerInfo.seriesId
                : seriesIdValue
            }
            size="small"
            onChange={e => {
              if (e.target.value == '' || validNumber(e.target.value)) {
                setSeriesIdValue(e.target.value)
                setSeriesIdError(null)

                if (sameSeriesIdForAllLayers) {
                  setMasterLayerInfo({
                    ...masterLayerInfo,
                    seriesId: e.target.value
                  })
                } else if (currentSelectedIndex !== null) {
                  updateLayerInfo(currentSelectedIndex, {
                    ...layerInfos.get(
                      layersToMintOrder[currentSelectedIndex].id.toString()
                    ),
                    seriesId: e.target.value
                  })
                }

                setIsValid(numberError == null && nameError == null)
              } else {
                setSeriesIdError('Must be a value from 0 - 16777215')
                setIsValid(false)
                return false
              }
            }}
          />
          <FormControlLabel
            style={{ paddingLeft: '12px', flex: 1 }}
            control={
              <Checkbox
                checked={sameSeriesIdForAllLayers}
                onChange={event => {
                  setSameSeriesIdForAllLayers(event.target.checked)
                  if (event.target.checked) {
                    setMasterLayerInfo({
                      ...masterLayerInfo,
                      seriesId: seriesIdValue
                    })
                  } else if (currentSelectedIndex !== null) {
                    updateLayerInfo(currentSelectedIndex, {
                      ...layerInfos.get(
                        layersToMintOrder[currentSelectedIndex].id.toString()
                      ),
                      seriesId: seriesIdValue
                    })
                  }
                }}
                name="checkedA"
              />
            }
            label="Use same series Id for all layers"
          />
        </FormGroup>
      </>
    )
  }

  const constructCollapsableDrawerContent = (
    <CollapsableTransferListView
      bottomListId={LIST_ID_LAYERS_TO_MINT_ITEMS}
      topListTitle={'ALL LAYERS:'}
      bottomListTitle={'LAYERS TO MINT:'}
      topListItems={allLayers}
      bottomListItems={layersToMintOrder}
      showLoadingPlaceholder={loadingPreviews}
      constructLoadingPlaceholderListItem={() => <LoadingPlaceholderListItem />}
      constructTopListItem={(layer, index) =>
        constructLayerListItem(layer, index, true, true)
      }
      constructBottomListItem={(layer, index) => (
        <LayerListItemWithSizeCheck
          layer={layer}
          name={
            sameNameForAllLayers
              ? masterLayerInfo.name
              : getNameOrDefault(layer)
          }
          number={
            autoGenerateNumberForAllLayers
              ? index + 1
              : getNumberOrDefault(layer)
          }
          seriesId={
            sameSeriesIdForAllLayers
              ? masterLayerInfo.seriesId
              : getSeriesIdOrDefault(layer)
          }
          primaryText={
            sameNameForAllLayers
              ? masterLayerInfo.name
              : getNameOrDefault(layer)
          }
          secondaryText1={`Number: ${
            autoGenerateNumberForAllLayers
              ? index + 1
              : getNumberOrDefault(layer)
          }`}
          secondaryText2={`Series Id: ${
            sameSeriesIdForAllLayers
              ? masterLayerInfo.seriesId
              : getSeriesIdOrDefault(layer)
          }`}
          index={index}
          key={layer.id.toString()}
          id={index}
          onCheckboxClicked={handleToggle}
          onListItemClick={handleListItemClick}
          onMintButtonClicked={async layer => {
            if (currentLayerMinting != null) {
              notification.error(
                'Please wait for the minting transaction to finish before minting another layer'
              )
              return
            }
            setCurrentLayerMinting(layer)
            updateLayerMintingState(layer, LAYER_STATE_MINTING)
            const name = sameNameForAllLayers
              ? masterLayerInfo.name
              : getNameOrDefault(layer)
            const number = autoGenerateNumberForAllLayers
              ? index + 1
              : getNumberOrDefault(layer)
            const seriesId = sameSeriesIdForAllLayers
              ? masterLayerInfo.seriesId
              : getSeriesIdOrDefault(layer)

            let detail
            try {
              detail = await getTransactionDataForLayersUsecase.execute({
                layerId: layer.id
              })
              const shouldShowWarning =
                detail.blockchainPixelData.length +
                  detail.blockchainPixelGroupData.length +
                  detail.blockchainPixelGroupIndexData.length +
                  detail.blockchainTransparentPixelGroupData.length +
                  detail.blockchainTransparentPixelGroupIndexData.length +
                  detail.colourIndexData.length +
                  2 >=
                DATA_SIZE_WARNING_THRESHOLD
              if (shouldShowWarning) {
                setCurrentLayerMinting(null)
                updateLayerMintingState(layer, LAYER_STATE_NOT_MINTED)
                notification.info(
                  'Data too large for 1 transaction - consider dividing the image or reducing the size'
                )
                return
              }
            } catch (error) {
              console.error(error)
              setCurrentLayerMinting(null)
              updateLayerMintingState(layer, LAYER_STATE_NOT_MINTED)
              notification.error(
                'Too many colours - please reduce image to 256 colours '
              )
              return
            }

            try {
              await setPixelsOnMurAllAndNotify(detail, name, number, seriesId)
              setCurrentLayerMinting(null)
              updateLayerMintingState(layer, LAYER_STATE_MINTED)
            } catch (error) {
              console.error(error)
              setCurrentLayerMinting(null)
              updateLayerMintingState(layer, LAYER_STATE_NOT_MINTED)
            }
          }}
          checked={checked.indexOf(layer) !== -1}
          minted={layerMintStates.get(layer.id) === LAYER_STATE_MINTED}
          loading={layerMintStates.get(layer.id) === LAYER_STATE_MINTING}
          selected={
            currentSelectedIndex !== null && currentSelectedIndex === index
          }
          previewImage={
            loadingPreviews
              ? TRANSPARENT_IMAGE_DATA_URL
              : layerPreviews.get(layer.id).croppedBase64PngString
          }
        />
      )}
      onSelectedToBottomClicked={handleMoveCheckedToDisplay}
      selectedToBottomDisabled={allLayersChecked.length === 0}
      onSelectedToTopClicked={handleMoveCheckedToAll}
      selectedToTopDisabled={layersToMintChecked.length === 0}
      onSelectAllTopListClick={handleSelectAllTop}
      onDeselectAllTopListClick={handleDeselectAllTop}
      onSelectAllBottomListClick={handleSelectAllBottom}
      onDeselectAllBottomListClick={handleDeselectAllBottom}
      onloadMoreTopListItems={() => {}}
      onBottomListDragEnd={onDragEnd}
    />
  )

  const updateLayerMintingState = (layer, newState) => {
    setLayerMintStates(new Map(layerMintStates.set(layer.id, newState)))
  }
  const updateLayerInfo = (index, layerInfo) => {
    setLayerInfos(
      new Map(layerInfos.set(layersToMintOrder[index].id.toString(), layerInfo))
    )
  }

  const getNameOrDefault = layer => {
    return (
      (layerInfos.get(layer.id.toString()) &&
        layerInfos.get(layer.id.toString()).name) ||
      ''
    )
  }
  const getNumberOrDefault = layer => {
    return (
      (layerInfos.get(layer.id.toString()) &&
        layerInfos.get(layer.id.toString()).number) ||
      0
    )
  }
  const getSeriesIdOrDefault = layer => {
    return (
      (layerInfos.get(layer.id.toString()) &&
        layerInfos.get(layer.id.toString()).seriesId) ||
      0
    )
  }

  const constructLayerListItem = (
    layer,
    index,
    disableSelect = false,
    disableDrag = false
  ) => {
    return (
      <LayerListItem
        layer={layer}
        primaryText={
          sameNameForAllLayers
            ? masterLayerInfo.name
            : disableSelect
            ? layer.name
            : getNameOrDefault(layer)
        }
        secondaryText1={
          disableDrag
            ? null
            : `Number: ${
                autoGenerateNumberForAllLayers
                  ? index + 1
                  : getNumberOrDefault(layer)
              }`
        }
        secondaryText2={
          disableDrag
            ? null
            : `Series Id: ${
                sameSeriesIdForAllLayers
                  ? masterLayerInfo.seriesId
                  : getSeriesIdOrDefault(layer)
              }`
        }
        index={index}
        key={layer.id.toString()}
        id={index}
        disableSelect={disableSelect}
        disableDrag={disableDrag}
        onCheckboxClicked={handleToggle}
        onListItemClick={handleListItemClick}
        onMintButtonClicked={
          disableSelect
            ? null
            : async layer => {
                if (currentLayerMinting != null) {
                  notification.error(
                    'Please wait for the minting transaction to finish before minting another layer'
                  )
                  return
                }
                setCurrentLayerMinting(layer)
                updateLayerMintingState(layer, LAYER_STATE_MINTING)
                const name = sameNameForAllLayers
                  ? masterLayerInfo.name
                  : getNameOrDefault(layer)
                const number = autoGenerateNumberForAllLayers
                  ? index + 1
                  : getNumberOrDefault(layer)
                const seriesId = sameSeriesIdForAllLayers
                  ? masterLayerInfo.seriesId
                  : getSeriesIdOrDefault(layer)

                let detail
                try {
                  detail = await getTransactionDataForLayersUsecase.execute({
                    layerId: layer.id
                  })
                  const shouldShowWarning =
                    detail.blockchainPixelData.length +
                      detail.blockchainPixelGroupData.length +
                      detail.blockchainPixelGroupIndexData.length +
                      detail.blockchainTransparentPixelGroupData.length +
                      detail.blockchainTransparentPixelGroupIndexData.length +
                      detail.colourIndexData.length +
                      2 >=
                    DATA_SIZE_WARNING_THRESHOLD
                  if (shouldShowWarning) {
                    setCurrentLayerMinting(null)
                    updateLayerMintingState(layer, LAYER_STATE_NOT_MINTED)
                    notification.info(
                      'Data too large for 1 transaction - consider dividing the image or reducing the size'
                    )
                    return
                  }
                } catch (error) {
                  console.error(error)
                  setCurrentLayerMinting(null)
                  updateLayerMintingState(layer, LAYER_STATE_NOT_MINTED)
                  notification.error(
                    'Too many colours - please reduce image to 256 colours '
                  )
                  return
                }

                try {
                  await setPixelsOnMurAllAndNotify(
                    detail,
                    name,
                    number,
                    seriesId
                  )
                  setCurrentLayerMinting(null)
                  updateLayerMintingState(layer, LAYER_STATE_MINTED)
                } catch (error) {
                  console.error(error)
                  setCurrentLayerMinting(null)
                  updateLayerMintingState(layer, LAYER_STATE_NOT_MINTED)
                }
              }
        }
        checked={checked.indexOf(layer) !== -1}
        minted={layerMintStates.get(layer.id) === LAYER_STATE_MINTED}
        loading={layerMintStates.get(layer.id) === LAYER_STATE_MINTING}
        selected={
          !disableSelect &&
          currentSelectedIndex !== null &&
          currentSelectedIndex === index
        }
        previewImage={
          loadingPreviews
            ? TRANSPARENT_IMAGE_DATA_URL
            : layerPreviews.get(layer.id).croppedBase64PngString
        }
      />
    )
  }

  return (
    <StyledImageViewerDialog
      open={open}
      onClose={props.onCancelClicked}
      maxWidth
      fullScreen
      withCloseButton
      dialogTitle={'Mint images onto MurAll'}
      dialogImageContent={
        <div
          className={clsx(
            classes.absoluteFill,
            classes.dialogMedia,
            'pixelated-image'
          )}
        >
          <Grid
            style={{ width: '100%', minHeight: '100%', overflow: 'hidden' }}
            container
            spacing={0}
            direction="row"
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
              {constructCollapsableDrawerContent}
            </Grid>
            <Grid item xs={6} sm={7} md={8} lg={9} xl={9}>
              {layersToMintOrder.length === 0 ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px'
                  }}
                >
                  <Typography
                    style={{ fontFamily: 'Roboto', fontWeight: 300 }}
                    variant="h4"
                    component="h4"
                    color="textPrimary"
                  >
                    Select the layers to mint from the list on the left
                  </Typography>
                  <Typography
                    style={{ fontFamily: 'Roboto', fontWeight: 300 }}
                    variant="h6"
                    component="h6"
                    color="textSecondary"
                    align="center"
                  >
                    Select from the "ALL LAYERS" list on the left, then use the
                    arrows to move them into the "LAYERS TO MINT" list below to
                    preview them here.
                    <br></br>
                    <br></br>
                    Once you have selected the layers you want to mint, click
                    the individual "MINT" buttons on each layer in the list to
                    mint them one by one.
                    <br></br>
                    <br></br>
                    After all the layers are minted you can close the dialog
                    <br />
                    <br />
                    For a more in depth tutorial checkout{' '}
                    <Link
                      href="https://knowledgebase.murall.art/tutorials/minting/3-minting"
                      target="_blank"
                      underline="hover"
                    >
                      {'our knowledgebase'}
                    </Link>
                  </Typography>
                </div>
              ) : loadingPreviews ? (
                <LoadingSpinner />
              ) : (
                <SvgLayersImageViewer
                  layerPreview={layer => {
                    const layerPreview = layerPreviews.get(layer.id)

                    return layerPreview != null
                      ? layerPreview
                      : DUMMY_PREVIEW_DATA
                  }}
                  layers={layersToMintOrder}
                  selectedIndex={currentSelectedIndex}
                  animationDuration={DEFAULT_FADE_DURATION_MILLIS / 1000.0}
                  style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'auto'
                  }}
                />
              )}
            </Grid>
          </Grid>
        </div>
      }
      dialogInformation={
        <div className={clsx(classes.dialogInformationRoot)}>
          <CustomAccordion
            accordionSummaryStyle={{
              flexDirection: 'row'
            }}
            expandedIndex={layersToMintOrder.length > 0 ? 1 : false}
            accordionStyle={{
              width: 'auto',
              background: 'transparent',
              border: 'none'
            }}
            items={[
              {
                title: (
                  <>
                    <Typography
                      className={classes.typography}
                      variant="body1"
                      component="p"
                      align="justify"
                      style={{
                        flex: 1,
                        alignSelf: 'flex-start',
                        marginBottom: '16px'
                      }}
                    >
                      {currentSelectedIndex !== null
                        ? `Editing information for layer ${currentSelectedIndex}`
                        : 'Select a layer to edit its information below, or apply the same information to all layers'}
                    </Typography>
                  </>
                ),
                content: (
                  <div
                    style={{
                      display: 'inline-flex',
                      width: '100%',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                      justifyContent: 'stretch'
                    }}
                  >
                    {constructNftInputFields()}
                  </div>
                )
              }
            ]}
          />
        </div>
      }
      dialogActions={
        <>
          <Typography
            className={classes.typography}
            variant="body1"
            component="p"
            align="justify"
            style={{
              flex: 1,
              alignSelf: 'flex-start',
              marginRight: '16px',
              marginLeft: '16px'
            }}
          >
            Click the individual 'MINT' buttons on each layer to mint them one
            by one. By clicking 'MINT' you agree to the{' '}
            <Link
              component={RouterLink}
              to="/terms"
              color="secondary"
              underline="hover"
            >
              Terms and Conditions
            </Link>
            .
          </Typography>
        </>
      }
    />
  )
}

const LayerListItemWithSizeCheck = ({
  layer,
  name,
  number,
  seriesId,
  index,
  primaryText,
  secondaryText1,
  secondaryText2,
  onCheckboxClicked,
  onListItemClick,
  onMintButtonClicked,
  checked,
  minted,
  loading,
  selected,
  previewImage
}) => {
  const [isTooLarge, setIsTooLarge] = useState(false)

  const {
    tooManyColours,
    loading: checkingSize,
    withinLimit
  } = useLayerDataSizeCheckForSetPixels({
    layer,
    name,
    number,
    seriesId,
    immediate: true
  })

  useEffect(() => {
    if (!checkingSize) {
      setIsTooLarge(!withinLimit)
    }
  }, [checkingSize, withinLimit])

  return (
    <LayerListItem
      layer={layer}
      index={index}
      primaryText={primaryText}
      secondaryText1={secondaryText1}
      secondaryText2={secondaryText2}
      key={layer.id.toString()}
      id={index}
      onCheckboxClicked={onCheckboxClicked}
      onListItemClick={onListItemClick}
      onMintButtonClicked={onMintButtonClicked}
      checked={checked}
      minted={minted}
      loading={checkingSize || loading}
      selected={selected}
      previewImage={previewImage}
      warningText={
        isTooLarge
          ? 'Image too large'
          : tooManyColours
          ? 'Too many colours'
          : null
      }
      warningTooltipText={
        isTooLarge
          ? 'Data too large for 1 transaction - consider dividing the image or reducing the size'
          : tooManyColours
          ? 'Too many colours - please reduce image to 256 colours'
          : null
      }
    />
  )
}
