import { useEffect, useState } from 'react'
import { SupportedChain } from '../lib/constants'
import { useEthersProviderForChainId } from './use-ethers-provider-for-chain-id'

export const useEnsReverse = (ensName) => {
  const ethersProvider = useEthersProviderForChainId(SupportedChain.Ethereum)
  const [resolvedAddress, setResolvedAddress] = useState(null)

  useEffect(() => {
    setResolvedAddress(null)
    if (!ensName) return
    // return if the ens name does not end in .eth (case insensitive)
    if (!ensName.match(/\.eth$/i)) return

    let isCanceled = false // Flag to keep track of whether the effect has been cleaned up

    ethersProvider
      .resolveName(ensName)
      .then((address) => {
        if (!isCanceled) {
          // Only proceed if the effect hasn't been cleaned up
          console.log('ENS resolved to:', address)
          setResolvedAddress(address)
        }
      })
      .catch((error) => {
        console.error(error)
        setResolvedAddress(null)
      })

    return () => {
      isCanceled = true // Set the flag to true on cleanup
    }
  }, [ensName, ethersProvider])

  return resolvedAddress
}
