import { createSlice } from '@reduxjs/toolkit'
import { MURALL_WALL_ID } from '../../lib/constants'

export const initialState = {
  id: MURALL_WALL_ID.EVOLV3
}

const wallSlice = createSlice({
  name: 'wall',
  initialState,
  reducers: {
    setWall: (state, action) => {
      return { id: action.payload }
    }
  }
})

export const { setWall } = wallSlice.actions

export default wallSlice.reducer
