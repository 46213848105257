import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import {
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Box
} from '@mui/material'
import { getDateInFuture } from '../../../lib/datetime'
import { setHideDrawWelcome } from '../../../state/slices/drawWelcome'
import { useDispatch } from 'react-redux'
import StyledBanner from '../../../components/common/banner/StyledBanner'
import { useTheme } from '@mui/material/styles'

export default function WelcomeDrawBanner ({
  onClose,
  open,
  onPositiveButtonClick
}) {
  const theme = useTheme()
  const [cookies, setCookie] = useCookies(['hideDrawWelcome'])
  const [remember, setRemember] = useState(false)
  const toggleRemember = event => setRemember(event.target.checked)
  const dispatch = useDispatch()

  const onCloseClickInternal = () => {
    if (remember) {
      setCookie('hideDrawWelcome', true, {
        path: '/',
        expires: getDateInFuture(180)
      })
    }
    dispatch(setHideDrawWelcome(true))
    onClose && onClose()
  }

  return (
    <StyledBanner
      open={open}
      withCloseButton
      onClose={() => {
        onCloseClickInternal()
      }}
      actions={
        <>
          <Button
            variant={'contained'}
            color='secondary'
            onClick={() => {
              onPositiveButtonClick && onPositiveButtonClick()
            }}
          >
            Find out more
          </Button>
          <FormControlLabel
            style={{ flex: 1, paddingLeft: '1em' }}
            control={
              <Checkbox
                checked={remember}
                onChange={toggleRemember}
                name='rememberChoice'
              />
            }
            label={`Don't show next time`}
          />
        </>
      }
      sx={{
        position: 'relative',
        width: '100%'
      }}
    >
      <Box
        tabIndex={-1}
        sx={{
          p: 2
        }}
      >
        <Typography noWrap color='textPrimary' gutterBottom>
          Welcome to MurAll!
        </Typography>
        <Typography
          style={{ fontFamily: 'Roboto', fontWeight: 300 }}
          color='textSecondary'
          align='justify'
        >
          First Time? Start here to learn how to create your first artwork on
          MurAll.
        </Typography>
      </Box>
    </StyledBanner>
  )
}
