import { useState, useEffect, useCallback } from 'react'
import { getAuctionMarketplaceContractAddress } from '../../js/modules/blockchain/datasource/ContractAddressDataSource.js'
import { useAuctionMarketplaceDataSource } from '../use-auction-marketplace-datasource.js'
import { useErc20DataSource } from '../use-erc20.js'
import { useActiveWeb3React } from '../web3.js'
import notification from '../../lib/notification.js'

export const usePurchaseWonAuctionForId = (
  id,
  tokenAddress,
  tokenSymbol,
  bidAmount,
  bidAmountFormatted,
  name,
  immediate = false
) => {
  const [loading, setLoading] = useState(false)

  const { account, chainId } = useActiveWeb3React()
  const { purchaseAuctionItem } = useAuctionMarketplaceDataSource()
  const {
    approveTokenTransfer,
    isTokenTransferApprovedForAmount,
    maxAllowance
  } = useErc20DataSource(tokenAddress, chainId)
  const apply = useCallback(async () => {
    try {
      setLoading(true)
      const checkAllowancePromise = isTokenTransferApprovedForAmount(
        tokenAddress,
        getAuctionMarketplaceContractAddress(),
        bidAmount
      )

      try {
        const isAllowed = await notification.promise(checkAllowancePromise, {
          loading: `Checking ${tokenSymbol} token allowance`,
          success: `Successfully bid ${bidAmountFormatted} for ${name} - good luck!`,
          error: error => `Error while checking allowance ${error.name}`
        })
        if (!isAllowed) {
          const allowTransferPromise = approveTokenTransfer(
            account,
            getAuctionMarketplaceContractAddress(),
            maxAllowance()
          )
          await notification.promise(allowTransferPromise, {
            loading: `Requesting permission to transfer your ${tokenSymbol} to continue`,
            success: `You can now purchase ${name}!`,
            error: error => {
              if (error.message && error.message.indexOf('User denied') != -1)
                return 'You rejected the transaction!'
              return `Sorry, the transaction failed: ${error.name}`
            }
          })
        }
        const purchasePromise = purchaseAuctionItem(id, account``)

        try {
          await notification.promise(purchasePromise, {
            loading: `Purchasing  ${name} for ${bidAmountFormatted}`,
            success: `Successfully purchased ${name}!`,
            error: error => {
              if (error.message && error.message.indexOf('User denied') != -1)
                return 'You rejected the transaction!'
              return `Sorry, the transaction failed: ${error.name}`
            }
          })
        } catch (error) {
          console.error(error)
        }
      } catch (error) {
        console.error(error)
      }

      setLoading(false)
      return true
    } catch (error) {
      console.error(error)
      setLoading(false)
      return
    }
  }, [account])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, loading }
}

export default usePurchaseWonAuctionForId
