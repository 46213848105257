import React, { useState } from 'react'

import clsx from 'clsx'
import Skeleton from '@mui/material/Skeleton'
import _ from 'underscore'

import Helper_class from '../../libs/helpers'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './../../../css/layout.css'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText
    // border: 'solid',
    // borderColor: theme.palette.secondary.main,
    // borderWidth: '1px'
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  selectedBackground: {
    backgroundColor: theme.palette.primary.dark
    // backgroundColor: 'transparent'
  },
  accordian: {
    flex: 1,
    width: '100%',
    transition: 'all 300ms'
  },
  accordianDetails: {
    // paddingTop: '12px'
    // paddingLeft: 0,
    // paddingRight: 0,
    // paddingBottom: 0
    maxHeight: '205px',
    overflow: 'auto'
  },
  accordianDetailsContents: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    maxHeight: '205px'
  },
  imgContainer: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.dark
  },
  auctionImg: {
    borderRadius: 10,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    aspectRatio: '2/1'
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 500
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  emptyViewContainer: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    //backgroundColor: theme.palette.primary.dark,
    backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  menuIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212100,
            #21212199,
            #212121B3)`
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}))

const Accordion = withStyles(theme => ({
  root: {
    // border: 'solid',
    // borderColor: theme.palette.secondary.main,
    // borderWidth: '1px',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
}))(MuiAccordion)

const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
}))(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)

export default function AuctionItemNftLoadingPlaceholderCard (props) {
  const classes = useStyles()
  const Helper = new Helper_class()

  const [expanded, setExpanded] = useState('id1')

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const createAccordianForSection = id => {
    return (
      <Accordion
        className={clsx(
          classes.accordian,
          classes.transparent
          // { [classes.transparent]: expanded !== id },
          // { [classes.selectedBackground]: expanded === id }
        )}
        expanded={expanded === id}
        onChange={handleChange(id)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Skeleton variant='text' width={'100%'} />
        </AccordionSummary>
        <AccordionDetails
          className={clsx(classes.selectedBackground, classes.accordianDetails)}
          scroll={'paper'}
        >
          <Skeleton variant='text' width={'100%'} />
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <React.Fragment>
      <Card elevation={10} className={clsx(classes.imgContainer)}>
        <Skeleton
          variant="rectangular"
          className={clsx(classes.auctionImg, 'pixelated-image')}
        />
      </Card>

      <Card
        className={classes.root}
        elevation={10}
        style={{ marginTop: '24px', marginBottom: '24px', padding: '0px' }}
      >
        <CardContent
          style={{
            padding: '0px',
            width: '100%',
            maxWidth: '100%'
          }}
        >
          {createAccordianForSection('id1')}
          {createAccordianForSection('id2')}
          {createAccordianForSection('id3')}
          {createAccordianForSection('id4')}
        </CardContent>
      </Card>
    </React.Fragment>
  )
}
