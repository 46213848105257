import {
  createOffscreenCanvas,
  cropImage,
  isLittleEndian,
  rgb565HexToRgba,
  getImageCropDataFromCanvasContext,
  disableCanvasSmooth
} from '../../libs/appUtils'
import _ from 'underscore'
import Web3 from 'web3'

import Worker from '../../workers/blockchain/parseNftDataToCanvas.worker'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  DESCRIPTION_PLACEHOLDER,
  getWallIdForContractAddress
} from '../../../lib/constants'
import fetchImage from '../../../lib/actions/fetch-image'

const emptyHex4 = '0000'

/**
 * Mapper for Nft data
 *
 * @author TheKeiron
 */

export const processNftTokenUriMetadata = async (
  contractAddress,
  metadata,
  chainId = 1,
  processImages = false
) => {
  let base64PngString, croppedBase64PngString
  const coords = metadata.dimensions.coordinates
  if (processImages) {
    try {
      const offScreenCanvas = createOffscreenCanvas(CANVAS_WIDTH, CANVAS_HEIGHT)
      const ctx = offScreenCanvas.getContext('2d')
      disableCanvasSmooth(ctx)

      const img = await fetchImage(metadata.image)
      ctx.drawImage(img, coords.x, coords.y)

      base64PngString = offScreenCanvas.toDataURL()
      cropImage(
        ctx,
        coords.x,
        coords.y,
        metadata.dimensions.width,
        metadata.dimensions.height
      )
      croppedBase64PngString = offScreenCanvas.toDataURL()
    } catch (e) {
      console.error(e)
      base64PngString = metadata.image
      croppedBase64PngString = metadata.image
    }
  } else {
    base64PngString = metadata.image
    croppedBase64PngString = metadata.image
  }
  const wallId = getWallIdForContractAddress(contractAddress)

  const positionInfo = {
    start: { x: coords.x, y: coords.y },
    end: {
      x: coords.x + metadata.dimensions.width,
      y: coords.y + metadata.dimensions.height
    },
    width: CANVAS_WIDTH,
    height: CANVAS_HEIGHT,
    croppedWidth: metadata.dimensions.width,
    croppedHeight: metadata.dimensions.height
  }
  return constructNftInformationObject({
    wallId,
    contractAddress,
    tokenId: metadata.id.toString(),
    croppedBase64PngString,
    fullBase64PngString: base64PngString,
    positionInformation: positionInfo,
    artist: metadata.artist,
    name: metadata.name,
    number: metadata.number,
    seriesId: metadata.seriesId,
    hasAlpha: true,
    chainId
  })
}

export const processNftData = async (
  contractAddress,
  tokenId,
  artist,
  colorIndexData,
  pixelData,
  pixelGroups,
  pixelGroupIndexes,
  transparentPixelGroupData,
  transparentPixelGroupIndexData,
  nameMetadata,
  otherMetadata,
  complete,
  completionStatus = '',
  chainId = 1
) => {
  const offScreenCanvas = createOffscreenCanvas(CANVAS_WIDTH, CANVAS_HEIGHT)
  const ctx = offScreenCanvas.getContext('2d')
  disableCanvasSmooth(ctx)
  const name = processNameMetadata(nameMetadata)
  const metadata = processOtherMetadata(otherMetadata)
  const colorIndex = processColorIndexData(colorIndexData)
  const imageData = ctx.getImageData(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
  const buf = await drawNftDataToCanvasBuffer(
    imageData.data.buffer,
    colorIndexData,
    pixelData,
    pixelGroups,
    pixelGroupIndexes,
    transparentPixelGroupData,
    transparentPixelGroupIndexData,
    metadata.hasAlpha
  )

  const arr = new Uint8ClampedArray(buf)
  let processedImageData
  try {
    processedImageData = new ImageData(arr, imageData.width, imageData.height)
  } catch (e) {
    processedImageData = ctx.createImageData(imageData.width, imageData.height)
    processedImageData.data.set(arr)
  }

  ctx.putImageData(processedImageData, 0, 0)
  const base64PngString = offScreenCanvas.toDataURL()

  const positionInfo = await getImageCropDataFromCanvasContext(ctx)
  cropImage(
    ctx,
    positionInfo.start.x,
    positionInfo.start.y,
    positionInfo.croppedWidth,
    positionInfo.croppedHeight
  )
  const croppedBase64PngString = offScreenCanvas.toDataURL()
  const wallId = getWallIdForContractAddress(contractAddress)
  return constructNftInformationObject({
    contractAddress,
    tokenId,
    colorIndex,
    pixelData,
    pixelGroups,
    pixelGroupIndexes,
    transparentPixelGroups: transparentPixelGroupData,
    transparentPixelGroupIndexes: transparentPixelGroupIndexData,
    croppedBase64PngString: croppedBase64PngString,
    fullBase64PngString: base64PngString,
    complete,
    positionInformation: positionInfo,
    artist,
    name,
    number: metadata.number,
    seriesId: metadata.seriesId,
    hasAlpha: metadata.hasAlpha,
    completionStatus,
    chainId,
    wallId
  })
}

export const drawNftDataToCanvasBuffer = (
  canvasBuffer,
  colorIndexData,
  pixelData,
  pixelGroups,
  pixelGroupIndexes,
  transparentPixelGroupData,
  transparentPixelGroupIndexData,
  hasAlpha,
  colorIndexPreprocessed = false
) => {
  const encoder = new TextEncoder()
  const isDeviceLittleEndian = isLittleEndian()
  return new Promise(function (resolve, reject) {
    const encodedColorIndex = encoder.encode(JSON.stringify(colorIndexData))
    const encodedIndividualPixels = encoder.encode(JSON.stringify(pixelData))
    const encodedPixelGroups = encoder.encode(JSON.stringify(pixelGroups))
    const encodedGroupIndexes = encoder.encode(
      JSON.stringify(pixelGroupIndexes)
    )
    const encodedTransparentPixelGroups = encoder.encode(
      JSON.stringify(transparentPixelGroupData)
    )
    const encodedTransparentGroupIndexes = encoder.encode(
      JSON.stringify(transparentPixelGroupIndexData)
    )

    const worker = new Worker()

    worker.onmessage = function (msg) {
      worker.terminate()
      resolve(msg.data.canvasBuffer)
    }

    const buffers = [
      canvasBuffer,
      encodedColorIndex.buffer,
      encodedIndividualPixels.buffer,
      encodedPixelGroups.buffer,
      encodedGroupIndexes.buffer,
      encodedTransparentPixelGroups.buffer,
      encodedTransparentGroupIndexes.buffer
    ]
    const message = {
      canvasBuffer,
      encodedColorIndex,
      encodedIndividualPixels,
      encodedPixelGroups,
      encodedGroupIndexes,
      encodedTransparentPixelGroups,
      encodedTransparentGroupIndexes,
      isLittleEndian: isDeviceLittleEndian,
      buffers,
      hasAlpha,
      colorIndexPreprocessed
    }

    worker.postMessage(message, buffers)
  })
}

export const processNameMetadata = nameMetadata => {
  return Web3.utils.hexToAscii(Web3.utils.numberToHex(nameMetadata))
}

export const processOtherMetadata = otherMetadata => {
  const otherMetadataHex = Web3.utils
    .padLeft(Web3.utils.numberToHex(otherMetadata), 64)
    .slice(2)

  // first 3 bytes are number
  const number = parseInt('0x' + otherMetadataHex.slice(0, 6))
  // next 3 bytes are seriesId
  const seriesId = parseInt('0x' + otherMetadataHex.slice(6, 12))
  // last bit is alpha channel flag
  const hasAlpha = otherMetadataHex.slice(otherMetadataHex.length - 1) !== '0'

  return {
    number,
    seriesId,
    hasAlpha
  }
}

const processColorIndexData = (colorIndexes, rgbaFormat = false) => {
  let currentColourGroup
  const colourList = []

  for (var i = 0, n = colorIndexes.length; i < n; i++) {
    currentColourGroup = Web3.utils
      .padLeft(Web3.utils.numberToHex(colorIndexes[i]), 64)
      .slice(2)

    if (i === colorIndexes.length - 1) {
      // we're at the last index, find any empty slots and remove them too
      while (currentColourGroup.slice(0, 4) == emptyHex4) {
        currentColourGroup = currentColourGroup.slice(4)
      }
    }
    for (var j = 0, k = currentColourGroup.length / 4; j < k; j++) {
      const colour = currentColourGroup.slice(4 * j, 4 * (j + 1))
      if (rgbaFormat) {
        colour = rgb565HexToRgba('0x'.concat(colour))
      }
      colourList.push(colour)
    }
  }

  return colourList
}

export const constructNftInformationObject = ({
  contractAddress = '',
  tokenId = 0,
  colorIndex = [],
  pixelData = [],
  pixelGroups = [],
  pixelGroupIndexes = [],
  transparentPixelGroups = [],
  transparentPixelGroupIndexes = [],
  croppedBase64PngString = '',
  fullBase64PngString = '',
  complete = false,
  positionInformation = {
    start: { x: 0, y: 0 },
    end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
    width: CANVAS_WIDTH,
    height: CANVAS_HEIGHT
  },
  artist = '',
  name = '',
  number = 0,
  seriesId = 0,
  hasAlpha = false,
  completionStatus = 0,
  chainId = 1,
  wallId = null
}) => {
  return mapInformationToNftObject({
    contractAddress: contractAddress,
    tokenId: tokenId,
    image: croppedBase64PngString,
    positionInformation: positionInformation,
    creator: artist,
    name: name,
    description: `#${number} of Series ${seriesId}`,
    chainId,
    wallId,
    attributes: [
      { trait_type: 'Name', value: name },
      {
        trait_type: 'Artist',
        value: artist
      },
      { trait_type: 'Filled', value: complete ? 'Filled' : 'Not filled' },
      { trait_type: 'Number', value: number, display_type: 'number' },
      { trait_type: 'Series ID', value: seriesId, display_type: 'number' }
      // { trait_type: 'Pixels', value: 350, display_type: 'number' },
      // { trait_type: '$PAINT burned', value: 175, display_type: 'number' }
    ],
    additionalInformation: {
      artist: artist,
      croppedBase64PngString: croppedBase64PngString,
      fullBase64PngString: fullBase64PngString,
      complete: complete,
      name: name,
      number: number,
      seriesId: seriesId,
      hasAlpha: hasAlpha,
      completionStatus: completionStatus,
      description: `#${number} of Series ${seriesId}`,
      colorIndex,
      pixelData,
      pixelGroups,
      pixelGroupIndexes,
      transparentPixelGroups,
      transparentPixelGroupIndexes,
      wallId
    }
  })
}

export const mapInformationToNftObject = ({
  contractAddress = '',
  tokenId = 0,
  positionInformation = {
    start: { x: 0, y: 0 },
    end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
    width: CANVAS_WIDTH,
    height: CANVAS_HEIGHT
  },
  creator = '',
  name = '',
  description = DESCRIPTION_PLACEHOLDER,
  attributes = [],
  image = '',
  animation_url = '',
  chainId = 1,
  additionalInformation = {}
}) => {
  return {
    contractAddress: contractAddress,
    tokenId: tokenId,
    positionInformation: positionInformation,
    creator: creator,
    name: name,
    description: !description?.length ? DESCRIPTION_PLACEHOLDER : description,
    attributes: attributes,
    image: image,
    animation_url: animation_url,
    chainId: chainId,
    ...additionalInformation
  }
}
