import StyledDialog from '../../uicomponents/styled_dialog'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
const useStyles = makeStyles(theme => ({
  typography: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  }
}))

export default function PoapPromoDialog (props) {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const handleClaimClick = async (address, tokenId) => {
    window.open(
      `https://poap.website/murallxgraffitikingscharityauction2021`,
      '_blank'
    )
  }

  return (
    <StyledDialog
      maxWidth={'sm'}
      open={props.open}
      onClose={props.onClose}
      withCloseButton={true}
      dialogTitle={''}
      dialogContent={() => (
        <Grid
          container
          spacing={2}
          direction='column'
          justifyContent='space-between'
          alignItems='stretch'
          alignContent='center'
        >
          <Grid item xs={12}>
            <img
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto',
                height: 'auto',
                margin: 'auto',
                aspectRatio: '1/1',
                objectFit: 'contain',
                overflow: 'hidden'
              }}
              src={'images/GraffitiKings_POAP.png'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={clsx(classes.typographyLight)}
              variant='h5'
              component='h5'
              color='textPrimary'
              align='center'
            >
              Bid confirmed! Don't forget to claim your POAP if you haven't
              already!
            </Typography>
          </Grid>
          <Grid item xs={12} flexGrow={1}>
            <Button
              size='large'
              color='secondary'
              onClick={handleClaimClick}
              variant={'contained'}
              style={{ marginBottom: '12px', marginTop: '24px', width: '100%' }}
            >
              Claim poap
            </Button>
          </Grid>
        </Grid>
      )}
      positiveButtonTitle={'Claim poap'}
      onPositiveButtonClick={handleClaimClick}
    />
  )
}
