import React from 'react'
import { Typography, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PeekCollapseView from './peek-collapse-view'
const BG =
  'linear-gradient(30deg, #000000 0%, #040504 16%, #080b08 33%, #0c0f0c 50%, #101310 66%, #121612 83%, #151915 100%)'

export default function StyledInfoBox({
  maxWidth,
  title,
  titleVariant = 'body2',
  titleProps = {},
  subtitle,
  subtitleProps = {},
  subtitleVariant = 'h5',
  subtitleMaxLength = -1,
  children,
  subtitleWrapperSx = {},
  sx
}) {
  const theme = useTheme()
  const gradientForTheme = `linear-gradient( 135deg, ${theme.palette.secondary.main} 10%, ${theme.palette.primary.main} 100%)`
  const infoBackground = BG + ' padding-box,' + gradientForTheme + ' border-box'
  const peekRequired = subtitle && subtitleMaxLength > 0 && subtitle.length > subtitleMaxLength
  return (
    <Box
      {...(maxWidth && { maxWidth })}
      sx={{
        position: 'relative',
        display: 'block',
        borderRadius: '5px',
        background: infoBackground,
        border: '2px solid transparent',
        py: { xs: '12px', sm: '24px' },
        ...(peekRequired && {
          pb: { xs: '6px', sm: '12px' }
        }),
        px: { xs: '12px' },
        boxShadow: theme.shadows[4],
        ...sx
      }}
    >
      {title && (
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 500
          }}
          variant={titleVariant}
          color={theme.palette.secondary.main}
          align={'center'}
          {...titleProps}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Box
          sx={{
            mt: { xs: '6px', sm: '12px' },
            position: 'relative',
            display: 'flex',
            flexDirection: peekRequired ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            ...subtitleWrapperSx
          }}
        >
          {peekRequired ? (
            <PeekCollapseView>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: 300
                }}
                variant={subtitleVariant}
                color={'inherit'}
                align={'center'}
                {...subtitleProps}
              >
                {subtitle}
              </Typography>
            </PeekCollapseView>
          ) : (
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 300,
                textOverflow: 'ellipsis'
              }}
              variant={subtitleVariant}
              noWrap
              color={'inherit'}
              align={'center'}
              {...subtitleProps}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      {children}
    </Box>
  )
}
