import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  emptyViewContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  }
}))

export default function ErrorMessageView ({
  title,
  description,
  header,
  children
}) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.emptyViewContainer, classes.absoluteFill)}>
      {header}
      <Typography
        align='center'
        className={clsx(classes.typographyLight)}
        variant='h4'
        component='h4'
        color='textPrimary'
      >
        {title}
      </Typography>
      <Typography
        className={clsx(classes.typographyLight)}
        variant='h6'
        component='h6'
        color='textSecondary'
        align='center'
      >
        {description}
      </Typography>
      {children}
    </div>
  )
}
