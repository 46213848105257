import React from 'react'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../../../lib/constants'
import FramesView from './frame-view'

export default function FramesWrapperView ({
  tokenId,
  width = CANVAS_WIDTH,
  height = CANVAS_HEIGHT,
  children,
  style,
  noWrapper = false
}) {
  if (tokenId == null || tokenId == undefined) {
    if (noWrapper) {
      return <>{children}</>
    }

    return (
      <div
        style={{
          ...style
        }}
      >
        {children}
      </div>
    )
  }

  return (
    <FramesView
      style={style}
      tokenId={tokenId}
      width={width}
      height={height}
      children={children}
    />
  )
}
