import React, { useEffect, useState } from 'react'
import { Typography, Box, Button } from '@mui/material'
import styled from 'styled-components'
import PulseBorder from './pulse-border'
import { Stack } from '../../../node_modules/@mui/material/index'

const Border = styled(PulseBorder)`
  height: 3px;
  position: relative;
`

const IndeterminateLoadingMessage = ({ sx, message = 'Loading...' }) => {
  return (
    <Stack
      direction='column'
      spacing={1}
      justifyContent='center'
      alignItems='center'
      sx={sx}
    >
      <Typography variant='h5' sx={{ textAlign: 'center' }}>
        {message}
      </Typography>

      <Border />
    </Stack>
  )
}

export default IndeterminateLoadingMessage
