import Helper_class from '../../libs/helpers'

var instance = null

class File_generateWithAI_class {
  constructor () {
    //singleton
    if (instance) {
      return instance
    }
    instance = this

    this.Helper = new Helper_class()
  }
  generate () {
    this.Helper.dispatchCustomEvent('drawingContainer', 'generateWithAI', {})
  }
}

export default File_generateWithAI_class
