import makeStyles from '@mui/styles/makeStyles'

const styles = {
  desktop: () => ({
    gridRow: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'row',
      flex: 1
      // paddingBottom: '16px'
    },
    rangeStart: {
      flex: 1
    },
    rangeEnd: {
      flex: 1,
      marginLeft: '24px'
    }
  }),
  mobile: () => ({
    gridRow: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'row',
      flex: 1
      // paddingBottom: '16px'
    },
    rangeStart: {
      flex: 1
    },
    rangeEnd: {
      flex: 1,
      marginLeft: '24px'
    }
  })
}

export default (isMobile) => {
  return makeStyles(isMobile ? styles.mobile : styles.desktop)
}
