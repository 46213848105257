import config from './../../../config.js'
import IERC721 from './../../../../contracts/common/IERC721.json'
import Web3 from 'web3'
import { isTransactionSuccess } from '../../../libs/appUtils.js'

const async = require('async')
/**
 * Source for MurAll marketplace data
 *
 * @author TheKeiron
 */
class Marketplace_data_source_class {
  constructor () {
    this.MarketplaceSaleItem = class {
      constructor (
        saleItemId,
        tokenId,
        erc721TokenContractAddress,
        priceInEth,
        priceInWei,
        seller,
        feeInEth,
        feeInWei
      ) {
        this.saleItemId = saleItemId
        this.tokenId = tokenId
        this.contractAddress = erc721TokenContractAddress
        this.price = priceInEth
        this.priceInWei = priceInWei
        this.seller = seller
        this.marketplaceFee = feeInEth
        this.marketplaceFeeInWei = feeInWei
      }
    }
  }

  getSellerBalanceForAccount () {
    return this.getSellerBalanceForSellerAddress(config.account)
  }

  getSellerBalanceForSellerAddress (sellerAddress) {
    return config.marketplaceContract.methods
      .getSellerBalance(sellerAddress)
      .call()
  }

  withdrawFullSellerBalanceForAccount () {
    return config.marketplaceContract.methods
      .withdrawSellerBalance()
      .send({ from: config.account })
  }

  getTotalSalesItemsCount () {
    return config.marketplaceContract.methods.totalSaleItems().call()
  }

  getTotalSalesItemsCountForSellerAddress (sellerAddress) {
    return config.marketplaceContract.methods
      .totalSaleItemsForSellerAddress(sellerAddress)
      .call()
  }

  getTotalSalesItemsCountForContractAddress (contractAddress) {
    return config.marketplaceContract.methods
      .totalSaleItemsForContractAddress(contractAddress)
      .call()
  }

  getAllSalesItemIds () {
    return config.marketplaceContract.methods.getAllSaleItemIds().call()
  }

  fetchAllSalesItemsForAccount () {
    return this.fetchAllSalesItemsForSellerAddress(config.account)
  }

  async fetchAllSalesItems () {
    const salesItemIds = await this.getAllSalesItemIds()
    return this.getSalesItemsForIds(salesItemIds)
  }

  async fetchAllSalesItemsForSellerAddress (sellerAddress) {
    const salesItemIds = await this.getSalesItemIdsForSellerAddress(
      sellerAddress
    )
    return this.getSalesItemsForIds(salesItemIds)
  }

  async fetchAllSalesItemsForContractAddress (contractAddress) {
    const salesItemIds = await this.getSalesItemIdsForContractAddress(
      contractAddress
    )
    return this.getSalesItemsForIds(salesItemIds)
  }

  getSalesItemIdsForContractAddress (contractAddress) {
    return config.marketplaceContract.methods
      .getItemsForSaleByContractAddress(contractAddress)
      .call()
  }

  getSalesItemIdsForSellerAddress (sellerAddress) {
    return config.marketplaceContract.methods
      .getItemsForSaleBySellerAddress(sellerAddress)
      .call()
  }

  async getSalesItemsForIds (saleItemIds) {
    var _this = this
    return async.map(saleItemIds, async function (saleItemId, callback) {
      const saleItem = await config.marketplaceContract.methods
        .getSaleItem(saleItemId)
        .call()
      const priceInEther = Web3.utils.fromWei(saleItem.price, 'ether')
      const feeInEther = Web3.utils.fromWei(saleItem.marketplaceFee, 'ether')
      callback(
        null,
        new _this.MarketplaceSaleItem(
          saleItemId,
          saleItem.tokenId,
          saleItem.contractAddress,
          priceInEther,
          saleItem.price,
          saleItem.owner,
          feeInEther,
          saleItem.marketplaceFee
        )
      )
    })
  }

  isContractAtAddressERC721Compliant (erc721TokenContractAddress) {
    return config.marketplaceContract.methods
      .isAddressERC721(erc721TokenContractAddress)
      .call()
  }

  isTokenTransferApproved (erc721TokenContractAddress) {
    const erc721Instance = new config.web3.eth.Contract(
      IERC721.abi,
      erc721TokenContractAddress
    )
    return erc721Instance.methods
      .isApprovedForAll(config.account, config.marketplaceContractAddress)
      .call()
  }

  approveTokenTransfer (erc721TokenContractAddress) {
    var _this = this
    const erc721Instance = new config.web3.eth.Contract(
      IERC721.abi,
      erc721TokenContractAddress
    )
    return new Promise(function (resolve, reject) {
      erc721Instance.methods
        .setApprovalForAll(config.marketplaceContractAddress, true)
        .send({ from: config.account })
        .on('receipt', function (receipt) {
          if (isTransactionSuccess(receipt)) {
            resolve(receipt.events.ApprovalForAll)
          } else {
            reject('Transaction Failed')
          }
        })
        .on('error', function (error) {
          console.error(error)

          reject(error)
        })
    })
  }

  listItemForSale (tokenId, erc721TokenContractAddress, priceInEth) {
    const priceInWei = Web3.utils.toWei(priceInEth.toString(), 'ether')

    return new Promise(function (resolve, reject) {
      config.marketplaceContract.methods
        .listErc721ForSale(tokenId, erc721TokenContractAddress, priceInWei)
        .send({ from: config.account })
        .on('receipt', function (receipt) {
          if (isTransactionSuccess(receipt)) {
            resolve(receipt.events.ItemListed)
          } else {
            reject('Transaction Failed')
          }
        })
        .on('error', function (error) {
          console.error(error)

          reject(error)
        })
    })
  }

  withdrawSalesItemForId (saleItemId) {
    return new Promise(function (resolve, reject) {
      config.marketplaceContract.methods
        .withdrawSaleListing(saleItemId)
        .send({ from: config.account })
        .on('receipt', function (receipt) {
          if (isTransactionSuccess(receipt)) {
            resolve(receipt.events.ItemUnlisted)
          } else {
            reject('Transaction Failed')
          }
        })
        .on('error', function (error) {
          console.error(error)

          reject(error)
        })
    })
  }

  purchaseSaleItem (saleItemId, priceInWei, feeInWei) {
    return new Promise(function (resolve, reject) {
      config.marketplaceContract.methods
        .purchaseSaleItem(saleItemId)
        .send({
          from: config.account,
          value: Web3.utils.toBN(priceInWei).add(Web3.utils.toBN(feeInWei))
        })
        .on('receipt', function (receipt) {
          if (isTransactionSuccess(receipt)) {
            resolve(receipt.events.ItemSold)
          } else {
            reject('Transaction Failed')
          }
        })
        .on('error', function (error) {
          console.error(error)

          reject(error)
        })
    })
  }
}

export default Marketplace_data_source_class
