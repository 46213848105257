import React from 'react'
import Link from '@mui/material/Link'
import CustomAccordion from '../../../components/common/Accordion'
import SectionTitle from '../SectionTitle'

const SectionMontage = () => {
  return (
    <>
     <SectionTitle variant='h4' component='h4'>
        Montage system/NFTs
      </SectionTitle>
      <CustomAccordion
        items={[
          {
            title: 'What is Montage?',
            content: (
              <>
                Montage is a feature that enables you to package your individual
                on-chain MurAll NFT pieces into a single NFT image!
              </>
            )
          },
          {
            title: 'Why was the Montage system created?',
            content: (
              <>
                Due to the on-chain nature of MurAll NFTs, it creates a size
                limit to the images that can be fit into a single NFT. To
                overcome that people have minted larger works as a collection of
                NFTs, each containing a part of a larger image.
                <br />
                <br />
                Montage is a way to combine these pieces again into a single
                image. Since the artwork of a Montage is not stored on chain, it
                does not have a size limit. However, it is still as reliable
                since it can always be regenerated from the on-chain image data
                of MurAll NFTs.
              </>
            )
          },
          {
            title: 'How can I create a Montage of my MurAll NFTs?',
            content: (
              <>
                You can find a full tutorial of how to create a Montage{' '}
                <Link
                  href='https://knowledgebase.murall.art/tutorials/creating-montage'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  here on our knowledgebase
                </Link>
              </>
            )
          },
          {
            title: 'How many NFTs can be combined into a Montage NFT?',
            content: (
              <>
                You can combine a maximum of 50 MurAll NFTs into a single
                Montage.
              </>
            )
          },
          {
            title:
              'Can Montage NFTs be unpacked into its original individual MurAll NFTs?',
            content: (
              <>
                At the time of minting a Montage you have the choice to allow
                the Montage to be unpacked or not.
                <br />
                <br />
                If you choose the Montage to be unpackable, then the owner can
                choose to unpack it in the future. This means that the MurAll
                NFTs that were packed (sent to the Montage contract) will be
                sent to the current owner and the Montage NFT is burned.
                <br />
                <br />
                If the Montage is not unpackable, the individual MurAll NFTs
                that it contains can never be owned by someone again and the
                Montage NFT is not burnable.
              </>
            )
          },
          {
            title: 'What other features are included in the Montage NFT?',
            content: (
              <>
                Montage has a special feature that becomes available after
                minting: you can attach a unlockable!
                <br />
                <br />
                The unlockable is a link that only the current owner of the NFT
                can see and it can only be set by the creator when they own the
                NFT.
                <br />
                <br />
                The unlockable can be used by the creator to include special
                content that may not be possible otherwise (e.g original HD
                image, video content, extra data etc).
              </>
            )
          },
          {
            title: 'How do I add an unlockable to my minted Montage NFT?',
            content: (
              <>
                You can find a full tutorial of how to add an unlockable to a
                Montage{' '}
                <Link
                  href='https://knowledgebase.murall.art/tutorials/creating-montage#4.addinganunlockable'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  here on our knowledgebase
                </Link>
              </>
            )
          },
          {
            title: 'Do i need Paint to create a Montage?',
            content: (
              <>
                No Paint is needed to create a Montage, you only need to pay the
                transaction fee for minting.
              </>
            )
          }
        ]}
      />
    </>
  )
}

export default SectionMontage
