import { ethers } from 'ethers'
import { SupportedChain } from '../../lib/constants'
const WEBSOCKET_URL = {
  [SupportedChain.Ethereum]:
    'wss://eth-mainnet.ws.alchemyapi.io/v2/gcQg7HfeVdRNa--z3c9LqMIkAeWF1gjG',
  [SupportedChain.Goerli]:
    'wss://eth-goerli.ws.alchemyapi.io/v2/8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn',
  [SupportedChain.Polygon]:
    'wss://polygon-mainnet.g.alchemy.com/v2/PLh-zCcMAGuB2pHN3c1Lz1x_5EIW_cps',
  [SupportedChain.Mumbai]:
    'wss://polygon-mumbai.g.alchemy.com/v2/vfGuAfi-XIdXrB0QluxjUZaOC-J9CWaM'
}

const getEthersWebsocketProvider = (chainId = SupportedChain.Ethereum) => {
  const url = WEBSOCKET_URL[chainId]
  if (!url) throw new Error(`No websocket url for chainId ${chainId}`)
  const provider = new ethers.providers.WebSocketProvider(url)

  return provider
}

export default getEthersWebsocketProvider
