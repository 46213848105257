import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import LayerPreview from './LayerPreview'
import StyledImageViewerDialog from '../../../js/uicomponents/styled_image_viewer_dialog'

const useStyles = makeStyles(theme => ({
  dialogInformationRoot: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dialogMedia: {
    backgroundColor: theme.palette.primary.dark,
    objectFit: 'contain'
  }
}))

export default function LayerPreviewDialog ({
  open,
  onCancelClicked,
  withCloseButton,
  title,
  layer,
  maxWidth = 'sm',
  dialogActions,
  children
}) {
  const classes = useStyles()

  return (
    <StyledImageViewerDialog
      forceFullHeight={false}
      open={open}
      withCloseButton={withCloseButton}
      onClose={onCancelClicked}
      maxWidth={maxWidth}
      dialogTitle={title}
      dialogImageContent={
        <Box
          id='drawing-preview'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            aspectRatio: '2/1'
          }}
        >
          <Box
            sx={{
              aspectRatio: '2/1',
              width: '100%'
            }}
          >
            <LayerPreview
              layer={layer}
              style={{
                width: '100%',
                aspectRatio: '2/1',
                position: 'absolute'
              }}
              classname={clsx(classes.dialogMedia)}
            />
          </Box>
        </Box>
      }
      dialogInformation={
        <div className={clsx(classes.dialogInformationRoot)}>{children}</div>
      }
      {...(dialogActions && {
        dialogActions: dialogActions
      })}
    />
  )
}
