import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import Base_layers_class from '../../js/core/base-layers.js'
import config from '../../js/config.js'
import Base_gui_class from '../../js/core/base-gui.js'
import notification from '../../lib/notification.js'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  MURALL_WALL
} from '../../lib/constants.js'
import useGetCurrentStateForWall from '../history/use-get-current-state-for-wall.js'
import { createOffscreenCanvas, loadImage } from '../../js/libs/appUtils.js'

const TRANSPARENT_IMAGE_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

const createBlockchainLayerIfNotExists = async Base_layers => {
  if (
    !config.blockchainDataLayer ||
    !Base_layers.get_layer(config.blockchainDataLayer?.id)
  ) {
    var params = {
      type: 'image',
      name: 'MurAll blockchain data',
      data: TRANSPARENT_IMAGE_DATA_URL,
      x: 0,
      y: 0,
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      opacity: 100
    }

    //create the new layer from these params
    await Base_layers.insert(params, false)

    //grab the newly created layer as a canvas to populate with our blockchain
    canvas = Base_layers.convert_layer_to_canvas(null, true)
    config.blockchainDataLayer = config.layer
  }
}

const setClearCanvas = async (Base_layers, offscreenCanvas) => {
  await createBlockchainLayerIfNotExists(Base_layers)

  const image = await loadImage(TRANSPARENT_IMAGE_DATA_URL)
  offscreenCanvas.getContext('2d').drawImage(image, 0, 0)
  Base_layers.update_layer_image(offscreenCanvas, config.blockchainDataLayer.id)
  new Base_gui_class().GUI_layers.render_layers()
}

export const useUpdateLiveStateOnDrawingClient = () => {
  const terms = useSelector(state => state.terms)
  const [cookies] = useCookies(['agreedToTerms'])

  const [loadingObject, setLoadingObject] = useState(null)

  const agreedToTerms = !!cookies.agreedToTerms || terms.agreed
  const currentWallId = useSelector(state => state.wall.id)
  const wallInfo = MURALL_WALL[currentWallId]
  const {
    apply: applyCurrentState,
    currentState,
    loading: isLoadingStateInformation,
    error
  } = useGetCurrentStateForWall(currentWallId, false, false, false)

  useEffect(() => {
    if (agreedToTerms) {
      console.log('useUpdateLiveStateOnDrawingClient - fetching current state')
      applyCurrentState()
    }
  }, [applyCurrentState, currentWallId])

  useEffect(() => {
    console.log(
      'useUpdateLiveStateOnDrawingClient - currentState',
      currentState
    )
    console.log(
      'useUpdateLiveStateOnDrawingClient - isLoadingStateInformation',
      isLoadingStateInformation
    )
    if (isLoadingStateInformation) return
    if (loadingObject === currentState) return
    if (!currentState) {
      console.error(error)

      setClearCanvas(
        new Base_layers_class(),
        createOffscreenCanvas(CANVAS_WIDTH, CANVAS_HEIGHT)
      )
      return
    }
    setLoadingObject(currentState)
    const stateUpdatePromise = new Promise(async (resolve, reject) => {
      const Base_layers = new Base_layers_class()
      await createBlockchainLayerIfNotExists(Base_layers)
      const offscreenCanvas = createOffscreenCanvas(
        currentState.positionInformation.width,
        currentState.positionInformation.height
      )

      const ctx = offscreenCanvas.getContext('2d')
      loadImage(currentState.image)
        .then(image => {
          ctx.drawImage(image, 0, 0)
          Base_layers.update_layer_image(
            offscreenCanvas,
            config.blockchainDataLayer.id
          )
          new Base_gui_class().GUI_layers.render_layers()
          resolve(offscreenCanvas)
        })
        .catch(async error => {
          console.error(error)
          await setClearCanvas(Base_layers, ctx)
          reject(error)
        })
        .finally(() => {
          setLoadingObject(null)
        })
    })

    console.log(
      'useUpdateLiveStateOnDrawingClient - stateUpdatePromise',
      stateUpdatePromise
    )
    notification.promise(
      stateUpdatePromise,
      {
        loading: `Loading live MurAll state for ${wallInfo.name} to layer`,
        success: `Loaded ${wallInfo.name} live MurAll state to layer`,
        error: error => {
          return `Failed to load ${wallInfo.name} live MurAll state`
        }
      },
      { id: `useUpdateLiveStateOnDrawingClient-${wallInfo.name}` }
    )
  }, [currentState, isLoadingStateInformation])

  return { loading: isLoadingStateInformation || !!loadingObject }
}

export default useUpdateLiveStateOnDrawingClient
