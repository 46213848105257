import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import StyledImageViewerDialog from '../../../uicomponents/styled_image_viewer_dialog'
import useSmallScreenDetection from '../../../uicomponents/useSmallScreenDetection'
import './../../../../css/layout.css'
import Evolv3MetadataInputForm from './evolv3MetadataInputForm'
import ArrowButton from '../../../../components/common/buttons/arrow-button'

export default function Evolv3MintDetailsInputDialog ({
  open,
  mintData,
  onDataChanged,
  onCancelClicked,
  onMintClicked,
  children
}) {
  const smallScreen = useSmallScreenDetection()

  const [mintDataValid, setMintDataValid] = useState(mintData?.valid)

  useEffect(() => {
    setMintDataValid(mintData?.valid)
  }, [mintData])

  return (
    <StyledImageViewerDialog
      open={open}
      withCloseButton
      fullScreen={smallScreen}
      onClose={onCancelClicked}
      maxWidth={'md'}
      dialogTitle={'Mint onto MurAll Evolv3'}
      dialogImageContent={
        <Box
          id='metadata-input-dialog-content'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          <Evolv3MetadataInputForm
            onDataChange={data => {
              onDataChanged && onDataChanged(data)
            }}
            attributes={mintData?.attributes}
            description={mintData?.description}
            name={mintData?.name}
            selectedLayer={mintData?.selectedLayer}
          >
            {children}
            <ArrowButton
              onClick={() => {
                console.log('mintData', mintData)
                onMintClicked && onMintClicked(mintData)
              }}
              variant='contained'
              size='large'
              sx={{
                alignSelf: 'flex-end'
              }}
              arrowPosition='end'
              disabled={!mintDataValid}
            >
              Proceed to Mint
            </ArrowButton>
          </Evolv3MetadataInputForm>
        </Box>
      }
    />
  )
}
