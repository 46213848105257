import React from 'react'
import { Avatar, Typography } from '@mui/material'
import RoundedButton from './rounded-button'
import { colorForChain, logoForChain } from '../chainUiUtils'
import switchToNetwork from '../../../lib/actions/switch-network'
import { CHAIN_INFO } from '../../../lib/constants'
import { useActiveWeb3React } from '../../../hooks/web3'

export default function NetworkSwitchButton ({ targetChainId }) {
  const { library } = useActiveWeb3React()
  const targetChainName = CHAIN_INFO[targetChainId]?.label
  return (
    <RoundedButton
      variant='text'
      aria-label='close'
      sx={{
        color: 'inherit',
        fontSize: 'inherit',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }}
      endIcon={
        <Avatar
          sx={{
            width: 24,
            height: 24,
            padding: '3px',
            bgcolor: colorForChain(targetChainId)
          }}
          alt={targetChainName}
          src={logoForChain(targetChainId)}
        />
      }
      onClick={() => {
        switchToNetwork({ library, chainId: targetChainId })
      }}
    >
      <Typography variant='body1'>Switch to {targetChainName}</Typography>
    </RoundedButton>
  )
}
