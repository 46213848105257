import config from '../../../config.js'
import Base_layers_class from '../../../core/base-layers.js'
import {
  createOffscreenCanvas,
  cropImage,
  disableCanvasSmooth,
  getImageCropDataFromCanvasContext
} from '../../../libs/appUtils.js'

class Get_image_for_layer_usecase {
  constructor () {
    this.Base_layers = new Base_layers_class()
  }

  async execute (layer = config.layer) {
    const offScreenCanvas = createOffscreenCanvas(config.WIDTH, config.HEIGHT)
    const ctx = offScreenCanvas.getContext('2d', { willReadFrequently: true })
    disableCanvasSmooth(ctx)
    var videoCurrentTime = 0
    if (layer.type === 'video') {
      // get the video and fast forward to 10% of the video so there is a preview
      videoCurrentTime = layer.link.currentTime
      console.log(
        'videoCurrentTime',
        videoCurrentTime,
        layer.link,
        layer.link.duration
      )
      layer.link.currentTime = layer.link.duration * 0.1
      // wait for the seeked event to fire
      await new Promise(resolve => {
        layer.link.addEventListener('seeked', resolve, { once: true })
      })
    }
    this.Base_layers.render_object(ctx, layer)

    if (layer.type === 'video') {
      // reset the video to the original time
      layer.link.currentTime = videoCurrentTime
    }
    const fullBase64PngString = offScreenCanvas.toDataURL()
    const positionInformation = await getImageCropDataFromCanvasContext(ctx)
    cropImage(
      ctx,
      positionInformation.start.x,
      positionInformation.start.y,
      positionInformation.croppedWidth,
      positionInformation.croppedHeight
    )
    const croppedBase64PngString = offScreenCanvas.toDataURL()

    return { fullBase64PngString, croppedBase64PngString, positionInformation }
  }
}

export default Get_image_for_layer_usecase
