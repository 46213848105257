import { useState, useEffect, useCallback } from 'react'
import { useMurAllStateS3DataSourceForWall } from '../use-murall-s3-state-datasource-for-wall'

export const useGetTotalStateChanges = (wallId, immediate = true) => {
  const datasource = useMurAllStateS3DataSourceForWall(wallId)
  const getTotalStateChanges = datasource?.getTotalStateChanges || null
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [totalStateChanges, setTotalStateChanges] = useState(null)

  const apply = useCallback(async () => {
    if (!getTotalStateChanges) {
      return
    }
    try {
      setLoading(true)

      const totalStateChanges = await getTotalStateChanges?.()

      setTotalStateChanges(totalStateChanges)
      setLoading(false)
      return totalStateChanges
    } catch (error) {
      console.error(error)
      setTotalStateChanges(0)
      setError(error)
      setLoading(false)
      return
    }
  }, [wallId, getTotalStateChanges, datasource])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, loading, totalStateChanges, error }
}

export default useGetTotalStateChanges
