import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import _ from 'underscore'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box } from '@mui/material'

const DEFAULT_FADE_DURATION_SECONDS = 18
const DEFAULT_EASE = 'sine.inOut'
const CONFIG_DESKTOP = {
  SCALE_MAX: 1.5,
  SCALE_MID: 1.25,
  SCALE_MIN: 1,
  X_MAX: 20,
  X_MID: 10,
  X_MIN: 0,
  Y_MAX: 14,
  Y_MID: 7,
  Y_MIN: 0
}
const CONFIG_MOBILE = {
  SCALE_MAX: 1,
  SCALE_MID: 0.5,
  SCALE_MIN: 0,
  X_MAX: 20,
  X_MID: 10,
  X_MIN: 0,
  Y_MAX: 14,
  Y_MID: 7,
  Y_MIN: 0
}

const KenBurnsView = ({ className, animationVariant = false, children }) => {
  const loaderContainer = useRef(null)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const config = isMobile ? CONFIG_MOBILE : CONFIG_DESKTOP
  const timeline = gsap.timeline({ repeat: -1, yoyo: true })

  useEffect(() => {
    // Call handler right away so state gets updated with initial window size
    animationVariant ? startAnimationVariant() : startAnimation()

    return () => {
      timeline.clear()
    }
  }, []) // Empty array ensures that effect is only run on mount

  const startAnimation = () => {
    if (!_.isNull(loaderContainer.current)) {
      timeline
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MAX,
          yPercent: -config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MID,
          yPercent: config.Y_MID,
          scale: config.SCALE_MID,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MAX,
          yPercent: config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MAX,
          yPercent: -config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MAX,
          yPercent: -config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MAX,
          yPercent: config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MAX,
          yPercent: config.Y_MIN,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MAX,
          yPercent: config.Y_MIN,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MID,
          yPercent: -config.Y_MID,
          scale: config.SCALE_MID,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MAX,
          yPercent: -config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MIN,
          yPercent: config.Y_MIN,
          scale: config.SCALE_MIN,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
    }
  }
  const startAnimationVariant = () => {
    if (!_.isNull(loaderContainer.current)) {
      timeline
        .to(loaderContainer.current, {
          transformOrigin: 'center',

          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MAX,
          yPercent: config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',

          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MID,
          yPercent: -config.Y_MID,
          scale: config.SCALE_MID,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MAX,
          yPercent: -config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MAX,
          yPercent: config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MAX,
          yPercent: config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MAX,
          yPercent: -config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MAX,
          yPercent: -config.Y_MIN,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MAX,
          yPercent: -config.Y_MIN,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: config.X_MID,
          yPercent: config.Y_MID,
          scale: config.SCALE_MID,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MAX,
          yPercent: config.Y_MAX,
          scale: config.SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          transformOrigin: 'center',
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -config.X_MIN,
          yPercent: -config.Y_MIN,
          scale: config.SCALE_MIN,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
    }
  }
  return (
    <Box className={className} ref={loaderContainer}>
      {children}
    </Box>
  )
}

export default KenBurnsView
