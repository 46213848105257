import React from 'react'
import { useTheme, alpha } from '@mui/material/styles'
import { Grid, Typography, Divider, ListItemButton } from '@mui/material'
import Fade from '../../reveal/in-and-out/Fade'

export default function InfoGrid ({
  onItemClicked,
  sx,
  contents,
  dividers = true
}) {
  const theme = useTheme()
  const createGridTextRow = (key, value, shouldIncludeDivider, data) => (
    <React.Fragment>
      <Grid item xs={12}>
        {createTextRow(key, value, data)}
      </Grid>
      {shouldIncludeDivider && (
        <Grid item xs={12}>
          <Divider variant='middle' />
        </Grid>
      )}
    </React.Fragment>
  )
  const createTextRow = (key, value, data) => (
    <Fade top cascade>
      {onItemClicked ? (
        <>
          <ListItemButton
            focusRipple
            onClick={() => {
              onItemClicked(data)
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 'auto',
              width: '100%',
              justifyContent: 'space-between',
              paddingTop: '5px',
              paddingBottom: '5px'
            }}
          >
            {createTextRowContents(key, value)}
          </ListItemButton>
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 'auto',
              width: '100%',
              justifyContent: 'space-between',
              paddingTop: '5px',
              paddingBottom: '5px'
            }}
          >
            {createTextRowContents(key, value)}
          </div>
        </>
      )}
    </Fade>
  )

  const createTextRowContents = (key, value) => (
    <>
      <Typography
        sx={{
          color: theme.palette.primary.contrastText,
          textAlign: 'left',
          fontFamily: 'Roboto',
          fontWeight: 500,
          textShadow: '2px 2px 5px black'
        }}
        variant='body'
        component='p'
      >
        {key}:&emsp;
      </Typography>
      <Typography
        variant='body'
        component='p'
        sx={{
          color: theme.palette.primary.contrastText,
          textAlign: 'right',
          fontFamily: 'Roboto',
          fontWeight: 300,
          textShadow: '2px 2px 5px black'
        }}
      >
        {value}
      </Typography>
    </>
  )

  return (
    <Grid container spacing={2} px={1} pb={4} direction='column' sx={{ ...sx }}>
      {Object.keys(contents).map(function (keyName, keyIndex) {
        return createGridTextRow(
          keyName,
          contents[keyName].title || contents[keyName],
          dividers && keyIndex < Object.keys(contents).length - 1,
          contents[keyName].data
        )
      })}
    </Grid>
  )
}
