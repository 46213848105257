import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardMedia from '@mui/material/CardMedia'
import clsx from 'clsx'
import StyledProgress from '../../uicomponents/styled_progress'
import NftInformationDialog from '../../uicomponents/nft_information_dialog'
import Button from '@mui/material/Button'
import './../../../css/layout.css'
import { CardActions, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import {
  MURALL_WALL,
  MURALL_WALL_QUERY_PARAM,
  SupportedChain,
  getWallIdForContractAddress
} from '../../../lib/constants'
import EnsAddress from '../../../components/common/wallet/ensAddress'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px'
  },
  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%' // square
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },

  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212100,
            #21212199,
            #212121B3)`
  }
}))

export default function NftCard({ nftInformation, ens, key, sx, hideArtistAddress, address }) {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const tokenId = nftInformation.tokenId
  const croppedBase64Png = nftInformation.croppedBase64PngString
  const name = nftInformation.name
  const number = nftInformation.number
  const seriesId = nftInformation.seriesId
  const artist = nftInformation.artist

  const contractAddress = nftInformation?.contractAddress || address
  const wallId = nftInformation.wallId || getWallIdForContractAddress(contractAddress)
  const wallInfo = MURALL_WALL[wallId]

  //const completionStatus = nftInformation.completionStatus
  const completionStatus = 100
  const chainId = nftInformation.bridged ? SupportedChain.Polygon : nftInformation.chainId

  const constructNftInfoDialog = () => {
    return (
      <NftInformationDialog
        open={open}
        onClose={handleClose}
        showCompletionStatus
        nftInformation={nftInformation}
        contractAddress={contractAddress}
        wallId={wallId}
        chainId={chainId}
        withViewCropToggle
        hideInformation
      />
    )
  }

  return (
    <Card
      className={classes.root}
      elevation={0}
      sx={{
        ...sx
      }}
      key={key}
    >
      <CardHeader
        avatar={
          <Avatar variant="square" aria-label="tokenId" src={wallInfo.murallLogo} className={clsx(classes.avatar)} />
        }
        title={name}
        subheader={
          <>
            {number && seriesId && (
              <Typography
                align="left"
                variant={'subtitle2'}
                color="text.secondary"
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: 100
                }}
              >
                {`#${number} from Series ${seriesId}`}
              </Typography>
            )}
            {!hideArtistAddress && (
              <EnsAddress
                address={artist}
                ensOverride={ens}
                titleSx={{
                  fontFamily: 'Roboto',
                  fontWeight: 300,
                  fontStyle: 'normal'
                }}
                titleProps={{
                  color: 'textSecondary',
                  align: 'left',
                  variant: 'subtitle2'
                }}
                short
              />
            )}
          </>
        }
        titleTypographyProps={{ align: 'left' }}
        subheaderTypographyProps={{
          fontFamily: 'Roboto',
          align: 'left',
          fontWeight: 100
        }}
        style={{ padding: '12px' }}
      />

      <CardActionArea onClick={handleOpen}>
        <CardMedia
          className={clsx('pixelated-image', classes.media)}
          image={croppedBase64Png}
          title={'#' + tokenId + ': ' + name}
          alt={'#' + tokenId + 'has not been filled!'}
        >
          <div className={clsx(classes.overlay, classes.overlayBackground)}>
            <StyledProgress variant="determinate" value={completionStatus} />
          </div>
        </CardMedia>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          onClick={() => {
            history.push(`/murall/${MURALL_WALL_QUERY_PARAM[wallId]}/${tokenId}`)
          }}
        >
          More Details
        </Button>
      </CardActions>
      {constructNftInfoDialog()}
    </Card>
  )
}
