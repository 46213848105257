import React, { forwardRef, useState } from 'react'
import EmojiFab from './EmojiFab'
import EmojiPickerPopover from './EmojiPickerPopover'

function EmojiFabWithPicker({ sx, emoji, size = 'small', disabled = false, onEmojiSelect, loading, children }, ref) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleEmojiSelect = (emoji) => {
    console.log(emoji.native) // Handle the selected emoji here.
    onEmojiSelect && onEmojiSelect(emoji)
    handleClose()
  }
  const open = Boolean(anchorEl)

  return (
    <>
      <EmojiFab ref={ref} size={size} sx={sx} disabled={disabled} emoji={emoji} onClick={handleClick} loading={loading}>
        {children}
      </EmojiFab>
      <EmojiPickerPopover anchorEl={anchorEl} open={open} onEmojiSelect={handleEmojiSelect} onClose={handleClose} />
    </>
  )
}

export default forwardRef(EmojiFabWithPicker)
