import React, { forwardRef, useEffect, useState } from 'react'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import EmojiAvatarGroup from './EmojiAvatarGroup'
import { Grid } from '@mui/material'
import EmojiCountDisplay from './EmojiCountDisplay'

function EmojiAvatarGroupWithCounts(
  {
    emojiCounts,
    sx = {},
    avatarParams = { width: 30, height: 30, fontSize: 15 },
    anchorOrigin = {
      vertical: 'top',
      horizontal: 'center'
    },
    transformOrigin = {
      vertical: 'bottom',
      horizontal: 'left'
    },
    loading = false,
    logoStyle,
    maxShown = 3,
    children
  },
  ref
) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [emojis, setEmojis] = useState([])
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const [closePopoverTimeout, setClosePopoverTimeout] = useState(null)

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverCloseWithDelay = () => {
    const timeoutId = setTimeout(() => {
      setAnchorEl(null)
    }, 500)
    setClosePopoverTimeout(timeoutId)
  }

  const handlePopoverMouseEnter = () => {
    if (closePopoverTimeout) {
      clearTimeout(closePopoverTimeout)
      setClosePopoverTimeout(null)
    }
  }

  const handlePopoverMouseLeave = () => {
    handlePopoverCloseWithDelay()
  }

  useEffect(() => {
    const emojis = Object.keys(emojiCounts).map((key) => {
      return emojiCounts[key].emoji || emojiCounts[key]
    })
    setEmojis(emojis)
  }, [emojiCounts])

  const open = Boolean(anchorEl)
  const id = anchorEl ? 'emoji-popover' : undefined
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx
      }}
    >
      <EmojiAvatarGroup
        ref={ref}
        maxShown={maxShown}
        avatarParams={avatarParams}
        emojiList={emojis}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handlePopoverCloseWithDelay}
        loading={loading}
        {...(logoStyle && { logoStyle })}
      />
      {Object.keys(emojiCounts).length > 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onMouseEnter={handlePopoverMouseEnter}
          onMouseLeave={handlePopoverMouseLeave}
          PaperProps={{
            sx: {
              mb: 20,
              borderRadius: 5
            }
          }}
        >
          <Grid
            id="emoji-grid"
            container
            spacing={1.5}
            p={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              borderRadius: 10,
              maxHeight: '350px',
              maxWidth: '375px',
              flexWrap: 'wrap'
            }}
          >
            {Object.keys(emojiCounts).map(function (keyName, keyIndex) {
              return (
                <Grid item key={keyIndex}>
                  <EmojiCountDisplay
                    emoji={emojiCounts[keyName].emoji}
                    value={emojiCounts[keyName].count}
                    data={emojiCounts[keyName]}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Popover>
      )}
      {children}
    </Box>
  )
}

export default forwardRef(EmojiAvatarGroupWithCounts)
