import { useEffect, useRef } from 'react'

const useEffectDebounced = (effect, deps, delay) => {
  const lastExecution = useRef(Date.now())

  useEffect(() => {
    const handler = setTimeout(() => {
      const now = Date.now()
      const elapsedTime = now - lastExecution.current
      // Ensure the delay time has passed since the last execution
      if (elapsedTime >= delay) {
        effect()
        lastExecution.current = now
      }
    }, delay)

    // Clear the timeout when deps change or on component unmount
    return () => clearTimeout(handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay])
}

export default useEffectDebounced
