import { Collapse, Box, IconButton, Icon } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import React, { useState } from 'react'
const PeekCollapseView = ({
  children,
  collapsedSize = 100,
  peekFadeColor = '#000'
}) => {
  const [expandDescription, setExpandDescription] = useState(false)
  return (
    <>
      <Collapse in={expandDescription} collapsedSize={collapsedSize}>
        {children}
      </Collapse>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(!expandDescription && {
            backgroundImage: `linear-gradient(transparent, ${peekFadeColor})`
          })
        }}
      >
        <IconButton
          aria-label='delete'
          size='large'
          style={{
            marginTop: '-5px'
          }}
          onClick={() => {
            setExpandDescription(!expandDescription)
          }}
        >
          <Icon>
            <KeyboardArrowDownIcon
              style={{
                transition: 'all 300ms',
                transform: expandDescription ? 'rotate(180deg)' : ''
              }}
            />
          </Icon>
        </IconButton>
      </Box>
    </>
  )
}

export default PeekCollapseView
