import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect
} from 'react'
import clsx from 'clsx'
import useStyles from './styles'
import { updateCanvasWithVideo } from '../../../js/libs/appUtils'

/**
 * displays a currently video state in the history svg using a canvas
 */
export default function SvgHistoryVideoLocateState ({
  opacity,
  videoRef,
  x,
  y,
  width,
  height,
  animationDuration,
  filter
}) {
  const classes = useStyles({
    animationDuration
  })

  const glitchCanvasRef = useRef(null)
  const locateCanvasRef = useRef(null)
  const [shouldStop, setShouldStop] = useState(true)

  useLayoutEffect(() => {
    if (!shouldStop) {
      let timerId

      const animate = () => {
        if (videoRef.current) {
          locateCanvasRef.current &&
            updateCanvasWithVideo(
              locateCanvasRef.current,
              videoRef.current,
              width,
              height
            )
          glitchCanvasRef.current &&
            updateCanvasWithVideo(
              glitchCanvasRef.current,
              videoRef.current,
              width,
              height
            )
        }
        timerId = requestAnimationFrame(animate)
      }
      timerId = requestAnimationFrame(animate)
      return () => cancelAnimationFrame(timerId)
    }
  }, [shouldStop])

  useEffect(() => {
    if (videoRef.current) {
      // draw video frame to canvas on load
      locateCanvasRef.current &&
        updateCanvasWithVideo(
          locateCanvasRef.current,
          videoRef.current,
          width,
          height
        )
      glitchCanvasRef.current &&
        updateCanvasWithVideo(
          glitchCanvasRef.current,
          videoRef.current,
          width,
          height
        )
      // update canvas on every animation frame if playing
      if (videoRef.current.paused) {
        setShouldStop(true)
      } else {
        setShouldStop(false)
      }
    }
  }, [videoRef])

  return useMemo(() => {
    return (
      <g
        transform={`translate(${x} ${y})`}
        style={{
          opacity: opacity
        }}
      >
        <foreignObject
          height={height}
          width={width}
          className={clsx(classes.locate)}
        >
          <canvas
            ref={locateCanvasRef}
            className={clsx('pixelated-image')}
            height={height}
            width={width}
            style={{
              ...(filter && {
                filter: filter
              })
            }}
          />
        </foreignObject>
        <foreignObject height={height} width={width}>
          <canvas
            ref={glitchCanvasRef}
            className={clsx('pixelated-image', classes.glitch1)}
            height={height}
            width={width}
            style={{
              ...(filter && {
                filter: filter
              })
            }}
          />
        </foreignObject>
      </g>
    )
  }, [videoRef, x, y, width, height, animationDuration, filter, opacity])
}
