import * as React from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LoadingButton from '@mui/lab/LoadingButton'
export default function StyledStepper ({
  steps,
  loading,
  onStepButtonClick,
  activeStep,
  labelForStep,
  buttonLabelForStep,
  descriptionForStep,
  iconForStep,
  sx,
  children
}) {
  const theme = useTheme()

  const maxSteps = steps.length

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, ...sx }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default'
        }}
      >
        <Typography>{labelForStep(steps[activeStep])}</Typography>
      </Paper>
      <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
        {descriptionForStep(steps[activeStep])}
        {children}
      </Box>
      <MobileStepper
        variant='text'
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        nextButton={
          <LoadingButton
            size='small'
            onClick={() => onStepButtonClick(steps[activeStep])}
            startIcon={iconForStep(steps[activeStep])}
            loading={loading}
          >
            {buttonLabelForStep(steps[activeStep])}
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </LoadingButton>
        }
      />
    </Box>
  )
}
