import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import config from '../../js/config'
import { MURALL_WALL_ID } from '../../lib/constants'

const useUpdateDrawingClientTheme = () => {
  const currentWall = useSelector(state => state.wall)

  useEffect(() => {
    for (var i in config.themes) {
      document
        .querySelector('body')
        .classList.remove('theme-' + config.themes[i])
    }
    switch (currentWall.id) {
      case MURALL_WALL_ID.LAYER_1:
        console.log('changing to l1 theme')
        document
          .querySelector('body')
          .classList.add('theme-' + config.themes[0])
        break
      case MURALL_WALL_ID.LAYER_2:
        console.log('changing to l2 theme')
        document
          .querySelector('body')
          .classList.add('theme-' + config.themes[1])
        break
      case MURALL_WALL_ID.EVOLV3:
        console.log('changing to evolv3 theme')
        document
          .querySelector('body')
          .classList.add('theme-' + config.themes[2])
        break
      default:
        console.log('changing to l1 theme')
        document
          .querySelector('body')
          .classList.add('theme-' + config.themes[0])
        break
    }
  }, [currentWall.id]) // Empty array ensures that effect is only run on mount
}

export default useUpdateDrawingClientTheme
