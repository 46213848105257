import { createSlice } from '@reduxjs/toolkit'
import { MURALL_WALL_ID } from '../../lib/constants'

const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    paintedEvents: {
      revo1t: [],
      di2rupt: [],
      evolv3: []
    }
  },
  reducers: {
    addPaintedEvents: (state, action) => {
      const { targetWallId, events } = action.payload
      const targetWall =
        targetWallId === MURALL_WALL_ID.LAYER_1
          ? 'revo1t'
          : targetWallId === MURALL_WALL_ID.LAYER_2
          ? 'di2rupt'
          : 'evolv3'
      const uniqueEvents = events.filter(
        newEvent =>
          !state.paintedEvents[targetWall].some(existingEvent => existingEvent.id === newEvent.id)
      )
      
      state.paintedEvents[targetWall] = [
        ...state.paintedEvents[targetWall],
        ...uniqueEvents
      ]

      return state
    }
  }
})

export const { addPaintedEvents } = eventsSlice.actions

export default eventsSlice.reducer
