import React, { useContext, useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardMedia from '@mui/material/CardMedia'
import clsx from 'clsx'
import StyledProgress from '../../uicomponents/styled_progress'
import './../../../css/layout.css'
import { useHistory } from 'react-router-dom'
import Davatar from '@davatar/react'
import EnsAddress from '../../../components/common/wallet/ensAddress'
import { useActiveWeb3React } from '../../../hooks/web3'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px'
  },
  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%' // square
  },

  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },

  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212180,
            #21212199,
            #212121B3)`
  }
}))

export default function ArtistCard ({ sx, image, address }) {
  const classes = useStyles()
  const history = useHistory()

  const { library } = useActiveWeb3React()

  return (
    <Card
      className={classes.root}
      elevation={0}
      sx={{
        ...sx
      }}
    >
      <CardActionArea
        onClick={() => {
          history.push(`/artist/${address}`)
        }}
      >
        <CardMedia
          className={clsx('pixelated-image', classes.media)}
          image={image}
          title={address}
          alt={address}
        >
          <div className={clsx(classes.overlay, classes.overlayBackground)}>
            <CardHeader
              avatar={
                <Davatar
                  address={address}
                  size={24}
                  provider={library?.provider}
                />
              }
              title={
                <EnsAddress
                  address={address}
                  titleSx={{
                    fontFamily: 'Roboto',
                    fontWeight: 300,
                    fontStyle: 'normal',
                    textShadow: '2px 2px 5px black'
                  }}
                  titleProps={{
                    color: 'textPrimary',
                    align: 'left',
                    variant: 'h6'
                  }}
                  subtitleProps={{
                    color: 'textSecondary',
                    align: 'left',
                    variant: 'subtitle2'
                  }}
                  subtitleSx={{
                    fontFamily: 'Roboto',
                    fontWeight: 300,
                    fontStyle: 'normal',
                    textShadow: '2px 2px 5px black'
                  }}
                  showAddressSubtitle
                  short
                />
              }
              style={{ padding: '12px' }}
            />
            <StyledProgress variant='determinate' value={100} />
          </div>
        </CardMedia>
      </CardActionArea>
    </Card>
  )
}
