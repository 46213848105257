import React, { useEffect, useState } from 'react'
import config from '../../js/config'
import Get_image_for_layer_usecase from '../../js/modules/blockchain/usecase/get_image_for_layer_usecase'

function useLayers ({
  includeBlockchainDataLayer = true,
  includePreviews = true
}) {
  const [allLayers, setAllLayers] = useState([])
  const getImageForLayerUsecase = new Get_image_for_layer_usecase()

  const [layerPreviews, setLayerPreviews] = useState(new Map())
  const [loadingPreviews, setLoadingPreviews] = useState(false)

  useEffect(() => {
    function setAllLayersInitial () {
      setAllLayers(getLayers())
    }

    setAllLayersInitial()
  }, [config.layers])

  useEffect(() => {
    async function fetchLayerPreviews () {
      console.log('fetchLayerPreviews')
      setLoadingPreviews(true)
      const newLayerPreviews = new Map(layerPreviews)
      if (
        includeBlockchainDataLayer &&
        config.blockchainDataLayer &&
        !layerPreviews.has(config.blockchainDataLayer.id)
      ) {
        const layerPreview = await getImageForLayerUsecase.execute(
          config.blockchainDataLayer
        )
        newLayerPreviews.set(config.blockchainDataLayer.id, layerPreview)
      }
      for (const layer of allLayers) {
        if (!layerPreviews.has(layer.id)) {
          const layerPreview = await getImageForLayerUsecase.execute(layer)
          newLayerPreviews.set(layer.id, layerPreview)
        }
      }
      setLayerPreviews(newLayerPreviews)
      setLoadingPreviews(false)
    }
    if(!includePreviews){
      return
    }
    fetchLayerPreviews()
  }, [allLayers, includePreviews])

  const getLayers = () => {
    const layers = [...config.layers].sort(
      //sort function
      (a, b) => b.order - a.order
    )
    return includeBlockchainDataLayer
      ? layers
      : layers.filter(layer => layer.id !== config.blockchainDataLayer?.id)
  }

  return { layers: allLayers, loading: loadingPreviews, layerPreviews }
}

export default useLayers
