import React from 'react'
import { LoadingSpinner, SPRITE_SIZE } from './loading_spinner'
import Dialog from '@mui/material/Dialog'
import { Box } from '@mui/material'
export default function LoadingDialog ({ open, children }) {
  return (
    <Dialog
      fullScreen={true}
      disableEscapeKeyDown={true}
      open={open}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
    >
      <Box
        id='drawing-preview'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: SPRITE_SIZE + 'px',
            height: SPRITE_SIZE + 'px'
          }}
        >
          <LoadingSpinner />
        </Box>
        {children}
      </Box>
    </Dialog>
  )
}
