import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'underscore'
import Grid from '@mui/material/Grid'
import clsx from 'clsx'
import Collapse from '@mui/material/Collapse'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import FastForwardIcon from '@mui/icons-material/FastForward'
import PauseIcon from '@mui/icons-material/Pause'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import './../../../css/layout.css'
import TokenThumbnailSlider from './token_thumbnail_slider'
import CrtOverlay from '../../../components/common/images/crt-overlay'
import { Link as RouterLink } from 'react-router-dom'
import EnsAddress from '../../../components/common/wallet/ensAddress'
import StyledPlayControls from '../../../components/common/video/StyledPlayControls'
import { DESCRIPTION_PLACEHOLDER, MURALL_WALL_ID } from '../../../lib/constants'
import { Slider } from '@mui/material'
import { StateActions } from './actions/StateActions'
import NftEmojiAvatarGroup from '../../../components/common/emoji/NftEmojiAvatarGroup'
import NftEmoteButton from '../../../components/common/emoji/NftEmoteButton'
import { useActiveWeb3React } from '../../../hooks/web3'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    left: '0px',
    bottom: '0px',
    right: '0px',
    width: '100%',
    zIndex: '99'
  },
  playControlsContainer: {
    padding: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    borderTop: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px',
    width: '100%',
    zIndex: '99'
  },
  slider: {
    color: theme.palette.secondary.main
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  expandOpen: {
    transform: 'rotate(270deg)'
  },
  gridColumnContainer: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column'
  },
  gridRowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },

  backgroundImage: {
    margin: 0,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundClip: 'padding-box',
    backgroundPosition: 'center center',
    backgroundBlendMode: `normal, normal`
  },
  largeIcon: {
    height: 38,
    width: 38
  },
  controlsExpandIcon: {
    padding: '24px',
    alignSelf: 'center',
    position: 'absolute',
    top: 0,
    right: '50%',
    transform: 'translate(50%, calc(-100% - 8px))'
  }
}))

export default function HistoryNavigationControlsDesktop(props) {
  const { account } = useActiveWeb3React()
  const classes = useStyles()
  const [settingsExpanded, setSettingsExpanded] = useState(false)

  const handleSettingsExpandClick = (event) => {
    setSettingsExpanded(!settingsExpanded)
  }

  const PlayControls = (
    <div
      className={classes.gridRowContainer}
      style={{
        justifyContent: 'space-between'
      }}
    >
      <React.Fragment>
        <IconButton aria-label="previous" onClick={props.onSkipPreviousClicked} size="large">
          <SkipPreviousIcon />
        </IconButton>
        <IconButton aria-label="previous" onClick={props.onFastRewindClicked} size="large">
          <FastRewindIcon />
        </IconButton>
        <IconButton aria-label="play/pause" onClick={props.onPlayPauseClicked} size="large">
          {props.isPlaying ? (
            <PauseIcon className={clsx(classes.largeIcon)} />
          ) : (
            <PlayArrowIcon className={clsx(classes.largeIcon)} />
          )}
        </IconButton>
        <IconButton aria-label="next" onClick={props.onFastForwardClicked} size="large">
          <FastForwardIcon />
        </IconButton>
        <IconButton aria-label="next" onClick={props.onSkipNextClicked} size="large">
          <SkipNextIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: settingsExpanded
          })}
          onClick={handleSettingsExpandClick}
          aria-expanded={settingsExpanded}
          aria-label="settings"
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      </React.Fragment>
    </div>
  )

  const InfoView = (
    <Grid
      item
      xs={props.singleTokenMode ? 12 : 5}
      style={{
        padding: 0,
        margin: 0,
        marginRight: props.singleTokenMode ? '8px' : '-8px'
      }}
    >
      <div
        className={classes.gridColumnContainer}
        style={{
          padding: 0,
          margin: 0,
          backgroundClip: 'padding-box',
          overflow: 'hidden',
          height: '100%'
        }}
      >
        {!_.isUndefined(props.currentSelection) && (
          <div
            className={clsx('pixelated-image', classes.backgroundImage, classes.gridRowContainer)}
            style={{
              paddingRight: '24px',
              paddingTop: '24px',
              paddingBottom: '24px',
              margin: 0,
              height: '100%',
              backgroundImage: `radial-gradient(circle at top right,
                                                                    #26262B85,
                                                                    #26262B 75%),
                                                                    url(${
                                                                      props.image
                                                                        ? props.image
                                                                        : props.currentSelection.image
                                                                    })`
            }}
          >
            <div
              className={clsx(classes.gridColumnContainer)}
              style={{
                zIndex: '1',
                height: '100%',
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                alignSelf: 'flex-start'
              }}
            >
              <Typography
                component="h4"
                variant="h4"
                noWrap
                className={clsx(classes.typography, 'crt-text')}
                color={props.currentSelection.name ? 'textPrimary' : 'textSecondary'}
                sx={{
                  fontStyle: props.currentSelection.name ? 'normal' : 'italic'
                }}
              >
                {props.currentSelection.name ? props.currentSelection.name : 'Untitled'}
              </Typography>

              <Typography
                noWrap
                component="span"
                variant="h6"
                className={clsx(classes.typography, 'crt-text')}
                color="textPrimary"
                sx={{
                  fontStyle: props.currentSelection?.description !== DESCRIPTION_PLACEHOLDER ? 'normal' : 'italic'
                }}
              >
                {props.wallId === MURALL_WALL_ID.EVOLV3
                  ? props.currentSelection.description
                  : props.currentSelection.description ||
                    `${props.currentSelection.number}  of Series ${props.currentSelection.seriesId}`}
              </Typography>
              <br />
              <EnsAddress
                titleProps={{
                  component: RouterLink,
                  to: '/artist/' + props.currentSelection.artist,
                  underline: 'hover',
                  variant: 'subtitle2',
                  noWrap: true,
                  className: clsx(classes.typography, 'crt-text'),
                  color: 'textSecondary'
                }}
                address={props.currentSelection.artist}
              />
              <Typography
                component="span"
                gutterBottom
                variant="subtitle2"
                className={clsx(classes.typography, 'crt-text')}
                color="textPrimary"
              >
                Token #{props.currentSelection.tokenId}
              </Typography>

              {props.wallId === MURALL_WALL_ID.EVOLV3 && (
                <>
                  <NftEmojiAvatarGroup
                    chainId={props.currentSelection.chainId}
                    tokenContractAddress={props.currentSelection.contractAddress}
                    tokenId={props.currentSelection.tokenId}
                    logoStyle={{ position: 'relative' }}
                    sx={{ alignSelf: 'flex-start' }}
                    debounce={props.singleTokenMode ? 0 : 250}
                  />
                  <StyledPlayControls
                    sx={{
                      flex: 1,
                      width: '100%',
                      marginTop: '16px'
                    }}
                    disabled={!props.currentSelection.animation_url}
                    currentTime={props.videoCurrentTime}
                    duration={props.videoDuration}
                    isPlaying={!props.videoPaused}
                    onPlayPauseClicked={props.onVideoPlayPauseClicked}
                    onSeek={props.onVideoSeek}
                    onMuteClicked={props.onVideoMuteClicked}
                    isMuted={props.videoMuted}
                    volume={props.videoVolume}
                    onVolumeChange={props.onVideoVolumeChange}
                    showDuration
                  />
                </>
              )}
            </div>
            <StateActions
              style={{
                justifyContent: 'flex-end',
                position: 'absolute',
                bottom: props.wallId === MURALL_WALL_ID.EVOLV3 ? '72px' : '24px',
                right: '24px',
                zIndex: '1'
              }}
              onInfoButtonClicked={props.onInfoButtonClicked}
              onLocateButtonClicked={props.onLocateButtonClicked}
              onOpenDetailsClicked={props.onOpenDetailsClicked}
            />
          </div>
        )}
      </div>
    </Grid>
  )

  const Settings = (
    <div
      className={classes.gridRowContainer}
      style={{
        justifyContent: 'space-evenly',
        marginBottom: '12px'
      }}
    >
      <TextField
        style={{ flex: 1, marginRight: '24px' }}
        onChange={props.onFadeDurationChanged}
        id="standard-helperText"
        label="Transition duration"
        value={props.fadeDurationPlaying}
        helperText={'in milliseconds'}
        inputProps={{
          step: 50,
          type: 'number',
          'aria-labelledby': 'input-slider'
        }}
      />
      <TextField
        style={{ flex: 1 }}
        onChange={props.onDurationBetweenStatesChanged}
        id="standard-helperText"
        label="Delay between states"
        value={props.durationBetweenStates}
        helperText={'in milliseconds'}
        inputProps={{
          step: 50,
          type: 'number',
          'aria-labelledby': 'input-slider'
        }}
      />
    </div>
  )

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="show/hide play controls"
        onClick={props.onExpandNavigationControlsIconClicked}
        className={clsx(classes.controlsExpandIcon, classes.largeIcon)}
        size="medium"
      >
        {props.open ? (
          <ExpandMore className={clsx(classes.largeIcon)} />
        ) : (
          <ExpandLess className={clsx(classes.largeIcon)} />
        )}
      </IconButton>
      <Collapse in={props.open} mountOnEnter unmountOnExit>
        <Paper className={classes.playControlsContainer} elevation={0}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: '24px',
              paddingRight: props.singleTokenMode ? 0 : '24px',
              margin: 0
            }}
          >
            <CrtOverlay
              grain
              refresh
              style={{
                zIndex: '0',
                marginLeft: '-24px',
                position: 'absolute',
                width: '100%',
                height: '100%'
              }}
            />
            {!props.singleTokenMode && (
              <Grid item xs={7}>
                <div className={classes.gridColumnContainer}>
                  <Slider
                    className={classes.slider}
                    defaultValue={0}
                    value={props.currentSelectedState}
                    onChange={props.onSliderChange}
                    // startTokenId={props.startTokenId}
                    step={1}
                    min={props.rangeStart}
                    max={props.rangeEnd}
                  />

                  {PlayControls}
                  <Collapse in={settingsExpanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                    {Settings}
                  </Collapse>
                </div>
              </Grid>
            )}
            {InfoView}
          </Grid>
          {props.wallId === MURALL_WALL_ID.EVOLV3 && props.currentSelection && account && (
            <NftEmoteButton
              chainId={props.currentSelection.chainId}
              tokenContractAddress={props.currentSelection.contractAddress}
              tokenId={props.currentSelection.tokenId}
              size="medium"
              sx={{
                position: 'absolute',
                right: '24px',
                m: 0,
                top: '-24px',
                zIndex: 999
              }}
              debounce={props.singleTokenMode ? 0 : 500}
            />
          )}
        </Paper>
      </Collapse>
    </div>
  )
}
