import { useMemo } from 'react'
import {
  getSvgCodeFromFile,
  IPFS_GATEWAY_PINATA_MURALL,
  replaceIpfsIfNeeded
} from '../js/libs/appUtils'
import Web3 from 'web3'
import { useMurAllFramesContract } from './use-contract-for-chain'
import { getMurAllFramesContractAddress } from '../js/modules/blockchain/datasource/ContractAddressDataSource'
import { SupportedChain } from '../lib/constants'

export const useMurAllFramesDataSource = () => {
  const contract = useMurAllFramesContract(SupportedChain.Ethereum)

  return useMemo(() => {
    const getContractToUse = () => {
      return contract
    }

    const getEndpointForToken = (tokenId, chainId = 1) => {
      return `https://frames-api.murall.art/v1/tokens/${getMurAllFramesContractAddress()}/${tokenId}/metadata?chain=${chainId}`
    }

    const getTraits = tokenId => {
      return getContractToUse().methods.getTraits(tokenId).call()
    }

    const getFullTokenUriMetadata = async tokenId => {
      return new Promise(async function (resolve, reject) {
        try {
          const url = await getContractToUse().methods.tokenURI(tokenId).call()
          const res = await fetch(url)

          const tokenInfo = await res.json()

          resolve(tokenInfo)
        } catch (error) {
          console.error('error', error)
          reject(error)
        }
      })
    }

    const getBaseFrameSvg = tokenId => {
      return getFullTokenUriMetadata(tokenId)
        .then(data =>
          getSvgCodeFromFile(
            replaceIpfsIfNeeded(data.animation_url, IPFS_GATEWAY_PINATA_MURALL)
          )
        )
        .then(svgCode =>
          svgCode.substring(
            svgCode.indexOf('<svg'),
            svgCode.lastIndexOf('</svg>') + 6
          )
        )
        .then(svgCode =>
          svgCode.substring(
            svgCode.indexOf('<style'),
            svgCode.lastIndexOf('</defs>') + 7
          )
        )
    }

    return {
      getTraits,
      getBaseFrameSvg,

      getBalanceForAddress: address => {
        return getContractToUse().methods.balanceOf(address).call()
      },
      ownerOf: tokenId => {
        return getContractToUse().methods.ownerOf(tokenId).call()
      },
      name: () => {
        return getContractToUse().methods.name().call()
      },
      symbol: () => {
        return getContractToUse().methods.symbol().call()
      },
      totalSupply: () => {
        return getContractToUse().methods.totalSupply().call()
      },
      getOwnedTokenIdsInIndexRange: (ownerAddress, startIndex, endIndex) => {
        const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
          startIndex,
          endIndex + 1
        )

        return Promise.all(
          indexes.map(index =>
            getContractToUse()
              .methods.tokenOfOwnerByIndex(ownerAddress, index)
              .call()
          )
        )
      },
      getOwnedTokenIdAtIndex: (ownerAddress, index) => {
        return getContractToUse()
          .methods.tokenOfOwnerByIndex(ownerAddress, index)
          .call()
      },
      transferTokenId: (senderAddress, recipientAddress, tokenId) => {
        return getContractToUse()
          .methods.transferFrom(
            senderAddress,
            recipientAddress,
            Web3.utils.toBN(tokenId)
          )
          .send({
            from: senderAddress
          })
      },
      setApproveTokenTransfer: (ownerAddress, spenderAddress, approved) => {
        return getContractToUse()
          .methods.setApprovalForAll(spenderAddress, approved)
          .send({
            from: ownerAddress
          })
      },
      isTokenTransferApproved: (ownerAddress, spenderAddress) => {
        return getContractToUse()
          .methods.isApprovedForAll(ownerAddress, spenderAddress)
          .call()
      },
      getTokenUri: tokenId => {
        return getContractToUse().methods.tokenURI(tokenId).call()
      },
      getOffchainRemoteData: tokenId => {
        return new Promise(async function (resolve, reject) {
          try {
            const url = await getContractToUse()
              .methods.tokenURI(tokenId)
              .call()
            const res = await fetch(url)
            const tokenInfo = await res.json()

            resolve(tokenInfo)
          } catch (error) {
            reject(error)
          }
        })
      },
      getFullTokenUriMetadata
    }
  }, [contract])
}
