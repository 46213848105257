import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { LoadingSpinner as MurallSprite } from '../../js/uicomponents/loading_spinner'

const Loader = ({ show = true, useLogo, full }) => {
  const component = useLogo ? <MurallSprite /> : <CircularProgress />

  return (
    <Backdrop
      sx={{ zIndex: theme => (full ? theme.zIndex.drawer + 1 : null) }}
      open={show}
    >
      {component}
    </Backdrop>
  )
}

export default Loader
