import { useEffect, useState } from 'react'
import { useMurAllEvolv3Contract } from '../use-evolv3-contract.js'
import { SupportedChain } from '../../lib/constants.js'

const mapEvent = event => {
  return {
    blockNumber: event.blockNumber,
    from: event.args.from,
    to: event.args.to,
    tokenId: event.args.tokenId,
    id: `${event.blockHash}/${event.transactionIndex}/${event.logIndex}`
  }
}

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

const useListenForEvolv3Mints = ({
  filterAddress,
  includePastEvents = false,
  active = true
}) => {
  const [mintedNfts, setMintedNfts] = useState(undefined)

  const contractInstance = useMurAllEvolv3Contract(SupportedChain.Polygon, true)

  useEffect(() => {
    console.log('useListenForEvolv3Mints - mintedNfts updated', mintedNfts)
  }, [mintedNfts])

  useEffect(() => {
    if (!active) return
    if (!contractInstance) return
    //listen for Transfer events from zero address
    const filter = contractInstance.filters.Transfer(ZERO_ADDRESS)

    const listener = (...args) => {
      const event = args[args.length - 1]
      console.log('useListenForEvolv3Mints - listener received event', event)
      // only listen to Transfer events from zero address as that is the mint event
      if (event.args.from !== ZERO_ADDRESS) return
      if (
        filterAddress &&
        event.args.to.toString().toLowerCase() !==
          filterAddress.toString().toLowerCase()
      )
        return
      setMintedNfts(rs => [mapEvent(event), ...(rs || [])])
    }

    const subscribe = async () => {
      if (includePastEvents) {
        // TODO get start block from contract
        const past = await contractInstance.queryFilter(
          filter,
          34848456,
          'latest'
        )
        setMintedNfts((past.reverse() || []).map(mapEvent))
      }
      contractInstance.on(filter, listener)
    }

    subscribe()
    return () => contractInstance.off(filter, listener)
  }, [contractInstance, filterAddress, includePastEvents, active])

  return mintedNfts
}

export default useListenForEvolv3Mints
