import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './root-reducer'
import { API } from './api'
import { historyAPI } from './historyApi'
import { ImageApi } from './imageApi'
import { Evolv3Api } from './evolv3Api'
import { OpenseaApi } from './opensea'

export default function configureAppStore (preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false
      })
        .concat(API.middleware)
        .concat(historyAPI.middleware)
        .concat(OpenseaApi.middleware)
        .concat(ImageApi.middleware)
        .concat(Evolv3Api.middleware)
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./root-reducer.js', () =>
      store.replaceReducer(rootReducer)
    )
  }

  return store
}
