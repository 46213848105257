import React from 'react'
import './Loading.css'
import AILogo from '../../../../images/AI.png'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'

const Logo = styled.img`
  flex-shrink: 1;
  flex: 1;
  display: inline-block;
  min-height: 0;
  min-width: 0;
  margin: auto auto;
  object-fit: contain;
`

const Container = styled.div`
  flex: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
`

export default function AILoadingAnimation ({ style }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Container
      isMobile={isMobile}
      style={{
        ...style
      }}
    >
      <Logo className='aiLoader animated' src={AILogo} alt='AI logo' />
    </Container>
  )
}
