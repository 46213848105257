import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import ExternalLink from '../links/external'
import InternalLink from '../links/internal'
import PulseBorder from '../pulse-border'

import TwitterIcon from '@mui/icons-material/Twitter'
import DiscordLogo from '../../../../images/logos/discord.svg'
import MediumLogo from '../../../../images/logos/medium.svg'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon from '@mui/icons-material/Instagram'
import TelegramIcon from '@mui/icons-material/Telegram'

const MurAllSocials = ({ sx }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isLandscape = useMediaQuery('(orientation: landscape)')
  return (
    <>
      <SocialsWrapper
        sx={{
          ...sx
        }}
      >
        <ExternalLink
          href='https://twitter.com/MurAll_art'
          icon={<TwitterIcon mx={0.5} />}
        />
        <ExternalLink
          href='https://discord.murall.art'
          icon={<CustomSocialIcon src={DiscordLogo} alt='Discord' />}
        />
        <ExternalLink
          href='https://murall.medium.com'
          icon={<CustomSocialIcon src={MediumLogo} alt='Medium' />}
        />
        <ExternalLink
          href='https://www.instagram.com/murall.art/'
          icon={<InstagramIcon mx={0.5} />}
        />
        <ExternalLink
          href='https://www.youtube.com/channel/UCrEFkjpHY5YojV4px7kGcZQ'
          icon={<YouTubeIcon mx={0.5} />}
        />
        <ExternalLink
          href='https://t.me/MurALLart'
          icon={<TelegramIcon mx={0.5} />}
        />
      </SocialsWrapper>
    </>
  )
}

const CustomSocialIcon = ({ src, alt }) => {
  return (
    <Icon mr={1} ml={0.5}>
      <img src={src} alt={alt} />
    </Icon>
  )
}

const SocialsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`

const Border = styled(PulseBorder)`
  height: 1px;
  bottom: auto;
`

export default MurAllSocials
