import * as React from 'react'
import { Icon } from '@mui/material'

export default function EmojiIcon({ style, emoji }) {
  const svgStyle = {
    width: '100%',
    height: '100%',
    ...style
  }

  return (
    <Icon
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 35,
        textAlign: 'center',
        ...style
      }}
    >
      <svg style={svgStyle} preserveAspectRatio="xMinYMid meet" viewBox="0 0 24 24">
        <text
          x="50%"
          y="50%"
          dy=".35em" // Adjust this value to align vertically
          textAnchor="middle"
          style={{ fontSize: 16 }}
        >
          {emoji}
        </text>
      </svg>
    </Icon>
  )
}
