import * as React from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'
import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@mui/material'
import { StyledStepConnector } from '../../common/stepper/StyledStepConnector'
import { StyledStepIcon } from '../../common/stepper/StyledStepIcon'
import LoadingButton from '@mui/lab/LoadingButton'

export default function StyledStepper ({
  steps,
  loading,
  onStepButtonClick,
  activeStep,
  labelForStep,
  buttonLabelForStep,
  descriptionForStep,
  iconForStep,
  sx,
  children
}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <Stepper
      sx={sx}
      alternativeLabel
      activeStep={activeStep}
      connector={<StyledStepConnector />}
      orientation={isMobile ? 'vertical' : 'horizontal'}
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel
            StepIconComponent={StyledStepIcon}
            icon={iconForStep(step)}
          >
            {labelForStep(step)}
          </StepLabel>
          <StepContent
            sx={{
              backgroundColor: 'transparent',
              border: 'none',
              '.MuiStepContent-root': {
                paddingTop: '1em',
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
              }
            }}
          >
            <Typography
              variant='subtitle2'
              component='subtitle2'
              color='textSecondary'
              gutterBottom
            >
              {descriptionForStep(step)}
            </Typography>
            <Box sx={{ mb: 2 }}>
              {children}
              <div>
                <LoadingButton
                  variant='contained'
                  onClick={() => onStepButtonClick(step)}
                  sx={{ mt: 1, mr: 1 }}
                  loading={loading}
                >
                  {buttonLabelForStep(step)}
                </LoadingButton>
              </div>
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  )
}
