import React, { useRef, useCallback, useState, useEffect } from 'react'
import _ from 'underscore'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import TransparentDialog from './dialogs/transparent'
import useSmallScreenDetection from '../../js/uicomponents/useSmallScreenDetection'
import Confetti from 'react-confetti'
import { Box, Typography } from '@mui/material'
import styled from 'styled-components'
import LayerPreview from './layers/LayerPreview'

const useStyles = makeStyles(theme => ({
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  dialogMedia: {
    objectFit: 'contain'
  },
  nftInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    flex: 1,
    alignContent: 'flex-end',
    justifyContent: 'flex-end'
  },
  noPadding: {
    padding: 0,
    margin: 0
  },
  dialogMediaCropped: {
    width: '100%' /* Full width */,
    objectFit: 'contain'
  }
}))

export default function CongratulationsDialog ({
  open,
  title,
  layer,
  onClose,
  message,
  children
}) {
  const classes = useStyles()
  const smallScreen = useSmallScreenDetection()

  return (
    <TransparentDialog
      disableEscapeKeyDown
      showCloseButton
      open={open}
      maxWidth={'sm'}
      title={
        <Typography
          component='h3'
          variant='h4'
          noWrap
          align={'center'}
          sx={{
            alignSelf: 'center',
            width: '100%'
          }}
        >
          {title}
        </Typography>
      }
      onClose={onClose}
      dialogContent={
        <>
          <Box
            id='drawing-preview'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              aspectRatio: '2/1'
            }}
          >
            <Box
              sx={{
                aspectRatio: '2/1',
                width: '100%'
              }}
            >
              <LayerPreview
                layer={layer}
                style={{
                  width: '100%',
                  aspectRatio: '2/1',
                  position: 'relative'
                }}
                classname={clsx(classes.dialogMedia)}
              />
            </Box>
          </Box>
          <Typography
            component='h3'
            variant='h5'
            noWrap
            align={'center'}
            sx={{
              alignSelf: 'center',
              width: '100%'
            }}
          >
            {message}
          </Typography>
          {children}
          <Confetti
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }}
          />
        </>
      }
      hideFooter
    />
  )
}

const FullScreenConfetti = (
  <Confetti
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}
  />
)
