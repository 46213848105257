import React from 'react'
import { Button } from '@mui/material'
import { XIcon } from './x-utils'

const BASE_URL = 'https://twitter.com/intent/tweet'
function SendTweetButton ({
  title,
  variant = 'outlined',
  size = 'small',
  sx,
  tweetContents,
  tweetUrl
}) {
  const fullTweetIntent = `${BASE_URL}?text=${encodeURIComponent(
    tweetContents
  )}&url=${encodeURIComponent(tweetUrl)}`

  return (
    <Button
      variant={variant}
      size={size}
      endIcon={<XIcon color={'primary'} />}
      sx={{
        backgroundColor: '#1DA1F2',
        ...sx
      }}
      href={fullTweetIntent}
    >
      {title}
    </Button>
  )
}

export default SendTweetButton
