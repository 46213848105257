import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import '../../../css/layout.css'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { Card, Grid, CardContent } from '@mui/material'
import { useTheme } from 'styled-components'
import LeftRightInfoView from '../left-right-info-view'
import { MURALL_WALL, MURALL_WALL_ID, MURALL_WALL_QUERY_PARAM, SupportedChain } from '../../../lib/constants'
import { getMontageContractAddress } from '../../../js/modules/blockchain/datasource/ContractAddressDataSource'
import { Button, Icon, IconButton, List } from '@mui/material'
import { useActiveWeb3React } from '../../../hooks/web3'
import { useMontageDataSource } from '../../../hooks/use-montage-datasource'
import notification from '../../../lib/notification'
import switchToNetwork from '../../../lib/actions/switch-network'
import SetMontageUnlockableDialog from '../../../js/pages/montage/set_montage_unlockable_dialog'
import Web3 from 'web3'
import { findLinksInText } from '../../../js/libs/appUtils'
import StyledDialog from '../../../js/uicomponents/styled_dialog'
import MontageInformationDialog from '../../../js/uicomponents/montage_information_dialog'
import { useMontageBridgedDataSource } from '../../../hooks/use-montage-bridged-datasource'
import InfoIcon from '@mui/icons-material/Info'
import { useApi } from '../../../hooks/use-api'
import { useHistory } from 'react-router-dom'
import ImageListItem from '../list/image_list_item'
import Fade from '../../../reveal/in-and-out/Fade'
import StyledInfoBox from '../StyledInfoBox'
import StyledNftInfoPlaque from './styled-nft-info-plaque'

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  list: {
    minHeight: 0,
    overflowX: 'hidden'
  },
  noPadding: {
    padding: 0,
    margin: 0
  },
  dialogMediaCropped: {
    width: '100%' /* Full width */,
    aspectRatio: '1/1',
    objectFit: 'contain',
    margin: 'auto',
    overflow: 'auto',
    transform: 'scale(1, 1)',
    transition: '0.6s',
    '&:hover': {
      transform: 'scale(1.1, 1.1)',
      cursor: 'pointer'
    }
  }
}))
// TODO for now montages only on polygon - might change in the future
const MontageNftView = ({ chainId, id, nftData }) => {
  const { library, account, chainId: activeChainId } = useActiveWeb3React()

  const api = useApi(SupportedChain.Polygon, MURALL_WALL_ID.LAYER_2)

  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { setUnlockableContentUri, getUnlockableContentUri, ownerOf, unpackMontage } = useMontageDataSource()

  const {
    getUnlockableContentUri: getBridgedUnlockableContentUri,
    exists: existsBridged,
    ownerOf: ownerOfBridged
  } = useMontageBridgedDataSource()

  const [canBeUnpacked, setCanBeUnpacked] = useState(false)
  const [canSetUnlockable, setCanSetUnlockable] = useState(null)
  const [isOwner, setIsOwner] = useState(false)
  const [unlockable, setUnlockable] = useState(null)
  const [unlockableDescription, setUnlockableDescription] = useState(null)
  const [unlockableViewerOpen, setUnlockableViewerOpen] = useState(false)
  const [migrated, setMigrated] = useState(false)
  const [nftInfoOpen, setNftInfoOpen] = useState(false)
  const [unlockableInputOpen, setUnlockableInputOpen] = useState(false)
  const [tokensViewerOpen, setTokensViewerOpen] = useState(false)
  const [otherAttributes, setOtherAttributes] = useState(null)
  const [tokenIds, setTokenIds] = useState([])

  const wallInfo = MURALL_WALL[MURALL_WALL_ID.LAYER_2]

  useEffect(() => {
    async function onMetadataUpdated() {
      const unlockableStatus = nftData.attributes.find(
        (attribute) => attribute.trait_type.toUpperCase() === 'UNLOCKABLE CONTENT'
      )
      const canBeUnpackedStatus = nftData.attributes.find(
        (attribute) => attribute.trait_type.toUpperCase() === 'CAN BE UNPACKED'
      )
      const tokenIds = nftData.attributes
        .find((attribute) => attribute.trait_type.toUpperCase() === 'TOKEN IDS')
        .value.split(', ')
      setTokenIds(tokenIds)

      setCanBeUnpacked(canBeUnpackedStatus.value.toUpperCase() === 'YES')

      let migrated = false
      if (chainId === SupportedChain.Polygon) {
        migrated = await existsBridged(id)
      }
      setMigrated(migrated)

      let isOwner = false
      let ownerAddress = null
      if (migrated && account) {
        try {
          ownerAddress = await ownerOfBridged(id)
          isOwner = Web3.utils.toChecksumAddress(ownerAddress) === Web3.utils.toChecksumAddress(account)
        } catch (e) {
          console.error('Error checking migrated token ownership', e)
        }
      } else if (account && chainId === activeChainId) {
        try {
          ownerAddress = await ownerOf(id)
          console.log('ownerAddress', ownerAddress)
          isOwner = Web3.utils.toChecksumAddress(ownerAddress) === Web3.utils.toChecksumAddress(account)
        } catch (e) {
          console.error('ownerAddress error - may be burned', e)
        }
      }

      setIsOwner(isOwner)

      if (unlockableStatus.value.toUpperCase() !== 'NO') {
        const unlockableDescription = nftData.attributes.find(
          (attribute) => attribute.trait_type.toUpperCase() === 'UNLOCKABLE DESCRIPTION'
        )
        setUnlockableDescription(unlockableDescription.value)
        if (isOwner) {
          if (!migrated && SupportedChain.Polygon === activeChainId) {
            const unlockable = await getUnlockableContentUri(id, account)

            setUnlockable(unlockable)
          } else if (migrated && SupportedChain.Ethereum === activeChainId) {
            const unlockable = await getBridgedUnlockableContentUri(id, account)

            setUnlockable(unlockable)
          }
        }
      }

      const canSetUnlockable =
        nftData.creator != null &&
        account != null &&
        Web3.utils.toChecksumAddress(nftData.creator) === Web3.utils.toChecksumAddress(account)

      setCanSetUnlockable(canSetUnlockable)

      const other = nftData.attributes.filter(
        (attribute) =>
          attribute.trait_type.toUpperCase() !== 'NAME' &&
          attribute.trait_type.toUpperCase() !== 'UNLOCKABLE CONTENT' &&
          attribute.trait_type.toUpperCase() !== 'TOKEN IDS' &&
          attribute.trait_type.toUpperCase() !== 'UNLOCKABLE DESCRIPTION'
      )

      setOtherAttributes(other)
    }

    onMetadataUpdated()
  }, [nftData, setUnlockableContentUri, getUnlockableContentUri, activeChainId, account])

  const handleClose = () => {
    setNftInfoOpen(false)
  }

  const goToExternalUrl = () => {
    const baseUrl =
      chainId === SupportedChain.Mumbai
        ? 'https://testnets.opensea.io/assets/mumbai'
        : chainId === SupportedChain.Polygon
        ? 'https://opensea.io/assets/matic'
        : 'https://opensea.io/assets/ethereum'
    window.open(`${baseUrl}/${getMontageContractAddress(chainId)}/${id}`, '_blank')
  }

  const constructUnlockableViewer = () => {
    const links = findLinksInText(unlockable)

    return (
      <StyledDialog
        open={unlockableViewerOpen}
        onClose={() => {
          setUnlockableViewerOpen(false)
        }}
        dialogContent={() => (
          <>
            <Typography
              className={clsx(classes.typography)}
              gutterBottom
              variant="h5"
              component="p"
              color="textPrimary"
              style={{ paddingTop: '12px', paddingLeft: '12px' }}
            >
              {unlockableDescription}
            </Typography>

            <Typography
              className={clsx(classes.typography)}
              variant="body1"
              component="p"
              color="textSecondary"
              gutterBottom
              style={{ paddingLeft: '12px' }}
            >
              {unlockable}
            </Typography>
            {links &&
              links.map((link) => (
                <Button
                  variant="contained"
                  fullWidth
                  align="center"
                  href={link}
                  target="_blank"
                  rel="noopener"
                  noWrap
                  sx={{
                    mt: '12px'
                  }}
                >
                  <Typography {...theme.typography.button} noWrap>
                    Go To {link}
                  </Typography>
                </Button>
              ))}
          </>
        )}
        withCloseButton
      />
    )
  }

  const constructTokensViewer = () => {
    return (
      <StyledDialog
        maxWidth={'sm'}
        dialogTitle={'NFTs in this Montage'}
        open={tokensViewerOpen}
        onClose={() => {
          setTokensViewerOpen(false)
        }}
        dialogContent={() => (
          <>
            <List className={clsx(classes.list, classes.noPadding)}>
              {tokenIds.map((tokenId, index) => {
                return (
                  <ImageListItem
                    item={tokenId}
                    primaryText={`#${tokenId}`}
                    index={index}
                    key={index}
                    id={index}
                    onListItemClick={() => {
                      history.push(`/murall/${MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.LAYER_2]}/${tokenId}`)
                    }}
                    image={api ? api.s3.token(tokenId) : ''}
                  />
                )
              })}
            </List>
          </>
        )}
        withCloseButton
      />
    )
  }

  const constructNftInfoDialog = () => {
    return (
      <MontageInformationDialog
        open={nftInfoOpen}
        onClose={handleClose}
        chainId={chainId}
        name={nftData.name}
        artist={nftData.creator}
        description={nftData.description}
        tokenId={id}
        croppedImage={nftData.image}
        fullImage={nftData.image}
        hideInformation
      />
    )
  }

  const constructUnlockableInputDialog = () => {
    return (
      <SetMontageUnlockableDialog
        open={unlockableInputOpen}
        onClose={() => {
          setUnlockableInputOpen(false)
        }}
        onSetUnlockableClicked={async (uri, description) => {
          const funcPromise = setUnlockableContentUri(id, uri, description, account)

          try {
            return await notification.promise(funcPromise, {
              loading: 'Setting unlocklable URI...',
              success: `Set unlockable URI to ${uri}`,
              error: (error) => {
                if (error.message && error.message.indexOf('User denied') != -1) return 'You rejected the transaction!'
                return `Sorry, the transaction failed: ${error.name}`
              }
            })
          } catch (error) {
            console.error(error)
          }
        }}
      />
    )
  }

  const createTextRow = (index, key, value, breakpoints) => (
    <Grid item {...breakpoints} key={index}>
      <Fade right cascade>
        <StyledInfoBox
          sx={{
            flex: 1
          }}
          title={key}
          subtitle={value}
        />
      </Fade>
    </Grid>
  )

  const createTokenIdsRow = (breakpoints) => (
    <Grid item {...breakpoints} key={'token ids'}>
      <Fade right cascade>
        <StyledInfoBox
          sx={{
            flex: 1
          }}
          title={'Token Ids'}
          titleVariant={isMobile ? 'subtitle2' : 'body2'}
          subtitle={tokenIds.join(', ')}
          subtitleVariant={isMobile ? 'h6' : 'h5'}
          subtitleProps={{
            align: 'left',
            noWrap: true
          }}
        >
          <IconButton
            aria-label={'details'}
            onClick={() => {
              setTokensViewerOpen(true)
            }}
            size={'large'}
            style={{
              position: 'absolute',
              right: isMobile ? 0 : '6px',
              top: isMobile ? 0 : '6px'
            }}
          >
            <InfoIcon />
          </IconButton>
        </StyledInfoBox>
      </Fade>
    </Grid>
  )

  const createInfoPlaque = () => (
    <Grid item xs={12}>
      <Fade right cascade>
        <StyledNftInfoPlaque creatorAddress={nftData.creator} description={nftData.description} title={nftData.name} />
      </Fade>
    </Grid>
  )
  const constructUnlockableInfo = () => (
    <Grid item xs={12}>
      <Fade right cascade>
        <StyledInfoBox
          sx={{
            flex: 1
          }}
          title={'Contains unlockable:'}
          titleVariant={isMobile ? 'subtitle2' : 'body2'}
          subtitleMaxLength={300}
          subtitleVariant={'subtitle1'}
          subtitle={unlockableDescription}
        >
          {isOwner && (
            <Button
              variant="contained"
              disabled={!account || !unlockable}
              fullWidth
              align="center"
              sx={{
                mt: { xs: '6px', sm: '12px' },
                ...(unlockableDescription.length > 300 && {
                  mb: { xs: '6px', sm: '12px' }
                })
              }}
              onClick={() => {
                console.log('clicked', unlockable)
                setUnlockableViewerOpen(true)
              }}
            >
              {!unlockable ? `Switch to ${migrated ? 'Ethereum' : 'Polygon'} to view` : 'View unlockable content'}
            </Button>
          )}
        </StyledInfoBox>
      </Fade>
    </Grid>
  )

  return (
    <LeftRightInfoView
      leftContents={
        <>
          <img
            className={clsx('pixelated-image', classes.dialogMediaCropped)}
            src={nftData.image}
            alt={'Token ' + id}
            onClick={() => {
              setNftInfoOpen(true)
            }}
          />
          {nftInfoOpen && constructNftInfoDialog()}
          {unlockableInputOpen && constructUnlockableInputDialog()}
          {unlockableViewerOpen && constructUnlockableViewer()}
          {tokensViewerOpen && constructTokensViewer()}
        </>
      }
      rightContents={
        <Card
          elevation={0}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            backgroundColor: 'transparent'
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px',
              width: '100%'
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{
                pt: { xs: '12px', sm: '24px', md: '36px' },
                pb: { xs: '12px', sm: '24px', md: '36px' }
              }}
            >
              {createInfoPlaque()}
              {createTokenIdsRow({
                xs: 12,
                sm: 6,
                md: 6,
                lg: 4
              })}
              {otherAttributes &&
                otherAttributes.map((attribute, index) => {
                  return createTextRow(index, attribute.trait_type, attribute.value, {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4
                  })
                })}
              {migrated &&
                createTextRow(0, 'Bridged To', 'Ethereum', {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4
                })}
              {unlockableDescription && constructUnlockableInfo()}
            </Grid>
            <Grid container direction="column" alignItems="stretch" justifyContent={'center'} spacing={2}>
              {canBeUnpacked && isOwner && (
                <Grid item xs>
                  <Button
                    variant="contained"
                    disabled={!account || migrated}
                    fullWidth
                    align="center"
                    onClick={() => {
                      if (chainId === activeChainId) {
                        notification.promise(unpackMontage(id, account), {
                          loading: `Unpacking ${nftData.name}`,
                          success: `Successfully unpacked ${nftData.name} - the tokens are now in your wallet!`,
                          error: (error) => {
                            if (error.message && error.message.indexOf('User denied') != -1)
                              return 'You rejected the transaction!'
                            return `Sorry, the transaction failed: ${error.name}`
                          }
                        })
                      } else if (library) {
                        notification.error(
                          `Wrong network - please switch to ${
                            chainId === SupportedChain.Ethereum ? 'Ethereum' : 'Polygon'
                          }`
                        )

                        switchToNetwork({ library, chainId })
                      }
                    }}
                  >
                    {migrated
                      ? `Bridge back to ${chainId === SupportedChain.Ethereum ? 'Ethereum' : 'Polygon'} to unpack`
                      : 'Unpack Montage'}
                  </Button>
                </Grid>
              )}
              {canSetUnlockable && isOwner && (
                <Grid item xs>
                  <Button
                    variant="contained"
                    disabled={!account || migrated}
                    fullWidth
                    align="center"
                    onClick={() => {
                      if (chainId === activeChainId) {
                        setUnlockableInputOpen(true)
                      } else if (library) {
                        setUnlockableInputOpen(false)
                        notification.error(
                          `Wrong network - please switch to ${
                            chainId === SupportedChain.Ethereum ? 'Ethereum' : 'Polygon'
                          }`
                        )

                        switchToNetwork({ library, chainId })
                      }
                    }}
                  >
                    {migrated ? 'Bridge back to Polygon to set unlockable' : 'Set unlockable content'}
                  </Button>
                </Grid>
              )}
              <Grid item xs>
                <Button
                  variant="contained"
                  fullWidth
                  align="center"
                  component={RouterLink}
                  to={`/artist/${nftData.creator}`}
                >
                  View other works by this artist
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  fullWidth
                  align="center"
                  onClick={goToExternalUrl}
                  endIcon={
                    <Icon style={{ fontSize: 35, textAlign: 'center' }}>
                      <img src={'images/opensea_logo_white.svg'} alt={'opensea'} />
                    </Icon>
                  }
                  sx={{
                    backgroundColor: '#2081e2'
                  }}
                >
                  View on OpenSea
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
    />
  )
}

export default MontageNftView
