import web3 from 'web3'

export const shortenAddress = (address = '', chars = 4) => {
  if (!web3.utils.isAddress(address)) return null
  return `${address.substring(0, chars + 2)}...${address.substring(
    address.length - chars
  )}`
}

export async function ensReverseLookup (address, provider) {
  const library = provider
  const namehash = await library.eth.call({
    to: '0x084b1c3c81545d370f3634392de611caabff8148', // ENS: Reverse Registrar
    data: library.eth.abi.encodeFunctionCall(
      {
        name: 'node',
        type: 'function',
        inputs: [{ type: 'address', name: 'addr' }]
      },
      [address]
    )
  })
  return library.eth.abi.decodeParameter(
    'string',
    await library.eth.call({
      to: '0xa2c122be93b0074270ebee7f6b7292c7deb45047', // ENS: Default Reverse Resolver
      data: library.eth.abi.encodeFunctionCall(
        {
          name: 'name',
          type: 'function',
          inputs: [{ type: 'bytes32', name: 'hash' }]
        },
        [namehash]
      )
    })
  )
}

export default { shortenAddress, ensReverseLookup }
