import { gsap, SteppedEase } from 'gsap'
import React, { useRef, useEffect } from 'react'
import _ from 'underscore'

export const SPRITE_SIZE = 256
const TOTAL_FRAMES = 18

export function LoadingSpinner (props) {
  const loaderContainer = useRef(null)

  const timeline = gsap.timeline({ repeat: -1 })

  useEffect(() => {
    // Call handler right away so state gets updated with initial window size
    startAnimation()

    return () => {
      timeline.clear()
    }
  }, []) // Empty array ensures that effect is only run on mount

  const startAnimation = () => {
    if (!_.isNull(loaderContainer.current)) {
      timeline.to(loaderContainer.current, {
        duration: 1.5,
        x: -SPRITE_SIZE * (TOTAL_FRAMES - 1),
        repeat: -1,
        ease: SteppedEase.config(TOTAL_FRAMES - 1)
      })
    }
  }
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        overflow: 'hidden',
        width: SPRITE_SIZE + 'px',
        height: SPRITE_SIZE + 'px',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <img src='images/murall_loading_sprites.png' ref={loaderContainer} />
    </div>
  )
}
