import React, { useCallback, useRef } from 'react'
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom'

export default function ZoomableViewWrapper ({ style, innerStyle, children }) {
  const containerRef = useRef()

  const updateRefTransform = (ref, { x, y, scale }) => {
    if (ref && ref.current) {
      const value = make3dTransformValue({ x, y, scale })
      ref.current.style.setProperty('transform', value)
    }
  }

  const onViewZoomUpdate = useCallback(({ x, y, scale }) => {
    updateRefTransform(containerRef, { x, y, scale })
  }, [])

  return (
    <QuickPinchZoom
      onUpdate={onViewZoomUpdate}
      maxZoom={10}
      wheelScaleFactor={500}
      shouldInterceptWheel={() => false}
      containerProps={{
        style: {
          flex: 1,
          width: '100%',
          height: '100%',
          ...style
        }
      }}
    >
      <div
        ref={containerRef}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          ...innerStyle
        }}
      >
        {children}
      </div>
    </QuickPinchZoom>
  )
}
