import Paper from '@mui/material/Paper'
import React, { useState } from 'react'
import { IconButton, InputBase } from '@mui/material'
import StyledTooltip from '../../js/uicomponents/styled_tooltip'
import FormHelperText from '@mui/material/FormHelperText'
export default function CustomInput({
  sx,
  value,
  placeholder,
  buttonIcon,
  onIconClicked,
  buttonDisabled,
  multiline,
  error,
  onChange,
  onKeyDown,
  children
}) {
  return (
    <>
      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: 400,
          ...(sx
            ? {
                ...sx
              }
            : {})
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          type="text"
          value={value}
          placeholder={placeholder}
          inputProps={{ 'aria-label': placeholder }}
          onChange={(event) => {
            onChange && onChange(event.target.value)
          }}
          onKeyDown={onKeyDown}
          multiline={multiline}
          error={error !== undefined}
        />
        {buttonIcon && (
          <IconButton
            // type='submit'
            sx={{ p: '10px' }}
            aria-label="search"
            disabled={buttonDisabled}
            onClick={() => {
              onIconClicked && onIconClicked(value)
            }}
          >
            {buttonIcon}
          </IconButton>
        )}

        {children}
      </Paper>
      {error && (
        <FormHelperText
          sx={{
            color: 'red',
            fontFamily: 'Roboto',
            fontWeight: 300,
            fontSize: '12px'
          }}
        >
          {error}
        </FormHelperText>
      )}
    </>
  )
}
