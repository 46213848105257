import React from 'react'
import { styled } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'

export default function AvatarWithAvatarBadge ({
  className,
  sx,
  avatarParams,
  badgeParams
}) {
  return (
    <Badge
      className={className}
      sx={{
        ...sx
      }}
      overlap='circular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={<SmallAvatar {...(badgeParams || {})} />}
    >
      <Avatar
        {...(avatarParams || {})}
        sx={{ width: 30, height: 30, ...avatarParams.sx }}
      />
    </Badge>
  )
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  padding: '2px',
  border: `2px solid ${theme.palette.primary.dark}`
}))
