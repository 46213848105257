import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import { LoadingSpinner } from '../../uicomponents/loading_spinner'
import EmptyNftsMessageView from '../../uicomponents/empty_nfts_message_view'
import NetworkErrorMessageView from '../../uicomponents/network_error_message_view'
import ConnectWalletMessageView from '../../uicomponents/connect_wallet_message_view'
import Grid from '@mui/material/Grid'
import notification from '../../../lib/notification'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import Pagination from '@mui/material/Pagination'
import { useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../../hooks/web3'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
    flexGrow: 1
  },
  glow: {
    boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + 'B3'
  },
  border: {
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px'
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  fixedContainer: {
    display: 'flex',
    padding: '12px',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    right: 0
  },
  pagination: {
    padding: '3px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: '48px',
    alignSelf: 'center',
    borderRadius: 28,
    background: `#26262B`
  }
}))

export default function PaginatedNftGridView (props) {
  const network = useSelector(state => state.network)
  const { account } = useActiveWeb3React()

  const [isFetching, setIsFetching] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [isFetchingTokenData, setIsFetchingTokenData] = useState(false)
  const [networkError, setNetworkError] = useState(false)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const [page, setPage] = useState(1)
  const [nfts, setNfts] = useState(0)
  const classes = useStyles()

  useEffect(() => {
    setIsFetching(!network)
    setNetworkError(network && !network.supported)
    if (network && network.supported && account) {
      setIsConnected(true)
      initialiseNftInformation()
    }
  }, [network, account]) // Empty array ensures that effect is only run on mount

  useEffect(() => {
    initialiseNftInformation()
    // Remove event listener on cleanup
    return () => {}
  }, [props.isLoading]) // Empty array ensures that effect is only run on mount

  const initialiseNftInformation = async () => {
    const nftBalance = await props.onFetchNftBalance()

    const totalPageCount = Math.ceil(parseInt(nftBalance) / props.itemsPerPage)

    setTotalPageCount(totalPageCount)
    setIsFetching(false)
    setIsFetchingTokenData(true)
    if (nftBalance > 0) {
      fetchTokensForPage(page)
    } else {
      setIsFetching(false)
      setIsFetchingTokenData(false)
      setNfts([])
      notification.error(
        'You do not own any MurAll NFTs - try drawing something or go to the marketplace!'
      )
    }
  }

  const fetchTokensForPage = async page => {
    setIsFetchingTokenData(true)
    setNfts([])
    const nftBalance = await props.onFetchNftBalance()

    let startIndex = (page - 1) * props.itemsPerPage

    let endIndex =
      startIndex + props.itemsPerPage - 1 > nftBalance - 1
        ? nftBalance - 1
        : startIndex + props.itemsPerPage - 1
    let myNfts = await props.onFetchNftsInIndexRange(startIndex, endIndex)

    if (_.isEmpty(myNfts)) {
      setPage(1)
      setIsFetchingTokenData(false)
      setNfts([])
      notification.error(
        'You do not own any MurAll NFTs - try drawing something or go to the marketplace!'
      )
    } else {
      setIsFetchingTokenData(false)
      setNfts(myNfts)
    }
  }

  const onPageChanged = (event, value) => {
    setPage(value)
    fetchTokensForPage(value)
  }

  return isFetching || props.isLoading ? (
    <LoadingSpinner />
  ) : networkError ? (
    <NetworkErrorMessageView />
  ) : !isConnected ? (
    <ConnectWalletMessageView />
  ) : _.isEmpty(nfts) && !isFetchingTokenData ? (
    <EmptyNftsMessageView />
  ) : (
    <div className={clsx(classes.absoluteFill, classes.root)}>
      <Grid
        container
        spacing={4}
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        style={{ paddingBottom: '48px' }}
      >
        {Object.keys(nfts).map(key => {
          return (
            <Grid
              key={key}
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              align='center'
            >
              {props.constructNftView(nfts[key])}
            </Grid>
          )
        })}
      </Grid>
      {isFetchingTokenData && <LoadingSpinner />}

      {!isFetchingTokenData && (
        <div className={clsx(classes.fixedContainer)}>
          {totalPageCount > 0 && (
            <Pagination
              className={clsx(classes.pagination, classes.border)}
              count={totalPageCount}
              page={page}
              color='secondary'
              onChange={onPageChanged}
              size='large'
            />
          )}
        </div>
      )}
    </div>
  )
}
