import { useCallback, useEffect, useState } from 'react'
import { useDataSizeCheckForSetPixels } from './use-data-size-check-for-set-pixels.js'
import Get_transaction_data_for_layers_usecase from '../../js/modules/blockchain/usecase/get_transaction_data_for_layers_usecase.js'

export const useLayerDataSizeCheckForSetPixels = ({
  layer,
  name,
  number,
  seriesId,
  immediate = false
}) => {
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState(null)
  const [tooManyColours, setTooManyColours] = useState(false)

  const {
    loading: loadingSizeCheck,
    maxSize,
    txSize,
    withinLimit
  } = useDataSizeCheckForSetPixels({
    colourIndexData: detail?.colourIndexData,
    blockchainPixelData: detail?.blockchainPixelData,
    blockchainPixelGroupData: detail?.blockchainPixelGroupData,
    blockchainPixelGroupIndexData: detail?.blockchainPixelGroupIndexData,
    blockchainTransparentPixelGroupData:
      detail?.blockchainTransparentPixelGroupData,
    blockchainTransparentPixelGroupIndexData:
      detail?.blockchainTransparentPixelGroupIndexData,
    name,
    number,
    seriesId,
    alphaEnabled: detail?.alphaEnabled,
    immediate: immediate && !!detail && !loading && !tooManyColours
  })

  const apply = useCallback(async () => {
    setLoading(true)
    setTooManyColours(false)

    try {
      const detail = await new Get_transaction_data_for_layers_usecase().execute(
        {
          layerId: layer.id
        }
      )
      setDetail(detail)
    } catch (error) {
      console.error(error)
      setTooManyColours(true)
    }
    setLoading(false)
  }, [layer])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])

  return {
    apply,
    loading: loading || loadingSizeCheck,
    tooManyColours,
    txSize,
    maxSize,
    withinLimit
  }
}
