import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import NftInformationDialog from '../../uicomponents/nft_information_dialog'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './../../../css/layout.css'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import { truncate } from '../../libs/appUtils'
import { SupportedChain } from '../../../lib/constants'

const TOKEN_ID = 21
const AUCTION_ID =
  '0x0cda7895d8e4e9d598e1984954f9d32a4b664bd999a0c8404e5dde3634d08371'
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText
    // border: 'solid',
    // borderColor: theme.palette.secondary.main,
    // borderWidth: '1px'
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  selectedBackground: {
    backgroundColor: theme.palette.primary.dark
    // backgroundColor: 'transparent'
  },
  accordian: {
    flex: 1,
    width: '100%',
    transition: 'all 300ms'
  },
  accordianDetails: {
    // paddingTop: '12px'
    // paddingLeft: 0,
    // paddingRight: 0,
    // paddingBottom: 0
    maxHeight: '205px',
    overflow: 'auto'
  },
  accordianDetailsContents: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    maxHeight: '205px'
  },
  imgContainer: {
    borderRadius: 10,
    margin: 0,
    padding: 0,
    backgroundColor: theme.palette.primary.dark
  },
  auctionImg: {
    borderRadius: 10,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    aspectRatio: '2/1'
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 500
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  emptyViewContainer: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    //backgroundColor: theme.palette.primary.dark,
    backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  menuIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212100,
            #21212199,
            #212121B3)`
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}))

const Accordion = withStyles(theme => ({
  root: {
    // border: 'solid',
    // borderColor: theme.palette.secondary.main,
    // borderWidth: '1px',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
}))(MuiAccordion)

const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
}))(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)

export default function AuctionItemNftInfoCard (props) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [expanded, setExpanded] = useState('id1')

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const createAccordianForSection = (question, answer, id) => {
    return (
      <Accordion
        className={clsx(
          classes.accordian,
          classes.transparent
          // { [classes.transparent]: expanded !== id },
          // { [classes.selectedBackground]: expanded === id }
        )}
        expanded={expanded === id}
        onChange={handleChange(id)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            className={clsx(classes.typography)}
            variant='h6'
            component='h6'
          >
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={clsx(classes.selectedBackground, classes.accordianDetails)}
          scroll={'paper'}
        >
          <Typography
            className={clsx(
              classes.typographyLight,
              classes.accordianDetailsContents,
              classes.selectedBackground
            )}
            color='textPrimary'
            align='justify'
          >
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    )
  }

  const createAccordianForSectionCustom = (question, answer, id) => {
    return (
      <Accordion
        className={clsx(classes.accordian, classes.transparent)}
        expanded={expanded === id}
        onChange={handleChange(id)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            className={clsx(classes.typography)}
            variant='h6'
            component='h6'
          >
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={clsx(classes.selectedBackground, classes.accordianDetails)}
        >
          {answer()}
        </AccordionDetails>
      </Accordion>
    )
  }

  const constructNftInfoDialog = () => {
    return (
      <NftInformationDialog
        open={open}
        onClose={handleClose}
        nftInformation={props.nftInformation}
        hideInformation
        viewCropped
      />
    )
  }

  const createTextRow = (key, value, link = null) => (
    <div className={clsx(classes.textRow)}>
      <Typography
        className={clsx(classes.typography)}
        variant='body1'
        component='p'
      >
        {key}:
      </Typography>
      <Typography
        className={clsx(classes.typographyLight)}
        variant='body1'
        component='p'
      >
        {link ? (
          <Link href={link} color='secondary'>
            {value}
          </Link>
        ) : (
          value
        )}
      </Typography>
    </div>
  )

  const createGridRow = (imageLink, value) => (
    <React.Fragment>
      <Grid item align='center'>
        <img className={clsx(classes.avatar)} src={imageLink} />
      </Grid>
      <Grid item xs align='center'>
        <Typography
          className={clsx(classes.typography)}
          variant='body1'
          component='p'
        >
          {value}
        </Typography>
      </Grid>
    </React.Fragment>
  )

  const tokenId = props.nftInformation.tokenId
  const image = props.nftInformation.croppedBase64PngString
  const description = props.nftInformation.description
  const name = props.nftInformation.name
  const hasUnlockable = props.nftInformation.hasUnlockable
  const unlockableDescription = props.nftInformation.unlockableDescription

  return (
    <React.Fragment>
      <Card elevation={10} className={clsx(classes.imgContainer)}>
        <CardActionArea onClick={handleOpen}>
          <img
            src={image}
            className={clsx(classes.auctionImg, 'pixelated-image')}
            title={`#${tokenId}: ${name}`}
          />
        </CardActionArea>
        {open && constructNftInfoDialog()}
      </Card>

      <Card
        className={classes.root}
        elevation={10}
        style={{ marginTop: '24px', marginBottom: '24px', padding: '0px' }}
      >
        <CardContent
          style={{
            padding: '0px',
            width: '100%',
            maxWidth: '100%'
          }}
        >
          {createAccordianForSection(`Description`, `${description}`, 'id1')}
          {hasUnlockable &&
            createAccordianForSection(
              `Unlockable`,
              `${unlockableDescription}`,
              'id2'
            )}
          {createAccordianForSectionCustom(
            `100% On Chain`,
            () => (
              <Grid container spacing={1} direction='row' alignItems='center'>
                {createGridRow(
                  'images/100_percent_onchain.png',
                  `All MurAll image data is encoded onto the blockchain itself
                    - the most decentralised, robust and secure storage
                    available. Our custom built blockmap encoding is a world's
                    first and a unique feature of every NFT created on the
                    MurAll canvas.`
                )}
              </Grid>
            ),
            'id4'
          )}
          {createAccordianForSectionCustom(
            `Charity Auction Details`,
            () => (
              <div style={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={4}
                  direction='column'
                  alignItems='stretch'
                >
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    {createGridRow(
                      'images/logo_graffiti_kings.png',
                      `50% of proceeds go to Graff Punks; the first-ever 16 and
                      under only NFT show in the metaverse. The Graffiti Kings
                      are avid sponsors of young artists of all ages; they began
                      in 1996 encouraging young artists with graffiti art
                      projects all over the UK. The Graff Punks art exhibition
                      will be an ongoing program for minors; helping educate
                      them about all aspects of this new digital world we are
                      all entering, from onboarding to minting NFTs, teaching
                      them all things "metaverse" to keep them doing what they
                      love most: creating.`
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    {createGridRow(
                      'https://uhhm.org/wp-content/uploads/2021/01/uhhm_logo@2x.png',
                      `25% of proceeds go to the Univeral Hip Hop Museum (UHHM) in the Bronx, New York, USA. Anchored 
                      in the birthplace of the culture, the UHHM celebrates and preserves the history of local
                       and global Hip Hop to inspire, empower, and promote understanding.  They provide a space
                        for audiences, artists, and technology to converge to create unparalleled educational and
                         entertainment experiences around the Hip Hop culture of the past, present, and future.`
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    {createGridRow(
                      'images/MurAll_Logo_L1_WHITE.png',
                      `25% of proceeds go to the development of MurAll; the collaborative 
                      canvas allowing artists worldwide to paint onto an ever-evolving 
                      piece of art. The entire ethos of the MurAll project fits
                      with the blockchain narrative of a neutral place that doesn’t 
                      filter, censor or stop any transaction, incentivising good
                      quality artwork through tokenomics over centralised control.
                      A place for true freedom of speech through art where anyone
                      no matter their stature in the art world can participate, with instant representation through the ever-evolving live state.`
                    )}
                  </Grid>
                </Grid>
              </div>
            ),
            'id5'
          )}
          {createAccordianForSectionCustom(
            `Token Details`,
            () => (
              <div
                className={clsx(
                  classes.emptyViewContainer,
                  classes.accordianDetailsContents
                )}
              >
                {createTextRow(
                  'Contract',
                  truncate(props.nftInformation.contractAddress, 6, 4),
                  props.nftInformation.chainId === SupportedChain.Polygon
                    ? `https://polygonscan.com/address/${props.nftInformation.contractAddress}`
                    : `https://etherscan.io/address/${props.nftInformation.contractAddress}`
                )}
                {createTextRow('Token ID', tokenId)}
                {createTextRow('Number of tokens', '1 of 1')}
                {createTextRow(
                  'Blockchain',
                  props.nftInformation.chainId === SupportedChain.Polygon
                    ? 'Polygon'
                    : 'Ethereum'
                )}
              </div>
            ),
            'id3'
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  )
}
