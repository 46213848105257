import React from 'react'
import Typography from '@mui/material/Typography'
import { PaintPlusIcon } from '../wallet/paint-icon'
import RoundedButton from './rounded-button'

const PaintButton = ({ text, onClick }) => {
  return (
    <RoundedButton
      variant='text'
      aria-label='close'
      onClick={onClick}
      sx={{
        color: 'inherit',
        fontSize: 'inherit',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }}
      endIcon={<PaintPlusIcon />}
    >
      <Typography variant='body1'>{text}</Typography>
    </RoundedButton>
  )
}

export default PaintButton
