import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import StyledEmojiAvatar from './StyledEmojiAvatar'

export default function EmojiCountDisplay({ emoji, value, style = {}, emojiStyle = {} }) {
  return (
    <Stack
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      alignContent={'center'}
      gap={0}
      sx={{
        border: `1px solid white`,
        borderRadius: '24px',
        ...style
      }}
    >
      <StyledEmojiAvatar
        style={{
          ...emojiStyle
        }}
      >
        {emoji}
      </StyledEmojiAvatar>

      <Typography
        variant="body"
        component="p"
        sx={{
          px: '0.5em',
          m: 0,
          textAlign: 'right',
          fontFamily: 'Roboto',
          fontWeight: 500
        }}
      >
        {value}
      </Typography>
    </Stack>
  )
}
