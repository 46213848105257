import React, { useRef, useCallback, useState, useEffect } from 'react'
import _ from 'underscore'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import useSmallScreenDetection from './useSmallScreenDetection'
import './../../css/layout.css'
import TransparentDialog from './transparent_dialog'
import { truncate } from '../libs/appUtils'
import ZoomableViewWrapper from '../../components/common/ZoomableViewWrapper'

const useStyles = makeStyles(theme => ({
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  dialogMedia: {
    // backgroundColor: theme.palette.primary.dark
  },
  nftInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    flex: 1,
    alignContent: 'flex-end',
    justifyContent: 'flex-end'
  },
  noPadding: {
    padding: 0,
    margin: 0
  },
  dialogMediaCropped: {
    width: '100%' /* Full width */,
    objectFit: 'contain'
  }
}))

export default function NftImagePreviewDialog (props) {
  const classes = useStyles()
  const smallScreen = useSmallScreenDetection()
  const dialogContentRef = useRef()
  const [imageHeight, setImageHeight] = useState(0)

  useEffect(() => {
    function handleResize () {
      updateExpandedHeight()
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  const updateExpandedHeight = () => {
    if (dialogContentRef.current) {
      setImageHeight(dialogContentRef.current.getBoundingClientRect().height)
    }
  }

  const measuredRef = useCallback(node => {
    if (node !== null) {
      dialogContentRef.current = node
      setImageHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const nftInformation = props.nftInformation

  return nftInformation != null ? (
    <TransparentDialog
      {...props}
      withCloseButton
      dialogTitle={nftInformation.name}
      dialogContentRef={measuredRef}
      dialogContent={() => (
        <ZoomableViewWrapper>
          <img
            className={clsx(
              'pixelated-image',
              classes.dialogMedia,
              classes.dialogMediaCropped
            )}
            style={{
              height: imageHeight != 0 ? imageHeight + 'px' : 100 + 'vh'
            }}
            src={props.image}
            alt={'Token ' + nftInformation.tokenId + 'has not been filled!'}
          />
        </ZoomableViewWrapper>
      )}
      dialogFooterColumn={smallScreen}
      dialogFooter={() => (
        <React.Fragment>
          {!props.hideInformation && (
            <div
              className={clsx(classes.nftInfo)}
              style={
                {
                  // marginBottom: '16px'
                }
              }
            >
              <Typography
                className={clsx(classes.typography)}
                variant='h6'
                component='h6'
                color='textPrimary'
                noWrap
              >
                {smallScreen
                  ? truncate(nftInformation.artist, 6, 4)
                  : nftInformation.artist}
              </Typography>
              <Typography
                className={clsx(classes.typography)}
                variant='subtitle1'
                component='subtitle1'
                color='textSecondary'
                noWrap
                gutterBottom={props.showCompletionStatus}
              >
                {nftInformation.description}
              </Typography>
              <Typography
                className={clsx(classes.typography)}
                variant='subtitle2'
                component='subtitle2'
                color='textSecondary'
                noWrap
                gutterBottom={props.showCompletionStatus}
              >
                Token #{nftInformation.tokenId}
              </Typography>
              {props.showCompletionStatus && (
                <Typography
                  className={clsx(classes.typography)}
                  variant='subtitle2'
                  component='subtitle2'
                  color='textSecondary'
                  noWrap
                >
                  {nftInformation.completionStatus}% filled
                </Typography>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    />
  ) : null
}
