import React from 'react'
import StyledDialog from '../../uicomponents/styled_dialog'
import { Typography, Link } from '@mui/material'
import Helper_class from '../../libs/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { MURALL_WALL_ID } from '../../../lib/constants'
import { setWall } from '../../../state/slices/wall'

export default function WelcomeDrawDialog (props) {
  const { onClose, open, onPositiveButtonClick } = props
  const dispatch = useDispatch()

  const setSelectedWallId = id => {
    dispatch(setWall(id))
  }

  return (
    <StyledDialog
      open={open}
      withCloseButton
      onClose={onClose}
      maxWidth={'sm'}
      dialogTitle={'Make your artwork history on MurAll'}
      dialogContent={() => (
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography
            style={{ fontFamily: 'Roboto', fontWeight: 300 }}
            variant='h6'
            component='h6'
            color='textSecondary'
            align='justify'
          >
            Use our editor to create your image from scratch, or import one you
            have already made, or try our{' '}
            <Link
              onClick={() => {
                new Helper_class().dispatchCustomEvent(
                  'drawingContainer',
                  'generateWithAI',
                  {}
                )
                onClose()
              }}
              underline='hover'
            >
              {'new AI image generation feature!'}
            </Link>
            <br />
            <br />
            Fancy going gasless? Select our{' '}
            <Link
              onClick={() => {
                setSelectedWallId(MURALL_WALL_ID.EVOLV3)
              }}
              underline='hover'
            >
              {'new Evolv3 wall on Polygon'}
            </Link>{' '}
            for an even easier experience with new exciting features like
            support for images with full color, video support, and full
            transparency support for both images and video!!!
            <br />
            <br />
            Once you are happy with where it sits on the wall, hit the "Mint"
            button on the top right and follow the mint instructions to solidify
            it as part of the MurAll, where it will be seen instantly on our
            live state.
            <br />
            <br />
            Remember minting on our onchain walls{' '}
            <Link
              onClick={() => {
                setSelectedWallId(MURALL_WALL_ID.LAYER_1)
              }}
              underline='hover'
            >
              {'Revo1t on Ethereum'}
            </Link>{' '}
            and{' '}
            <Link
              onClick={() => {
                setSelectedWallId(MURALL_WALL_ID.LAYER_2)
              }}
              underline='hover'
            >
              {'Di2rupt on Polygon'}
            </Link>{' '}
            use 0.5 PAINT per pixel + network transaction fees, or on Evolv3
            0.0005 PAINT per pixel (no tx fee!) and you will receive an NFT of
            your artwork!
            <br />
            <br />
            Check out our tutorials on our knowledgebase for more info on:
            <br />
            <Link
              href='https://knowledgebase.murall.art/tutorials/minting/2-drawing'
              target='_blank'
              underline='hover'
            >
              {'- How to draw on MurAll'}
            </Link>
            <br />
            <Link
              href='https://knowledgebase.murall.art/tutorials/minting/3-minting'
              target='_blank'
              underline='hover'
            >
              {'- How to mint on MurAll'}
            </Link>
            <br />
            <Link
              href='https://knowledgebase.murall.art/tutorials/creating-montage'
              target='_blank'
              underline='hover'
            >
              {
                '- How to join minted MurAll NFTs into a single NFT image using Montage'
              }
            </Link>
            <br />
            <br />
            Or check out our{' '}
            <Link
              href='https://www.youtube.com/watch?v=BEOX2hOjhMM'
              target='_blank'
              underline='hover'
            >
              {'1 minute YouTube tutorial on how to mint on MurAll'}
            </Link>
          </Typography>
        </div>
      )}
      positiveButtonTitle={'Ok'}
      onPositiveButtonClick={onPositiveButtonClick}
    />
  )
}
