import React from 'react'
import Footer from '../components/common/footer'
import AboutContents from '../components/common/about-contents'

const AboutPage = () => {
  return (
    <>
      <AboutContents />
      <Footer />
    </>
  )
}

export default AboutPage
