import { useMemo } from 'react'
import { getMurAllContractAddress } from '../../js/modules/blockchain/datasource/ContractAddressDataSource.js'
import notification from '../../lib/notification.js'
import { useMurAllDataSource } from '../use-murall-datasource.js'
import { usePaintDataSource } from '../use-paint-datasource.js'
import { useActiveWeb3React } from '../web3.js'
import { useSelector } from 'react-redux'

export const useSetPixelsOnMurAll = () => {
  const { account, chainId } = useActiveWeb3React()
  const currentWall = useSelector(state => state.wall)
  const {
    isTokenTransferApprovedForAmount,
    approveTokenTransfer,
    maxAllowance
  } = usePaintDataSource(chainId)
  const { setPixels, setDataValidator } = useMurAllDataSource(currentWall.id)

  return useMemo(() => {
    return {
      setDataValidator: async validatorAddress => {
        return setDataValidator(validatorAddress, account)
      },
      setPixelsOnMurAllAndNotify: async (detail, name, number, seriesId) => {
        const allowed = await isTokenTransferApprovedForAmount(
          account,
          getMurAllContractAddress(chainId),
          detail.costInPaint
        )

        if (!allowed) {
          const funcPromise = approveTokenTransfer(
            account,
            getMurAllContractAddress(chainId),
            maxAllowance()
          )

          try {
            await notification.promise(funcPromise, {
              loading:
                'MurAll needs permission to spend your Paint to continue',
              success: 'You can now draw on MurAll!',
              error: error => {
                if (
                  error &&
                  error.message &&
                  error.message.indexOf('User denied') != -1
                )
                  return 'You rejected the transaction!'
                return `Sorry, the transaction failed: ${
                  error ? error.name : error
                }`
              }
            })
          } catch (error) {
            console.error(error)
            throw error
          }
        }

        const setPixelsPromise = setPixels(
          detail.colourIndexData,
          detail.blockchainPixelData,
          detail.blockchainPixelGroupData,
          detail.blockchainPixelGroupIndexData,
          detail.blockchainTransparentPixelGroupData,
          detail.blockchainTransparentPixelGroupIndexData,
          name,
          number,
          seriesId,
          detail.alphaEnabled,
          account
        )

        try {
          const returnData = await notification.promise(setPixelsPromise, {
            loading: `Drawing ${name}, #${number} of series ${seriesId}`,
            success: event =>
              `Minted new token #${event.returnValues.tokenId} ${name}, #${number} of series ${seriesId} - Fill it with data from inside View My NFTs`,
            error: error => {
              if (error.message && error.message.indexOf('User denied') != -1)
                return 'You rejected the transaction!'
              return `Sorry, the transaction failed: ${error.name}`
            }
          })
          return returnData
        } catch (error) {
          console.error(error)
          throw error
        }
      }
    }
  }, [
    chainId,
    isTokenTransferApprovedForAmount,
    approveTokenTransfer,
    maxAllowance,
    setPixels,
    account
  ])
}

export default useSetPixelsOnMurAll
