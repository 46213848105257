import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Slider from '@mui/material/Slider'
import MuiInput from '@mui/material/Input'
import { FormHelperText } from '@mui/material'

const Input = styled(MuiInput)`
  width: 52px;
`

export default function LabelledSlider ({
  value,
  onChange,
  label,
  step,
  min,
  max,
  icon,
  sx
}) {
  const handleSliderChange = (event, newValue) => {
    onChange && onChange(newValue)
  }

  const handleInputChange = event => {
    onChange &&
      onChange(event.target.value === '' ? '' : Number(event.target.value))
  }

  const handleBlur = () => {
    if (value < min) {
      onChange && onChange(min)
    } else if (value > max) {
      onChange && onChange(max)
    }
  }

  return (
    <Box sx={{ ...sx }}>
      <FormHelperText id='input-slider'>{label}</FormHelperText>
      <Grid container spacing={2} alignItems='center'>
        {icon && <Grid item>{icon}</Grid>}
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby='input-slider'
            step={step}
            min={min}
            max={max}
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            size='small'
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: step,
              min: min,
              max: max,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
