import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import CustomAccordion from '../../../components/common/Accordion'
import SectionTitle from '../SectionTitle'

const SectionMurAllRevo1tDi2rupt = () => {
  return (
    <>
      <SectionTitle variant='h4' component='h4'>
        MurAll Revo1t & Di2rupt
      </SectionTitle>
      <CustomAccordion
        items={[
          {
            title:
              'How many times in total can the whole MurAll Revo1t/Di2rupt be drawn over?',

            content: (
              <>
                Revo1t and Di2rupt can be drawn over no more than 21 thousand
                times in their entirety.
              </>
            )
          },
          {
            title: 'Where is the data hosted?',
            content: (
              <>
                All pixel data is stored on the Ethereum blockchain in the event
                log storage. Most current solutions use things like IPFS however
                they are not anywhere as decentralised as the blockchain itself,
                and also separate the ownership of the data and the data itself;
                we really wanted a solution that was without the compromise of
                this separation, so we have done our best to create a solution
                that has all data on chain, the state of MurAll itself is on the
                Etheruem blockchain, and the NFT’s have their drawing data
                inside them,all on chain too.
              </>
            )
          },
          {
            title: 'What colour space does MurAll Revo1t/Di2rupt use?',
            content: (
              <>
                MurAll uses RGB565, a 16 bit colour space which gives the best
                look while allowing for more pixels per transaction, as well as
                taking up less room on the blockchain.
              </>
            )
          },
          {
            title: 'How many colours can each drawing have?',
            content: (
              <>
                In order to fit as much information as possible into each
                transaction without sacrificing colour too much, each drawn
                image can have up to 256 distinct colours from the RGB565
                spectrum. There is no set 256 colour spectrum, each artist can
                choose any 256 colours to use for each drawing
              </>
            )
          },

          {
            title:
              'Is it possible to put the image data onto contract storage?',
            content: (
              <>
                Yes; you can fill the MURALL NFT with the same drawing data as
                your original drawing on MurAll so your NFT contains the same
                data as your contribution; all pixel data on the blockchain in
                contract storage.
              </>
            )
          },
          {
            title:
              'What are the terms and conditions of using this website to interact with MurAll?',
            content: (
              <>
                You can find the terms and conditions{' '}
                <Link
                  component={RouterLink}
                  to='/terms'
                  color='secondary'
                  underline='hover'
                >
                  here
                </Link>
              </>
            )
          },
          {
            title: 'What are the contract addresses for MurAll Revo1t/Di2rupt?',
            content: (
              <>
                <br />
                Revo1t (Ethereum):
                <br />
                <br />
                MurAll:{' '}
                <Link
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  href='https://etherscan.io/address/0x6442bdfd16352726aa25ad6b3cbab3865c05ed15'
                  color='secondary'
                >
                  0x6442bdfd16352726aa25ad6b3cbab3865c05ed15
                </Link>
                <br />
                <br />
                MurAll NFT (ERC-721):{' '}
                <Link
                  href='https://etherscan.io/address/0x5bb3d01f32687f92f8636799687e55cba0627704'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x5bb3d01f32687f92f8636799687e55cba0627704
                </Link>
                <br />
                <br />
                Paint (PAINT) Token (ERC-20):{' '}
                <Link
                  href='https://etherscan.io/token/0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042
                </Link>
                <br />
                <br />
                Paint airdrop claim contract:{' '}
                <Link
                  href='https://etherscan.io/address/0xf45ba58c415ed6c77cfc157f42f300f9fd980985'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0xf45ba58c415ed6c77cfc157f42f300f9fd980985
                </Link>
                <br />
                <br />
                MurAll data validator:{' '}
                <Link
                  href='https://etherscan.io/address/0x94A20eefdb61eF160f052D8ec9F8Fb328907ba29'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x94A20eefdb61eF160f052D8ec9F8Fb328907ba29
                </Link>
                <br />
                <br />
                MurAll NFT data storage (contract storage for filled tokens):{' '}
                <Link
                  href='https://etherscan.io/address/0xe2514e229a657F37DA9384679452C34466FA9539'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0xe2514e229a657F37DA9384679452C34466FA9539
                </Link>
                <br />
                <br />
                MurAll NFT Metadata decoder:{' '}
                <Link
                  href='https://etherscan.io/address/0x0041C8050A2c895d532103f1F7A234794deF1D8E'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x0041C8050A2c895d532103f1F7A234794deF1D8E
                </Link>
                <br />
                <br />
                <br />
                Di2rupt (Polygon/Matic) addresses:
                <br />
                <br />
                Paint (PAINT) Token (ERC-20):{' '}
                <Link
                  href='https://polygonscan.com/token/0x7c28f627ea3aec8b882b51eb1935f66e5b875714'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x7c28F627eA3aEc8B882b51eb1935f66e5b875714
                </Link>
                <br />
                <br />
                MurAll NFT (ERC-721):{' '}
                <Link
                  href='https://polygonscan.com/token/0xffbce889d62f6c73f005b836255ad69b0a01b222'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0xFfbce889D62F6C73f005B836255aD69B0a01b222
                </Link>
              </>
            )
          }
        ]}
      />
    </>
  )
}

export default SectionMurAllRevo1tDi2rupt
