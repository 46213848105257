import { useContext, useMemo } from 'react'
import { SupportedChain } from '../lib/constants'
import MurAllContractAbiL1 from '../contracts/layer1/MurAll.json'
import MurAllNFTContractAbiL1 from '../contracts/layer1/MurAllNFT.json'
import PaintContractAbiL1 from '../contracts/layer1/PaintToken.json'
import MurAllMarketplaceAbiL1 from '../contracts/layer1/MurAllMarketplace.json'
import NftMetadataAbiL1 from '../contracts/layer1/NftMetadata.json'
import MerkleDistributorL1 from '../contracts/layer1/MerkleDistributor.json'
import NftDataStorage from '../contracts/layer2/NftDataStorage.json'
import MurAllContractAbiL2 from '../contracts/layer2/MurAll.json'
import MurAllNFTContractAbiL2 from '../contracts/layer2/MurAllNFT.json'
import PaintContractAbiL2 from '../contracts/layer2/PaintToken.json'
import AuctionMarketplaceContractAbiL2 from '../contracts/layer2/AuctionMarketplace.json'
import MurAllMarketplaceAbiL2 from '../contracts/layer2/MurAllMarketplace.json'
import NftMetadataAbiL2 from '../contracts/layer2/NftMetadata.json'
import IMontage from '../contracts/layer2/MontageNFT.json'
import MurAllNFTL2Contract from '../contracts/layer1/MurAllNFTL2.json'
import MontageNFTContract from '../contracts/layer1/MontageNFT.json'
import MontageNFTL2Contract from '../contracts/layer1/MontageNFTL2.json'
import MurAllFramesContract from '../contracts/layer1/MurAllFrame.json'
import { useActiveWeb3React } from './web3'
import { Web3L1Context, Web3L2Context } from '../App'
import getWeb3L1 from '../js/libs/getWeb3L1'
import getWeb3L2 from '../js/libs/getWeb3L2'

// returns null on errors
export const useContract = (buildMap, targetChainId, forceArchiveNode = false) => {
  const { library, chainId } = useActiveWeb3React()
  const web3L1 = useContext(Web3L1Context)
  const web3L2 = useContext(Web3L2Context)
  return useMemo(() => {
    const buildFile = buildMap && buildMap[targetChainId]
    if (!buildFile || !library || !targetChainId) return null
    const ABI = buildFile.abi
    const networkData = buildFile.networks[targetChainId]
    const address = networkData && networkData.address
    if (!ABI || !address) return null

    const libraryToUse =
      targetChainId === chainId && !!library && !forceArchiveNode
        ? library
        : targetChainId === SupportedChain.Ethereum
        ? web3L1 || getWeb3L1()
        : web3L2 || getWeb3L2()

    if (!libraryToUse) {
      console.error('Failed to get provider or library')
      return null
    }
    try {
      return new libraryToUse.eth.Contract(ABI, address)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [buildMap, library, chainId, targetChainId, web3L1, web3L2])
}

const generateBuildMap = (layer1Abi, layer2Abi) => {
  return useMemo(() => {
    return {
      [SupportedChain.Ethereum]: layer1Abi,
      [SupportedChain.Ropsten]: layer1Abi,
      [SupportedChain.Rinkeby]: layer1Abi,
      [SupportedChain.Goerli]: layer1Abi,
      [SupportedChain.LocalhostL1]: layer1Abi,
      [SupportedChain.Polygon]: layer2Abi,
      [SupportedChain.Mumbai]: layer2Abi,
      [SupportedChain.LocalhostL2]: layer2Abi
    }
  }, [layer1Abi, layer2Abi])
}

export const useMurAllContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(MurAllContractAbiL1, MurAllContractAbiL2)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useMurAllNFTContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(MurAllNFTContractAbiL1, MurAllNFTContractAbiL2)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useBridgedMurAllNFTContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(MurAllNFTL2Contract, MurAllNFTContractAbiL2)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const usePaintContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(PaintContractAbiL1, PaintContractAbiL2)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useAuctionMarketplaceContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(null, AuctionMarketplaceContractAbiL2)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useMarketplaceContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(MurAllMarketplaceAbiL1, MurAllMarketplaceAbiL2)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useNftMetadataContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(NftMetadataAbiL1, NftMetadataAbiL2)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useMerkleDistributorContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(MerkleDistributorL1, null)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useMontageContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(MontageNFTContract, IMontage)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useBridgedMontageContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(MontageNFTL2Contract, IMontage)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useNftDataStorageContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(null, NftDataStorage)
  return useContract(buildMap, chainId, forceArchiveNode)
}

export const useMurAllFramesContract = (chainId, forceArchiveNode = false) => {
  const buildMap = generateBuildMap(MurAllFramesContract, null)
  return useContract(buildMap, chainId, forceArchiveNode)
}
