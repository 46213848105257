import { useContext, useMemo } from 'react'
import { useActiveWeb3React } from './web3'
import { SupportedChain } from '../lib/constants'
import {
  EthersL1Context,
  EthersL2Context,
  EthersL2TestnetContext,
  EthersL1TestnetContext,
  EthersL1WebsocketContext,
  EthersL2WebsocketContext
} from '../App'
import { ethers } from 'ethers'

const stallTimeout = 2 * 1000

const quorum = 1

const fallbackMainnet = new ethers.providers.AlchemyProvider(
  'homestead',
  'gcQg7HfeVdRNa--z3c9LqMIkAeWF1gjG'
)
const fallbackGoerli = new ethers.providers.AlchemyProvider(
  'goerli',
  '8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn'
)

const fallbackPolygon = new ethers.providers.AlchemyProvider(
  'matic',
  'PLh-zCcMAGuB2pHN3c1Lz1x_5EIW_cps'
)
const fallbackMumbai = new ethers.providers.AlchemyProvider(
  'maticmum',
  'vfGuAfi-XIdXrB0QluxjUZaOC-J9CWaM'
)

const fallbackForChainId = chainId => {
  switch (chainId) {
    case SupportedChain.Ethereum:
      return fallbackMainnet
    case SupportedChain.Goerli:
      return fallbackGoerli
    case SupportedChain.Polygon:
      return fallbackPolygon
    case SupportedChain.Mumbai:
      return fallbackMumbai
    default:
      return null
  }
}

export const useEthersProviderForChainId = (
  chainId,
  forceArchiveNode = false,
  websocketInstance = false
) => {
  const { library, chainId: connectedChainId } = useActiveWeb3React()
  const ethersProviderL1 = useContext(EthersL1Context)
  const ethersProviderL2 = useContext(EthersL2Context)
  const ethersWebsocketProviderL1 = useContext(EthersL1WebsocketContext)
  const ethersWebsocketProviderL2 = useContext(EthersL2WebsocketContext)
  const ethersProviderTestnetL1 = useContext(EthersL1TestnetContext)
  const ethersProviderTestnetL2 = useContext(EthersL2TestnetContext)

  return useMemo(() => {
    let providerToUse = null
    if (websocketInstance) {
      providerToUse =
        chainId === SupportedChain.Ethereum
          ? ethersWebsocketProviderL1
          : ethersWebsocketProviderL2
    } else if (forceArchiveNode) {
      providerToUse =
        chainId === SupportedChain.Ethereum
          ? ethersProviderL1
          : chainId === SupportedChain.Mumbai
          ? ethersProviderTestnetL2
          : chainId === SupportedChain.Goerli
          ? ethersProviderTestnetL1
          : ethersProviderL2
    } else {
      const fallbackProvider = fallbackForChainId(chainId)

      let combinedFallbackProvider = null
      if (
        chainId === connectedChainId &&
        library?.ethersProvider &&
        fallbackProvider
      ) {
        combinedFallbackProvider = new ethers.providers.FallbackProvider(
          [
            {
              provider: library?.ethersProvider,
              priority: 2,
              weight: 1,
              stallTimeout
            },
            {
              provider: fallbackProvider,
              priority: 1,
              weight: 2,
              stallTimeout
            }
          ],
          quorum
        )
      }

      providerToUse = combinedFallbackProvider
        ? combinedFallbackProvider
        : chainId === SupportedChain.Ethereum
        ? ethersProviderL1
        : chainId === SupportedChain.Mumbai
        ? ethersProviderTestnetL2
        : chainId === SupportedChain.Goerli
        ? ethersProviderTestnetL1
        : ethersProviderL2
    }

    return providerToUse
  }, [connectedChainId, library, chainId])
}
