import React, { useEffect, useState } from 'react'
import useSmallScreenDetection from '../../../uicomponents/useSmallScreenDetection'
import { Typography, Box } from '@mui/material'
import StyledStepper from '../../../../components/desktop/stepper/StyledStepper'
import MintMobileStepper from '../../../../components/mobile/stepper/StyledStepper'
import { STEP_ID, steps } from '../../../../components/common/evolv3/mint_steps'
import useUploadFileToEvolv3ForMint from '../../../../hooks/evolv3/use-upload-file-to-evolv3-for-mint'
import LinearProgressWithLabel from '../../../../components/common/progress/LinearProgressWithLabel'
import useAllowPaintTransferToEvolv3ForAmount from '../../../../hooks/evolv3/use-allow-paint-transfer-to-evolv3-for-amount'
import useMintToEvolv3 from '../../../../hooks/evolv3/use-mint-to-evolv3'
import { EVOLV3_FILETYPES } from '../../../../lib/constants'

const Evolv3MintStepper = ({
  nftFileToUpload,
  paintUsage,
  nftUploadEndpoint,
  nftUploadEndpointFilePath,
  nftName,
  nftDescription = '',
  nftPosition,
  nftDimensions,
  nftFileType,
  nftAttributeKeys,
  nftAttributeValues,
  metaTransaction,
  onMintSuccess
}) => {
  const smallScreen = useSmallScreenDetection()

  const [activeStep, setActiveStep] = useState(0)
  const [shouldUploadFile, setShouldUploadFile] = useState(false)
  const [loadingStep, setLoadingStep] = useState(false)

  const StepperComponent = smallScreen ? MintMobileStepper : StyledStepper
  const activeStepId = steps[activeStep].id

  const {
    fileUploadProgress,
    loading: isUploadingFile,
    error: fileUploadError,
    fileData: currentFileData
  } = useUploadFileToEvolv3ForMint(
    nftFileToUpload,
    nftUploadEndpoint,
    nftUploadEndpointFilePath,
    shouldUploadFile && nftFileToUpload && activeStepId === STEP_ID.UPLOAD
  )

  const {
    apply: allowPaintTransferToEvolv3ForAmount,
    loading: isAllowingPaintTransfer,
    approved: isPaintTransferApproved,
    error: errorAllowingPaintTransfer
  } = useAllowPaintTransferToEvolv3ForAmount({
    paintTokenAmount: paintUsage,
    immediate: currentFileData && !isUploadingFile && paintUsage
  })

  const {
    apply: mintToEvolv3,
    error: errorMintingToEvolv3,
    loading: isMintingToEvolv3,
    success: isMintedToEvolv3,
    mintedNftData
  } = useMintToEvolv3({
    name: nftName,
    description: nftDescription,
    position: nftPosition,
    dimensions: nftDimensions,
    ipfsHash: currentFileData?.ipfsCid,
    fileType: nftFileType,
    videoLengthMillis:
      nftFileType === EVOLV3_FILETYPES.VIDEO
        ? currentFileData?.dimensions?.duration
        : 0,
    attributeKeys: nftAttributeKeys,
    attributeValues: nftAttributeValues,
    nonce: currentFileData?.nonce,
    verifierSignature: currentFileData?.signature,
    metaTransaction: metaTransaction
  })

  // reset stepper if file changes
  useEffect(() => {
    if (nftFileToUpload) {
      setActiveStep(0)
    }
  }, [nftFileToUpload])

  useEffect(() => {
    if (isAllowingPaintTransfer) {
      setLoadingStep(STEP_ID.ALLOWANCE)
      return
    }
    if (isPaintTransferApproved) {
      setActiveStep(steps.indexOf(steps.find(step => step.id === STEP_ID.MINT)))
    } else {
      console.log(
        'error - paint transfer not approved',
        errorAllowingPaintTransfer
      )
    }
    setLoadingStep(false)
  }, [isPaintTransferApproved, isAllowingPaintTransfer])

  useEffect(() => {
    if (isUploadingFile) {
      console.log('uploading file', fileUploadProgress, currentFileData)
      return
    }
    if (currentFileData) {
      console.log('success - currentFileData', currentFileData)
      setActiveStep(
        steps.indexOf(steps.find(step => step.id === STEP_ID.ALLOWANCE))
      )
    } else {
      console.log('error', fileUploadError)
    }
    setShouldUploadFile(false)
    setLoadingStep(false)
  }, [isUploadingFile, currentFileData, fileUploadError])

  useEffect(() => {
    if (isMintingToEvolv3) {
      setLoadingStep(STEP_ID.MINT)
      return
    }
    if (isMintedToEvolv3) {
      console.log('success - mintedNftData', mintedNftData)
      onMintSuccess && onMintSuccess(mintedNftData)
    } else {
      console.log('error', errorMintingToEvolv3)
    }
    setLoadingStep(false)
  }, [isMintingToEvolv3, isMintedToEvolv3, mintedNftData, errorMintingToEvolv3])

  return (
    <StepperComponent
      steps={steps}
      activeStep={activeStep}
      loading={steps[activeStep].id === loadingStep}
      onStepButtonClick={step => {
        switch (step.id) {
          case STEP_ID.UPLOAD:
            setShouldUploadFile(true)
            setLoadingStep(STEP_ID.UPLOAD)
            break
          case STEP_ID.ALLOWANCE:
            allowPaintTransferToEvolv3ForAmount()
            setLoadingStep(STEP_ID.ALLOWANCE)
            break
          case STEP_ID.MINT:
            mintToEvolv3()
            setLoadingStep(STEP_ID.MINT)
            break
        }
      }}
      labelForStep={step => step.label}
      buttonLabelForStep={step => step.buttonLabel}
      descriptionForStep={step => step.description}
      iconForStep={step =>
        steps.indexOf(step) < activeStep ? step.doneIcon : step.icon
      }
    >
      {steps[activeStep].id === STEP_ID.UPLOAD &&
        !isUploadingFile &&
        fileUploadError && (
          <Typography color='error'>
            Error uploading file. Please try again.
          </Typography>
        )}
      {steps[activeStep].id === STEP_ID.ALLOWANCE &&
        errorAllowingPaintTransfer && (
          <Typography color='error'>
            {errorAllowingPaintTransfer.message &&
            errorAllowingPaintTransfer.message.indexOf('User denied') != -1
              ? 'You rejected the transaction!'
              : `Sorry, the transaction failed: ${
                  errorAllowingPaintTransfer
                    ? errorAllowingPaintTransfer.name
                    : errorAllowingPaintTransfer
                }`}
          </Typography>
        )}
      {isUploadingFile && loadingStep === STEP_ID.UPLOAD && (
        <LinearProgressWithLabel
          variant={fileUploadProgress < 100 ? 'determinate' : 'indeterminate'}
          progressValue={fileUploadProgress.toFixed(0)}
          progressText={
            fileUploadProgress < 100
              ? `${fileUploadProgress.toFixed(0)}%`
              : 'Finishing upload...'
          }
          sx={{
            width: '100%'
          }}
        />
      )}
    </StepperComponent>
  )
}

export default Evolv3MintStepper
