import { useMemo } from 'react'
import { replaceIpfsIfNeeded } from '../js/libs/appUtils'
import { getMontageContractAddress } from '../js/modules/blockchain/datasource/ContractAddressDataSource'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  MURALL_WALL,
  MURALL_WALL_ID
} from '../lib/constants'
import { useApi } from './use-api'
import { useMontageContract } from './use-contract-for-chain'
import { useActiveWeb3React } from './web3'
import { mapInformationToNftObject } from '../js/modules/blockchain/NftDataMapper'
import { useSelector } from 'react-redux'

export const useMontageDataSource = wallId => {
  const { account } = useActiveWeb3React()

  const currentWallId = useSelector(state => state.wall.id)
  let wallIdToUse = wallId
  if (!wallIdToUse) {
    wallIdToUse = currentWallId
  }
  if (wallIdToUse === MURALL_WALL_ID.EVOLV3) {
    console.error(
      'useMontageDataSource not supported for EVOLV3 - use useMurAllEvolv3NftDataSource instead. Falling back to MURALL_WALL_ID.LAYER_2 which is also on Polygon'
    )
    wallIdToUse = MURALL_WALL_ID.LAYER_2
  }
  const wallInfo = MURALL_WALL[wallIdToUse]
  const api = useApi(wallInfo.chainId, wallIdToUse)

  const contract = useMontageContract(wallInfo.chainId)

  return useMemo(() => {
    const getOffchainRemoteData = tokenId => {
      return new Promise(async function (resolve, reject) {
        try {
          const res = await fetch(api.montages.montage(tokenId))
          const tokenInfo = await res.json()

          resolve(tokenInfo)
        } catch (error) {
          try {
            const url = await contract.methods.tokenURI(tokenId).call()
            const res = await fetch(url)
            const tokenInfo = await res.json()

            resolve(tokenInfo)
          } catch (error) {
            reject(error)
          }
        }
      })
    }

    const getMontageFullInformation = async tokenId => {
      let hasUnlockable = false
      let unlockableDescription = ''
      let canBeUnpacked = false
      let tokenIds = ''
      const metadata = await getOffchainRemoteData(tokenId)

      let base64PngString = null
      let croppedBase64PngString = null
      let positionInformation = {
        start: { x: 0, y: 0 },
        end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT
      }

      base64PngString = metadata.image
        ? metadata.image
        : 'images/montage_l2_placeholder.png'
      croppedBase64PngString = metadata.image
        ? metadata.image
        : 'images/montage_l2_placeholder.png'
      metadata.attributes.forEach(attribute => {
        if (attribute.trait_type === 'Token IDs') {
          tokenIds = attribute.value
        } else if (attribute.trait_type === 'Unlockable content') {
          hasUnlockable = attribute.value
        } else if (attribute.trait_type === 'Unlockable description') {
          unlockableDescription = attribute.value
        } else if (attribute.trait_type === 'Can be unpacked') {
          canBeUnpacked = attribute.value
        }
      })
      const nftDataObject = mapInformationToNftObject({
        contractAddress: getMontageContractAddress(wallInfo.chainId),
        tokenId,
        name: metadata.name,
        description: metadata.description,
        creator: metadata.creator,
        positionInformation,
        chainId: wallInfo.chainId,
        image: metadata.image,
        attributes: metadata.attributes,
        additionalInformation: {
          croppedBase64PngString,
          fullBase64PngString: base64PngString,
          name: metadata.name,
          canBeUnpacked,
          artist: metadata.creator,
          positionInformation,
          tokenIds,
          chainId: wallInfo.chainId,
          hasUnlockable,
          unlockableDescription,
          canSetUnlockable:
            metadata.creator != null &&
            account != null &&
            metadata.creator.toUpperCase() === account.toUpperCase(),
          imageUrl: metadata.image
        }
      })
      return {
        ...metadata,
        ...nftDataObject
      }
    }

    const getMontageFullInformationForTokenIds = tokenIds => {
      return Promise.all(
        tokenIds.map(async tokenId => {
          return getMontageFullInformation(tokenId)
        })
      )
    }

    return {
      getMontageInformation: tokenId => {
        return contract.methods.getMontageInformation(tokenId).call()
      },
      getMontageFullInformation,
      getMontageFullInformationForTokenIds,
      getMontageFullInformationForOwnedTokenInIndexRange: async (
        address,
        startIndex,
        endIndex
      ) => {
        const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
          startIndex,
          endIndex + 1
        )
        const tokenIds = await Promise.all(
          indexes.map(async index => {
            return contract.methods.tokenOfOwnerByIndex(address, index).call()
          })
        )
        return getMontageFullInformationForTokenIds(tokenIds)
      },
      getTokenIds: tokenId => {
        return contract.methods.getTokenIds(tokenId).call()
      },
      getCreator: tokenId => {
        return contract.methods.getCreator(tokenId).call()
      },
      getName: tokenId => {
        return contract.methods.getName(tokenId).call()
      },
      getDescription: tokenId => {
        return contract.methods.getDescription(tokenId).call()
      },
      canBeUnpacked: tokenId => {
        return contract.methods.canBeUnpacked(tokenId).call()
      },
      viewURIsInMontage: tokenId => {
        return contract.methods.viewURIsInMontage(tokenId).call()
      },
      getUnlockableDescription: (tokenId, account) => {
        return contract.methods
          .getUnlockableDescription(tokenId)
          .call({ from: account })
      },
      hasUnlockableContentUri: (tokenId, account) => {
        return contract.methods
          .hasUnlockableContentUri(tokenId)
          .call({ from: account })
      },
      getUnlockableContentUri: (tokenId, account) => {
        return contract.methods
          .getUnlockableContentUri(tokenId)
          .call({ from: account })
      },
      getOffchainRemoteData,
      setUnlockableContentUri: (
        tokenId,
        unlockableContentUri,
        unlockableContentDescription,
        account
      ) => {
        return contract.methods
          .setUnlockableContentUri(
            tokenId,
            unlockableContentUri,
            unlockableContentDescription
          )
          .send({ from: account })
      },
      createMontage: (name, description, canBeUnpacked, tokenIds, account) => {
        return contract.methods
          .createMontage(name, description, canBeUnpacked, tokenIds)
          .send({ from: account })
      },
      unpackMontage: (tokenId, account) => {
        return contract.methods.unpackMontage(tokenId).send({ from: account })
      },

      getBalanceForAddress: address => {
        return contract.methods.balanceOf(address).call()
      },
      ownerOf: tokenId => {
        return contract.methods.ownerOf(tokenId).call()
      },
      name: () => {
        return contract.methods.name().call()
      },
      symbol: () => {
        return contract.methods.symbol().call()
      },
      totalSupply: () => {
        return contract.methods.totalSupply().call()
      },
      exists: tokenId => {
        return contract.methods.exists(tokenId).call()
      },
      getOwnedTokenIdsInIndexRange: (ownerAddress, startIndex, endIndex) => {
        const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
          startIndex,
          endIndex + 1
        )

        return Promise.all(
          indexes.map(index =>
            contract.methods.tokenOfOwnerByIndex(ownerAddress, index).call()
          )
        )
      },
      getOwnedTokenIdAtIndex: (ownerAddress, index) => {
        return contract.methods.tokenOfOwnerByIndex(ownerAddress, index).call()
      },
      transferTokenId: (senderAddress, recipientAddress, tokenId) => {
        return contract.methods
          .transferFrom(
            senderAddress,
            recipientAddress,
            Web3.utils.toBN(tokenId)
          )
          .send({
            from: senderAddress
          })
      },
      setApproveTokenTransfer: (ownerAddress, spenderAddress, approved) => {
        return contract.methods
          .setApprovalForAll(spenderAddress, approved)
          .send({
            from: ownerAddress
          })
      },
      isTokenTransferApproved: (ownerAddress, spenderAddress) => {
        return contract.methods
          .isApprovedForAll(ownerAddress, spenderAddress)
          .call()
      },
      getTokenUri: tokenId => {
        return contract.methods.tokenURI(tokenId).call()
      },
      getOffchainRemoteData: tokenId => {
        return new Promise(async function (resolve, reject) {
          try {
            const url = await contract.methods.tokenURI(tokenId).call()
            const res = await fetch(url)
            const tokenInfo = await res.json()

            resolve(tokenInfo)
          } catch (error) {
            reject(error)
          }
        })
      },
      getFullTokenUriMetadata: async tokenId => {
        let tokenUri = await contract.methods.tokenURI(tokenId).call()

        tokenUri = replaceIpfsIfNeeded(tokenUri)

        if (tokenUri.indexOf('http') === -1) {
          tokenUri = 'https://' + tokenUri
        }

        let returnData
        if (
          tokenUri &&
          tokenUri.startsWith('https://data:application/json;base64')
        ) {
          const json = Buffer.from(
            tokenUri.replace('https://data:application/json;base64', ''),
            'base64'
          )
          returnData = JSON.parse(json)
        } else {
          try {
            const response = await fetch(tokenUri)
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`)
            }
            returnData = await response.json()
          } catch (error) {
            console.error('Fetch error:', error)
            // Handle errors appropriately
          }
        }

        return returnData
      }
    }
  }, [api, contract, wallIdToUse, currentWallId, wallId])
}
