import React, { useEffect, useState } from 'react'
import {
  Typography,
  Button,
  Box,
  Icon,
  Link,
  Grid,
  FormControlLabel,
  useTheme
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import StyledImageViewerDialog from '../../uicomponents/styled_image_viewer_dialog'
import './../../../css/layout.css'
import { Link as RouterLink } from 'react-router-dom'
import {
  useTextToImageMutation,
  useFetchResultsMutation
} from '../../../state/imageApi'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import ImageGenerationInput from '../../../components/common/ai/image-generation-input'
import ErrorMessageView from '../../uicomponents/error_message_view'
import DefaultVirtualizedGrid from '../../../components/common/DefaultVirtualizedGrid'
import styled from 'styled-components'
import SelectableGridImageCard from '../../../components/common/SelectableGridImageCard'
import CountdownLoadingButton from '../../../components/common/CountdownLoadingButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import AILoadingAnimation from '../../../components/common/ai/AILoadingAnimation'
import AILogo from '../../../../images/AI.png'
import MurAllLogoFilled from '../../../../images/logos/murall/pink.svg'
import MurAllLogoOutline from '../../../../images/logos/murall/white_outline.svg'
import { CustomImageIcon } from '../../../components/common/CustomImageIcon'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'
import { DefaultContainer } from '../../../components/common/DefaultContainer'
import Checkbox from '@mui/material/Checkbox'
import PulseBorder from '../../../components/common/pulse-border'
import { SCHEDULER_TYPES } from '../../../lib/constants'

const useStyles = makeStyles(theme => ({
  typography: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto'
    // fontWeight: 100,
  }
}))
const LOADING_PLACEHOLDER = 'Loading...'
const NUMBER_OF_IMAGES = 2

const Border = styled(PulseBorder)`
  height: 3px;
  position: relative;
`

export default function ImageGenerationDialog ({
  open,
  onCloseClicked,
  onImportClicked
}) {
  const classes = useStyles()

  const theme = useTheme()
  const [generatingImage, setGeneratingImage] = useState(false)
  const [generatedImagesPrompt, setGeneratedImagesPrompt] = useState(false)
  const [generatedImages, setGeneratedImages] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [processingId, setProcessingId] = useState('')
  const [processingEta, setProcessingEta] = useState('')
  const [prompt, setPrompt] = useState('')
  const [negativePrompt, setNegativePrompt] = useState(
    '(bad anatomy, bad art, frame, deformed, disfigured, extra limbs, text, meme, low quality, mutated, ordinary, overexposed, pixelated, poorly drawn, signature, thumbnail, too dark, too light, unattractive, useless, watermark, writing, cropped:1.1)'
  )
  const [scheduler, setScheduler] = useState(SCHEDULER_TYPES.EulerAncestralDiscrete)
  const [guidanceScale, setGuidanceScale] = useState(10.5)
  const [seed, setSeed] = useState(null)
  const [murAllify, setMurAllify] = useState(false)
  const [promptError, setPromptError] = useState(null)

  const [generateImage, response] = useTextToImageMutation()
  const [fetchResults, response2] = useFetchResultsMutation()
  const smallScreen = useSmallScreenDetection()

  useEffect(() => {
    console.log('generatingImage', generatingImage)
  }, [generatingImage])

  const input = (
    <DefaultContainer
      sx={{
        width: 'calc(100% - 32px)',
        flexDirection: smallScreen ? 'column' : 'row'
      }}
    >
      <ImageGenerationInput
        sx={{
          width: '100%'
        }}
        loading={generatingImage}
        prompt={prompt}
        error={promptError}
        onPromptChanged={prompt => {
          setPrompt(prompt)
          setPromptError(null)
        }}
        scheduler={scheduler}
        guidanceScale={guidanceScale}
        seed={seed}
        negativePrompt={negativePrompt}
        onNegativePromptChanged={negativePrompt => {
          setNegativePrompt(negativePrompt)
        }}
        onSchedulerChanged={scheduler => {
          setScheduler(scheduler)
        }}
        onGuidanceScaleChanged={guidanceScale => {
          setGuidanceScale(guidanceScale)
        }}
        onSeedChanged={seed => {
          setSeed(seed)
        }}
        onGenerateClicked={generationValues => {
          setGeneratingImage(true)
          setPromptError(null)
          // clear selected images
          setSelectedImages([])
          // fill generatedImages with loading placeholders
          setGeneratedImages(
            Array.from(Array(NUMBER_OF_IMAGES), (e, i) => LOADING_PLACEHOLDER)
          )
          setGeneratedImagesPrompt(generationValues.prompt)
          const variables = {
            prompt: generationValues.prompt,
            negativePrompt: generationValues.negativePrompt,
            seed: generationValues.seed,
            guidanceScale: generationValues.guidanceScale,
            scheduler: generationValues.scheduler,
            murAllify: murAllify
          }
          generateImage(variables)
            .unwrap()
            .then(result => {
              console.log('Generated images', result)
              const images = result.images
              const eta = Math.ceil(result.eta)

              const fetchResultId = result.fetchResultId
              if (images.length === 0) {
                if (fetchResultId && result.status === 'processing') {
                  setGeneratingImage(false)
                  setProcessingId(fetchResultId)
                  setProcessingEta(eta)
                } else {
                  setGeneratingImage(false)
                  setPromptError(
                    "Couldn't generate image. Please try again later."
                  )
                }
              } else {
                fetchResults({
                  resultId: result.fetchResultId,
                  asBase64: true
                })
                  .unwrap()
                  .then(result => {
                    setGeneratingImage(false)
                    setGeneratedImages(result.images)
                  })
              }
            })
            .catch(error => {
              setGeneratingImage(false)
              setGeneratedImages([])
              console.error('error generating images', error)
              setPromptError("Couldn't generate image. Please try again later.")
            })
        }}
        inputChildren={
          <Checkbox
            checked={murAllify}
            color={murAllify ? 'secondary' : 'default'}
            onChange={event => {
              setMurAllify(event.target.checked)
            }}
            name='checkedA'
            icon={
              <Icon style={{ fontSize: 25, textAlign: 'center' }}>
                <img src={MurAllLogoOutline} alt={'MurAllify unticked'} />
              </Icon>
            }
            checkedIcon={
              <Icon style={{ fontSize: 25, textAlign: 'center' }}>
                <img src={MurAllLogoFilled} alt={'MurAllify ticked'} />
              </Icon>
            }
          />
        }
      />
    </DefaultContainer>
  )

  return (
    <StyledImageViewerDialog
      open={open}
      withCloseButton
      fullScreen
      onClose={onCloseClicked}
      {...(generatedImages &&
        generatedImages.length > 0 && {
          dialogTitle: input
        })}
      {...(generatingImage && {
        dialogTitle: input
      })}
      {...(smallScreen && {
        dialogTitle: input
      })}
      dialogImageContent={
        <>
          {processingId && !generatingImage ? (
            <ErrorMessageView
              title={
                <>
                  <Grid
                    container
                    direction={smallScreen ? 'column' : 'row'}
                    alignItems='center'
                  >
                    <Grid item>
                      <AILoadingAnimation
                        style={{
                          width: '100px',
                          height: '100px'
                        }}
                      />
                    </Grid>
                    <Grid item>Processing</Grid>
                  </Grid>
                </>
              }
              description={
                <>
                  Our AI is experiencing a high load of requests.
                  <br />
                  <br />
                  Please wait {processingEta} seconds for your image to be
                  processed then click the button below to fetch the results.
                  <br />
                  <br />
                  <Border />
                  <br />
                </>
              }
            >
              <CountdownLoadingButton
                sx={{
                  mt: 2
                }}
                fullWidth
                text={
                  processingEta == 0
                    ? 'Fetch processed results'
                    : 'Processing - please wait ' + processingEta + ' seconds'
                }
                onTick={time => {
                  setProcessingEta(
                    processingEta - 1 > 0 ? processingEta - 1 : 0
                  )
                }}
                loading={processingEta > 0}
                endIcon={<RefreshIcon />}
                loadingPosition={'end'}
                onClick={() => {
                  setGeneratingImage(true)
                  fetchResults({
                    resultId: processingId,
                    asBase64: true
                  })
                    .unwrap()
                    .then(result => {
                      console.log('results for fetch', result)
                      if (
                        result.images.length !== 0 &&
                        result.status === 'success'
                      ) {
                        setGeneratingImage(false)
                        setProcessingId(null)
                        setGeneratedImages(result.images)
                      } else {
                        setProcessingEta(Math.ceil(result.eta))
                        setGeneratingImage(false)
                      }
                    })
                }}
              />
            </ErrorMessageView>
          ) : generatingImage ||
            (generatedImages && generatedImages.length > 0) ? (
            <DefaultVirtualizedGrid
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0
              }}
              customItemContainer={ItemContainer}
              items={generatedImages}
              loadingPlaceholder={
                <SelectableGridImageCard
                  loading={true}
                  selected={false}
                  twoByOne
                >
                  <AILoadingAnimation />
                </SelectableGridImageCard>
              }
              renderItem={(index, image) => {
                return (
                  <SelectableGridImageCard
                    withCheckbox
                    loading={image === LOADING_PLACEHOLDER}
                    selected={
                      image !== LOADING_PLACEHOLDER &&
                      selectedImages.includes(image)
                    }
                    twoByOne
                    {...(image !== LOADING_PLACEHOLDER && {
                      image: image
                    })}
                    onClick={() => {
                      if (image !== LOADING_PLACEHOLDER) {
                        // add image to selectedImages, or remove it if it's already there
                        if (selectedImages.includes(image)) {
                          setSelectedImages(
                            selectedImages.filter(i => i !== image)
                          )
                        } else {
                          setSelectedImages([...selectedImages, image])
                        }
                      }
                    }}
                  >
                    {image === LOADING_PLACEHOLDER && (
                      <AILoadingAnimation
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          aspectRatio: '2/1'
                        }}
                      />
                    )}
                  </SelectableGridImageCard>
                )
              }}
            />
          ) : (
            <ErrorMessageView
              title={
                <>
                  <Grid
                    container
                    direction={smallScreen ? 'column' : 'row'}
                    alignItems='center'
                  >
                    <Grid item>
                      <CustomImageIcon
                        sx={{
                          width: '100px',
                          height: '100px'
                        }}
                        src={AILogo}
                      />
                    </Grid>
                    <Grid item>Generate Images with AI!</Grid>
                  </Grid>
                </>
              }
              description={
                <>
                  Type a description of what you want to see into the Image
                  prompt above then click{' '}
                  <ImageSearchIcon
                    style={{
                      fontSize: 20,
                      textAlign: 'center',
                      color: 'white'
                    }}
                  />{' '}
                  to get started
                  <br />
                  Once generated, select the images you like and click IMPORT to
                  add them as new layers in the editor
                  <br />
                  <br />
                  TIP: Toggle the{' '}
                  <Icon
                    style={{
                      textAlign: 'center',
                      color: 'white',
                      overflow: 'visible'
                    }}
                  >
                    <img src={MurAllLogoOutline} alt={'MurAllify unticked'} />
                  </Icon>{' '}
                  MurAll option to give your image some MurAll flair to your
                  image!
                </>
              }
            >
              {!smallScreen && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    mt: 12,
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  {input}
                </Box>
              )}
            </ErrorMessageView>
          )}
        </>
      }
      dialogActions={
        <>
          <Typography
            className={classes.typography}
            variant='body1'
            component='p'
            align='justify'
            style={{
              flex: 1,
              alignSelf: 'flex-start',
              marginRight: '16px',
              marginLeft: '16px'
            }}
          >
            By clicking 'Generate' you agree to the{' '}
            <Link
              component={RouterLink}
              to='/terms'
              color='secondary'
              underline='hover'
            >
              Terms and Conditions
            </Link>
            .
          </Typography>
          {generatedImages && generatedImages.length > 0 && (
            <Button
              size={smallScreen ? 'medium' : 'large'}
              color='secondary'
              onClick={() => {
                onImportClicked &&
                  onImportClicked({
                    prompt: generatedImagesPrompt,
                    images: selectedImages,
                    format: 'base64'
                  })
              }}
              variant={'contained'}
              disabled={selectedImages.length === 0}
              sx={{ mr: '16px', mb: '16px' }}
            >
              Import
            </Button>
          )}
        </>
      }
    />
  )
}

const ItemContainer = styled.div`
  padding: 0.5rem;
  aspect-ratio: 2/1;
  width: 100%;
  display: flex;
  flex: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (min-width: 900px) {
    width: 50%;
  }
`
