import { useEffect, useState } from 'react'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../../lib/constants'
import Base_layers_class from '../../js/core/base-layers'
import Get_image_for_layer_usecase from '../../js/modules/blockchain/usecase/get_image_for_layer_usecase'

const useValidateBoundsForLayer = ({ layer }) => {
  const [loading, setLoading] = useState(false)

  const [inBounds, setInBounds] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!layer) {
      return
    }
    async function checkIsValid () {
      setLoading(true)
      let outOfBounds = false
      let layerPosition = { x: 0, y: 0 }
      let layerDimensions = { width: 0, height: 0 }
      if (layer.type === 'image') {
        const {
          positionInformation
        } = await new Get_image_for_layer_usecase().execute(layer)

        layerPosition.x = positionInformation.start.x
        layerPosition.y = positionInformation.start.y
        layerDimensions = {
          width: positionInformation.croppedWidth,
          height: positionInformation.croppedHeight
        }

        // if the layer is an image, the image is cropped if it is out of bounds, so we need to take
        // this into account
        const visibleWidth = layerPosition.x + positionInformation.croppedWidth
        const visibleHeight =
          layerPosition.y + positionInformation.croppedHeight

        outOfBounds =
          visibleWidth <= 0 ||
          visibleHeight <= 0 ||
          layerPosition.x >= CANVAS_WIDTH ||
          layerPosition.y >= CANVAS_HEIGHT ||
          positionInformation.croppedWidth === 0 ||
          positionInformation.croppedHeight === 0
      } else if (layer.type === 'video') {
        outOfBounds =
          layer.x < 0 ||
          layer.y < 0 ||
          layer.x + layer.width > CANVAS_WIDTH ||
          layer.y + layer.height > CANVAS_HEIGHT

        layerPosition = { x: layer.x, y: layer.y }
        layerDimensions = { width: layer.width, height: layer.height }
      }

      setInBounds(!outOfBounds)
      setPosition(layerPosition)
      setDimensions(layerDimensions)

      setLoading(false)
    }
    checkIsValid()
  }, [layer])

  return { inBounds, loading, position, dimensions }
}

export default useValidateBoundsForLayer
