import * as React from 'react'
import './../../../css/layout.css'
import { Box, TextField as MuiTextField } from '@mui/material'
import { TRANSPARENT_IMAGE_DATA_URL } from '../../../lib/constants'

import { useTheme } from '@mui/material/styles'
import VirtualisedAutocompleteField from '../../../js/pages/history/range-picker/range-fields/virtualised-autocomplete-field'
import LayerSelectListItem from './LayerSelectListItem'
import useLayers from '../../../hooks/layers/use-layers'

const backgroundForLayer = (previewImage, selected, theme) => {
  return selected
    ? `radial-gradient(circle at top right,
        ${theme.palette.secondary.main}cc 30%,
        ${theme.palette.primary.main}),
        url(${previewImage}),
        linear-gradient(to right, #26262B, #26262B)`
    : `radial-gradient(circle at top right,
    #cccccc,
    #4a4a4a,
    #212121),
    url(${previewImage}),
    linear-gradient(to right, #26262B, #26262B)`
}

export default function LayerSelectMenu ({
  label,
  helperLabel,
  layers,
  previewForLayer,
  loading,
  onLayerSelected,
  value,
  sx
}) {
  const theme = useTheme()

  return (
    <VirtualisedAutocompleteField
      sx={sx}
      id='layer-select-autocomplete'
      options={layers}
      value={value}
      autoHighlight
      getOptionLabel={layer => layer.name}
      renderVirtualisedOption={(props, layer, inlineStyle) => (
        <LayerSelectListItem
          {...props}
          style={inlineStyle}
          key={layer}
          disablePadding
          id={layer}
          layer={layer}
          loading={loading}
          layerPreviewImage={
            loading || !previewForLayer(layer.id)
              ? TRANSPARENT_IMAGE_DATA_URL
              : previewForLayer(layer.id)
          }
          primaryText={layer.name}
          selected={layer === value}
        />
      )}
      renderInput={params => (
        <Box sx={{ zIndex: 999 }}>
          <MuiTextField
            {...params}
            label={label}
            helperText={helperLabel}
            inputProps={{
              ...params.inputProps,
              // autocomplete: 'new-password',
              autocomplete: 'off',
              form: {
                autocomplete: 'off'
              }
            }}
            InputProps={{
              ...params.InputProps,
              sx: {
                ...(value && {
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  backgroundBlendMode: `multiply, normal, normal`,
                  backgroundImage: backgroundForLayer(
                    loading || !previewForLayer(value.id)
                      ? TRANSPARENT_IMAGE_DATA_URL
                      : previewForLayer(value.id),
                    false,
                    theme
                  )
                })
              }
            }}
            sx={{
              '&, & .MuiTypography-root': {
                textShadow: '2px 2px 5px black'
              }
            }}
          />
        </Box>
      )}
      onChange={(e, value, reason) => {
        if (reason === 'selectOption') {
          onLayerSelected(value)
        } else if (reason === 'clear') {
          onLayerSelected(null)
        }
      }}
    />
  )
}
