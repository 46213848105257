import React, { useEffect, useRef, useState } from 'react'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../../../lib/constants'
import SvgDraggableImage from './svg-draggable-image'
import SvgHistoryState from './svg-history-state'
import { gsap } from 'gsap'
import { startLocateAnimation } from './animations'

const DEFAULT_LOCATE_ANIMATION_DURATION_MILLIS = 800
export default function SvgImageViewer ({
  style,
  nfts,
  selectedIndex,
  baseState,
  animationDuration,
  disableTouch,
  locateAnimationDuration
}) {
  if (!nfts || nfts.length === 0) {
    return null
  }

  const timeline = gsap.timeline()

  const blurRef = useRef(null)
  const fadeRRef = useRef(null)
  const fadeGRef = useRef(null)
  const fadeBRef = useRef(null)

  const currentLocateAnim = useRef(null)

  const [isLocating, setLocating] = useState(false)

  const lastMove = useRef(null)
  const canvas = useRef(null)

  const [positions, setPositions] = useState(new Map())
  const locateAnimDuration =
    locateAnimationDuration || DEFAULT_LOCATE_ANIMATION_DURATION_MILLIS

  useEffect(() => {
    if (selectedIndex !== null) {
      setLocating(true)
      startLocateAnimation(
        timeline,
        locateAnimDuration,
        blurRef,
        fadeRRef,
        fadeGRef,
        fadeBRef,
        currentLocateAnim,
        () => {
          setLocating(false)
        }
      )
    }
  }, [selectedIndex])

  const getPosition = nft => {
    const position = positions.get(nft.contractAddress + nft.tokenId)
    if (position) {
      return position
    }
    const positionForNft = {
      x: nft.positionInformation.start.x,
      y: nft.positionInformation.start.y,
      active: false,
      offset: {}
    }

    setPositions(
      new Map(positions.set(nft.contractAddress + nft.tokenId, positionForNft))
    )
    return positionForNft
  }

  const updatePosition = (nft, x, y) => {
    const position = getPosition(nft)
    if (position) {
      position.x = x
      position.y = y
      setPositions(
        new Map(positions.set(nft.contractAddress + nft.tokenId, position))
      )
    }
  }

  const getSelectedNftInfo = () => {
    const selectedNft = selectedIndex !== null && nfts[selectedIndex]
    const position = selectedNft && getPosition(selectedNft)
    return { selectedNft, position }
  }

  const handleTouchStart = e => {
    const touch = e.touches[0]
    // e.preventDefault()
    handleStart(touch)
  }

  const handleTouchMove = e => {
    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0]
      // e.preventDefault()
      handleMove(touch)
    }
  }

  const handlePointerDown = e => {
    e.preventDefault()
    handleStart(e)
  }

  const handlePointerMove = e => {
    e.preventDefault()
    handleMove(e)
  }

  const handleStart = e => {
    const { selectedNft, position } = getSelectedNftInfo()
    if (!selectedNft || !position) {
      return
    }

    const svgElement = e.target.ownerSVGElement || e.target

    lastMove.current = globalToLocalCanvasCoords(
      svgElement,
      e.clientX,
      e.clientY
    )

    position.active = true

    setPositions(
      new Map(
        positions.set(
          selectedNft.contractAddress + selectedNft.tokenId,
          position
        )
      )
    )
  }

  const handleMove = e => {
    const { selectedNft, position } = getSelectedNftInfo()

    if (!selectedNft || !positionForSelectedIndex || !position.active) {
      return
    }

    const el = e.target.ownerSVGElement || e.target
    const currentMove = globalToLocalCanvasCoords(el, e.clientX, e.clientY)

    const dx = currentMove.x - lastMove.current.x
    const dy = currentMove.y - lastMove.current.y

    lastMove.current = currentMove

    updatePosition(selectedNft, position.x + dx, position.y + dy)
  }

  const handleEnd = e => {
    const selectedNft = selectedIndex !== null && nfts[selectedIndex]
    const position = selectedNft && getPosition(selectedNft)

    if (!selectedNft || !position) {
      return
    }
    position.active = false
    setPositions(
      new Map(
        positions.set(
          selectedNft.contractAddress + selectedNft.tokenId,
          position
        )
      )
    )
  }

  const globalToLocalCanvasCoords = (ownerSvgElement, x, y) => {
    var p = ownerSvgElement.createSVGPoint()
    const canvasElementMatrix = canvas.current.getScreenCTM().inverse()

    p.x = x
    p.y = y

    return p.matrixTransform(
      canvasElementMatrix.multiply(ownerSvgElement.getScreenCTM())
    )
  }

  const { selectedNft, position } = getSelectedNftInfo()
  const positionForSelectedIndex = selectedNft && getPosition(selectedNft)
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      {...(!disableTouch &&
        !isLocating &&
        selectedNft && {
          onPointerDown: handlePointerDown,
          onPointerMove: handlePointerMove,
          onPointerUp: handleEnd,
          onTouchStart: handleTouchStart,
          onTouchMove: handleTouchMove,
          onTouchCancel: handleEnd,
          onTouchEnd: handleEnd
        })}
      style={{
        overflow: 'visible',
        ...(!disableTouch &&
          selectedNft && {
            pointerEvents: 'all'
          }),
        ...(disableTouch && {
          pointerEvents: 'none'
        }),
        ...(style && {
          ...style
        })
      }}
    >
      <defs>
        <filter id='blurFilter' width='100%' height='100%' x='0' y='0'>
          <feGaussianBlur
            ref={blurRef}
            id='blur'
            in='SourceGraphic'
            stdDeviation='0'
            result='blurred'
          />
          <feComponentTransfer id={'darkened'}>
            <feFuncR ref={fadeRRef} id='darkenR' type='linear' slope='1' />
            <feFuncG ref={fadeGRef} id='darkenG' type='linear' slope='1' />
            <feFuncB ref={fadeBRef} id='darkenB' type='linear' slope='1' />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode in='neutral' />
            <feMergeNode in='darkened' />
          </feMerge>
        </filter>
      </defs>
      <svg
        viewBox={`0 0 ${CANVAS_WIDTH} ${CANVAS_HEIGHT}`}
        style={{
          overflow: 'visible'
        }}
      >
        <g
          ref={canvas}
          style={{
            overflow: 'visible',
            ...(selectedNft && {
              pointerEvents: 'none'
            }),
            ...(isLocating && { filter: 'url(#blurFilter)' })
          }}
        >
          {baseState && (
            <SvgHistoryState
              image={baseState.image}
              x={baseState.positionInformation.start.x}
              y={baseState.positionInformation.start.y}
              height={baseState.positionInformation.height}
              width={baseState.positionInformation.width}
            />
          )}

          {nfts.map((nft, index) => {
            const position = getPosition(nft)
            return (
              <SvgDraggableImage
                animationDuration={`${animationDuration}ms`}
                image={nft.image}
                x={position.x}
                y={position.y}
                height={nft.positionInformation.croppedHeight}
                width={nft.positionInformation.croppedWidth}
                onPositionUpdated={(x, y) => {
                  updatePosition(nft, x, y)
                }}
                disableTouch={disableTouch || selectedNft || isLocating}
              />
            )
          })}
        </g>
        {isLocating && positionForSelectedIndex && selectedNft && (
          <SvgHistoryState
            animationDuration={`${locateAnimDuration}ms`}
            shouldLocate={isLocating}
            image={selectedNft.image}
            x={positionForSelectedIndex.x}
            y={positionForSelectedIndex.y}
            height={selectedNft.positionInformation.croppedHeight}
            width={selectedNft.positionInformation.croppedWidth}
            disableTouch={true}
            useGlitchEffect={true}
          />
        )}
      </svg>
    </svg>
  )
}
