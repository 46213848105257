import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addPaintedEvents } from './slices/events'
import processPaintedEvent from '../lib/actions/process-painted-event'
import { paintedEvent } from '../lib/notification'
import { MURALL_WALL_ID } from '../lib/constants'
import { useMurAllEvolv3NftDataSource } from '../hooks/use-murall-evolv3-nft-datasource'
import useListenForEvolv3Mints from '../hooks/evolv3/use-listen-for-evolv3-mints'
import { IPFS_GATEWAY_PINATA_MURALL, replaceIpfsIfNeeded } from '../js/libs/appUtils'
import useListenForPaintedEvents from '../hooks/murall/use-listen-for-painted-events'

const processEventAndDispatch = async (event, processTask, wallId, dispatch) => {
  processTask(event)
    .then((nft) => {
      paintedEvent(nft)
      dispatch(
        addPaintedEvents({
          targetWallId: wallId,
          events: [nft]
        })
      )
    })
    .catch((error) => {
      console.error('Error processing event for wall id ' + wallId, error)
    })
}

export default function Updater() {
  const dispatch = useDispatch()

  const evolv3Mints = useListenForEvolv3Mints({
    active: true
  })
  const evolv3Datasource = useMurAllEvolv3NftDataSource()
  const revo1tMints = useListenForPaintedEvents({ wallId: MURALL_WALL_ID.LAYER_1, active: true })
  const d12ruptMints = useListenForPaintedEvents({ wallId: MURALL_WALL_ID.LAYER_2, active: true })

  const processEvolv3Mint = useCallback(
    async (event) => {
      const nft = await evolv3Datasource.getTokenMetadataForTokenId(event.tokenId)
      const preferredImageUrl = replaceIpfsIfNeeded(nft.fileUri, IPFS_GATEWAY_PINATA_MURALL)
      return {
        ...nft,
        image: preferredImageUrl,
        fullBase64PngString: preferredImageUrl,
        croppedBase64PngString: preferredImageUrl
      }
    },
    [evolv3Datasource]
  )
  // listen to evolv3 mints
  useEffect(() => {
    if (evolv3Mints?.length) {
      processEventAndDispatch(evolv3Mints[0], processEvolv3Mint, MURALL_WALL_ID.EVOLV3, dispatch)
    }
  }, [evolv3Mints, processEvolv3Mint, dispatch])

  // listen to Painted events
  useEffect(() => {
    if (revo1tMints?.length) {
      processEventAndDispatch(revo1tMints[0], processPaintedEvent, MURALL_WALL_ID.LAYER_1, dispatch)
    }
  }, [revo1tMints, dispatch])

  useEffect(() => {
    if (d12ruptMints?.length) {
      processEventAndDispatch(d12ruptMints[0], processPaintedEvent, MURALL_WALL_ID.LAYER_2, dispatch)
    }
  }, [d12ruptMints, dispatch])

  return null
}
