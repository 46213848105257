import Slider from '@mui/material/Slider'
import makeStyles from '@mui/styles/makeStyles'
import { Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { MURALL_WALL, MURALL_WALL_ID } from '../../../lib/constants'
import clsx from 'clsx'
import { useApi } from '../../../hooks/use-api'

const useStyles = makeStyles(theme => ({
  slider: {
    color: theme.palette.secondary.main
  }
}))

function ImageWithFallback({ thumbnailSrc, fallbackSrc }) {
  const [src, setSrc] = useState(thumbnailSrc)

  return (
    <img
      src={src}
      onError={() => setSrc(fallbackSrc)}
      style={{
        width: '75px',
        aspectRatio: '2/1',
        objectFit: 'contain',
        pointerEvents: 'none'
      }}
    />
  )
}

export default function TokenThumbnailSlider(props) {
  const currentWall = useSelector(state => state.wall)
  const wallInfo = MURALL_WALL[currentWall.id]
  const api = useApi(wallInfo.chainId, currentWall.id)

  const classes = useStyles()
  const [hovering, setHovering] = useState(false)
  // const [activeThumb, setActiveThumb] = useState(0)
  const [hoveringThumb, setHoveringThumb] = useState(false)
  const [hoveringTokenId, setHoveringTokenId] = useState(props.value)
  const positionRef = useRef({
    x: 0,
    y: 0
  })
  const popperRef = useRef(null)
  const areaRef = useRef(null)

  useEffect(() => {
    const thumbs = document.getElementsByClassName('MuiSlider-thumb')

    const cleanups = []

    for (const thumb of thumbs) {
      const enterListener = () => {
        setHoveringThumb(true)
      }
      const leaveListener = () => {
        setHoveringThumb(false)
      }

      thumb.addEventListener('mouseenter', enterListener)
      thumb.addEventListener('mouseleave', leaveListener)

      const cleanup = () => {
        thumb.removeEventListener('mouseenter', enterListener)
        thumb.removeEventListener('mouseleave', leaveListener)
      }
      cleanups.push(cleanup)
    }

    return () => {
      cleanups.forEach(cleanup => cleanup())
    }
  }, [])
  const thumbnailPlacement = props.thumbnailPlacement || 'top'
  const stateImageUrlForValue = value => {
    return `https://murall.art/images/thumbnails/${
      wallInfo.id === MURALL_WALL_ID.LAYER_1
        ? 'ethereum'
        : wallInfo.id === MURALL_WALL_ID.LAYER_2
        ? 'polygon'
        : 'evolv3'
    }/state/state_${value}.png`
  }

  return (
    <>
      <Tooltip
        key={hoveringTokenId}
        open={hovering && !hoveringThumb}
        placement={thumbnailPlacement}
        arrow
        enterTouchDelay={0}
        PopperProps={{
          popperRef,
          anchorEl: {
            clientHeight: 0,
            clientWidth: 0,
            getBoundingClientRect: () => ({
              top:
                thumbnailPlacement === 'top'
                  ? areaRef.current?.getBoundingClientRect().top ?? 0
                  : areaRef.current?.getBoundingClientRect().bottom ?? 0,
              left: positionRef.current.x,
              right: positionRef.current.x,
              bottom: areaRef.current?.getBoundingClientRect().bottom ?? 0,
              width: 0,
              height: 0
            })
          }
        }}
        sx={{
          pointerEvents: 'none'
        }}
        TransitionProps={{ timeout: 0 }} // Disable transition animation to prevent flickering
        // title={
        //   <ImageWithFallback
        //     thumbnailSrc={stateImageUrlForValue(hoveringTokenId)}
        //     fallbackSrc={api.images.history(hoveringTokenId)}
        //   />
        // }
      >
        <Slider
          className={clsx(props.className && props.className, classes.slider)}
          defaultValue={props.defaultValue}
          value={props.value}
          max={props.max}
          min={props.min}
          step={props.step}
          ref={areaRef}
          sx={{
            '& .MuiSlider-track': {
              border: 'none'
            },
            '& .MuiSlider-valueLabel': {
              width: '75px',
              aspectRatio: '2/1'
            }
          }}
          onPointerEnter={() => setHovering(true)}
          onPointerLeave={() => setHovering(false)}
          onPointerMove={e => {
            if (hovering) {
              var rect = e.target.getBoundingClientRect()

              positionRef.current = { x: e.clientX, y: e.clientY }
              var x = e.clientX - rect.left //x position within the element.

              const max = parseInt(props.max) + parseInt(props.startTokenId)
              const min = parseInt(props.startTokenId)
              const tokenId = Math.round(
                ((x / rect.width) * 100 * (max - min)) / 100 + min
              )

              setHoveringTokenId(tokenId)
            }
            if (popperRef.current != null) {
              popperRef.current.update()
            }
          }}
          onChange={(e, value, activeThumb) => {
            // setActiveThumb(activeThumb)

            props.onChange && props.onChange(e, value, activeThumb)
          }}
          className={clsx(props.className && props.className, classes.slider)}
          valueLabelDisplay={'off'}
          // valueLabelFormat={value => {
          //   const valueToUse = Array.isArray(value) ? value[activeThumb] : value

          //   return (
          //     <img
          //       style={{
          //         position: 'absolute',
          //         left: 0,
          //         top: 0,
          //         width: '100%',
          //         aspectRatio: '2/1',
          //         borderRadius: '5px'
          //       }}
          //       src={stateImageUrlForValue(
          //         parseInt(props.startTokenId) + valueToUse
          //       )}
          //     />
          //   )
          // }}
        />
      </Tooltip>
    </>
  )
}
