import { useAuctionMarketplaceContract } from './use-contract-for-chain'
import IERC20 from '../contracts/common/ERC20.json'
import Web3 from 'web3'
import { useMemo } from 'react'
import { useActiveWeb3React } from './web3'
import { SupportedChain } from '../lib/constants'
// returns null on errors

export const useAuctionMarketplaceDataSource = () => {
  const contract = useAuctionMarketplaceContract(SupportedChain.Polygon)
  const { library } = useActiveWeb3React()
  return useMemo(() => {
    return {
      bidForNft: (id, priceInWei, account) => {
        return contract.methods
          .bidForNft(id, priceInWei)
          .send({ from: account })
      },
      totalAuctions: () => {
        return contract.methods.totalAuctionItems().call()
      },
      itemIsOnAuction: id => {
        return contract.methods.itemIsOnAuction(id).call()
      },
      getAuctionItem: id => {
        return contract.methods.getAuctionItem(id).call()
      },
      getAuctionItemFullDetails: async id => {
        const auctionInfo = await contract.methods.getAuctionItem(id).call()

        const tokenInstance = new library.eth.Contract(
          IERC20.abi,
          auctionInfo.paymentToken
        )

        const tokenName = await tokenInstance.methods.name().call()
        const tokenSymbol = await tokenInstance.methods.symbol().call()
        const tokenDecimals = await tokenInstance.methods.decimals.call()

        return {
          auctionId: id,
          tokenId: auctionInfo.tokenId,
          tokenContractAddress: auctionInfo.contractAddress,
          sellerAddress: auctionInfo.sellerAddress,
          currentBidderAddress: auctionInfo.currentBidder,
          paymentTokenAddress: auctionInfo.paymentToken,
          paymentTokenInfo: {
            contractAddress: auctionInfo.paymentToken,
            name: tokenName,
            symbol: tokenSymbol,
            decimals: tokenDecimals
          },
          auctionPrice: auctionInfo.auctionPrice,
          auctionStartTimeSeconds: auctionInfo.auctionStartTime,
          auctionEndTimeSeconds: Web3.utils
            .toBN(auctionInfo.auctionStartTime)
            .add(
              Web3.utils
                .toBN(auctionInfo.auctionDurationDays)
                .mul(Web3.utils.toBN(SECONDS_IN_1_DAY))
            )
        }
      },

      getAuctionItemIdAtIndex: index => {
        return contract.methods.getAuctionItemIdAtIndex(index).call()
      },
      remainingAuctionTime: id => {
        return contract.methods.remainingAuctionTime(id).call()
      },
      purchaseAuctionItem: (id, account) => {
        return contract.methods.purchaseAuctionItem(id).send({ from: account })
      },
      auctionEndTime: id => {
        return contract.methods.auctionEndTime(id).call()
      },
      getAuctionId (tokenId, tokenContractAddress) {
        return Web3.utils.soliditySha3(
          Web3.eth.abi.encodeParameters(
            ['uint256', 'string'],
            [tokenId, tokenContractAddress]
          )
        )
      },
      subscribeToAuctionBidEvents: (auctionId, callback) => {
        return contract.events
          .ItemBidChanged(
            {
              filter: { id: auctionId },
              fromBlock: 'latest'
            },
            function (error, event) {}
          )
          .on('connected', function (subscriptionId) {
            console.log('connected with sub id', subscriptionId)
          })
          .on('data', function (event) {
            callback(event)
          })
          .on('changed', function (event) {
            // remove event from local database
          })
          .on('error', function (error, receipt) {
            // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            console.error('error in sub', error)
          })
      }
    }
  }, [contract])
}
