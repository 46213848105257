import { useEffect, useState } from 'react'
import { useEmotableContract } from '../use-emotable.js'

const useListenForEmotesOnNft = ({ tokenId, collection, chainId, includePastEvents = false, active = true }) => {
  const [emotes, setEmotes] = useState(undefined)

  const contractInstance = useEmotableContract(chainId, true)

  useEffect(() => {
    console.log('useListenForEmotesOnNft - emotes updated', emotes)
  }, [emotes])

  useEffect(() => {
    if (!active) return
    if (!contractInstance) return
    //listen for emoting on this nft
    const filter = contractInstance.filters.Emoted(null, collection, tokenId, null, null)

    const listener = (...args) => {
      const event = args[args.length - 1]
      if (!event || !event.args.on) return
      console.log('useListenForEmotesOnNft - listener received event', event)

      setEmotes((rs) => [event.args.emoji, ...(rs || [])])
    }

    const subscribe = async () => {
      if (includePastEvents) {
        // Evolv3 first mint at block 49169126
        const past = await contractInstance.queryFilter(filter, 49169126, 'latest')
        setEmotes((past.reverse() || []).map((event) => event.emoji))
      }
      contractInstance.on(filter, listener)
    }

    subscribe()
    return () => contractInstance.off(filter, listener)
  }, [contractInstance, tokenId, collection, chainId, includePastEvents, active])

  return emotes
}

export default useListenForEmotesOnNft
