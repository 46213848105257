import { extract } from '@murall/event-processor'
import { processNftData } from '../../js/modules/blockchain/NftDataMapper'

export const processPaintedEvent = async rawEvent => {
  const event = extract(rawEvent)
  if (!event) return {}

  const nftInfo = await processNftData(
    rawEvent.address,
    event.tokenId,
    event.artist,
    event.colorIndex,
    event.pixelData,
    event.pixelGroups,
    event.pixelGroupIndexes,
    event.transparentPixelGroups,
    event.transparentPixelGroupIndexes,
    event.metadata[0],
    event.metadata[1],
    false
  )

  return nftInfo
}

export default processPaintedEvent
