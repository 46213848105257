import { captureVideoFrame, createOffscreenCanvas } from '../js/libs/appUtils'
import {
  processOtherMetadata,
  drawNftDataToCanvasBuffer,
  processNftTokenUriMetadata,
  mapInformationToNftObject,
  constructNftInformationObject
} from '../js/modules/blockchain/NftDataMapper'
import {
  useMurAllContract,
  useMurAllNFTContract,
  useNftDataStorageContract
} from './use-contract-for-chain'
import { useApi } from './use-api'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  EVOLV3_FILETYPES,
  MURALL_WALL,
  MURALL_WALL_ID,
  SupportedChain
} from '../lib/constants'
import { useMemo } from 'react'
import fetchImage from '../lib/actions/fetch-image'
import { useMurAllEvolv3NftDataSource } from './use-murall-evolv3-nft-datasource'
import { batchFetchTokensInRangeCached } from './api-utils'
import { useEvolv3StatisticsDataSource } from './use-evolv3-statistics'
const async = require('async')
// returns null on errors

export const useMurAllStateS3DataSourceForWall = wallId => {
  const wallInfo = MURALL_WALL[wallId]
  const api = useApi(wallInfo.chainId, wallId)
  const nftContract = useMurAllNFTContract(wallInfo.chainId)
  const murAllContract = useMurAllContract(wallInfo.chainId)
  const nftDataStorageContract = useNftDataStorageContract(
    SupportedChain.Polygon
  )
  const murAllEvolv3Datasource = useMurAllEvolv3NftDataSource()
  const evolv3StatisticsDataSource = useEvolv3StatisticsDataSource()

  return useMemo(() => {
    if (
      !nftContract ||
      !murAllContract ||
      !nftDataStorageContract ||
      !murAllEvolv3Datasource
    ) {
      return null
    }
    const fetchStateImageForTokenId = tokenId => {
      return fetchImage(api.s3.history(tokenId))
    }

    const addNewEventsEvolv3 = async (canvas, tokens) => {
      const ctx = canvas.getContext('2d')
      await async.eachSeries(tokens, async (tokenInfo, callback) => {
        const {
          start,
          croppedWidth,
          croppedHeight
        } = tokenInfo.positionInformation
        let tokenImage
        if (tokenInfo?.fileType === EVOLV3_FILETYPES.VIDEO) {
          const base64Img = await captureVideoFrame(
            tokenInfo.animation_url,
            tokenInfo.duration * 1000.0
          )
          tokenImage = await fetchImage(base64Img)
        } else {
          tokenImage = await fetchImage(tokenInfo.image)
        }
        ctx.drawImage(tokenImage, start.x, start.y, croppedWidth, croppedHeight)

        callback()
      })
      return canvas
    }

    const addNewEvents = async (canvas, events) => {
      const ctx = canvas.getContext('2d')
      await async.eachSeries(events, async (event, callback) => {
        const img = ctx.getImageData(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
        const tokenInfo = event.returnValues
        const metadata = processOtherMetadata(tokenInfo.metadata[1])
        const canvasBuffer = await drawNftDataToCanvasBuffer(
          img.data.buffer,
          tokenInfo.colorIndex,
          tokenInfo.pixelData,
          tokenInfo.pixelGroups,
          tokenInfo.pixelGroupIndexes,
          tokenInfo.transparentPixelGroups,
          tokenInfo.transparentPixelGroupIndexes,
          metadata.hasAlpha
        )

        const arr = new Uint8ClampedArray(canvasBuffer)
        let processedImageData
        try {
          processedImageData = new ImageData(arr, CANVAS_WIDTH, CANVAS_HEIGHT)
        } catch (e) {
          processedImageData = ctx.createImageData(CANVAS_WIDTH, CANVAS_HEIGHT)
          processedImageData.data.set(arr)
        }

        ctx.putImageData(processedImageData, 0, 0)
        callback()
      })
      return canvas
    }

    const putImgToCanvas = (img, events, wallId) => {
      const offScreenCanvas = createOffscreenCanvas(CANVAS_WIDTH, CANVAS_HEIGHT)
      const ctx = offScreenCanvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      if (events) {
        return wallId === MURALL_WALL_ID.EVOLV3
          ? addNewEventsEvolv3(offScreenCanvas, events)
          : addNewEvents(offScreenCanvas, events)
      }
      return offScreenCanvas
    }

    const fetchMurAllStateAtTokenId = async tokenId => {
      try {
        const stateData = await fetchStateImageForTokenId(tokenId)
        return putImgToCanvas(stateData)
      } catch (error) {
        return createOffscreenCanvas(CANVAS_WIDTH, CANVAS_HEIGHT)
      }
    }
    return {
      getTotalStateChanges: () => {
        switch (wallId) {
          case MURALL_WALL_ID.EVOLV3:
            return murAllEvolv3Datasource.totalSupply()
          case MURALL_WALL_ID.LAYER_1:
            return nftContract.methods.totalSupply().call()
          default:
            return nftDataStorageContract.methods.getTotalArtworks().call()
        }
      },
      getTotalNumberOfArtists: () => {
        switch (wallId) {
          case MURALL_WALL_ID.EVOLV3:
            // TODO implement the graph maybe to get number of artists based off mints
            return evolv3StatisticsDataSource.currentStatistics().then(
              stats => {
                return stats.totalArtists
              },
              error => {
                console.log('error getting total artists', error)
                return 0
              }
            )
          default:
            return murAllContract.methods.totalArtists().call()
        }
      },
      getCurrentMurAllStateData: async (
        withMetadata = false,
        preferImageUrl = true
      ) => {
        const currentStateResponse = await fetch(api.states.current)
        const currentState = await currentStateResponse.json()

        let currentImage = currentState.url || api.s3.currentState
        const lastBlock = (currentState || {}).blocknumber
        const tokenId = (currentState || {}).token
        const wallInfo = MURALL_WALL[wallId]
        if (wallId === MURALL_WALL_ID.EVOLV3) {
          const totalSupply = await murAllEvolv3Datasource.totalSupply()
          const difference = totalSupply - 1 - tokenId
          console.log('difference in tokens', difference)
          if (difference > 0) {
            console.log(
              'fetching new events for token range',
              tokenId + 1,
              tokenId + difference
            )
            const newEvents = await murAllEvolv3Datasource.fetchAllTokenInfoForTokenIndexRange(
              tokenId + 1,
              tokenId + difference
            )
            if (newEvents && newEvents.length) {
              currentImage = await fetchImage(currentImage)
              const offScreenCanvas = await putImgToCanvas(
                currentImage,
                newEvents,
                MURALL_WALL_ID.EVOLV3
              )
              if (!withMetadata) {
                return offScreenCanvas
              } else {
                const fullBase64PngString = offScreenCanvas.toDataURL()
                return mapInformationToNftObject({
                  ...newEvents[newEvents.length - 1],
                  contractAddress: wallInfo.nftContractAddress,
                  image: fullBase64PngString,
                  positionInformation: {
                    start: { x: 0, y: 0 },
                    end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
                    width: CANVAS_WIDTH,
                    height: CANVAS_HEIGHT
                  },
                  additionalInformation: {
                    ...newEvents[newEvents.length - 1],
                    fullBase64PngString: fullBase64PngString,
                    croppedBase64PngString: fullBase64PngString,
                    positionInformation: {
                      start: { x: 0, y: 0 },
                      end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
                      width: CANVAS_WIDTH,
                      height: CANVAS_HEIGHT
                    }
                  }
                })
              }
            }
          }
        } else if (lastBlock) {
          const newEvents = await murAllContract.getPastEvents('Painted', {
            fromBlock: lastBlock
          })
          if (newEvents && newEvents.length) {
            currentImage = await fetchImage(currentImage)
            const offScreenCanvas = await putImgToCanvas(
              currentImage,
              newEvents
            )
            if (!withMetadata) return offScreenCanvas
            const fullBase64PngString = offScreenCanvas.toDataURL()
            const tokenInfo = newEvents[newEvents.length - 1].returnValues
            const { number, seriesId } = processOtherMetadata(
              tokenInfo.metadata[1]
            )
            return constructNftInformationObject({
              contractAddress: wallInfo.nftContractAddress,
              tokenId,
              chainId: wallInfo.chainId,
              image: fullBase64PngString,
              positionInformation: {
                start: { x: 0, y: 0 },
                end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
                width: CANVAS_WIDTH,
                height: CANVAS_HEIGHT
              },
              additionalInformation: {
                fullBase64PngString: fullBase64PngString,
                croppedBase64PngString: fullBase64PngString,
                name: '',
                number: number,
                seriesId: seriesId,
                hasAlpha: false,
                colorIndex: [],
                artist: ''
              }
            })
          }
        }

        if (!withMetadata) {
          return preferImageUrl
            ? currentImage
            : putImgToCanvas(await fetchImage(currentImage))
        }

        let fullBase64PngString
        if (preferImageUrl) {
          fullBase64PngString = currentImage
        } else {
          fullBase64PngString = putImgToCanvas(
            await fetchImage(currentImage)
          ).toDataURL()
        }
        return mapInformationToNftObject({
          contractAddress: wallInfo.nftContractAddress,
          tokenId,
          image: fullBase64PngString,
          positionInformation: {
            start: { x: 0, y: 0 },
            end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
            width: CANVAS_WIDTH,
            height: CANVAS_HEIGHT
          },
          chainId: wallInfo.chainId,
          additionalInformation: {
            fullBase64PngString: fullBase64PngString,
            croppedBase64PngString: fullBase64PngString,
            name: '',
            number: 0,
            seriesId: 0,
            hasAlpha: false,
            colorIndex: [],
            artist: ''
          }
        })
      },
      getMurAllStateDataAtId: async (
        tokenId,
        withMetadata = false,
        processImages = false,
        preferImageUrl = true
      ) => {
        const wallInfo = MURALL_WALL[wallId]
        let fullBase64PngString
        if (preferImageUrl) {
          fullBase64PngString = api.s3.history(tokenId)
        } else {
          const offScreenCanvas = await fetchMurAllStateAtTokenId(tokenId)
          if (!withMetadata) {
            return offScreenCanvas
          }
          fullBase64PngString = offScreenCanvas.toDataURL()
        }

        const nftDataObject = mapInformationToNftObject({
          contractAddress: wallInfo.nftContractAddress,
          chainId: wallInfo.chainId,
          tokenId,
          image: fullBase64PngString,
          positionInformation: {
            start: { x: 0, y: 0 },
            end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
            width: CANVAS_WIDTH,
            height: CANVAS_HEIGHT
          },
          additionalInformation: {
            fullBase64PngString: fullBase64PngString,
            croppedBase64PngString: fullBase64PngString,
            name: '',
            number: 0,
            seriesId: 0,
            hasAlpha: false,
            colorIndex: [],
            artist: ''
          }
        })
        if (tokenId < 0) {
          return nftDataObject
        } else {
          const res = await fetch(api.tokens.cachedToken(tokenId))
          const tokenInfo = await res.json()
          let stateInfo = await processNftTokenUriMetadata(
            wallInfo.nftContractAddress,
            tokenInfo,
            wallInfo.chainId,
            processImages
          )

          // this state is full screen
          stateInfo.positionInformation.start.x = 0
          stateInfo.positionInformation.start.y = 0
          stateInfo.positionInformation.end.x = CANVAS_WIDTH
          stateInfo.positionInformation.end.y = CANVAS_HEIGHT

          return mapInformationToNftObject({
            contractAddress: wallInfo.nftContractAddress,
            tokenId,
            chainId: wallInfo.chainId,
            image: stateInfo.croppedBase64PngString,
            positionInformation: stateInfo.positionInformation,
            creator: stateInfo.artist,
            name: stateInfo.name,
            additionalInformation: {
              fullBase64PngString: fullBase64PngString,
              croppedBase64PngString: stateInfo.croppedBase64PngString,
              positionInformation: stateInfo.positionInformation,
              name: stateInfo.name,
              number: stateInfo.number,
              seriesId: stateInfo.seriesId,
              hasAlpha: stateInfo.hasAlpha,
              colorIndex: stateInfo.colorIndex,
              artist: stateInfo.artist
            }
          })
        }
      },
      getMurAllStateChangesForTokenIds: (
        tokenIndexes,
        processImages = false
      ) => {
        return Promise.all(
          tokenIndexes.map(
            tokenId =>
              new Promise(async function (resolve, reject) {
                if (wallId === MURALL_WALL_ID.EVOLV3) {
                  const stateInfo = await murAllEvolv3Datasource.getTokenMetadataForTokenId(
                    tokenId
                  )

                  resolve(stateInfo)
                  return
                }
                const res = await fetch(api.tokens.cachedToken(tokenId))
                const tokenInfo = await res.json()

                const wallInfo = MURALL_WALL[wallId]
                let stateInfo = await processNftTokenUriMetadata(
                  wallInfo.nftContractAddress,
                  tokenInfo,
                  wallInfo.chainId,
                  processImages
                )

                resolve(stateInfo)
              })
          )
        )
      },
      getMurAllStateChangesForTokenIdRange: (
        fromTokenId,
        toTokenId,
        processImages = false
      ) => {
        if (wallId === MURALL_WALL_ID.EVOLV3) {
          return murAllEvolv3Datasource.fetchAllTokenInfoForTokenIndexRange(
            fromTokenId,
            toTokenId
          )
        }
        return new Promise(async (resolve, reject) => {
          const tokenInfos = await batchFetchTokensInRangeCached(
            api,
            fromTokenId,
            toTokenId
          )
          const wallInfo = MURALL_WALL[wallId]
          const processedTokenInfos = await Promise.all(
            tokenInfos.map(tokenInfo =>
              processNftTokenUriMetadata(
                wallInfo.nftContractAddress,
                tokenInfo,
                wallInfo.chainId,
                processImages
              )
            )
          )
          resolve(processedTokenInfos)
        })
      }
    }
  }, [
    wallId,
    api,
    nftContract,
    murAllContract,
    nftDataStorageContract,
    murAllEvolv3Datasource
  ])
}
