import { combineReducers } from 'redux'

import { API } from './api'
import { historyAPI } from './historyApi'
import network from './slices/network'
import terms from './slices/terms'
import networkSwitch from './slices/networkSwitch'
import wall from './slices/wall'
import drawWelcome from './slices/drawWelcome'
import frames from './slices/frames'
import events from './slices/events'
import promo from './slices/promo'
import { OpenseaApi } from './opensea'
import { ImageApi } from './imageApi'
import { Evolv3Api } from './evolv3Api'

const rootReducer = combineReducers({
  network,
  terms,
  events,
  networkSwitch,
  wall,
  drawWelcome,
  promo,
  frames,
  [API.reducerPath]: API.reducer,
  [historyAPI.reducerPath]: historyAPI.reducer,
  [ImageApi.reducerPath]: ImageApi.reducer,
  [Evolv3Api.reducerPath]: Evolv3Api.reducer,
  [OpenseaApi.reducerPath]: OpenseaApi.reducer
})

export default rootReducer
