import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useActiveWeb3React } from '../../../hooks/web3'
import { shortenAddress } from '../../../lib/web3/utils'
import Typography from '@mui/material/Typography'
import WalletIcon from './icon'
import { useEns } from '../../../hooks/useEns'

const AccountAddress = ({ textSize = 'h5', short }) => {
  const { account } = useActiveWeb3React()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const charsToShowAtEnd = isMobile ? 3 : 4

  const ens = useEns(account)

  const address = ens || account

  return (
    <Container>
      <WalletIcon />
      {short ? (
        <>
          {account && (
            <Typography variant={textSize}>
              {ens || shortenAddress(account, charsToShowAtEnd)}
            </Typography>
          )}
        </>
      ) : (
        <>
          {account && (
            <Typography
              noWrap
              align='center'
              variant={textSize}
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            >
              {address.substring(0, address.length - charsToShowAtEnd)}
            </Typography>
          )}
          {account && (
            <Typography variant={textSize} style={{ flexShrink: 0 }}>
              {address.substring(address.length - charsToShowAtEnd)}
            </Typography>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  min-width: 0;
`

export default AccountAddress
