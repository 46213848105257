import { useCallback, useEffect, useState } from 'react'
import { useEmotableDataSource } from '../use-emotable.js'

const useGetEmoteForNft = ({ tokenId, tokenContractAddress, chainId, immediate = false }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [emotes, setEmotes] = useState(false)

  const emotableDataSource = useEmotableDataSource(chainId)
  const emotesForToken = emotableDataSource?.emotesForToken

  const apply = useCallback(async () => {
    if (!emotesForToken) {
      setError('No account or emotesForToken function')
      return
    }
    setLoading(true)
    try {
      const emotes = await emotesForToken(tokenContractAddress, tokenId)

      setEmotes(emotes)
      setLoading(false)
      return emotes
    } catch (error) {
      console.error(error)

      setError(error)
      setLoading(false)
      return
    }
  }, [emotesForToken, tokenId, tokenContractAddress])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, loading, error, emotes }
}

export default useGetEmoteForNft
