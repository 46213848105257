import React, { forwardRef, useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import useStyles from './styles'
import { updateCanvasWithVideo } from '../../../js/libs/appUtils'

function SvgHistoryVideoState(
  {
    opacity = 1,
    video,
    muted = true,
    volume = 1,
    x,
    y,
    width,
    height,
    shouldHide,
    shouldShow,
    animationDuration,
    filter,
    useGlitchEffect,
    shouldPlay,
    onTimeUpdate,
    onPlaybackEnded,
    onPlaybackStarted,
    onVolumeChange,
    seekTo,
    showPlayControls,
    objectFit = 'fill'
  },
  ref
) {
  const classes = useStyles({
    animationDuration
  })

  const videoRef = useRef(null)
  const glitchCanvasRef = useRef(null)

  useEffect(() => {
    const currentVideo = videoRef.current
    if (currentVideo) {
      if (shouldPlay) {
        currentVideo.play()
      } else {
        currentVideo.pause()
      }
    }
  }, [shouldPlay])

  useEffect(() => {
    if (videoRef.current && shouldHide) {
      videoRef.current.pause()
    }
  }, [shouldHide])

  useEffect(() => {
    const currentVideo = videoRef.current
    if (!currentVideo) return

    const seekToEnd = () => {
      if (currentVideo.duration) {
        currentVideo.currentTime = currentVideo.duration
      }
    }

    if (seekTo === 'end') {
      if (currentVideo.readyState >= 2) {
        seekToEnd()
      } else {
        currentVideo.addEventListener('loadedmetadata', seekToEnd, {
          once: true
        })
      }
    } else if (typeof seekTo === 'number' && seekTo >= 0) {
      currentVideo.currentTime = seekTo
    }
  }, [seekTo])

  useEffect(() => {
    if (videoRef.current && glitchCanvasRef.current) {
      // draw video frame to canvas on load
      updateCanvasWithVideo(
        glitchCanvasRef.current,
        videoRef.current,
        width,
        height
      )
    }
  }, [videoRef, glitchCanvasRef, useGlitchEffect])

  const groupStyle = useMemo(() => {
    return opacity > 0 ? { opacity } : { visibility: 'hidden', display: 'none' }
  }, [opacity])

  const imageClassNames = useMemo(() => {
    return clsx({
      [classes.hide]: shouldHide,
      [classes.show]: !shouldHide
    })
  }, [shouldHide, classes])

  const videoStyle = useMemo(() => {
    const styles = { objectFit }
    if (filter) styles.filter = filter
    return styles
  }, [objectFit, filter])

  return (
    <g transform={`translate(${x} ${y})`} style={groupStyle}>
      <foreignObject className={imageClassNames} height={height} width={width}>
        <video
          ref={currentRef => {
            videoRef.current = currentRef
            if (ref) {
              ref.current = currentRef
            }
          }}
          muted={muted}
          volume={volume}
          onVolumeChange={e =>
            onVolumeChange && onVolumeChange(e.target.volume)
          }
          height={height}
          width={width}
          src={`${video}#t=0.1`}
          autoPlay={shouldPlay}
          controls={showPlayControls}
          playsInline
          preload="metadata"
          onTimeUpdate={e => onTimeUpdate && onTimeUpdate(e.target.currentTime)}
          onPlay={onPlaybackStarted}
          onEnded={onPlaybackEnded}
          style={videoStyle}
        />
      </foreignObject>
      {!shouldShow && useGlitchEffect && (
        <foreignObject height={height} width={width}>
          <canvas
            ref={glitchCanvasRef}
            className={clsx('pixelated-image', classes.glitch1)}
            height={height}
            width={width}
          />
        </foreignObject>
      )}
    </g>
  )
}

export default forwardRef(SvgHistoryVideoState)
