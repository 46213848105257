import React, { forwardRef, useState } from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import { useRandomInterval } from '../../../lib/useRandomInterval'
import { getRandomNumber } from '../../../lib/utils'

const useStyles = makeStyles(theme => ({
  glitch1: {
    animationName: '$glitchanimcombined',
    opacity: 0,
    animationFillMode: 'forwards',
    webkitAnimationFillMode: 'forwards',
    animationTimingFunction: 'step-end',
    backgroundBlendMode: 'transparent',
    animationIterationCount: 'infinite'
  },
  '@keyframes glitchanimcombined': {
    '0%': {
      opacity: 0,
      display: 'none'
    },
    '0%': {
      opacity: 1,
      display: 'block',
      transform: 'translate3d(-2px, 5px, 0)',
      filter: 'hue-rotate(180deg)',
      webkitClipPath: 'polygon(0 2%, 100% 2%, 100% 10%, 0 10%)',
      clipPath: 'polygon(0 2%, 100% 2%, 100% 10%, 0 10%)'
    },
    '4%': {
      webkitClipPath: 'polygon(0 10%, 100% 10%, 100% 20%, 0 20%)',
      clipPath: 'polygon(0 10%, 100% 10%, 100% 20%, 0 20%)'
    },
    '6%': {
      webkitClipPath: 'polygon(0 1%, 100% 1%, 100% 12%, 0 12%)',
      clipPath: 'polygon(0 1%, 100% 1%, 100% 12%, 0 12%)'
    },

    '12%': {
      transform: 'translate3d(15px, 15px, 0)',
      webkitClipPath: 'polygon(0 50%, 100% 50%, 100% 20%, 0 20%)',
      clipPath: 'polygon(0 50%, 100% 50%, 100% 20%, 0 20%)'
    },

    '18%': {
      webkitClipPath: 'polygon(0 50%, 100% 50%, 100% 55%, 0 55%)',
      clipPath: 'polygon(0 50%, 100% 50%, 100% 55%, 0 55%)'
    },
    '20%': {
      webkitClipPath: 'polygon(0 70%, 100% 70%, 100% 80%, 0 80%)',
      clipPath: 'polygon(0 70%, 100% 70%, 100% 80%, 0 80%)'
    },

    '22%': {
      filter: 'hue-rotate(135deg)',
      transform: 'translate3d(-5px, 0px, 0)',
      webkitClipPath: 'polygon(0 25%, 100% 25%, 100% 30%, 0 30%)',
      clipPath: 'polygon(0 25%, 100% 25%, 100% 30%, 0 30%)'
    },

    '26%': {
      webkitClipPath: 'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)',
      clipPath: 'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)'
    },

    '32%': {
      webkitClipPath: 'polygon(0 52%, 100% 52%, 100% 89%, 0 89%)',
      clipPath: 'polygon(0 52%, 100% 52%, 100% 89%, 0 89%)'
    },

    '38%': {
      webkitClipPath: 'polygon(0 65%, 100% 65%, 100% 40%, 0 40%)',
      clipPath: 'polygon(0 65%, 100% 65%, 100% 40%, 0 40%)'
    },
    '40%': {
      filter: 'hue-rotate(0deg) invert(0.97)',
      transform: 'translate3d(-10px, -10px, 0)',
      webkitClipPath: 'polygon(0 75%, 100% 75%, 100% 95%, 0 95%)',
      clipPath: 'polygon(0 75%, 100% 75%, 100% 95%, 0 95%)'
    },
    '42%': {
      webkitClipPath: 'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)',
      clipPath: 'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)'
    },

    '44%': {
      transform: 'translate3d(-2px, 2px, 0)',
      webkitClipPath: 'polygon(0 10%, 100% 10%, 100% 30%, 0 30%)',
      clipPath: 'polygon(0 10%, 100% 10%, 100% 30%, 0 30%)'
    },
    '45.5%': {
      webkitClipPath: 'polygon(0 20%, 100% 20%, 100% 9%, 0 9%)',
      clipPath: 'polygon(0 20%, 100% 20%, 100% 9%, 0 9%)'
    },
    '48%': {
      webkitClipPath: 'polygon(0 15%, 100% 15%, 100% 26%, 0 26%)',
      clipPath: 'polygon(0 15%, 100% 15%, 100% 26%, 0 26%)'
    },

    '54%': {
      webkitClipPath: 'polygon(0 30%, 100% 30%, 100% 25%, 0 25%)',
      clipPath: 'polygon(0 30%, 100% 30%, 100% 25%, 0 25%)'
    },
    '55.5%': {
      filter: 'hue-rotate(135deg)',
      webkitClipPath: 'polygon(0 15%, 100% 15%, 100% 36%, 0 36%)',
      clipPath: 'polygon(0 15%, 100% 15%, 100% 36%, 0 36%)'
    },
    '57%': {
      webkitClipPath: 'polygon(0 40%, 100% 40%, 100% 39%, 0 39%)',
      clipPath: 'polygon(0 40%, 100% 40%, 100% 39%, 0 39%)'
    },
    '58%': {
      webkitClipPath: 'polygon(0 80%, 100% 80%, 100% 91%, 0 91%)',
      clipPath: 'polygon(0 80%, 100% 80%, 100% 91%, 0 91%)'
    },
    '59%': {
      webkitClipPath: 'polygon(0 60%, 100% 60%, 100% 55%, 0 55%)',
      clipPath: 'polygon(0 60%, 100% 60%, 100% 55%, 0 55%)'
    },
    '60.5%': {
      webkitClipPath: 'polygon(0 30%, 100% 30%, 100% 31%, 0 31%)',
      clipPath: 'polygon(0 30%, 100% 30%, 100% 31%, 0 31%)'
    },
    '61%': {
      webkitClipPath: 'polygon(0 70%, 100% 70%, 100% 69%, 0 69%)',
      clipPath: 'polygon(0 70%, 100% 70%, 100% 69%, 0 69%)'
    },
    '63%': {
      webkitClipPath: 'polygon(0 40%, 100% 40%, 100% 41%, 0 41%)',
      clipPath: 'polygon(0 40%, 100% 40%, 100% 41%, 0 41%)'
    },
    '64%': {
      filter: 'hue-rotate(185deg)',
      webkitClipPath: 'polygon(0 80%, 100% 80%, 100% 75%, 0 75%)',
      clipPath: 'polygon(0 80%, 100% 80%, 100% 75%, 0 75%)'
    },
    '64.5%': {
      webkitClipPath: 'polygon(0 50%, 100% 50%, 100% 58%, 0 58%)',
      clipPath: 'polygon(0 50%, 100% 50%, 100% 58%, 0 58%)'
    },

    '70%': {
      filter: 'hue-rotate(275deg)',
      transform: 'translate3d(-5px, 0px, 0)',
      webkitClipPath: 'polygon(0 70%, 100% 70%, 100% 71%, 0 71%)',
      clipPath: 'polygon(0 70%, 100% 70%, 100% 71%, 0 71%)'
    },

    '80%': {
      webkitClipPath: 'polygon(0 2%, 100% 2%, 100% 10%, 0 10%)',
      clipPath: 'polygon(0 2%, 100% 2%, 100% 10%, 0 10%)'
    },

    '84%': {
      webkitClipPath: 'polygon(0 10%, 100% 10%, 100% 20%, 0 20%)',
      clipPath: 'polygon(0 10%, 100% 10%, 100% 20%, 0 20%)'
    },
    '86%': {
      filter: 'hue-rotate(185deg)',
      transform: 'translate3d(2px, -2px, 0)',
      webkitClipPath: 'polygon(0 1%, 100% 1%, 100% 12%, 0 12%)',
      clipPath: 'polygon(0 1%, 100% 1%, 100% 12%, 0 12%)'
    },
    '89%': {
      webkitClipPath: 'polygon(0 33%, 100% 33%, 100% 33%, 0 33%)',
      clipPath: 'polygon(0 33%, 100% 33%, 100% 33%, 0 33%)'
    },

    '92%': {
      webkitClipPath: 'polygon(0 50%, 100% 50%, 100% 20%, 0 20%)',
      clipPath: 'polygon(0 50%, 100% 50%, 100% 20%, 0 20%)'
    },
    '94%': {
      webkitClipPath: 'polygon(0 70%, 100% 70%, 100% 70%, 0 70%)',
      clipPath: 'polygon(0 70%, 100% 70%, 100% 70%, 0 70%)'
    },

    '98%': {
      webkitClipPath: 'polygon(0 50%, 100% 50%, 100% 55%, 0 55%)',
      clipPath: 'polygon(0 50%, 100% 50%, 100% 55%, 0 55%)'
    },
    '100%': {
      opacity: 0,
      display: 'none',
      transform: 'translate3d(0, 0, 0)',
      webkitClipPath: 'polygon(0 0, 0 0, 0 0, 0 0)',
      clipPath: 'polygon(0 0, 0 0, 0 0, 0 0)'
    }
  }
}))

function GlitchImage ({ className, src, style }, ref) {
  const classes = useStyles()
  const [glitch, setGlitch] = useState(true)
  const [duration, setDuration] = useState(getRandomNumber(800, 1000))

  useRandomInterval(
    () => {
      const glitchEnabled = Math.random() > 0.6

      setGlitch(glitchEnabled)
      setDuration(getRandomNumber(800, 1000))
    },
    duration,
    duration + getRandomNumber(50, 100)
  )

  return (
    <Box
      ref={ref}
      className={className}
      style={{
        position: 'relative'
      }}
    >
      <Image src={src} style={{ ...style }} />

      {glitch && (
        <>
          <Image
            src={src}
            className={clsx(classes.glitch1)}
            style={{
              ...style,
              position: 'absolute',
              left: 0,
              top: 0,
              animationDelay: -1 * getRandomNumber(2, 4) + 's',
              animationDuration: duration + 'ms',
              webkitAnimationDuration: duration + 'ms'
            }}
          />
        </>
      )}
    </Box>
  )
}
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`

export default forwardRef(GlitchImage)
