import { EVOLV3_CONTRACT_INFO, MURALL_WALL, MURALL_WALL_ID } from '../constants'
import { createInstance } from './forwarder'
import { signMetaTxRequest } from './signerUtils'

const defaultForwarderAddress =
  EVOLV3_CONTRACT_INFO[MURALL_WALL[MURALL_WALL_ID.EVOLV3].chainId]
    .MinimalForwarder

export async function sendMetaTx ({
  targetContract,
  provider,
  signer,
  forwarderAddress = defaultForwarderAddress,
  functionName,
  functionArgs = []
}) {
  const url = process.env.METATX_WEBHOOK_URL
  if (!url) throw new Error(`Missing relayer url`)

  const forwarder = createInstance(provider, forwarderAddress)
  const from = await signer.getAddress()

  const data = targetContract.interface.encodeFunctionData(
    functionName,
    functionArgs
  )

  const to = targetContract.address

  const request = await signMetaTxRequest(signer.provider, forwarder, {
    to,
    from,
    data
  })

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: { 'Content-Type': 'application/json' }
  })
}
