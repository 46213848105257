import React from 'react'
import Box from '@mui/material/Box'

const MAX_WIDTH = 1200

export function DefaultContainer (props) {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: `${MAX_WIDTH}px`,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        padding: 0,
        position: 'relative',
        ...(props.sx
          ? {
              ...props.sx
            }
          : {})
      }}
    >
      {props.children}
    </Box>
  )
}
