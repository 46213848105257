import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import MenuToggle from './menu-toggle'
import TokenBridgeDialog from '../../../js/pages/montage/token_bridge_dialog'
import CreateMontageDialog from '../../../js/pages/montage/montage_nfts_dialog'
import useCreateMontageNft from '../../../hooks/montage/use-create-montage-nft'
import FramePickerDialog from '../../common/frames/frame-picker-dialog'
import PulseBorder from '../../common/pulse-border'
import MurAllSocials from '../../common/socials/MurAllSocials'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const NavLinks = () => {
  const { account } = useWeb3React()
  const [isOpen, setOpen] = useState(false)

  const [bridgeDialogOpen, setBridgeDialogOpen] = useState(false)
  const [montageDialogOpen, setMontageDialogOpen] = useState(false)
  const [framePickerOpen, setFramePickerOpen] = useState(false)
  const [montageInputData, setMontageInputData] = useState(false)

  const { apply, error, loading, success } = useCreateMontageNft(
    montageInputData && montageInputData.name,
    montageInputData && montageInputData.description,
    montageInputData && montageInputData.canBeUnpacked,
    montageInputData && montageInputData.tokenIds
  )

  useEffect(() => {
    if (montageInputData) {
      apply()
    }
  }, [montageInputData])
  useEffect(() => {
    if (success) {
      setBridgeDialogOpen(false)
      setMontageDialogOpen(false)
    }
  }, [success])

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container>
        <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
        {isOpen && (
          <LinksWrapper onClick={() => setOpen(false)}>
            <NavLink to='/state'>
              <LinkItem>The MurAll</LinkItem>
            </NavLink>
            <NavLink to='/draw'>
              <LinkItem>Draw</LinkItem>
            </NavLink>
            {/* <NavLink to='/trade'>
              <LinkItem>Trade</LinkItem>
            </NavLink> */}
            <NavLink to='/stats'>
              <LinkItem>Stats</LinkItem>
            </NavLink>
            <NavLink to='/artists'>
              <LinkItem>Artists</LinkItem>
            </NavLink>
            <Divider sx={{ margin: 1 }} />
            {account && (
              <>
                <NavLink to='/allnfts'>
                  <ExtraLinkItem>My NFTs</ExtraLinkItem>
                </NavLink>
                <NavLink to='/mystats'>
                  <ExtraLinkItem>My Stats</ExtraLinkItem>
                </NavLink>
                <NavLink to='/gallery'>
                  <ExtraLinkItem>Gallery</ExtraLinkItem>
                </NavLink>
              </>
            )}

            {account && (
              <NavLink
                onClick={() => {
                  setFramePickerOpen(true)
                }}
              >
                <ExtraLinkItem>Use Frames with MurAll</ExtraLinkItem>
                <img
                  src={'images/logos/frames.png'}
                  alt={'use frames with murall'}
                  style={{
                    height: 25,
                    width: 25,
                    objectPosition: 'center',
                    objectFit: 'contain',
                    verticalAlign: 'text-top'
                  }}
                />
              </NavLink>
            )}
            <NavLink
              to={{ pathname: 'https://knowledgebase.murall.art' }}
              target='_blank'
            >
              <ExtraLinkItem>Knowledgebase</ExtraLinkItem>
              <MenuBookIcon
                fontSize='inherit'
                sx={{ verticalAlign: 'text-top' }}
              />
            </NavLink>
            <NavLink
              to={{ pathname: 'https://snapshot.org/#/dao.murall.eth' }}
              target='_blank'
            >
              <ExtraLinkItem>Governance</ExtraLinkItem>
              <OpenInNewIcon
                fontSize='inherit'
                sx={{ verticalAlign: 'text-top' }}
              />
            </NavLink>
            <Border
              style={{
                marginTop: '1em',
                marginBottom: '1em'
              }}
            />
            <MurAllSocials sx={{ pt: 0, pb: 1 }} />
          </LinksWrapper>
        )}
        {account && (
          <FramePickerDialog
            open={framePickerOpen}
            onClose={() => {
              setFramePickerOpen(false)
            }}
          />
        )}
        {account && bridgeDialogOpen && (
          <TokenBridgeDialog
            open={bridgeDialogOpen}
            onClose={() => {
              setBridgeDialogOpen(false)
            }}
          />
        )}
        {account && montageDialogOpen && (
          <CreateMontageDialog
            open={montageDialogOpen}
            onClose={() => {
              setMontageDialogOpen(false)
            }}
            onCreateMontageButtonClick={(
              newName,
              newDescription,
              newCanBeUnpacked,
              newTokenIds
            ) => {
              if (
                montageInputData &&
                newName === montageInputData.name &&
                newDescription === montageInputData.description &&
                newCanBeUnpacked === montageInputData.canBeUnpacked &&
                newTokenIds === montageInputData.tokenIds
              ) {
                apply()
              } else {
                setMontageInputData({
                  name: newName,
                  description: newDescription,
                  canBeUnpacked: newCanBeUnpacked,
                  tokenIds: newTokenIds
                })
              }
            }}
          />
        )}
      </Container>
    </ClickAwayListener>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`

const LinksWrapper = styled.ul`
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px;
  margin: 0;
  padding: ${({ theme }) => theme.spacing(1)} 0;
  display: flex;
  list-style: none;
  background: ${({ theme }) => theme.colors.bg.main};
  width: 100%;
  flex-direction: column;
  position: absolute;
  top: 65px;
  left: 0;
`

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`

const LinkItem = styled.li`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  display: flex;
`

const ExtraLinkItem = styled(LinkItem)`
  display: inline-block;
  font-weight: 300;
  width: auto;
`

const Border = styled(PulseBorder)`
  height: 1px;
  position: relative;
`

export default NavLinks
