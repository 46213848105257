//css
import './../../../css/reset.css'
import './../../../css/layout.css'
import './../../../css/menu.css'
import './../../../css/print.css'
import './../../../css/slider.css'

//js
import React from 'react'
import config from '../../config'
import Base_gui_class from '../../core/base-gui'
import Base_layers_class from '../../core/base-layers'
import Base_tools_class from '../../core/base-tools'
import withStyles from '@mui/styles/withStyles'
import Base_state_class from '../../core/base-state'
import DrawingLayersTransactionDialog from './drawingLayersTransactionDialog'
import ImageGenerationDialog from './imageGenerationDialog'
import BraveBugWarningDialog from './braveBugWarningDialog'
import StyledDialog from '../../uicomponents/styled_dialog'
import LoadingDialog from '../../uicomponents/loading_dialog'
import Typography from '@mui/material/Typography'
import _ from 'underscore'
import notification from '../../../lib/notification'
import File_open_class from '../../modules/file/open'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  MURALL_WALL_ID
} from '../../../lib/constants'
import Helper_class from '../../libs/helpers'

const useStyles = theme => ({
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  }
})

class DrawingClient extends React.Component {
  constructor (props) {
    super(props)

    this.File_open = new File_open_class()
    var params = new Helper_class().get_url_parameters()
    this.state = {
      loaded: false,
      showLoading: false,
      showImageGeneration: params.generateWithAI,
      drawLayersRequested: false,
      promptFlattenLayers: false,
      isBraveBrowser: false
    }
  }

  componentDidMount () {
    const drawingContainer = document.querySelector('#drawingContainer')
    this.setVisibility(drawingContainer, true)
    drawingContainer.addEventListener(
      'drawClicked',
      this.onDrawEventReceived.bind(this),
      false
    )
    drawingContainer.addEventListener(
      'changeLoadingVisibility',
      this.onChangeLoadingVisibilityEventReceived.bind(this),
      false
    )
    drawingContainer.addEventListener(
      'generateWithAI',
      this.onGenerateWithAIEventReceived.bind(this),
      false
    )

    // forcing state update to ensure componentDidUpdate fires
    this.setState({ loaded: true })
    this.warnBraveBrowserUserIfNecessary()
  }

  componentWillUnmount () {
    this.setState({ loaded: false })
    const drawingContainer = document.querySelector('#drawingContainer')
    this.setVisibility(drawingContainer, false)
    drawingContainer.removeEventListener(
      'drawClicked',
      this.onDrawEventReceived,
      false
    )
    drawingContainer.removeEventListener(
      'changeLoadingVisibility',
      this.onChangeLoadingVisibilityEventReceived,
      false
    )
    drawingContainer.removeEventListener(
      'generateWithAI',
      this.onGenerateWithAIEventReceived,
      false
    )
  }

  componentDidUpdate () {
    // dom is loaded and ready at this time so safe to initiate as sizes will be correct
    if (_.isNull(window.Layers) || _.isUndefined(window.Layers)) {
      //initiate app
      var Layers = new Base_layers_class()
      var Base_tools = new Base_tools_class(true)
      var GUI = new Base_gui_class()
      var Base_state = new Base_state_class()

      //register as global for quick or external access
      window.Layers = Layers
      window.State = Base_state // window.State.save();

      //render all
      GUI.load_modules()
      GUI.load_default_values()
      GUI.render_main_gui()

      Layers.init()
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        {this.state.drawLayersRequested && (
          <DrawingLayersTransactionDialog
            open={this.state.drawLayersRequested}
            onCancelClicked={() => {
              this.setState({
                drawLayersRequested: false
              })
            }}
          />
        )}
        {this.state.showImageGeneration && (
          <ImageGenerationDialog
            open={this.state.showImageGeneration}
            onImportClicked={imageData => {
              const images = imageData.images
              this.setState({
                showImageGeneration: false
              })

              for (var i in images) {
                var data = {
                  url: images[i],
                  name: imageData.prompt + '_' + i,
                  width: CANVAS_WIDTH,
                  height: CANVAS_HEIGHT
                }

                this.File_open.file_open_url_handler(data)
              }
            }}
            onCloseClicked={() => {
              this.setState({
                showImageGeneration: false
              })
            }}
          />
        )}

        {this.state.promptFlattenLayers && (
          <StyledDialog
            open={this.state.promptFlattenLayers}
            onClose={() => {
              this.setState({ promptFlattenLayers: false })
            }}
            dialogTitle={'Flatten layers?'}
            dialogContent={() => (
              <Typography
                className={classes.typography}
                variant='body2'
                component='p'
              >
                Do you want to flatten all layers to mint as a single piece on
                MurAll, or do you want to mint individual layers?
              </Typography>
            )}
            positiveButtonTitle={'Flatten'}
            onPositiveButtonClick={() => {
              this.setState({
                promptFlattenLayers: false
              })
              this.props.constructDrawTransactionInfo(true)
            }}
            negativeButtonTitle={'Mint individually'}
            onNegativeButtonClick={() => {
              this.setState({
                promptFlattenLayers: false,
                drawLayersRequested: true
              })
            }}
          />
        )}

        {this.props.agreedToTerms && (
          <LoadingDialog open={this.state.showLoading} />
        )}

        {this.props.agreedToTerms && this.state.isBraveBrowser && (
          <BraveBugWarningDialog
            open={this.state.isBraveBrowser}
            onClose={() => {
              this.setState({ isBraveBrowser: false })
            }}
            onPositiveButtonClick={() => {
              this.setState({ isBraveBrowser: false })
            }}
          />
        )}
      </div>
    )
  }

  async warnBraveBrowserUserIfNecessary () {
    const isBrave =
      (navigator.brave && (await navigator.brave.isBrave())) ||
      window.brave ||
      false
    this.setState({ isBraveBrowser: isBrave })
  }

  setVisibility (target, visible) {
    if (visible) {
      target.style.display = 'block'
    } else {
      target.style.display = 'none'
    }
  }

  isVisible (target) {
    return target.style.display != 'none'
  }

  async onDrawEventReceived (e) {
    if (!this.props.connected) {
      notification.error(
        'Wallet not connected - please connect wallet to paint on MurAll!'
      )
      return
    }

    if (config.layers.length == 1 && config.blockchainDataLayer != null) {
      notification.error(
        'There is nothing to mint!\nDraw something or import an image from the file menu!'
      )
    }

    this.props.onMintClicked && this.props.onMintClicked()
    if (this.props.wallId === MURALL_WALL_ID.EVOLV3) {
      return
    }

    if (config.layers.length > 2) {
      this.setState({ promptFlattenLayers: true })
    } else {
      this.props.constructDrawTransactionInfo(true, false)
    }
  }

  onChangeLoadingVisibilityEventReceived (e) {
    this.setState({ showLoading: e.detail.showLoading })
  }

  onGenerateWithAIEventReceived (e) {
    this.setState({ showImageGeneration: true })
  }
}

export default withStyles(useStyles, { withTheme: true })(DrawingClient)
