import _ from 'underscore'
import { LoadingSpinner } from '../../uicomponents/loading_spinner'
import DrawerDetailsView from '../../uicomponents/drawer_detail_view'
import CollapsableTransferListView from '../../uicomponents/collapsable_transfer_list_view'
import NftDrawerListItem from './nft_drawer_list_item'
import LoadingPlaceholderListItem from './loading_placeholder_list_item'
import { useTheme } from '@mui/material/styles'
import EmptyNftsMessageView from '../../uicomponents/empty_nfts_message_view'
import NetworkErrorMessageView from '../../uicomponents/network_error_message_view'
import ConnectWalletMessageView from '../../uicomponents/connect_wallet_message_view'
import NftInformationDialog from '../../uicomponents/nft_information_dialog'
import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import notification from '../../../lib/notification'
import { useMurAllCompositeNftDataSource } from '../../../hooks/use-murall-composite-nft-datasource'
import { truncate } from '../../libs/appUtils'
import ErrorMessageView from '../../../components/common/error-view'
import SvgImageViewer from '../../../components/common/svg/svg-image-viewer'
import { getMontageContractAddress } from '../../modules/blockchain/datasource/ContractAddressDataSource'
import { SupportedChain } from '../../../lib/constants'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'
import { useSelector } from 'react-redux'
import FramesWrapperView from '../../../components/common/frames/frame-wrapper-view'
import { useActiveWeb3React } from '../../../hooks/web3'
import ZoomableViewWrapper from '../../../components/common/ZoomableViewWrapper'

const DEFAULT_FADE_DURATION_MILLIS = 350
const DEFAULT_LOCATE_ANIMATION_DURATION_MILLIS = 800
const LOAD_ITEM_LIMIT = 9
const ANIMATION_EASE_DEFAULT = 'power2.inOut'
const LIST_ID_ALL_ITEMS = 'allItemsList'
const LIST_ID_DISPLAY_ITEMS = 'bottomList'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

export default function NftDisplay(props) {
  const { account, chainId } = useActiveWeb3React()
  const { isLoading: isLoadingAll, getBalance, getNftInfoForIndexRange } = useMurAllCompositeNftDataSource()

  const frames = useSelector((state) => {
    return state.frames
  })
  const [isFetching, setIsFetching] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [networkError, setNetworkError] = useState(false)
  const [checked, setChecked] = useState([])
  const [currentSelectedState, setCurrentSelectedState] = useState(0)
  const [nftBalance, setNftBalance] = useState(0)
  const [displayTokenOrder, setDisplayTokenOrder] = useState([])
  const [allTokens, setAllTokens] = useState([])
  const [nftDialogOpen, setNftDialogOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(true)
  const [selectedNftInfo, setSelectedNftInfo] = useState(0)

  const allNftsTokenIdsChecked = intersection(checked, allTokens)
  const displayNftTokenIdsChecked = intersection(checked, displayTokenOrder)
  const smallScreen = useSmallScreenDetection()
  const theme = useTheme()

  useEffect(() => {
    if (!isLoadingAll) {
      checkWalletConnectionThenFetch()
    }
  }, [isLoadingAll])

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result
    // dropped outside the list
    if (
      !destination ||
      (destination.droppableId === source.droppableId && destination.droppableId === LIST_ID_ALL_ITEMS)
    ) {
      return
    }

    if (destination.droppableId === source.droppableId) {
      //if (destination.index === source.index) return;
      const items = reorder(
        source.droppableId === LIST_ID_DISPLAY_ITEMS ? displayTokenOrder : allTokens,
        source.index,
        destination.index
      )

      if (source.droppableId === LIST_ID_DISPLAY_ITEMS) {
        updateDisplayTokens(items)
      } else {
        setAllTokens(items)
      }
    } else {
      const result = move(
        source.droppableId === LIST_ID_DISPLAY_ITEMS ? displayTokenOrder : allTokens,
        destination.droppableId === LIST_ID_DISPLAY_ITEMS ? displayTokenOrder : allTokens,
        source,
        destination
      )

      updateDisplayTokens(result.displayList)
      setAllTokens(result.allItemsList)
    }
  }

  const handleToggle = (nft, key) => {
    const currentIndex = checked.indexOf(nft)

    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(nft)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const selectAll = (list) => {
    const newChecked = [...checked]
    list.forEach((layer) => {
      console.log(layer)
      if (newChecked.indexOf(layer) === -1) {
        newChecked.push(layer)
      }
    })
    setChecked(newChecked)
  }

  const deselectAll = (list) => {
    const newChecked = [...checked]
    list.forEach((layer) => {
      newChecked.splice(newChecked.indexOf(layer), 1)
    })
    setChecked(newChecked)
  }

  const handleSelectAllTop = () => {
    selectAll(allTokens)
  }
  const handleSelectAllBottom = () => {
    selectAll(displayTokenOrder)
  }
  const handleDeselectAllTop = () => {
    deselectAll(allTokens)
  }
  const handleDeselectAllBottom = () => {
    deselectAll(displayTokenOrder)
  }

  const updateDisplayTokens = (newDisplayIds) => {
    setDisplayTokenOrder(newDisplayIds)

    if (props.onDisplayTokenOrderUpdate) {
      props.onDisplayTokenOrderUpdate(newDisplayIds, newDisplayIds)
    }
  }

  const handleMoveCheckedToDisplay = () => {
    const newDisplayIds = displayTokenOrder.concat(allNftsTokenIdsChecked)

    updateDisplayTokens(newDisplayIds)
    setAllTokens(not(allTokens, allNftsTokenIdsChecked))
    setChecked(not(checked, allNftsTokenIdsChecked))
  }

  const handleMoveCheckedToAll = () => {
    setAllTokens(allTokens.concat(displayNftTokenIdsChecked))
    const newDisplayIds = not(displayTokenOrder, displayNftTokenIdsChecked)

    updateDisplayTokens(newDisplayIds)
    setChecked(not(checked, displayNftTokenIdsChecked))
  }

  const constructNftInfoDialog = () => {
    if (!selectedNftInfo) {
      return null
    }
    const montage =
      selectedNftInfo.contractAddress.toUpperCase() ===
        getMontageContractAddress(SupportedChain.Ethereum).toUpperCase() ||
      selectedNftInfo.contractAddress.toUpperCase() === getMontageContractAddress(SupportedChain.Polygon).toUpperCase()

    return (
      <NftInformationDialog
        open={nftDialogOpen}
        onClose={handleNftDialogClose}
        showCompletionStatus
        nftInformation={selectedNftInfo}
        {...(montage
          ? { viewCropped: true }
          : {
              withViewCropToggle: true
            })}
      />
    )
  }

  const constructCollapsableDrawerContent = (
    <CollapsableTransferListView
      bottomListId={LIST_ID_DISPLAY_ITEMS}
      topListTitle={'ALL NFTS:'}
      bottomListTitle={'DISPLAY NFTS:'}
      topListItems={allTokens}
      bottomListItems={displayTokenOrder}
      showLoadingPlaceholder={allTokens.length + displayTokenOrder.length < nftBalance}
      constructLoadingPlaceholderListItem={() => <LoadingPlaceholderListItem />}
      constructTopListItem={(id, index) => {
        return constructNftListItem(allTokens[index], id, index, true, true)
      }}
      constructBottomListItem={(id, index) => {
        return constructNftListItem(displayTokenOrder[index], id, index)
      }}
      onSelectedToBottomClicked={handleMoveCheckedToDisplay}
      selectedToBottomDisabled={allNftsTokenIdsChecked.length === 0}
      onSelectedToTopClicked={handleMoveCheckedToAll}
      selectedToTopDisabled={displayNftTokenIdsChecked.length === 0}
      onSelectAllTopListClick={handleSelectAllTop}
      onDeselectAllTopListClick={handleDeselectAllTop}
      onSelectAllBottomListClick={handleSelectAllBottom}
      onDeselectAllBottomListClick={handleDeselectAllBottom}
      onloadMoreTopListItems={loadMore}
      onBottomListDragEnd={onDragEnd}
    />
  )

  const constructNftListItem = (nftInformation, id, index, disableSelect = false, disableDrag = false) => {
    return (
      <NftDrawerListItem
        nftInformation={nftInformation}
        primaryText={nftInformation.name}
        secondaryText1={truncate(nftInformation.artist, 6, 4)}
        secondaryText2={nftInformation.description}
        index={index}
        key={id}
        id={index}
        disableSelect={disableSelect}
        disableDrag={disableDrag}
        onCheckboxClicked={handleToggle}
        onListItemClick={handleListItemClick}
        onNftInfoClicked={handleNftInfoClicked}
        checked={checked.indexOf(nftInformation) !== -1}
        selected={!disableSelect && currentSelectedState === index}
      />
    )
  }

  const handleNftInfoClicked = (nftInformation) => {
    setSelectedNftInfo(nftInformation)
    setNftDialogOpen(true)
  }

  const handleNftDialogClose = () => {
    setSelectedNftInfo(null)
    setNftDialogOpen(false)
  }

  const handleListItemClick = (event, selectedToken, index) => {
    const newSelectedState = currentSelectedState === index ? null : index

    setCurrentSelectedState(newSelectedState)

    const currentIndex = checked.indexOf(selectedToken)
    const newChecked = [...checked]

    if (currentIndex !== -1 && newSelectedState === null) {
      newChecked.splice(currentIndex, 1)
      setChecked(newChecked)
    } else if (currentIndex === -1 && newSelectedState === index) {
      newChecked.push(selectedToken)
      setChecked(newChecked)
    }
  }

  const checkWalletConnectionThenFetch = async () => {
    setIsFetching(true)
    console.log('checking wallet connection', account, chainId)
    if (!account) {
      setNetworkError(false)
      setIsConnected(false)
      setIsFetching(false)
      return
    } else {
      setIsConnected(true)
    }
    if (chainId !== SupportedChain.Ethereum && chainId !== SupportedChain.Polygon) {
      setNetworkError(true)
      setIsFetching(false)
      return
    }
    setNetworkError(false)
    setIsConnected(true)
    fetchAllTokensForCurrentAccount()
  }

  const loadMore = async () => {
    if (isLoadingMore) {
      return
    }
    setIsLoadingMore(true)
    const startIndex = allTokens.length + displayTokenOrder.length

    if (startIndex > nftBalance - 1) {
      setIsLoadingMore(false)
      return
    }
    const endIndex = startIndex + LOAD_ITEM_LIMIT >= nftBalance - 1 ? nftBalance - 1 : startIndex + LOAD_ITEM_LIMIT

    const nftsForRange = await getNftInfoForIndexRange(startIndex, endIndex)

    const fullList = [...allTokens, ...nftsForRange]

    setAllTokens(fullList)
    setIsLoadingMore(false)
    return
  }

  const fetchAllTokensForCurrentAccount = async () => {
    setIsFetching(true)
    setNetworkError(false)
    updateDisplayTokens([])
    setAllTokens([])
    setCurrentSelectedState(null)
    setNftBalance(0)
    try {
      const myNftBalance = getBalance()
      setNftBalance(myNftBalance)
      console.log('nft balance = ' + myNftBalance)
      if (myNftBalance == 0) {
        setIsFetching(false)

        updateDisplayTokens([])
        setAllTokens([])
        notification.error('You do not own any MurAll NFTs - try drawing something or go to the marketplace!')
      } else {
        const endIndex = myNftBalance <= LOAD_ITEM_LIMIT ? myNftBalance - 1 : LOAD_ITEM_LIMIT

        const firstNfts = await getNftInfoForIndexRange(0, endIndex)

        updateDisplayTokens([])
        setAllTokens(firstNfts)
        setIsFetching(false)
      }
    } catch (error) {
      console.error(error)
      setNetworkError(true)
    }
  }

  return isFetching ? (
    <LoadingSpinner />
  ) : !isConnected ? (
    <ConnectWalletMessageView />
  ) : networkError ? (
    <NetworkErrorMessageView />
  ) : allTokens.length + displayTokenOrder.length === 0 ? (
    <EmptyNftsMessageView />
  ) : (
    <React.Fragment>
      {props.noDrawer ? (
        <Grid
          {...props}
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          style={{ overflow: 'hidden', backgroundColor: '#FF00FF' }}
        >
          <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
            {constructCollapsableDrawerContent}
          </Grid>
          <Grid item xs={6} sm={7} md={8} lg={9} xl={9} container justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              {displayTokenOrder.length === 0 ? (
                props.emptyView || (
                  <ErrorMessageView
                    title={props.emptyViewTitle || 'Choose some NFTs to display!'}
                    description={
                      props.emptyViewDescription ||
                      `Select from the "ALL NFTS" list on the left, then use the arrows to move them into the "DISPLAY NFTS" list below to see them here.`
                    }
                    sx={{
                      width: '100%',
                      height: '100%',
                      overflow: 'auto',
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '20px'
                    }}
                  />
                )
              ) : (
                <ZoomableViewWrapper
                  style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                  }}
                >
                  <FramesWrapperView
                    {...(frames &&
                      frames &&
                      frames.assignedFrameId !== null && {
                        tokenId: frames.assignedFrameId
                      })}
                    style={{
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <SvgImageViewer
                      locateAnimationDuration={DEFAULT_LOCATE_ANIMATION_DURATION_MILLIS}
                      disableTouch={props.disableOffset}
                      nfts={displayTokenOrder}
                      selectedIndex={currentSelectedState}
                      animationEase={ANIMATION_EASE_DEFAULT}
                      animationDuration={DEFAULT_FADE_DURATION_MILLIS}
                      style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                      }}
                    />
                  </FramesWrapperView>
                </ZoomableViewWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <DrawerDetailsView
          {...props}
          style={{ width: '100%', height: '100%' }}
          drawerOpen={drawerOpen}
          onDrawerOpenClick={() => {
            setDrawerOpen(true)
          }}
          onDrawerCloseClick={() => {
            setDrawerOpen(false)
          }}
          temporaryDrawer={smallScreen}
          drawerContent={constructCollapsableDrawerContent}
          mainContent={
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {displayTokenOrder.length === 0 ? (
                props.emptyView || (
                  <ErrorMessageView
                    title={props.emptyViewTitle || 'Choose some NFTs to display!'}
                    description={
                      props.emptyViewDescription ||
                      `Move the NFTs you want to display from the "ALL NFTS" list on the left to the "DISPLAY NFTS" by selecting them and using the arrows to move them into the "DISPLAY NFTS" list below`
                    }
                  />
                )
              ) : (
                <ZoomableViewWrapper
                  style={{
                    position: 'relative',
                    flex: 1,
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <FramesWrapperView
                    {...(frames &&
                      frames &&
                      frames.assignedFrameId !== null && {
                        tokenId: frames.assignedFrameId
                      })}
                    style={{
                      position: 'relative',
                      flex: 1,
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <SvgImageViewer
                      locateAnimationDuration={DEFAULT_LOCATE_ANIMATION_DURATION_MILLIS}
                      disableTouch={props.disableOffset}
                      nfts={displayTokenOrder}
                      selectedIndex={currentSelectedState}
                      animationEase={ANIMATION_EASE_DEFAULT}
                      animationDuration={DEFAULT_FADE_DURATION_MILLIS}
                      style={{
                        width: '100%',
                        height: '100%'
                      }}
                    />
                  </FramesWrapperView>
                </ZoomableViewWrapper>
              )}
            </div>
          }
        />
      )}

      {selectedNftInfo != null && constructNftInfoDialog()}
    </React.Fragment>
  )
}
