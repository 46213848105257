import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import Divider from '@mui/material/Divider'
import Header from './header'
import PendingView from './pending'
import ErrorContent from './error'
import WalletList from './list'
import Account from './account'

export const VIEWS = {
  LIST: 'list',
  ACCOUNT: 'account',
  PENDING: 'pending'
}

const WalletModalContent = ({ isModalOpen, closeModal }) => {
  const { account, activate, error } = useWeb3React()

  const [view, setView] = useState(VIEWS.ACCOUNT)
  const [pendingWallet, setPendingWallet] = useState()
  const [pendingError, setPendingError] = useState()

  // always reset to account view
  useEffect(() => {
    if (isModalOpen) {
      setPendingError(false)
      setView(VIEWS.ACCOUNT)
    }
  }, [isModalOpen])

  const tryActivation = async connector => {
    setPendingWallet(connector)
    setView(VIEWS.PENDING)

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }

    connector &&
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector) // a little janky...can't use setError because the connector isn't set
        } else {
          setPendingError(true)
        }
      })
  }

  const getBody = () => {
    if (error) return <ErrorContent />

    if (account && view === VIEWS.ACCOUNT) return <Account onChangeClick={() => setView(VIEWS.LIST)} />

    return view === VIEWS.PENDING ? (
      <PendingView
        connector={pendingWallet}
        error={pendingError}
        setPendingError={setPendingError}
        tryActivation={tryActivation}
      />
    ) : <WalletList setView={setView} activate={tryActivation} />
  }

  return (
    <>
      <Header
        onClose={closeModal}
        showBackButton={!error && view !== VIEWS.ACCOUNT}
        onBackClick={() => {
          setPendingError(false)
          setView(VIEWS.ACCOUNT)
        }}
      />
      <Divider />
      <Body>{getBody()}</Body>
    </>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
`

export default WalletModalContent
