import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import NftCard from './nftCard'
import MontageNftCard from './montageNftCard'
import { LoadingSpinner } from '../../uicomponents/loading_spinner'
import EmptyNftsMessageView from '../../uicomponents/empty_nfts_message_view'
import NetworkErrorMessageView from '../../uicomponents/network_error_message_view'
import ConnectWalletMessageView from '../../uicomponents/connect_wallet_message_view'
import { useHistory, useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import notification from '../../../lib/notification'
import { useMurAllNftDataSource } from '../../../hooks/use-murall-nft-datasource'
import { useMontageDataSource } from '../../../hooks/use-montage-datasource'
import { useActiveWeb3React } from '../../../hooks/web3'
import NftLoadingListView from '../../../components/common/nft/nft-loading-list-view'
import {
  getBridgedMurAllNFTL2ContractAddress,
  getMontageContractAddress,
  getMurAllNFTContractAddress
} from '../../modules/blockchain/datasource/ContractAddressDataSource'
import { useMurAllBridgedNftDataSource } from '../../../hooks/use-murall-bridged-nft-datasource'
import { useMontageBridgedDataSource } from '../../../hooks/use-montage-bridged-datasource'
import {
  MURALL_WALL,
  MURALL_WALL_ID,
  SupportedChain
} from '../../../lib/constants'
import { useSelector } from 'react-redux'
import { useMurAllEvolv3NftDataSource } from '../../../hooks/use-murall-evolv3-nft-datasource'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
    flexGrow: 1
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  gridList: {
    width: '100%',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  }
}))

const LOAD_ITEM_LIMIT = 4

export default function ViewAllNfts () {
  const { account, chainId } = useActiveWeb3React()

  const currentWall = useSelector(state => state.wall)
  const wallInfo = MURALL_WALL[currentWall.id]
  const wallId = currentWall.id

  const murAllNftDatasource = useMurAllNftDataSource()
  const {
    getBalanceForAddress: getMurAllNftBalanceForAddress,
    getNftDataForAccountForOwnedTokenInIndexRange: fetchMurAllNftDataForAccountForOwnedTokenInIndexRange
  } = murAllNftDatasource || {}

  const {
    getBalanceForAddress: getMurAllNftBalanceForAddressEvolv3,
    getNftDataForAccountForOwnedTokenInIndexRange: fetchMurAllNftDataForAccountForOwnedTokenInIndexRangeEvolv3
  } = useMurAllEvolv3NftDataSource()
  const {
    getBalanceForAddress: getMontageBalanceForAddress,
    getMontageFullInformationForOwnedTokenInIndexRange: getMontageFullInformationForOwnedTokenInIndexRange
  } = useMontageDataSource()
  const {
    getBalanceForAddress: getBridgedMurAllNftBalanceForAddress,
    getNftDataForAccountForOwnedTokenInIndexRange: getBridgedMurAllNftDataForAccountForOwnedTokenInIndexRange
  } = useMurAllBridgedNftDataSource()
  const {
    getBalanceForAddress: getBridgedMontageBalanceForAddress,
    getMontageFullInformationForOwnedTokenInIndexRange: getBridgedMontageFullInformationForOwnedTokenInIndexRange
  } = useMontageBridgedDataSource()

  const [isFetching, setIsFetching] = useState(false)

  const [isConnected, setIsConnected] = useState(false)
  const [networkError, setNetworkError] = useState(false)
  const [nftBalance, setNftBalance] = useState(0)
  const [nftBalanceEvolv3, setNftBalanceEvolv3] = useState(0)
  const [bridgedNftBalance, setBridgedNftBalance] = useState(0)
  const [bridgedMontageBalance, setBridgedMontageBalance] = useState(0)
  const [montageBalance, setMontageBalance] = useState(0)

  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()

  useEffect(() => {
    // reload info when navigating here
    if (
      location.pathname === '/allnfts' &&
      account &&
      chainId &&
      murAllNftDatasource
    ) {
      checkWalletConnectionThenFetch()
    }
  }, [location])

  useEffect(() => {
    if (!murAllNftDatasource) {
      return
    }
    if (!account || !chainId || !murAllNftDatasource) {
      setIsConnected(false)
      return
    }
    if (
      chainId !== SupportedChain.Ethereum &&
      chainId !== SupportedChain.Polygon &&
      chainId !== SupportedChain.Mumbai
    ) {
      setIsConnected(true)
      setNetworkError(true)
      return
    }
    setNetworkError(false)
    setIsConnected(true)
    checkWalletConnectionThenFetch()
  }, [account, chainId, murAllNftDatasource])

  const constructGridList = (
    title,
    balance,
    onLoadMore,
    onSeeAllClicked,
    constructNftView
  ) => {
    return (
      <NftLoadingListView
        title={title}
        balance={balance}
        itemLoadLimit={LOAD_ITEM_LIMIT}
        connected={isConnected}
        onLoadMore={onLoadMore}
        constructItemView={constructNftView}
        onSeeAllClicked={onSeeAllClicked}
        divider
      />
    )
  }

  const checkWalletConnectionThenFetch = async () => {
    setIsConnected(true)
    initialiseNftInformation()
  }

  const initialiseNftInformation = async () => {
    setIsFetching(true)
    const latestEvolv3NftBalance = await getMurAllNftBalanceForAddressEvolv3(
      account
    )
    const latestNftBalance = await getMurAllNftBalanceForAddress(account)
    const latestMontageBalance = await getMontageBalanceForAddress(account)
    const latestBridgedMontageBalance = await getBridgedMontageBalanceForAddress(
      account
    )
    const latestBridgedNftBalance = await getBridgedMurAllNftBalanceForAddress(
      account
    )
    setNftBalance(parseInt(latestNftBalance))
    setNftBalanceEvolv3(parseInt(latestEvolv3NftBalance))
    setMontageBalance(parseInt(latestMontageBalance))
    setBridgedNftBalance(parseInt(latestBridgedNftBalance))
    setBridgedMontageBalance(parseInt(latestBridgedMontageBalance))
    setIsFetching(false)

    if (
      latestNftBalance == 0 &&
      latestMontageBalance == 0 &&
      latestBridgedNftBalance == 0 &&
      latestBridgedMontageBalance == 0
    ) {
      notification.error(
        'You do not own any MurAll NFTs - try drawing something or go to the marketplace!'
      )
    }
  }
  const zeroBalanceForSelectedWall =
    wallId === MURALL_WALL_ID.EVOLV3 ? nftBalanceEvolv3 === 0 : nftBalance === 0

  return isFetching ? (
    <LoadingSpinner />
  ) : networkError ? (
    <NetworkErrorMessageView />
  ) : !isConnected ? (
    <ConnectWalletMessageView />
  ) : zeroBalanceForSelectedWall &&
    montageBalance == 0 &&
    bridgedNftBalance == 0 &&
    bridgedMontageBalance == 0 ? (
    <EmptyNftsMessageView />
  ) : (
    <div className={clsx(classes.absoluteFill, classes.root)}>
      {nftBalanceEvolv3 != 0 &&
        constructGridList(
          'MurAll Evolv3 NFTs',
          nftBalanceEvolv3,
          (startIndex, endIndex) => {
            return fetchMurAllNftDataForAccountForOwnedTokenInIndexRangeEvolv3(
              account,
              startIndex,
              endIndex
            )
          },
          async () => {
            history.push('/murallnfts')
          },
          currentNft => {
            return (
              <NftCard
                key={currentNft.tokenId}
                address={MURALL_WALL[MURALL_WALL_ID.EVOLV3].nftContractAddress}
                nftInformation={currentNft}
              />
            )
          }
        )}
      {nftBalance != 0 &&
        constructGridList(
          'MurAll NFTs',
          nftBalance,
          (startIndex, endIndex) => {
            return fetchMurAllNftDataForAccountForOwnedTokenInIndexRange(
              account,
              startIndex,
              endIndex
            )
          },
          async () => {
            history.push('/murallnfts')
          },
          currentNft => {
            return (
              <NftCard
                key={currentNft.tokenId}
                address={getMurAllNFTContractAddress(chainId)}
                nftInformation={currentNft}
              />
            )
          }
        )}
      {bridgedNftBalance != 0 &&
        constructGridList(
          'Bridged MurAll NFTs',
          bridgedNftBalance,
          (startIndex, endIndex) => {
            return getBridgedMurAllNftDataForAccountForOwnedTokenInIndexRange(
              account,
              startIndex,
              endIndex
            )
          },
          async () => {
            history.push('/bridgedmurallnfts')
          },
          currentNft => {
            return (
              <NftCard
                key={currentNft.tokenId}
                address={getBridgedMurAllNFTL2ContractAddress()}
                nftInformation={currentNft}
              />
            )
          }
        )}

      {montageBalance != 0 &&
        constructGridList(
          'Montage NFTs',
          montageBalance,
          (startIndex, endIndex) => {
            return getMontageFullInformationForOwnedTokenInIndexRange(
              account,
              startIndex,
              endIndex
            )
          },
          async () => {
            history.push('/montagenfts')
          },
          montage => {
            return (
              <MontageNftCard
                key={montage.tokenId}
                address={getMontageContractAddress(chainId)}
                nftInformation={montage}
              />
            )
          }
        )}
      {bridgedMontageBalance != 0 &&
        constructGridList(
          'Bridged Montage NFTs',
          bridgedMontageBalance,
          (startIndex, endIndex) => {
            return getBridgedMontageFullInformationForOwnedTokenInIndexRange(
              account,
              startIndex,
              endIndex
            )
          },
          async () => {
            history.push('/bridgedmontagenfts')
          },
          montage => {
            return (
              <MontageNftCard
                key={montage.tokenId}
                address={getMontageContractAddress(chainId)}
                nftInformation={montage}
              />
            )
          }
        )}
    </div>
  )
}
