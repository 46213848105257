import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import Skeleton from '@mui/material/Skeleton'
import _ from 'underscore'
import { CardActionArea, CardMedia } from '@mui/material'
import clsx from 'clsx'
import Checkbox from '@mui/material/Checkbox'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    border: 'solid',
    borderColor: 'black',
    borderWidth: '1px',
    transition: 'all 0.3s'
  },
  selected: {
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    filter: 'brightness(115%)',
    borderWidth: '1px',
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },
  media: {
    backgroundColor: theme.palette.primary.dark
  },
  square: {
    height: 0,
    paddingTop: '100%' // square
  },
  twoByOne: {
    height: 0,
    paddingTop: '50%' // 2:1
  },
  sixteenByNine: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },

  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212180,
            #21212199,
            #212121B3)`
  }
}))

export default function SelectableGridImageCard ({
  title,
  loading,
  header,
  onClick,
  selected,
  square,
  twoByOne,
  sixteenByNine,
  darkenHeader,
  withCheckbox,
  image,
  children,
  sx
}) {
  const classes = useStyles()
  const aspectRatio = square
    ? classes.square
    : sixteenByNine
    ? classes.sixteenByNine
    : twoByOne
    ? classes.twoByOne
    : classes.square

  return (
    <Card
      className={clsx(classes.root, {
        [classes.selected]: selected
      })}
      elevation={0}
      sx={{
        ...sx
      }}
    >
      <CardActionArea onClick={onClick}>
        {loading ? (
          <CardMedia
            className={clsx(classes.media, aspectRatio)}
            image={image}
            title={title}
            alt={title}
          >
            <div
              className={clsx(classes.overlay, {
                [classes.overlayBackground]: darkenHeader
              })}
            >
              {header}
              <Skeleton
                animation='wave'
                sx={{ bgcolor: 'grey.900' }}
                className={clsx(aspectRatio)}
                variant='rectangular'
              />
              {children}
            </div>
          </CardMedia>
        ) : (
          <CardMedia
            className={clsx(classes.media, aspectRatio)}
            image={image}
            title={title}
            alt={title}
          >
            <div
              className={clsx(classes.overlay, {
                [classes.overlayBackground]: darkenHeader
              })}
            >
              {header}
              {withCheckbox && (
                <Checkbox
                  checked={selected}
                  onChange={onClick}
                  sx={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px'
                  }}
                />
              )}
              {children}
            </div>
          </CardMedia>
        )}
      </CardActionArea>
    </Card>
  )
}
