import React from 'react'
import Helper_class from '../../libs/helpers'
import makeStyles from '@mui/styles/makeStyles'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'

import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import StyledCardButton from '../../uicomponents/styled_card_button'
import './../../../css/layout.css'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px'
  },
  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%' // square
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    bottom: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212100,
            #21212199,
            #212121B3)`
  }
}))

export default function NftBundleCard (props) {
  const classes = useStyles()
  const Helper = new Helper_class()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const tokenId = props.nftInformation.tokenId
  const tokenIds = props.nftInformation.tokenIds
  const croppedBase64Png = 'images/l2murall_nft_logo.png' //props.nftInformation.croppedBase64PngString
  const name = props.nftInformation.name
  const artist = props.nftInformation.artist
  const isL2 = props.nftInformation.isL2

  return (
    <Card className={classes.root} elevation={10}>
      <CardActionArea onClick={handleOpen}>
        <CardHeader
          avatar={
            <Avatar
              aria-label='tokenId'
              src={
                isL2
                  ? '/images/l2murall_nft_logo.png'
                  : '/images/murall_nft_logo.png'
              }
              className={clsx(classes.avatar)}
            />
          }
          title={
            <Typography
              className={classes.typography}
              noWrap
              variant='h5'
              component='h4'
            >
              #{tokenId}
            </Typography>
          }
          titleTypographyProps={{ align: 'left' }}
          subheaderTypographyProps={{ align: 'left' }}
          style={{ padding: '12px' }}
        />

        <CardMedia
          className={clsx('pixelated-image', classes.media)}
          image={croppedBase64Png}
          title={'#' + tokenId + ': ' + name}
          alt={'#' + tokenId + 'has not been filled!'}
        >
          <div className={clsx(classes.overlay, classes.overlayBackground)}>
            <Typography
              className={clsx(classes.typography)}
              gutterBottom
              variant='h5'
              component='p'
              color='textPrimary'
              style={{ paddingTop: '12px', paddingLeft: '12px' }}
            >
              {name}
            </Typography>
            <Typography
              className={clsx(classes.typography)}
              variant='h6'
              component='p'
              color='textSecondary'
              style={{ paddingLeft: '12px' }}
            >
              {Helper.truncate(artist, 10, 10)}
            </Typography>
            <Typography
              className={clsx(classes.typography)}
              variant='subtitle2'
              component='p'
              color='textSecondary'
              gutterBottom
              style={{ paddingLeft: '12px' }}
            >
              Token Ids {tokenIds.join()}
            </Typography>
          </div>
        </CardMedia>
      </CardActionArea>
      <CardActions className={clsx(classes.cardActions)} disableSpacing>
        <StyledCardButton buttonTitle='View' onClick={handleOpen} />
        <StyledCardButton
          buttonTitle='Unbundle'
          onClick={() => {
            props.onUnbundleButtonClick(tokenId)
          }}
        />
        <StyledCardButton
          buttonTitle='Sell'
          onClick={() => {
            props.onSellButtonClick(props.address, tokenId)
          }}
        />
      </CardActions>
    </Card>
  )
}
