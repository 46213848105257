import React, { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import LoadingSpinner from '../components/common/loader'
import SvgHistoryViewer from '../components/common/svg/svg-history-viewer'
import { useMurAllSelectFromQueryParams } from '../hooks/murall/use-murall-select-from-query-params'
import useGetCurrentStateForWall from '../hooks/history/use-get-current-state-for-wall'
import RoundedButton from '../components/common/buttons/rounded-button'
import { Typography } from '@mui/material'
import ErrorMessageView from '../js/uicomponents/error_message_view'
import { MURALL_WALL_ID } from '../lib/constants'

const DEFAULT_FADE_DURATION_MILLIS = 350

const LivePage = (
  {
    wallId,
    showMurAllLogoSpinner,
    onLiveDataUpdated,
    shouldLocate,
    videoState,
    onVideoInfoUpdate,
    onLoadingStateChanged,
    onVolumeChange,
    style,
  },
  videoRef
) => {
  const [cookies] = useCookies(['agreedToTerms'])

  const [currentSelectedState, setCurrentSelectedState] = useState(0)
  const [states, setStates] = useState([])

  const terms = useSelector((state) => state.terms)
  const events = useSelector((state) => state.events)
  const eventsForCurrentWall =
    wallId === MURALL_WALL_ID.LAYER_1
      ? events?.paintedEvents?.revo1t
      : wallId === MURALL_WALL_ID.LAYER_2
      ? events?.paintedEvents?.di2rupt
      : events?.paintedEvents?.evolv3

  useMurAllSelectFromQueryParams()

  const agreedToTerms = !!cookies.agreedToTerms || terms.agreed

  const {
    currentState: currentStateData,
    currentStateTokenData,
    error: currentStateError,
    loading,
    apply: applyCurrentState,
  } = useGetCurrentStateForWall(wallId, true, true, agreedToTerms)

  useEffect(() => {
    loading && onLoadingStateChanged && onLoadingStateChanged(loading)
  }, [loading])

  useEffect(() => {
    applyCurrentState()
  }, [wallId])
  useEffect(() => {
    if (!eventsForCurrentWall || eventsForCurrentWall?.length === 0) return
    const newStates = [...states, ...eventsForCurrentWall]
    setStates(newStates)
    setCurrentSelectedState(newStates.length - 1)
  }, [eventsForCurrentWall?.length])

  useEffect(() => {
    if (!currentStateData || !currentStateTokenData || loading) return

    setStates([currentStateTokenData])
    onLoadingStateChanged && onLoadingStateChanged(false)
  }, [currentStateData, currentStateTokenData, loading])

  useEffect(() => {
    console.log('live view: states updated', states)
    onLiveDataUpdated && onLiveDataUpdated(states)
  }, [states])

  if (loading) return <LoadingSpinner useLogo={showMurAllLogoSpinner} />
  if (currentStateError || !currentStateData || !currentStateTokenData)
    return (
      <ErrorMessageView
        title="Live view unavailable"
        description="Try again later"
      >
        <RoundedButton
          variant="text"
          aria-label="close"
          sx={{
            mt: 2,
            color: 'inherit',
            fontSize: 'inherit',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => {
            applyCurrentState()
          }}
        >
          <Typography variant="body1">Retry</Typography>
        </RoundedButton>
      </ErrorMessageView>
    )

  return (
    <SvgHistoryViewer
      wallId={wallId}
      ref={videoRef}
      onVideoInfoUpdate={onVideoInfoUpdate}
      baseState={currentStateData}
      historyStates={states}
      useGlitchEffect={true}
      selectedStateIndex={currentSelectedState}
      animationDuration={DEFAULT_FADE_DURATION_MILLIS}
      shouldLocate={shouldLocate}
      onVolumeChange={onVolumeChange}
      isPlaying={true}
      videoState={videoState}
      style={{
        ...style,
        width: '100%',
        height: '100%',
      }}
    />
  )
}

export default forwardRef(LivePage)
