import React from 'react'
import { Typography, Button, TextField, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import StyledImageViewerDialog from '../../uicomponents/styled_image_viewer_dialog'
import { yellow, red } from '@mui/material/colors'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'
import './../../../css/layout.css'
import { rgb565HexToRgbHex } from '../../libs/appUtils'
import { Link as RouterLink } from 'react-router-dom'
import { useDataSizeCheckForSetPixels } from '../../../hooks/murall/use-data-size-check-for-set-pixels'

const useStyles = makeStyles(theme => ({
  dialogInformationRoot: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },

  body: {
    fontSize: 16
  },

  dialogMedia: {
    display: 'block',
    backgroundColor: theme.palette.primary.dark
  },
  dialogMediaCropped: {
    margin: 'auto',
    overflow: 'auto',
    width: '100%',
    height: 'auto'
  },
  footer: {
    position: 'absolute',
    width: '100%',
    minHeight: '1px',
    bottom: '0',
    left: '0',
    overflow: 'hidden'
  },

  dialogContent: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogTitle: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogTitleFlex: {
    backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '8px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  typography: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto'
    // fontWeight: 100,
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    width: '100%',
    justifyContent: 'space-between'
  },
  infoRowWrapper: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  warningRed: {
    color: red[700]
  },
  verticalDivider: {
    borderColor: theme.palette.divider,
    borderTop: 0,
    borderLeft: 'thin',
    borderStyle: 'solid',
    borderBottom: 0,
    borderRight: 0,
    alignSelf: 'stretch',
    height: 'auto',
    marginTop: '16px'
  },

  warningBannerRoot: {
    width: '100%',
    borderRadius: 10,
    background: yellow[800],
    marginTop: '16px',
    padding: '16px'
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  warningIcon: {
    height: '48px',
    width: '48px',
    marginRight: '16px'
  },
  fullFlex: {
    flex: 1
  }
}))

const validNumber = value => /^[\d]+$/.test(value) && value <= 16777215
const createTextRow = (classes, key, value, warning = false) => (
  <div className={clsx(classes.textRow)}>
    <Typography
      className={clsx(classes.typography, classes.body)}
      variant="body2"
      component="p"
    >
      {key}:
    </Typography>
    <Typography
      className={clsx(classes.typography, classes.body, {
        [classes.warningRed]: warning
      })}
      variant="body2"
      component="p"
    >
      {value}
    </Typography>
  </div>
)

const DATA_SIZE_WARNING_THRESHOLD = 4075

export default function DrawingTransactionDialog(props) {
  const classes = useStyles()

  const [numberValue, setNumberValue] = React.useState(0)
  const [seriesIdValue, setSeriesIdValue] = React.useState(0)
  const [nameValue, setNameValue] = React.useState('')
  const [numberError, setNumberError] = React.useState(null)
  const [nameError, setNameError] = React.useState(null)
  const [seriesIdError, setSeriesIdError] = React.useState(null)
  const [isValid, setIsValid] = React.useState(true)
  const smallScreen = useSmallScreenDetection()

  const {
    open,
    croppedBase64PngString,
    numberOfPixels,
    blockchainPixelDataLength,
    blockchainPixelGroupDataLength,
    blockchainPixelGroupIndexDataLength,
    blockchainTransparentPixelGroupDataLength,
    blockchainTransparentPixelGroupIndexDataLength,
    blockchainColourIndexDataLength,
    alphaEnabled,
    alphaChannel,
    costInPaint,
    numberOfColors,
    fullDetail
  } = props

  const {
    loading: loadingSizeCheck,
    maxSize,
    txSize,
    withinLimit
  } = useDataSizeCheckForSetPixels({
    colourIndexData: fullDetail.colourIndexData,
    blockchainPixelData: fullDetail.blockchainPixelData,
    blockchainPixelGroupData: fullDetail.blockchainPixelGroupData,
    blockchainPixelGroupIndexData: fullDetail.blockchainPixelGroupIndexData,
    blockchainTransparentPixelGroupData:
      fullDetail.blockchainTransparentPixelGroupData,
    blockchainTransparentPixelGroupIndexData:
      fullDetail.blockchainTransparentPixelGroupIndexData,
    name: nameValue,
    number: numberValue,
    seriesId: seriesIdValue,
    alphaEnabled: fullDetail.alphaEnabled,
    immediate: !!fullDetail
  })
  const alphaChannelRGBA = '#' + rgb565HexToRgbHex('0x' + alphaChannel)
  const shouldShowWarning = !withinLimit

  const constructWarningBanner = () => {
    return (
      <div className={clsx(classes.warningBannerRoot, classes.flexRow)}>
        <img
          src="images/warning_icon.svg"
          alt="MurAll warning icon"
          className={clsx(classes.warningIcon)}
        />

        <div className={clsx(classes.flexColumn)}>
          <Typography
            className={clsx(classes.typography, classes.fullFlex)}
            variant="h6"
            component="p"
            gutterBottom
            sx={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              // fontWeight: 300,
              textShadow: '0px 0px 1px black'
            }}
          >
            Your image is too large to go into a single transaction.
          </Typography>
          <Typography
            className={clsx(classes.typography, classes.fullFlex)}
            variant="body1"
            component="p"
            color="red"
            align="justify"
            sx={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              // fontWeight: 300,
              textShadow: '0px 0px 1px black'
            }}
          >
            You image has {numberOfPixels} pixels; around 100K-120K pixels per
            image is the maximum that fits into 1 transaction. Please either
            reduce the image size or consider dividing the image into pieces and
            putting it through as multiple transactions; you can associate
            images into a series by giving them the same series id and give them
            unique numbers as part of the series.
            <br />
            <br />
            To divide your image into sections go to "Layers {` > `} Divide
            layer" in the menu. A full 2048 x 1024 pixel coverage divided into 5
            rows by 4 columns produces 20 individual sections, each small enough
            to go into a single transaction.
            <br />
            The individual minted NFT pieces can be combined later into a single
            NFT image using our{' '}
            <Link
              href="https://knowledgebase.murall.art/montage"
              target="_blank"
              rel="noopener"
              underline="hover"
              color="secondary"
            >
              Montage tool
            </Link>
            .
            <br />
            <br />
            For more details, please see{' '}
            <Link
              href="https://knowledgebase.murall.art/tutorials/minting/3-minting#imagetoolarge"
              target="_blank"
              rel="noopener"
              underline="hover"
              color="secondary"
            >
              our knowledgebase
            </Link>
          </Typography>
        </div>
      </div>
    )
  }

  const constructWarningBannerMobile = () => {
    return (
      <div className={clsx(classes.warningBannerRoot, classes.flexColumn)}>
        <div className={clsx(classes.flexRow)} style={{ marginBottom: '8px' }}>
          <img
            src="images/warning_icon.svg"
            alt="MurAll warning icon"
            className={clsx(classes.warningIcon)}
          />
          <Typography
            className={clsx(classes.typography, classes.fullFlex)}
            variant="body1"
            component="p"
          >
            Your image is too large to go into a single transaction.
          </Typography>
        </div>
        <Typography
          className={clsx(classes.typography, classes.fullFlex)}
          variant="body2"
          component="p"
          color="textSecondary"
          align="justify"
        >
          Consider dividing the image into pieces and putting it through as
          multiple transactions; you can associate images into a series by
          giving them the same series id and give them unique numbers as part of
          the series.
          <br />
          <br />
          The individual minted NFT pieces can be combined later into a single
          NFT image using our{' '}
          <Link
            href="https://knowledgebase.murall.art/montage"
            target="_blank"
            rel="noopener"
            underline="hover"
            color="secondary"
          >
            Montage tool.
          </Link>
        </Typography>
      </div>
    )
  }

  const constructAlphaRow = () => {
    return (
      <div className={clsx(classes.textRow)}>
        <Typography
          className={clsx(classes.typography, classes.body)}
          variant="body2"
          component="p"
          color="textSecondary"
          style={{
            flex: 1
          }}
        >
          Alpha channel:
        </Typography>
        {alphaEnabled ? (
          <React.Fragment>
            <Typography
              className={clsx(classes.body)}
              variant="body2"
              component="p"
              color="textSecondary"
              style={{
                color: alphaChannelRGBA
              }}
            >
              &#11044;
            </Typography>
            <Typography
              className={clsx(classes.typography, classes.body)}
              variant="body2"
              component="p"
              color="textSecondary"
              style={{
                paddingLeft: '8px'
              }}
            >
              0x{alphaChannel}
            </Typography>
          </React.Fragment>
        ) : (
          <Typography
            className={clsx(classes.typography, classes.body)}
            variant="body2"
            component="p"
            color="textSecondary"
          >
            Disabled
          </Typography>
        )}
      </div>
    )
  }

  const constructNftInputFields = () => {
    return (
      <React.Fragment>
        <TextField
          {...(nameError && { error: true, helperText: nameError })}
          id="name"
          variant="outlined"
          label="Optional name"
          style={{ flex: 1 }}
          value={nameValue}
          size="small"
          onChange={e => {
            if (e.target.value == '' || e.target.value.length <= 32) {
              setNameValue(e.target.value)
              setNameError(null)
              setIsValid(numberError == null && seriesIdError == null)
            } else {
              setNameError('Must be a string of up to 32 characters')
              setIsValid(false)
              return false
            }
          }}
        />
        <TextField
          {...(numberError && { error: true, helperText: numberError })}
          id="number"
          variant="outlined"
          label="Optional number"
          style={{ flex: 1, marginTop: '16px' }}
          value={numberValue}
          size="small"
          onChange={e => {
            if (e.target.value == '' || validNumber(e.target.value)) {
              setNumberValue(e.target.value)
              setNumberError(null)

              setIsValid(nameError == null && seriesIdError == null)
            } else {
              setNumberError('Must be a value from 0 - 16777215')
              setIsValid(false)
              return false
            }
          }}
        />
        <TextField
          {...(seriesIdError && { error: true, helperText: seriesIdError })}
          id="seriesId"
          variant="outlined"
          label="Optional series id"
          style={{ flex: 1, marginTop: '16px' }}
          value={seriesIdValue}
          size="small"
          onChange={e => {
            if (e.target.value == '' || validNumber(e.target.value)) {
              setSeriesIdValue(e.target.value)
              setSeriesIdError(null)

              setIsValid(numberError == null && nameError == null)
            } else {
              setSeriesIdError('Must be a value from 0 - 16777215')
              setIsValid(false)
              return false
            }
          }}
        />
      </React.Fragment>
    )
  }

  return (
    <StyledImageViewerDialog
      open={open}
      withCloseButton
      onClose={props.onCancelClicked}
      dialogTitle={'Mint image onto MurAll'}
      dialogImageContent={
        <img
          className={clsx(
            classes.dialogMedia,
            classes.dialogMediaCropped,
            'pixelated-image'
          )}
          src={croppedBase64PngString}
          alt={'image to draw'}
        />
      }
      dialogInformation={
        <div className={clsx(classes.dialogInformationRoot)}>
          {!shouldShowWarning && (
            <div className={clsx(classes.infoRowWrapper)}>
              <div
                style={{
                  display: 'inline-block',
                  width: '50%',
                  paddingTop: '16px'
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'stretch',
                    justifyContent: 'space-between',
                    height: '100%',
                    paddingRight: '16px'
                  }}
                >
                  {createTextRow(
                    classes,
                    'Total pixels',
                    numberOfPixels,
                    shouldShowWarning
                  )}

                  {createTextRow(classes, 'Number of colors', numberOfColors)}

                  {constructAlphaRow()}

                  {createTextRow(
                    classes,
                    'Cost in PAINT',
                    costInPaint + ' PAINT'
                  )}
                </div>
              </div>
              <div className={clsx(classes.verticalDivider)} />
              <div
                style={{
                  display: 'inline-flex',
                  width: '50%',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  justifyContent: 'stretch',
                  paddingLeft: '16px',
                  paddingTop: '16px'
                }}
              >
                {constructNftInputFields()}
              </div>
            </div>
          )}
          {shouldShowWarning
            ? smallScreen
              ? constructWarningBannerMobile()
              : constructWarningBanner()
            : null}
        </div>
      }
      dialogActions={
        <>
          {!shouldShowWarning && (
            <React.Fragment>
              <Typography
                className={classes.typography}
                variant="body1"
                component="p"
                align="justify"
                style={{
                  flex: 1,
                  alignSelf: 'flex-start',
                  marginRight: '16px',
                  marginLeft: '16px'
                }}
              >
                By clicking 'DRAW' you agree to the{' '}
                <Link
                  component={RouterLink}
                  to="/terms"
                  color="secondary"
                  underline="hover"
                >
                  Terms and Conditions
                </Link>
                .
              </Typography>
              <Button
                size="medium"
                color="secondary"
                onClick={() => {
                  props.onDrawClicked(nameValue, numberValue, seriesIdValue)
                }}
                disabled={!isValid || shouldShowWarning}
                style={{ marginRight: '16px' }}
              >
                Draw
              </Button>
              <Button
                size="medium"
                color="secondary"
                onClick={props.onCancelClicked}
              >
                Cancel
              </Button>
            </React.Fragment>
          )}
        </>
      }
    />
  )
}
