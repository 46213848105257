import React from 'react'
import MontageNftCard from './montageNftCard'
import PaginatedNftGridView from './paginated_nft_gridview'
import { useActiveWeb3React } from '../../../hooks/web3'
import { useSelector } from 'react-redux'
import { getMontageContractAddress } from '../../modules/blockchain/datasource/ContractAddressDataSource'
import { useMontageBridgedDataSource } from '../../../hooks/use-montage-bridged-datasource'

export default function ViewBridgedMontageNfts () {
  const network = useSelector(state => state.network)
  const { account } = useActiveWeb3React()
  const {
    getBalanceForAddress,
    getMontageFullInformationForOwnedTokenInIndexRange
  } = useMontageBridgedDataSource()

  return (
    <PaginatedNftGridView
      itemsPerPage={9}
      onFetchNftBalance={() => {
        return getBalanceForAddress(account)
      }}
      onFetchNftsInIndexRange={(startIndex, endIndex) => {
        return getMontageFullInformationForOwnedTokenInIndexRange(
          account,
          startIndex,
          endIndex
        )
      }}
      constructNftView={nftBundle => {
        return (
          <MontageNftCard
            key={nftBundle.tokenId}
            address={getMontageContractAddress(network.id)}
            nftInformation={nftBundle}
          />
        )
      }}
    />
  )
}
