import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Slider from '@mui/material/Slider'

const StyledVerticalVolumeSlider = styled(Slider)(({ theme }) => ({
  width: 10,
  height: 100,
  padding: theme.spacing(0.5),
  margin: 0,

  '& input[type="range"]': {
    WebkitAppearance: 'slider-vertical',
  },
  '& .MuiSlider-thumb': {
    display: 'none',
  },
  '& .MuiSlider-track': {
    border: 'none',
    width: 8,
    borderRadius: 4,
  },
  '& .MuiSlider-rail': {
    border: 'none',
    width: 8,
    borderRadius: 4,
  },
}))
export const VerticalVolumeSlider = ({ volume, onVolumeChange }) => {
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault()
    }
  }

  const handleVolumeSliderChange = (event, newValue) => {
    onVolumeChange && onVolumeChange(newValue)
  }

  return (
    <StyledVerticalVolumeSlider
      orientation={'vertical'}
      value={volume}
      onChange={handleVolumeSliderChange}
      min={0}
      max={1}
      step={0.01} // allows fine-grained control over the volume
      onKeyDown={preventHorizontalKeyboardNavigation}
    />
  )
}
