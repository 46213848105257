import React from 'react'
import ErrorMessageView from './error_message_view'
import { Grid } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SupportedChain } from '../../lib/constants'
import NetworkSwitchButton from '../../components/common/buttons/network-switch-button'

export default function NetworkErrorMessageView () {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <ErrorMessageView
      title={'Network unsupported'}
      description={
        'Please select the Ethereum Mainnet or Polygon network in your wallet'
      }
    >
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        spacing={1}
        alignItems={'center'}
        sx={{
          mt: 2,
          width: 'auto'
        }}
      >
        <Grid item>
          <NetworkSwitchButton targetChainId={SupportedChain.Ethereum} />
        </Grid>
        <Grid item>
          <NetworkSwitchButton targetChainId={SupportedChain.Polygon} />
        </Grid>
      </Grid>
    </ErrorMessageView>
  )
}
