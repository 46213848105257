exports.singleTokenValue = (value, totalTokens) => {
  if (value >= totalTokens) return totalTokens
  return value > 0 ? value : 0
}

exports.tokenRangeStartValue = (value, totalTokens) => {
  const max = totalTokens > 0 ? totalTokens - 1 : 0
  if (value >= max) return max
  return value > 0 ? value : 0
}

exports.tokenRangeEndValue = (value, totalTokens) => {
  if (value >= totalTokens) return totalTokens
  return value < 1 ? 1 : value
}
