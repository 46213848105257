import React from 'react'

import CustomAccordion from '../../../components/common/Accordion'
import SectionTitle from '../SectionTitle'

const SectionMurAllEvolv3Nfts = () => {
  return (
    <>
      <SectionTitle variant='h4' component='h4'>
        MurAll NFTs minted on Evolv3
      </SectionTitle>
      <CustomAccordion
        items={[
          {
            title: 'What is a MurAll Evolv3 NFT?',
            content: (
              <>
                NFTs minted on Evolv3 standard ERC721 NFTs with onchain
                metadata, meaning all attributes including name, description,
                file uri and custom additional attributes input at mint time are
                stored onchain in contract storage.
              </>
            )
          },
          {
            title: 'Where is the artwork for MurAll Evolv3 NFTs stored?',
            content: (
              <>
                The artwork for your image/video is stored on ipfs and the uri
                for the hosted file is stored onchain within the NFT.
              </>
            )
          },
          {
            title: 'Is minting an Evolv3 NFT gasless?',
            content: (
              <>
                Yes. Minting an Evolv3 NFT is gasless, meaning you do not need
                to pay network fees for minting. Only your Paint token is used
                in the minting process.
              </>
            )
          },
          {
            title: 'How much Paint will be used in minting?',
            content: (
              <>
                The usage is governed by the community. At present 0.0005 Paint
                per pixel is used to mint an NFT, calculated by the area covered
                by your image (i.e. width multiplied by height). For videos, the
                area is multiplied by the video length in seconds.
              </>
            )
          },

          {
            title: 'What files are supported on Evolv3?',
            content: (
              <>
                Evolv3 supports standard image formats, plus mp4 and webm video
                formats (including webm with transparency).
              </>
            )
          }
        ]}
      />
    </>
  )
}

export default SectionMurAllEvolv3Nfts
