import { useCallback, useEffect, useState } from 'react'
import { useActiveWeb3React } from '../web3.js'
import { MURALL_WALL } from '../../lib/constants.js'
import { usePaintDataSource } from '../use-paint-datasource.js'
import { useSelector } from 'react-redux'

const useGetPaintBalance = ({ account = undefined, immediate = false }) => {
  const { account: connectedAccount } = useActiveWeb3React()
  const currentWall = useSelector(state => state.wall)
  const wallInfo = MURALL_WALL[currentWall.id]
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [paintBalance, setPaintBalance] = useState(undefined)

  const { getBalanceForAddress } = usePaintDataSource(wallInfo.chainId)

  const apply = useCallback(async () => {
    setLoading(true)
    try {
      const accountToFetch = account || connectedAccount
      const balance = await getBalanceForAddress(accountToFetch)
      setPaintBalance(balance)
    } catch (error) {
      console.error(error)

      setError(error)
      setPaintBalance(undefined)
    } finally {
      setLoading(false)
    }
  }, [getBalanceForAddress, connectedAccount, account, currentWall])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, loading, error, paintBalance }
}

export default useGetPaintBalance
