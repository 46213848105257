import React, { useRef, useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import Skeleton from '@mui/material/Skeleton'
import _ from 'underscore'
import { CardActions } from '@mui/material'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px'
  },
  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%' // square
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },

  overlay: {
    position: 'absolute',
    width: '100%',
    bottom: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212100,
            #21212199,
            #212121B3)`
  }
}))

export default function LoadingPlaceholderNftCard (props) {
  const classes = useStyles()
  const [skeletonHeight, setSkeletonHeight] = useState(0)
  const rootRef = useRef()

  useEffect(() => {
    function handleResize () {
      updateSkeletonHeight()
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  const updateSkeletonHeight = () => {
    if (!_.isNull(rootRef.current)) {
      const height = rootRef.current.offsetWidth
      setSkeletonHeight(height)
    }
  }
  return (
    <Card className={classes.root} elevation={10} ref={rootRef}>
      <CardHeader
        avatar={<Skeleton variant='circular' width={40} height={40} />}
        title={<Skeleton variant='text' />}
        subheader={
          <>
            <Skeleton variant='text' />
            <Skeleton variant='text' />
          </>
        }
        titleTypographyProps={{ align: 'left' }}
        subheaderTypographyProps={{ align: 'left' }}
        style={{ padding: '12px' }}
      />

      <Skeleton variant='rectangular' width={345} height={skeletonHeight} />
      <CardActions>
        <Skeleton variant='h3' width='40%' />
      </CardActions>
    </Card>
  )
}
