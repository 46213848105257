import { useState, useEffect } from 'react'
import Base_layers_class from '../../js/core/base-layers'

const useGetFileFromLayer = layer => {
  const [file, setFile] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const grabFileFromLayer = async () => {
      if (!layer) {
        return
      }
      setLoading(true)
      if (layer.type === 'video') {
        setFile(layer.file)
      } else {
        const canvas = new Base_layers_class().convert_layer_to_canvas(
          layer.id,
          false,
          true
        )
        const blob = await new Promise(resolve =>
          canvas.toBlob(resolve, 'image/png')
        )
        setFile(blob)
      }
      setLoading(false)
    }
    grabFileFromLayer()
  }, [layer])

  return { file, loading }
}

export default useGetFileFromLayer
