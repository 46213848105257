import * as React from 'react'
import { styled } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../../hooks/web3'
import switchNetwork from '../../../lib/actions/switch-network'
import { setSelectedNetwork } from '../../../state/slices/networkSwitch'
import {
  CHAIN_INFO,
  MURALL_WALL,
  getDefaultWallIdForChain,
} from '../../../lib/constants'
import { setWall } from '../../../state/slices/wall'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { colorForChain, logoForChain } from '../chainUiUtils'
import AvatarWithAvatarBadge from '../AvatarWithAvatarBadge'
import { useHistory } from 'react-router-dom'
import useUpdateDrawingClientTheme from '../../../hooks/draw/use-update-drawing-client-theme'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const DropDownMenuProps = {
  PaperProps: {
    elevation: 0,
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 180,
    },
    sx: {
      overflow: 'visible',
      bgcolor: '#333',
      borderRadius: '10px',
      mt: 1.5,
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: '50%',
        width: 10,
        height: 10,
        bgcolor: '#333',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  },
}

export default function WallSelect({ sx = {}, onChange }) {
  const { library, chainId } = useActiveWeb3React()

  const history = useHistory()
  const dispatch = useDispatch()

  const currentWall = useSelector((state) => state.wall)
  useUpdateDrawingClientTheme()

  const [display, setDisplay] = React.useState(MURALL_WALL[currentWall.id])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    //clear the query params
    history.replace({
      search: '',
    })

    setDisplay(value)
    switchNetwork({ library, chainId: value.chainId })
    dispatch(setSelectedNetwork(value.chainId))
    dispatch(setWall(value.id))
    onChange && onChange(typeof value === 'string' ? value.split(',') : value)
  }

  React.useEffect(() => {
    if (chainId) {
      setDisplay(MURALL_WALL[getDefaultWallIdForChain(chainId)])
    }
  }, [])
  React.useEffect(() => {
    setDisplay(MURALL_WALL[currentWall.id])
  }, [currentWall])

  return (
    <Select
      labelId="wall-select-label"
      id="wall-select"
      value={display}
      onChange={handleChange}
      renderValue={(selected) => (
        <AvatarWithAvatarBadge
          avatarParams={{
            sx: { width: 30, height: 30 },
            alt: selected.name,
            src: selected.murallLogo,
            variant: 'square',
          }}
          badgeParams={{
            alt: CHAIN_INFO[selected.chainId]?.label,
            src: logoForChain(selected.chainId),
            sx: { bgcolor: colorForChain(selected.chainId) },
          }}
        />
      )}
      MenuProps={DropDownMenuProps}
      disableUnderline
      sx={{
        ...sx,
        height: 34,
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        borderRadius: 0,
        border: '0px solid',
        boxShadow: 'none',
      }}
    >
      {Object.values(MURALL_WALL).map((wallInfo, index) => (
        <MenuItem key={wallInfo.id} value={wallInfo}>
          <WallListItem wallInfo={wallInfo} />
        </MenuItem>
      ))}
    </Select>
  )
}

const WallListItem = ({ wallInfo }) => {
  return (
    <>
      <ListItemIcon>
        <Avatar
          sx={{ width: 24, height: 24 }}
          alt={wallInfo.name}
          src={wallInfo.murallLogo}
          variant="square"
        />
      </ListItemIcon>
      <ListItemText primary={wallInfo.name} sx={{ color: 'text.primary' }} />
      <ListItemIcon
        sx={{
          width: 24,
          height: 24,

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Avatar
          sx={{
            width: 24,
            height: 24,
            padding: '3px',
            bgcolor: colorForChain(wallInfo.chainId),
          }}
          alt={CHAIN_INFO[wallInfo.chainId]?.label}
          src={logoForChain(wallInfo.chainId)}
        />
      </ListItemIcon>
    </>
  )
}
