import React, { useEffect, useState } from 'react'
import Base_layers_class from '../../js/core/base-layers'
import useGetFileFromLayer from '../layers/use-get-file-from-layer'

const MAXIMUM_FILESIZE_BYTES_VIDEO = 1048576 * 50 // 50 MB
const MAXIMUM_FILESIZE_BYTES_IMAGE = 1048576 * 10 // 10 MB
export const useValidateFilesizeForLayer = ({ layer }) => {
  const [loading, setLoading] = useState(false)

  const [maximumFilesizeBytes, setMaximumFilesizeBytes] = useState(0)
  const [layerFilesizeBytes, setLayerFilesizeBytes] = useState(0)
  const [valid, setValid] = useState(false)
  const { file, loading: loadingFileFromLayer } = useGetFileFromLayer(layer)

  useEffect(() => {
    if (!layer || !file || loadingFileFromLayer) {
      return
    }
    async function checkIsValid () {
      setLoading(true)
      const maximumFilesizeBytes =
        layer.type === 'image'
          ? MAXIMUM_FILESIZE_BYTES_IMAGE
          : MAXIMUM_FILESIZE_BYTES_VIDEO

      setMaximumFilesizeBytes(maximumFilesizeBytes)

      if (layer.type === 'image') {
        const filesizeBytes = file.size

        setLayerFilesizeBytes(filesizeBytes)
        setValid(filesizeBytes <= maximumFilesizeBytes)
      } else if (layer.type === 'video') {
        setLayerFilesizeBytes(layer._exif?.general?.SizeBytes)
        setValid(maximumFilesizeBytes > layer?._exif?.general?.SizeBytes)
      } else {
        setValid(false)
        setLoading(false)
        return
      }

      setLoading(false)
    }
    checkIsValid()
  }, [layer, loadingFileFromLayer, file])

  return {
    valid,
    maximumFilesizeBytes,
    layerFilesizeBytes,
    loading: loading || loadingFileFromLayer
  }
}

export default useValidateFilesizeForLayer
