import { CHAIN_INFO } from '../constants'
import addNetwork from './add-network'

const switchToNetwork = async ({ library, chainId, account }) => {
  const currentProvider = library?.currentProvider || library
  if (!currentProvider) return

  if (!chainId && library?.eth?.net?.getId) {
    ({ chainId } = await library.eth.net.getId())
  }

  try {
    await currentProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${chainId.toString(16)}` }, account]
    })
  } catch (error) {
    console.log('error switching eth network: ', chainId, JSON.stringify(error))
    if (error.code === -32000 && window.ethereum) {
      switchToNetwork({ library: window.ethereum, chainId, account })
    }
    // 4902 is the error code for attempting to switch to an unrecognized chainId
    if (error.code === 4902 && chainId !== undefined) {
      const info = CHAIN_INFO[chainId]

      // metamask (only known implementer) automatically switches after a network is added
      // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
      // metamask's behavior when switching to the current network is just to return null (a no-op)
      await addNetwork({ library, chainId, info })
      await switchToNetwork({ library, chainId })
    } else {
      throw error
    }
  }
}

export default switchToNetwork
