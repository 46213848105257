import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'underscore'
import { Draggable } from 'react-beautiful-dnd'
import clsx from 'clsx'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import Button from '@mui/material/Button'
import { green } from '@mui/material/colors'
import './../../../css/layout.css'
import { useTheme } from 'styled-components'
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Checkbox,
  ListItemButton,
  CircularProgress,
  IconButton
} from '@mui/material'
import ErrorTextIconTooltip from '../../../components/common/ErrorTextIconTooltip'
const useStyles = makeStyles(theme => ({
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  inline: {
    display: 'inline'
  },
  listItem: {
    backgroundBlendMode: `multiply, normal, normal`,
    minHeight: theme.spacing(13),
    padding: 0,
    margin: 0
  },
  listItemTypography: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0
  },

  listBgImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundBlendMode: props =>
      props.selected
        ? `hard-light, normal, normal`
        : `multiply, normal, normal`,
    backgroundImage: props =>
      props.selected
        ? `radial-gradient(circle at top right,
          ${theme.palette.secondary.main}cc 30%,
          ${theme.palette.primary.main}),
          url(${props.previewImage}),
          linear-gradient(to right, #26262B, #26262B)`
        : `radial-gradient(circle at top right,
      #cccccc,
      #4a4a4a,
      #212121),
      url(${props.previewImage}),
      linear-gradient(to right, #26262B, #26262B)`
  },
  selectedGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },
  onTop: {
    zIndex: 999
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  secondaryTypographyContainer: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  buttonMint: {
    color: '#fff'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}))

const ListItemContents = ({
  provided,
  classes,
  selected,
  disableSelect,
  onCheckboxClicked,
  onListItemClick,
  id,
  disableDrag,
  checked,
  layer,

  primaryText,
  secondaryText1,
  secondaryText2,
  onMintButtonClicked,
  minted,
  loading,
  warningText,
  warningTooltipText
}) => (
  <ListItemButton
    focusRipple
    className={clsx(classes.listItem, 'pixelated-image', classes.listBgImage)}
    onClick={event => {
      if (disableSelect) {
        onCheckboxClicked && onCheckboxClicked(layer)
        return
      }

      onListItemClick && onListItemClick(event, id, layer)
    }}
  >
    {!disableDrag && (
      <IconButton
        {...provided.dragHandleProps}
        className={clsx(classes.icon, classes.onTop)}
        edge="start"
        aria-label="draghandle"
        style={{
          alignSelf: 'center',
          paddingLeft: '16px'
        }}
        size="large"
      >
        <DragHandleIcon />
      </IconButton>
    )}
    <Checkbox
      className={clsx(classes.onTop)}
      edge="end"
      checked={checked}
      tabIndex={-1}
      inputProps={{ 'aria-labelledby': id }}
      style={{
        alignSelf: 'center'
      }}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        onCheckboxClicked && onCheckboxClicked(layer)
      }}
    />

    <ListItemText
      disableTypography
      style={{
        alignSelf: 'center',
        paddingLeft: '16px'
      }}
      className={clsx(
        classes.typography,
        classes.onTop,
        classes.listItemTypography
      )}
      primary={
        <Typography
          component="h5"
          variant="h5"
          noWrap
          className={clsx(classes.typography)}
          color="textPrimary"
        >
          {primaryText}
        </Typography>
      }
      secondary={
        <div className={clsx(classes.secondaryTypographyContainer)}>
          {secondaryText1 && (
            <Typography
              component="span"
              variant="subtitle1"
              noWrap
              className={clsx(classes.inline, classes.typography)}
              color="textPrimary"
            >
              {secondaryText1}
            </Typography>
          )}
          {secondaryText2 && (
            <Typography
              component="span"
              variant="subtitle2"
              noWrap
              className={clsx(classes.inline, classes.typography)}
              color={selected ? 'textPrimary' : 'textSecondary'}
            >
              {secondaryText2}
            </Typography>
          )}
        </div>
      }
    />

    <ListItemSecondaryAction
      className={clsx(classes.onTop)}
      style={{ display: 'flex' }}
    >
      {warningText && warningTooltipText ? (
        <ErrorTextIconTooltip
          text={warningText}
          tooltipText={warningTooltipText}
        />
      ) : (
        onMintButtonClicked && (
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={clsx(
                classes.icon,
                classes.onTop,
                {
                  [classes.buttonSuccess]: minted
                },
                {
                  [classes.buttonMint]: !minted
                }
              )}
              disabled={loading}
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                if (!minted) onMintButtonClicked && onMintButtonClicked(layer)
              }}
            >
              {loading ? 'Minting...' : minted ? 'Minted!' : 'Mint'}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        )
      )}
    </ListItemSecondaryAction>
  </ListItemButton>
)

export default function LayerListItem(props) {
  const classes = useStyles(props)
  const theme = useTheme()

  return props.disableDrag ? (
    <ListItem
      className={clsx(
        classes.listItem,
        {
          [classes.onTop]: props.selected
        },
        {
          [classes.selectedGlow]: props.selected
        }
      )}
      alignItems="flex-start"
      disableGutters
      dense
      divider
      key={props.id}
      selected={props.selected}
    >
      <ListItemContents
        classes={classes}
        selected={props.selected}
        disableSelect={props.disableSelect}
        onCheckboxClicked={props.onCheckboxClicked}
        onListItemClick={props.onListItemClick}
        id={props.id}
        disableDrag={props.disableDrag}
        checked={props.checked}
        layer={props.layer}
        primaryText={props.primaryText}
        secondaryText1={props.secondaryText1}
        secondaryText2={props.secondaryText2}
        onMintButtonClicked={props.onMintButtonClicked}
        minted={props.minted}
        loading={props.loading}
        warningText={props.warningText}
        warningTooltipText={props.warningTooltipText}
      />
    </ListItem>
  ) : (
    <Draggable
      key={props.id}
      draggableId={props.id.toString()}
      index={props.index}
    >
      {(provided, snapshot) => (
        <ListItem
          className={clsx(
            classes.listItem,
            {
              [classes.onTop]: props.selected
            },
            {
              [classes.selectedGlow]: props.selected
            }
          )}
          sx={{
            // hover states
            '& .MuiListItemButton-root:hover': {
              backgroundImage: props.selected
                ? `radial-gradient(circle at top right,
          ${theme.palette.secondary.main}80 30%,
          ${theme.palette.primary.main})80,
          url(${props.previewImage}),
          linear-gradient(to right, #26262B, #26262B)`
                : `radial-gradient(circle at top right,
      #cccccc80,
      #4a4a4a80,
      #21212180),
      url(${props.previewImage}),
      linear-gradient(to right, #26262B, #26262B)`,
              '&, & .MuiTypography-root': {
                textShadow: '2px 2px 5px black'
              }
            }
          }}
          {...provided.draggableProps}
          ref={provided.innerRef}
          alignItems="flex-start"
          disableGutters
          dense
          divider
          key={props.id}
          selected={props.selected}
        >
          <ListItemContents
            provided={provided}
            classes={classes}
            selected={props.selected}
            disableSelect={props.disableSelect}
            onCheckboxClicked={props.onCheckboxClicked}
            onListItemClick={props.onListItemClick}
            id={props.id}
            disableDrag={props.disableDrag}
            checked={props.checked}
            layer={props.layer}
            primaryText={props.primaryText}
            secondaryText1={props.secondaryText1}
            secondaryText2={props.secondaryText2}
            onMintButtonClicked={props.onMintButtonClicked}
            minted={props.minted}
            loading={props.loading}
            warningText={props.warningText}
            warningTooltipText={props.warningTooltipText}
          />
        </ListItem>
      )}
    </Draggable>
  )
}
