import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'underscore'
import clsx from 'clsx'
import './../../../../../css/layout.css'
import { ListItem, ListItemText, Typography, ListItemButton } from '@mui/material'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0
  },

  listItem: {
    minHeight: theme.spacing(7),
    padding: 0,
    margin: 0
  },
  listItemTypography: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0
  },

  listBgImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundBlendMode: `multiply, normal, normal`,
    backgroundImage: (props) => `radial-gradient(circle at top right,
      #cccccc,
      #4a4a4a,
      #212121),
      url(${props.image}),
      linear-gradient(to right, #26262B, #26262B)`
  },

  onTop: {
    zIndex: 999
  },
  typography: {
    fontFamily: 'Roboto',
    backgroundColor: 'transparent'
  },
  disablePointerEvents: {
    pointerEvents: 'none'
  },
  secondaryTypographyContainer: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  }
}))

export default function TokenListItem(props) {
  const classes = useStyles(props)

  return (
    <ListItem
      {...props}
      className={clsx(classes.root)}
      alignItems="flex-start"
      disableGutters
      dense
      divider
      key={props.id}
      sx={{
        // hover states
        '& .MuiListItemButton-root:hover': {
          backgroundImage: `radial-gradient(circle at top right,
      #cccccc80,
      #4a4a4a80,
      #21212180),
      url(${props.image}),
      linear-gradient(to right, #26262B, #26262B)`,
          '&, & .MuiTypography-root': {
            textShadow: '2px 2px 5px black'
          }
        }
      }}
    >
      <ListItemButton
        focusRipple
        className={clsx(classes.listItem, 'pixelated-image', classes.listBgImage)}
        onClick={() => {
          props.onListItemClick && props.onListItemClick(props.item)
        }}
      >
        <ListItemText
          disableTypography
          style={{
            alignSelf: 'center',
            paddingLeft: '16px'
          }}
          className={clsx(classes.typography, classes.onTop, classes.listItemTypography, classes.disablePointerEvents)}
          primary={
            <Typography
              component="body"
              variant="body"
              noWrap
              className={clsx(classes.typography, classes.disablePointerEvents)}
              color="textPrimary"
            >
              {props.primaryText}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
