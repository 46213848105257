import React from 'react'
import Avatar from '@mui/material/Avatar'
import { useTheme } from '@mui/material/styles'

export default function StyledEmojiAvatar({ children, style = {}, sx = {} }) {
  const theme = useTheme()
  const emojiStyle = {
    textShadow: '0 0 4px black' // Outline effect
    // filter: 'drop-shadow(2px 4px 6px black)' // Apply a drop shadow
    // You can experiment with these filters to achieve the desired effect
    // filter: 'brightness(0) invert(1)', // This would make the emoji white
    // filter: 'grayscale(0%) contrast(300%)', // This would increase the contrast
    // filter: 'brightness(0) saturate(100%) invert(20%) sepia(86%) saturate(2927%) hue-rotate(284deg) brightness(111%) contrast(113%)'
  }
  return (
    <Avatar
      sx={sx}
      style={{
        backgroundImage: `linear-gradient( 135deg,${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
        // backgroundColor: 'transparent',
        ...style
      }}
    >
      <span style={emojiStyle}>{children}</span>
    </Avatar>
  )
}
