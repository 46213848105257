import React from 'react'
import CustomAccordion from '../../../components/common/Accordion'
import SectionTitle from '../SectionTitle'

const SectionPaintToken = () => {
  return (
    <>
      <SectionTitle variant='h4' component='h4'>
        Paint (PAINT) Token
      </SectionTitle>
      <CustomAccordion
        items={[
          {
            title: 'What is Paint?',
            content: (
              <>
                Paint (ticker symbol PAINT) is a fixed-supply ERC-20 token,
                required to draw on MurAll. Just like real life paint, you can
                only use as much as you have to draw on the canvas, and just
                like real life paint, when it’s used it can’t be used again (the
                Paint token is burned as part of the draw transaction, reducing
                the total supply of Paint).
              </>
            )
          },
          {
            title: 'What is the total supply of Paint?',
            content: (
              <>
                There is a fixed supply of 22,020,096,000 Paint tokens. At 0.5
                Paint per pixel, it costs 1,048,576 Paint to draw over all
                2,097,152 pixels once. 1,048,576 Paint x 21,000 draws =
                22,020,096,000 Paint.
              </>
            )
          },
          {
            title: 'How much Paint will be used per pixel to draw on MurAll?',
            content: (
              <>
                0.5 Paint per pixel (excluding the network gas fee for the
                transaction).
              </>
            )
          },
          {
            title: 'Can Paint be reused?',
            content: (
              <>
                No. Just like in real life you can’t take your paint off of the
                wall and reuse it to paint again, once you use Paint to draw on
                MurAll, it can’t be taken away and reused. Under the hood in the
                MurAll smart contract, the Paint used in the drawing transaction
                is burned, and so the overall supply of Paint is reduced as a
                result.
              </>
            )
          },
          {
            title: 'Will there ever be more Paint produced?',
            content: <>No. There is a fixed supply.</>
          },
          {
            title:
              'Why is there a fixed supply of Paint? Why is the Paint burned per transaction?',
            content: (
              <>
                This is a mechanism to improve the overall quality of the art
                drawn on the canvas over time. Burning the tokens in each
                transaction reduces the total supply, which increases the rarity
                (and in turn, the value) of the remaining tokens. This means at
                the beginning of the life of MurAll when Paint is plentiful in
                supply, drawing to the canvas will be incredibly cheap, which no
                doubt will result in lower quality artwork, where anyone and
                everyone will draw anything and everything on the canvas because
                it is so cheap. However, over time as the tokens supply reduces
                (and thus the rarity increases) this will result in the
                remaining tokens increasing in value, causing token holders to
                be more reluctant to use their tokens to draw meaningless
                things, leaving only those who truly want to make a statement or
                who truly want to make good quality art with the desire to use
                the tokens to draw on the canvas.
              </>
            )
          },
          {
            title: 'Is Paint mineable?',
            content: <>No, it is not possible to mine Paint.</>
          },
          {
            title: 'How can I get some Paint?',
            content: (
              <>
                Verified artists on KnownOrigin/SuperRare/Rarible/AsyncArt
                (snapshot taken 15/11/2020), and ERC721 NFT hodlers (snapshot
                for addresses taken 18/12/2020) are eligible to claim Paint; you
                can check your eligibility to claim on the address checker by
                clicking through from the Paint summary. Paint can also be
                purchased on the open market.
              </>
            )
          },
          {
            title: 'Who qualifies for the Paint airdrop?',
            content: (
              <>
                To ensure that MurAll hit the ground running, we targeted
                wallets of users we believed to be crypto artists or active in
                the NFT community. Therefore we searched for verified artists
                and wallets with both incoming and outgoing ERC-721 token
                transactions. We believed that this would ensure we cater to an
                active art community and people who are familiar/competent with
                ERC-721 tokens and thus will appreciate our project more!
                <br></br>
                <br></br>
                You are able to claim Paint if:
                <br></br>
                <ol>
                  <li>
                    You are a verified blockchain artist (addresses collated
                    from Known Origin/Rarible/SuperRare/Async Art) - Snapshot
                    taken 15/11/2020
                  </li>
                  <li>
                    You are a trading NFT holder. To be an active trading NFT
                    holder, there are 3 criteria:
                    <ul>
                      <li>Your NFTs are fully ERC-721 compatible</li>
                      <li>
                        You have more incoming than outgoing transactions with
                        said NFTs
                      </li>
                      <li>
                        These criteria were fulfilled at the time of taking the
                        snapshot (18/12/2020)
                      </li>
                    </ul>
                  </li>
                </ol>
                Following these requirements gave us 51 754 addresses that are
                eligible for the airdrop and ensured that the claimable amount
                is meaningful and claimants could actually use it to draw on the
                MurAll canvas, which is what we are trying to achieve.
              </>
            )
          },
          {
            title: 'Is there a deadline for claiming airdropped Paint?',
            content: (
              <>
                The Paint airdrop claim contract has a timelock of 365 days from
                it's inception (22nd of January 2021), which allows us to
                reclaim any unclaimed Paint after 365 days in order for us to
                redistribute in other ways, so eligible claimants have until
                22nd of January 2022 to claim.
              </>
            )
          }
        ]}
      />
    </>
  )
}

export default SectionPaintToken
