import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Layout from './components/common/layout'

import TradeLandingPage from './js/pages/trade/tradeLandingPage'
import ViewAllNfts from './js/pages/nfts/view_all_nfts'
import ViewMurAllNfts from './js/pages/nfts/viewMurAllNfts'
import ViewNftBundles from './js/pages/nfts/viewNftBundles'
import ViewMontageNfts from './js/pages/nfts/viewMontageNfts'
import ViewHistory from './js/pages/history/view_murall_history_svg'
import NftDisplay from './js/pages/gallery/all_nfts_display'
import StatsLandingPage from './js/pages/stats/statsLandingPage'
import AuctionDetailsPage from './js/pages/marketplace/auctionDetailsPage'

import LandingPage from './pages/landing'
import AboutPage from './pages/about'
import FaqPage from './pages/faq/faq'
import TermsPage from './pages/terms'
import DrawPage from './pages/draw'
import MurAllNftPage from './pages/murallNft'
import MontageNftPage from './pages/montageNft'
import ViewBridgedMontageNfts from './js/pages/nfts/viewBridgedMontageNfts'
import ViewBridgedMurAllNfts from './js/pages/nfts/viewBridgedMurAllNfts'
import MyStatsLandingPage from './js/pages/stats/myStatsLandingPage'
import ArtistPage from './pages/artist'
import ArtistLandingPage from './pages/artistsLanding'
import { MURALL_WALL_ID, MURALL_WALL_QUERY_PARAM } from './lib/constants'

const Routes = () => {
  return (
    <Layout>
      <Switch>
        <Route path='/draw' component={DrawPage} exact />
        {/* <Route path='/trade' component={TradeLandingPage} exact /> */}
        <Route path='/allnfts' component={ViewAllNfts} exact />
        <Route path='/murallnfts' component={ViewMurAllNfts} exact />
        <Route
          path='/bridgedmurallnfts'
          component={ViewBridgedMurAllNfts}
          exact
        />
        <Route path='/nftbundles' component={ViewNftBundles} exact />
        <Route path='/montagenfts' component={ViewMontageNfts} exact />
        <Route
          path='/bridgedmontagenfts'
          component={ViewBridgedMontageNfts}
          exact
        />
        <Route path='/state' component={ViewHistory} exact />
        <Route path='/history' exact>
          <Redirect to='/state' />
        </Route>
        <Route path='/gallery' component={NftDisplay} exact />
        <Route path='/stats' component={StatsLandingPage} exact />
        <Route path='/mystats' component={MyStatsLandingPage} exact />
        <Route path='/auction' component={AuctionDetailsPage} exact />
        <Route path='/live' exact>
          <Redirect to='/state?live=true' />
        </Route>
        <Route path='/terms' component={TermsPage} exact />
        <Route path='/faq' component={FaqPage} exact />
        <Route path='/about' component={AboutPage} exact />
        <Route path='/artists' component={ArtistLandingPage} exact />
        <Redirect
          from={'/murall/polygon/:id'}
          to={`/murall/${MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.LAYER_2]}/:id`}
        />
        <Redirect
          from={'/montage/polygon/:id'}
          to={`/montage/${MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.LAYER_2]}/:id`}
        />
        <Redirect
          from={'/murall/ethereum/:id'}
          to={`/murall/${MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.LAYER_1]}/:id`}
        />
        <Redirect
          from={'/montage/ethereum/:id'}
          to={`/montage/${MURALL_WALL_QUERY_PARAM[MURALL_WALL_ID.LAYER_1]}/:id`}
        />
        <Route path='/murall/:wall/:id'>
          <MurAllNftPage />
        </Route>
        <Route path='/montage/:wall/:id'>
          <MontageNftPage />
        </Route>
        <Route path='/artist/:address'>
          <ArtistPage />
        </Route>
        <Route path='/' component={LandingPage} exact />
        <Redirect to='/' />
      </Switch>
    </Layout>
  )
}

export default Routes
