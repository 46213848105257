import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import CustomAccordion from '../../../components/common/Accordion'
import SectionTitle from '../SectionTitle'

const SectionMurAll = () => {
  return (
    <>
      <SectionTitle variant='h4' component='h4'>
        MurAll
      </SectionTitle>
      <CustomAccordion
        items={[
          {
            title: 'What is MurAll?',
            content: (
              <>
                MurAll is an on-chain digital collaborative mural/canvas/wall
                that anyone anywhere can draw on, without restrictions on what
                you can draw. The entire ethos of the MurAll project is that it
                fits with the blockchain narrative of a neutral place that
                doesn’t filter, censor or stop any transaction. A place for true
                freedom of speech through art. The only thing required to draw
                on MurAll is the Paint token - a fixed supply ERC-20 token
                analogous with real life paint; you can't take paint off a wall
                and reuse it once it's on there, and similarly any Paint used in
                drawing is burned as part of the transaction preventing it from
                being reused. Your contribution to MurAll will be on the
                blockchain in its history for as long as the blockchain lives
                (i.e. you will always be able to see your contribution there in
                the history, even if someone draws over your pixels). When you
                draw, you also get a MURALL Non-Fungible Token (NFT) of your
                contribution, a token containing your drawing, which you own and
                are free to do with as you please - hold, display, trade,
                collect, the choice is yours. MurAll's tokenomics with Paint
                mean that it has a finite lifespan; once the Paint runs out you
                won’t be able to draw on MurAll again.
              </>
            )
          },
          {
            title: 'What are the dimensions of MurAll?',
            content: <>2048 pixels by 1024 pixels (2097152 pixels in total).</>
          },
          {
            title: 'Is there any restriction on what you can draw?',
            content: (
              <>
                No; any restriction would go against the entire ethos of the
                MurAll project. MurAll itself is a protocol that anyone can
                interact with, so people are free to create their own clients
                with filters and restrictions, but the protocol itself does not
                have any.
              </>
            )
          },
          {
            title: 'Do you own the pixels you draw over?',
            content: (
              <>
                No, the pixels on MurAll have no ownership; anyone can draw
                anything, anywhere, on MurAll, the only restriction is that it
                requires Paint to draw. However, you do own the MURALL NFT
                produced by drawing on MurAll.
              </>
            )
          },
          {
            title: 'Can you draw over someone else’s drawing?',
            content: (
              <>
                Yes. Unlike some other collaborative drawing projects, you do
                not own the pixels you draw over on MurAll, however no one can
                draw on your MURALL NFT which contains your original drawing on
                MurAll.
              </>
            )
          },
          {
            title: 'Can you draw the same thing that someone else has drawn?',
            content: (
              <>
                Yes. As mentioned, the entire point of MurAll is that there is
                no restriction, you can draw whatever you like, which includes
                the same thing that others have drawn. However, the tokenomics
                behind the Paint token (mostly it’s finite supply and burn upon
                use) and the fact you can only cover MurAll in its entirety a
                maximum of 21 thousand times means people will think twice about
                redrawing other people’s work; wouldn’t you rather draw
                something unique considering you may not get the opportunity to
                draw on MurAll again?
              </>
            )
          },
          {
            title: 'What do you get for drawing on MurAll?',
            content: (
              <>
                When you draw onto MurAll, not only is your drawing written into
                the blockchain history forever, you also get a MURALL NFT with a
                hash of the pixel data, which is entirely yours to do with as
                you please; hodl, display, sell, trade, load into other
                platforms, the choice is yours.
              </>
            )
          },
          {
            title:
              'What are the terms and conditions of using this website to interact with MurAll?',
            content: (
              <>
                You can find the terms and conditions{' '}
                <Link
                  component={RouterLink}
                  to='/terms'
                  color='secondary'
                  underline='hover'
                >
                  here
                </Link>
              </>
            )
          },
          {
            title:
              'What are the contract addresses for the smart contracts used by MurAll?',
            content: (
              <>
                <br />
                Revo1t (Ethereum):
                <br />
                <br />
                MurAll:{' '}
                <Link
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  href='https://etherscan.io/address/0x6442bdfd16352726aa25ad6b3cbab3865c05ed15'
                  color='secondary'
                >
                  0x6442bdfd16352726aa25ad6b3cbab3865c05ed15
                </Link>
                <br />
                <br />
                MurAll NFT (ERC-721):{' '}
                <Link
                  href='https://etherscan.io/address/0x5bb3d01f32687f92f8636799687e55cba0627704'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x5bb3d01f32687f92f8636799687e55cba0627704
                </Link>
                <br />
                <br />
                Paint (PAINT) Token (ERC-20):{' '}
                <Link
                  href='https://etherscan.io/token/0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042
                </Link>
                <br />
                <br />
                Paint airdrop claim contract:{' '}
                <Link
                  href='https://etherscan.io/address/0xf45ba58c415ed6c77cfc157f42f300f9fd980985'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0xf45ba58c415ed6c77cfc157f42f300f9fd980985
                </Link>
                <br />
                <br />
                MurAll data validator:{' '}
                <Link
                  href='https://etherscan.io/address/0x94A20eefdb61eF160f052D8ec9F8Fb328907ba29'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x94A20eefdb61eF160f052D8ec9F8Fb328907ba29
                </Link>
                <br />
                <br />
                MurAll NFT data storage (contract storage for filled tokens):{' '}
                <Link
                  href='https://etherscan.io/address/0xe2514e229a657F37DA9384679452C34466FA9539'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0xe2514e229a657F37DA9384679452C34466FA9539
                </Link>
                <br />
                <br />
                MurAll NFT Metadata decoder:{' '}
                <Link
                  href='https://etherscan.io/address/0x0041C8050A2c895d532103f1F7A234794deF1D8E'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x0041C8050A2c895d532103f1F7A234794deF1D8E
                </Link>
                <br />
                <br />
                <br />
                Di2rupt (Polygon/Matic) addresses:
                <br />
                <br />
                Paint (PAINT) Token (ERC-20):{' '}
                <Link
                  href='https://polygonscan.com/token/0x7c28f627ea3aec8b882b51eb1935f66e5b875714'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x7c28F627eA3aEc8B882b51eb1935f66e5b875714
                </Link>
                <br />
                <br />
                MurAll NFT (ERC-721):{' '}
                <Link
                  href='https://polygonscan.com/token/0xffbce889d62f6c73f005b836255ad69b0a01b222'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0xFfbce889D62F6C73f005B836255aD69B0a01b222
                </Link>
              </>
            )
          }
        ]}
      />
    </>
  )
}

export default SectionMurAll
