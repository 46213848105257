import React, { useRef, useCallback, useState, useEffect } from 'react'
import _ from 'underscore'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import useSmallScreenDetection from './useSmallScreenDetection'
import './../../css/layout.css'
import TransparentDialog from './transparent_dialog'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  MURALL_WALL,
  SupportedChain,
  getWallIdForContractAddress
} from '../../lib/constants'
import { useApi } from '../../hooks/use-api'
import ZoomableViewWrapper from '../../components/common/ZoomableViewWrapper'
import SvgImageOverlayFocusableView from '../../components/common/svg/svg-image-overlay-focusable-view'

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  dialogMedia: {
    backgroundColor: theme.palette.primary.dark
  },
  nftInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    flex: 1,
    alignContent: 'flex-end',
    justifyContent: 'flex-end'
  },
  noPadding: {
    padding: 0,
    margin: 0
  },

  dialogMediaInPlaceWrapper: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    aspectRatio: '1/1'
  },
  dialogAnimValues: {
    transition: 'all 0.6s'
  },
  dialogMediaInPlace: {
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: '50%' /*2:1 aspect ratio */,
    objectFit: 'contain'
  },

  dialogMediaInPlaceBg: {
    filter: 'brightness(35%) blur(3px)'
  },
  dialogMediaCropped: {
    width: '100%',
    objectFit: 'contain',
    justifyContent: 'center'
  }
}))

export default function NftInformationDialog(props) {
  const classes = useStyles()
  const smallScreen = useSmallScreenDetection()
  const [shouldDim, setShouldDim] = useState(false)
  const dialogContentRef = useRef()
  const [imageHeight, setImageHeight] = useState(0)

  const chainId = props.chainId ? props.chainId : props.nftInformation.chainId
  const contractAddress = props.contractAddress || props.nftInformation.contractAddress

  const wallId = props.wallId || props.nftInformation.wallId || getWallIdForContractAddress(contractAddress, chainId)
  const wallInfo = MURALL_WALL[wallId]
  const api = useApi(chainId, wallInfo.id)

  useEffect(() => {
    function handleResize() {
      updateExpandedHeight()
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  const updateExpandedHeight = () => {
    if (dialogContentRef.current) {
      setImageHeight(dialogContentRef.current.getBoundingClientRect().height)
    }
  }

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      dialogContentRef.current = node
      setImageHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const croppedImage = props.croppedImage
    ? props.croppedImage
    : props.nftInformation && props.nftInformation.croppedBase64PngString
  const artist = props.artist ? props.artist : props.nftInformation && props.nftInformation.artist
  const name = props.name ? props.name : props.nftInformation && props.nftInformation.name
  const tokenId = props.tokenId ? props.tokenId : props.nftInformation && props.nftInformation.tokenId
  const description = props.description ? props.description : props.nftInformation && props.nftInformation.description
  const completionStatus =
    props.showCompletionStatus && props.completionStatus
      ? props.completionStatus
      : props.nftInformation && props.nftInformation.completionStatus

  const positionInformation = props.nftInformation && props.nftInformation.positionInformation

  const withViewCropToggle = props.withViewCropToggle

  const [viewCroppedInternal, setViewCroppedInternal] = React.useState(true)

  const handleViewCropped = () => {
    setViewCroppedInternal(true)
  }
  const handleViewFull = () => {
    setViewCroppedInternal(false)
  }
  const viewCropped = withViewCropToggle ? viewCroppedInternal : props.viewCropped

  return (
    <TransparentDialog
      {...props}
      withCloseButton
      iconUrl={chainId === SupportedChain.Polygon ? '/images/l2murall_nft_logo.png' : '/images/murall_nft_logo.png'}
      dialogTitle={name}
      {...(withViewCropToggle && {
        positiveButtonTitle: 'View Cropped',
        onPositiveButtonClick: handleViewCropped,
        negativeButtonTitle: 'View In Position',
        onNegativeButtonClick: handleViewFull
      })}
      dialogContentRef={measuredRef}
      dialogContent={() =>
        viewCropped ? (
          <ZoomableViewWrapper>
            <img
              className={clsx('pixelated-image', classes.dialogMediaCropped, {
                [classes.mobileImg]: smallScreen
              })}
              src={croppedImage}
              alt={'Token ' + tokenId + 'has not been filled!'}
              style={{
                height: imageHeight != 0 ? imageHeight + 'px' : 100 + 'vh'
              }}
            />
          </ZoomableViewWrapper>
        ) : (
          <ZoomableViewWrapper
            style={{
              position: 'relative',
              minHeight: '100%',
              minWidth: '100%',
              margin: '0 auto'
            }}
          >
            {positionInformation && (
              <SvgImageOverlayFocusableView
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                style={{
                  width: '100%',
                  height: '100%'
                }}
                backgroundImageSrc={api.s3.history(parseInt(tokenId) - 1)}
                backgroundImageProps={{
                  height: CANVAS_HEIGHT,
                  width: CANVAS_WIDTH
                }}
                foregroundImageSrc={croppedImage}
                foregroundImageProps={{
                  x: positionInformation.start.x,
                  y: positionInformation.start.y,
                  height: positionInformation.croppedHeight,
                  width: positionInformation.croppedWidth
                }}
              />
            )}
          </ZoomableViewWrapper>
        )
      }
      dialogFooterColumn={smallScreen}
      dialogFooter={() => (
        <React.Fragment>
          {!props.hideInformation && (
            <div className={clsx(classes.nftInfo)}>
              <Typography className={clsx(classes.typography)} variant="h6" component="h6" color="textPrimary" noWrap>
                {artist}
              </Typography>
              <Typography
                className={clsx(classes.typography)}
                variant="subtitle1"
                component="subtitle1"
                color="textSecondary"
                noWrap
                gutterBottom={props.showCompletionStatus}
              >
                {description}
              </Typography>
              <Typography
                className={clsx(classes.typography)}
                variant="subtitle2"
                component="subtitle2"
                color="textSecondary"
                noWrap
                gutterBottom={props.showCompletionStatus}
              >
                Token #{tokenId}
              </Typography>
              {props.showCompletionStatus && (
                <Typography
                  className={clsx(classes.typography)}
                  variant="subtitle2"
                  component="subtitle2"
                  color="textSecondary"
                  noWrap
                >
                  {completionStatus}% filled
                </Typography>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    />
  )
}
