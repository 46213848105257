import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import InfoGrid from '../../../components/common/InfoGrid'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },

  body: {
    fontSize: 16
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  typographyBold: {
    fontFamily: 'Roboto',
    fontWeight: 500
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  }
}))

export default function LeaderboardCard ({ title, items, onItemClicked }) {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, classes.flexColumn)}
      style={{
        paddingTop: '24px',
        justifyContent: 'center',
        alignContent: 'flex-start',
        alignItems: 'center'
      }}
    >
      <div
        className={clsx(classes.flexColumn)}
        style={{
          alignContent: 'flex-start',
          padding: '24px'
        }}
      >
        <Typography
          className={classes.typography}
          variant='h4'
          component='p'
          align='center'
          style={{
            marginBottom: '16px'
          }}
        >
          {title}
        </Typography>
        <InfoGrid dividers contents={items} onItemClicked={onItemClicked} />
      </div>
    </div>
  )
}
