import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import InfoGrid from '../../../components/common/InfoGrid'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },

  body: {
    fontSize: 16
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  typographyBold: {
    fontFamily: 'Roboto',
    fontWeight: 500
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  }
}))

export default function PaintStatsInfoCard ({ contents }) {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, classes.flexColumn)}
      style={{
        paddingTop: '24px',
        justifyContent: 'center',
        alignContent: 'flex-start',
        alignItems: 'center'
      }}
    >
      <div
        className={clsx(classes.flexColumn)}
        style={{
          alignContent: 'flex-start',
          padding: '24px'
        }}
      >
        <InfoGrid dividers contents={contents} />

        <div
          style={{
            paddingTop: '12px',
            display: 'flex',
            flexDirection: 'row',
            flex: 'auto',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <Typography
            className={clsx(classes.typographyLight)}
            align='center'
            variant='h6'
            component='p'
          >
            More stats on MurAll available on{' '}
            <Link
              component={'a'}
              target='_blank'
              href={'https://duneanalytics.com/pillieshwar/murall'}
              color='secondary'
            >
              Dune Analytics
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  )
}
