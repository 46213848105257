import { useEffect, useState } from 'react'
import { SupportedChain } from '../lib/constants'
import { useEthersProviderForChainId } from './use-ethers-provider-for-chain-id'

export const useEns = (account) => {
  const ethersProvider = useEthersProviderForChainId(SupportedChain.Ethereum)
  const [ensName, setEnsName] = useState(null)

  useEffect(() => {
    setEnsName(null)
    if (!account) return

    let isCanceled = false // Flag to keep track of whether the effect has been cleaned up

    ethersProvider.lookupAddress(account).then((name) => {
      if (!isCanceled) {
        // Only proceed if the effect hasn't been cleaned up
        console.log('ENS name found:', name)
        setEnsName(name)
      }
    })

    return () => {
      isCanceled = true // Set the flag to true on cleanup
    }
  }, [account, ethersProvider])

  return ensName
}
