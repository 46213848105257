import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import StyledImageViewerDialog from '../../js/uicomponents/styled_image_viewer_dialog'
import { Typography, Button, Grid, Box } from '@mui/material'

const useStyles = makeStyles(theme => ({
  typography: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  }
}))

export default function PromoDialog ({
  open,
  onClose,
  onPositiveButtonClick,
  maxWidth = 'sm'
}) {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <StyledImageViewerDialog
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      withCloseButton
      dialogImageContent={
        <Grid
          container
          spacing={2}
          direction='column'
          justifyContent='center'
          alignItems='stretch'
          alignContent='center'
        >
          <Grid
            container
            item
            xs={12}
            direction='column'
            justifyContent='center'
            alignItems='center'
            alignContent='center'
          >
            <Grid item xs>
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                  margin: 'auto',
                  aspectRatio: '1/1',
                  objectFit: 'contain',
                  overflow: 'hidden'
                }}
                src={'images/AI.png'}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box align='center'>
              <Typography
                className={clsx(classes.typographyLight)}
                variant={isMobile ? 'h5' : 'h4'}
                component={isMobile ? 'h5' : 'h4'}
                color='textPrimary'
                align='center'
              >
                Introducing AI-Powered Creativity: Transform Your Words into Art
                on MurAll
                <br />
                <br />
              </Typography>

              <Typography
                className={clsx(classes.typographyLight)}
                variant={isMobile ? 'body1' : 'h6'}
                component={isMobile ? 'body1' : 'h6'}
                color='textPrimary'
                align='center'
              >
                Welcome to a groundbreaking new era of artistic expression on
                MurAll!
                <br />
                We're excited to announce the open beta of our latest feature:
                AI-powered text to image generation!
                <br />
                <br />
                Unleash your imagination and transform your words into vibrant,
                unique artworks on our collaborative digital canvas. Experience
                true freedom of speech through art, as our cutting-edge AI
                technology brings your stories and ideas to life, pixel by
                pixel, and immortalize your creativity on the blockchain today
                through MurAll.
                <br />
                <br />
                Be part of the revolution, and remember - once the PAINT runs
                out, the canvas closes forever. Don't miss your chance to make
                history!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      }
      dialogActions={
        <>
          <Button
            variant={'contained'}
            color='secondary'
            onClick={() => {
              onPositiveButtonClick && onPositiveButtonClick()
            }}
            style={{ marginRight: '16px', width: '100%' }}
          >
            Try it out
          </Button>
        </>
      }
    />
  )
}
