import React, { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

import ErrorView from './dead'
import {
  FALLBACK_PROVIDER_NAME,
  FALLBACKL2_PROVIDER_NAME
} from '../../lib/constants'
import { useEagerConnect, useInactiveListener } from '../../hooks/web3'
import { fallback, fallbackL2 } from '../../lib/connectors'

const Web3ReactManager = ({ children }) => {
  const { active } = useWeb3React()

  const {
    active: fallbackActive,
    error: fallbackError,
    activate: activateFallback
  } = useWeb3React(FALLBACK_PROVIDER_NAME)

  const {
    active: fallbackL2Active,
    error: fallbackL2Error,
    activate: activateFallbackL2
  } = useWeb3React(FALLBACKL2_PROVIDER_NAME)

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // after eagerly trying injected and it's not active,
  // try to activate the fallback connection if it isn't yet active or in an error state
  useEffect(() => {
    if (triedEager && !fallbackActive && !fallbackError && !active) {
      activateFallback(fallback)
    }
  }, [triedEager, fallbackActive, fallbackError, activateFallback, active])
  useEffect(() => {
    if (triedEager && !fallbackL2Active && !fallbackL2Error && !active) {
      activateFallbackL2(fallbackL2)
    }
  }, [
    triedEager,
    fallbackL2Active,
    fallbackL2Error,
    activateFallbackL2,
    active
  ])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager)

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (triedEager && !active && fallbackError && fallbackL2Error)
    return <ErrorView />

  return children
}

export default Web3ReactManager
