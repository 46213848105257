import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import LoadingNftCard from './loadingPlaceholderNftCard'
import Button from '@mui/material/Button'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Carousel from '../../../components/common/carousel'

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => WrappedComponent => props => (
  <WrappedComponent {...props} width='xs' />
)
const LOADING_ID = 'loading'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
    flexGrow: 1
  },
  gridList: {
    flex: 'none',
    height: 'auto',
    width: '100%'
  },
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  flexRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const CardScrollableList = props => {
  const classes = useStyles()

  const [loadingPlaceholder, setLoadingPlaceholder] = useState(
    Array(props.placeholderAmount).fill(LOADING_ID)
  )

  useEffect(() => {
    !props.isLoadingMore &&
      props.data.length < props.maxListSize &&
      props.onLoadMore &&
      props.onLoadMore()
  }, [])

  return (
    <React.Fragment>
      <div className={clsx(classes.flexRow)} style={{ marginBottom: '24px' }}>
        <Typography
          component='h5'
          variant='h5'
          noWrap
          className={clsx(classes.typography)}
          color='textPrimary'
        >
          {props.title}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          endIcon={<ChevronRightIcon />}
          onClick={props.onSeeAllClicked}
        >
          See All Items
        </Button>
      </div>
      <Carousel
        className={classes.gridList}
        mousewheelEnabled={true}
        contents={
          props.data.length < props.maxListSize
            ? props.data.concat(loadingPlaceholder)
            : props.data
        }
        freeMode={true}
        onCreateContent={nft => {
          return nft !== LOADING_ID ? (
            props.constructGridItemView(nft)
          ) : (
            <LoadingNftCard />
          )
        }}
        onSlideVisible={(index, isVisible) => {
          if (
            !props.isLoadingMore &&
            isVisible &&
            index >= props.data.length &&
            props.data.length < props.maxListSize
          ) {
            props.onLoadMore && props.onLoadMore()
          }
        }}
        breakpoints={{
          '@0.25': {
            slidesPerView: 1.25,
            spaceBetween: 24
          },
          '@0.5': {
            slidesPerView: 1.65,
            spaceBetween: 24
          },
          '@0.75': {
            slidesPerView: 2.75,
            spaceBetween: 24
          },
          '@1.00': {
            slidesPerView: 3.5,
            spaceBetween: 24
          },
          '@1.25': {
            slidesPerView: 4.25,
            spaceBetween: 70
          },
          '@1.50': {
            slidesPerView: 5.55,
            spaceBetween: 24
          },
          '@1.75': {
            slidesPerView: 6.55,
            spaceBetween: 24
          },
          '@2': {
            slidesPerView: 7.55,
            spaceBetween: 24
          }
        }}
      ></Carousel>
    </React.Fragment>
  )
}
export default withWidth()(CardScrollableList)
