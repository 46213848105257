import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { useGetCurrentStateQuery } from '../state/api'
import clsx from 'clsx'
import Loader from '../components/common/loader'
import FallbackImage from '../../images/logos/murall/l1.svg'
import FallbackImageL2 from '../../images/logos/murall/l2.svg'
import FallbackImageEvolv3 from '../../images/logos/murall/evolv3.svg'
import configJson from '../../config/data_landing.json'
import KenBurnsView from '../components/common/images/ken-burns'
import Footer from '../components/common/footer'
import { MURALL_WALL, MURALL_WALL_ID, SupportedChain } from '../lib/constants'
import layer1Theme from '../theme/layer1'
import layer2Theme from '../theme/layer2'
import evolv3Theme from '../theme/evolv3'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedNetwork } from '../state/slices/networkSwitch'
import { setHidePromo } from '../state/slices/promo'
import { ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import switchToNetwork from '../lib/actions/switch-network'
import { useActiveWeb3React } from '../hooks/web3'
import AboutContents from '../components/common/about-contents'
import ScrollDownFab from '../components/common/buttons/scroll-down'
import NetworkButton from '../components/common/landing/network-button'
import { useApi } from '../hooks/use-api'
import PromoDialog from '../components/common/promo_dialog'
import { setWall } from '../state/slices/wall'
import HeroTabSwitcher from '../components/common/landing/hero-tab-switcher'
import { Box } from '@mui/material'
import { useMurAllStateS3DataSourceForWall } from '../hooks/use-murall-s3-state-datasource-for-wall'

const useStyles = makeStyles((theme) => ({
  kenBurnsImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'filter 0.3s'
  },
  unsaturate: {
    filter: 'saturate(0) grayscale(1) brightness(50%)'
  },
  halfSaturation: {
    filter: 'saturate(0.5)'
  },
  fullSaturation: {
    filter: 'saturate(1)'
  }
}))

const wallIdForSectionId = (sectionId) => {
  switch (sectionId) {
    case 1:
      return MURALL_WALL_ID.LAYER_1

    case 2:
      return MURALL_WALL_ID.LAYER_2

    case 3:
      return MURALL_WALL_ID.EVOLV3
  }
}

const LandingPage = () => {
  const classes = useStyles()

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const detailsRef = useRef(null)
  const { library } = useActiveWeb3React()
  const [cookies] = useCookies(['agreedToTerms'])
  const [selectedSection, setSelectedSection] = useState(3)
  const [canFade, setCanFade] = useState(null)
  const promo = useSelector((state) => state.promo)
  const [promoOpen, setPromoOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const terms = useSelector((state) => state.terms)

  const goToLiveWithWallInfo = (wallInfo) => {
    switchToNetwork({ library, chainId: wallInfo.chainId })
    dispatch(setSelectedNetwork(wallInfo.chainId))
    dispatch(setWall(wallInfo.id))
    history.push('/live')
  }
  const setSelectedWallId = (id) => {
    dispatch(setWall(id))
  }

  useEffect(() => {
    setSelectedWallId(wallIdForSectionId(selectedSection))
    setCanFade(canFade === null ? false : true)
  }, [selectedSection])

  const agreedToTerms = !!cookies.agreedToTerms || terms.agreed

  const { evolv3Image, l1Image, l2Image, loading } = useWallStates(agreedToTerms)

  const highlightedWallId = wallIdForSectionId(selectedSection)
  const { highlightedWallImage, highlightedWallTheme } =
    highlightedWallId === MURALL_WALL_ID.EVOLV3
      ? { highlightedWallImage: evolv3Image, highlightedWallTheme: evolv3Theme }
      : highlightedWallId === MURALL_WALL_ID.LAYER_2
      ? { highlightedWallImage: l2Image, highlightedWallTheme: layer2Theme }
      : { highlightedWallImage: l1Image, highlightedWallTheme: layer1Theme }

  return (
    <>
      <PromoDialog
        open={promoOpen}
        onClose={() => {
          setPromoOpen(false)
          dispatch(setHidePromo(true))
        }}
        onPositiveButtonClick={() => {
          setPromoOpen(false)
          dispatch(setHidePromo(true))
          history.push('draw?generateWithAI=true')
        }}
      />

      <HeroTabSwitcher
        title={'See MurAll LIVE'}
        subtitle={'Pick a wall to view'}
        tabDirection="row"
        selectedSection={selectedSection}
        onSectionHoverChange={(sectionId) => {
          if (sectionId !== selectedSection) {
            setSelectedSection(sectionId)
          }
        }}
        tabContainerStyle={{
          ...(canFade && {
            backgroundColor: 'rgba(0,0,0,0.5)',
            transition: 'background-color 0.5s ease-in-out'
          })
        }}
        tab3={
          <TabContent
            goToLiveWithWallInfo={goToLiveWithWallInfo}
            sectionId={3}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            theme={evolv3Theme}
            wallInfo={MURALL_WALL[MURALL_WALL_ID.EVOLV3]}
          />
        }
        tab2={
          <TabContent
            goToLiveWithWallInfo={goToLiveWithWallInfo}
            sectionId={2}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            theme={layer2Theme}
            wallInfo={MURALL_WALL[MURALL_WALL_ID.LAYER_2]}
          />
        }
        tab1={
          <TabContent
            goToLiveWithWallInfo={goToLiveWithWallInfo}
            sectionId={1}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            theme={layer1Theme}
            wallInfo={MURALL_WALL[MURALL_WALL_ID.LAYER_1]}
          />
        }
      >
        <Section
          classes={classes}
          loading={loading}
          isMobile={isMobile}
          image={highlightedWallImage}
          theme={highlightedWallTheme}
        />
      </HeroTabSwitcher>

      <ScrollDownFab
        onClick={() => {
          detailsRef.current &&
            detailsRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })
        }}
      />

      <AboutContents detailsRef={detailsRef} />

      <Footer />
    </>
  )
}

const TabContent = ({
  disabled = false,
  setSelectedSection,
  goToLiveWithWallInfo,
  selectedSection,
  theme,
  sectionId,
  wallInfo
}) => {
  return (
    <ThemeProvider theme={theme}>
      <NetworkButton
        sx={{
          zIndex: 6
        }}
        disabled={disabled}
        wallInfo={wallInfo}
        hover={selectedSection === sectionId}
        onClick={() => {
          if (disabled) return
          if (selectedSection === sectionId) {
            goToLiveWithWallInfo(wallInfo)
          } else {
            setSelectedSection(sectionId)
          }
        }}
      />
    </ThemeProvider>
  )
}

const useWallStates = (agreedToTerms) => {
  const [loading, setLoading] = useState(false)
  const [stateL1, setStateL1] = useState(false)
  const [stateL2, setStateL2] = useState(false)
  const [stateEvolv3, setStateEvolv3] = useState(false)
  // Ref to track the previous state of datasources
  const prevDatasourcesRef = useRef()
  const LAYERS_CONFIG = {
    LAYER_1: {
      id: MURALL_WALL_ID.LAYER_1,
      chainId: SupportedChain.Ethereum,
      fallbackImage: FallbackImage,
      safeState: configJson.safeStates.L1
    },
    LAYER_2: {
      id: MURALL_WALL_ID.LAYER_2,
      chainId: SupportedChain.Polygon,
      fallbackImage: FallbackImageL2,
      safeState: configJson.safeStates.L2
    },
    EVOLV3: {
      id: MURALL_WALL_ID.EVOLV3,
      chainId: SupportedChain.Polygon,
      fallbackImage: FallbackImageEvolv3,
      safeState: configJson.safeStates.L3
    }
  }

  // Fetching states
  const layerStates = {}
  const layerData = {}
  const layerErrors = {}
  const layerLoadings = {}

  Object.entries(LAYERS_CONFIG).forEach(([key, config]) => {
    const datasource = useMurAllStateS3DataSourceForWall(config.id)
    const getCurrentMurAllStateData = datasource?.getCurrentMurAllStateData || null
    layerStates[key] = getCurrentMurAllStateData

    const { data, error, isFetching } = useGetCurrentStateQuery(config.id, {
      skip: !agreedToTerms
    })

    layerData[key] = data
    layerErrors[key] = error
    layerLoadings[key] = isFetching
  })

  // Fetching data in useEffect
  useEffect(() => {
    const fetchData = async () => {
      // Only create promises for layers that have a getCurrentState function.
      const promises = Object.entries(layerStates)
        .filter(([key, getCurrentState]) => !!getCurrentState)
        .map(([key, getCurrentState]) =>
          getCurrentState(true, true)
            .then((data) => data.fullBase64PngString)
            .catch((error) => {
              console.error('error', error)
              return null
            })
        )

      // Destructure results based on the layers that had a valid getCurrentState function.
      const results = await Promise.all(promises)

      if (layerStates.LAYER_1) setStateL1(results.shift())
      if (layerStates.LAYER_2) setStateL2(results.shift())
      if (layerStates.EVOLV3) setStateEvolv3(results.shift())

      setLoading(false)
    }

    // Check if any datasource has changed
    const hasDatasourceChanged = Object.keys(LAYERS_CONFIG).some((key) => {
      return (prevDatasourcesRef.current && prevDatasourcesRef.current[key]) !== layerStates[key]
    })

    // Update the ref with the current datasources
    prevDatasourcesRef.current = { ...layerStates }

    // Only fetch if a datasource has changed or if it's the first run
    if (hasDatasourceChanged || !prevDatasourcesRef.current) {
      fetchData()
    }
  }, [layerStates, agreedToTerms])

  // Generating URLs
  const getImageUrl = (layerKey, state) => {
    const config = LAYERS_CONFIG[layerKey]
    const api = useApi(config.chainId, config.id)
    return agreedToTerms
      ? state
        ? state
        : layerErrors[layerKey] || !layerData[layerKey]
        ? config.fallbackImage
        : layerData[layerKey].url
      : api
      ? api.s3.history(config.safeState)
      : config.fallbackImage
  }

  const l1Image = getImageUrl('LAYER_1', stateL1)
  const l2Image = getImageUrl('LAYER_2', stateL2)
  const evolv3Image = getImageUrl('EVOLV3', stateEvolv3)

  return {
    loading,
    l1Image,
    l2Image,
    evolv3Image
  }
}
const Section = ({ classes, loading, image, theme, animationVariant, isMobile, children }) => {
  return (
    <Box
      id="landing"
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%'
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <KenBurnsView animationVariant={animationVariant} className={clsx(classes.kenBurnsImage)}>
          <img src={image} className={clsx(classes.kenBurnsImage)} />
        </KenBurnsView>
      )}
      <DimOverlay
        style={{
          backgroundImage: `linear-gradient(to ${isMobile ? 'bottom' : 'right'}, ${
            theme.palette.primary.main
          }00 55%,  ${theme.palette.primary.main}80)`
        }}
      />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Box>
  )
}

const DimOverlay = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  mix-blend-mode: normal;
`

export default LandingPage
