import { useState, useEffect, useCallback } from 'react'
import { useApi } from '../use-api'
import { mapInformationToNftObject } from '../../js/modules/blockchain/NftDataMapper'
import { CANVAS_HEIGHT, CANVAS_WIDTH, MURALL_WALL } from '../../lib/constants'
import useGetStateChangesForIdRange from './use-get-state-changes-for-id-range'

export const useHistoryForTokenRange = (
  wallId,
  rangeStart,
  rangeEnd,
  immediate = true
) => {
  const wallInfo = MURALL_WALL[wallId]
  const api = useApi(wallInfo.chainId, wallId)
  const [sanitisedRangeStart, setSanitisedRangeStart] = useState(null)
  const [tokenIds, setTokenIds] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [baseState, setBaseState] = useState(null)
  const [stateChanges, setStateChanges] = useState(null)

  const {
    apply: getStateChanges,
    error: stateChangesError
  } = useGetStateChangesForIdRange(wallId, rangeStart, rangeEnd, false)

  useEffect(() => {
    if (rangeStart !== null && rangeStart !== undefined) {
      const sanitisedValue = rangeStart > 0 ? parseInt(rangeStart) - 1 : 0
      setSanitisedRangeStart(sanitisedValue)

      const startValue = parseInt(rangeStart)
      let endValue =
        rangeEnd && rangeEnd >= startValue ? parseInt(rangeEnd) : startValue

      let tokens = [...Array(endValue - startValue + 1).keys()].map(
        i => i + startValue
      )

      setTokenIds(tokens)
    } else {
      setSanitisedRangeStart(null)
    }
  }, [rangeStart, rangeEnd])

  const apply = useCallback(async () => {
    try {
      if (sanitisedRangeStart !== null) {
        setLoading(true)
        setError(null)
        // construct base state
        const stateUrl = api.s3.history(sanitisedRangeStart)
        const constructedBaseState = mapInformationToNftObject({
          contractAddress: wallInfo.nftContractAddress,
          tokenId: sanitisedRangeStart,
          chainId: wallInfo.chainId,
          image: stateUrl,
          positionInformation: {
            start: { x: 0, y: 0 },
            end: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
            width: CANVAS_WIDTH,
            height: CANVAS_HEIGHT
          },
          additionalInformation: {
            fullBase64PngString: stateUrl,
            croppedBase64PngString: stateUrl,
            name: '',
            number: 0,
            seriesId: 0,
            hasAlpha: false,
            colorIndex: [],
            artist: ''
          }
        })

        setBaseState(constructedBaseState)
        const stateData = await getStateChanges()
        if (!stateData) {
          setError('No state data')
          setLoading(false)
          return
        }
        setStateChanges(stateData)
        setLoading(false)
      } else {
        setError('Range start not set.')
      }
    } catch (error) {
      console.error(error)
      setError(error)
      setLoading(false)
      return
    }
  }, [sanitisedRangeStart, api, tokenIds])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])

  return {
    apply,
    loading,
    baseState,
    stateChanges,
    error: error || stateChangesError
  }
}

export default useHistoryForTokenRange
