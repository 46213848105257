import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import CustomAccordion from '../../../components/common/Accordion'
import SectionTitle from '../SectionTitle'

const SectionMurAllEvolv3 = () => {
  return (
    <>
      <SectionTitle variant='h4' component='h4'>
        MurAll Evolv3
      </SectionTitle>
      <CustomAccordion
        items={[
          {
            title: 'Does MurAll Evolv3 be support both image and video?',
            content: (
              <>
                Yes, Evolv3 supports both image and video. Video is supported in
                .mp4 and .webm formats, including .webm with transparency.
              </>
            )
          },
          {
            title:
              'How many times in total can the whole MurAll Evolv3 be drawn over?',
            content: (
              <>
                This depends on the Paint usage as governed by the community. At
                present, 0.0005 Paint per pixel is used per image, meaning
                Evolv3 can be drawn over no more than 11,010,048 times in its
                entirety, assuming only images are drawn. It is worth noting
                video is also supported, which use 0.0005 Paint per pixel per
                second of video.
              </>
            )
          },
          {
            title: 'Is minting an on MurAll Evolv3 gasless?',
            content: (
              <>
                Yes. Minting on Evolv3 is gasless, meaning you do not need to
                pay network fees for minting. Only your Paint token is used in
                the minting process. Simply sign the message with your wallet
                and the minting will be processed.
              </>
            )
          },
          {
            title: 'Where is the image/video data hosted?',
            content: <>All image/video data is stored on ipfs.</>
          },
          {
            title: 'What colour space does MurAll Evolv3 use?',
            content: (
              <>Images and video on Evolv3 can use full RGB colour space.</>
            )
          },
          {
            title: 'What does MurAll Evolv3 support image transparency?',
            content: <>Yes.</>
          },
          {
            title: 'What does MurAll Evolv3 support video transparency?',
            content: <>Yes, with .webm format video</>
          },
          {
            title: 'What files are supported on MurAll Evolv3?',
            content: (
              <>
                Evolv3 supports all standard image formats (e.g. png/jpeg etc),
                plus .mp4 and .webm video formats (including .webm with
                transparency).
              </>
            )
          },

          {
            title:
              'What are the terms and conditions of using this website to interact with MurAll?',
            content: (
              <>
                You can find the terms and conditions{' '}
                <Link
                  component={RouterLink}
                  to='/terms'
                  color='secondary'
                  underline='hover'
                >
                  here
                </Link>
              </>
            )
          },
          {
            title: 'What are the contract addresses for MurAll Evolv3?',
            content: (
              <>
                <br />
                Evolv3 (Polygon/Matic) addresses:
                <br />
                <br />
                Paint (PAINT) Token (ERC-20):{' '}
                <Link
                  href='https://polygonscan.com/token/0x7c28f627ea3aec8b882b51eb1935f66e5b875714'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x7c28F627eA3aEc8B882b51eb1935f66e5b875714
                </Link>
                <br />
                <br />
                Evolv3 NFT (ERC-721):{' '}
                <Link
                  href='https://polygonscan.com/token/0x962f182c8E49fC2D9E4385202bDBe5F7A8578415'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  0x962f182c8E49fC2D9E4385202bDBe5F7A8578415
                </Link>
              </>
            )
          }
        ]}
      />
    </>
  )
}

export default SectionMurAllEvolv3
