import { useState, useEffect, useCallback } from 'react'
import { useApi } from '../use-api.js'
import { MURALL_WALL, MURALL_WALL_ID } from '../../lib/constants.js'
import { useMurAllEvolv3NftDataSource } from '../use-murall-evolv3-nft-datasource.js'

export const useGetMurAllNftForId = (
  wallId,
  tokenId,
  cached = false,
  immediate = true
) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [nftData, setNftData] = useState(false)
  const wallInfo = MURALL_WALL[wallId]
  const { getTokenMetadataForTokenId } = useMurAllEvolv3NftDataSource()
  const api = useApi(wallInfo.chainId, wallId)

  const apply = useCallback(async () => {
    if (!tokenId) return
    try {
      setLoading(true)
      let tokenInfo
      if (wallId === MURALL_WALL_ID.EVOLV3) {
        if (cached) {
          const res = await fetch(
            cached ? api.tokens.cachedToken(tokenId) : api.tokens.token(tokenId)
          )
          tokenInfo = await res.json()
        } else {
          tokenInfo = await getTokenMetadataForTokenId(tokenId)
        }
      } else {
        const res = await fetch(
          cached ? api.tokens.cachedToken(tokenId) : api.tokens.token(tokenId)
        )
        tokenInfo = await res.json()
        console.log(tokenInfo)
      }
      setNftData(tokenInfo)
      setLoading(false)
      return tokenInfo
    } catch (error) {
      console.error(error)
      setError(error)
      setLoading(false)
      return
    }
  }, [api, wallId, tokenId, getTokenMetadataForTokenId, cached])

  useEffect(() => {
    if (immediate) {
      apply()
    }
  }, [apply, immediate])
  return { apply, nftData, loading, error }
}

export default useGetMurAllNftForId
