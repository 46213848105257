import React from 'react'
import { useActiveWeb3React } from '../../hooks/web3'
import { shortenAddress } from '../../lib/web3/utils'
import { Typography, CardHeader } from '@mui/material'
import InfoGrid from '../../components/common/InfoGrid'
import useMediaQuery from '@mui/material/useMediaQuery'
import Davatar from '@davatar/react'
import CustomAccordion from '../../components/common/Accordion'
import InfoIcon from '@mui/icons-material/Info'

const InfoPlaque = ({
  artistAddress,
  artistEns,
  burnedPaint,
  pixelsDrawn,
  nftsCreated,
  nftsCreatedOnEth,
  nftsCreatedOnPolygon,
  nftsCreatedOnEvolv3
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const charsToShowAtEnd = 4

  const { library } = useActiveWeb3React()

  return (
    <CustomAccordion
      icon={<InfoIcon />}
      titleStyle={{
        padding: '0px'
      }}
      accordionStyle={{
        width: 'auto',
        background: 'transparent',
        border: 'none'
      }}
      accordionSummaryStyle={{
        flexDirection: 'row'
      }}
      rotateIcon={false}
      items={[
        {
          title: (
            <>
              <CardHeader
                avatar={
                  <Davatar
                    address={artistAddress}
                    size={isMobile ? 48 : 48}
                    provider={library?.provider}
                  />
                }
                title={
                  <Typography
                    sx={{
                      width: '100%',
                      fontFamily: 'Roboto',
                      fontWeight: 300,
                      fontStyle: 'normal',
                      textOverflow: 'ellipsis',
                      padding: '0px'
                    }}
                    noWrap
                    color={'textPrimary'}
                    align={'left'}
                    variant={isMobile ? 'h6' : 'h5'}
                  >
                    {artistEns
                      ? artistEns
                      : isMobile
                      ? shortenAddress(artistAddress, charsToShowAtEnd)
                      : artistAddress}
                  </Typography>
                }
                subheader={
                  <Typography
                    noWrap
                    align='left'
                    variant={isMobile ? 'subtitle2' : 'subtitle1'}
                    color='text.secondary'
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: 300
                    }}
                  >
                    {!artistEns
                      ? ''
                      : isMobile
                      ? shortenAddress(artistAddress, charsToShowAtEnd)
                      : artistAddress}
                  </Typography>
                }
                style={{ padding: '12px' }}
              />
            </>
          ),
          content: (
            <InfoGrid
              dividers
              contents={{
                'Total Artworks 🖼': nftsCreated,
                'Artworks on Layer1 (Ethereum) 🖼': nftsCreatedOnEth,
                'Artworks on Layer2 (Polygon) 🖼': nftsCreatedOnPolygon,
                'Artworks on Evolv3 (Polygon) 🖼': nftsCreatedOnEvolv3,
                'Pixels Drawn 🖌': pixelsDrawn,
                'Paint Burned 🔥': burnedPaint
              }}
            />
          )
        }
      ]}
    />
  )
}

export default InfoPlaque
