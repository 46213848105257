import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import clsx from 'clsx'

export default function SvgDraggableImage ({
  image,
  x,
  y,
  width,
  height,
  animationDuration,
  onPositionUpdated,
  disableTouch,
  filter
}) {
  const classes = useStyles({
    animationDuration,
    x,
    y,
    width,
    height
  })

  const [position, setPosition] = useState({
    x: x,
    y: y,
    active: false,
    offset: {}
  })

  useEffect(() => {
    if (image) {
      setPosition({
        x: x,
        y: y,
        active: false,
        offset: {}
      })
    }
  }, [image])

  const handleTouchStart = e => {
    const touch = e.touches[0]
    // e.preventDefault()
    handleStart(touch)
  }

  const handleTouchMove = e => {
    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0]
      // e.preventDefault()
      handleMove(touch)
    }
  }

  const handlePointerDown = e => {
    e.preventDefault()
    const el = e.target
    el.setPointerCapture(e.pointerId)
    handleStart(e)
  }

  const handlePointerMove = e => {
    e.preventDefault()
    handleMove(e)
  }

  const handleStart = e => {
    const bbox = e.target.getBoundingClientRect()
    const x = e.clientX - bbox.left
    const y = e.clientY - bbox.top
    setPosition({
      ...position,
      active: true,
      offset: {
        x,
        y
      }
    })
  }
  const handleMove = e => {
    if (position.active) {
      const bbox = e.target.getBoundingClientRect()
      const x = e.clientX - bbox.left
      const y = e.clientY - bbox.top
      setPosition({
        ...position,
        x: position.x - (position.offset.x - x),
        y: position.y - (position.offset.y - y)
      })
      onPositionUpdated &&
        onPositionUpdated(
          position.x - (position.offset.x - x),
          position.y - (position.offset.y - y)
        )
    }
  }
  const handleEnd = e => {
    setPosition({
      ...position,
      active: false
    })
  }

  return (
    <image
      className={clsx(
        // 'pixelated-image',
        classes.show,
        {
          [classes.notClickable]: disableTouch
        }
      )}
      x={x}
      y={y}
      height={height}
      width={width}
      href={image}
      onPointerDown={!disableTouch && handlePointerDown}
      onPointerMove={!disableTouch && handlePointerMove}
      onPointerUp={!disableTouch && handleEnd}
      onTouchStart={!disableTouch && handleTouchStart}
      onTouchMove={!disableTouch && handleTouchMove}
      onTouchCancel={!disableTouch && handleEnd}
      onTouchEnd={!disableTouch && handleEnd}
      style={{
        filter: filter
      }}
    />
  )
}
