import React from 'react'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'

const SectionTitle = styled(Typography)`
  font-family: Roboto;
  font-weight: 300;
  margin: ${({ theme }) => theme.spacing(4)} 0;
`

export default SectionTitle
