import React, { useState } from 'react'
import _ from 'underscore'
import makeStyles from '@mui/styles/makeStyles'
import Divider from '@mui/material/Divider'
import CardScrollableList from '../../../js/pages/nfts/cardScrollableList'

const useStyles = makeStyles(theme => ({
  gridList: {
    width: '100%',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  }
}))

const LOAD_ITEM_LIMIT = 4

export default function NftLoadingListView ({
  title,
  balance,
  onLoadMore,
  onSeeAllClicked,
  constructItemView,
  divider,
  itemLoadLimit
}) {
  const [isFetchingNfts, setIsFetchingNfts] = useState(false)

  const [nfts, setNfts] = useState([])

  const classes = useStyles()

  const loadLimit = itemLoadLimit || LOAD_ITEM_LIMIT

  const constructGridList = () => {
    return (
      <>
        <CardScrollableList
          className={classes.gridList}
          data={nfts}
          title={title}
          placeholderAmount={loadLimit}
          isLoadingMore={isFetchingNfts}
          onLoadMore={async () => {
            if (!isFetchingNfts) {
              setIsFetchingNfts(true)
              const startIndex = nfts.length
              if (startIndex > balance - 1) {
                setIsFetchingNfts(false)
                return
              }
              const endIndex =
                startIndex + (loadLimit - 1) >= balance - 1
                  ? balance - 1
                  : startIndex + (loadLimit - 1)
              const nftsForRange = await onLoadMore(startIndex, endIndex)
              setNfts(_.union(nfts, nftsForRange))
              setIsFetchingNfts(false)
            }
          }}
          constructGridItemView={constructItemView}
          onSeeAllClicked={onSeeAllClicked}
          maxListSize={balance}
        />

        {divider && (
          <Divider
            variant='middle'
            style={{ marginTop: '24px', marginBottom: '24px' }}
          />
        )}
      </>
    )
  }

  return balance != 0 && constructGridList()
}
