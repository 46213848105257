import React, { useEffect, useRef, useState } from 'react'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  DUMMY_PREVIEW_DATA
} from '../../../lib/constants'
import SvgHistoryState from './svg-history-state'
import { gsap } from 'gsap'
import { startLocateAnimation, startPixellateAnimation } from './animations'
import Get_image_for_layer_usecase from '../../../js/modules/blockchain/usecase/get_image_for_layer_usecase'
import SvgHistoryVideoState from './svg-history-video-state'
import { Box } from '@mui/material'
import { LoadingSpinner } from '../../../js/uicomponents/loading_spinner'

const LOCATE_ANIMATION_DURATION_MILLIS = 800
export default function SvgLayerPreviewViewer ({
  style,
  layer,
  baseLayer,
  useGlitchEffect = true,
  animationDuration = '0.5s',
  locateAnimationDuration = LOCATE_ANIMATION_DURATION_MILLIS
}) {
  const timeline = gsap.timeline()

  const getImageForLayerUsecase = new Get_image_for_layer_usecase()

  const [layerPreview, setLayerPreview] = useState(DUMMY_PREVIEW_DATA)
  const [baseLayerPreview, setBaseLayerPreview] = useState(DUMMY_PREVIEW_DATA)
  const [loadingPreview, setLoadingPreview] = useState(false)

  useEffect(() => {
    async function fetchBasePreview () {
      setLoadingPreview(true)
      const newBaseLayerPreview = await getImageForLayerUsecase.execute(
        baseLayer
      )
      setBaseLayerPreview(newBaseLayerPreview)
      setLoadingPreview(false)
    }
    if (!baseLayer) {
      return
    }
    fetchBasePreview()
  }, [baseLayer])

  useEffect(() => {
    async function fetchLayerPreview () {
      setLoadingPreview(true)
      const newLayerPreview = await getImageForLayerUsecase.execute(layer)
      setLayerPreview(newLayerPreview)
      setLoadingPreview(false)
    }
    if (!layer) {
      return
    }
    if (layer.type === 'image') {
      fetchLayerPreview()
    }
  }, [layer])

  const blurRef = useRef(null)
  const fadeRRef = useRef(null)
  const fadeGRef = useRef(null)
  const fadeBRef = useRef(null)
  const videoRef = useRef(null)

  const pixellateGlitchFilter = useRef(null)
  const currentPixellateGlitchAnim = useRef(null)
  const currentLocateAnim = useRef(null)

  const [isLocating, setLocating] = useState(false)
  const [isGlitchAnimating, setGlitchAnimating] = useState(false)

  useEffect(() => {
    if (layerPreview !== DUMMY_PREVIEW_DATA || layer.type === 'video') {
      setLocating(true)
      startLocateAnimation(
        timeline,
        LOCATE_ANIMATION_DURATION_MILLIS,
        blurRef,
        fadeRRef,
        fadeGRef,
        fadeBRef,
        currentLocateAnim,
        () => {
          setLocating(false)
        },
        true
      )
    }
  }, [layerPreview])

  useEffect(() => {
    if (useGlitchEffect && layerPreview !== DUMMY_PREVIEW_DATA) {
      setGlitchAnimating(true)
      startPixellateAnimation(
        timeline,
        animationDuration,
        pixellateGlitchFilter,
        currentPixellateGlitchAnim,
        () => {
          setGlitchAnimating(false)
        }
      )
    }
  }, [layerPreview, useGlitchEffect])

  if (loadingPreview)
    return (
      <Box
        {...(style && {
          ...style
        })}
      >
        <LoadingSpinner />
      </Box>
    )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      style={{
        overflow: 'visible',
        ...(style && {
          ...style
        })
      }}
    >
      <defs>
        <filter id='blurFilter' width='100%' height='100%' x='0' y='0'>
          <feGaussianBlur
            ref={blurRef}
            id='blur'
            in='SourceGraphic'
            stdDeviation='0'
            result='blurred'
          />
          <feComponentTransfer id={'darkened'}>
            <feFuncR ref={fadeRRef} id='darkenR' type='linear' slope='1' />
            <feFuncG ref={fadeGRef} id='darkenG' type='linear' slope='1' />
            <feFuncB ref={fadeBRef} id='darkenB' type='linear' slope='1' />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode in='neutral' />
            <feMergeNode in='darkened' />
          </feMerge>
        </filter>
        <filter id='pixelate' x='0' y='0' width='200%' height='200%'>
          <feTurbulence
            id='animation'
            type='fractalNoise'
            baseFrequency='0.00001 9.9999999'
            numOctaves='1'
            result='warp'
          >
            {useGlitchEffect && (
              <animate
                attributeName='baseFrequency'
                from='0.00001 9.9999'
                to='0.00001 0.001'
                dur='2s'
                repeatCount='indefinite'
                restart='always'
              />
            )}
          </feTurbulence>
          <feDisplacementMap
            id={'glitchelate'}
            ref={pixellateGlitchFilter}
            xChannelSelector='R'
            yChannelSelector='G'
            scale='0'
            in='SourceGraphic'
            in2='warpOffset'
          ></feDisplacementMap>
        </filter>
      </defs>
      <svg
        viewBox={`0 0 ${CANVAS_WIDTH} ${CANVAS_HEIGHT}`}
        style={{
          overflow: 'visible'
        }}
      >
        <g
          style={{
            overflow: 'visible',
            ...(isLocating && { filter: 'url(#blurFilter)' })
          }}
        >
          {baseLayerPreview && (
            <SvgHistoryState
              showFullImage={true}
              image={baseLayerPreview.fullBase64PngString}
              x={0}
              y={0}
              height={CANVAS_HEIGHT}
              width={CANVAS_WIDTH}
            />
          )}
          {layer.type === 'image' && layerPreview && (
            <SvgHistoryState
              animationDuration={
                isLocating
                  ? `${LOCATE_ANIMATION_DURATION_MILLIS}ms`
                  : animationDuration
              }
              shouldLocate={isLocating}
              image={layerPreview.croppedBase64PngString}
              x={layerPreview.positionInformation.start.x}
              y={layerPreview.positionInformation.start.y}
              height={layerPreview.positionInformation.croppedHeight}
              width={layerPreview.positionInformation.croppedWidth}
              filter={isGlitchAnimating ? 'url(#pixelate)' : 'url(#gapfix)'}
              useGlitchEffect={isGlitchAnimating}
            />
          )}
          {layer.type === 'video' && (
            <SvgHistoryVideoState
              ref={videoRef}
              showPlayControls={true}
              animationDuration={`${animationDuration}ms`}
              shouldPlay={true}
              video={layer.data}
              x={layer.x}
              y={layer.y}
              height={layer.height}
              width={layer.width}
              filter={isGlitchAnimating ? 'url(#pixelate)' : 'url(#gapfix)'}
              useGlitchEffect={isGlitchAnimating}
            />
          )}
        </g>
      </svg>
    </svg>
  )
}
