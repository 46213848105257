import React, { useContext, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import CustomInput from './CustomInput'
import Web3 from 'web3'
import { useActiveWeb3React } from '../../hooks/web3'
import { EthersL1Context } from '../../App'

export default function AddressInput({ sx, placeholder, onSearchClicked }) {
  const [error, setError] = useState(false)
  const [value, setValue] = useState('')

  const { library, chainId } = useActiveWeb3React()
  const ethersProviderL1 = useContext(EthersL1Context)
  return (
    <CustomInput
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: 400,
        ...(sx
          ? {
              ...sx
            }
          : {})
      }}
      value={value}
      onChange={(value) => {
        setValue(value)
        setError(false)
      }}
      error={error}
      placeholder={placeholder}
      onIconClicked={(inputValue) => {
        if (inputValue && inputValue.length === 42 && Web3.utils.isAddress(inputValue.toUpperCase())) {
          onSearchClicked && onSearchClicked(Web3.utils.toChecksumAddress(inputValue))
        } else if (inputValue && inputValue.endsWith('.eth')) {
          const providerToUse =
            library && library.ethersProvider && chainId === 1 ? library.ethersProvider : ethersProviderL1
          providerToUse
            .resolveName(inputValue)
            .then((address) => {
              if (!address) {
                setError('ENS name not found')
              } else {
                onSearchClicked && onSearchClicked(address)
              }
            })
            .catch((error) => {
              console.error(error)
              setError('ENS name not found')
            })
        } else {
          setError(!inputValue ? 'Must not be empty' : 'Invalid address or ENS')
        }
      }}
      buttonIcon={<SearchIcon />}
    />
  )
}
