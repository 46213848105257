import * as React from 'react'

import Check from '@mui/icons-material/Check'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import VerifiedIcon from '@mui/icons-material/Verified'
import PaletteIcon from '@mui/icons-material/Palette'

import VideoLabelIcon from '@mui/icons-material/VideoLabel'

export const STEP_ID = {
  UPLOAD: 0,
  METADATA: 1,
  ALLOWANCE: 2,
  MINT: 3,
  FINISH: 4
}

export const steps = [
  {
    id: STEP_ID.UPLOAD,
    label: 'Upload file to IPFS',
    description: `Our servers will automatically verify the dimensions and video length which will be used to calculate the Paint cost of the NFT.`,
    buttonLabel: 'Upload',
    icon: <UploadFileIcon />,
    doneIcon: <Check className='StyledStepIcon-completedIcon' />
  },
  {
    id: STEP_ID.ALLOWANCE,
    label: 'Allow MurAll Evolv3 to use your Paint tokens',
    description:
      'MurAll Evolv3 requires allowance to use your Paint tokens to mint your NFT. You will be prompted to sign a transaction to facilitate this.',
    buttonLabel: 'Allow',
    icon: <PaletteIcon />,
    doneIcon: <Check className='StyledStepIcon-completedIcon' />
  },
  {
    id: STEP_ID.MINT,
    label: 'Mint your NFT to Evolv3',
    description: `The transaction is gasless - only your Paint is used in the minting process. Simply sign the message and your NFT will be minted!`,
    buttonLabel: 'Mint',
    icon: <VideoLabelIcon />,
    doneIcon: <VerifiedIcon className='StyledStepIcon-completedIcon' />
  }
]
