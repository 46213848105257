import * as React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const LinearProgressWithLabel = ({
  sx,
  variant = 'determinate',
  progressValue,
  progressText
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant={variant} value={progressValue} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>
          {progressText}
        </Typography>
      </Box>
    </Box>
  )
}

export default LinearProgressWithLabel
