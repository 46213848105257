import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import styled from 'styled-components'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'

const LeftContainer = styled.div`
  display: inline-flex;
  flex: 2;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  min-width: 0;
  padding: 0;
  margin: 0;
  min-height: 0;
  height: 100%;
`
const RightContainer = styled.div`
  display: flex;
  flex: 1.5;
  flex-direction: column;
  align-items: stretch;
  min-width: 0;
  min-height: 0;
  margin: 0;
  height: 100%;
`

export default function LeftRightInfoView ({
  leftContents,
  rightContents,
  rightContentsSx
}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const theme = useTheme()

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
        alignItems: 'stretch',
        margin: '0',
        width: '100%',
        height: '100%'
      }}
    >
      <LeftContainer>{leftContents && leftContents}</LeftContainer>
      <RightContainer>
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            flex: 'none',
            flexDirection: 'column',
            alignItems: 'stretch',
            minWidth: 0,
            maxWidth: '800px',
            paddingLeft: '24px',
            paddingRight: '24px',
            height: '100%',
            overflow: 'auto',
            boxShadow: theme.shadows[4],
            backgroundColor: 'rgba(0,0,0,0.8)'
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'block flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'center',
              ...(rightContentsSx
                ? {
                    ...rightContentsSx
                  }
                : {})
            }}
          >
            {rightContents && rightContents}
          </Box>
        </Paper>
      </RightContainer>
    </Paper>
  )
}
